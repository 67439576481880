import React from 'react';
import Header from './Header';
import Body from './Body';

const PrimeHistory = (props) => {
  const { salesHistory } = props;


  return (
    <div className="dialer-agent-details-history">
      <div className="history-title">
        <i className="history-circle"/>
        <p>Sales status updates</p>
      </div>
      <table className="table table-striped sales-history-table">
        <Header historyKeys={salesHistory.length > 0 ? Object.keys(salesHistory[0]) : []} />
        <Body historyLogs={salesHistory} />
      </table>
    </div>
  )
};

export default PrimeHistory;
