import { Map } from 'immutable';

const initialState = Map({
  timeSlots: []
});

const actionsMap = {
  ['SET_TIMESLOTS']: (state, action) => {
    const {
      contact_time_slots,
    } = action.data;

    return state.merge(Map({
      timeSlots: contact_time_slots,
    }));
  },
  ['RESET_TIMESLOTS']: (state) => {
    return state.merge(initialState);
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
