import React, { useEffect } from 'react';

import Areas from './Areas';

const handleDone = (_event) => {
  const button = document.querySelector('.dashoard-close-wrapper .close');

  button.click();
};

const ExcludedAreas = ({ fetching, currentAreas, setAttribute, toggleFetching }) => {
  useEffect(handleSelectedAreas, [currentAreas]);

  const wrapperKlass = fetching ? 'overlay' : '';
  const disabled = currentAreas.length ? '' : 'disabled';

  function handleSelectedAreas() {
    const wrapper = document.getElementById('location-excluded-areas-wrapper');
    const options = wrapper.querySelectorAll('.location-option');

    options.forEach(option => {
      if (!option.classList.contains('selected')) {
        option.classList.contains('select-all') ? option.classList.add('selected') : option.click();
      }
    });
  }

  function removeAll() {
    return fetch(
      '/product_dashboard/emergency_exclusion_areas',
      {
        method: 'DELETE',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
      }
    )
    .then(response => response.json());
  }

  function handleDelete(event) {
    const disabled = event.currentTarget.classList.contains('disabled');
    const message = 'Are you sure you want to remove all emergency exclusion areas?'

    if (!disabled && confirm(message)) {
      toggleFetching(true);

      removeAll()
      .then(response => {
        if (response.success) {
          Util.createFlash('Successfully deleted all excluded areas', 'success');
          setAttribute('currentAreas', []);
          toggleFetching(false);
        } else {
          Util.createFlash(response.errors.join(', '), 'danger');
        }
      })
      .catch(error => console.log(error));
    }
  }

  function handleSave() {
    const wrapper = document.getElementById('location-excluded-areas-wrapper');
    const options = wrapper.querySelectorAll('.location-option.selected:not(.select-all)');
    const locations = [...options].map(o => o.dataset);

    toggleFetching(true);
    removeAll().catch(error => console.log(error));

    fetch(
      '/product_dashboard/emergency_exclusion_areas',
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ locations }),
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        Util.createFlash('Successfully created excluded areas', 'success');
        setAttribute('currentAreas', response.centroids);
        toggleFetching(false);
      } else {
        Util.createFlash(response.errors.join(', '), 'danger');
      }
    })
    .catch(error => console.log(error));
  }

  return (
    <div id='location-excluded-areas-wrapper' className={ wrapperKlass }>
      <h2>Excluded Areas <span id="delete-all" className={ `pull-right ${disabled}`} onClick={ handleDelete }>&mdash; Delete All</span></h2>
      <Areas areas={ currentAreas } />
      <div className='footer text-right'>
        <button className={ `btn btn-lg btn-primary mr-15 ${disabled}` } disabled={ disabled } onClick={ handleSave }>Save</button>
        <button className='btn btn-lg btn-default' onClick={ handleDone }>Done</button>
      </div>
    </div>
  );
};

export default ExcludedAreas;
