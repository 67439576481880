import React from 'react';

import Finished from './FinishedContainer';

import Alert from './images/alert.png';
import Success from './images/success.png';

const ToggleSubscriptionView = (props) => {
  const {
    display,
    finished,
    hasActiveSubscription,
    loading,
    toggle
  } = props;
  const heading = hasActiveSubscription ? 'Cancel Subscription' : 'Activate Subscription';
  const subheading = hasActiveSubscription ? 'cancel' : 'enable';
  const message = hasActiveSubscription ? 'Successfully unsubscribed from LIVE.' : 'Successfully subscribed agent to LIVE.';

  const icon = display === 'confirm' ? Alert : Success;

  return (
    <div className='toggle-live-subscription'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ icon } />
      </div>
      <div className='rex-modal-heading'>
        LIVE Account Management: { heading }
      </div>
      { display === 'confirm' &&
        <div className='rex-modal-contents'>
          <div className='rex-modal-subheading'>
            Are you sure you want to { subheading } the subscription?
          </div>
          <div className='rex-modal-actions rex-modal-actions__centered'>
            <button
              className={`rex-modal-action ${loading ? 'rex-modal-action__loading' : ''}`}
              onClick={ () => toggle() }
              disabled={ loading }
            >
              { loading ? 'Loading' : 'Confirm' }
            </button>
          </div>
        </div>
      }
      { display === 'success' &&
        <Finished
          message={ message }
          close={ finished }
        />
      }
    </div>
  );
};

export default ToggleSubscriptionView;
