import React, { useState, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './crop-styles.scss';

const initCrop = {
  unit: '%',
  x: 0,
  y: 0,
  width: 100,
  height: 100,
  aspect: 1,
};

const initalStyles = {
  maxHeight: 500,
  maxWidth: 820
};

const ImageCropper = ({
  src,
  setNewImage,
  minPixels,
  children,
}) => {
  const [image, setImage] = useState(null);
  const [cropSelector, setCropSelector] = useState(null);
  const [crop, setCrop] = useState(initCrop);

  useEffect(() => {
    makeClientCrop(crop);
  }, [crop]);

  let imageUrl;

  const getCroppedImg = () => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        blob.lastModifiedDate = new Date();
        resolve(blob);
      }, 'image/png');
    });
  };

  const validCropSize = (newCrop) => {
    if (!image) {
      return false;
    }

    const [minProp, minNaturalProp] = image.height > image.width
      ? ['width', 'naturalWidth']
      : ['height', 'naturalHeight'];

    const minDiameter = (image[minProp] / image[minNaturalProp]) * minPixels;

    return newCrop.height >= minDiameter;
  };

  const errorOut = () => {
    if (window.updateNotice) {
      window.updateNotice({
        header: 'Error!',
        message: 'Image does not meet minimum size requirements (480 x 480).',
      }, 5000);
    }
    setNewImage({
      hideModal: true,
    });
  };

  const makeClientCrop = async (newCrop) => {
    if (!image) {
      return;
    }

    if (validCropSize(newCrop)) {
      const croppedImg = await getCroppedImg();

      window.URL.revokeObjectURL(imageUrl);
      imageUrl = window.URL.createObjectURL(croppedImg);
      setNewImage({
        image: croppedImg,
        imageUrl,
      });
    }
  };

  const applySelectorStyles = () => {
    if (!cropSelector) {
      const selector = document.getElementsByClassName('ReactCrop__crop-selection')[0];

      if (selector) {
        setCropSelector(selector);
        selector.style.borderRadius = '50%';
        selector.style.border = '2px solid white';
      }
    }
  };

  const onCropChange = (cropChange) => {
    event.stopPropagation();
    applySelectorStyles();

    if (validCropSize(cropChange)) {
      setCrop(cropChange);
    }
  };

  const handleImageLoad = (img) => {
    const {
      height,
      naturalHeight,
      naturalWidth,
      width,
    } = img;

    let initialCrop = {};
    let [centerX, centerY] = [0, 0];
    const imageContainer = document.getElementsByClassName('ReactCrop__image')[0];
    const minLength = Math.min(height, width);
    const minNaturalLength = Math.min(naturalHeight, naturalWidth);
    const scaledLength = (minLength * minPixels) / minNaturalLength;

    if (minNaturalLength < minPixels) {
      errorOut();
    } else {
      setImage(img);

      if (imageContainer) {
        centerX = (imageContainer.width - scaledLength) / 2;
        centerY = (imageContainer.height - scaledLength) / 2;
      }

      initialCrop = {
        unit: 'px',
        x: centerX,
        y: centerY,
        width: scaledLength,
        height: scaledLength,
        aspect: 1,
      };

      setCrop(initialCrop);
    }

    return false;
  };

  return (
    <div className="image-crop-container">
      {!!src && (
        <>
          <ReactCrop
            src={src}
            crop={crop}
            imageStyle={initalStyles}
            onChange={onCropChange}
            onComplete={makeClientCrop}
            onImageLoaded={handleImageLoad}
            circularCrop
          />
          {children}
        </>
      )}
    </div>
  );
};

export default ImageCropper;
