import React, { useState, useEffect } from 'react';

const Modal = props => {
  const {
    reportingOptions,
    reviewsManagementIndexPath,
    fetchReviews,
  } = props;

  const [reviewId, setReviewId] = useState(undefined);
  const [reported, setReported] = useState(false);
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');

  useEffect(() => {
    $('#report-agent-profile-review').on('hidden.bs.modal', resetModal);
    document.body.addEventListener('report_review.modal_open', handleModalOpen);
  }, []);

  useEffect(() => {
    if (reported) fetchReviews();
  }, [reported]);

  const handleModalOpen = event => {
    setReviewId(event.detail);
    $('#report-agent-profile-review').modal('show');
  };

  const handleReasonChange = event => {
    const { value } = event.currentTarget;

    setReason(value);
  };

  const handleCommentChange = event => {
    const { value } = event.currentTarget;

    setComment(value);
  };

  const toggleError = (action, message) => {
    const input = document.getElementById('profile_review_reason');
    const formGroup = input.closest('.form-group');
    const errorDiv = formGroup.querySelector('.error-text');
    const operation = /add/.test(action) ? 'add' : 'remove';

    errorDiv.innerText = message || '';
    formGroup.classList[operation]('has-error');
  };

  const resetModal = () => {
    setReviewId(undefined)
    setReported(false);
    setReason('');
    setComment('');
    toggleError('remove');
  };

  const buildReportingOptions = () => {
    const opts = [];

    opts.push(<option key="_blank" value="">- Reason -</option>);

    reportingOptions.forEach(option => {
      const [displayText, value] = option;

      opts.push(<option key={ value } value={ value }>{ displayText }</option>);
    });

    return opts;
  };

  const toggleModalIcon = () => {
    const iconKlasses = reported ? 'fa-check success' : 'fa-exclamation warn';

    return <i className={ `fa ${iconKlasses} header-icon` } aria-hidden="true"></i>;
  };

  const toggleModalBody = () => {
    if (reported) {
      return (
        <div className="text-center mb-10">
          <h3 className="p-0">This review has been reported.</h3>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div className="text-center mb-10">
            <h3 className="p-0">Please choose a reason for reporting this review.</h3>
          </div>
          <div className="form-group">
            <select className="form-control" name="profile_review[reason]" id="profile_review_reason" value={ reason } onChange={ handleReasonChange }>
              { buildReportingOptions() }
            </select>
            <div className="error-text"></div>
          </div>
          <div className="form-group">
            <textarea className="form-control" name="profile_review[comment]" id="profile_review_comment" value={ comment } onChange={ handleCommentChange } placeholder="Optional Details"></textarea>
          </div>
        </React.Fragment>
      );
    }
  };

  const toggleModalFooter = () => {
    if (reported) {
      return <button type="button" className="btn btn-wide btn-rex-orange" data-dismiss="modal">OK</button>;
    } else {
      return (
        <React.Fragment>
          <button type="button" className="btn btn-wide btn-rex-default mr-10" data-dismiss="modal">CANCEL</button>
          <button type="button" className="btn btn-wide btn-rex-orange" onClick={ handleSubmit }>REPORT</button>
        </React.Fragment>
      );
    }
  };

  const toggleModalOverlay = action => {
    const modal = document.getElementById('report-agent-profile-review');
    const modalContent = modal.querySelector('.modal-content');
    const operation = /add/.test(action) ? 'add' : 'remove';

    modalContent.classList[operation]('overlay');
  };

  const handleSubmit = event => {
    event.preventDefault();

    toggleError('remove');
    toggleModalOverlay('add');

    if (!!reason) {
      const payload = JSON.stringify({
        agent_profile_review_id: reviewId,
        reason: reason,
        comment: comment,
      });

      fetch(
        reviewsManagementIndexPath,
        {
          method: 'POST',
          credentials: 'same-origin',
          headers: { 'Content-Type': 'application/json' },
          body: payload,
        }
      )
      .then(response => response.json())
      .then(response => {
        toggleModalOverlay('remove');

        if (response.success) {
          setReported(true);
        } else {
          toggleError('add', response.errors.join(', '));
          console.log(response.errors);
        }
      })
      .catch(error => {
        console.log(error);
      });
    } else {
      toggleError('add', 'Required field');
      toggleModalOverlay('remove');
    }
  };

  return (
    <div id="report-agent-profile-review" className="modal fade" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            { toggleModalIcon() }
          </div>
          <div className="modal-body">
            { toggleModalBody() }
          </div>
          <div className="modal-footer text-center pt-0">
            { toggleModalFooter() }
          </div>
        </div>
      </div>
    </div>
  )
};

export default Modal;
