import React, { Component } from 'react';
import { store } from '../../../index';

import BaseInputContainer from '../index';

import View from './view';

export default class InputContainer extends BaseInputContainer {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      classNames,
      data,
      display,
      id,
      index,
      property
    } = this.props;

    const {
      focused,
      valid,
      value,
     } = this.state;

    return (
      <View
        classNames={ classNames }
        data={ data }
        display={ display }
        focused={ focused }
        id={ id }
        index={ index }
        name={ property }
        valid={ valid }
        value={ value }

        onChange={ this.handleChange }
        onFocus={ this.handleOnFocus }
      />
    );
  }

}
