import { Component } from 'react';

import { ProfileReviewLiveCallControls } from '../LiveCallControlsV2'
import NewEmail from './NewEmail';
import NewNote from './NewNote';
import NewHandling from './NewHandling';

export default class OutcomeContainer extends Component {
  constructor(props) {
    super(props);

    let email_data  = {};
    let task_data   = {};

    this.props.matches.forEach(function(data) {
      email_data[data.match.id] = {};
      task_data[data.match.id] = {
        task_message: '',
        progress_report_date: moment(this.sevenDaysFromNow()).format('MM/DD/YYYY HH:mmA'),
        agent_follow_up_date: '',
        call_client: '',
      }
    }.bind(this));

    this.state = {
      currentTab: this.props.outcome === 'warm_transfer' ? 'transfer' : 'email',
      note_data: { note_message: this.props.lead_note },
      task_data: task_data,
      email_data: email_data,
      email_type: '',
      smsType: ''
    }

    this.submitData                    = this.submitData.bind(this);
    this.updateState                   = this.updateState.bind(this);
    this.updateTab                     = this.updateTab.bind(this);
    this.next                          = this.next.bind(this);
    this.hideEmailArrow                = this.hideEmailArrow.bind(this);
    this.toggleArrowControl            = this.toggleArrowControl.bind(this);
    this.hideEmailArrow                = this.hideEmailArrow.bind(this);
    this.toggleSMSArrowControl         = this.toggleSMSArrowControl.bind(this);
    this.toggleContactTimeArrowControl = this.toggleContactTimeArrowControl.bind(this);
  }

  tomorrowAt6() {
    var date = new Date();
    date.setDate(date.getDate() + 1);
    date.setMinutes(0);
    date.setHours(6);
    return date;
  }

  sevenDaysFromNow() {
    var date = new Date();
    date.setDate(date.getDate() + 7);
    date.setMinutes(0);
    date.setHours(6);
    return date;
  }

  updateState(prop, val, cb){
    this.setState({ [prop]: val }, cb)
  }

  renderContent() {
    switch(this.state.currentTab){
      case 'transfer':
        return(
          <ProfileReviewLiveCallControls
            lead={this.props.lead}
            matches={this.props.matches}
            rep={this.props.currentUser}
            data={{}}
          />
        ); break;
      case 'email':
        return (
          <NewEmail
            matches                       = {this.props.matches}
            email                         = {this.props.email}
            name                          = {this.props.name}
            currentUser                   = {this.props.currentUser}
            user_mailer_css               = {this.props.user_mailer_css}
            email_data                    = {this.state.email_data}
            emailArrowControl             = {this.state.emailArrowControl}
            smsArrowControl               = {this.state.smsArrowControl}
            contactTimeArrowControl       = {this.state.contactTimeArrowControl}
            toggleArrowControl            = {this.toggleArrowControl}
            toggleSMSArrowControl         = {this.toggleSMSArrowControl}
            toggleContactTimeArrowControl = {this.toggleContactTimeArrowControl}
            email_type                    = {this.state.email_type}
            smsType                       = {this.state.smsType}
            updateState                   = {this.updateState}
            lead                          = {this.props.lead}
          />
        ); break;
      case 'text':
        return null; break;
      case 'handling':
        return (
          <NewHandling
            lead               = {this.props.lead}
            callLead           = {this.props.callLead}
            matches            = {this.props.matches}
            task_data          = {this.state.task_data}
            updateState        = {this.updateState}
            currentUser        = {this.props.currentUser}
          />
        ); break;
      default:
        return (
          <NewNote
            note_data          = {this.state.note_data}
            email_data         = {this.state.email_data}
            updateState        = {this.updateState}
          />
        );
    }
  }

  hideEmailArrow() {
    $('#dropdown-container-email').slideUp()
    $('#dropdown-container-sms').slideUp()
    this.setState({ emailArrowControl: false, smsArrowControl: false, contactTimeArrowControl: false })
  }

  toggleArrowControl() {
    this.state.emailArrowControl
      ? $('#dropdown-container-email').slideUp()
      : $('#dropdown-container-email').slideDown();
    this.setState({ emailArrowControl: !this.state.emailArrowControl })
  }

  toggleSMSArrowControl() {
    this.state.smsArrowControl
      ? $('#dropdown-container-sms').slideUp()
      : $('#dropdown-container-sms').slideDown();
    this.setState({ smsArrowControl: !this.state.smsArrowControl })
  }

  toggleContactTimeArrowControl() {
    const op = this.state.contactTimeArrowControl ? 'slideUp' : 'slideDown';
    $('#dropdown-container-contact-time')[op]();
    this.setState({ contactTimeArrowControl: !this.state.contactTimeArrowControl })
  }

  emptyTask() {
    let empty = false;
    let { task_data } = this.state;

    this.props.matches.forEach((data) => {
      if (task_data[data.match.id]['agent_follow_up_date'].length && !task_data[data.match.id]['task_message'].length) empty = true;
    });
    return empty;
  }

  updateTab(e) {
    var tab = e.currentTarget.dataset.tab;

    if(this.state.currentTab === 'email' && this.state.email_type.length) {
      var confirmation = confirm('Are you sure you want to move on? You have unsaved changes.');
      if (confirmation) this.setState({currentTab: tab, email_type: ''});
    } else if (this.state.currentTab === 'handling' && this.emptyTask()) {
      alert("This task will not save unless details are entered!")
      this.setState({currentTab: tab})
    } else {
      this.setState({currentTab: tab});
    }
  }

  tabClasses(tab) {
    return this.state.currentTab === tab
      ? 'border-box header-container height-100 display-inline-vertical-center inline-block header-container-selected'
      : 'border-box header-container height-100 display-inline-vertical-center inline-block';
  }

  submitData() {
    if(!this.state.note_data.note_message) {
      alert('Must fill out note!');
      return;
    }

    var options = {
      note_data: this.state.note_data,
      task_data: this.state.task_data,
      email_data: this.emailData(),
      outcome: this.props.outcome,
      lead_id: this.props.lead.id,
      lead_options: this.props.leadOptions,
      lead_updated: this.props.leadUpdated,
      pause: this.props.pause
    };
    this.props.events.emit( 'dialer:submit' );
    $.post('/dialer/profile_review/submit', options, function(result) {
      this.props.refreshDialer();
    }.bind(this));
  }

  emailData() {
    let { lead } = this.props;
    let { email_data } = this.state;
    return email_data;
  }

  next() {
    var nextTabs = {
      email: 'handling',
      handling: 'note'
    };
    if(this.state.currentTab === 'email' && this.state.email_type.length) {
      var confirmation = confirm('Are you sure you want to move on? You have unsaved changes.');
      if (confirmation) this.setState({currentTab: nextTabs[this.state.currentTab], email_type: ''});
    } else {
      this.setState({currentTab: nextTabs[this.state.currentTab]});
    }
  }

  renderButton() {
    switch (this.state.currentTab) {
      case 'email':
      case 'handling':
        return <button onClick={this.next}>Next</button>;
      case 'transfer':
        return <button onClick={this.updateTab} data-tab={'email'}>Next</button>
      default:
        return <button onClick={this.submitData}>SUBMIT</button>;
    }
  }

  render() {
    return (
      <div className='cs-overshadow'>
        <div className='profile-review-outcome-container' onClick={this.hideEmailArrow}>
          <div className='header border-box relative'>
            <div onClick={this.updateTab} data-tab={'transfer'} className={this.tabClasses('transfer')}>
              TRANSFER
            </div>
            <div onClick={this.updateTab} data-tab={'email'} className={this.tabClasses('email')}>
              MESSAGING
            </div>
            <div onClick={this.updateTab} data-tab={'handling'} className={this.tabClasses('handling')}>
              HANDLING
            </div>
            <div onClick={this.updateTab} data-tab={'note'} className={this.tabClasses('note')}>
              LEAD NOTES
            </div>
            <div className='close absolute'>
              <i onClick={this.props.hideOutcomeContainer} className='fa fa-times'></i>
            </div>
          </div>
          {this.renderContent()}
          <div className='bottom-area'>
            {this.renderButton()}
          </div>
        </div>
      </div>
    )
  }
}
