import { Component } from 'react';
import AppointmentWidgetModal from './AppointmentWidgetModal'

export default class CalendarWidget extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalOpen: false,
      dates: {
        last_week: {
          sunday: {
            day: '',
            date: ''
          },
          monday: {
            day: '',
            date: ''
          },
          tuesday: {
            day: '',
            date: ''
          },
          wednesday: {
            day: '',
            date: ''
          },
          thursday: {
            day: '',
            date: ''
          },
          friday: {
            day: '',
            date: ''
          },
          saturday: {
            day: '',
            date: ''
          }
        },
        this_week: {
          sunday: {
            day: '',
            date: ''
          },
          monday: {
            day: '',
            date: ''
          },
          tuesday: {
            day: '',
            date: ''
          },
          wednesday: {
            day: '',
            date: ''
          },
          thursday: {
            day: '',
            date: ''
          },
          friday: {
            day: '',
            date: ''
          },
          saturday: {
            day: '',
            date: ''
          }
        },
        next_week: {
          sunday: {
            day: '',
            date: ''
          },
          monday: {
            day: '',
            date: ''
          },
          tuesday: {
            day: '',
            date: ''
          },
          wednesday: {
            day: '',
            date: ''
          },
          thursday: {
            day: '',
            date: ''
          },
          friday: {
            day: '',
            date: ''
          },
          saturday: {
            day: '',
            date: ''
          }
        },
        next_next_week: {
          sunday: {
            day: '',
            date: ''
          },
          monday: {
            day: '',
            date: ''
          },
          tuesday: {
            day: '',
            date: ''
          },
          wednesday: {
            day: '',
            date: ''
          },
          thursday: {
            day: '',
            date: ''
          },
          friday: {
            day: '',
            date: ''
          },
          saturday: {
            day: '',
            date: ''
          }
        }
      }
    };
    this.changeDate = this.changeDate.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  dayClasses(day){
    if (day === this.props.activeDay) {
      return ['date inline-block active_day']
    } else {
      return ['date inline-block']
    }
  }

  currentMonth(){
    let monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return monthNames[this.props.activeMonth];
  }

  changeDate(e){
    this.props.updateActiveDay(e.currentTarget.dataset.date, e.currentTarget.innerText)
  }

  componentDidMount(){
    Util.getCalendar()
      .then((result) => { this.setState({ dates: result.dates }) });
  }

  dateBlock(day, data, style){
    return(
      <div
        className={this.dayClasses(day)}
        onClick={this.changeDate}
        data-date={data}
        style={style}
      >
        { day }
      </div>
    )
  }

  toggleModal() {
    this.setState({modalOpen: !this.state.modalOpen})
  }

  weekBlock(week){
    return(
      <div className='dates'>
        { this.dateBlock(week.sunday.day, week.sunday.date, {textAlign: 'left'}) }
        { this.dateBlock(week.monday.day, week.monday.date) }
        { this.dateBlock(week.tuesday.day, week.tuesday.date) }
        { this.dateBlock(week.wednesday.day, week.wednesday.date) }
        { this.dateBlock(week.thursday.day, week.thursday.date) }
        { this.dateBlock(week.friday.day, week.friday.date) }
        { this.dateBlock(week.saturday.day, week.saturday.date) }
      </div>
    )
  }

  modalButton(){
    if (this.props.currentUserRoles.includes('administrator')){
      return(
        <input
          type='button'
          onClick={this.toggleModal}
          value='Set Max'
        />
      )
    }
  }

  render(){
    let { activeYear } = this.props
    let { last_week, this_week, next_week, next_next_week } = this.state.dates
    return (
      <div className='calendar-widget width-100 display-flex-vertical-center'>
        <AppointmentWidgetModal
          isOpen={this.state.modalOpen}
          toggleModal={this.toggleModal}
        />
        <div className='inner horizontal-center'>
          <div className='month-year'>
            {this.currentMonth() + ' ' + activeYear}
            {this.modalButton()}
          </div>
          <div className='week'>
            <div className='days'>
              <div className='day inline-block'>S</div>
              <div className='day inline-block'>M</div>
              <div className='day inline-block'>T</div>
              <div className='day inline-block'>W</div>
              <div className='day inline-block'>T</div>
              <div className='day inline-block'>F</div>
              <div className='day inline-block'>S</div>
            </div>
            { this.weekBlock(last_week) }
            { this.weekBlock(this_week) }
            { this.weekBlock(next_week) }
            { this.weekBlock(next_next_week) }
          </div>
        </div>
      </div>
    )
  }
}
