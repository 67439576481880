import React, { useState } from 'react';
import Fetch from '../../../common/fetch';

import { apAgentsTableHeaders } from '../shared/tables';
import searchListener from '../shared/searchListener';

import "../styles.scss";

const Management = (props) => {
  const {
    paths,
    badCanonicalAid,
    suggestedCanonicalAid,
  } = props.data;

  const [newStreams, setNewStreams] = useState([]);

  const addEnterKeyListener = () => {
    document.addEventListener('keydown', searchListener);
  }

  const removeEnterKeyListener = () => {
    document.removeEventListener('keydown', searchListener);
  }

  const search = async (e) => {
    const target = e.currentTarget;
    const search = document.getElementById('altos-search-field').value

    target.disabled = true;
    target.classList.add('submitting');

    const res = await Fetch.get({
      url: paths.search,
      params: {
        search,
      }
    })

    if (res.altosStreams && res.altosStreams.length) {
      setNewStreams(res.altosStreams);
    } else if (res.error) {
      alert(res.error);
    } else {
      alert('Search could not be completed or returned nothing.');
    }

    target.disabled = false;
    target.classList.remove('submitting');
  }

  const exactSearch = async (e) => {
    const target = e.currentTarget;
    const search = document.getElementById('altos-search-field').value

    target.disabled = true;
    target.classList.add('submitting');

    const res = await Fetch.get({
      url: paths.exactSearch,
      params: {
        search,
      }
    })

    if (res.altosStreams && res.altosStreams.length) {
      setNewStreams(res.altosStreams);
    } else if (res.error) {
      alert(res.error);
    } else {
      alert('Search could not be completed or returned nothing.');
    }

    target.disabled = false;
    target.classList.remove('submitting');
  }

  const apAgentsTableRows = (rows) => {
    return (
      <>
        {
          rows.map(({
            name,
            brokerage,
            location,
            detailsLink,
            urlSlug,
            agentId,
          }, idx) => (
            <div
              key={idx}
              className="form-table-row"
            >
              <div className="table-column col-width-25">
                <a href={detailsLink}>
                  <span className="font-2">{name}</span>
                </a>
              </div>
              <div className="table-column col-width-15">
                <span className="font-1">{agentId}</span>
              </div>
              <div className="table-column col-width-10">
                <span className="font-1">{urlSlug}</span>
              </div>
              <div className="table-column col-width-25">
                <span className="font-1">{brokerage}</span>
              </div>
              <div className="table-column col-width-25">
                <span className="font-1">{location}</span>
              </div>
            </div>
          ))
        }
      </>
    )
  }

  return (
    <div className="rea-management-wrapper">
      <div className="rea-management">
        <h2>RealEstateAgents.com Profile Management</h2>
        {
          badCanonicalAid && (
            <div className="suggested-canonincal-aid">
              <p>{`Error loading cluster. The canonical_aid ( ${badCanonicalAid} ) may be outdated.`}</p>
              {
                suggestedCanonicalAid && (
                  <p>
                    Would you like to try
                    &nbsp;
                    <a href={`/rea_clusters/details/${suggestedCanonicalAid}`}>
                      {suggestedCanonicalAid}
                    </a>
                  </p>
                )
              }
            </div>
          )
        }
        <div className="altos-search">
          <input
            id="altos-search-field"
            type="text"
            placeholder="Enter Name, URL, URL slug, or agent ID"
            onFocus={addEnterKeyListener}
            onBlur={removeEnterKeyListener}
          />
          <button
            id="rea-search-button"
            className="profile-edit-button submit light"
            onClick={exactSearch}
          >
            <i className="fas fa-sync-alt fa-spin" />
            <span className="submit-text">Search</span>
          </button>
          <button
            id="rea-search-button"
            className="profile-edit-button submit light"
            onClick={search}
          >
            <i className="fas fa-sync-alt fa-spin" />
            <span className="submit-text">Fuzzy Search</span>
          </button>
        </div>
        <p>
          Search for an agent by name, realestateagents.com URL, URL slug, or Agent ID
        </p>
        <div className="altos-search-details">
          {
            newStreams.length > 0 && (
              <div className="form-table">
                {apAgentsTableHeaders()}
                <div className="form-table-data">
                  {apAgentsTableRows(newStreams)}
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
};

export default Management;
