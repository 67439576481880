import React, { useState } from 'react';
import Modal from '../../Modal';
import Fetch from '../../../common/fetch';

const EditProfileUrlModal = (props) => {
  const {
    editSlugModalStatus,
    slug,
    closeModal,
    updatePath,
    updateState,
  } = props;

  const [newSlug, setNewSlug] = useState(slug);

  const handleChange = (e) => {
    setNewSlug(e.currentTarget.value);
  }

  const submit = async (e) => {
    const target = e.currentTarget;
    const newSlug = document.getElementById('edit-slug-field').value

    target.disabled = true;
    target.classList.add('submitting');

    const res = await Fetch.get({
      url: updatePath,
      params: {
        newSlug,
      }
    })

    if (res.newSlug) {
      alert('Success')
      updateState({ slug: res.newSlug })
    } else {
      alert('Slug could not be completed');
    }

    target.disabled = false;
    target.classList.remove('submitting');
  }

  return (
    <Modal
      close={closeModal}
      id="edit-slug-modal"
      status={editSlugModalStatus}
    >
      <h2>Enter new profile URL in field below.</h2>
      <p>
        URL must contain 3-50 letters or numbers.
        <br />
        Do not use spaces, symbols, or special characters.
      </p>
      <p>This URL can only be changed once. Once saved, the action cannot be undone.</p>
      <p>
        <span>
          www.realestateagents.com/agents/profile/
        </span>
        <input
          id="edit-slug-field"
          type="text"
          value={newSlug}
          onChange={handleChange}
        />
      </p>
      <div className="button-container">
        <button
          type="button"
          className="profile-edit-button"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="profile-edit-button submit light"
          onClick={submit}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default EditProfileUrlModal;
