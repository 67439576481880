const Header = () => {
  return (
    <thead>
      <tr>
        <th>Requested</th>
        <th>Allocated</th>
        <th>Status</th>
        <th>Created At</th>
        <th>Updated At</th>
        <th>Created By</th>
      </tr>
    </thead>
  );
};

export default Header;
