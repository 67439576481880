const Header = () => {
  return (
    <thead>
      <tr>
        <th>Contact</th>
        <th>Status</th>
        <th>Created At</th>
        <th>Updated At</th>
        <th className="no-overflow">Updated By</th>
        <th>Sources</th>
      </tr>
    </thead>
  );
};

export default Header;
