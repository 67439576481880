import React, { useState, useEffect } from 'react';

import GooglePlacesAutocomplete from './../../../_common/GooglePlacesAutocomplete.js';
import PriceSlider from './../../../_common/PriceSlider.js';
import SelectField from './../../../_common/SelectField.js';
import CheckboxField from './../../../_common/CheckboxField.js';
import Address from './_common/Address.js';

const SellerRequest = props => {
  const {
    type_home,
    leadType,
    country_options,
    us_state_options,
    ca_province_options,
    reason_options,
    seller_max_price,
    seller_min_price,
    seller_range,
    time_frame_options,
    type_home_options,
    updateFieldHandler,
  } = props;

  const [manual, toggleManual] = useState(false);

  useEffect(() => {
    if (!manual) updateFieldHandler({ seller: {  clients_location: {} } });
  }, [manual]);

  const subHeader = () => {
    if (/buy_sell/.test(leadType)) {
      return (
        <div className="header sub-header">
          <div className="icon-wrapper"><i className="icon seller"></i></div>
          <div className="title-wrapper">
            <p>Seller Request</p>
          </div>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      { subHeader() }

      <div className="input-row m-0">
        <CheckboxField fieldType="bool" fieldTitle="Manually Enter?" fieldPrefix="seller" fieldName="manual_override" required={ false } klasses={ 'inline inline-right' } updateFieldHandler={ _data => {} } fieldValue={ manual } fieldChecked={ manual } handleOnClick={ () => toggleManual(!manual) } />
      </div>

      {
        manual &&
        <Address
          fieldPrefix="seller"
          country_options={ country_options }
          us_state_options={ us_state_options }
          ca_province_options={ ca_province_options }
          updateFieldHandler={ updateFieldHandler }
        />
      }

      { !manual &&
        <div className="input-row">
          <GooglePlacesAutocomplete fieldPrefix="seller" fieldTitle="Client's Location" required={ true } placeholder="Full address" updateFieldHandler={ updateFieldHandler } filtered={ true } />
        </div>
      }

      <div className="input-row">
        <PriceSlider fieldPrefix="seller" fieldTitle="Client's price range" range={ seller_range } min_price={ seller_min_price } max_price={ seller_max_price } required={ true } updateFieldHandler={ updateFieldHandler } />

        <div className="spacer"></div>
      </div>

      <div className="input-row">
        <SelectField fieldPrefix="seller" fieldTitle="Type of Home" fieldName="type_home" options={ type_home_options } required={ true } includeBlank={ true } updateFieldHandler={ updateFieldHandler } fieldValue={ type_home } />
        <SelectField fieldPrefix="seller" fieldTitle="Time Frame" options={ time_frame_options } required={ false } includeBlank={ true } updateFieldHandler={ updateFieldHandler } />
      </div>

      <div className="input-row">
        <SelectField fieldPrefix="seller" fieldTitle="Reason for Selling" options={ reason_options } required={ false } includeBlank={ true } updateFieldHandler={ updateFieldHandler } />

        <div className="spacer"></div>
      </div>
    </React.Fragment>
  );
};

export default SellerRequest;
