import React, { useState } from 'react';

import String from '../../../../../../common/String.js'

const string = new String();

const calcCompletedValue = value => {
  return (value / 28) * 100;
};

const calcMinMax = value => {
  const input = Number(value);
  let min, max;

  if (input >= 18 && input < 26) {
    const offset = input - 18;

    min = offset * 250000 + 1000000;
    max = (offset + 1) * 250000 + 1000000;
  } else if (input >= 26) {
    const offset = input - 26;

    min = offset * 1000000 + 3000000;
    max = (offset + 1) * 1000000 + 3000000;
  } else {
    min = (input + 1) * 50000;
    max = (input + 2) * 50000;
  }

  return [min, max];
};

const PriceSlider = props => {
  const {
    fieldName: fldName,
    fieldPrefix,
    fieldTitle,
    fieldType,
    required,
    range,
    min_price,
    max_price,
    updateFieldHandler,
  } = props;

  const [minHeader, setMinHeader] = useState(min_price);
  const [maxHeader, setMaxHeader] = useState(max_price);
  const [rangeValue, setRangeValue] = useState(range);
  const [completed, setCompleted] = useState(calcCompletedValue(rangeValue));

  const fieldName = fldName || string.snakeCase(fieldTitle.replace(/[^\w\s]/g, ""));
  const name = !!fieldPrefix ? `${fieldPrefix}[${fieldName}]` : fieldName;
  const id = string.snakeCase(name);

  const labelText = () => {
    return required ? `${fieldTitle}*` : fieldTitle;
  };

  const updateRangeMinMax = value => {
    const [min, max] = calcMinMax(value);
    const rangeMinMax = { range: value, min_price: min, max_price: max };
    const data = !!fieldPrefix ? { [fieldPrefix]: rangeMinMax } : rangeMinMax;

    updateFieldHandler(data);
  };

  const handleSliderChange = event => {
    const value = Number(event.target.value);

    setRangeValue(value);
    setCompleted(calcCompletedValue(value));
    setHeading(value);
    updateRangeMinMax(value);
  };

  const togglePriceRange = action => {
    const currValue = Number(rangeValue);
    const increaseValue = (action === "+");
    const value = increaseValue ? currValue + 1 : currValue - 1;

    if ((!increaseValue && currValue > 0) || (increaseValue && currValue < 28)) {
      setRangeValue(value);
      setCompleted(calcCompletedValue(value));
      setHeading(value);
      updateRangeMinMax(value);
    }
  };

  const magnitude = value => {
    const order = Math.floor(Math.log10(Math.abs(value)));

    return Math.pow(10, order);
  };

  const humanize = value => {
    const magValue = magnitude(value);
    let humanizedValue;

    switch (magValue) {
      case 100000:
        humanizedValue = `${value / 1000}K`;
        break;
      case 1000000:
        humanizedValue = `${value / 1000000}M`;
        break;
      default:
        humanizedValue = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        break;
    }

    return `$${humanizedValue}`;
  };

  const setHeading = inputValue => {
    const input = Number(inputValue);
    const [min, max] = calcMinMax(input);

    setMinHeader(min);
    setMaxHeader(max);
  };

  const heading = () => {
    if (rangeValue === 0) {
      return (
        <>Under { humanize(100000) }</>
      );
    } else if (rangeValue === 28) {
      return (
        <>{ humanize(5000000) }+</>
      );
    } else {
      return (
        <>{ humanize(minHeader) } - { humanize(maxHeader) }</>
      );
    }
  };

  return (
    <label htmlFor="clients-price-range">
      <span className="labels-text">{ labelText() }</span>
      <div className="price-range-label center mb-10">
        <button type="button" className="price-control-btn" onClick={ () => togglePriceRange('-') }>-</button>
        <div className="price-control-range">{ heading() }</div>
        <button type="button" className="price-control-btn" onClick={ () => togglePriceRange('+') }>+</button>
      </div>
      <input
        type="range"
        id={ id }
        name={ name }
        className="price-range-slider"
        min="0"
        max="28"
        step="1"
        onChange={ handleSliderChange }
        value={ rangeValue }
        style={{
          background: `linear-gradient(to right, rgba(234, 88, 12, 1) 0%, rgba(234, 88, 12, 1) ${completed}%, rgba(234, 88, 12, 0.2) ${completed}%, rgba(234, 88, 12, 0.2) 100%)`,
        }}
      />
      <div className="price-range-label">
        <div>{ humanize(100000) }</div>
        <div>{ humanize(5000000) }+</div>
      </div>
    </label>
  );
};

export default PriceSlider;
