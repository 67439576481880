import React, { useState } from 'react';

const Form = (props) => {
  const {
    recruitFormData,
    recruitFormShow,
    duplicateCheckPath,
    setRecruitFormData,
    toggleRecruitForm,
    dispositionProspect,
    resetRecruitForm,
  } = props;

  const { user, company, agent } = recruitFormData;
  const [checked, toggleChecked] = useState(true);
  const [localization, setLocalization] = useState(agent.country || 'US');
  const [canSkipDuplicateCheck, toggleCanSkipDuplicateCheck] = useState(false);
  const checkedKlass = checked ? 'checked' : '';
  const selectLabelText = (/US/).test(localization) ? 'State' : 'Province';
  const labelText = (/US/).test(localization) ? 'Zip' : 'Postal';

  function buildOptions(country) {
    const { states, provinces } = window.isoStateProvinceCodes;
    const codes = country == 'US' ? states : provinces;
    const options = [];

    options.push(<option value=''></option>);

    codes.forEach(code => options.push(<option value={ code }>{ code }</option>));

    return options;
  }

  function buildYearOptions() {
    const hi = new Date().getFullYear();
    const lo = hi - 100;
    const options = [];

    options.push(<option value=''></option>);

    for (let i = hi; i >= lo; i--) {
      options.push(<option value={ i }>{ i }</option>);
    }

    return options
  }

  function extractNestedNames(name) {
    return name.match(/\[(.*?)\]/g).map(e => e.replace(/\[|\]/g, ''));
  }

  function clearFormErrors() {
    const form = document.getElementById('agent-recruitment-form');
    const errors = form.querySelectorAll('.has-error');

    errors.forEach(error => {
      error.classList.remove('has-error');
      error.closest('.form-group').getElementsByClassName('input-error').item(0).remove();
    });

    cleanDuplicatesDisplay();
  }

  function validateForm(data) {
    clearFormErrors();

    const form = document.getElementById('agent-recruitment-form');
    const fields = form.querySelectorAll('.validate');
    let valid = true;

    fields.forEach(field => {
      const [object, attribute] = extractNestedNames(field.name);
      const formGroup = field.closest('.form-group');
      const list = field.classList;
      let messages = new Array();

      if (list.contains('validate-presence')) {
        if (!field.value.length) {
          formGroup.classList.add('has-error');
          valid = false;
          messages.push('Required field');
        }
      }

      if (list.contains('validate-number')) {
        if (!(/^\d+$/).test(field.value)) {
          formGroup.classList.add('has-error');
          valid = false;
          messages.push('Invalid value (number)');
        }
      }

      if (list.contains('validate-email')) {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!emailRegex.test(String(field.value).toLowerCase())) {
          formGroup.classList.add('has-error');
          valid = false;
          messages.push('Invalid email');
        }
      }

      if (list.contains('validate-zip')) {
        const countryIsUS = /US/.test(data.agent.country);
        const zipRegex = countryIsUS ? /^([0-9]{5})$/ : /^([A-Z][0-9][A-Z] [0-9][A-Z][0-9])$/;
        const message = countryIsUS ? 'Invalid zip' : 'Invalid postal code';

        if (!zipRegex.test(field.value)) {
          formGroup.classList.add('has-error');
          valid = false;
          messages.push(message);
        }
      }

      if (list.contains('validate-phone')) {
        const phoneRegex = /^(\+?\d{1,3}[.-\s]?)?\(?([0-9]{3})\)?[.-\s]?([0-9]{3})[.-\s]?([0-9]{4})$/

        if (!phoneRegex.test(field.value)) {
          formGroup.classList.add('has-error');
          valid = false;
          messages.push('Invalid phone');
        }
      }

      if (list.contains('validate-length')) {
        const { maxLength, minLength } = field.dataset;

        if (maxLength != undefined && field.value.length > maxLength) {
          formGroup.classList.add('has-error');
          valid = false;
          messages.push(`Max length (${maxLength})`);
        }

        if (minLength != undefined && field.value.length < minLength) {
          formGroup.classList.add('has-error');
          valid = false;
          messages.push(`Min length (${minLength})`);
        }
      }

      if (messages.length) {
        const errorDiv = document.createElement('div');

        errorDiv.classList.add('input-error');
        errorDiv.innerHTML = messages.join(', ');

        formGroup.appendChild(errorDiv);
      }
    });

    if (!valid) form.scrollTop = 0;

    return valid;
  }

  function passDuplicateCheck() {
    const userEmailField = document.getElementById('recruit_top_agent_user_email');
    const userEmailFormGroup = userEmailField.closest('.form-group');
    const { email } = recruitFormData.user;
    const requestPath = `${duplicateCheckPath}?agent[email]=${email}`;

    return fetch(
      requestPath,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      if (!response.success) {
        const emailExactMatch = response.agent_matches.some(agent => agent.email.toLowerCase() == email.toLowerCase());
        const message = 'Possible duplicate agent';
        const errorDiv = document.createElement('div');

        errorDiv.classList.add('input-error');
        errorDiv.innerText = message;

        if (emailExactMatch) {
          userEmailFormGroup.classList.add('has-error');
          userEmailFormGroup.appendChild(errorDiv);
        }

        if (!canSkipDuplicateCheck) displayDuplicates(response.agent_matches);
        toggleCanSkipDuplicateCheck(!emailExactMatch);
      }

      return canSkipDuplicateCheck || response.success;
    });
  }

  function displayDuplicates(dupes) {
    const agentMatchesContainer = document.getElementById('duplicate-agent-matches-container');
    const agentMatchesContent = agentMatchesContainer.querySelector('.duplicate-agent-matches-content');

    cleanDuplicatesDisplay();

    dupes.forEach((dup, idx) => {
      const radioData = { ...dup, ...{ checked: '' } };
      const wrapper = document.createElement('div');

      wrapper.innerHTML = duplicateRadioOption(radioData);

      agentMatchesContent.appendChild(wrapper.firstChild);
    });

    const radios = document.querySelectorAll('input[name="recruit_top_agent[existing_agent_id]"]');

    radios.forEach(radio => {
      radio.addEventListener('change', (event) => toggleCanSkipDuplicateCheck(event.currentTarget.value.length > 0));
    });

    agentMatchesContainer.classList.remove('hidden');
  }

  function duplicateRadioOption(data) {
    const {
      id,
      name,
      email,
      status,
      license_number,
      highlight,
      checked,
    } = data;

    const agentLink = id ? `<a href="/a/${id}" target="_blank">${id}</a>` : '';
    const emailAddress = highlight.email || email;
    const licenseNumber = highlight.license_number || license_number;

    const agentStatus = (() => {
      if (status == undefined) {
        return ' (agent status unknown)';
      } else if (status.length) {
        return ` (${status})`;
      } else {
        return status;
      }
    })();

    const inputText = [agentLink, `${name}${agentStatus}`, emailAddress.toLowerCase(), licenseNumber].filter(Boolean).join(', ');

    return(
      `<div class="duplicate-agent-match">
        <label for="recruit_top_agent_existing_agent_id_${id}">
          <input ${checked} type="radio" value="${id}"" name="recruit_top_agent[existing_agent_id]" id="recruit_top_agent_existing_agent_id_${id}" /> ${inputText}
        </label>
      </div>`
    );
  }

  function cleanDuplicatesDisplay() {
    const agentMatchesContainer = document.getElementById('duplicate-agent-matches-container');
    const agentMatchesContent = agentMatchesContainer.querySelector('.duplicate-agent-matches-content');
    const radioData = { id: '', name: 'No Duplicates Found', status: '', email: '', checked: 'checked', highlight: {} };

    agentMatchesContent.innerHTML = duplicateRadioOption(radioData);
    toggleCanSkipDuplicateCheck(false);
    agentMatchesContainer.classList.add('hidden');
  }

  function toggleFormOverlay(operation) {
    const container = document.getElementById('dialer-recruit-top-agents-recruit-form');
    const action = /show/.test(operation) ? 'add' : 'remove';

    container.classList[action]('overlay');
  }

  function handleChange(event) {
    const { value, name } = event.currentTarget;
    const [key1, key2] = extractNestedNames(name);

    if (key2 == 'country') {
      setLocalization(value);
      setRecruitFormData('company', 'state', '');
      setRecruitFormData('agent', 'license_state', '');
    }

    if (key2 == 'zip_codes') {
      setRecruitFormData(key1, key2, [value]);
    } else {
      setRecruitFormData(key1, key2, value);
    }
  }

  function handleOptIn(event) {
    const currentlyChecked = !checked;
    const value = currentlyChecked ? 1 : 0;

    setRecruitFormData('agent', 'text_message', value);
    toggleChecked(currentlyChecked);
  }

  function handleDuplicateRadioOption(event) {
    const { value } = event.currentTarget;

    setRecruitFormData(undefined, 'existing_agent_id', value);
  }

  function handleDisposition(event) {
    event.preventDefault();
    toggleFormOverlay('show');

    const { currentTarget } = event;

    if (validateForm(recruitFormData)) {
      passDuplicateCheck()
      .then(success => {
        if (success) {
          const event = new CustomEvent('agent_recruitment.submit_form', { detail: currentTarget });

          dispositionProspect(event);
        } else {
          const duplicateAgentMatches = document.querySelectorAll('.duplicate-agent-match input');

          duplicateAgentMatches.forEach(duplicateAgentMatch => {
            duplicateAgentMatch.addEventListener('click', handleDuplicateRadioOption);
          });

          toggleFormOverlay('hide');
        }
      });
    } else {
      toggleFormOverlay('hide');
    }
  }

  function handleCancel(event) {
    event.preventDefault();

    const form = document.getElementById('agent-recruitment-form');

    toggleRecruitForm('hide');
    resetRecruitForm();
    clearFormErrors();
    toggleChecked(true);

    setTimeout(() => {
      form.scroll({ top: 0, behavior: 'smooth' });
    }, 250);
  }

  return (
    <div id="dialer-recruit-top-agents-recruit-form" className={ recruitFormShow }>
    <form id="agent-recruitment-form" className="p-30">
      <div className="row">
        <div className="col-md-12 text-right">
          <i className="fa fa-times fa-2x p-5 close-recruit-form" onClick={ handleCancel }></i>
          <h2 className="text-center p-0 mb-20">Agent Recruitment Form</h2>
        </div>

        <div className="col-md-12 section-header">
          <div className="form-group">
            <i className="fa fa-user"></i> Personal Info
            <hr />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_user_first_name">First Name</label>
            <input className="form-control validate validate-presence" type="text" name="recruit_top_agent[user][first_name]" id="recruit_top_agent_user_first_name" value={ user.first_name } onChange={ handleChange } />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_user_last_name">Last Name</label>
            <input className="form-control validate validate-presence" type="text" name="recruit_top_agent[user][last_name]" id="recruit_top_agent_user_last_name" value={ user.last_name } onChange={ handleChange } />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_user_email">Email</label>
            <input className="form-control validate validate-presence validate-email" type="text" name="recruit_top_agent[user][email]" id="recruit_top_agent_user_email" value={ user.email } onChange={ handleChange } />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_user_phone_office">Phone Office</label>
            <input className="form-control validate validate-presence validate-phone" type="text" name="recruit_top_agent[user][phone_office]" id="recruit_top_agent_user_phone_office" value={ user.phone_office } onChange={ handleChange } />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_user_phone_mobile">Phone Mobile</label>
            <input className="form-control validate validate-presence validate-phone" type="text" name="recruit_top_agent[user][phone_mobile]" id="recruit_top_agent_user_phone_mobile" value={ user.phone_mobile } onChange={ handleChange } />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_user_direct_line">Direct Line</label>
            <input className="form-control validate validate-presence validate-phone" type="text" name="recruit_top_agent[user][direct_line]" id="recruit_top_agent_user_direct_line" value={ user.direct_line } onChange={ handleChange } />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_agent_text_message_number">Text Message Number</label>
            <input className="form-control validate validate-presence validate-phone" type="text" name="recruit_top_agent[agent][text_message_number]" id="recruit_top_agent_agent_text_message_number" value={ agent.text_message_number } onChange={ handleChange } />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_agent_text_message">
              <input className="form-control mr-10" type="checkbox" name="recruit_top_agent[agent][text_message]" id="recruit_top_agent_agent_text_message" checked={ checkedKlass } onClick={ handleOptIn } />
              Opt-In Text Message
            </label>
          </div>
        </div>

        <div className="col-md-12 section-header">
          <div className="form-group">
            <i className="fa fa-briefcase"></i> Company Info
            <hr />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_company_name">Company Name</label>
            <input className="form-control validate validate-presence" type="text" name="recruit_top_agent[company][name]" id="recruit_top_agent_company_name" value={ company.name } onChange={ handleChange } />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_company_address">Address</label>
            <input className="form-control validate validate-presence" type="text" name="recruit_top_agent[company][address]" id="recruit_top_agent_company_address" value={ company.address } onChange={ handleChange } />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_agent_country">Country</label>
            <select className="form-control validate validate-presence" name="recruit_top_agent[agent][country]" id="recruit_top_agent_agent_country" value={ agent.country } onChange={ handleChange } >
              <option value="CA">Canada</option>
              <option value="US">United States</option>
            </select>
          </div>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_company_city">City</label>
            <input className="form-control validate validate-presence" type="text" name="recruit_top_agent[company][city]" id="recruit_top_agent_company_city" value={ company.city } onChange={ handleChange } />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_company_state">{ selectLabelText }</label>
            <select className="form-control validate validate-presence" name="recruit_top_agent[company][state]" id="recruit_top_agent_company_state" value={ company.state } onChange={ handleChange } >
              { buildOptions(agent.country) }
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_company_zip">{ `${labelText} Code` }</label>
            <input className="form-control validate validate-presence validate-zip" type="text" name="recruit_top_agent[company][zip]" id="recruit_top_agent_company_zip" value={ company.zip } onChange={ handleChange } />
          </div>
        </div>

        <div className="col-md-12 section-header">
          <div className="form-group mt-20">
            <i className="fa fa-id-card"></i> License Info
            <hr />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_agent_broker_name">Broker Name</label>
            <input className="form-control validate validate-presence validate-length" data-max-length="40" type="text" name="recruit_top_agent[agent][broker_name]" id="recruit_top_agent_agent_broker_name" value={ agent.broker_name } onChange={ handleChange } />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_agent_original_real_estate_license_year">Original License Year</label>
            <select className="form-control validate validate-presence" name="recruit_top_agent[agent][original_real_estate_license_year]" id="recruit_top_agent_agent_original_real_estate_license_year" value={ agent.original_real_estate_license_year } onChange={ handleChange } >
              { buildYearOptions() }
            </select>
          </div>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_agent_past_12_months_sales">Past 12 Months Sales</label>
            <input className="form-control validate validate-presence validate-length" data-max-length="50" type="text" name="recruit_top_agent[agent][past_12_months_sales]" id="recruit_top_agent_agent_past_12_months_sales" value={ agent.past_12_months_sales } onChange={ handleChange } />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_agent_license_type">License Type</label>
            <select className="form-control validate validate-presence" name="recruit_top_agent[agent][license_type]" id="recruit_top_agent_agent_license_type" value={ agent.license_type } onChange={ handleChange } >
              <option value=""></option>
              <option value="Broker">Broker</option>
              <option value="Salesperson">Salesperson</option>
            </select>
          </div>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_agent_license_number">License Number</label>
            <input className="form-control validate validate-presence validate-length" data-max-length="30" type="text" name="recruit_top_agent[agent][license_number]" id="recruit_top_agent_agent_license_number" value={ agent.license_number } onChange={ handleChange } />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_agent_license_state">{ `License ${selectLabelText}` }</label>
            <select className="form-control validate validate-presence" name="recruit_top_agent[agent][license_state]" id="recruit_top_agent_agent_license_state" value={ agent.license_state } onChange={ handleChange } >
              { buildOptions(agent.country) }
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="recruit_top_agent_agent_zip_codes">{ `${labelText} Code` }</label>
            <input className="form-control validate validate-presence validate-zip" type="text" name="recruit_top_agent[agent][zip_codes][]" id="recruit_top_agent_agent_zip_codes" value={ agent.zip_codes } onChange={ handleChange } />
          </div>
        </div>

        <div className="col-md-12 hidden" id="duplicate-agent-matches-container">
          <h3 className="text-danger p-0 mb-15">ATTENTION! Possible duplicate agents found.</h3>
          <p className="text-danger">Please select the agent you would like to recruit. If no duplicates are found, please correct the fields highlighted in red above in order to proceed.</p>
          <div className="duplicate-agent-matches-content"></div>
        </div>

        <div className="col-md-12">
          <div className="form-group text-right">
            <hr />
            <button className="btn btn-lg btn-rex-white mr-10" onClick={ handleCancel }>Cancel</button>
            <button className="btn btn-lg btn-rex-blue" data-disposition="recruit" onClick={ handleDisposition }>Save</button>
          </div>
        </div>
      </div>
    </form>
    </div>
  )
};

export default Form;
