import { Map } from 'immutable';

const initialState = Map({
  attempts: '',
  max_requal_attempts: '',
  origin: '',
  submission: '',
});

const actionsMap = {
  ['SET_HEADER']: (state, action) => {
    const {
      lead,
      max_requal_attempts,
      origin,
      qual_attempt_count,
    } = action.data;

    const { created_at } = lead;

    return state.merge(Map({
      attempts: qual_attempt_count,
      max_requal_attempts: max_requal_attempts + 1,
      origin,
      submission: created_at,
    }));
  },
  ['SET_LIMITED_LEAD_HEADER_DATA']: (state, action) => {
    const { created_at, lead_source } = action.customAttributes;
    return state.merge(Map({
      origin: lead_source,
      submission: created_at,
    }));
  },
  ['RESET_HEADER']: (state) => {
    return state.merge(initialState);
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
