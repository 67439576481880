import LiveControls from './LiveControls'
import CallStatus from './CallStatus';
import Agent from './Agent';
import Lead from './Lead';
import Conference from './Conference';
import request from 'superagent';
import './styles.scss';

import { Object } from 'core-js';

import { store } from '../QualQueueV2';

export default class QualUI extends LiveControls {
  constructor(props) {
    super(props)

    this.updateLead = this.updateLead.bind(this)
  }

  updateLead() {
    store.dispatch({
      type: 'SET_DISPOSITION',
      data: {
        disposition: 'matching',
        value: 'Contacted'
      },
    });
    store.dispatch({
      type: 'INITIATING_CALL',
    });

    let { lead } = this.props;

    const allowed_params = [
      "baths",
      "beds",
      "buyer_cities",
      "city",
      "comments",
      "country",
      "email_address",
      "first_name",
      "home_price_max",
      "home_price_min",
      "id",
      "last_name",
      "lead_type",
      "mortgage_status",
      "opt_out",
      "phone_number",
      "phone_number_ext",
      "size",
      "state",
      "street_name",
      "street_number",
      "type_home",
      "unit_number",
      "zip",
    ];

    let lead_params = Object.keys(lead)
      .filter(key => allowed_params.includes(key))
      .reduce((obj, key) => {
        let value = lead[key];
        if (key === 'phone_number') {
          if (lead.phone_number_ext) {
            value = `${lead[key]}ext${lead.phone_number_ext}`;
          }
        }
        obj[key] = value;
        return obj;
      }, {});

    return new Promise((resolve, reject) => {
      request.post('/qual/queue/update_lead')
        .send({ lead: lead_params, email: lead_params.email_address })
        .then(res => { resolve(res) })
        .catch(err => {
          console.error('error on updateLead', err)
          reject(err)
        })
    })
  }

  /**
 * Begin the process of patching the agent into the call.
 */
  contactAgent() {
    return new Promise((resolve, reject) => {
      console.debug('contacting agent')

      this.setState({ callStatus: 'agent_contacted' });

      request.post('/phone/live_conference/v2/contact_agent')
        .send({
          call_control_uuid: this.state.callControlUUID
        }).then(({ body }) => {
          if (body.match_id) {
            request.post('/qual/queue/create_servicing_complete_note')
              .send({ lead_id: this.props.lead.id, qual_note: this.props.data.qual_note })
              .then(noteData => { resolve(noteData) })
          }
          resolve();
        }).catch(err => {
          console.error(err);
          reject(err);
        });
    });
  }

  initiate() {
    return new Promise((resolve, reject) => {
      this.updateLead()
        .then(this.startLiveTransfer.bind(this))
        .then(callControlUUID => {
          this.setState({ callControlUUID })
          console.debug('set call control UUID', callControlUUID)
        })
        .then(this.contactAgent)
        .then(resolve)
        .catch(err => {
          console.error('could not start live transfer', err)
          reject(err);
        })
    });
  }

  render() {
    const { allValid } = this.props;
    let { callStatus, agentCallStartTime, leadCallStartTime, conferenceCallStartTime } = this.state;
    const disabled = this.props.live.status === 'submitted_rex' || !allValid

    return (
      <div className="control-user-info">
        {this.state && this.state.callControlUUID && this.renderSubscription()}

        <CallStatus
          disabled={disabled}
          callStatus={callStatus}
          conferenceCallStartTime={conferenceCallStartTime}
          agentCallStartTime={agentCallStartTime}
          leadCallStartTime={leadCallStartTime}
          buttonText={`Initiate Transfer to Agent`}
          initiate={this.initiate.bind(this)}
        />

        <Agent
          disabled={disabled}
          updateCallMode={this.updateCallMode}
          callStatus={callStatus}
        />

        <Conference
          disabled={disabled}
          updateCallMode={this.updateCallMode}
          callStatus={callStatus}
        />

        <Lead
          disabled={disabled}
          updateCallMode={this.updateCallMode}
          callStatus={callStatus}
        />
      </div>
    );
  }
}
