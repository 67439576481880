import React, { Component } from 'react';
import { connect } from 'react-redux';

import View from './view';

function mapStateToProps(state) {
  return {
    attempts: state.headers.get('attempts'),
    history: state.qual.get('history'),
    instructions: state.qual.get('instructions'),
    max_requal_attempts: state.headers.get('max_requal_attempts'),
    note: state.qual.get('note'),
  };
}

class Qual extends Component {
  render() {
    const {
      attempts,
      history,
      instructions,
      max_requal_attempts,
      note,
    } = this.props;

    const data = {
      attempts,
      instructions,
      max_requal_attempts,
      note,
    };

    return(
      <View
        data={ data }
        history={ history }
      />
    );
  }
}

export default connect(mapStateToProps)(Qual);
