import React, { Component } from 'react';

import ProcessRefundPartial from './ProcessRefundPartial';
import ProcessRefundSelection from './ProcessRefundSelection';
import ProcessRefundSuccess from './ProcessRefundSuccess';

export default class ProcessRefundContainer extends Component {
  constructor(props) {
    super(props);
    this.changeAmount = this.changeAmount.bind(this);
    this.finished     = this.finished.bind(this);
    this.goTo         = this.goTo.bind(this);
    this.submitRefund = this.submitRefund.bind(this);

    this.state = {
      amount:       0,
      errorMessage: '',
      loading:      false,
    };
  }

  changeAmount(e) {
    this.setState({ amount: e.target.value })
  }

  success() {
    const {
      updateManagement,
    } = this.props;

    this.goTo('refundSuccess');
    updateManagement({ loading: false });
  }

  fail(errorMessage) {
    const {
      updateManagement,
    } = this.props;

    this.setState({ errorMessage: errorMessage, loading: false });
    updateManagement({ loading: false });
  }

  finished() {
    const {
      updateManagement,
    } = this.props;

    updateManagement({
      displaying: null,
      loading:    false,
      modalOpen:  false,
    });
  }

  submitRefund(fullRefund) {
    const {
      agentId,
      netInitialFee,
      updateManagement,
    } = this.props;

    const {
      amount,
    } = this.state;

    updateManagement({ loading: true });
    this.setState({ loading: true });

    const amountToSubmit = fullRefund ? netInitialFee : (amount * 100);

    const url = `/agents/${ agentId }/refund_live?amount=${ amountToSubmit }`;

    fetch(url, { credentials: 'same-origin', method: 'POST' })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          this.fail(res.error);
        } else {
          this.success();
        }
      })
      .catch(err => {
        alert(err);
      });
  }

  goTo(page) {
    this.setState({
      display: page,
      loading: false,
    });
  }

  render() {
    const {
      netInitialFee,
      toggleModal,
    } = this.props;

    const {
      display,
      errorMessage,
      loading,
    } = this.state;

    return (
      (
        () => {
          switch(display) {
            case 'refundSuccess':
              return <ProcessRefundSuccess
                finished={ this.finished }
              />;
            case 'refundPartial':
              return <ProcessRefundPartial
                changeAmount={ this.changeAmount }
                errorMessage={ errorMessage }
                finished={ this.finished }
                goTo={ this.goTo }
                loading={ loading }
                submitRefund={ this.submitRefund }
              />;
            default:
              return <ProcessRefundSelection
                errorMessage={ errorMessage }
                goTo={ this.goTo }
                loading={ loading }
                netInitialFee={ netInitialFee }
                submitRefund={ this.submitRefund }
              />;
          }
        }
      )()
    )
  }
}
