const nameText = (valid, response) => {
  let text = 'Name Match';
  const { caller_name, body } = response;

  if (!valid && caller_name !== undefined) text += `, ${caller_name.caller_name}`;
  if (!valid && caller_name === undefined) text += `, Error code ${body.code}`;

  return text;
};

const phoneText = (valid, response) => {
  let text = 'Valid Phone';
  const { carrier, body } = response;

  if (!valid && carrier !== undefined) text += `, Error code ${carrier.error_code}`;
  if (!valid && carrier === undefined) text += `, Error code ${body.code}`;

  return text;
};

const mobileText = (valid, response) => {
  let text = 'Mobile Phone';
  const { caller_name, body } = response;

  if (!valid && caller_name !== undefined) text += `, ${caller_name.caller_type}`;
  if (!valid && caller_name === undefined) text += `, Error code ${body.code}`;

  return text;
};

const valid = { li: 'valid', fa: 'fa-check-circle-o' };
const invalid = { li: 'invalid', fa: 'fa-times-circle-o' };

const Outcome = ({ phone, result }) => {
  const {
    name_match,
    mobile_phone,
    valid_phone,
    response,
  } = result;

  const nameKlasses = name_match ? valid : invalid;
  const mobileKlasses = mobile_phone ? valid : invalid;
  const phoneKlasses = valid_phone ? valid : invalid;

  return (
    <div className="col-md-6 mb-30">
      <div className="result-outcome">
        <p className="bold">{ phone }</p>
        <ul className="ml-15">
          <li className={ nameKlasses.li }>
            <i className={ `fa ${nameKlasses.fa}` }></i> { response && nameText(name_match, response) }
          </li>
          <li className={ phoneKlasses.li }>
            <i className={ `fa ${phoneKlasses.fa}` }></i> { response && phoneText(valid_phone, response) }
          </li>
          <li className={ mobileKlasses.li }>
            <i className={ `fa ${mobileKlasses.fa}` }></i> { response && mobileText(mobile_phone, response) }
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Outcome;
