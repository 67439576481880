import { Component } from 'react';

export default class VerificationRecords extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      records: []
    }
  }

  componentDidMount() {
    if(this.props.lead_id)
      this.obtainValidationRecords()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lead_id != this.props.lead_id) {
      this.obtainValidationRecords(nextProps.lead_id)
    }
  }

  obtainValidationRecords(id) {
    let lead_id = id || this.props.lead_id;
    $.get('/qual/queue/validation_records', {lead_id: lead_id}, function(result) {
      this.setState({
        records: result.validation_records
      })
    }.bind(this))
  }

  renderValidationRecords() {
    if(this.state.records.length) {
      return (
        this.state.records.map(function(record, idx) {
          return (
            <div key={idx} style={{paddingBottom: '10px'}} className={record.class + ' height-100 display-flex-vertical-center'} id={"validation_record_" + record.id} title={record.title}>
              {' '}
              {record.result}
              {' '}
              {record.source}
              {' '}
              {record.attributes}
            </div>
          )
        })
      );
    } else {
      return (
        <div className="no-records">No validation records</div>
      );
    }
  }

  resetForm() {
    $('#new_validation_record').reset()
  }

  render() {
    return(
      <div className='results inline-block v-align-top' style={{padding: '15px'}}>
        <div className='height-100'>
          <h3 style={{padding: 0}}>Verifications: </h3>
        </div>
        <div className='v-align-top height-100'>
          <div className="robinator_records height-100" style={{margin: '10px 0 0 0'}}>
            {this.renderValidationRecords()}
          </div>
        </div>
      </div>
    );
  }
}
