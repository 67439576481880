import React, { Component } from 'react';

import ToggleSubscription from './ToggleSubscriptionView';

export default class ToggleSubscriptionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: 'confirm',
      loading: false,
    };

    this.finished = this.finished.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  success(hasActiveSubscription) {
    const { updateManagement } = this.props;

    const messages = {
      subscribed: {
        subscribed: 'Yes',
        monthlyPlan: '50 Credits'
      },
      unsubscribed: {
        subscribed: 'No',
        monthlyPlan: 'N/A'
      }
    };

    const subscribedEl = document.getElementsByClassName('subscribed');
    const monthlyPlanEl = document.getElementsByClassName('monthly-plan');

    const message = hasActiveSubscription ? messages.subscribed : messages.unsubscribed;

    subscribedEl[0].innerText = message.subscribed;
    monthlyPlanEl[0].innerText = message.monthlyPlan;

    this.setState({
      loading: false,
      display: 'success'
    });
    updateManagement({
      loading: false,
    });
  }

  finished() {
    const { hasActiveSubscription, updateManagement } = this.props;
    updateManagement({
      displaying: null,
      hasActiveSubscription: !hasActiveSubscription,
      loading: false,
      modalOpen: false,
    });
  }

  toggle() {
    const {
      agentId,
      hasActiveSubscription,
      updateManagement
    } = this.props;
    const url = hasActiveSubscription ? `/agents/${agentId}/cancel_live_subscription` : `/agents/${agentId}/reactivate_live_subscription`;

    updateManagement({ loading: true });
    this.setState({ loading: true });

    fetch(url, { credentials: 'same-origin', method: 'POST' })
      .then(res => {
        this.success(!hasActiveSubscription);
      })
      .catch(err => {
        unsuccessfulRequest(err);
      });
  }

  render() {
    const { hasActiveSubscription } = this.props;
    const { display, loading } = this.state;
    return (
      <ToggleSubscription
        display={ display }
        hasActiveSubscription={ hasActiveSubscription }
        loading={ loading }
        finished={ this.finished }
        toggle={ this.toggle }
      />
    );
  }
}
