import React from 'react';
import ReactDOM from 'react-dom';
import ValidationDialer from './index';

/**
 * Initialize ClaimValidationDialer
 */

export function initialize(containerElement, props) {
  let component = React.createElement(ValidationDialer, props);
  ReactDOM.render(component, containerElement);
}
