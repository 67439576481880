import { Component } from 'react';

export default class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initSalesManager: {}
    }
    this.updateSalesManagerBilling = this.updateSalesManagerBilling.bind(this);
    this.updateSalesManagerBillingPhone = this.updateSalesManagerBillingPhone.bind(this);
    this.inSync = this.inSync.bind(this);
  }

  componentWillReceiveProps(newProps) {
    let initSalesManager = newProps.state;
    this.setState({ initSalesManager });
  }

  updateSalesManagerBilling(field, e) {
    let { salesManager } = this.props.state;
    let { updateState } = this.props;

    salesManager[field] = e.currentTarget.value;
    updateState({ salesManager: salesManager });
  }

  updateSalesManagerBillingPhone(e) {
    let { salesManager } = this.props.state;
    let { updateState } = this.props;
    let temp = e.currentTarget.value.replace(/\D+/g, '');

    salesManager['billing_phone_number'] = temp !== '' ? temp : null;
    updateState({ salesManager: salesManager })
  }

  inSync(field) {
    let { salesManager, initSalesManager } = this.props.state;
    if ((salesManager[field] === initSalesManager[field]) || (!salesManager[field] && !initSalesManager[field])) {
      return '';
    } else {
      return ' unsynced';
    }
  }

  render() {
    let { salesManager, updating } = this.props.state;
    let { klass, state, billingSynced } = this.props;
    let update = this.updateSalesManagerBilling;
    let submit = this.props.submitUpdatedSalesManager;

    return (
      <div className={klass}>
        <div className='toolbar'>
          <h3>Billing Information</h3>
          <input
            type="button"
            className={billingSynced ? 'hidden' : 'edit-button'}
            value={updating ? 'Updating...' : 'Update'}
            onClick={submit}
          />
        </div>
        <div className='billing-content'>
          <div className='field-column'>
            <div className='billing-item'>First Name</div>
            <input
              className={'value' + this.inSync('billing_first_name')}
              value={salesManager.billing_first_name || ''}
              onChange={(e) => update('billing_first_name', e)}
            />
            <div className='billing-item'>Last Name</div>
            <input
              className={'value' + this.inSync('billing_last_name')}
              value={salesManager.billing_last_name || ''}
              onChange={(e) => update('billing_last_name', e)}
            />
          </div>
          <div className='field-column'>
            <div className='billing-item'>Street</div>
            <input
              className={'value' + this.inSync('billing_address')}
              value={salesManager.billing_address || ''}
              onChange={(e) => update('billing_address', e)}
            />
          <div className='billing-city-state'>
              <div>
                <div className='billing-item'>City</div>
                <input
                  className={'value' + this.inSync('billing_city')}
                  value={salesManager.billing_city || ''}
                  onChange={(e) => update('billing_city', e)}
                  />
              </div>
              <div>
                <div className='billing-item'>State</div>
                <select
                  className='dropdown-value'
                  value={salesManager.billing_state || 'billing_state'}
                  onChange={(e) => update('billing_state', e)}
                  >
                  {FormHelper.stateOptions().map((state, idx) => <option key={idx} value={state}>{state}</option>)}
                </select>
              </div>
            </div>
            <div className='billing-item'>Zipcode</div>
            <input
              className={'value' + this.inSync('billing_zip_code')}
              value={salesManager.billing_zip_code || ''}
              onChange={(e) => update('billing_zip_code', e)}
            />
          </div>
          <div className='field-column'>
            <div className='billing-item'>Phone</div>
            <input
            className={'value' + this.inSync('billing_phone_number')}
            value={Util.formatPhoneNumber(salesManager.billing_phone_number) || ''}
            onChange={this.updateSalesManagerBillingPhone}
            />
            <div className='billing-item'>Email</div>
            <input
            className={'value' + this.inSync('billing_email')}
            value={salesManager.billing_email || ''}
            onChange={(e) => update('billing_email', e)}
            />
          </div>
        </div>
      </div>
    );
  }
}
