import { Component } from 'react';

export default class NewNoteModal extends Component {
  constructor(props){
    super(props)

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal() {
    return this.props.state.showAddNoteModal
      ? 'modal-overlay'
      : 'hidden';
  }

  closeModal(){
    this.props.updateState({ showAddNoteModal: false, noteMessage: '' });
  }

  render(){
    let { state, updateState, createNote } = this.props;
    return(
      <div className={this.showModal()} onClick={this.closeModal}>
        <div className='title-modal' onClick={(e) => e.stopPropagation()}>
          <div className='header'>
            <h3>Add Note</h3>
            <div className='close-button' onClick={this.closeModal}>
              <i className='fa fa-times-circle' />
            </div>
          </div>
          <div className='content'>
            <textarea
              type='input'
              value={state.noteMessage}
              placeholder="Leave a note."
              className='note-textarea'
              onChange={(e) => updateState({ noteMessage: e.currentTarget.value })}
            />
            <div className="prompt-options">
              <div className="prompt-cancel" onClick={this.closeModal}>Cancel</div>
              <div className="prompt-confirm" onClick={createNote}>Create</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
