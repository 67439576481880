const toggleOverlay = (container, action) => {
  const operation = /enable/.test(action) ? 'add' : 'remove';

  container.classList[operation]('overlay');
};

const Actions = ({ reviewId, reviewModerationReviewValidatePath }) => {
  function submitValitation(event) {
    const { currentTarget } = event;
    const container = currentTarget.closest('.result');
    const validationPill = container.querySelector('.validation-result');
    const validated = currentTarget.classList.contains('valid') ? 'valid' : 'invalid';
    const data = JSON.stringify({ review_id: reviewId, validated: validated });

    toggleOverlay(container, 'enable');

    fetch(
      reviewModerationReviewValidatePath,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        body: data
      }
    )
    .then(response => {
      validationPill.dispatchEvent(new CustomEvent('profile_review.validation_change', { detail: { validation: validated } }));
      toggleOverlay(container, 'disable');
    });
  }

  return (
    <div className="col-md-12">
      <div className="actions">
        <button className="btn btn-md btn-success validation-btn valid mr-15" onClick={ submitValitation }><i className="fa fa-thumbs-up"></i> Valid</button>
        <button className="btn btn-md btn-danger validation-btn invalid" onClick={ submitValitation }><i className="fa fa-thumbs-down"></i> Invalid</button>
      </div>
    </div>
  )
};

export default Actions;
