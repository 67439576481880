import { Component } from 'react';
import request, { search } from 'superagent';
import intlTelInput from 'intl-tel-input';

import Loader                   from './Loader';
import PreMatchResearchModal    from './PreMatchResearchModal';
import LexisNexisModal          from './LexisNexisModal';
import Header                   from './Header';
import Banner                   from './Banner';
import Scripts                  from './Scripts';
import NextStep                 from './NextStep';
import LeadForm                 from './LeadForm';
import LeadTypeForm             from './live/LeadTypeForm';

import {
  killSubmitParams,
  qualSubmitParams,
  requalSubmitParams,
} from './util/paramsFromState'

import {
  isLocationSynced,
  buildSearchQuery,
  validHCSubmitParams,
} from './util/helperMethods'

export default class Content extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      pause             : false,
      lead              : {
        buyer_cities: '',
        blocked_company_brand_ids: '',
        blocked_verified_company_ids: '',
        microsite_id:  '',
        lead_type: '',
        commercial: false,
        opt_out: false,
        country: '',
        time_frame: '',
        language_preference: 'en',
      },
      partner_id        : '',
      email             : '',
      highend           : '0',
      white_pages       : {},
      phone             : '',
      location          : '',
      requal_attempts   : 0,
      qual_attempt_count: 0,
      origin            : '',
      duplicates        : '',
      home              : '',
      mortgage_status   : {},
      states            : [],
      note              : {},
      call_count        : '',
      home_captain      : false,
      whitelabeled      : false,
      status            : '',
      match_count       : '',
      qual_note         : '',
      comments          : '',
      initial_call      : true,
      companies         : {},
      companies_loading : false,
      beds              : '',
      baths             : '',
      blacklisted       : '',
      status_button     : {
        text       : false,
        action     : '',
        note_status: ''
      },
      sending_agent: {},
      pmr_form: {
        companies_block              : false,
        listing_link                 : false,
        is_an_agent                  : '',
        intent                       : undefined,
        home                         : undefined,
        matcher_notes                : '',
        comments                     : '',
        blocked_company_brand_ids    : {},
        blocked_verified_company_ids : {},
        qual_status                  : '',
        companies                    : {},
        agent_link                   : '',
        listed                       : '',
        listing_info                 : '',
        estimate                     : '',
        additional_notes             : '',
        home_price                   : '',
        no_estimate                  : '0',
        qual_queue                   : true
      },
      validations: {
        incorrect_location: false,
        incorrect_zip: false,
        invalid_phone_number: false,
        blacklisted_reasoning: false,
      },
      live: {
        live_lead: false,
        live_disposition: '',
        submitting_agent_name: '',
        submitting_agent_company_name: '',
        submitting_agent_photo: '',
        submitting_agent_broker_name: '',
        verified_info: false,
        from_the_agent: '',
        source: ''
      },
      live_verifications: {},
      search: '',
      property: '',
      loading: false,
      loadingMessage: '',
      country_options: {},
      skip_pmr: false,
      user_name: props.user_name,
      dual_transaction: false,
      dual_transaction_buyer_lead: '',
      stand_in_data: {},
    };

    this.state = this.initialState;

    this.events = EventBus;
    this.events.on( 'requal:submit', this.requalSubmit.bind( this ) );
    this.events.on( 'dialer:pause', this.pauseDialer.bind( this ) );
    this.events.on( 'verification:pmr', this._verificationPMR.bind( this ) );
    this.events.on( 'liveagent:accepted', this.changeDisposition.bind(this) );

    this.itiPhoneNumber = undefined;

    this.click2CallLink       = this.click2CallLink.bind(this);
    this.handleSuccess        = this.handleSuccess.bind(this);
    this.pmrSubmit            = this.pmrSubmit.bind(this);
    this.reloadCompanies      = this.reloadCompanies.bind(this);
    this.submitButtonClick    = this.submitButtonClick.bind(this);
    this.updateState          = this.updateState.bind(this);
    this.updateAndReturnLead  = this.updateAndReturnLead.bind(this);
    this.isCompassLead        = this.isCompassLead.bind(this);
    this.requalSubmit         = this.requalSubmit.bind(this);
    this.openPCRModal         = this.openPCRModal.bind(this);
    this.initCountrySelector  = this.initCountrySelector.bind(this);
    this.updatePhoneNumberState = this.updatePhoneNumberState.bind(this);
  }

  componentDidMount() {
    history.pushState(null, null, '?');
    document.title = 'Qual Queue';
    this.buyersPancake        = document.getElementById('buyer-areas-pancake');
    this.buyersCollapsible    = document.getElementsByClassName('buyer-areas collapsible')[0];
    this.qualModalElements    = document.querySelectorAll('.research_modal, .research_modal_overlay');
    this.lexisNexisElements   = document.querySelectorAll('.lexis_nexis_modal .lexis_nexis_overlay');
    this.formFields           = document.querySelectorAll('.right_content .content_form input, .right_content .content_form select, .right_content .content_form textarea');
    this.show(this.buyersPancake);
    this.hide(this.buyersCollapsible);
    this.initCountrySelector();

    this.requestCall();
  }

  initCountrySelector() {
    // Keep checking until the phone field is visible
    let interval = setInterval(() => {
      let target = document.getElementById('intl_phone_target');
      if (!!target) {
        clearInterval(interval);

        const selectors = ['#intl_phone_target'];
        const initialCountries = [this.state.lead.phone_number_country] || 'US';
        Util.initPhoneCountrySelectors(selectors, initialCountries, this.updatePhoneNumberState);
      }
    }, 500);
  }

  updatePhoneNumberState(_, iti) {
    let lead = this.state.lead;
    lead.phone_number_base = iti.getE164PhoneNumber();
    let validations = this.state.validations;
    validations.phone_number_valid = iti.isValidNumber();
    validations.blacklisted_reasoning = this.state.blacklisted;
    this.updateState({ lead: lead, validations: validations });
  }

  show(obj){
    if(!obj) return;

    obj instanceof NodeList
      ? obj.forEach(el => el.style.display = 'block')
      : obj.style.display = 'block';
  }

  hide(obj){
    if (!obj) return;

    obj instanceof NodeList
      ? obj.forEach(el => el.style.display = 'none')
      : obj.style.display = 'none';
  }

  fetchLead() {
    let { lead_id } = this.props;
    this.setState({ companies_loading: true })

    request
      .get('/api/request_lead')
      .query({ lead_id })
      .then(({ body: { companies, comments } }) => {
        this.setState({
          companies,
          companies_loading: false,
          pmr_form: {
            ...this.state.pmr_form,
            comments,
          }
        })
      })
  }

  fromHomeCaptain() {
    ['Home Captain', 'Home Captain 2'].includes(this.state.origin)
  }

  isCompassLead() {
    return this.state.partner_id === '67';
  }

  isCincLead() {
    return this.state.partner_id == '50';
  }

  // The number of requal attempts has already been reached. Kill or Match is now the only option available.
  finalAttempt() {
    return this.state.qual_attempt_count >= this.state.max_requal_attempts;
  }

  firstAttempt() {
    return this.state.qual_attempt_count === 0;
  }

  render() {
    return(
      <div id='lead_info_fresh'>
        <Loader events={ this.events } />
        <PreMatchResearchModal
          data        = { this.state }
          updateState = { this.updateState }
          pmrSubmit   = { this.pmrSubmit }
        />
        <LexisNexisModal
          lead_id     = {this.props.lead_id}
          data        = {this.state}
          updateState = {this.updateState}
          requalSubmit= {this.requalSubmit}
          openPCRModal= {this.openPCRModal}
        />
        <Header
          reloadCompanies = { this.reloadCompanies }
          state           = { this.state }
          updateState     = { this.updateState }

          phoneStrategy   = { this.props.phone_strategy }
        />
        <Banner
          state           = { this.state }
          finalAttempt    = { this.finalAttempt() }
          fromHomeCaptain = { this.fromHomeCaptain() }
        />

        <div className='dialer_content'>
          <div className='left_content'>
            {this.renderSubmittingAgent()}
            {this.renderSource()}
            {this.renderLeadTypeToggle()}
            <Scripts data={this.state} />
          </div>
          <div className='right_content'>
            <LeadForm
              events             = { this.events }
              click2CallLink     = { this.click2CallLink }
              country            = { this.props.country }
              state              = { this.state }
              updateState        = { this.updateState }
              directReferral     = { this.props.direct_referral }
              exclusive          = { this.props.exclusive }
              exclusiveAgentId   = { this.props.exclusive_agent_id }
              exclusiveAgentLink = { this.props.exclusive_agent_link }
            />
          </div>
          <div className='clear'></div>
          <NextStep
            requalSubmit        = { this.requalSubmit }
            finalAttempt        = { this.finalAttempt() }
            state               = { this.state }
            submitButtonClick   = { this.submitButtonClick }
            updateState         = { this.updateState }
            isCompassLead       = { this.isCompassLead() }
            isCincLead          = { this.isCincLead() }
            validHCSubmitParams = { validHCSubmitParams(this.state) }
          />
        </div>
        <iframe className='hide' src={ this.state.call_url }></iframe>
        <div className={this.state.loading ? 'qual-loading-screen' : 'hide'}>
          <p>{this.state.loadingMessage}</p>
          <i className='fa fa-refresh fa-spin fa-2x fa-fw'></i>
        </div>
      </div>
    )
  }

  requestCall() {
    if( this.state.pause ) {
      this.quitDialer();
      return false;
    }
    let options = {
      no_prepare: this.state.initial_call,
      lead_id: this.props.lead_id
    };

    request
      .get(`/qual/queue/${this.props.lead_segment}/request_call`)
      .query(options)
      .then(({ body }) => {
        let { lead, email, live, dual_transaction_buyer_lead, stand_in_data } = body;
        let mergedInitState = { ...this.initialState, ...body };
        let mergedInitLive = { ...this.initialState.live, ...live };

        let data = {
          ...mergedInitState,
          initial_call: false,
          initLead: {...lead},
          initEmail: email,
          live: mergedInitLive,
          stand_in_data: stand_in_data,
        }

        if (lead.lead_type == 'buyer' && lead.buyer_cities) {
          data.lead = this.setBuyerCities(lead);
          data.initLead = this.setBuyerCities(lead);
        }

        this.setState(data, () => {
          this.searchQuery();
          this.events.emit('dialer:loaded', { name: data.lead.first_name + ' ' + data.lead.last_name });
          this.onSuccess();
        });

        this.makeCall(data)
        this.fetchLead();
      })
  }

  updateAndReturnLead(data){
    data.initial_call = false;
    data.lead.user_attributes = this.state.lead.user_attributes;
    data = Object.assign(this.state, data);
    data.initLead = Object.assign({}, data.lead);
    data.initEmail = data.email;
    if (data.lead.lead_type == 'buyer') {
      data.lead = this.setBuyerCities(data.lead);
      data.initLead = this.setBuyerCities(data.lead);
    }
    this.setState( data, this.searchQuery);
    return data.lead
  }

  makeCall(data) {
    if (!data.live.live_lead) {
      setTimeout((self, data) => {
        request
          .post(`/qual/queue/call`)
          .query({ lead_id: data.lead.id })
          .then(({ body }) => self.setState(body))
      }, 3000, this, data)
    }
  }

  onSuccess() {
    request.get('/jobs/watching_tab/queue_menu_fresh');
  }

  searchQuery() {
    let { realtor_search, property_search } = buildSearchQuery(this.state);
    this.setState({
      search: realtor_search,
      realtor_search,
      property_search,
    });
  }

  renderSubmittingAgent() {
    if (this.state.live.live_lead && this.state.live.live_disposition === 'submitted_agent') {
      var liveParams = this.state.live;

      return(
        <div>
          <div className='content_header'>
            <i className='fa fa-user'></i>
            Submitting Agent:
          </div>
          <div className='submitting-agent'>
            <img src={liveParams.submitting_agent_photo}/>
            <div className='agent-info'>
              <p className='agent-name'>{ liveParams.submitting_agent_name }</p>
              <p className='realtor-company'>{`${liveParams.submitting_agent_broker_name} - ${liveParams.submitting_agent_company_name}` }</p>
            </div>
          </div>
        </div>
      )
    } else {
      return ''
    }
  }

  renderSource() {
    if (this.state.live.live_lead) {
      return(
        <div>
          <div className='content_header'>
            <i className='fa fa-exclamation-circle'></i>
            Source:
          </div>
          <div>
            { this.state.live.source }
          </div>
        </div>
      )
    } else {
      return ''
    }
  }

  renderLeadTypeToggle() {
    if (!this.state.live.live_lead) return

    return (
      <LeadTypeForm
        data={this.state}
        updateState={this.updateState }
      />
    )
  }

  setBuyerCities(lead) {
    if (!lead.buyer_cities) { return lead }

    lead.buyer_cities.split('&').map( (bc, i) => {
      var buyer_city_state = bc.split(',')
      lead[`buyer_city_${i}`] = buyer_city_state[0];
      lead[`buyer_state_${i}`] = buyer_city_state[1];
    })

    return { ...lead }; // returns duped lead
  }

  _verificationPMR() {
    let { status_button } = this.state;
    status_button.action = 'qual';

    this.setState({ status_button }, this.submitButtonClick);
  }

  firstAttemptHC() {
    let { home_captain, status_button } = this.state;
    return home_captain && status_button.note_status === 'Unknown - Answering Machine/Name';
  }

  submitButtonClick() {
    switch (this.state.status_button.action){
      case 'kill':
        this.killSubmit();
        break;
      case 'qual':
        this.handleQual();
        break;
      case 'requal':
        if (this.firstAttempt()) {
          $('lexis_nexis_modal, .lexis_nexis_overlay').show();
        } else {
          this.requalSubmit();
        }
        break;
      default:
        return;
    }
    window.scrollTo(0, 0);
  }

  handleQual() {
    if (this.validForm()) {
      const { live_lead, return_to_agent } = this.state.live;

      if (live_lead && return_to_agent) {
        this.qualSubmit();
      } else if (isLocationSynced(this.state)) {
        this.show(this.qualModalElements);
      } else {
        this.reloadCompanies();
      }
    }
  }

  openPCRModal() {
    this.show(this.qualModalElements);
  }

  loadNextLead(){
    request
      .get('/qual/queue/live/reserve_next_lead')
      .then(({ body: { lead_id } }) => {
        lead_id
          ? window.location.replace(`/qual/queue/live?lead_id=${lead_id}`)
          : this.waitForNextLead()
      })
  }

  waitForNextLead() {
    this.setState({ loading: true, loadingMessage: 'Waiting for More Leads' });

    let attemptLoadNextLead = setInterval(() => {
      request
        .get('/qual/queue/live/reserve_next_lead')
        .then(({ body: { lead_id } }) => {
          if (lead_id) {
            clearInterval(attemptLoadNextLead);
            window.location.replace(`/qual/queue/live?lead_id=${lead_id}`)
          }
        })
    }, 5000)
  }

  reloadCompanies(){
    let { email, lead } = this.state;
    this.setState({ loading: true, loadingMessage: 'Updating contact info and location' });

    request
      .post('/qual/queue/update_lead')
      .query({ email, lead })
      .then(({ body }) => {
        this.updateAndReturnLead(body);
        this.initializeReloadCompaniesInterval(body);
      })
  }

  // Checks every 3 seconds if geocoding job is complete and reloads companies to block
  initializeReloadCompaniesInterval(body) {
    let { lead_id } = this.props;
    let { initial_sidekiq_job_id } = body;
    let expired = false;

    let reloadCompaniesInterval = setInterval((self) => {
      request
        .get('/qual/queue/reload_companies')
        .query({ lead_id, initial_sidekiq_job_id })
        .then(({ body: { incomplete, companies } }) => {
          if (!incomplete || expired) {
            this.setState({ companies, loading: false, loadingMessage: '' });
            this.show(this.qualModalElements);
            clearInterval(reloadCompaniesInterval);
          }
        })
    }, 3000, this);
  }

  pmrSubmit() {
    if (this.state.pmr_form.is_an_agent) {
      this.setState({
        qual_note: this.state.pmr_form.agent_link,
        status_button: {
          note_status: 'Bad - Agent'
        }
      }, this.killSubmit);
    } else {
      this.qualSubmit();
    }
  }

  lnSearchSubmit() {
    let lexisSearchLoading = setInterval((self) => {
      request
        .get(`/leads/${this.props.lead_id}/accurint_requests?request_type=lead`)
        .query({ lead_id, initial_sidekiq_job_id })
        .then(({ body: { search_data } }) => {
          if (!!search_data) {
            this.setState({ lnData: search_data, loading: false, loadingMessage: '' });
            this.show(this.qualModalElements);
            clearInterval(lexisSearchLoading);
          }
        })
    }, 3000, this);
  }

  handleSuccess() {
    let { live, pause } = this.state;

    window.scrollTo(0, 0);
    window.onbeforeunload = null;
    $('#live-transfer-padding').hide()

    if (pause) {
      this.quitDialer();
      return false;
    }

    if(live.live_lead) {
      this.handleLiveSuccess()
    } else {
      this.events.emit('dialer:submit');
      window.location.reload(true);
    }
  }

  // Ensure live conference component unmounts and hit unmounting callbacks
  handleLiveSuccess(){
    let { live } = this.state;
    live.dismount = true;
    this.setState({ live })
    this.loadNextLead();
  }

  qualSubmit() {
    window.scrollTo(0, 0);
    request
      .post('/qual/queue/submit')
      .query(qualSubmitParams(this.state))
      .then(({ body }) => {
        body.errors
          ? alert(body.errors)
          : this.handleSuccess();
      })
  }

  requalSubmit() {
    request
      .post('/qual/queue/submit')
      .query(requalSubmitParams(this.state))
      .then(({ body }) => {
        body.errors
          ? alert(body.errors)
          : this.handleSuccess();
      })
  }

  killSubmit() {
    request
      .post('/qual/queue/submit')
      .query(killSubmitParams(this.state))
      .then(({ body }) => {
        body.errors
          ? alert(body.errors)
          : this.handleSuccess();
      })
  }

  click2CallLink(phone_number) {
    const { blacklisted } = this.state
    const { id, phone_number_base, extension } = this.state.lead;
    // optionally get phone_number from argument. Fallback to lead in state
    const number = phone_number || phone_number_base || '';

    if (!number || blacklisted) {
      return(<a href={'#'}></a>)
    }

    const prefix = number.startsWith('+') ? '+' : ''
    const encodedNumber = encodeURIComponent(prefix + number.replace(/\D/g, ''))
    const ext = (!!extension) ? `&ext=${extension}` : ''
    const href = `/click2call?call_destination=lead&call_destination_id=${id}&call_type=qual&regionalize=area_code&phone=${encodedNumber}${ext}`;

    return(
      <a
        className='click2call-link'
        data-remote='true'
        rel='nofollow'
        data-method='post'
        href={href}
      >
        <img
          title={`Call ${number}`}
          className='icon'
          src={window.image_path('recall_icon')}
          alt='Telephone go'
        />
      </a>
    )
  }

  updateState( opts ) {
    this.setState( opts );
  }

  changeDisposition() {
    this.setState({ skip_pmr: true })
  }

  validForm() {
    let invalids = [];
    this.formFields.forEach(field => field.classList.remove('error'));

    this.formFields.forEach(field => {
      let optional = field.getAttribute('data-optional');
      if (optional !== 'true' && field.value === '') { invalids.push(field) };
    });

    if(invalids.length > 0) {
      invalids.forEach(field => field.classList.add('error'))
      window.scrollTo(0, 270);
      return false;
    }

    return true;
  }

  pauseDialer( paused ) {
    this.setState({ pause: paused });
  }

  quitDialer() {
    window.onbeforeunload = null;
    window.location.href = '/dialer';
  }
}
