import React, { Component } from 'react';
import { store } from '../../index';

export default class BaseInputContainer extends Component {
  constructor(props) {
    super(props);

    const { property } = props;
    this.property = property;
    this.state = this.getCurrentStateFromStore(this.property);

    this.handleChange = this.handleChange.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
  }

  componentDidMount() {
    this.unsubscribeStore = store.subscribe(this.updateStateFromStore.bind(this));
  }

  componentWillUnmount() {
    this.unsubscribeStore();
  }

  getCurrentStateFromStore(key) {
    const validationStore = store.getState().validations;
    const focusedStore = store.getState().focused;

    const value = store.getState().info.get(key);
    const valid = validationStore.get(key) !== undefined ? validationStore.get(key) : null;
    const focused = focusedStore.get(key) !== undefined ? focusedStore.get(key) : null;

    return {
      value,
      valid,
      focused,
    };
  }

  updateStateFromStore() {
    const currentState = this.getCurrentStateFromStore(this.property);

    if (this.state !== currentState) {
      this.setState(currentState);
    }
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.validateField(name, value);
    this.setInfoField(name, value);
  }

  validateField(name, value) {
    const { dispatch } = store;

    dispatch({
      type: 'VALIDATE_FIELD',
      data: {
        key: name,
        value,
      }
    });
  }

  handleOnFocus(e) {
    const { name, value } = e.target;
    const { dispatch } = store;

    dispatch({
      type: 'FIELD_FOCUSED',
      data: {
        key:  name,
      },
    });
  }

  setInfoField(key, value) {
    const { dispatch } = store;

    dispatch({
      type: 'SET_INFO_FIELD',
      data: { key, value }
    });
  }
}
