import { Map } from 'immutable';

const initialState = Map({
  calling: false,
  disposition: '',
  killOptions: null,
  matchingOptions: null,
  qualOptions: null,
  submitted: false,
  submitting: false,
  value: '',
});

const actionsMap = {
  ['SET_ACTIONS']: (state, action) => {
    const {
      home_captain,
      intent,
      lead,
      live,
      origin,
      partner_id,
      qual_attempt_count,
      whitelabeled,
    } = action.data;

    const { lead_type, origination } = lead;
    const { live_lead } = live;

    const matchingOptions = {
      personal: [
        'Contacted',
        'Answering Machine/Name',
        'Phone/Address Records Match',
        'Passes Qualification'
      ],
      good: [
        'Contacted'
      ],
    };

    if (home_captain && qual_attempt_count >= 2) {
      matchingOptions.good.push('Assume Intent');
    }

    if (lead.lead_type === 'seller' && (lead.origination === 'Microsite' || lead.origination === 'Organic')) {
      matchingOptions.personal.push('Bad Number Only Seller (GGGB)');
    }

    const matchingChoice = (origin !== 'Home Captain' || !intent) ? 'good' : 'personal';
    const matching = matchingOptions[matchingChoice];


    // kill
    const kill = [
      'Agent',
      'Client has Already Transacted',
      'Currently Working with an Agent',
      'Duplicate Submission',
      'Junk Info',
      'Max Requal Attempts',
      'No Current Real Estate Needs',
    ];

    // 'Already Our Client',
    // "Don't Want to be Matched",

    if (whitelabeled && partner_id !== '50') {
      // This option should not exist for leads created by agents from whitelabeled partners.
      var idx = kill.indexOf('Max Requal Attempts');
      if (idx > -1) {
        kill.splice(idx, 1);
      }
    }


    // qual
    const qual = [
      'Other'
    ];

    if (!live_lead) {
      qual.unshift(
        'Voicemail/No Name',
        'No Answer',
        'Voicemail/Different Name',
      );
    }

    if (!intent && !live_lead) {
      qual.splice(0, 0, 'Voicemail/Name');
    }

    return state.merge(Map({
      killOptions: kill,
      matchingOptions: matching,
      qualOptions: qual,
    }));
  },
  ['SET_DISPOSITION']: (state, action) => {
    const { disposition, value } = action.data;
    return state.merge(Map({
      disposition,
      value,
    }));
  },
  ['INITIATING_CALL']: (state) => {
    return state.merge(Map({
      calling: true,
    }));
  },
  ['SUBMIT']: (state, actions) => {
    return state.merge(Map({
      submitted: false,
      submitting: true,
    }));
  },
  ['SUBMISSION_SUCCESS']: (state) => {
    return state.merge(Map({
      submitted: true,
    }));
  },
  ['SUBMISSION_FAILURE']: (state) => {
    return state.merge(Map({
      submitted: false,
      submitting: false,
    }));
  },
  ['RESET_SUBMISSIONS']: (state) => {
    return state.merge(Map({
      disposition: '',
      submitted: false,
      submitting: false,
      value: '',
    }));
  },
  ['RESET_ACTIONS']: (state) => {
    return state.merge(initialState);
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
