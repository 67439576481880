import React from 'react';

import StandardInput from '../inputs/StandardInput';
import CityInput from '../inputs/StandardInput/city';
import TypeHomeSelect from '../inputs/Select/typeHome';
import StateSelect from '../inputs/Select/state';
import ZipInput from '../inputs/StandardInput/zip';
import PriceInput from '../inputs/NumberInput/prices';

import GoogleSearch from '../GoogleSearch/sellers';

const searchProperties = [
  'street_number',
  'street_name',  
  'unit_number',
  'city',
  'state',
  'zip'
];

const SellerFields = () => {
  return (
    <div>
      <div className='info-row'>
        <StandardInput
          classNames='street-number yellow'
          display='Street Number'
          property='street_number'
        />

        <StandardInput
          classNames='street-name yellow'
          display='Street Name'
          property='street_name'
        />

        <StandardInput
          classNames='address-unit'
          display='Unit'
          property='unit_number'
        />
      </div>

      <div className='info-row'>
        <CityInput
          display='City'
          property='city'
        />

        <StateSelect
          property='state'
        />

        <ZipInput
          property='zip'
        />

        <GoogleSearch
          properties={ searchProperties }
        />
      </div>

      <div className='info-row'>
        <PriceInput
          classNames='quarter'
          display='Min Price'
          property='home_price_min'
        />

        <PriceInput
          classNames='quarter'
          display='Max Price'
          property='home_price_max'
        />

        <TypeHomeSelect
          display='Property Type'
          property='type_home'
        />
      </div>
    </div>
  );
};

export default SellerFields;
