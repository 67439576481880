const ToolSelector = ({ openTool, item }) => {
  const { title, icon } = item;

  return (
    <div className='dashboard-selector clickable' onClick={ openTool }>
      <div className='row'>
        <div className='col-md-11 col-sm-11 col-xs-10'>
          <span className='item-icon'>
            <img src={ icon } />
          </span>
          <span className='item-title'>{title}</span>
        </div>
        <div className='col-md-1 col-sm-1 col-xs-2 text-right'>
          <i className='fa fa-chevron-right'></i>
        </div>
      </div>
    </div>
  );
};

export default ToolSelector;
