import { Component } from 'react';

export default class Invite extends Component {
  constructor(props) {
    super(props)
  }

  isInviteListPopulated() {
    let inviteList = this.props.state.userInvitations;
    return inviteList && Object.keys(inviteList).length;
  }

  inviteList() {
    let inviteList = this.props.state.userInvitations;
    return Object.keys(inviteList).map((id, idx) => {
      let invitation = inviteList[id]
      return (
        <div className='list-item-centered' key={idx}>
          <div className="list-item-centered-detail w250">Email: {invitation.email}</div>
        </div>
      )
    })
  }

  emptyList() {
    return (
      <div className='list-item-centered'>
        <div className="list-item-centered-detail w1000 message">Invitations Empty</div>
      </div>
    )
  }

  render() {
    let { klass, state, updateState, submitInviteEmail } = this.props;
    return (
      <div className={klass}>
        <div className='toolbar'>
          <input
            type='email'
            className='email-input'
            placeholder='Enter Email...'
            value={state.emailInviteInput}
            onChange={(e) => updateState({ emailInviteInput: e.currentTarget.value })}
          />
          <input
            type='submit'
            className='add-button-large'
            onClick={submitInviteEmail}
            value='Invite'
          />
        </div>
        <div className='tab-content'>
          <div className='list h280'>
            <div className='list-inner'>
              {this.isInviteListPopulated() ? this.inviteList() : this.emptyList()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
