import React, { useState, useEffect } from 'react';

const Modal = props => {
  const {
    moderationResultOptions,
    reviewId,
    validateReview,
  } = props;

  const [reported, setReported] = useState(false);
  const [reason, setReason] = useState('');
  const [error, toggleError] = useState('');
  const [overlay, toggleOverlay] = useState('');

  useEffect(() => {
    $('#report-agent-profile-review').on('hidden.bs.modal', resetModal);
    document.body.addEventListener('review_moderation.modal_open', handleModalOpen);
  }, []);

  const handleModalOpen = event => {
    $('#report-agent-profile-review').modal('show');
  };

  const handleReasonChange = event => {
    const { value } = event.currentTarget;

    setReason(value);
  };

  const resetModal = () => {
    setReported(false);
    setReason('');
    toggleError('');
    toggleOverlay('');
  };

  const buildOptions = () => {
    const opts = [];

    opts.push(<option key="_blank" value="">- Reason -</option>);

    moderationResultOptions.forEach(option => {
      const [displayText, value] = option;

      opts.push(<option key={ value } value={ value }>{ displayText }</option>);
    });

    return opts;
  };

  const toggleModalIcon = () => {
    const iconKlasses = reported ? 'fa-check success' : 'fa-exclamation warn';

    return <i className={ `fa ${iconKlasses} header-icon` } aria-hidden="true"></i>;
  };

  const toggleModalBody = () => {
    if (reported) {
      return (
        <div className="text-center mb-10">
          <h3 className="p-0">This review has been marked invalid.</h3>
        </div>
      );
    } else {
      const showError = !!error ? 'show' : 'hide';
      const errorKlass = !!error ? 'has-error' : '';

      return (
        <>
          <div className="text-center mb-10">
            <h3 className="p-0">Please choose a reason for invalidating this review.</h3>
          </div>
          <div className={ `form-group ${errorKlass}` }>
            <select className="form-control" name="profile_review[reason]" id="profile_review_reason" value={ reason } onChange={ handleReasonChange }>
              { buildOptions() }
            </select>
            <div className={ `error-text ${showError}` }>{ error }</div>
          </div>
        </>
      );
    }
  };

  const toggleModalFooter = () => {
    if (reported) {
      return <button type="button" className="btn btn-wide btn-rex-orange" data-dismiss="modal">OK</button>;
    } else {
      return (
        <>
          <button type="button" className="btn btn-wide btn-rex-default mr-10" data-dismiss="modal">CANCEL</button>
          <button type="button" className="btn btn-wide btn-rex-orange" onClick={ handleSubmit }>REPORT</button>
        </>
      );
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    toggleError('');
    toggleOverlay('overlay');

    if (!!reason) {
      const payload = { review_id: reviewId, validated: false, moderation_result: reason }

      validateReview(payload);
      toggleOverlay('');
      setReported(true);
    } else {
      toggleError('Required field');
      toggleOverlay('');
    }
  };

  return (
    <div id="report-agent-profile-review" className="modal fade" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className={ `modal-content ${overlay}` }>
          <div className="modal-header text-center">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            { toggleModalIcon() }
          </div>
          <div className="modal-body">
            { toggleModalBody() }
          </div>
          <div className="modal-footer text-center pt-0">
            { toggleModalFooter() }
          </div>
        </div>
      </div>
    </div>
  )
};

export default Modal;
