import React from 'react';
import Success from '../LiveAccountManagement/images/success.png';

const ProcessRefundSuccess = (props) => {
  const {
    finished,
  } = props;

  let finishedHeader = 'REFUND SUCCESSFUL';
  let finishedSubheadings = [
    'If you selected a full refund, the agent has already been disabled / blocked from LIVE.',
    'If you selected a partial refund, the agent still has access to LIVE.'
  ];

  return (
    <div className='process-refund'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ Success } />
      </div>
      <div className='rex-modal-heading'>
        { finishedHeader }
      </div>
      <div className='rex-modal-contents'>
        <div className='rex-modal-subheading '>
          <ul>
            {
              finishedSubheadings.map((subheading, key) => (
                <li key={ key }>{ subheading }</li>
              ))
            }
          </ul>
        </div>
        <div className='rex-modal-actions rex-modal-actions__centered'>
          <button
            className='rex-modal-action rex-modal-actions__centered'
            onClick={ finished }
          >
            ClOSE THIS WINDOW
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProcessRefundSuccess;
