import ManagementIcon from '../../../../LiveAccountManagement/images/live_management.png';

// make sure to keep this list up to date with all Prime rex_product_offerings
const subscriptionOptions = [
  // older plans and trial plans
  { id: '12', name: '2020_may_gold ($99 USD / month)',                                currency: 'usd', canUpgradeTo: false },
  { id: '13', name: 'Semiannual ($594 USD / 6 months)',                               currency: 'usd', canUpgradeTo: false },
  { id: '14', name: 'Monthly ($99 USD / month, 6 mos commit recurring)',              currency: 'usd', canUpgradeTo: false },
  { id: '15', name: 'Semiannual ($774 CAD / 6 months)',                               currency: 'cad', canUpgradeTo: false },
  { id: '16', name: 'Monthly ($129 CAD / month, 6 mos commit recurring)',             currency: 'cad', canUpgradeTo: false },
  { id: '17', name: 'Pre-PRIME ($500 USD one-time charge)',                           currency: 'usd', canUpgradeTo: false },
  { id: '24', name: '1 month free trial, auto-upgrade to Monthly ($99 USD / month)',  currency: 'usd', canUpgradeTo: false },
  { id: '25', name: '1 month free trial, auto-upgrade to Monthly ($129 CAD / month)', currency: 'cad', canUpgradeTo: false },

  // current plans agents can be upgraded to
  { id: '18', name: 'Monthly ($99 USD / month, 12 mos commit recurring) ',            currency: 'usd', canUpgradeTo: true },
  { id: '21', name: 'Monthly ($129 CAD / month, 12 mos commit recurring)',            currency: 'cad', canUpgradeTo: true },
  { id: '22', name: 'Semiannual ($594 USD / 6 months, 12 mos commit recurring)',      currency: 'usd', canUpgradeTo: true },
  { id: '23', name: 'Semiannual ($774 CAD / 6 months, 12 mos commit recurring)',      currency: 'cad', canUpgradeTo: true },
  { id: '19', name: 'Annual ($1089 USD / year)',                                      currency: 'usd', canUpgradeTo: true },
  { id: '20', name: 'Annual ($1419 CAD / year)',                                      currency: 'cad', canUpgradeTo: true },
];

const subscriptionText = (productId) => {
  if (productId) {
    return subscriptionOptions.find(option => option.id === productId).name;
  }

  return 'None';
};

const countryFromCurrency = (currency) => {
  switch (currency) {
    case 'usd':
      return 'US';
    case 'cad':
      return 'CA';
  }
}

const Change = (props) => {
  const {
    moveTo,
    toggleModal,
    newProductOfferingId,
    currentProductOfferingId,
    country,
    setAttribute,
  } = props;

  const handleChange = (e) => {
    const { value } = e.currentTarget;

    setAttribute('newProductOfferingId', value);
  }

  return (
    <div id='enable-warning'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ManagementIcon} />
      </div>

      <div className='rex-modal-heading'>
        PRIME Account Management: Change Subscription Package
      </div>

      <div className='rex-modal-contents'>
        <div className='input-wrapper'>
          <label htmlFor="current-prime">
            <span>Current Package</span>
            <input
              type="text"
              name="current-prime"
              id="current-prime"
              value={subscriptionText(currentProductOfferingId)}
              disabled
            />
          </label>
          <label htmlFor="new-prime">
            <span>Change Package</span>
            <select
              name="new-prime"
              value={newProductOfferingId}
              onChange={handleChange}
            >
              <option value="" aria-label="blank">Select Option</option>
              {subscriptionOptions.map((option) => {
                return option.id !== currentProductOfferingId &&
                  option.canUpgradeTo &&
                  countryFromCurrency(option.currency) === country && (
                    <option
                      value={option.id}
                      aria-label="blank"
                    >
                      {option.name}
                    </option>
                  )
              })}
            </select>
          </label>
        </div>

        <div className='rex-modal-actions'>
          <button
            className='rex-modal-action rex-modal-action__loading'
            onClick={() => moveTo('warning')}
            disabled={newProductOfferingId.length < 1}
          >
            CONFIRM
          </button>
          <button
            className='rex-modal-action'
            onClick={() => toggleModal(false)}
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  )
};

export default Change;
