import { Map } from 'immutable';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { cityState } from '../utils';

const initialState = Map({
  baths: '',
  beds: '',
  buyer_cities: '',
  buyer_city_0: '',
  buyer_state_0: '',
  city: '',
  comments: '',
  contact_date: '',
  contact_time: '',
  country: '',
  email_address: '',
  first_name: '',
  home_price_min: '',
  home_price_max: '',
  id: '',
  last_name: '',
  lead_type: '',
  mortgage_status: '',
  opt_out: '',
  phone_number: '',
  phone_number_ext: '',
  state: '',
  street_name: '',
  street_number: '',
  text_consent: '',
  type_home: '',
  unit_number: '',
  zip: '',
});

const actionsMap = {
  ['SET_INFO']: (st, action) => {
    const { email, lead } = action.data;

    const {
      baths,
      beds,
      buyer_cities,
      buyer_city_0,
      buyer_state_0,
      city,
      comments,
      country,
      first_name,
      home_price_min,
      home_price_max,
      id,
      last_name,
      lead_type,
      mortgage_status,
      opt_out,
      phone_number,
      phone_number_ext,
      state,
      street_name,
      street_number,
      type_home,
      unit_number,
      zip,
    } = lead;

    return st.merge(Map({
      baths,
      beds,
      buyer_cities,
      buyer_city_0,
      buyer_state_0,
      city,
      comments,
      country,
      email_address: email,
      first_name,
      home_price_min,
      home_price_max,
      id,
      last_name,
      lead_type,
      mortgage_status,
      opt_out,
      phone_number,
      phone_number_ext,
      state,
      street_name,
      street_number,
      type_home,
      unit_number,
      zip,
    }));
  },
  ['SET_LIMITED_LEAD_INFO_DATA']: (state, action) => {
    const { lead_first_name, lead_last_name } = action.customAttributes;
    return state.merge(Map({
      first_name: lead_first_name,
      last_name: lead_last_name,
    }));
  },
  ['SET_INFO_FIELD']: (state, action) => {
    const { key, value } = action.data;
    return state.merge(Map({
      ...state,
      [key]: value,
    }));
  },
  ['COUNTRY_CHANGE']: (state, action) => {
    const bc = state.get('buyer_cities');
    const updated = bc.map(p => ({
      city: p.city,
      state: ''
    }));
    return state.merge(Map({
      ...state,
      buyer_cities: updated,
      state: '',
    }));
  },
  ['ADD_BUYER_CITY']: (state, action) => {
    const { data, index } = action;
    const buyer_cities = state.get('buyer_cities');

    buyer_cities[index] = {
      ...buyer_cities[index],
      ...data,
    };

    return state.merge(Map({
      ...state,
      buyer_cities,
    }));
  },
  ['ADD_BUYER_CITY_FIELD']: (state, actions) => {
    const buyer_cities = state.get('buyer_cities');

    if (buyer_cities.length >= 4) {
      return state.merge(Map({
        ...state,
      }));
    }
    buyer_cities.push(cityState());
    return state.merge(Map({
      ...state,
      buyer_cities,
    }));
  },
  ['REMOVE_BUYER_CITY_FIELD']: (state, action) => {
    const { index } = action;
    const buyer_cities = state.get('buyer_cities');
    buyer_cities.splice(index, 1);

    return state.merge(Map({
      ...state,
      buyer_cities,
    }));
  },
  ['SET_FOCUSED_REQ_FIELDS']: (state, action) => {
    return state.merge(Map({
      ...state,
      hasFocusedRequiredFields: action.data
    }))
  },
  ['SET_FROM_RAW_LOCATION']: (state, action) => {
    const { rawAddressFields } = action.data;
    const current = Object.keys(rawAddressFields).reduce((acc, field) => {
      const lead_type = state.get('lead_type');
      const val = rawAddressFields[field];
      if (['city', 'state'].includes(field) && lead_type !== 'seller') {
        if (!acc.buyer_cities) {
          acc.buyer_cities = [{ city: null, state: null }];
        }
        acc.buyer_cities[0][field] = val;
      } else {
        acc[field] = val;
      }
      return acc;
    }, {});

    return state.merge(Map({
      ...state,
      ...current
    }));
  },
  ['RESET_INFO']: (state) => {
    return state.merge(initialState);
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
