import { Component }           from 'react';
import AppointmentDateSelector from './AppointmentDateSelector';
import AppointmentTimeSelector from './AppointmentTimeSelector';
import AppointmentSlots        from './AppointmentSlots';

export default class CustomerSuccessBookAppointment extends Component {
  constructor(props){
    super(props);
    let date = new Date;
    this.state = {
      maxDayRange: 7,
      calendar: null,
      activeDatetime: date,
      activeDate: Util.MMDDYYYY(),
      activeMonth: FormHelper.monthShort(date.getMonth() + 1),
      activeYear: date.getFullYear(),
      timeFilter: '',
      selectedSlot: '',
      selectedOffsetTime: '',
      confirmNotice: false,
      weekdays: {},
      progress: 'initiation'
    }
    this.updateDate = this.updateDate.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.selectSlot = this.selectSlot.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setWeekdays = this.setWeekdays.bind(this);
  }

  componentDidMount(){
    if(this.props.welcomeCallCompleted){
      this.setState({ progress: 'terminated' })
    } else {
      let params = { weekday: this.state.activeDate};
      this.updateCalendar(params);
      this.setWeekdays();
    }
  }

  // function removes Saturdays and Sundays and saves the date value with
  // the parsed date string as an array.
  setWeekdays(){
    let range = this.state.maxDayRange || 7;
    let weekdays = {};
    let adjustedDate, displayDate, dateInt, datetime, date;

    for (let i = 0; i <= range; i++) {
      datetime = new Date;
      dateInt = (datetime.getDay() + i) % 7;
      if(this.isWeekDay(dateInt)){
        adjustedDate = new Date(datetime.setDate(datetime.getDate() + i));
        date = Util.MMDDYYYY(adjustedDate);
        displayDate = `${FormHelper.weekday(dateInt)} ${date}`
        weekdays[date] = { displayDate: displayDate, adjustedDate: adjustedDate }
      }
    }
    this.setState({ weekdays });
  }

  isWeekDay(weekdayInt){
    return weekdayInt > 0 && weekdayInt < 6
  }

  updateCalendar(params){
    Util.getCustomerSuccessSchedule(params)
      .then(result => this.setState({ calendar: result.calendar, selectedSlot: '' }))
  }

  headerContent(){
    let { activeDay, activeMonth, activeYear, activeDate, maxDayRange } = this.state;
    let currentDate = new Date();
    let maxRangeDate = new Date();
    maxRangeDate.setDate(currentDate.getDate() + maxDayRange);
    let maxRangeMonth = FormHelper.monthShort(maxRangeDate.getMonth() + 1);
    let maxRangeDay = maxRangeDate.getDate();
    return `Appointments for ${activeMonth} ${currentDate.getDate()} - ${maxRangeMonth} ${maxRangeDay} ${activeYear}`
  }

  selectSlot(selectedSlot, selectedOffsetTime){
    this.setState({ selectedSlot, selectedOffsetTime })
  }

  updateDate(date){
    let newDate = Util.MMDDYYYY(date);
    let params = { weekday: newDate};
    this.updateCalendar(params);
    this.setState({
      activeDate: newDate,
      activeDatetime: date
    });
  }

  updateTime(time){
    this.setState({ timeFilter: time })
  }

  handleSubmit(){
    let { activeDate, timeZone, selectedSlot, confirmNotice } = this.state;
    let { welcomeCallTask, agentId } = this.props;

    selectedSlot = selectedSlot.replace(/\s/g, '')
    if(selectedSlot === '' || !confirmNotice) return;
    let dateTime = activeDate + ' ' + selectedSlot;
    let appointmentTime = Util.parseDateTime(dateTime);

    let appointmentParams = {
      completed_task: {},
      new_task: {
        taskable_id: agentId,
        follow_up: appointmentTime
      },
      note: {
        message: `Appointment scheduled by agent for ${appointmentTime}`,
        noteable_id: agentId
      }
    };
    if(welcomeCallTask) appointmentParams.completed_task.task_id = welcomeCallTask.id;

    Util.welcomeCallAppointmentScheduled(appointmentParams)
      .then((result) => {
        if(result.error){
          this.setState({ progress: 'terminated'});
        } else {
          this.setState({ progress: 'finalized' });
        }
      })
  }

  render(){
    let { activeDatetime, activeMonth, activeYear, activeDate, calendar, timeFilter, selectedSlot, selectedOffsetTime, progress, weekdays } = this.state;
    let { agentId, user, timeZone } = this.props;
    let phone = user.phone_mobile || user.phone_office;
    let selectionBlock = () => {
      if(progress === 'initiation') {
        return(
          <div>
            <div className='cs-appt-header'>
              { this.headerContent() }
            </div>
            <AppointmentDateSelector
              activeDate={activeDate}
              activeDatetime={activeDatetime}
              weekdays={weekdays}
              updateDate={this.updateDate}
              setWeekdays={this.setWeekdays}

            />
            <AppointmentTimeSelector
              updateTime={this.updateTime}
            />
            <AppointmentSlots
              calendar={calendar}
              timeFilter={timeFilter}
              selectSlot={this.selectSlot}
              selectedSlot={selectedSlot}
              timeZone={this.props.timeZone}
              welcomeTeamSchedule={this.props.welcomeTeamSchedule}
            />
            <div className='cs-appt-submit'>
              <input
                type='submit'
                value='Submit'
                onClick={() => this.setState({ progress: 'confirmation' }) }
              />
            </div>
          </div>
        )
      } else if(progress === 'confirmation') {
        return(
          <div>
            <div className='cs-appt-header'>
              Review Information
            </div>
            <div className='cs-appt-info-block'>
              <div className='title'>Time</div>
              <div className='content'>
                {`${selectedOffsetTime} ${timeZone.time_zone_short} ${activeDate}`}
              </div>
            </div>
            <div className='cs-appt-info-block'>
              <div className='title'>Your Information</div>
              <div className='content'>
                <div className='content-type'>Name: &nbsp;</div>
                { user.name }
              </div>
              <div className='content'>
                <div className='content-type'>Phone: &nbsp;</div>
                { Util.formatPhoneNumber(phone) }
              </div>
              <div className='content'>
                <div className='content-type'>Email: &nbsp;</div>
                { user.email }
              </div>
            </div>
            <div className='cs-appt-info-block'>
              <div className='title'>Additional Information</div>
              <div className='content'>
                <input type='checkbox'
                  onChange={() => this.setState({confirmNotice: !this.state.confirmNotice })}
                  value={this.state.confirmNotice}
                />
                  &nbsp;&nbsp;Confirm you will be in front of a computer (required)!
              </div>
            </div>
            <div className='cs-appt-submit'>
              <input
                type='submit'
                value='Submit'
                onClick={this.handleSubmit}
              />
            </div>
          </div>
        )
      } else if(progress === 'finalized'){

        return(
          <div>
            <div className='cs-appt-header'>
              Appointment Confirmed
            </div>
            <div className='cs-appt-submitted'>
              Thank you for scheduling your welcome appointment. <br/><br/>
              {`${selectedOffsetTime} ${timeZone.time_zone_short} ${activeDate}`}
            </div>
          </div>
        )
      } else if(progress === 'terminated'){
        return(
          <div>
            <div className='cs-appt-header'>
              Appointment Already Completed
            </div>
            <div className='cs-appt-submitted'>
              Our records have shown that this appointment has already been completed.
              <br/>
              Please reach out to our customer support team if you believe you received this message in error.
              <br/>
              (415) 983-3950
              <br/>
            </div>
          </div>
        )
      }
    }
    return(
      <div className='cs-appt-content'>
        { selectionBlock() }
      </div>
    )
  }
}
