import AlertIcon from '../../../../LiveAccountManagement/images/alert.png';

const _textBody = (pastEndDate) => {
  if (pastEndDate) {
     return <p className='center'>Selecting "REACTIVATE PRIME" will immediately activate a new six-month<br /> PRIME subscription plan for the agent. Upon activation, the agent will be immediately<br /> charged $99.00, and their plan end-date and billing date will be reset.</p>;
  } else {
    return <p className='center'>Selecting "REACTIVATE PRIME" will immediately reactivate the agent's current<br /> PRIME subscription plan. Their plan end-date and billing date will remain the same.</p>;
  }
};

const _submitReactivation = (event, props) => {
  const {
    action,
    toggleButtons,
    moveTo,
    primeSubscriptionPath,
    setAndGo,
    setAttribute,
  } = props;

  toggleButtons(event, 'disable');

  fetch(
    primeSubscriptionPath,
    {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ action: action }),
    }
  )
  .then(response => response.json())
  .then(response => {
    if (response.success) {
      setAttribute('planCancelled', false);
      moveTo('success');
    } else {
      throw new Error(response.errors.join(', '));
    }
  })
  .catch((error) => {
    setAndGo('error', error.message, 'fail');
  });
};

const Warning = (props) => {
  const {
    moveTo,
    pastEndDate,
    toggleModal,
  } = props;

  return (
    <div id='reactivate-warning'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ AlertIcon } />
      </div>

      <div className='rex-modal-heading'>
        Please Read and Proceed with Caution
      </div>

      <div className='rex-modal-contents'>
        <div className='rex-modal-subheading'>
          { _textBody(pastEndDate) }
        </div>

        <div className='rex-modal-actions'>
          <button
            className='rex-modal-action'
            onClick={ () => toggleModal(false) }
          >
            KEEP ACCOUNT CANCELLED
          </button>

          <button
            className='rex-modal-action rex-modal-action__loading'
            onClick={ (e) => _submitReactivation(e, props) }
          >
            REACTIVATE PRIME
          </button>
        </div>
      </div>
    </div>
  )
};

export default Warning;
