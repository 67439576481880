import { Component } from 'react';

export default class NewTaskModal extends Component {
  constructor(props){
    super(props)
    this.state = {
      assignees: [],
      taskTypes: [],
      taskMessage: '',
      assignee: props.currentUser.id,
      taskType: '',
      followUp: '',
      sixAmButtonSelected: false,
      errors: {
        assignee: false,
        taskType: false,
        followUp: false,
        taskMessage: false,
      }
    }

    this.assignToDropDown = this.assignToDropDown.bind(this);
    this.taskTypeDropDown = this.taskTypeDropDown.bind(this);
    this.createTask = this.createTask.bind(this);
    this.changeMessage = this.changeMessage.bind(this);
    this.changeFollowUp = this.changeFollowUp.bind(this);
    this.changeAssignee = this.changeAssignee.bind(this);
    this.changeTaskType = this.changeTaskType.bind(this);
  }

  createTask(){
    let { errors, assignee, taskType, followUp, taskMessage } = this.state;
    let { agentId, currentUser, toggleNewTask } = this.props
    if(this.containsErrors()) return;

    let params = {
      task: {
        task_type: taskType,
        message: taskMessage,
        assigned_to: assignee,
        taskable_id: agentId,
        taskable_type: 'Agent',
        creator_id: currentUser.id,
        follow_up: Util.parseDateTime(followUp)
      },
      note: {
        message: `${taskType} created. ${taskMessage}.`,
        noteable_id: agentId,
        noteable_type: 'Agent',
        creator_id: currentUser.id
      }
    }
    $.post('/customer_success/create/task', params);
    toggleNewTask();

  }

  componentDidMount(){
    Util.getAssignees()
      .then(result => this.setState({ assignees: result.assignees }));

    $('#follow_up_call_later_date').attachLinkedDateTimePicker('#call_later_days', 30);
    $('.ui-datepicker-trigger').hide();
    $(document).on('valueChange', '#follow_up_call_later_date', () => {
      this.changeFollowUp($('#follow_up_call_later_date').val());
    });
  }

  componentWillUnmount(){
    $(document).off('valueChange', '#follow_up_call_later_date', () => {
      this.changeFollowUp($('#follow_up_call_later_date').val());
    });
  }

  changeMessage(e){
    this.setState({ taskMessage: e.currentTarget.value })
  }

  assignToDropDown(){
    let { assignees, errors } = this.state;
    return(
      <div>
        <select
          value={this.state.assignee}
          className={errors.assignee ? 'task-error' : ''}
          onChange={(e) => this.changeAssignee(e.currentTarget.value)}
        >
          {assignees.map((assignee, idx) => {
            return(
              <option key={assignee.user_id} value={assignee.user_id}>
                { assignee.user_name }
              </option>
            )
          })}
        </select>
      </div>
    )
  }

  taskTypeDropDown(){
    let { taskTypes, errors } = this.state;
    return(
      <select
        className={errors.taskType ? 'task-error' : ''}
        onChange={(e) => this.changeTaskType(e.currentTarget.value)}
      >
        {taskTypes.map((type, idx) => {
          return (
            <option key={idx} value={type}>{ type } </option>
          )
        })}
      </select>
    )
  }

  daysDropDown(){
    let parseTime = (e) => {
      let number = e.currentTarget.value
      if(number === '') return;
      let additionalDays = parseInt(number);
      let date = new Date();
      date.setDate(date.getDate() + additionalDays);
      return date.toLocaleDateString('en-US') + " 12:00PM"
    }
    return(
      <select id='call_later_days' onChange={(e) => this.changeFollowUp(parseTime(e))}>
        <option value=''></option>
        <option value='1'>1</option>
        <option value='2'>2</option>
        <option value='3'>3</option>
        <option value='4'>4</option>
        <option value='5'>5</option>
        <option value='6'>6</option>
        <option value='7'>7</option>
        <option value='8'>8</option>
        <option value='9'>9</option>
        <option value='10'>10</option>
      </select>
    )
  }

  changeFollowUp(followUp){
    if(followUp === undefined) return;
    this.setState({ followUp: followUp, sixAmButtonSelected: false });
  }

  changeAssignee(assignee){
    if(assignee === undefined) return;
    this.setState({ assignee });
  }

  changeTaskType(taskType){
    if(taskType === undefined) return;
    this.setState({ taskType });
  }

  selectTomorrowAtSix(tomorrowAtSix){
    this.changeFollowUp(tomorrowAtSix);
    this.setState({ sixAmButtonSelected: true });
  }

  containsErrors(){
    let { assignee, taskType, followUp, taskMessage } = this.state;
    let assigneeError = assignee === '' ? true : false;
    let followUpError = followUp === '' ? true : false;
    let taskMessageError = taskMessage === '' ? true : false;
    let taskTypeError = (taskType === '' || taskType === ' ') ? true : false;
    this.setState({
      errors: {
        assignee: assigneeError,
        taskType: taskTypeError,
        followUp: followUpError,
        taskMessage: taskMessageError
      }
    })

    if(assigneeError || taskTypeError || followUpError || taskMessageError) {
      return true;
    } else {
      return false;
    }
  }

  preventBubbling(e){
    e.stopPropagation()
  }

  render(){
    let tomorrowAtSix = new Date();
    tomorrowAtSix.setDate(tomorrowAtSix.getDate() + 1);
    tomorrowAtSix = tomorrowAtSix.toLocaleDateString('en-US') + " 6:00AM"

    let { user, agentId, toggleNewTask } = this.props;
    let { sixAmButtonSelected } = this.state;
    return(
      <div className='cs-new-task' onClick={toggleNewTask}>
        <div className='cs-new-task-container' onClick={this.preventBubbling}>
          <div className='title'>
            {`New Task for: Agent #${agentId} (${user.first_name})`}
          </div>
          <div className='contents'>
            <div className='dropdowns'>
              <div className='dropdown'>
                <label>Assign to</label>
                { this.assignToDropDown() }
              </div>
              <div className='dropdown'>
                <label>Type</label>
                { this.taskTypeDropDown() }
              </div>
              <div className={this.state.errors.followUp ? 'task-error' : ''}>
                <label>Follow Up</label>
                <div className='follow-up-menu'>
                  <input
                    id='follow_up_call_later_date'
                    type='input'
                    onChange={(e) => this.changeFollowUp(e.currentTarget.value)}
                  />
                  or
                  { this.daysDropDown() }
                  or
                  <input type='button'
                    value='6am tomorrow'
                    className ={sixAmButtonSelected ? 'selected' : ''}
                    onClick={() => this.selectTomorrowAtSix(tomorrowAtSix)}
                  />
                </div>
              </div>
            </div>
            <textarea
              className={this.state.errors.taskMessage ? 'textarea task-error' : 'textarea'}
              onChange={this.changeMessage}
              value={this.state.taskMessage}
            />
          </div>
          <div className='footer'>
            <div
              className='cs-task-button'
              type='button'
              onClick={this.props.toggleNewTask}
            >
              Close
            </div>
            <div
              className='cs-task-button'
              type='button'
              onClick={this.createTask}
            >
              Create
            </div>
          </div>
        </div>
      </div>
    )
  }
}
