import { Component } from 'react';

import ScriptIntroduction from './ScriptIntroduction';
import ScriptQuestions from './ScriptQuestions';
import ScriptClosing from './ScriptClosing';

export default class Scripts extends Component {
  constructor( props ) {
    super( props );
    this.scripts = {};
    this.script = {
      introduction: {},
      questions: {},
      closing: {}
    };

    this.showLeadToLeadModal = this.showLeadToLeadModal.bind(this);
  }

  showLeadToLeadModal() {
    const modal = document.getElementById('new-lead-from-lead-modal');

    if (modal) {
      modal.classList.remove('hidden');
    }
  }

  componentWillReceiveProps( nextProps ) {
    var bc = `${nextProps.data.lead.buyer_city_0}, ${nextProps.data.lead.buyer_state_0}`;

    this.partnerScripts = {
      '31': {
        buyer: {
          introduction: {
            title: 'introduction',
            greeting: `Hi, <strong>${nextProps.data.lead.first_name}</strong>?`,
            copy: `"Hey there, this is ${nextProps.data.user_name} with TopAgentsRanked, calling about your request to speak with an agent regarding property in ${bc.split( '&' )[ 0 ].replace(',', ', ' )}. I’m working on getting those recommendations to you, but I wanted to check in and get some additional details.
            <br /><br />
            What would you like the agents to know ahead of time about what you’re looking for?"
            <br /><br />
            - Record information
            <br />
            - Repeat details back to summarize`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: '',
            button: () => (<button onClick={this.showLeadToLeadModal}>+ New Lead</button>),
            questions: `
            <strong>
            - Are you also looking to sell a property?
            </strong>
            <br />
            - Have you spoken with a lender yet?
            <br />
            - Are there any must haves for your next property? What about deal breakers?
            <br />
            - Are you in the area already or are you planning to visit and look at property later on?
            <br />
            - Is there a best time for the agents to reach out to you?`,
            bonus: false
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"Alright, great. Then what I’m going to do from here is reach out to a couple of top agents and let them know you’re interested in speaking with someone about this. I’ll send their information over to you as well so you can see their credentials, is ${nextProps.data.email} the best email to send that to?
            <br /><br />
            And before I let you go, are you looking to sell a property as well? Do you have any other questions for me?
            <br /><br />
            Okay, great. The agents will be reaching out possibly by email or phone, and you can take it from there.
            <br /><br />
            Thank you for your time, and best of luck to you."`
          }
        },
        seller: {
          introduction: {
            title: 'introduction',
            greeting: `Hi, <strong>${nextProps.data.lead.first_name}</strong>?`,
            copy: `
            "Hey there, this is ${nextProps.data.user_name} with TopAgentsRanked, calling about your request to speak ${nextProps.data.dual_transaction ? 'agents regarding your real estate needs' : 'with an agent regarding your property on ' }${ nextProps.data.dual_transaction ? '' : nextProps.data.lead.street_name } in ${nextProps.data.lead.city}, ${nextProps.data.lead.state}.
            <br /><br />
            I’m working on getting those recommendations to you, but I wanted to check in and get some additional information.
            <br /><br />
            What would you like the agents to know ahead of time about ${nextProps.data.dual_transaction ? 'the property you are selling or about what you are looking for' : 'your property'}?"
            <br /><br />
            - Record information
            <br />
            - Repeat details back to summarize
            `
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: '',
            button: () => (<button onClick={this.showLeadToLeadModal}>+ New Lead</button>),
            questions: `
            <strong>
            - Are you also looking to buy a property?
            </strong>
            <br />
            - Is there a date you’d like to sell the property by?
            <br />
            - Are there any additions or renovations to the house that the agent should know about?
            <br />
            - Is there a best time for the agents to reach out to you?
            ${
              nextProps.data.dual_transaction ?
              `<br />
              - Have you spoken with a lender yet?
              <br />
              - Are there any must haves for your next property? What about deal breakers?` : ''
            }
            `,
            bonus: false
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `“Alright, great. Then what I’m going to do from here is reach out to a couple of top agents and let them know you’re interested in speaking with someone about this. I’ll send their information over to you as well so you can see their credentials, is ${nextProps.data.email} the best email to send that to?
            ${
              !nextProps.data.dual_transaction ?
              `<br /><br />
              And before I let you go, are you looking to purchase a property as well? Do you have any other questions for me?` : ''
            }
            <br /><br />
            Okay, great. The agents will be reaching out possibly by email or phone, and you can take it from there.
            <br /><br />
            Thank you for your time, and best of luck to you.”`
          }
        }
      },
      '27': {
        buyer: {
          introduction: {
            title: 'introduction',
            greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
            copy: `"Following up on behalf of Home Captain in order to get you connected with the top local real estate agents to help you purchase a home."`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: `"I'd like to quickly confirm a few details in order to make sure we find you the agents best suited for your needs."`,
            questions: `
            - Verify price range
            <br />
            - Specific neighborhoods of interest
            <br />
            - Verify email
            <br />
            - "I understand that at this point you may be looking at several lenders. Do you have a preference on which one you will move forward with? I would like to communicate this to our agents"`,
            bonus: 'Are you also selling any property?'
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with properties that fit your needs.
            <br />
            <strong>The agents may follow up with you as well</strong>"`
          }
        }
      },
      '18': {
        buyer: {
          introduction: {
            title: 'introduction',
            greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
            copy: `"Following up to your enrollment on behalf of CU Realty’s HomeAdvantage program.
            <br/>
            I represent the HomeAdvantage network of Real Estate Agents and I would like to connect you with top agents to help you with your property search."`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: `"I have a few quick questions for you before we get started."`,
            questions: `
            - What city are you interested in purchasing in?
            <br />
            - What is your price range for the home?
            <br />
            - Specific neighborhoods of interest
            <br />
            - Verify Email
            <br />
            - Prequalified, preapproved, or still in process of applying for a loan with [INSERT CREDIT UNION NAME].`,
            bonus: false
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with properties that fit your needs.
            <br />
            <strong>The agents may follow up with you as well</strong>"`
          }
        }
      },
      '22': {
        buyer: {
          introduction: {
            title: 'introduction',
            greeting: `Hi, <strong>${nextProps.data.lead.first_name}</strong>?`,
            copy: `"This is ${nextProps.data.user_name} I’m calling on behalf of ${nextProps.data.origin}. I’m following up with your request to purchase property in ${nextProps.data.lead.buyer_city_0}, ${nextProps.data.lead.buyer_state_0}.
            <br /><br />
            I can get you connected with the top local Real Estate agents and to get you started, I have a few quick questions for you."`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: '',
            questions: `
            - Did you have a specific neighborhood of interest?
            <br />
            - Are you also selling any property?`,
            bonus: false
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with properties that fit your needs. The agents may follow up with you as well, what is the best time of day for them to call?
            <br /><br />
            Thank you so much for your time ${nextProps.data.lead.first_name} and have a great day!"`
          }
        },
        seller: {
          introduction: {
            title: 'introduction',
            greeting: `Hi, <strong>${nextProps.data.lead.first_name}</strong>?`,
            copy: `"This is ${nextProps.data.user_name} I’m calling on behalf of ${nextProps.data.origin}. I’m following up with your request to sell property in ${nextProps.data.lead.city}, ${nextProps.data.lead.state}.
            <br /><br />
            I can get you connected with the top local Real Estate agents and to get you started, I have a few quick questions for you."`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: '',
            questions: `
            <br />
            - Is your property currently listed?
            <br />
            - Are you also purchasing any property?`,
            bonus: false
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with properties that fit your needs. The agents may follow up with you as well, what is the best time of day for them to call?
            <br /><br />
            Thank you so much for your time ${nextProps.data.lead.first_name} and have a great day!"`
          }
        }
      },
      '22a': {
        buyer: {
          introduction: {
            title: 'introduction',
            greeting: `Hi, <strong>${nextProps.data.lead.first_name}</strong>?`,
            copy: `"This is ${nextProps.data.user_name}, I'm the assistant to ${nextProps.data.sending_agent.name} with ${nextProps.data.sending_agent.company}. I have here you’re looking for real estate agent recommendations in ${nextProps.data.lead.buyer_city_0}, ${nextProps.data.lead.buyer_state_0}, how is that going for you?`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: '',
            questions: `
            - Is there a specific area/property you would like to view?
            <br />
            - Are you also selling any property?`,
            bonus: false
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"${nextProps.data.sending_agent.name} or one of his/her colleagues will reach out to you by email or phone. What is the best time of day for them to call?
            <br /> <br />
            Thank you so much for your time ${nextProps.data.lead.first_name} and have a great day!"`
          }
        },
        seller: {
          introduction: {
            title: 'introduction',
            greeting: `Hi, <strong>${nextProps.data.lead.first_name}</strong>?`,
            copy: `"This is ${nextProps.data.user_name}, I'm the assistant to ${nextProps.data.sending_agent.name} with ${nextProps.data.sending_agent.company}. I have here you’re looking for agent recommendations in ${nextProps.data.lead.city}, ${nextProps.data.lead.state}, how is that going for you?"`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: '',
            questions: `
            <br />
            - Is your property currently listed?
            <br />
            - Were you looking to purchase in the same area you are selling?`,
            bonus: false
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"${nextProps.data.sending_agent.name} or one of his/her colleagues will reach out to you by email or phone. What is the best time of day for them to call?
            <br /><br />
            Thank you so much for your time ${nextProps.data.lead.first_name} and have a great day!"`
          }
        }
      },
      '34': {
        buyer: {
          introduction: {
            title: 'introduction / verify intent',
            greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
            copy: `"Calling on behalf of Carrington Real Estate Services regarding your request to be connected with a real estate agent who can help you get more information about properties in ${bc.split( '&' )[ 0 ].replace(',', ', ' )}
            <br /><br />
            Are you still looking to purchase?"`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: `"I just have a few quick questions before we can finish our research"`,
            questions: `
            -Is there a particular property or neighborhood of interest?
            <br />
            - Verify price range
            <br />
            - Verify Email`,
            bonus: 'Are you also selling any property?'
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"We will email you the profiles of the top 2-4 local real estate agents who are having the most success with homes in your price range and area of interest
            <br />
            <strong>The agents may follow up with you as well</strong>"`
          }
        }
      },
      '32': {
        seller: {
          introduction: {
            title: 'introduction / Verify intent',
            greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
            copy: `"Calling on behalf of <strong>TOPAGENTSRANKED</strong>.
            We received your request to be connected with the top local real estate agents to help you with a home value estimate regarding the property on ${nextProps.data.lead.street_number} ${nextProps.data.lead.street_name} in ${nextProps.data.lead.city}."`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: `"I have a few quick questions so that we can finish our research."`,
            questions: `
            <br />
            - Are you considering selling and would like to start with a home value estimate?
            <br />
            - Is the client working with an agent
            <br />
            - Is the property listed
            <br />
            - Verify Email`,
            bonus: 'Are you also purchasing any property?'
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with homes like yours.
            <br />
            <strong>The agents may follow up with you as well</strong>"`
          }
        }
      },
      '19': {
        buyer: {
          introduction: {
            title: 'introduction',
            greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
            copy: `"Calling on behalf of <strong>ForSaleByOwner.com</strong>.
            I received your request to be connected with top real estate agents to help you with purchasing a property in ${bc.split( '&' )[ 0 ].replace(',', ', ' )}."`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: `"I would like to quickly confirm a few details before we get started."`,
            questions: `
            - Verify price range
            <br />
            - Specific neighborhoods of interest
            <br />
            - Verify Email`,
            bonus: 'Are you also selling any property?'
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with properties that fit your needs.
            <br />
            <strong>The agents may follow up with you as well</strong>"`
          }
        },
        seller: {
          introduction: {
            title: 'introduction',
            greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
            copy: `"Calling on behalf of <strong>ForSaleByOwner.com</strong>.
            I received your request to be connected with top real estate agents to help you with listing the property on ${nextProps.data.lead.street_number} ${nextProps.data.lead.street_name} in ${nextProps.data.lead.city}."`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: `"I would like to quickly confirm a few details before we get started."`,
            questions: `
            <br />
            - Is the client working with an agent
            <br />
            - Is the property listed
            <br />
            - Verify Email`,
            bonus: 'Are you also purchasing any property?'
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with homes like yours.
            <br />
            <strong>The agents may follow up with you as well</strong>"`
          }
        }
      },
      '28': {
        buyer: {
          introduction: {
            title: 'introduction',
            greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
            copy: `"Calling on behalf of <strong>Owners.com</strong>.
            I received your request to be connected with top real estate agents to help you with purchasing a property in ${bc.split( '&' )[ 0 ].replace(',', ', ' )}."`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: `"I would like to quickly confirm a few details before we get started."`,
            questions: `
            - Verify price range
            <br />
            - Specific neighborhoods of interest
            <br />
            - Verify Email`,
            bonus: 'Are you also selling any property?'
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with properties that fit your needs.
            <br />
            <strong>The agents may follow up with you as well</strong>"`
          }
        },
        seller: {
          introduction: {
            title: 'introduction',
            greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
            copy: `"Calling on behalf of <strong>Owners.com</strong>.
            I received your request to be connected with top real estate agents to help you with listing the property on ${nextProps.data.lead.street_number} ${nextProps.data.lead.street_name} in ${nextProps.data.lead.city}."`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: `"I would like to quickly confirm a few details before we get started."`,
            questions: `
            <br />
            - Is the client working with an agent
            <br />
            - Is the property listed
            <br />
            - Verify Email`,
            bonus: 'Are you also purchasing any property?'
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with homes like yours.
            <br />
            <strong>The agents may follow up with you as well</strong>"`
          }
        }
      },
      '21': {
        buyer: {
          introduction: {
            title: 'introduction',
            greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
            copy: `"Following up on behalf of <strong>movoto.com</strong>
          regarding your request to be connected with a local real estate agent who can help you get more information about properties in ${bc.split( '&' )[ 0 ].replace(',', ', ' )}."
            <br /><br />
            Are you looking to buy property in the future?`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: `"I have a few quick questions so that we can finish our research"`,
            questions: `
            - Verify price range
            <br />
            - Specific neighborhoods of interest
            <br />
            - Verify Email`,
            bonus: 'Are you also selling any property?'
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with properties that fit your needs.
            <br />
            <strong>The agents may follow up with you as well</strong>"`
          }
        },
        seller: {
          introduction: {
            title: 'introduction',
            greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
            copy: `"Following up on behalf of <strong>movoto.com</strong>
          regarding your request to be connected with a local real estate agent  regarding the property on ${nextProps.data.lead.street_number} ${nextProps.data.lead.street_name} in ${nextProps.data.lead.city}."
            <br /><br />
            Are you considering selling this property?`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: `"I have a few quick questions so that we can finish our research"`,
            questions: `
            <br />
            - Is the client working with an agent
            <br />
            - Is the property listed
            <br />
            - Verify Email`,
            bonus: 'Are you also purchasing any property?'
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with homes like yours.
            <br />
            <strong>The agents may follow up with you as well</strong>"`
          }
        }
      },
      '25': {
        buyer: {
          introduction: {
            title: 'introduction',
            greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
            copy: `"Following up on behalf of <strong>Estately.com</strong>
          regarding your request to be connected with a local real estate agent who can help you get more information about properties in ${bc.split( '&' )[ 0 ].replace(',', ', ' )}."
            <br /><br />
            Are you looking to buy property in the future?`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: `"I have a few quick questions so that we can finish our research"`,
            questions: `
            - Verify price range
            <br />
            - Specific neighborhoods of interest
            <br />
            - Verify Email`,
            bonus: 'Are you also selling any property?'
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with properties that fit your needs.
            <br />
            <strong>The agents may follow up with you as well</strong>"`
          }
        },
        seller: {
          introduction: {
            title: 'introduction',
            greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
            copy: `"Following up on behalf of <strong>Estately.com</strong>
          regarding your request to be connected with a local real estate agent  regarding the property on ${nextProps.data.lead.street_number} ${nextProps.data.lead.street_name} in ${nextProps.data.lead.city}."
            <br /><br />
            Are you considering selling this property?`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: `"I have a few quick questions so that we can finish our research"`,
            questions: `
            <br />
            - Is the client working with an agent
            <br />
            - Is the property listed
            <br />
            - Verify Email`,
            bonus: 'Are you also purchasing any property?'
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with homes like yours.
            <br />
            <strong>The agents may follow up with you as well</strong>"`
          }
        }
      },
      '63': {
        buyer: {
          introduction: {
            title: 'introduction',
            greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
            copy: `"This is [YOUR NAME] calling on behalf of <strong>HomeSmart/ReferralExchange</strong>."
            <br />
            <br />
            "I understand you were searching for agent recommendations to help with purchasing property in ${bc.split( '&' )[ 0 ].replace(',', ', ' )}.
            How's the home search going for you?"
            <br />
            <br />
            "We'd/I'd be happy to help you get in touch with top performing agents in ${bc.split( '&' )[ 0 ].replace(',', ', ' )},
            we need to quickly confirm a few details so I can make sure to find the best agent for you."`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: `"First we just want to make sure we have your email correct."`,
            questions: `
            - Verify email
            <br />
            - Verify price range
            <br />
            - Is there a specific neighborhood you're interested in?`,
            bonus: `"Are you a first time home buyer?"
            <br />
            <ul>
              <li>- (if YES): "Great, we understand this is a big and exciting moment in your life. If you need guidance throughout the process our agents are experts and will be there to help you."</li>
              <li>- (if NO): "Great, have you placed your home on the market yet?"
                <ul>
                  <li>- (if NO): "What’s your current home address we’d be happy to help you list it as well?"</li>
                  <li>- (if YES): "How is that process going for you? How long have you had it listed? Is there anything that your agent could be doing differently? (transition to connecting with one of our agents for a 2nd opinion no obligations)."</li>
                </ul>
              </li>
            </ul>`
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"I will be emailing you 2-4 profiles of top local agents who have the most success with (what you are looking for / properties like yours).
            Thank you for letting us help you with such an important decision in your life. Feel free to reach out to the agents. <strong>The agents may follow up with you as well</strong>."
            They might mention HomeSmart/ReferralExchange so you know it is a continuation of our conversation."
            <br />
            <br />
            "Is there anything else I can do for you?"
            <br />
            <br />
            "And if you do have any other questions for me my name is [YOUR NAME]."`
          }
        },
        seller: {
          introduction: {
            title: 'introduction',
            greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
            copy: `"This is [YOUR NAME] calling on behalf of <strong>HomeSmart/ReferralExchange</strong>."
            <br />
            <br />
            "I understand you are looking for agent recommendations to help list your property on ${nextProps.data.lead.street_name}."
            <br />
            <br />
            How's that going for you?"`
          },
          questions: {
            title: 'questions',
            greeting: false,
            copy: `"I would like to quickly confirm a few details so I can make sure to find the best agent for you"`,
            questions: `
            - Verify address
            <br />
            - Is the property currently listed with an agent? If yes, when does the contract end?
            <br />
            - Verify Email`,
            bonus: `"What are your plans?"
            <br />
            "Are you moving close by or out of state?"
            <br />
            "What are you looking for in your new home?"
            <br />
            "What area(s) are you looking in?"`
          },
          closing: {
            title: 'closing',
            greeting: false,
            copy: `"I will be emailing you 2-4 profiles of top local agents who have had the most success with properties like yours.
            Thank you for letting us help you with such an important decision in your life! Feel free to reach out to the agents.
            <strong>The agents will follow up with you as well</strong>. They will probably mention HomeSmart/ReferralExchange so you know it is a continuation of our conversation."
            <br />
            <br />
            "Is there anything else I can do for you?"
            <br />
            <br />
            "And if you do have any other questions for me my name is [YOUR NAME]."`
          }
        }
      }
    }

    var rawRexScript = {
      buyer: {
        introduction: {
          title: 'introduction',
          greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
          copy: `"Calling on behalf of <strong>TopAgentsRanked</strong>.
          I’m following up to your (online) request to get more information about properties in ${bc.split( '&' )[ 0 ].replace(',', ', ' )}."
          <br /><br />
          Are you looking to buy property in ${bc.split( '&' )[ 0 ].replace(',', ', ' )}?`
        },
        questions: {
          title: 'questions',
          greeting: false,
          copy: `"I'm following up because I can get you connected with the top 2-4 local Real Estate agents to get you started. I have a few quick questions before we can complete our research"`,
          questions: `
          - Verify price range
          <br />
          - Specific neighborhoods of interest
          <br />
          - Verify email`,
          bonus: 'Are you also selling any property?'
        },
        closing: {
          title: 'closing',
          greeting: false,
          copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with properties that fit your needs.
          <br />
          <strong>The agents may follow up with you as well</strong>"`
        }
      },
      seller: {
        introduction: {
          title: 'introduction',
          greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
          copy: `"Calling on behalf of <strong>TopAgentsRanked</strong>.
          I’m following up to your (online) request regarding the property on ${nextProps.data.lead.street_number} ${nextProps.data.lead.street_name} in ${nextProps.data.lead.city}."
          <br /><br />
          Are you looking to sell the property on ${nextProps.data.lead.street_number} ${nextProps.data.lead.street_name} in ${nextProps.data.lead.city}?`
        },
        questions: {
          title: 'questions',
          greeting: false,
          copy: `"I'm following up because I can get you connected with the top 2-4 local Real Estate agents to get you started. I have a few quick questions before we can complete our research"`,
          questions: `
          <br />
          - Is the client working with an agent
          <br />
          - Is the property listed
          <br />
          - Verify email`,
          bonus: 'Are you also purchasing any property?'
        },
        closing: {
          title: 'closing',
          greeting: false,
          copy: `"We will email you the profiles of the top 2-4 local agents who are having the most success with homes like yours.
          <br />
          <strong>The agents may follow up with you as well</strong>"`
        }
      }
    }

    var newBerkshireScript = {
      buyer: {
        introduction: {
          title: 'introduction',
          greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
          copy: `"Hi, my name is [NAME], and I am calling on behalf of Berkshire Hathaway Drysdale Properties regarding a web inquiry that was submitted on our website: BerkshireHathawayHS.com. I wanted to make sure you received the information you were looking for."`
        },
        questions: {
          title: 'questions',
          greeting: false,
          copy: `"I would like to quickly confirm a few details so I can make sure to find the best agent for you."`,
          questions: `
          - Verify price range
          <br />
          - Verify neighborhoods of interest
          <br />
          - Verify email`,
          bonus: 'Are you also selling any property?'
        },
        closing: {
          title: 'closing',
          greeting: false,
          copy: `"I will be emailing you 2-4 profiles of top local agents who have the most success with properties that fit your needs. The agents may follow up with you, as well."`
        }
      },
      seller: {
        introduction: {
          title: 'introduction',
          greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
          copy: `"I am following up on behalf of Berkshire Hathaway Drysdale Properties regarding a request to be connected with a top real estate agent in ${nextProps.data.lead.city}.
           Are you looking to sell property there?"`
        },
        questions: {
          title: 'questions',
          greeting: false,
          copy: `"I would like to quickly confirm a few details so I can make sure to find the best agent for you."`,
          questions: `
          <br />
          - Verify address
          <br />
          - Is the property currently listed with an agent? If yes, when does the contract end?
          <br />
          - Verify email`,
          bonus: 'Are you also purchasing any property?'
        },
        closing: {
          title: 'closing',
          greeting: false,
          copy: `"I will be emailing you 2-4 profiles of top local agents who have the most success with properties like yours. The agents may follow up with you, as well."`
        }
      }
    }

    var USNewsScript = {
      buyer: {
        introduction: {
          title: 'introduction',
          greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
          copy: `"This is [YOUR NAME] with <strong>US News</strong>. We received your request to be connected with top real estate agents regarding purchase property in ${nextProps.data.lead.buyer_city_0} and I want to let you know that we are working on it."`
        },
        questions: {
          title: 'questions',
          greeting: false,
          copy: `"I would like to quickly confirm a few details so I can make sure to find the best agent for you."`,
          questions: `
          - Verify email
          <br />
          - Verify price range
          <br />
          - Verify neighborhoods of interest`,
          bonus: 'Are you also selling any property?'
        },
        closing: {
          title: 'closing',
          greeting: false,
          copy: `"I will be emailing you 2-4 profiles of top local agents who have the most success with properties that fit your needs. The agents may follow up with you, as well."`
        }
      },
      seller: {
        introduction: {
          title: 'introduction',
          greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
          copy: `"This is [YOUR NAME] with <strong>US News</strong>. We received your request to be connected with top real estate agents to sell property in ${nextProps.data.lead.city} and I want to let you know that we are working on it."`
        },
        questions: {
          title: 'questions',
          greeting: false,
          copy: `"I would like to quickly confirm a few details so I can make sure to find the best agent for you."`,
          questions: `
          <br />
          - Verify address
          <br />
          - Is the property currently listed with an agent? If yes, when does the contract end?
          <br />
          - Verify email`,
          bonus: 'Are you also purchasing any property?'
        },
        closing: {
          title: 'closing',
          greeting: false,
          copy: `"I will be emailing you 2-4 profiles of top local agents who have the most success with properties like yours. The agents may follow up with you, as well."`
        }
      }
    }

    var TwoXReferralsScript = {
      buyer: {
        introduction: {
          title: 'introduction',
          greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
          copy: `"I am following up on behalf of <strong>TopAgentsRanked</strong>
          to confirm your email address to send you profiles of top real estate agents to help you find a property in ${bc.split( '&' )[ 0 ].replace(',', ', ' )}."`
        },
        questions: {
          title: 'questions',
          greeting: false,
          copy: `"I would like to quickly confirm a few details so I can make sure to find the best agent for you."`,
          questions: `
          - Verify email
          <br />
          - Verify price range
          <br />
          - Verify neighborhoods of interest`,
          bonus: 'Are you also selling any property?'
        },
        closing: {
          title: 'closing',
          greeting: false,
          copy: `"I will be emailing you 2-4 profiles of top local agents who have the most success with properties that fit your needs. The agents may follow up with you, as well."`
        }
      },
      seller: {
        introduction: {
          title: 'introduction',
          greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
          copy: `"I am following up on behalf of <strong>TopAgentsRanked</strong> regarding a request for information to sell property in ${nextProps.data.lead.city}. I have some top performing real estate agents in the area who would be happy to provide you with a comparative market analysis unique to your property."`
        },
        questions: {
          title: 'questions',
          greeting: false,
          copy: `"I would like to quickly confirm a few details so I can make sure to find the best agent for you."`,
          questions: `
          <br />
          - Verify address
          <br />
          - Is the property currently listed with an agent? If yes, when does the contract end?
          <br />
          - Verify email`,
          bonus: 'Are you also purchasing any property?'
        },
        closing: {
          title: 'closing',
          greeting: false,
          copy: `"I will be emailing you 2-4 profiles of top local agents who have the most success with properties like yours. The agents may follow up with you, as well."`
        }
      }
    }

    var liveRexScript = {
      buyer: {
        introduction: {
          title: 'introduction',
          greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
          copy: `
          "I'm calling from ReferralExchange. This is in regards to your interest to speak with a real estate agent in ${bc.split('&')[0].replace(',', ', ')}. I want to make sure I can get you the information you’re looking for. Were you interested in purchasing or selling property at this time?”
          <br /><br />
          - Select 'Buyer' or 'Seller'
          <br /><br />
          “Oh, perfect. Our agents know the area well so if I can get a little more information from you, we can get started.”
          `
        },
        questions: {
          title: 'questions',
          greeting: false,
          copy: "I’d be happy to get you in touch with the top local agents to help you. To make sure I recommend the best agents for your needs, I’d like to confirm...",
          questions: `
          <br />
          - Verify location
          <br />
          - Verify price range
          <br />
          - Verify mortgage status
          <br />
          - Is the client working with an agent
          <br />
          - Verify email
          `,
          bonus: `Are you also selling any property?`
        },
        closing: {
          title: 'closing',
          greeting: false,
          copy: `“Great! I’ll get started on this right away. You can expect an email from us with the profiles of the top 2-4 local agents who are having the most success with property similar to what you’re looking for. The agents may follow up with you as well. Is there anything else I can help you with before we finish up?<br /><br />Thank you.”`
        }
      },
      seller: {
        introduction: {
          title: 'introduction',
          greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
          copy: `
          "I'm calling from ReferralExchange. This is in regards to your interest to speak with a real estate agent for ${`${nextProps.data.lead.street_number} ${nextProps.data.lead.street_name} in ${nextProps.data.lead.city}, ${nextProps.data.lead.state}`}. I want to make sure I can get you the information you’re looking for. Were you interested in purchasing or selling property at this time?”
          <br /><br />
          - Select 'Buyer' or 'Seller'
          <br /><br />
          “Oh, perfect. Our agents know the area well so if I can get a little more information from you, we can get started.”
          `
        },
        questions: {
          title: 'questions',
          greeting: false,
          copy: `I’d be happy to get you in touch with the top agents in the area. To make sure I recommend the best agents for your specific property, I’d like to confirm...`,
          questions: `
          <br />
          - Verify location
          <br />
          - Verify property type
          <br />
          - Is the client working with an agent
          <br />
          - Is the property listed
          <br />
          - Verify email
          `,
          bonus: `Are you also purchasing any Property?`
        },
        closing: {
          title: 'closing',
          greeting: false,
          copy: `“Great! I’ll get started on this right away. You can expect an email from us with the profiles of the top 2-4 local agents who are having the most success with homes like yours. The agents may follow up with you as well. Is there anything else I can help you with before we finish up?<br /><br />Thank you.`
        }
      }
    }

    var liveAgentScript = {
      buyer: {
        introduction: {
          title: 'introduction',
          greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
          copy: `
          "I'm calling from ReferralExchange. This is in regards to your interest to speak with a real estate agent in ${bc.split('&')[0].replace(',', ', ')}. I want to make sure I can get you the information you’re looking for. Were you interested in purchasing or selling property at this time?”
          <br /><br />
          - Select 'Buyer' or 'Seller'
          <br /><br />
          “Oh, perfect. Our agents know the area well so if I can get a little more information from you, we can get started.”
          `
        },
        questions: {
          title: 'questions',
          greeting: false,
          copy: `${nextProps.data.live.submitting_agent_name} knows the area well so I’d love to get you two connected while I have you on the phone. Let me confirm a couple of details to make sure what I see here is accurate...`,
          questions: `
          <br />
          - Verify location
          <br />
          - Verify price range
          <br />
          - Verify mortgage status
          <br />
          - Is the client working with an agent
          <br />
          - Verify email
          `,
          bonus: `Are you also selling any property?`
        },
        closing: {
          title: 'closing',
          greeting: false,
          copy: `“Great! I’m going to speak with ${nextProps.data.live.submitting_agent_name} now and go over everything we discussed together. [He/she] will be reaching out to go over this further, but I’ll email you their contact information so you can get in touch with them as well. Now, is there anything else I can help you with while I have you on the phone?<br /><br />Thank you.”`
        }
      },
      seller: {
        introduction: {
          title: 'introduction',
          greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
          copy: `
          "I'm calling from ReferralExchange. This is in regards to your interest to speak with a real estate agent for ${`${nextProps.data.lead.street_number} ${nextProps.data.lead.street_name} in ${nextProps.data.lead.city}, ${nextProps.data.lead.state}`}. I want to make sure I can get you the information you’re looking for. Were you interested in purchasing or selling property at this time?”
          <br /><br />
          - Select 'Buyer' or 'Seller'
          <br /><br />
          “Oh, perfect. Our agents know the area well so if I can get a little more information from you, we can get started.”
          `
        },
        questions: {
          title: 'questions',
          greeting: false,
          copy: `${nextProps.data.live.submitting_agent_name} knows the area well so I’d love to get you two connected while I have you on the phone. Let me confirm a couple of details to make sure what I see here is accurate...`,
          questions: `
          <br />
          - Verify location
          <br />
          - Verify property type
          <br />
          - Is the client working with an agent
          <br />
          - Is the property listed
          <br />
          - Verify email
          `,
          bonus: `Are you also purchasing any Property?`
        },
        closing: {
          title: 'closing',
          greeting: false,
          copy: `“Great! I’m going to speak with ${nextProps.data.live.submitting_agent_name} now and go over everything we discussed together. [He/she] will be reaching out to go over this further, but I’ll email you their contact information so you can get in touch with them as well. Now, is there anything else I can help you with while I have you on the phone?<br /><br />Thank you.”`
        }
      }
    }

    var defaultLiveScript = {
      introduction: {
        title: 'introduction',
        greeting: `Hi <strong>${nextProps.data.lead.first_name} ${nextProps.data.lead.last_name}</strong>,`,
        copy: `
        "I'm calling from ReferralExchange. This is in regards to your interest to speak with a real estate agent for ${nextProps.data.lead.buyer_city_0 && nextProps.data.lead.buyer_city_0.length > 0 ? bc.split('&')[0].replace(',', ', ') : `${ nextProps.data.lead.street_number } ${ nextProps.data.lead.street_name } in ${ nextProps.data.lead.city }, ${ nextProps.data.lead.state }`}. I want to make sure I can get you the information you’re looking for. Were you interested in purchasing or selling property at this time?”
        <br /><br />
        - Select 'Buyer' or 'Seller'
        <br /><br />
        “Oh, perfect. Our agents know the area well so if I can get a little more information from you, we can get started.”
        `
      },
      questions: {},
      closing: {}
    }

    this.micrositeScripts = {
      '873': rawRexScript,
      '872': rawRexScript,
      '876': newBerkshireScript,
      '874': USNewsScript,
      '869': TwoXReferralsScript
    }

    this.liveScripts = {
      submitted_rex: liveRexScript,
      submitted_agent: liveAgentScript
    }
    if (Object.keys(this.micrositeScripts).includes(nextProps.data.lead.microsite_id.toString())) {
      this.script = this.micrositeScripts[ nextProps.data.lead.microsite_id ][ nextProps.data.lead.lead_type ];
    } else if (nextProps.data.live.live_lead) {
      this.script = this.liveScripts[nextProps.data.live.live_disposition][nextProps.data.lead.lead_type] || defaultLiveScript;
    } else if (nextProps.data.partner_id.length) {
      const pid = window.location.pathname.includes('/qual/queue/agent') && nextProps.data.partner_id === '22' ? '22a' : nextProps.data.partner_id;
      this.script = this.partnerScripts[pid][nextProps.data.lead.lead_type] || { introduction: {}, questions: {}, closing: {} };
    }
  }

  render() {
    return(
      <div>
        <ScriptIntroduction
          data={ this.script.introduction }
        />
        <ScriptQuestions
          data={ this.script.questions }
          rex_referral={ this.props.data.rex_referral }
          live_lead={ this.props.data.live.live_lead }
          crm_link={ this.props.data.crm_link }
        />
        <ScriptClosing
          data={ this.script.closing }
        />
      </div>
    )
  }
}
