import { Component } from 'react';
import AgentSearchRows from './AgentSearchRows';

export default class AgentSearch extends Component {
  constructor(props){
    super(props)
    this.state = {
      searchState: '',
      query: '',
      agents: []
    }

    this.handleChange = this.handleChange.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.forceClose = this.forceClose.bind(this);
  }

  componentDidMount(){
    window.addEventListener('keydown', this.closeSearch)
  }

  componentWillUnmount(){
    window.removeEventListener('keydown', this.closeSearch)
  }

  closeSearch(e){
    if(e.key === 'Escape') this.setState({ searchState: '', query: '' })
  }

  forceClose(){
    this.setState({ searchState: '', query: '' })
  }

  handleChange(e){
    this.setState({ query: e.currentTarget.value });
  }

  handleKeyPress(e){
    if(e.key === 'Enter'){
      this.setState({ searchState: 'searching' });
      this.searchAgent();
    }
  }

  searchAgent(){
    let params;
    let { query } = this.state;
    let { advancedSearch, advancedSearchOptions } = this.props;

    let searchType = checkType(query);
    if(searchType === 'phone') query = query.replace(/[^0-9]/g, '')

    if (advancedSearch === 'true' && advancedSearchOptions) {
      let baseOptions;
      if (searchType === 'name') {
        let [first_name, last_name] = query.split(' ');
        baseOptions = { first_name, last_name };
      } else {
        baseOptions - { [searchType]: query };
      }

      params = {
        search_params: {
          search_type: 'advanced_search',
          advanced_search_options: {
            ...baseOptions,
            ...advancedSearchOptions,
          }
        }
      }
    } else {
      params = {
        search_params: {
          search_type: 'quick_search',
          quick_search_type: searchType,
          search_data: query,
        }
      }
    };

    Util.searchAgents(params)
      .then((results) => { this.setState({ agents: results }) })
      .then(() => this.setState({ searchState: 'displaying' }))
  }

  agentsSearchResults(){
    let { searchState } = this.state;
    switch(searchState){
      case 'searching':
        return(
          <div className='cs-overshadow' onClick={() => this.setState({ searchState: '' })}>
            <div className='search-panel' onClick={(e) => e.stopPropagation()}>
              <div className='search-loading'>
                <i className="fa fa-cog fa-spin fa-3x fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        );
        break;
      case 'displaying':
        return(
          <div className='cs-overshadow' onClick={() => this.setState({ searchState: '' })}>
            <div className='search-panel' onClick={(e) => e.stopPropagation()}>
              <AgentSearchRows
                agents={this.state.agents}
                fetchAgent={this.props.fetchAgent}
                forceClose={this.forceClose}
              />
            </div>
          </div>
        );
        break;
      default:
        return (<div />)
    }
  }

  render(){
    return(
      <div className='agent-search-bar'>
        Agent Search: &nbsp;
        <input
          className='search-input'
          type='input'
          placeholder='Search'
          value={this.state.query}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
        />
        { this.agentsSearchResults() }
      </div>
    )
  }
}
