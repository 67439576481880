import  { Component } from  'react';

import mainIcon from '../images/call-initiate.svg';
import { request } from 'https';

export default class Init extends Component {
  constructor(props){
    super(props);

    this.state = {
      wasClicked: false
    };

    this.reinitialize = this.reinitialize.bind(this);
    this.startCallOrContactAgent = this.startCallOrContactAgent.bind(this);
  }

  startCallOrContactAgent() {
    if (this.state.wasClicked == true) return;

    this.setState({ wasClicked: true });
    this.props.updateState({ skip_pmr: true })

    // TODO: fix mounting issue here https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
    this.props.updateLead()
      .then(() => {
        this.props.contactAgent()
        .then(() => {
          this.reinitialize();
        });
      })
  }

  reinitialize() {
    this.setState({wasClicked: false});
  }

  disableButton() {
    let mandatoryVerifications = [
      'buyer_city_0',
      'buyer_state_0',
      'email',
      'lead_type',
      'home_price_min',
      'home_price_max',
      'city',
      'state',
      'street_number',
      'street_name',
      'zip'
    ];

    if (this.props.verifications && Object.keys(this.props.verifications).some(key => mandatoryVerifications.includes(key) && this.props.verifications[key] === false))
      return 'disabled'
    return ''
  }

  render(){
    return (
      <div
        className={['button', 'init', this.disableButton()].join(' ')}
        onClick={this.startCallOrContactAgent}
      >
        <div className='row'>
          <div className='col-sm-2'>
            <img src={ mainIcon }/>
          </div>
          <div className='col-sm-6'>
            <div className='row short-top-row status-text'>
              Contact Agent<br></br>
              <span className='bottom-text'>Initiate</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
