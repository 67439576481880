import  { Component } from  'react';
import {
  AgentConnected,
  AgentReady,
  AgentUnavailable,
  Conference,
  Contacting,
  Init,
  LeadConnected
} from './CallStatusButtons';

export default class CallStatus extends Component {
  constructor(props){
    super(props);
  }

  render(){
    const { allValid, disabled, buttonText } = this.props;
    console.debug('render call status', this.props.callStatus)

    switch(this.props.callStatus) {
      case 'init':
        return (
          <Init
            allValid      = { allValid }
            disabled      = { disabled }
            initiate      = { this.props.initiate }
            callStatus    = { this.props.callStatus }
            verifications = { this.props.verifications }
            buttonText    = { buttonText }
          />
        )
      case 'contacting_lead':
        return (
          <Init
            allValid      = { allValid }
            disabled      = { disabled }
            initiate      = { this.props.initiate }
            callStatus    = { this.props.callStatus }
            verifications = { this.props.verifications }
            buttonText    = {buttonText}

          />
        )

      case 'lead_connected':
      case 'lead_contacted':
        return (
          <Init
            allValid      = { allValid }
            disabled      = { disabled }
            initiate      = { this.props.initiate }
            callStatus    = { this.props.callStatus }
            verifications = { this.props.verifications }
            buttonText    = {buttonText}
          />
        )

      case 'contacting_agent':
      case 'agent_contacted':
        return <Contacting />

      case 'agent_ready':
        return <AgentReady />

      case 'agent_connected':
      case 'solo_agent':
        return <AgentConnected agentCallStartTime = {this.props.agentCallStartTime} />

      case 'lead_connected':
      case 'solo_lead':
        return <LeadConnected leadCallStartTime = {this.props.leadCallStartTime} />

      case 'agent_unavailable':
      case 'agent_disconnected':
      case 'complete':
        return <AgentUnavailable agentCallStartTime = {this.props.agentCallStartTime} />

      case 'conference':
        return <Conference conferenceCallStartTime = {this.props.conferenceCallStartTime} />

      case 'lead_disconnected':
        return (
          <Init
            allValid      = { allValid }
            disabled      = { disabled }            
            initiate      = { this.props.initiate }
            callStatus    = { this.props.callStatus }
            verifications = { this.props.verifications }
            buttonText    = {buttonText}
          />
        )
    }
  }
}
