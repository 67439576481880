import React, { useState, useEffect } from 'react';

import ClientComments from './ops_ppc/ClientComments.js';
import ClientInformation from './ops_ppc/ClientInformation.js';
import LeadStatus from './ops_ppc/LeadStatus.js';
import TransactionType from './ops_ppc/TransactionType.js';
import ReferralDetails from './ops_ppc/ReferralDetails.js';

const LEAD_TYPES = [
  "Buy",
  "Sell",
  "Buy + Sell",
];

const LEAD_STATUSES = [
  "Fresh",
  "Hightouch",
  "Matching",
  "Dead",
];

const LFL_LEAD_STATUSES = [
  "Pending",
  "Is Client",
];

const LOCATION_ATTRS = [
  'clients_location',
  'buyer_cities',
  'street_number',
  'street_name',
  'city',
  'state',
  'country',
  'zip'
];

const initSeller = {
  clients_location: {
    street_number: null,
    street_name: null,
    city: null,
    state: null,
    country: null,
    zip: null,
  },
  time_frame: null,
  type_home: 'unknown',
  reason_for_selling: null,
  range: 5,
  min_price: 300000,
  max_price: 350000,
};

const initBuyer = {
  clients_location: {
    street_number: null,
    street_name: null,
    city: null,
    state: null,
    country: null,
    zip: null,
  },
  mortgage_status: 'none',
  time_frame: null,
  min_beds: null,
  min_baths: null,
  range: 5,
  min_price: 300000,
  max_price: 350000,
};

const initLead = {
  lead_type: "buy",
  status: "fresh",
  microsite_id: "917",
  source: null,
  language: null,
  client_comments: null,
  seller: initSeller,
  buyer: initBuyer,
  first_name: null,
  last_name: null,
  phone_number: null,
  email: null,
  consent_type: null,
};

const OpsPpc = props => {
  const {
    leadFromLead = {},
    exclusive = {},
    resetView,
    consent_options,
    country_options,
    us_state_options,
    ca_province_options,
    source_options,
    microsite_options,
    language_options,
    type_home_options,
    time_frame_options,
    reason_options,
    mortgage_options,
    bed_options,
    bath_options,
  } = props;

  const [lead, updateLead] = useState({ ...initLead, ...leadFromLead });
  const [submitDisabled, toggleSubmitDisabled] = useState(false);

  useEffect(() => resetBuyerSeller(), [lead.lead_type]);

  useEffect(() => {
    if (Object.keys(exclusive).length) updateLead({ ...lead, ...exclusive });
  }, [exclusive]);

  useEffect(() => {
    if (Object.keys(leadFromLead).length) updateLead({ ...lead, ...leadFromLead });
  }, [leadFromLead]);

  const updateFieldHandler = data => {
    Object.keys(data).forEach(key => {
      if (typeof(data[key]) === "object") {
        const overrides = Array.isArray(data[key]) ? data[key] : { ...lead[key], ...data[key] };

        updateLead({ ...lead, ...{ [key]: overrides } });
      } else {
        updateLead({ ...lead, ...data });
      }
    });
  };

  const resetBuyerSeller =  () => {
    const { lead_type } = lead;

    if (lead_type === 'buy') updateFieldHandler({ seller: initSeller });
    if (lead_type === 'sell') updateFieldHandler({ buyer: initBuyer });
  };

  const addError = (attr, error) => {
    const container = document.getElementById('lead-creation-form-container');
    const label = container.querySelector(`label[for="${attr}"]`);

    if (label) {
      if (label.classList.contains('has-error')) return;

      const div = document.createElement('div');

      div.classList.add('error-message');
      div.innerHTML = error;

      label.classList.add('has-error');
      label.appendChild(div);
    }
  };

  const addErrors = (errors, leadType) => {
    const override = document.getElementById(`${leadType}_manual_override`);
    const cache = [];

    for (const key in errors) {
      const attr = LOCATION_ATTRS.includes(key) ? `${leadType}_${key}` : key;

      if (cache.includes(attr)) continue;

      addError(attr, errors[key]);
      cache.push(attr);
    };
  };

  const removeErrors = () => {
    const errorMessages = document.querySelectorAll('.error-message');
    const labels = [...errorMessages].map(e => e.parentElement);

    Util.clearFlash();

    errorMessages.forEach(error => error.remove());
    labels.forEach(label => label.classList.remove('has-error'));
  };

  const leadTypesArray = () => {
    const { lead_type } = lead;

    switch (lead_type) {
    case "buy_sell":
      return ["seller", "buyer"];
      break;
    case "sell":
      return ["seller"];
      break;
    default:
      return ["buyer"];
    }
  };

  const submitRequest = ({ validate }) => {
    const leadTypes = leadTypesArray();
    const requests = [];

    removeErrors();

    leadTypes.forEach(leadType => {
      requests.push(fetchRequest({ validate: validate, lead_type: leadType }));
    });

    return new Promise(resolve => {
      return Promise.all(requests)
        .then(responses => {
          return Promise.all(responses.map(response => response.json()))
        })
        .then(responses => {
          const success = responses.map(response => response.success);
          const errors = responses.map(response => ({ ...response.errors, ...{ lead_type: response.lead_type } }));
          const uuids = responses.map(response => ({ lead_type: response.lead_type, lead_uuid: response.lead_uuid, request_uuid: response.request_uuid }));

          resolve({ valid: !success.includes(false), errors: errors, uuids: uuids });
        })
        .catch(error => console.log('Error occured on submitRequest', error))
        ;
    });
  };

  const fetchRequest = ({ validate, lead_type }) => {
    const payload = validate ? { ...lead, lead_type, validate } : { ...lead, lead_type };

    return fetch(
      '/api/leads',
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload),
      }
    );
  };

  const handleSubmitErrors = errors => {
    Util.createFlash('Please fix the following errors.', 'danger');

    errors.forEach(error => {
      const leadType = error.lead_type;

      delete error.lead_type;

      addErrors(error, leadType);
    });
  };

  const handleFormSubmit = _event => {
    if (submitDisabled) return;

    toggleSubmitDisabled(true);

    submitRequest({ validate: true })
      .then(response => {
        const { valid, errors, uuids } = response;

        if (valid) {
          submitRequest({ validate: false })
            .then(response => {
              const { valid, errors, uuids } = response;

              if (valid) {
                Util.createFlash('Lead successfully created.', 'success');

                uuids.forEach(uuid => {
                  const { lead_uuid, request_uuid } = uuid;
                  const leadURL = `/leads/${lead_uuid}?request_uuid=${request_uuid}`;

                  window.open(leadURL, '_blank');
                });

                resetView();
              } else {
                handleSubmitErrors(errors);
              }

              window.focus();
            })
            .catch(error => console.log('Error occured on handleFormSubmit, validate false', error));
        } else {
          handleSubmitErrors(errors);
        }

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      })
      .catch(error => console.log('Error occured on handleFormSubmit, validate true', error));

    setTimeout(() => toggleSubmitDisabled(false), 750);
  };

  const {
    lead_type,
    seller: {
      range: seller_range,
      min_price: seller_min_price,
      max_price: seller_max_price,
    },
    buyer: {
      range: buyer_range,
      min_price: buyer_min_price,
      max_price: buyer_max_price,
    },
  } = lead;

  return (
    <>
      <ClientInformation
        lead={ lead }
        consent_options={ consent_options }
        language_options={ language_options }
        microsite_options={ microsite_options }
        source_options={ source_options }
        updateFieldHandler={ updateFieldHandler }
      />

      <ClientComments
        updateFieldHandler={ updateFieldHandler }
      />

      <TransactionType
        leadTypes={ LEAD_TYPES }
        updateFieldHandler={ updateFieldHandler }
      />

      <ReferralDetails
        lead={ lead }
        leadType={ lead_type }
        country_options={ country_options }
        us_state_options={ us_state_options }
        ca_province_options={ ca_province_options }
        time_frame_options={ time_frame_options }
        type_home_options={ type_home_options }
        reason_options={ reason_options }
        mortgage_options={ mortgage_options }
        bed_options={ bed_options }
        bath_options={ bath_options }
        seller_range={ seller_range }
        seller_min_price={ seller_min_price }
        seller_max_price={ seller_max_price }
        buyer_range={ buyer_range }
        buyer_min_price={ buyer_min_price }
        buyer_max_price={ buyer_max_price }
        updateFieldHandler={ updateFieldHandler }
      />

      <LeadStatus
        leadFromLead={ leadFromLead }
        leadStatuses={ LEAD_STATUSES }
        lflLeadStatuses={ LFL_LEAD_STATUSES }
        updateFieldHandler={ updateFieldHandler }
      />

      <div className="section footer">
        <button type="button" className="rex-button" disabled={ submitDisabled } onClick={ handleFormSubmit }>Submit</button>
      </div>
    </>
  );
}

export default OpsPpc;
