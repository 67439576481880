import { Component }  from 'react';

export default class Modal extends Component {
  render() {
    const style = { display: 'block' };
    const size = this.props.size || 'modal-md';

    return (
      <div>
        <div className='modal-backdrop in' />
        <div className='modal in' tabIndex='-1' role='dialog' aria-hidden='false' ref='modal' style={style}>
          <div className={`modal-dialog ${size}`}>
            <div className='modal-content'>
              { this.props.children }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
