import React from 'react';
import ReactDOM from 'react-dom';
import LiveAccountManagement from './ManagementContainer';

/**
 * Initialize the LiveAccountManagement system.
 */

export function initialize(containerElement, props) {
  let component = React.createElement(LiveAccountManagement, props);
  ReactDOM.render(component, containerElement);
}
