import React from 'react';
import Alert from '../LiveAccountManagement/images/alert.png';

const ProcessRefundPartial = (props) => {
  const {
    changeAmount,
    errorMessage,
    goTo,
    loading,
    submitRefund,
  } = props;

  return (
    <div className='process-refund'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ Alert } />
      </div>
      <div className='rex-modal-wrapper'>
        <div className='rex-modal-heading'>
          ENTER PARTIAL REFUND AMOUNT
        </div>
        <div className='rex-modal-contents'>
          <div className='rex-modal-subheading'>
            <div className='rex-modal-input-number'>
              <span>$</span>
              <input
                type='number'
                min='0'
                onChange={ changeAmount }
              />
            </div>
          </div>
          <div className='rex-modal-error'>
            { errorMessage }
          </div>
          <div className='rex-modal-actions rex-modal-actions__multiple-actions'>
            <button
              className='rex-modal-action rex-modal-multiple-action'
              onClick={ () => goTo(null) }
              disabled={ loading }
            >
              GO BACK
            </button>
            <button
              className={ `rex-modal-action rex-modal-multiple-action ${ loading ? 'rex-modal-action__loading' : '' }` }
              onClick={ () => submitRefund(false) }
              disabled={ loading }
            >
              { loading ? 'Loading' : 'PARTIAL REFUND' }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessRefundPartial;
