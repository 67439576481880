import { Component } from 'react';

export default class AppointmentWeeklyDisplay extends Component {
  constructor(){
    super();
    this.state = {
      currentWeek: [],
      timeslots: {},
      config: {},
      quickConfigButtons: {},
      loading: false
    }

    this.days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ]

    this.timeslots = [
      "6:00AM",
      "6:30AM",
      "7:00AM",
      "7:30AM",
      "8:00AM",
      "8:30AM",
      "9:00AM",
      "9:30AM",
      "10:00AM",
      "10:30AM",
      "11:00AM",
      "11:30AM",
      "12:00PM",
      "12:30PM",
      "1:00PM",
      "1:30PM",
      "2:00PM",
      "2:30PM",
      "3:00PM",
      "3:30PM",
      "4:00PM",
      "4:30PM",
      "5:00PM"
    ]

    this.morning = this.timeslots.slice(0, 12)
    this.afternoon = this.timeslots.slice(12)

    this.renderTimeslotRow = this.renderTimeslotRow.bind(this);
    this.weekFinder = this.weekFinder.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchConfigData = this.fetchConfigData.bind(this);
    this.useDefaults = this.useDefaults.bind(this);
    this.quickConfigToggler = this.quickConfigToggler.bind(this);
    this.initializeConfigButtons = this.initializeConfigButtons.bind(this);
    this.weekdayConfig = this.weekdayConfig.bind(this);
  }

  weekFinder(newProp = 0) {
    let currentWeek = [],
        curr_date = new Date();

    curr_date.setDate(newProp.currentWeek * 5 + curr_date.getDate())

    while (currentWeek.length < 5){
      currentWeek.push(new Date(curr_date.valueOf()))
      curr_date.setDate(curr_date.getDate() + 1)
    }

    currentWeek = currentWeek.map(date =>
      `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
    )

    this.setState({ currentWeek: currentWeek, quickConfigButtons: this.initializeConfigButtons(currentWeek) },
      () => { this.fetchData() }
    )
  }

  initializeConfigButtons(currentWeek) {
    let times = ['Day', 'Morning', 'Afternoon'],
        newState = {};

    currentWeek.forEach((date) => {
      times.forEach((time) => {
        newState[`${date} ${time}`] = true
      })
    })

    return(newState)
  }

  fetchData(){
    let currentWeek = this.state.currentWeek,
        first_day = currentWeek[0],
        last_day = currentWeek[4],
        params = { start_date: first_day, end_date: last_day };

    $.get('/customer_success/fetch_schedule', params, (result) => {
      this.setState({ timeslots: result.timeslots },
        () => {
          this.setState({ loading: false })
        }
      )
    })
  }

  fetchConfigData(){
    $.get('/customer_success/fetch_schedule_config', (result) => {
      this.setState({ config: result.timeslots })
    })
  }

  componentDidMount(){
    this.fetchConfigData();
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  componentWillReceiveProps(newProp){
    this.setState({loading: true})
    this.weekFinder(newProp);
  }

  useDefaults(){
    let newState = this.state.timeslots

    Object.keys(this.state.timeslots).forEach(key => {
      let [date, time] = key.split(' '),
          day = this.days[new Date(date.split('-')).getDay()];

      newState[key] = this.state.config[`${day} ${time}`]
    })

    this.setState({ timeslots: newState })
  }

  quickConfigToggler(e){
    let date = e.target.parentElement.parentElement.className.split('_')[1],
        weekday = this.days[new Date(date.split('-')).getDay()],
        newTimeslots = this.state.timeslots,
        timeRange = e.target.dataset.time,
        newButtonState = !this.state.quickConfigButtons[`${date} ${timeRange}`],
        newState = this.state,
        times;

    switch(timeRange) {
      case 'Day':
        times = this.timeslots
        break;
      case 'Morning':
        times = this.morning
        break;
      case 'Afternoon':
        times = this.afternoon
        break;
    }

    times.forEach(timeslot => {
      let key = `${date} ${timeslot}`
      newTimeslots[key] = newButtonState ? this.state.config[`${weekday} ${timeslot}`] : 0
    })

    newState.timeslots = newTimeslots
    newState.quickConfigButtons[`${date} ${timeRange}`] = newButtonState

    this.setState(newState)
  }

  weekdayConfig(e){
    let weekday = e.target.parentElement.parentElement.dataset.weekday,
        newConfig = {};

    this.days.forEach((date) => {
      this.timeslots.forEach((timeslot) => {
        newConfig[`${date} ${timeslot}`] = this.state.config[`${weekday} ${timeslot}`]
      })
    })

    this.setState({config: newConfig})
  }

  handleChange(e){
    if (e.target.value < 0) { return }

    let newState = this.props.configMode ? this.state.config : this.state.timeslots;

    newState[`${e.target.id}`] = e.target.value
    this.setState({ timeslots: newState })
  }

  handleSubmit(){
    let timeslots = { timeslots: this.state.timeslots}

    this.setState({ loading: true })

    if (this.props.configMode) {
      $.post('/customer_success/update_config', { timeslots: this.state.config }, (response) => {
        this.setState({ loading: false })
      })
    } else {
      $.post('/customer_success/update_schedule', {timeslots: this.state.timeslots }, (response) => {
        this.setState({ loading: false })
      })
    }
  }

  renderTableHeaders(){
    if (this.props.configMode) {
      return this.days.map(day => {
        return(
          <th
            key={day}
            data-weekday={day}
          >
            <p className='date-header'>
              {day}
            </p>
            <label className='quick-config'>
              <input
                className='appointment-checkbox'
                type='button'
                data-day={day}
                value={`Set all as ${day.slice(0, 3)}`}
                onClick={this.weekdayConfig}
              />
            </label>
          </th>
        )
      })
    } else {
      return this.state.currentWeek.map(day => {
        let currDate = this.days[new Date(day + ' 12:00:00').getDay()]
        return(
          <th key={day}>
            <p className='date-header'>
              {currDate}
            </p>
            <p className='date-header'>
              {day}
            </p>
            <div className={`quick-config-container_${day}`}>
              <label className='quick-config'>
                Day
                <input
                  className='appointment-checkbox'
                  type='button'
                  data-time='Day'
                  value={this.state.quickConfigButtons[`${day} Day`] ? 'ON' : 'OFF'}
                  onClick={this.quickConfigToggler}
                />
              </label>
              <label className='quick-config'>
                Morning
                <input
                  className='appointment-checkbox'
                  type='button'
                  data-time='Morning'
                  value={this.state.quickConfigButtons[`${day} Morning`] ? 'ON' : 'OFF'}
                  onClick={this.quickConfigToggler}
                />
              </label>
              <label className='quick-config'>
                Afternoon
                <input
                  className='appointment-checkbox'
                  type='button'
                  data-time='Afternoon'
                  value={this.state.quickConfigButtons[`${day} Afternoon`] ? 'ON' : 'OFF'}
                  onClick={this.quickConfigToggler}
                />
              </label>
            </div>
          </th>
        )
      })
    }
  }

  renderTimeslotRow(timeslot){
    let timeslots = this.props.configMode ? this.state.config : this.state.timeslots,
        headers = this.props.configMode ? this.days : this.state.currentWeek;

    return(
      <tr key={timeslot}>
        {
          headers.map(day => (
            <td key={`${day}-${timeslot}`}>
              {('0' + timeslot).slice(-7)}
              <input
                type='number'
                className='timeslot-input'
                id={`${day} ${timeslot}`}
                value={timeslots[`${day} ${timeslot}`] || 0}
                onChange={this.handleChange}
              />
            </td>
          ))
        }
      </tr>
    )
  }

  render(){
    let loading = this.state.loading,
        spinnerClass = loading ? 'fa fa-spinner fa-spin' : 'hidden'

    return (
      <div className='widget-container'>
        <i className={spinnerClass}/>
        <div className={loading ? 'hidden' : ''}>
          <table className='weekly-display'>
            <thead>
              <tr>
                {this.renderTableHeaders()}
              </tr>
            </thead>
            <tbody className='weekly-content'>
              {
                this.timeslots.map(
                  timeslot => this.renderTimeslotRow(timeslot)
                )
              }
            </tbody>
          </table>
          <div>
            <input
              type='button'
              onClick={this.props.toggleModal}
              value='Close'
            />
          </div>
        </div>
      </div>
    )
  }
}
