const initSelectInputs = () => {
  const selectObjects = document.querySelectorAll('select.rex-custom-select');

  removeExistingBuilds();

  selectObjects.forEach(select => {
    const container = createSelectContainer();
    const newSelect = createSelectInput();
    const optionContainer = createOptionContainer(select.options);
    let classes = [];

    select.classList.forEach((v, k) => {
      if (!(/^rex/).test(v)) classes.push(`rex-${v}`);
    });

    container.classList.add(...classes);
    container.appendChild(newSelect);
    container.appendChild(optionContainer);

    select.classList.add('hidden');
    select.parentElement.appendChild(container);

    setSelectedValue(select.value, container);
  });
}

// private

const removeExistingBuilds = () => {
  const nodes = document.querySelectorAll('.rex-select-container');

  nodes.forEach(n => n.remove());
}

const createSelectContainer = () => {
  const container = document.createElement('div');

  container.classList.add('rex-select-container');

  return container;
}

const createSelectInput = () => {
  const select = document.createElement('div');

  select.classList.add('rex-select');
  select.addEventListener('click', toggleOptions);

  return select;
}

const createOptionContainer = (options) => {
  const optionContainer = document.createElement('div');
  const newOptions = createOptions(options);

  optionContainer.classList.add('rex-select-dropdown', 'hidden');
  newOptions.forEach(opt => optionContainer.appendChild(opt));

  return optionContainer;
}

const createOptions = (options) => {
  let newOptions = [];

  Array.from(options).forEach((option, idx) => {
    const opt = document.createElement('div');

    if (option.disabled) opt.classList.add('disabled');

    opt.classList.add('rex-option');
    opt.setAttribute('data-value', option.value);
    opt.innerHTML = option.innerHTML;

    if (!opt.classList.contains('disabled')) opt.addEventListener('click', updateSelect);

    newOptions.push(opt);
  });


  return newOptions;
}

const updateSelect = (event) => {
  const container = event.target.closest('.rex-select-container');
  const dropdown = container.querySelector('.rex-select-dropdown');
  const rexSelect = container.querySelector('.rex-select');
  const select = container.parentElement.querySelector('select.rex-custom-select');

  dropdown.classList.add('hidden');

  rexSelect.innerHTML = event.target.innerHTML;
  select.value = event.target.dataset.value;

  select.dispatchEvent(new Event('change', { bubbles: true }));
}

const toggleOptions = (event) => {
  const dropdown = event.target.parentElement.querySelector('.rex-select-dropdown');

  if (dropdown.classList.contains('hidden')) {
    dropdown.classList.remove('hidden');
  } else {
    dropdown.classList.add('hidden');
  }
}

const setSelectedValue = (value, container) => {
  container.querySelector(`.rex-option[data-value="${value}"]`).click();
}

export { initSelectInputs };
