import React                          from 'react';
import ReactDOM                       from 'react-dom';
import CustomerSuccess                from './CustomerSuccess';
import CustomerSuccessBookAppointment from './book_appointment/CustomerSuccessBookAppointment';

/**
 * Initialize the CustomerSuccess system.
 */

export function initialize(containerElement, options) {
  let component = React.createElement(CustomerSuccess, options);
  ReactDOM.render(component, containerElement);
}

export function initializeAppointment(containerElement, options) {
  let component = React.createElement(CustomerSuccessBookAppointment, options);
  ReactDOM.render(component, containerElement);
}