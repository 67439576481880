import { Component } from 'react';

export default class CustomerSuccessAppointmentSlot extends Component {
  constructor(props){
    super(props);
  }

  render(){
    let { timeSlot, welcomeTeamSchedule, selectSlot, timeZone, selectedSlot, currentUserId } = this.props;
    let time = Util.dateTimetoTime(timeSlot.time);
    let offsetTime = Util.dateTimetoTime(timeSlot.time, timeZone.offset + 8);
    if (timeZone.time_zone_short === "AZT") {
      offsetTime = Util.dateTimetoTime(new Date(timeSlot.time).toLocaleString('en-US', { timeZone: "America/Phoenix" }));
    }
    let slotClass = time === selectedSlot ? 'cs-appt-time-slot selected' : 'cs-appt-time-slot';
    let appointmentAvailable = () => {
      let currentTimeSlot = welcomeTeamSchedule[time];
      const incomplete_appointments = [];
      timeSlot.appointments.forEach( appointment => {
        if (appointment.status === 'incomplete') {
          incomplete_appointments.push(appointment);
        }
      })
      if(incomplete_appointments.length && currentUserId){
        if(incomplete_appointments.map(appt => appt.assigned_to).includes(currentUserId)) return false;
      }
      if(currentTimeSlot){
        return (currentTimeSlot.max_appointments > incomplete_appointments.length)
      }
      return false;
    }

    if(appointmentAvailable()){
      return(
        <div className={slotClass} onClick={() => selectSlot(time, offsetTime)}>
          { offsetTime + ' ' + timeZone.time_zone_short }
        </div>
      )
    } else {
      return <div />
    }
  }
}
