import React, { useEffect } from 'react';

const Client = ({ client }) => {
  const {
    name,
    email,
    city,
    state,
    validation,
    rating,
    review,
    created_at,
  } = client;

  const validationKlass = validation.toLowerCase();
  const fixedRating = rating.toFixed(1);
  const ratingKlass = `rated-stars-${fixedRating}`.replace(/[^a-z0-9]/gi, '-');
  const formattedDate = created_at ? moment.utc(created_at).format('MMMM Do, YYYY') : undefined;

  useEffect(() => {
    const validationPills = document.querySelectorAll('.validation-result');

    [...validationPills].forEach(validationPill => {
      validationPill.addEventListener('profile_review.validation_change', handleValidationChange);
    });
  });

  function handleValidationChange(event) {
    const { currentTarget } = event;
    const { validation } = event.detail;

    currentTarget.classList.remove('valid', 'invalid', 'unvalidated');
    currentTarget.classList.add(validation);
    currentTarget.textContent = validation.toUpperCase();
  }

  return (
    <div className="col-md-12">
      <div className="client-details">
        <div className="row">
          <div className="col-md-8">
            <h2>{ name || 'N/A' }</h2>
            <div className="client-email mb-5">{ email }</div>
            <div className="review-created-on">{ formattedDate }</div>
          </div>
          <div className="col-md-4">
            <span className={ `validation-result pull-right ${validationKlass}` }>{ validation }</span>
          </div>
        </div>
        <div className="review-details my-15">
          <div className="rating-results">
            { fixedRating } <i className={ `star-rating ${ratingKlass} ml-5` }></i>
          </div>
          <div className="review">{ review }</div>
        </div>
      </div>
    </div>
  )
};

export default Client;
