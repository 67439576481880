import { Component } from 'react';

export default class BrandView extends Component {
  constructor(props){
    super(props);
  }

  render(){
    let { brands, brandsSelected } = this.props.state;
    let { history } = this.props;

    let historyContent = () => {
      if (history && history.length) {
        return (
          <div className="history">
            <h3>Brand History</h3>
            {history.map((log, idx) => {
              return (
                <div className="history-log" key={idx}>
                  <div className="brand">{log.monitor_name}:</div>
                  <div className="date">{log.created_at}</div>
                </div>
              )
            })}
          </div>
        )
      }
    }

    let brandSelections = () => {
      return (
        <div className="checkboxes">
          <h3>Select Brand</h3>
          {brands.map((brand, idx) => {
            let brandNames = brandsSelected.map(b => { return b.name })
            return (
              <div className="checkbox" key={idx}>
                <input
                  type="checkbox"
                  checked={brandNames.includes(brand.name)}
                  onChange={() => this.props.updateList('brandsSelected', brand, 'name')}
                />
                <span>{brand.name}</span>
              </div>
            )
          })}
        </div>
      );
    }

    return (
      <div className="content">
        <div className="brand-content">
          { brandSelections() }
          { historyContent() }
        </div>
        <div className="prompt-options">
          <div className="prompt-cancel" onClick={this.props.resetState}>
            Back
          </div>
          <div className="prompt-confirm" onClick={this.props.loadNextView}>
            Next
          </div>
        </div>
      </div>
    )
  }
}