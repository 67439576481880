import React, { Component } from 'react';
import { connect } from 'react-redux';

import View from './view';

function mapStateToProps(state) {
  return {
    live: {
      timezone: state.live.get('submitting_agent_timezone'),
    },
  };
}

class Contact extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      live,
    } = this.props;

    return (
      <View
        live={ live }
      />
    );
  }
}

export default connect(mapStateToProps)(Contact);
