// eslint-disable-next-line import/prefer-default-export
const libphonenumber = require('libphonenumber-js');
const intlTelInput = require('intl-tel-input');

export const isMobile = () => window.matchMedia('only screen and (max-width: 760px)').matches;

export const notEmpty = (val) => val && val.length > 0;

export const stopPropagation = (e) => e.stopPropagation();

// This method does not fully support extensions
export const formatPhoneNumber = (number) => {
  if (number === null || number === '') { return ''; }
  let phoneNumber = null;

  // Check if in e.164 format already
  if (number[0] === '+') {
    // Check if American number
    if (number[1] === '1') {
      phoneNumber = libphonenumber.parsePhoneNumberFromString(number);
      return `+1 ${phoneNumber.formatNational()}`;
    }
    phoneNumber = libphonenumber.parsePhoneNumberFromString(number);
    return `${phoneNumber.formatInternational()}`;
  }

  // Assume it's an American number
  phoneNumber = libphonenumber.parsePhoneNumberFromString(`+1${number}`);
  return `+1 ${phoneNumber.formatNational()}`;
};

function getE164PhoneNumber() {
  if (this.isValidNumber()) {
    return this.getNumber();
  }

  const baseNumber = this.getNumber();
  // getNumber() sometimes returns numbers with the + dialcode even when invalid.
  // Detect this so we avoid adding the dialcode again
  if (baseNumber.startsWith('+')) {
    return baseNumber;
  }

  const { dialCode } = this.getSelectedCountryData();
  return `+${dialCode}${baseNumber}`;
}

// targetSelectors: A list of selectors that point to the fields
// initialCountries: Either a String or a list of initial countries (one for each field)
// callback: Callback function triggerd on phone number or country change
// Callback will be given the event object and the IntlTelInput object
export const initPhoneCountrySelectors = (targetSelectors, initialCountries, callback) => {
  targetSelectors.forEach((sel, index) => {
    const target = document.querySelector(sel);
    if (!target) return;

    let initialCountry;
    if (initialCountries.constructor === Array) {
      initialCountry = initialCountries[index];
    } else {
      initialCountry = initialCountries;
    }
    const iti = intlTelInput(target, { preferredCountries: ['US', 'CA'] });

    iti.getE164PhoneNumber = getE164PhoneNumber;
    target.addEventListener('keyup', (e) => { callback(e, iti); });
    iti.setCountry(initialCountry);
    iti.telInput.addEventListener('countrychange', (e) => { callback(e, iti); });
    const event = new CustomEvent('keyup');
    target.dispatchEvent(event);
  });
};

export const timer = (refObj, updateTime) => {
  const start = moment().startOf('day');
  const interval = 1000;

  if (refObj.current) {
    clearInterval(refObj.current);
  }

  refObj.current = setInterval(() => {
    start.add(interval, 'milliseconds');
    updateTime(start.format('HH:mm:ss').replace(/^(?:00:)?0?/, ''));
  }, interval);
}
