import  { Component } from  'react';
import mainIcon from '../images/call-connected.svg';
import * as Utilities from '../Utilities';

export default class Conference extends Component {
  constructor(props){
    super(props);

    this.state = {
      secondsSinceCallStart: null,
      intervalTimer: null
    };
  }

  componentDidMount() {
    this.createTimer();
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalTimer);
  }

  createTimer() {
    this.setState({ intervalTimer:
      setInterval(() => {
        this.setState({ secondsSinceCallStart: Utilities.calculateSecondsSinceCallStart(this.props.conferenceCallStartTime)});
      }, 500)
    });
  }

  render(){
    return (
      <div className="button conference">
        <div className='row'>
          <div className='col-sm-2'>
            <img src={ mainIcon }/>
          </div>
          <div className='col-sm-6'>
            <div className='row status-text top-row'>
              Conference <span className='timer-text'>{ this.state.secondsSinceCallStart }</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
