const Search = (props) => {
  const {
    lookupPath,
    setRequestingAreas,
    updateState,
  } = props;

  function fetchServiceAreas(event) {
    event.preventDefault();

    const form = event.target.closest('form');
    const input = form.querySelector('input');
    const { value } = input;

    if (validateSearch(event)) {
      setRequestingAreas(true);
      updateState('currentVisible', false);

      fetch(
        `${lookupPath}${value}`,
        {
          method: 'GET',
          credentials: 'same-origin',
        }
      )
      .then(response => response.json())
      .then(response => {
        setRequestingAreas(false);

        if (response.success) {
          updateState('serviceAreas', response.marketing_areas);
          input.value = null;
        } else {
          updateState('header', { klass: 'danger', message: response.errors.join(', ') });
        }
      });
    }
  }

  function validateSearch(event) {
    const form = event.target.closest('form');
    const formGroup = form.querySelector('.form-group');
    const input = form.querySelector('input');
    const valid = /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/.test(input.value);

    formGroup.classList.remove('has-error');

    if (valid) {
      updateState('header');
    } else {
      formGroup.classList.add('has-error');
      updateState('header', { klass: 'danger', message: 'Invalid zip code' });
    }

    return valid;
  }

  return (
    <form className='form-inline'>
      <div className='form-group mr-15'>
        <input type='text' className='form-control' id='zip-code' name='zip-code' />
      </div>

      <button type='submit' className='btn btn-md btn-primary' onClick={ fetchServiceAreas }>Search</button>
    </form>
  );
};

export default Search;
