import CancelledIcon from '../../../../LiveAccountManagement/images/cancel_success.png';

const _heading = (pastEndDate) => {
  if (pastEndDate) {
    return 'PRIME Subscription Successfully Cancelled';
  } else {
    return 'PRIME Subscription Cancellation Successfully Scheduled';
  }
};

const _textBody = (pastEndDate) => {
  if (pastEndDate) {
    return (
      <div>
        <p>The agent will retain access to their PRIME Dashboard and services until their next billing date - at which point their subscription will be cancelled.</p>

        <p>Once cancelled:</p>

        <ul>
          <li>The agent will no longer be charged the monthly fee</li>
          <li>The agent will not have access to their PRIME Dashboard or services</li>
          <li>The agent will be able to access their PRIME leads in their CRM account</li>
        </ul>
      </div>
    );
  } else {
    return (
      <div>
        <ul>
          <li>The agent will continue to be charged $99/monthly until the plan end-date</li>
          <li>The agent will retain access to their PRIME Dashboard and services until the plan end-date</li>
          <li>On their plan end-date, the subscription will be cancelled</li>
        </ul>

        <p>On the agent's plan end-date:</p>

        <ul>
          <li>The agent will no longer be charged the monthly fee</li>
          <li>The agent will not have access to their PRIME Dashboard or services</li>
          <li>The agent will be able to access their PRIME leads in their CRM account</li>
        </ul>
      </div>
    );
  }
};

const Success = (props) => {
  const {
    pastEndDate,
    toggleModal,
  } = props;

  return (
    <div id='cancel-finalize'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ CancelledIcon } />
      </div>

      <div className='rex-modal-heading'>
        { _heading(pastEndDate) }
      </div>

      <div className='rex-modal-contents'>
        <div className='rex-modal-subheading full-width'>
          { _textBody(pastEndDate) }
        </div>

        <div className='rex-modal-actions rex-modal-actions__centered'>
          <button
            className='rex-modal-action'
            onClick={ () => toggleModal(false) }
          >
            CLOSE THIS WINDOW
          </button>
        </div>
      </div>
    </div>
  )
};

export default Success;
