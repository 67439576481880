import { Component } from 'react';

import Form from './emergency_exclusion_areas/Form';
import ExcludedAreas from './emergency_exclusion_areas/ExcludedAreas';
import SearchResults from './emergency_exclusion_areas/SearchResults';
import './emergency_exclusion_areas/styles/index.scss';

export default class EmergencyExclusionAreas extends Component {
  constructor() {
    super();

    this.state = {
      currentAreas: [],
      searchAreas: [],
      viewExclusions: true,
      fetching: true
    };

    this.setAttribute = this.setAttribute.bind(this);
    this.toggleFetching = this.toggleFetching.bind(this);
    this.viewExcluded = this.viewExcluded.bind(this);
  }

  componentDidMount() {
    this.fetchCurrentAreas();
  }

  setAttribute(attr, value) {
    this.setState({ [attr]: value });
  }

  toggleFetching(bool) {
    this.setState({ fetching: bool });
  }

  viewExcluded(bool) {
    this.setState({ viewExclusions: bool });
  }

  fetchCurrentAreas() {
    fetch(
      '/product_dashboard/emergency_exclusion_areas',
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' }
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        this.setAttribute('currentAreas', response.centroids);
        this.toggleFetching(false);
      } else {
        Util.createFlash(response.errors.join(', '), 'danger');
      }
    })
    .catch(error => console.log(error));
  }

  render() {
    const {
      currentAreas,
      searchAreas,
      viewExclusions,
      fetching,
    } = this.state;

    return (
      <div className='dashboard-tool' id='emergency-exclusion-areas'>
        <div className='emergency-exclusion-areas'>
          <Form
            setAttribute={ this.setAttribute }
            toggleFetching={ this.toggleFetching }
            viewExcluded={ this.viewExcluded }
          />

          {
            viewExclusions ?
            <ExcludedAreas
              fetching={ fetching }
              currentAreas={ currentAreas }
              setAttribute={ this.setAttribute }
              toggleFetching={ this.toggleFetching }
            />
            :
            <SearchResults
              fetching={ fetching }
              currentAreas={ currentAreas }
              searchAreas={ searchAreas }
              setAttribute={ this.setAttribute }
              viewExcluded={ this.viewExcluded }
            />
          }
        </div>
      </div>
    );
  }
}
