import React, { Component } from 'react'
import request from 'superagent';

export default class StuckLeadModal extends Component {
  constructor(props) {
    super(props);

    this.trainCars = [
      'NewLead::HeadWorker',
      'NewLead::InsertWorker',
      'NewLead::CleanerWorker',
      'NewLead::PolicyWorker',
      'NewLead::DataScience::PreflightQualificationWorker',
      'NewLead::ChannelDelegatorWorker',
      'NewLead::ReviewingWorker',
    ]

    this.closeModal = this.closeModal.bind(this);
    this.submitLead = this.submitLead.bind(this);
    this.saveToNewLead = this.saveToNewLead.bind(this);
    this.sendToTrainCar = this.sendToTrainCar.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.reviewChanges = this.reviewChanges.bind(this);
  }

  componentDidMount() {
    $('#stuck-leads-modal').draggable();
  }

  closeModal() {
    this.props.setState({
      selectedLeadIdx: null,
      error: null,
      success: null,
      modalView: 'edit',
      changes: [],
    })
  }

  saveChanges() {
    const { lead, setState } = this.props;
    const el = document.getElementById('stuck-lead-data')
    const val = el.value;

    try {
      lead.data = JSON.parse(val)
      setState({
        success: 'Changes Saved!',
        error: '',
      })
    } catch(e) {
      setState({ error: e.message })
    }
  }

  reviewChanges() {
    const { initLead, lead, setState, objectsAreSame } = this.props;
    const keys = Object.keys(initLead.data);
    const changes = []

    keys.forEach(k => {
      const subkeys = Object.keys(initLead.data[k])

      subkeys.forEach(sk => {
        if (lead.data[k][sk] && typeof lead.data[k][sk] === 'object') {
          if (!objectsAreSame(lead.data[k][sk], initLead.data[k][sk])) {
            changes.push(`${k} > ${sk}: ${JSON.stringify(initLead.data[k][sk])}  -->  ${JSON.stringify(lead.data[k][sk])}`);
          }
        } else if (lead.data[k][sk] !== initLead.data[k][sk]) {
          changes.push(`${k} > ${sk}: ${initLead.data[k][sk]}  -->  ${lead.data[k][sk]}`);
        }
      })
    })
    setState({
      errors: null,
      success: null,
      modalView: 'reviewing',
      changes,
    })
  }

  sendToTrainCar(e){
    this.props.setState({ submitTrainCar: e.target.value })
  }

  submitLead(e) {
    const { lead, fetchStuckLead, state } = this.props;
    const { selectedLeadIdx, submitTrainCar } = state;

    const options = {
      new_lead_id: lead.id,
      new_lead_data: JSON.stringify(lead.data),
      train_car: submitTrainCar || lead.current_train_car,
    }

    request
      .post('/stuck_leads/submit_stuck_lead/')
      .query(options)
      .then(this.closeModal)
      .then(setTimeout(() => fetchStuckLead(lead.id, selectedLeadIdx, e), 5000))
      .catch(err => console.warn(JSON.parse(err)))
  }

  saveToNewLead() {
    const { lead } = this.props;

    const options = {
      new_lead_id: lead.id,
      new_lead_data: JSON.stringify(lead.data),
    }

    request
      .post('/stuck_leads/save_stuck_lead/')
      .query(options)
      .then(this.closeModal())
      .catch(err => console.warn(JSON.parse(err)))
  }

  headerText() {
    const { lead } = this.props;
    return (
      <span>
        {`Lead: ${lead.data.lead.first_name} ${lead.data.lead.last_name}`}
        &nbsp;
        <a
          target="_blank"
          href={`/l/${lead.lead_id}`}
          onClick={(e) => e.stopPropagation()}
        >
          {`(${lead.lead_id})`}
        </a>
      </span>
    )
  }

  headerButton() {
    const { state: { modalView }, setState } = this.props;

    switch (modalView) {
      case 'reviewing':
        return (
          <input
            type="submit"
            value="back"
            onClick={() => setState({ modalView: 'edit' })}
          />
        );
      default:
        return (
          <input
            type="submit"
            value="close"
            onClick={this.closeModal}
          />
        )
    }
  }

  renderContent() {
    const { modalView, changes } = this.props.state;
    const { lead } = this.props;

    switch(modalView) {
      case 'reviewing':
        return (
          <div className="modal-review">
            {changes.length > 0
              ? (
                <ul>
                  {changes.map((line, idx) => <li key={idx}>{line}</li>)}
                </ul>
              )
              : 'No changes made'
            }
          </div>
        );
      default:
        return (
          <textarea
            id='stuck-lead-data'
            className='modal-textarea'
            defaultValue={JSON.stringify(lead.data, undefined, 4) || ''}
          />
        )
    }
  }

  renderFooter() {
    const { modalView, success, error } = this.props.state;
    const { lead } = this.props;

    switch(modalView) {
      case 'reviewing':
        return (
          <div>
            <select
              name="train-car"
              defaultValue={lead.current_train_car}
              onChange={this.sendToTrainCar}
            >
              {this.trainCars.map((car, idx) =>
                <option key={idx}>{car}</option>
              )}
            </select>
            <input
              type="submit"
              value="Start new job"
              onClick={this.submitLead}
            />
            <input
              type="submit"
              value="Save Changes"
              onClick={this.saveToNewLead}
            />

          </div>
        );
      default:
        return (
          <React.Fragment>
            {success && <div className='modal-success'>{success}</div>}
            {error && <div className='modal-error'>{error}</div>}
            <input
              type="button"
              value="Save"
              onClick={this.saveChanges}
            />
            <input
              type="button"
              value="Review"
              onClick={this.reviewChanges}
            />
          </React.Fragment>
        );
    }
  }

  render() {
    return (
      <div className="modal-outer" onClick={this.closeModal}>
        <div id="stuck-leads-modal" className="modal-container" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            {this.headerText()}
            {this.headerButton()}
          </div>
          <div className="modal-content">{this.renderContent()}</div>
          <div className="modal-footer">{this.renderFooter()}</div>
        </div>
      </div>
    )
  }
};
