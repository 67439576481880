import React, { Component } from 'react';
import { store } from '../../../index';

import StandardInput from './index';

import View from './view';

export default class InputContainer extends StandardInput {
  constructor(props) {
    super(props);
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { dispatch } = store;
    const city = store.getState().info.get('city');
    const country = store.getState().info.get('country');
    const state = store.getState().info.get('state');

    const data = {
      city,
      country,
      state,
    };

    data[name] = value;

    dispatch({
      type: 'GET_VERIFIED_LOCATION',
      data,
    });

    this.setInfoField(name, value);
  }
}
