import React from 'react';

const Note = (props) => {
  const { data, handleChange } = props;

  const { note, submitting } = data;

  return (
    <div className='active-dialer-lead-section qual-note'>
      <div className='section-row full-width'>
        <div className='section-key'>Add to Qual Note (Visible to REX Only)</div>
        <div className='section-value'>
          <textarea
            disabled={ submitting }
            name='qual_note'
            value={ note || '' }
            onChange={ handleChange }
          />
        </div>
      </div>
    </div>
  );
};

export default Note;
