import React, { Component } from 'react';
import { store } from '../../../index';

import BaseInputContainer from '../index';

import View from './view';

export default class InputContainer extends BaseInputContainer {
  constructor(props) {
    super(props);

    this.type = 'date';
  }

  validateField(name, value) {
    const { dispatch } = store;
    const text_consent = store.getState().info.get('text_consent');
    const contact_time = store.getState().info.get('contact_time');

    dispatch({
      type: 'VALIDATE_CONTACT_DATE_TIME',
      data: {
        text_consent,
        contact_date: value,
        contact_time,
      },
    })
  }

  render() {
    const {
      classNames,
      display,
      id,
      index,
      property
    } = this.props;

    const {
      focused,
      valid,
      value,
     } = this.state;

    const minDate = moment(new Date()).format('YYYY-MM-DD');

    return (
      <View
        classNames={ classNames }
        display={ display }
        focused={ focused }
        id={ id }
        index={ index }
        name={ property }
        type={ this.type }
        valid={ valid }
        value={ value }
        min={ minDate }

        onChange={ this.handleChange }
        onFocus={ this.handleOnFocus }
      />
    );
  }
}
