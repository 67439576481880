import React from 'react';
import RealEstateAgentsLogo from '../../../../../../images/realestateagents_logo.png';

const AgentInfo = (props) => {
  const {
    newAgentId,
    agentInfo,
    click2call,
    click2call_path,
    opsUser,
    salesStatus,
    activatePrime,
    scheduleFollowUp,
    toggleNewAgentForm,
    copyDialerLink,
  } = props;

  const {
    prospect_id,
    prospect_type,
    display_number,
    location,
    name,
    number,
    do_not_call,
    email,
    timezone,
    utc,
    rea_claimed,
  } = agentInfo;

  const {
    calendly_url,
  } = opsUser;

  const {
    call_attempt,
    pitched_status,
    sales_cycle,
    sales_status,
    spots_remaining,
  } = salesStatus;

  const options = {};
  const anAgent = /^Agent$/.test(prospect_type);
  const newAgentDisabled = (!anAgent && !newAgentId) ? '' : 'disabled';
  const dncKlass = do_not_call ? 'do-not-call' : '';

  if (!anAgent && !newAgentId) options['disabled'] = 'disabled';

  function handleFormToggle(event) {
    const { currentTarget } = event;

    if (currentTarget.classList.contains('disabled')) return;

    toggleNewAgentForm(true);
  }

  function buildAgentLink() {
    const opts = {};
    const hrefLink = `/a/${newAgentId || prospect_id}`;

    opts['className'] = (!anAgent && !newAgentId) ? 'agent-name no-agent-record' : 'agent-name';
    if (anAgent || newAgentId) opts['href'] = hrefLink;
    if (anAgent || newAgentId) opts['target'] = '_blank';

    return <a { ...opts }>{ name }</a>;
  }

  function buildAgentPill() {
    const label = anAgent ? prospect_type : 'Top Altos Agent';

    return <span className="agent-pill" onClick={ copyDialerLink }><i className="fa fa-globe mr-5"></i>{ label }</span>;
  }

  function showAddContactModal(event) {
    event.preventDefault();

    const modal = document.getElementById('add-rta-contact-container');
    const modalEvent = new CustomEvent('add_rta.contact', { detail: { rta_agent_id: prospect_id, source: 'prime_dialer' } });

    modal.dispatchEvent(modalEvent);
  }

  function showReaLogo() {
    return <img className='rea-logo' src={ RealEstateAgentsLogo } />
  }

  function handleClick2Call(event) {
    event.preventDefault();

    if (do_not_call) return;

    fetch(
      click2call_path,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...click2call, push: true }),
      }
    )
    .catch(error => {
      const context = { errorType: 'rta_dialer_place_call_to_prospect', ...click2call }

      logError(context, error);
    });
  }

  function newEmailLink() {
    const params = {
      email: {
        first_name: agentInfo.name,
        last_name: '',
        to_email: agentInfo.email,
      },
      object_id: agentInfo.prospect_id,
      object_type: agentInfo.prospect_type,
    };

    return `/emails/new?${Util.toQueryString(params)}`;
  }

  function displayNumber() {
    return do_not_call ? 'DO NOT CALL' : display_number;
  }

  return (
    <div className="dialer-agent-details-info">
      <div className="agent-details-header mb-10">
        <div>
          { buildAgentLink() }
        </div>
        <div className="justify-content-right">
          { rea_claimed && showReaLogo() }
          { buildAgentPill() }
        </div>
      </div>
      <div className="agent-details-wrapper">
        <div className="agent-details">
          <a className={ `agent-contact-link rex-link ${dncKlass}`} onClick={ handleClick2Call }>
            <i className="agent-details-icon phone" />
            <div className="phone-number">
              { displayNumber() }
            </div>
          </a>
        </div>
        <div className="agent-details">
          { !anAgent && (<div><span className={ `new-agent-toggle ${newAgentDisabled}` } onClick={ handleFormToggle }>+ Create Agent</span></div>) }
        </div>
      </div>
      <div className="agent-details-wrapper">
        <div className="agent-details">
          <a className="agent-contact-link rex-link" href={ newEmailLink() } data-remote="true" data-method="get">
            <i className="agent-details-icon email" />
            <div>{ email }</div>
          </a>
        </div>
        <div className="agent-details">
          { !anAgent && <a href="#" className="rex-link" onClick={ showAddContactModal }>+ Add Contact</a> }
        </div>
      </div>
      <div className="agent-details-wrapper">
        <div className="agent-details">
          <i className="agent-details-icon map-marker" />
          <div>{ location }</div>
        </div>
      </div>
      <div className="agent-details-wrapper">
        <div className="agent-details">
          <i className="agent-details-icon clock" />
          <div>{ timezone } <span>| UTC { utc }</span></div>
        </div>
      </div>
      <hr />
      <table>
        <thead>
          <tr>
            <th>Sales Status</th>
            <th>Sales Cycle</th>
            <th>Call Attempt</th>
            <th>Pitched Status</th>
            <th>Spots Remaining</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{ sales_status }</td>
            <td>{ sales_cycle }</td>
            <td>{ call_attempt }</td>
            <td>{ pitched_status }</td>
            <td>{ spots_remaining }</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <div className="button-container">
        <a
          className="dialer-button blue filled"
          href={ calendly_url }
          target="_blank"
        >
          Schedule appointment
        </a>
        <button
          className="dialer-button blue"
          onClick={ scheduleFollowUp }
        >
          Schedule follow-up
        </button>
        <button id="activate-prime-btn" className="dialer-button blue" onClick={ activatePrime } { ...options }>
          Activate PRIME
        </button>
      </div>
    </div>
  )
};

export default AgentInfo;
