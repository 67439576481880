import { Component }  from 'react';
import request        from 'superagent';
import BrandView      from './monitor_views/BrandView';
import AdminView      from './monitor_views/AdminView';
import ExecutiveView  from './monitor_views/ExecutiveView';
import ReviewView     from './monitor_views/ReviewView';

export default class MonitorAgent extends Component {
  constructor(props){
    super(props);

    this.state                  = this.initialState();
    this.loadBrands             = this.loadBrands.bind(this);
    this.loadSalesAdmins        = this.loadSalesAdmins.bind(this);
    this.loadSalesExecutives    = this.loadSalesExecutives.bind(this);
    this.returnToBrands         = this.returnToBrands.bind(this);
    this.returnToAdmins         = this.returnToAdmins.bind(this);
    this.returnToExecutives     = this.returnToExecutives.bind(this);
    this.updateList             = this.updateList.bind(this);
    this.updateState            = this.updateState.bind(this);
    this.updateBrandList        = this.updateBrandList.bind(this);
    this.createSyndications     = this.createSyndications.bind(this);
  }

  initialState(){
    return {
      brands: [],
      admins: [],
      executives: [],
      brandsSelected: [],
      adminsSelected: [],
      executivesSelected: [],
      submitting: false,
      brandsLoading: false,
      adminsLoading: false,
      executivesLoading: false,
      view: 'brand'
    }
  }

  componentDidMount(){
    this.loadBrands();
  }

  returnToBrands(){
    this.setState({ adminsSelected: [], view: 'brand' })
  }

  returnToAdmins(){
    this.setState({ executivesSelected: [], view: 'admin' })
  }

  returnToExecutives() {
    this.setState({ view: 'executive' })
  }

  showLoading() {
    return this.state.submitting
      ? 'modal-overlay-submitting'
      : 'hidden';
  }

  createSyndications() {
    this.setState({ submitting: true });
    let { executivesSelected, adminsSelected } = this.state;
    let selectedExecs = executivesSelected.map(exec => {
      return {
        user_data_id: exec.id,
        admin_id: exec.parent_id,
        user_id: exec.user_id,
        brand_id: exec.brand_id
      }
    });
    let selectedAdmins = adminsSelected.map(admin => {
      return {
        user_data_id: admin.id,
        admin_id: admin.user_id,
        user_id: admin.user_id,
        brand_id: admin.brand_id
      }
    });

    let options = {
      agent_id: this.props.agentId,
      task: {},
      executives: JSON.stringify(selectedExecs),
      sales_managers: JSON.stringify(selectedAdmins),
    }

    return(
      request
        .post('/agent_insights/monitors/submit_form')
        .query(options)
        .then(() => this.props.loadInitialData())
        .then(() => this.props.closeModal())
    );
  }

  updateList(listName, listItem, listItemProperty){
    let list = this.state[listName];
    let listPropertyValues = list.map(listItem => { return listItem[listItemProperty]});
    let index = listPropertyValues.indexOf(listItem[listItemProperty]);
    index >= 0
      ? list.splice(index, 1)
      : list.push(listItem);

    this.setState({ [listName]: list });
  }

  updateState(object){
    this.setState(object)
  }

  updateBrandList(listName, listItem, listItemProperty){
    if(listItem.name === 'Unmonitored'){
      this.setState({ brandsSelected: [listItem] });
    } else if (this.state.brandsSelected[0] && this.state.brandsSelected[0].name === 'Unmonitored') {
      this.setState({ brandsSelected: [] }, () => {
        this.updateList(listName, listItem, listItemProperty);
      });
    } else {
      this.updateList(listName, listItem, listItemProperty);
    }
  }

  modalHeader(){
    switch (this.state.view) {
      case 'brand':
        return <h3>Brands</h3>;
        break;
      case 'admin':
        return <h3>Select Admins</h3>;
        break;
      case 'executive':
        return <h3>Select Executives</h3>;
        break;
      case 'review':
        return <h3>Review Selection</h3>;
        break;
    }
  }

  modalContent(){
    switch(this.state.view) {
      case 'brand':
        return(
          <BrandView
            state={this.state}
            history={this.props.history}
            updateBrandList={this.updateBrandList}
            updateList={this.updateList}
            resetState={this.props.resetState}
            loadNextView={this.loadSalesAdmins}
          />
        );
        break;
      case 'admin':
        return(
          <AdminView
            state={this.state}
            type='monitorList'
            updateList={this.updateList}
            loadPrevView={this.returnToBrands}
            loadNextView={this.loadSalesExecutives}
          />
        );
      case 'executive':
        return(
          <ExecutiveView
            state={this.state}
            updateState={this.updateState}
            updateList={this.updateList}
            returnToAdmins={this.returnToAdmins}
          />
        );
        break;
      case 'review':
        return(
          <ReviewView
            state={this.state}
            returnToBrands={this.returnToBrands}
            returnToExecutives={this.returnToExecutives}
            createSyndications={this.createSyndications}
          />
        );
        break;
    }
  }

  loadBrands() {
    this.setState({ submitting: true });

    request
      .get('/agent_insights/monitors/fetch_agent_monitors')
      .then(({ body }) => {
        this.setState({
          submitting: false,
          brands: body.monitors || []
        });
      });
  }

  loadSalesAdmins() {
    let { brandsSelected } = this.state;
    let { agentId } = this.props;

    if (brandsSelected.length < 1) return;

    this.setState({ submitting: true });
    let brandNames = brandsSelected.map(b => { return b.name })
    let options = { agent_id: agentId, brands: JSON.stringify(brandNames) };

    request
      .get('/agent_insights/monitors/fetch_admins_in_monitors')
      .query(options)
      .then(({ body }) => {
        this.setState({
          admins: body.admins || [],
          view: 'admin',
          submitting: false,
        });
      });
  }

  skipToReview(){
    this.setState({ view: 'review' });
  }

  listUnion(list1, list2){
    let union = [];
    list1.forEach(item1 => list2.forEach(item2 => {
        if (item1.id === item2.id) union.push(item1)
      })
    )
    return union;
  }

  loadSalesExecutives() {
    this.setState({ submitting: true });
    let { adminsSelected } = this.state;
    let { agentId } = this.props;
    let managerUserIds = adminsSelected.map(admin => { return admin.user_id });

    if (managerUserIds.length){
      request
        .get('/agent_insights/monitors/fetch_sales_executives_from_managers')
        .query({ agent_id: agentId, sales_managers_user_ids: JSON.stringify(managerUserIds) })
        .then(({ body }) => {
          this.setState({
            view: 'executive',
            executives: body.sales_executives || [],
            submitting: false
          });
        })
    } else {
      this.setState({
        view: 'executive',
        executives: [],
        submitting: false
      });
    }
  }

  render(){
    return(
      <monitor-agent>
        <div className="header">{ this.modalHeader() }</div>
        { this.modalContent() }
        <div className={this.showLoading()}>
          <i className='fa fa-refresh fa-spin fa-5x fa-fw'></i>
          <span className='sr-only'>Loading...</span>
        </div>
      </monitor-agent>
    )
  }
}
