import { ActionCableProvider } from 'react-actioncable-provider';
import LiveCallControl from './LiveCallControl';

const LiveCallControlContainer = ({ data, updateState, phoneStrategy }) => {
  const meta = document.querySelector('meta[name="action-cable-url"]');
  const wsURL = meta.content;
  const cable = ActionCable.createConsumer(wsURL);

  return (
    <ActionCableProvider cable={cable}>
      <LiveCallControl
        data          = { data }
        updateState   = { updateState }
        phoneStrategy = { phoneStrategy }
      />
    </ActionCableProvider>
  )
}

export default LiveCallControlContainer;
