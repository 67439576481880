import React from 'react';
import ReactDOM from 'react-dom';
import AgentInsight from './AgentInsight';

/**
 * Initialize the AgentInsights system.
 */

 export function initialize(containerElement, options) {
   let component = React.createElement(AgentInsight, options);
   ReactDOM.render(component, containerElement);
 }