import { Component } from 'react';

export default class AgentLeadEdit extends Component {
  constructor(props){
    super(props);
    let { questionResponses, timeZone, agent, user, agentCompany, agentFeatures, welcomeCallTask } = props;
    this.state = {
      agent: agent,
      user: user,
      timeZone: timeZone,
      responses: questionResponses,
      agentCompany: agentCompany ? agentCompany : {},
      agentFeatures: agentFeatures,
      welcomeCallTask: welcomeCallTask
    };

    this.getOptions = this.getOptions.bind(this);
    this.updateAgentLead = this.updateAgentLead.bind(this);
    this.updateEmail = this.updateEmail.bind(this);
    this.updateTimeZone = this.updateTimeZone.bind(this);
    this.updateAssignee = this.updateAssignee.bind(this);
  }

  componentDidMount(){
    this.syncPropsAndState();
  }

  componentWillReceiveProps(nextProps){
    this.syncPropsAndState(nextProps);
  }

  syncPropsAndState(props = this.props){
    let { questionResponses, timeZone, agent, user, agentCompany, agentFeatures } = props;

    this.setState({
      questionResponses: this.dup(questionResponses),
      timeZone: this.dup(timeZone),
      agent: this.dup(agent),
      user: this.dup(user),
      agentCompany: this.dup(agentCompany),
      agentFeatures: this.dup(agentFeatures)
    });
  }

  dup(target){
    return Object.assign({}, target);
  }

  preventBubbling(e){
    e.stopPropagation()
  }

  updateAgentLead(){
    let { user, agent, agentCompany, agentFeatures, responses, timeZone, welcomeCallTask } = this.state;
    let { currentUser } = this.props;

    agent.updater_id = currentUser.id;
    user.updater_id = currentUser.id;
    agentCompany.updater_id = currentUser.id;
    let updateParams = {
      id: agent.id,
      agent: agent,
      user: {
        email: user.email,
        gmt_offset: timeZone.offset,
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        phone_home: user.phone_home,
        phone_mobile: user.phone_mobile,
        phone_office: user.phone_office,
        updater_id: currentUser.id
      },
      agent_company: agentCompany,
      features: agentFeatures
    };
    if(this.props.afssId) updateParams.responses = responses;
    if(welcomeCallTask && welcomeCallTask.id){
      Util.updateWelcomeTaskAssignee({
        task_id: welcomeCallTask.id,
        assigned_to: welcomeCallTask.assigned_to
      })
    }
    Util.updateAgentDetails(updateParams)
      .then(() => this.props.obtainAgentInfo())
    this.props.toggleAgentEditPage();
  }

  agentFeaturesCheckboxes(){
    let { agentFeatures } = this.state;
    let featureOptions = FormHelper.agentFeatures();
    if(agentFeatures){
      return(
        <div className='checkboxes'>
          { featureOptions.map((feature, idx) => {
            return(
              <label key={idx}>
                <input type='checkbox'
                  value={feature.value}
                  checked={agentFeatures[feature.value] && agentFeatures[feature.value].enabled}
                  onChange={(e) => this.updateFeature(e, feature.value)}
                />
              { feature.text }
              </label>
            )
          })}
        </div>
      )
    }
  }

  getOptions(list){
    let options = list.map((option, idx) => {
      return <option value={option} key={idx + 1} >{option} </option>
    });
    options.unshift(<option key={0}>-- Select --</option>)
    return options;
  }

  timeZoneOptions(){
    let options = FormHelper.timeZones().map((option, idx) => {
      return <option value={option.value} key={idx + 1} >{option.text} </option>
    });
    options.unshift(<option key={0}>-- Select --</option>)
    return options;
  }

  updateAgent(e, field){
    this.setState({
      agent: {
        ...this.state.agent,
        [field]: e.currentTarget.value
      }
    });
  }

  updateUser(e, field){
    let { user } = this.state;
    user[field] = e.currentTarget.value
    this.setState({ user });
  }

  updateEmail(e){
    this.setState({
      user: { ...this.state.user,
        email: e.currentTarget.value,
        login: e.currentTarget.value
      }
    });
  }

  updateCompany(e, field){
    let agentCompany = this.state.agentCompany;
    agentCompany[field] = e.currentTarget.value;
    this.setState({ agentCompany });
  }

  updateAssignee(e){
    let welcomeCallTask = this.state.welcomeCallTask;
    welcomeCallTask['assigned_to'] = e.currentTarget.value;
    this.setState({ welcomeCallTask })
  }

  updateVerifiedCompany(e, field){
    this.setState({
      agentCompany:{ ...this.state.agentCompany,
        verified_company:{ ...this.state.agentCompany.verified_company,
          [field]: e.currentTarget.value
        }
      }
    });
  }

  updateFeature(e, selection){
    let { agent } = this.props;
    let { agentFeatures } = this.state;
    let val = e.currentTarget.value;
    if(agentFeatures[selection]){
      // if feature exists update feature
      this.setState({
        agentFeatures: {...agentFeatures,
          [selection]: {...agentFeatures[selection],
            enabled: !agentFeatures[selection].enabled
          }
        }
      })
    } else {
      // if feature doesn't exist create a new hash
      agentFeatures[selection] = {
        agent_id: agent.id,
        enabled: true,
        name: selection
      }
      this.setState({ agentFeatures })
    }
  }

  updateResponseField(e, field){
    this.setState({
      responses: { ...this.state.responses,
        [field]: e.currentTarget.value
      }
    });
  }

  updateTimeZone(e){
    let { timeZone }  = this.state;
    timeZone.offset = e.currentTarget.value;
    this.setState({ timeZone });
  }

  agentStatusCheckboxes(){
    let { responses } = this.state;
    return(
      <div className='checkboxes'>
        { FormHelper.agentStatus().map((option, idx) => {
          return(
            <label key={idx}>
              <input type='checkbox'
                disabled={!this.props.afssId}
                value={option}
                checked={responses[2] ? responses[2].includes(option) : false }
                onChange={(e) => this.updateCheckbox(e, 'status')}
              />
              {option}
            </label>
          )
        })}
      </div>
    )
  }

  agentLeadsCheckboxes(){
    let { responses } = this.state;
    return(
      <div className='checkboxes'>
        { FormHelper.agentLeadSources().map((option, idx) => {
          return(
            <label key={idx}>
              <input type='checkbox'
                disabled={!this.props.afssId}
                value={option}
                checked={responses[5] ? responses[5].includes(option) : false}
                onChange={(e) => this.updateCheckbox(e, 'lead source')}
              />
              {option}
            </label>
          )
        })}
      </div>
    )
  }

  updateCheckbox(e, type){
    let { responses } = this.state;
    let val = e.currentTarget.value;
    let updateResponse = (responseNumber) => {
      let response = responses[responseNumber]
      if(response && response.includes(val)){
        response = Util.removeValueFromListString(val, response)
      } else {
        response = Util.addValueToListString(val, response)
      }
      this.setState({
        responses: { ...responses,
          [responseNumber]: response
        }
      });
    };

    if(type === 'status'){
      // updateResponse either adds or removes a checkmark
      updateResponse(2);
    } else if(type === 'lead source'){
      updateResponse(5);
    }
  }

  render(){
    let { currentUser, toggleAgentEditPage, afssId, assignees } = this.props;
    let { user, agentCompany, agent, timeZone, responses, welcomeCallTask } = this.state;
    let assigneeBlock = () => {};

    if(assignees && welcomeCallTask){
      assigneeBlock = () => {
        if(welcomeCallTask){
          return(
            <div className='field'>
              <label>Assigned To </label>
              <div className='select-field-container'>
                <select
                  className='select-field'
                  value={welcomeCallTask.assigned_to}
                  onChange={this.updateAssignee}
                >
                  {assignees.map((assignee) => {
                    return(
                      <option key={assignee.user_id} value={assignee.user_id}>
                        { assignee.user_name }
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          )
        }
      }
    }
    return(
      <div className='cs-overshadow' onClick={toggleAgentEditPage}>
        <div className='cs-details-sales-edit' onClick={this.preventBubbling}>
          <div className='title'>
            <h2> Agent Lead Edit </h2>
          </div>
          <div className='content-row'>
            <div className='content-block'>
              <div className='content-title'>
                General
              </div>
              <div className='content-fields'>
                <div className='field'>
                  <label>First Name</label>
                  <input type='text'
                    className='input-field'
                    placeholder='First Name'
                    value={user.first_name || ''}
                    onChange={(e) => this.updateUser(e, 'first_name')}
                  />
                </div>
                <div className='field'>
                  <label>Last Name</label>
                  <input type='text'
                    className='input-field'
                    placeholder='Last Name'
                    value={user.last_name || ''}
                    onChange={(e) => this.updateUser(e, 'last_name')}
                  />
                </div>
                <div className='field'>
                  <label>Time Zone</label>
                    <div className='select-field-container'>
                      <select
                        className='select-field'
                        value={timeZone.offset || ''}
                        onChange={this.updateTimeZone}
                      >
                        { this.timeZoneOptions() }
                      </select>
                    </div>
                </div>
                <div className='field'>
                  <label>Company Name</label>
                  <input
                    className='input-field'
                    type='text'
                    value={agentCompany.name || ''}
                    onChange={(e) => this.updateCompany(e, 'name')}
                  />
                </div>
                <div className='field'>
                  <label>Address</label>
                  <input
                    className='input-field'
                    type='text'
                    value={agentCompany.address || ''}
                    onChange={(e) => this.updateCompany(e, 'address')}
                  />
                </div>
                <div className='field'>
                  <label>City</label>
                  <input
                    className='input-field'
                    type='text'
                    value={agentCompany.city || ''}
                    onChange={(e) => this.updateCompany(e, 'city')}
                  />
                </div>
                <div className='field'>
                  <label>State</label>
                  <input
                    className='input-field'
                    type='text'
                    value={agentCompany.state || ''}
                    onChange={(e) => this.updateCompany(e, 'state')}
                  />
                </div>
                <div className='field'>
                  <label>Zip</label>
                  <input
                    className='input-field'
                    type='text'
                    value={agentCompany.zip || ''}
                    onChange={(e) => this.updateCompany(e, 'zip')}
                  />
                </div>
              </div>
            </div>
            <div className='content-block'>
              <div className='content-title'>
                User Contact
              </div>
              <div className='content-fields'>
                <div className='field'>
                  <label>Office Number</label>
                  <input
                    className='input-field'
                    type='text'
                    value={user.phone_office || ''}
                    onChange={(e) => this.updateUser(e, 'phone_office')}
                  />
                </div>
                <div className='field'>
                  <label>Mobile Number</label>
                  <input
                    className='input-field'
                    type='text'
                    value={user.phone_mobile || ''}
                    onChange={(e) => this.updateUser(e, 'phone_mobile')}
                  />
                </div>
                <div className='field'>
                  <label>Home Number</label>
                  <input
                    className='input-field'
                    type='text'
                    value={user.phone_home || ''}
                    onChange={(e) => this.updateUser(e, 'phone_home')}
                  />
                </div>
                <div className='field'>
                  <label>Login/Email</label>
                  <input
                    className='input-field'
                    type='text'
                    value={user.email || ''}
                    onChange={this.updateEmail}
                  />
                </div>
                { assigneeBlock() }
                <div className='content-title'>
                  REX Features
                </div>
                <div className='field-checkbox'>
                  { this.agentFeaturesCheckboxes() }
                </div>
              </div>
            </div>

            <div className='content-block'>
              <div className='content-title'>
                License & Sales Info
              </div>
              <div className='content-fields'>
                <div className='field'>
                  <label>Name on Licence</label>
                  <input
                    className='input-field'
                    type='text'
                    value={agent.name_on_license || ''}
                    onChange={(e) => this.updateAgent(e, 'name_on_license')}
                  />
                </div>
                <div className='field'>
                  <label>License Type</label>
                  <input
                    className='input-field'
                    type='text'
                    value={agent.license_type || ''}
                    onChange={(e) => this.updateAgent(e, 'license_type')}
                  />
                </div>
                <div className='field'>
                  <label>License Number</label>
                  <input
                    className='input-field'
                    type='text'
                    value={agent.license_number || ''}
                    onChange={(e) => this.updateAgent(e, 'license_number')}
                  />
                </div>
                <div className='field'>
                  <label>Broker Name</label>
                  <input
                    className='input-field'
                    type='text'
                    value={agent.broker_name || ''}
                    onChange={(e) => this.updateAgent(e, 'broker_name')}
                  />
                </div>
              </div>
            </div>

            <div className='content-block'>
              <div className='content-title'>
                Question Responses
              </div>
              <div className='content-fields'>
                <div className='field'>
                  <label>Active in Real Estate Since: </label>
                  <div className='select-field-container'>
                    <select
                      disabled={!afssId}
                      className='select-field'
                      value={responses[1] || ''}
                      onChange={(e) => this.updateResponseField(e, 1)}
                    >
                      { this.getOptions(FormHelper.activeYears()) }
                    </select>
                  </div>
                </div>

                <div className='field'>
                  <label>Transactions per year</label>
                  <input type='text'
                    disabled={!afssId}
                    className='input-field'
                    value={responses[7] || ''}
                    onChange={(e) => this.updateResponseField(e, 7)}
                  />
                </div>
                <div className='field'>
                  <label>Typical price range of service</label>
                  <div className='select-field-container'>
                    <select
                      disabled={!afssId}
                      className='select-field'
                      value={responses[8] || ''}
                      onChange={(e) => this.updateResponseField(e, 8)}
                    >
                      { this.getOptions(FormHelper.priceRange()) }
                    </select>
                  </div>
                </div>

                <div className='field'>
                  <label>Percentage of clients sellers</label>
                  <div className='select-field-container'>
                    <select
                      disabled={!afssId}
                      className='select-field'
                      value={responses[4] || ''}
                      onChange={(e) => this.updateResponseField(e, 4)}
                    >
                      { this.getOptions(FormHelper.sellerPercentage()) }
                    </select>
                  </div>
                </div>

                <div className='field'>
                  <label>Monthly expenses</label>
                  <div className='select-field-container'>
                    <select
                      disabled={!afssId}
                      className='select-field'
                      value={responses[6] || ''}
                      onChange={(e) => this.updateResponseField(e, 6)}
                    >
                      { this.getOptions(FormHelper.monthlyExpenses()) }
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='content-block'>
              <div className='content-title'>
                &nbsp;
              </div>
              <div className='content-fields'>
                <div className='field-checkbox'>
                  <label>Agent status</label>
                  { this.agentStatusCheckboxes() }
                </div>
                <br/>
                <div className='field-checkbox'>
                  <label>Agent lead sources:</label>
                  { this.agentLeadsCheckboxes() }
                </div>
              </div>
            </div>
          </div>
          <div className='footer'>
            <input
              type='submit'
              value='Close'
              className='btn btn-default'
              onClick={toggleAgentEditPage}
            />
            &nbsp;&nbsp;
            <input
              type='submit'
              value='Update'
              onClick={this.updateAgentLead}
              className='btn btn-primary'
            />
          </div>
        </div>
      </div>
    )
  }
}
