import { Component } from 'react';
import AppointmentSlot from './AppointmentSlot';

export default class AppointmentSlots extends Component {
  constructor(props){
    super(props);
  }

  calendarSlots(){
    let { calendar, welcomeTeamSchedule, selectSlot, selectedSlot, timeZone, currentUserId } = this.props;
    if(calendar && Object.keys(calendar).length){
      return(
        <div className='select-container-inner'>
          { Object.keys(calendar).map((time, index) => {
            if(this.satisfiesFilter(time*1000)){
              return(
                <AppointmentSlot
                  key={index}
                  timeSlot={calendar[time]}
                  welcomeTeamSchedule={welcomeTeamSchedule}
                  selectSlot={selectSlot}
                  selectedSlot={selectedSlot}
                  timeZone={timeZone}
                  currentUserId={currentUserId}
                />
              )
            }
          }) }
        </div>
      )
    } else {
      return(
        <div className='select-container-inner'>
          No slots available
        </div>
      )
    }
  }

  satisfiesFilter(time){
    let { timeFilter, timeZone } = this.props;
    const fifteen_mins = 900000;
    if(time - fifteen_mins < new Date) return false
    let offSetTime = new Date(time);
    offSetTime.setHours(offSetTime.getHours() + timeZone.offset + 8)
    let AMPM = Util.getAMPM(offSetTime);

    switch(timeFilter){
      case 'morning':
        return AMPM === 'AM';
        break;
      case 'afternoon':
        return AMPM === 'PM';
        break;
      default:
        return true;
    }
  }

  render(){
    let {
      errorMessage,
    } = this.props;

    return(
      <div className='cs-appt-time-slots'>
        {
          errorMessage && <div className='error-message'>{ errorMessage }</div>
        }
        <div className='select-container'>
          { this.calendarSlots() }
        </div>
      </div>
    )
  }
}
