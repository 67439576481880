import { Component }  from 'react';
import request        from 'superagent';
import Selection      from './Selection';
import MonitorAgent   from './MonitorAgent';
import WorkingList    from './WorkingList';

export default class AgentInsightMonitorAgent extends Component {
  constructor(props){
    super(props);
    this.state = this.initialState();
    this.closeModal = this.closeModal.bind(this);
    this.resetState = this.resetState.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.selectMonitorView = this.selectMonitorView.bind(this);
  }

  initialState() {
    return {
      agentId: this.props.agentId,
      showModal: false,
      monitorView: 'selection',
      history: [],
      initWorkingLists: [],
      initBrandsSelected: [],
      initAdminsSelected: [],
      initExecutivesSelected: []
    }
  }

  resetState() {
    this.setState({ monitorView: 'selection' });
  }

  selectMonitorView(view){
    this.setState({ monitorView: view })
  }

  closeModal(){
    this.setState({ monitorView: 'selection', showModal: false });
  }

  componentDidMount() {
    this.loadInitialData();
  }

  loadInitialData() {
    request
      .get('/agent_insights/monitors/insight_data')
      .query({ agent_id: this.props.agentId })
      .then(({ body }) => {
        this.setState({
          history: body.brand_history || [],
          initWorkingLists: body.agent_working_lists || [],
          initBrandsSelected: body.agent_monitors || [],
          initAdminsSelected: body.agent_admins || [],
          initExecutivesSelected: body.agent_sales_executives || []
        })
      });
  }

  initialView() {
    let { initWorkingLists, initBrandsSelected, initAdminsSelected, initExecutivesSelected, history } = this.state;
    if (initBrandsSelected && initBrandsSelected.length) {
      let brand = initBrandsSelected[0];
      this.highlightHeader();
      return (
        <div className="init-display">
          <div className="status">Status: Referred</div>
          <div className="brand-item">{brand.name}: {brand.created_at}</div>
          <div className="manager">
            Admin:&nbsp;
            {initAdminsSelected.map((admin) => { return admin.name; }).join(', ') || 'None' }
          </div>
          <div className="exec">
            Exec:&nbsp;
            {initExecutivesSelected.map((exec) => { return exec.name; }).join(', ') || 'None' }
          </div>
          { this.editButton() }
        </div>
      )
    } else if (initWorkingLists && initWorkingLists.length) {
      let brand = initWorkingLists[0];
      this.highlightHeader();
      return (
        <div className="init-display">
          <div className="status">Status: Working List</div>
          <div className="brand-item">{brand.name}: {brand.created_at}</div>
          <div className="manager">
            Admin:&nbsp;
            {initWorkingLists.map((admin) => { return admin.assigned_to_name; }).join(', ') || 'None'}
          </div>
          <div className="exec">
            Exec: None
          </div>
          {this.editButton()}
        </div>
      )
    } else {
      return(
        <div className="init-display">
          {this.editButton()}
          {history.slice(0,3).map((log, idx) => {
            let { created_at, referred, monitor_name} = log;
            let status = referred == 0 ? 'W' : 'R';
            return <div className="status" key={idx}>{`${monitor_name} (${status})...............removed(${created_at})`}</div>
          })}
        </div>
      )
    }
  }

  highlightHeader(){
    const header = document.querySelector('.title-preference');
    header.classList.add('red-header');
  }

  editButton(){
    if (this.props.isEditable){
      return(
        <input
          type="button"
          value='Update Settings'
          onClick={() => this.setState({ showModal: true })}
        />
      )
    }
  }

  modalClass() {
    return this.state.showModal
      ? 'monitor-modal'
      : 'hidden';
  }

  monitorView(){
    switch(this.state.monitorView){
      case 'selection':
        return(
          <Selection
            state={this.state}
            agentId={this.props.agentId}
            loadInitialData={this.loadInitialData}
            selectMonitorView={this.selectMonitorView}
            closeModal={this.closeModal}
          />
        )
        break;
      case 'workingList':
        return(
          <WorkingList
            agentId={this.props.agentId}
            history={this.state.history}
            resetState={this.resetState}
            closeModal={this.closeModal}
            loadInitialData={this.loadInitialData}
          />
        );
        break;
      case 'monitorAgent':
        return(
          <MonitorAgent
            agentId={this.props.agentId}
            history={this.state.history}
            resetState={this.resetState}
            closeModal={this.closeModal}
            loadInitialData={this.loadInitialData}
          />
        );
        break;
    }
  }

  render(){
    return(
      <div className='sales-manager-container-inner'>
        { this.initialView() }
        <div className={this.modalClass()}>
          <div className="modal-overlay" onClick={this.closeModal}>
            <div className="title-modal" onClick={(e) => e.stopPropagation()}>
              { this.monitorView() }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
