import InputField from './../../_common/InputField.js';
import PhoneField from './../../_common/PhoneField.js';
import SelectField from './../../_common/SelectField.js';

const ClientInformation = props => {
  const {
    lead: {
      first_name,
      last_name,
      phone_number,
      email,
      consent_type,
      microsite_id,
      language,
      source,
    },
    consent_options,
    language_options,
    microsite_options,
    source_options,
    updateFieldHandler,
  } = props;

  return (
    <div className="section">
      <div className="header">
        <div className="icon-wrapper"><i className="icon user"></i></div>
        <div className="title-wrapper">
          <h2>Client Information</h2>
        </div>
      </div>

      <div className="input-row">
        <InputField fieldType="text" fieldTitle="First Name" required={ true } updateFieldHandler={ updateFieldHandler }  fieldValue={ first_name } />
        <InputField fieldType="text" fieldTitle="Last Name" required={ true } updateFieldHandler={ updateFieldHandler }  fieldValue={ last_name } />
      </div>

      <div className="input-row">
        <PhoneField fieldType="tel" fieldTitle="Phone Number" required={ false } updateFieldHandler={ updateFieldHandler }  fieldValue={ phone_number } />
        <InputField fieldType="email" fieldTitle="Email" required={ true } updateFieldHandler={ updateFieldHandler }  fieldValue={ email }  />
      </div>

      <div className="input-row">
        <SelectField fieldTitle="Consent Type" options={ consent_options } required={ true } includeBlank={ true } updateFieldHandler={ updateFieldHandler } fieldValue={ consent_type } />
        <SelectField fieldTitle="Microsite" fieldName="microsite_id" options={ microsite_options } required={ true } includeBlank={ true } updateFieldHandler={ updateFieldHandler } fieldValue={ microsite_id } />
      </div>

      <div className="input-row">
        <SelectField fieldTitle="Language" options={ language_options } required={ false } includeBlank={ true } updateFieldHandler={ updateFieldHandler } fieldValue={ language }  />
        <SelectField fieldTitle="Source" options={ source_options } required={ false } includeBlank={ true } updateFieldHandler={ updateFieldHandler } fieldValue={ source }  />
      </div>

      <div className="divider"></div>
    </div>
  );
};

export default ClientInformation;
