import React, { useState } from 'react';

import OpsPpc from './OpsPpc.js';
import Search from './exclusive/Search.js';

const initEclusive = {
  exclusive_id: null,
  results: [],
  force_agent_ids: [],
  working_with_agent_ids: [],
  force_match_exclusively: true,
};

const Exclusive = props => {
  const {
    agent = {},
    resetView,
    consent_options,
    country_options,
    us_state_options,
    ca_province_options,
    source_options,
    microsite_options,
    language_options,
    type_home_options,
    time_frame_options,
    reason_options,
    mortgage_options,
    bed_options,
    bath_options,
  } = props;

  const preloadEclusive = !agent ? {} : { exclusive_id: agent.id, results: [agent], force_agent_ids: [agent.id] };

  const [exclusive, updateExclusive] = useState({ ...initEclusive, ...preloadEclusive });

  const updateFieldHandler = data => {
    Object.keys(data).forEach(key => {
      if (typeof(data[key]) === "object") {
        const overrides = Array.isArray(data[key]) ? data[key] : { ...exclusive[key], ...data[key] };

        updateExclusive({ ...exclusive, ...{ [key]: overrides } });
      } else {
        updateExclusive({ ...exclusive, ...data });
      }
    });
  };

  return (
    <React.Fragment>
      <Search
        updateFieldHandler={ updateFieldHandler }
        results={ exclusive.results }
      />

      <OpsPpc
        exclusive={ exclusive }
        resetView={ resetView }
        consent_options={ consent_options }
        country_options={ country_options }
        us_state_options={ us_state_options }
        ca_province_options={ ca_province_options }
        source_options={ source_options }
        microsite_options={ microsite_options }
        language_options={ language_options }
        type_home_options={ type_home_options }
        time_frame_options={ time_frame_options }
        reason_options={ reason_options }
        mortgage_options={ mortgage_options }
        bed_options={ bed_options }
        bath_options={ bath_options }
      />
    </React.Fragment>
  );
};

export default Exclusive;
