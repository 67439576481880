import React from 'react';
import ReactDOM from 'react-dom';
import Attom from './index';
import '../../../../stylesheets/attom.scss';
import '../../../../stylesheets/form_builder_v2.scss';

export function initialize(containerElement, options) {
  let component = React.createElement(Attom, options);
  if (containerElement.children.length) {
    ReactDOM.unmountComponentAtNode(containerElement);
    ReactDOM.render(component, containerElement);
  } else {
    ReactDOM.render(component, containerElement);
  }
}