import PropTypes from 'prop-types';

export const checkboxField = {
  props: {
    id: PropTypes.string.isRequired,
    checkboxOptions: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.object),
    ]).isRequired,
    error: PropTypes.arrayOf(PropTypes.string),
    keyOnly: PropTypes.bool,
    klass: PropTypes.string,
    label: PropTypes.string,
    updateState: PropTypes.func.isRequired,
    required: PropTypes.bool,
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
  },
  defaultProps: {
    error: [],
    keyOnly: false,
    klass: null,
    label: '',
    required: false,
    value: [],
  },
};

export const errorNotice = {
  props: {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.object,
      PropTypes.node,
    ]),
    error: PropTypes.string,
  },
  defaultProps: {
    children: [],
    error: null,
  },
};

export const inlineAlert = {
  props: {
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onHide: PropTypes.func,
  },
  defaultProps: {
    onHide: null,
  },
};

export const inputAutocompleteField = {
  props: {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    error: PropTypes.arrayOf(PropTypes.string),
    klass: PropTypes.string,
    autocompleteOptions: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    handleChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
  },
  defaultProps: {
    label: '',
    error: [],
    klass: null,
    onBlur: null,
    required: false,
  },
};

export const inputField = {
  props: {
    disabled: PropTypes.bool,
    error: PropTypes.arrayOf(PropTypes.string),
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    idPrefix: PropTypes.string,
    inputClass: PropTypes.string,
    klass: PropTypes.string,
    label: PropTypes.string,
    labelKlass: PropTypes.string,
    maxLength: PropTypes.number,
    mobileSubLabel: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    tooltip: PropTypes.string,
    textMask: PropTypes.func,
    type: PropTypes.string,
    value: PropTypes.string,
  },
  defaultProps: {
    disabled: false,
    error: [],
    idPrefix: null,
    inputClass: null,
    klass: null,
    label: null,
    labelKlass: null,
    maxLength: null,
    mobileSubLabel: null,
    onBlur: null,
    onFocus: null,
    placeholder: null,
    required: false,
    textMask: null,
    tooltip: null,
    type: 'text',
    value: '',
  },
};

export const dateField = {
  props: {
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    idPrefix: PropTypes.string,
    inputClass: PropTypes.string,
    klass: PropTypes.string,
    label: PropTypes.string,
    labelKlass: PropTypes.string,
    maxLength: PropTypes.number,
    mobileSubLabel: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    tooltip: PropTypes.string,
    value: PropTypes.string,
  },
  defaultProps: {
    disabled: false,
    idPrefix: null,
    inputClass: null,
    klass: null,
    label: null,
    labelKlass: null,
    maxLength: null,
    mobileSubLabel: null,
    onBlur: null,
    onFocus: null,
    placeholder: null,
    required: false,
    tooltip: null,
    value: '',
  },
};

export const inputFieldWithCheckbox = {
  props: {
    checkboxId: PropTypes.string.isRequired,
    checkboxLabel: PropTypes.string.isRequired,
    checkboxValue: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.arrayOf(PropTypes.string),
    handleChange: PropTypes.func.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    klass: PropTypes.string,
    label: PropTypes.string.isRequired,
    labelKlass: PropTypes.string,
    mobileSubLabel: PropTypes.string,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    textMask: PropTypes.func,
    tooltip: PropTypes.string,
    value: PropTypes.string,
  },
  defaultProps: {
    disabled: false,
    error: [],
    klass: null,
    labelKlass: null,
    mobileSubLabel: null,
    onBlur: null,
    placeholder: null,
    textMask: null,
    tooltip: null,
    value: '',
  },
};

export const inputFieldWithExtension = {
  props: {
    error: PropTypes.arrayOf(PropTypes.string),
    extensionId: PropTypes.string.isRequired,
    extensionLabel: PropTypes.string.isRequired,
    extensionValue: PropTypes.string,
    extensionHandleChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    klass: PropTypes.string,
    label: PropTypes.string.isRequired,
    labelKlass: PropTypes.string,
    inputClass: PropTypes.string,
    mobileSubLabel: PropTypes.string,
    placeholder: PropTypes.string,
    textMask: PropTypes.func,
    tooltip: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string,
  },
  defaultProps: {
    error: [],
    extensionValue: '',
    onBlur: null,
    klass: null,
    labelKlass: null,
    inputClass: null,
    mobileSubLabel: null,
    placeholder: null,
    textMask: null,
    tooltip: null,
    required: false,
    value: '',
  },
};

export const modal = {
  props: {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.object,
      PropTypes.node,
    ]),
    close: PropTypes.func.isRequired,
    contentClass: PropTypes.string,
    footerContent: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    headerContent: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    id: PropTypes.string.isRequired,
    prompt: PropTypes.bool,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    status: PropTypes.string.isRequired,
  },
  defaultProps: {
    children: [],
    contentClass: null,
    footerContent: [],
    headerContent: [],
    prompt: false,
    showHeader: false,
    showFooter: false,
  },
};

export const mobileModal = {
  props: {
    banner: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.object,
      PropTypes.node,
    ]),
    close: PropTypes.func.isRequired,
    footerContent: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    headerContent: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    hideHeader: PropTypes.bool,
    hideFooter: PropTypes.bool,
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  },
  defaultProps: {
    banner: null,
    children: [],
    footerContent: [],
    headerContent: [],
    hideHeader: false,
    hideFooter: false,
  },
};

export const mobileEditButton = {
  props: {
    editText: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
  },
  defaultProps: {
    editText: 'EDIT',
  },
};

export const mobileRatingButton = {
  props: {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    rating: PropTypes.number,
    subText: PropTypes.string,
    text: PropTypes.string,
  },
  defaultProps: {
    disabled: false,
    onClick: null,
    rating: 0,
    subText: 'City',
    text: 'Client Name',
  },
};

export const rating = {
  props: {
    editable: PropTypes.bool,
    error: PropTypes.arrayOf(PropTypes.string),
    handleRatingChange: PropTypes.func,
    klass: PropTypes.string,
    maxRating: PropTypes.string,
    ratingTitle: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  },
  defaultProps: {
    editable: false,
    error: [],
    handleRatingChange: null,
    klass: null,
    maxRating: '5',
    ratingTitle: null,
    type: 'stars',
    value: null,
  },
};

export const selectField = {
  props: {
    allowBlank: PropTypes.bool,
    blankText: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.arrayOf(PropTypes.string),
    handleChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    id: PropTypes.string.isRequired,
    keyOnly: PropTypes.bool,
    klass: PropTypes.string,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    selectOptions: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.object,
    ]).isRequired,
    sortOptions: PropTypes.bool,
    sortBy: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  },
  defaultProps: {
    allowBlank: false,
    blankText: null,
    disabled: false,
    error: [],
    onFocus: null,
    keyOnly: false,
    klass: null,
    required: false,
    sortOptions: true,
    sortBy: 'key',
    value: '',
  },
};

export const textareaField = {
  props: {
    charLimit: PropTypes.number,
    disabled: PropTypes.bool,
    error: PropTypes.arrayOf(PropTypes.string),
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    klass: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string,
  },
  defaultProps: {
    charLimit: null,
    disabled: false,
    error: [],
    klass: null,
    label: null,
    required: false,
    placeholder: '',
    value: '',
  },
};

export const tooltip = {
  props: {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.object,
      PropTypes.node,
    ]),
    htmlFor: PropTypes.string.isRequired,
    klass: PropTypes.string,
    label: PropTypes.string,
    mobileSubLabel: PropTypes.string,
    tooltip: PropTypes.string,
    required: PropTypes.bool,
  },
  defaultProps: {
    children: [],
    label: null,
    klass: null,
    mobileSubLabel: null,
    tooltip: null,
    required: false,
  },
};
