export const isLocationSynced = state => {
  if (state.lead.lead_type === 'buyer') {
    return isBuyerLocationSynced(state);
  } else if (state.lead.lead_type === 'seller') {
    return isSellerLocationSynced(state);
  }
}

export const buildSearchQuery = contentState => {
  let {
    first_name,
    last_name,
    lead_type,
    city,
    state,
    zip,
    street_number,
    street_name,
    buyer_cities,
  } = contentState.lead;

  let location = '',
      property_search = '';

  if (lead_type == 'seller') {
    location = city + ' ' + state;
    property_search = `${street_number}%20${street_name}%20${city}%20${state}%20${zip}`;
  } else if (lead_type == 'buyer' && buyer_cities) {
    let bc = buyer_cities.split('&');
    location = bc[0].replace(',', ' ');
  }

  let realtor_search = `${first_name}%20${last_name}%20realtor%20${location}`;
  return { property_search, realtor_search };
}

export const validHCSubmitParams = state => {
  if (!state.home_captain || state.qual_attempt_count < 2) {
    return true
  }
  return [
    'Good - Assume Intent',
    'Good - Contacted',
    "Bad - Don't Want to be Matched",
    'Bad - Agent',
    'Bad - Client has Already Transacted',
    'Bad - Currently Working with an Agent',
    'Bad - No Current Real Estate Needs',
    'Bad - Junk Info',
    'Bad - Max Requal Attempts',
    'Bad - Already Our Client',
    'Bad - Duplicate Submission'
  ].includes(state.status_button.note_status)
}

// Private methods

const isBuyerLocationSynced = state => {
  let { initLead, lead, initEmail, email } = state;
  let synced = true;

  if (!lead) return true
  if (lead.first_name !== initLead.first_name) return false
  if (lead.last_name !== initLead.last_name) return false
  if (lead.phone_number !== initLead.phone_number) return false
  if (email !== initEmail) return false

  for (let i = 0; i < 4; i++) {
    let city = 'buyer_city_' + i;
    let state = 'buyer_state_' + i;
    if (lead[city] && lead[city] !== initLead[city]) {
      synced = false;
      break;
    }
    if (lead[state] && lead[state] !== initLead[state]) {
      synced = false;
      break;
    }
  }

  return synced;
}

const isSellerLocationSynced = state => {
  let { initLead, lead, initEmail, email } = state;
  if (!lead) return true
  if (lead.street_number !== initLead.street_number) return false
  if (lead.street_name !== initLead.street_name) return false
  if (lead.city !== initLead.city) return false
  if (lead.state !== initLead.state) return false
  if (lead.zip !== initLead.zip) return false
  if (email !== initEmail) return false
  return true
}
