import React from 'react';
import ReactDOM from 'react-dom';
import StuckLeadTool from './StuckLeadTool';

/**
 * Initialize the StuckLeadTool system.
 */

export function initialize(containerElement) {
  let component = React.createElement(StuckLeadTool);
  ReactDOM.render(component, containerElement);
}
