import React, { Component } from 'react';

import View from './view';

export default class Viewing extends Component {
  render() {
    return(
      <View />
    );
  }
}
