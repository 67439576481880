import React from 'react';
import ReactDOM from 'react-dom';
import StripeTransactionHistory from './StripeTransactionHistoryContainer';

/**
 * Initialize the StripeTransactionHistory system.
 */

export function initialize(containerElement, props) {
  let component = React.createElement(StripeTransactionHistory, props);
  ReactDOM.render(component, containerElement);
}
