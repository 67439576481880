import { Component } from 'react';

export default class AdminView extends Component {
  constructor(props){
    super(props);
  }

  checkboxes(){
    let { admins, monitorAdmins, adminsSelected } = this.props.state;
    if(this.props.type === 'workingList'){
      return(
        <div className="checkboxes">
          {admins.map((admin, idx) => {
            let adminId = 'admin_' + admin.user_id;
            return (
              <div className="checkbox" key={idx}>
                <input
                  id={adminId}
                  disabled={monitorAdmins.includes(admin.user_id)}
                  type="checkbox"
                  onChange={() => this.props.updateList('adminsSelected', admin, 'id')}
                />
                <label htmlFor={adminId}>{admin.name}</label>
              </div>
            )
          })}
        </div>
      )
    } else {
      return(
        <div className="checkboxes">
          {admins.map((admin, idx) => {
            let adminIds = adminsSelected.map(a => { return a.id });
            let adminId = 'admin_' + admin.user_id;
            return (
              <div className="checkbox" key={idx}>
                <input
                  id={adminId}
                  type="checkbox"
                  checked={adminIds.includes(admin.id)}
                  onChange={() => this.props.updateList('adminsSelected', admin, 'id')}
                />
                <label htmlFor={adminId}>{admin.name}</label>
              </div>
            )
          })}
        </div>
      )
    }
  }

  render(){
    return (
      <div className="content">
        { this.checkboxes() }
        <div className="prompt-options">
          <div className="prompt-cancel" onClick={this.props.loadPrevView}>
            Back
          </div>
          <div className="prompt-confirm" onClick={this.props.loadNextView}>
            Next
          </div>
        </div>
      </div>
    )
  }
}