import { Component } from 'react';

import LeadInfo from './LeadInfo';

export default class NewHandling extends Component {
  constructor(props) {
    super(props);

    this.updateTaskMessage        = this.updateTaskMessage.bind(this);
    this.updateProgressReportDate = this.updateProgressReportDate.bind(this);
    this.updateFollowUpDate       = this.updateFollowUpDate.bind(this);
    this.updateChecked            = this.updateChecked.bind(this);
  }

  componentDidMount() {
    this.props.matches.forEach(function(data) {
      const agentFollowUpNoteInput = document.querySelector(`#agent_follow_up_note_${data.match.id}`);
      const agentFollowUpDateInput = document.querySelector(`#agent_follow_up_date_${data.match.id}`);
      const progressReportDateInput = document.querySelector(`#progress_report_date_${data.match.id}`);

      $(agentFollowUpDateInput).attachLinkedDateTimePicker(`#agent_follow_up_days_${data.match.id}`);
      $(progressReportDateInput).attachLinkedDateTimePicker(`#progress_report_days_${data.match.id}`);

      $(document).on('valueChange', `#progress_report_date_${data.match.id}`, function() {
        this.updateProgressReportDate(data.match.id, progressReportDateInput.value);
      }.bind(this));

      agentFollowUpDateInput.addEventListener('keyup', function(e) {
        // On delete or backspace
        if (e.which === 8 || e.which === 46) {
          const event = new Event('valueChange', { bubbles: true });

          agentFollowUpDateInput.value = '';
          agentFollowUpDateInput.closest('div').querySelector('select').value = '';
          agentFollowUpDateInput.dispatchEvent(event);
        }
      }.bind(this));

      agentFollowUpNoteInput.addEventListener('keyup', function(e) {
        const textAreaInput = e.target;

        if (textAreaInput.value.length && !agentFollowUpDateInput.value.length) {
          const agentFollowUpDays = agentFollowUpDateInput.closest('div').querySelector('select[id*="agent_follow_up_days"]');
          const event = new Event('change', { bubbles: true });

          agentFollowUpDays.value = 30;
          agentFollowUpDays.dispatchEvent(event);
        }
      }.bind(this));

      $(document).on('valueChange', `#agent_follow_up_date_${data.match.id}`, function(e) {
        const textAreaInput = e.target.closest('div.afu-task').previousSibling.querySelector('textarea');

        this.toggleAfuWarn.call(textAreaInput);
        this.updateFollowUpDate(data.match.id, agentFollowUpDateInput.value);
      }.bind(this));
    }.bind(this));

    $('.ui-datepicker-trigger').hide();
  }

  componentWillUnmount() {
    this.props.matches.forEach(function(data) {
      $(document).off('valueChange', `#progress_report_date_${data.match.id}`, function() {
        this.updateProgressReportDate(data.match.id, $(`#progress_report_date_${data.match.id}`).val());
      }.bind(this));

      $(document).off('valueChange', `#agent_follow_up_date_${data.match.id}`, function() {
        this.updateFollowUpDate(data.match.id, $(`#agent_follow_up_date_${data.match.id}`).val());
      }.bind(this));
    }.bind(this))
  }

  callAgent(agent_id, e) {
    var options = { agent_id: agent_id };
    $.post('/dialer/profile_review/call_agent', options);
  }

  /**
   * Toggles agent follow up task warning message
   * @return {undefined}
   */
  toggleAfuWarn() {
    const input = this;
    const afuNoteWarn = input.closest('.task-note').querySelector('.afu-note-warn');
    const afuDateInput = input.closest('.right-container').querySelector('.afu-task input[id^="agent_follow_up_date"]');

    if (input.value && !afuDateInput.value) {
      afuNoteWarn.style.visibility = 'visible'
    } else {
      afuNoteWarn.style.visibility = 'hidden'
    }
  }

  updateTaskMessage(match_id, e) {
    const textAreaInput = e.target;
    const task_data = this.props.task_data;

    this.toggleAfuWarn.call(textAreaInput);

    task_data[match_id]['task_message'] = textAreaInput.value;
    this.props.updateState('task_data', task_data);
  }

  updateProgressReportDate(match_id, value) {
    var task_data = this.props.task_data;
    task_data[match_id]['progress_report_date'] = value;
    this.props.updateState('task_data', task_data);
  }

  updateFollowUpDate(match_id, value) {
    var task_data = this.props.task_data;
    task_data[match_id]['agent_follow_up_date'] = value;
    this.props.updateState('task_data', task_data);
  }

  renderLeadInfo(){
    var brand = this.props.lead.microsite || 'No Brand';
    return(
      <div className='profile-review-lead-info-outer'>
        <h2>{brand.toUpperCase()} {this.props.lead.lead_type} LEAD INFORMATION</h2>
        <LeadInfo lead={this.props.lead} callLead={this.props.callLead}/>
      </div>
    )
  }

  renderTaskContainers() {
    return this.props.matches.map(function(data, idx) {
      return (
        <div className='match-container border-box' key={idx}>
          <div className='left-container border-box inline-block'>
            <div className='profile border-box'>
              <img className='picture border-box' src={data.agent.photo}/>
            </div>
            <div className='content'>
              <div className='name'>{data.agent.first_name} {data.agent.last_name}</div>
              <div className='feature'>{data.agent.company_name}</div>
              <div className='button' style={{marginBottom: '8px'}}>
                <button className='border-box width-100 height-100' onClick={() => ReferralExchange.copyCrmLoginLinkToClipboard('agent', '/profile', data.agent.id)}>Agent CRM URL</button>
              </div>
              <div className='button'>
                <button onClick={this.callAgent.bind(this, data.agent.id)} className='border-box width-100 height-100'>CALL AGENT</button>
              </div>
            </div>
          </div>
          <div className='right-container border-box inline-block v-align-top'>
            <div className='task-note'>
              <textarea id={`agent_follow_up_note_${data.match.id}`} placeholder={'Enter details here to create AFU note & task.'} onChange={this.updateTaskMessage.bind(this, data.match.id)} value={this.props.task_data[data.match.id]['task_message']} className='border-box width-100 height-100'></textarea>
              <span className='afu-note-warn'>Match note will be created with no AFU task</span>
            </div>
            <div className='afu-task inline-block'>
              <div className='inline-block'>
                <div className='attribute'>
                  Schedule AFU Task:
                </div>
                <input id={`agent_follow_up_date_${data.match.id}`} className='border-box inline-block' value={this.props.task_data[data.match.id]['agent_follow_up_date']}/>
                <div className='relative inline-block v-align-top select-box'>
                  <select id={`agent_follow_up_days_${data.match.id}`} className='border-box'>
                    <option value=''>--</option>
                    <option value='1'>1</option>
                    <option value='3'>3</option>
                    <option value='7'>7</option>
                    <option value='14'>14</option>
                    <option value='30'>30</option>
                    <option value='70'>60</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='progress-report inline-block'>
              <div className='inline-block'>
                <div className='attribute'>
                  Schedule Progress Report:
                </div>
                <input id={`progress_report_date_${data.match.id}`} className='border-box inline-block' value={this.props.task_data[data.match.id]['progress_report_date']}/>
                <div className='relative inline-block v-align-top select-box'>
                  <select id={`progress_report_days_${data.match.id}`} className='border-box'>
                    <option value=''>--</option>
                    <option value='1'>1</option>
                    <option value='3'>3</option>
                    <option value='7'>7</option>
                    <option value='14'>14</option>
                    <option value='30'>30</option>
                    <option value='70'>60</option>
                  </select>
                </div>
              </div>
            </div>

            <div className='call-client'>
              <div className='title'>Did the agent call the client?</div>
              <div className='values'>
                <input className='inline-block' type='checkbox' checked={this.isChecked(data.match.id, 'true')} onChange={this.updateChecked.bind(this, data.match.id, 'true')}/>
                <div className='inline-block description'>Yes</div>
                <input className='inline-block' type='checkbox' checked={this.isChecked(data.match.id, 'false')} onChange={this.updateChecked.bind(this, data.match.id, 'false')}/>
                <div className='inline-block description'>No</div>
              </div>
            </div>
          </div>
        </div>
      );
    }.bind(this))
  }

  updateChecked(match_id, value, e) {
    var task_data = this.props.task_data;
    if(e.target.checked) {
      task_data[match_id]['call_client'] = value
    } else {
      task_data[match_id]['call_client'] = '';
    }
    this.props.updateState('task_data', task_data);
  }

  isChecked(match_id, result) {
    return this.props.task_data[match_id]['call_client'] === result;
  }

  render() {
    return (
      <div className='inner-handling-container'>
        {this.renderLeadInfo()}
        {this.renderTaskContainers()}
      </div>
    );
  }
}
