import { Component } from 'react';

export default class LiveBuyerForm extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      lead: {},
      invalid_city_state: this.props.data.validations.invalid_location
    }

    this.props.data.home_price = this._parsePrice( props.data.home.price );
    this.props.data.highend = parseInt( props.data.home.price.min_price ) >= 1000000;
  }

  componentDidMount(){
    this.setLead();
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.data && nextProps.data.lead.id !== this.state.lead.id) this.setLead(nextProps);
  }

  setLead(props = this.props){
    if(props.data) this.setState({ lead: Object.assign({}, props.data.lead) })
  }

  _parsePrice( price ) {
    return numeral( price.min_price ).format( '$0,0[.]00' ) + ' - ' + numeral( price.max_price ).format( '$0,0[.]00' );
  }

  handleLeadCountryChange(e){
    let lead = this.props.data.lead;
    for(let i = 0; i < 4; i++){
      lead[`buyer_city_${i}`] = '';
      lead[`buyer_state_${i}`] = '';
    }

    this.props.updateState(this.props.data);
    this.handleLeadChange(e);
  }

  handleLeadChange( e ) {
    var val = e.target.value;
    if (['home_price_max', 'home_price_min'].includes(e.target.name))
      val = e.target.value.replace(/\D/g,'');

    this.props.data.lead[ e.target.name ] = val;
    this.props.updateState( this.props.data );
  }

  handleChange( e ) {
    if(e.target.name === 'qual_note') {
      this.props.data.pmr_form.matcher_notes = e.target.value;
    }
    this.props.data[ e.target.name ] = e.target.value;
    this.props.updateState( this.props.data );
  }

  handleCommentsChange( e ) {
    this.props.data[ e.target.name ] = e.target.value;
    this.props.data.pmr_form[ e.target.name ] = e.target.value;
    this.props.updateState( this.props.data );
  }

  handleCheckChange( e ) {
    this.props.data.lead[ e.target.name ] = e.target.checked;
    this.props.updateState( this.props.data );
  }

  verifyField(e) {
    if (!Object.keys(this.props.data.live_verifications).includes(e.target.name)) return
    if(['buyer_city_0', 'buyer_state_0'].includes(e.target.name)) return

    this.props.data.live_verifications[e.target.name] = true
    this.props.updateState(this.props.data)
  }

  verifyPrice(e) {
    if (!Object.keys(this.props.data.live_verifications).includes(e.target.name)) { return }

    if(['home_price_min', 'home_price_max'].includes(e.target.name) && e.target.value == 0) {
      this.props.data.live_verifications[e.target.name] = false
      this.props.updateState(this.props.data)
    }
  }

  _checkCityState() {
    // Check with marketing_geos api db for valid city/state pair
    var city = this.props.data.lead.buyer_city_0;
    var state = this.props.data.lead.buyer_state_0;
    $.get('/qual/queue/verify_location', { city: city, state: state }, res => {
      this.setState({ invalid_city_state: !res.valid })
      this.props.data.live_verifications.buyer_city_0 = res.valid
      this.props.data.live_verifications.buyer_state_0 = res.valid
      this.props.updateState(this.props.data)
    })
  }

  highlightClass(field) {
    if (!Object.keys(this.props.data.live_verifications).includes(field)) {
      return ''
    } else if (this.props.data.live_verifications[field]) {
      return 'verified'
    } else {
      return 'verification-needed'
    }
  }

  onHomeSelectChange( e ) {
    this.props.data.home_price = e.target.value;
    var vals = e.target.value.split( '-' );

    vals.forEach( function( val, i ) {
      if( vals.length === 1 && val !== 'Up to $100,000'){
        this.props.data.highend = true;
      } else {
        this.props.data.highend = parseInt( val.replace(/\D/g,'') ) >= 1000000;
      }
    }.bind( this ));
    this.props.updateState( this.props.data );
  }

  countryOptions() {
    let countries = this.props.data.country_options;
    return <option className='selectdd' value='US'>United States</option>
  }

  render() {
    let verifications = this.props.data.live_verifications;

    return(
      <div>
        <div className='raw_input'>
          { `RAW INPUT: ` + (this.props.data.live.raw_location_for_client || 'n/a') }
        </div>
        <div className='buyer_cities_field'>
          <div className='label'>
            {`Buyer Cities` + (verifications.buyer_city_0 && verifications.buyer_state_0 ? "  \u2705" : '')}
          </div>
          <div className='cities-container'>
            <div className='fields'>
              { this.render_buyer_city_fields() }
            </div>
          </div>
          <span className={this.state.invalid_city_state ? 'invalid-location' : 'invalid-location hidden'}>INVALID LOCATION</span>
        </div>
        <div className='clear'></div>
        <div className='field odd hp price-range-field'>
          <div className='label'>
            {`Home Price` + (verifications.home_price_min && verifications.home_price_max ? "  \u2705" : '')}
            <span className='italicized'>{ `Raw Input: ` + (this.props.data.live.raw_price_input || 'n/a') }</span>
            <span className={ this.props.data.highend ? 'highend' : 'highend hidden' }>HIGH END</span>
          </div>
          <div className="clear"></div>
          <input
            type='text'
            name='home_price_min'
            className={['price-range', 'price-range-left', this.highlightClass('home_price_min')].join(' ')}
            data-optional='true'
            value={ numeral(this.props.data.lead.home_price_min).format('0,0') }
            onChange={ this.handleLeadChange.bind( this ) }
            onFocus={(e) => { this.highlightIntro.bind(this)(e); this.verifyField.bind(this)(e) }}
            onBlur={ this.verifyPrice.bind( this ) }
            placeholder='Min'
            />
          <input
            type='text'
            name='home_price_max'
            className={['price-range', this.highlightClass('home_price_max')].join(' ')}
            data-optional='true'
            value={ numeral(this.props.data.lead.home_price_max).format('0,0') }
            onChange={ this.handleLeadChange.bind( this ) }
            onFocus={(e) => { this.highlightIntro.bind(this)(e); this.verifyField.bind(this)(e) }}
            onBlur={ this.verifyPrice.bind( this ) }
            placeholder='Max'
            />
        </div>
        <div className='field selectdd'>
          <div className='label'>
            {`Mortgage Status` + (verifications.mortgage_status ? "  \u2705" : '')}
          </div>
          <select
            name='mortgage_status'
            className={ this.highlightClass('mortgage_status') }
            value={ this.props.data.lead.mortgage_status || '' }
            onChange={ this.handleLeadChange.bind( this ) }
            onFocus={(e) => { this.highlightIntro.bind(this)(e); this.verifyField.bind(this)(e) }}
          >
            { this.props.data.mortgage_status.options.map( this._renderOption, this ) }
          </select>
        </div>
        <div className='clear'></div>
        <div className='field'>
          <div className='selectdd' style={{width: '290px'}}>
            <div className='label'>
              {`Min Beds` + (verifications.beds ? "  \u2705" : '')}
            </div>
            <select
              data-optional='true'
              name='beds'
              className={ this.highlightClass('beds') }
              value={this.props.data.lead.beds || ''}
              onChange={ this.handleLeadChange.bind( this ) }
              onFocus={(e) => { this.highlightIntro.bind(this)(e); this.verifyField.bind(this)(e) }}
            >
              <option value=''>--</option>
              <option value='0'>0</option>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
              <option value='7'>7</option>
            </select>
          </div>
          <div className='selectdd' style={{width: '290px', margin: '10px 0px 0px 0px'}}>
            <div className='label'>
              {`Min Baths` + (verifications.baths ? "  \u2705" : '')}
            </div>
            <select
              data-optional='true'
              name='baths'
              className={ this.highlightClass('baths') }
              value={this.props.data.lead.baths || ''}
              onChange={ this.handleLeadChange.bind( this ) }
              onFocus={(e) => { this.highlightIntro.bind(this)(e); this.verifyField.bind(this)(e) }}
            >
              <option value=''>--</option>
              <option value='0'>0</option>
              <option value='1'>1</option>
              <option value='1.5'>1.5</option>
              <option value='2'>2</option>
              <option value='2.5'>2.5</option>
              <option value='3'>3</option>
              <option value='3.5'>3.5</option>
              <option value='4'>4</option>
              <option value='4.5'>4.5</option>
              <option value='5'>5</option>
            </select>
          </div>
        </div>
        <div className='field selectdd'>
          <div className='label'>
            {`Property Type` + (verifications.type_home ? "  \u2705" : '')}
          </div>
          <select
            name='type_home'
            className={ this.highlightClass('type_home') }
            value={this.props.data.lead.type_home || ''}
            onChange={ this.handleLeadChange.bind( this ) }
            onFocus={(e) => { this.highlightIntro.bind(this)(e); this.verifyField.bind(this)(e) }}
          >
            <option>Choose One</option>
            <option value='Single Family'>Single Family</option>
            <option value='Condominium'>Condominium</option>
            <option value='Town Home'>Town Home</option>
            <option value='Mobile Home'>Mobile Home with land</option>
            <option value='2-4 Units'>2-4 Units</option>
            <option value='Vacation Home'>Vacation Home</option>
            <option value='Rental'>Rental</option>
            <option value='Co-op'>Co-op</option>
            <option value='Loft'>Loft</option>
            <option value='Land/Lot'>Land/Lot</option>
            <option value='Unknown'>Unknown</option>
          </select>
          <div className='field checkboxes-live'>
            <input checked={this.props.data.lead.opt_out} type="checkbox" data-optional='true' name='opt_out' id='opt_out' onChange={this.handleCheckChange.bind(this)} />
            <label htmlFor="opt_out">
              Opt Out
            </label>
          </div>
          <div className='field checkboxes-live'>
            <input checked={this.props.data.lead.commercial} type="checkbox" data-optional='true' id="commercial" name='commercial' onChange={this.handleCheckChange.bind(this)} />
            <label htmlFor="commercial">
              Commercial
            </label>
          </div>
        </div>
        <div className='clear'></div>
        <div className='clear'></div>
        <div className='clear'></div>
        <div className='field full'>
          <div className='label'>
            { this.props.data.origin.indexOf( 'Home Captain' ) !== -1 ? 'Client Comments (will be viewed as a custom message to agents) - Include lender info' : 'Client Comments (will be viewed as a custom message to agents)' }
          </div>
          <textarea data-optional='true' name='comments' onChange={ this.handleCommentsChange.bind(this) } value={ this.props.data.comments || '' } onFocus={ this.highlightClosing.bind( this ) }></textarea>
        </div>
      </div>
    )
  }

  _renderOption( option, i ) {
    return(
      <option key={ i } value={ option }>{ option }</option>
    )
  }

  highlightIntro() {
    EventBus.emit( 'highlight:intro' );
  }

  highlightQuestions() {
    EventBus.emit( 'highlight:questions' );
  }

  highlightClosing() {
    EventBus.emit( 'highlight:closing' );
  }

  render_buyer_city_fields() {
    if (this.props.data.lead.buyer_cities) {
      return this.props.data.lead.buyer_cities.split('&').map(this._buyerCityField, this)
    } else {
      return this._buyerCityField('', 0)
    }
  }

  _buyerCityField( buyer_city, i ) {
    let data = this.props.data;
    let classString = 'buyer_city bc_option_' + i;
    let name = 'buyer_city_' + i;
    let state = 'buyer_state_' + i;
    let stateType = data.lead.country == 'CA' ? data.provinces : data.states;

    return(
      <div className={ classString } key={ i }>
        <input
          data-optional='true'
          type='text'
          name={ name }
          value={ this.props.data.lead[`buyer_city_${i}`] || '' }
          onFocus={(e) => { this.highlightIntro.bind(this)(e); this.verifyField.bind(this)(e) }}
          onChange={ this.handleLeadChange.bind( this ) }
          className={ ['w240', this.highlightClass(`buyer_city_${i}`)].join(' ') }
          onBlur={ this._checkCityState.bind(this) }
          />
        <select data-optional='true'
          name={ state }
          className={ this.highlightClass(`buyer_state_${i}`) }
          value={ this.props.data.lead[`buyer_state_${i}`] || '' }
          onFocus={(e) => { this.highlightIntro.bind(this)(e); this.verifyField.bind(this)(e) }}
          onChange={ this.handleLeadChange.bind( this ) }
          onBlur={ this._checkCityState.bind(this) }
        >
          <option value=""></option>
          { stateType.map( this._renderStateOption, this ) }
        </select>
        <img
          src={window.image_path('google-icon')}
          className='icon'
          onClick={ this._googleSearch.bind(this) }></img>
        <i className={ i === 3 ? 'hidden' : 'fa fa-plus-circle' } data-num={ i } onClick={ this._onBuyerCityClick.bind( this ) }></i>
        <div className='clear'></div>
      </div>
    )
  }

  _googleSearch( e ) {
    var key = e.target.parentElement.className.slice(-1)
    var buyer_city = `buyer_city_${key}`
    var buyer_state = `buyer_state_${key}`

    window.open(`https://www.google.com/search?q=${this.props.data.lead[buyer_city]}+${this.props.data.lead[buyer_state]}`)
  }

  _onBuyerCityClick( e ) {
    var num = $( e.target ).data( 'num' );

    if( num === 3 ) return false;

    this.props.data.lead.buyer_cities += '& , ';
    $( '[data-num="' + num + '"]' ).hide();
    this.props.updateState( this.props.data );
  }

  _renderStateOption( state, i ) {
    return(
      <option key={ i } value={ state }>{ state }</option>
    )
  }
}
