import React, { useState, useEffect } from 'react';

import TransactionType from './live/TransactionType.js';
import ClientInformation from './live/ClientInformation.js';
import Search from './live/Search.js';

const REX_PRODUCT_OFFERINGS_IDS = {
  880: 8,
  899: 10,
  705: 7,
};

const MICROSITE_OPTIONS = [
  ['CINC', 880],
  ['Compass', 899],
  ['ReferralExchange', 705],
];

const RADIO_FIELD_OPTIONS = [
  ['No', 'false'],
  ['Yes', 'true'],
];

const LEAD_TYPES = [
  'Buy',
  'Sell',
];

const initLead = {
  first_name: null,
  last_name: null,
  email: null,
  lead_type: 'buy',
  phone_number: null,
  sending_agent_id: null,
  microsite_id: null,
  product_offering_id: null,
  rental: false,
  open_in_qual: false,
};

const Live = props => {
  const { resetView } = props;

  const [lead, updateLead] = useState(initLead);

  useEffect(() => console.log(lead), [lead]);

  useEffect(() => updateFieldHandler({ product_offering_id: REX_PRODUCT_OFFERINGS_IDS[lead.microsite_id] }), [lead.microsite_id]);

  const updateFieldHandler = data => {
    Object.keys(data).forEach(key => {
      if (typeof(data[key]) === "object") {
        const overrides = Array.isArray(data[key]) ? data[key] : { ...lead[key], ...data[key] };

        updateLead({ ...lead, ...{ [key]: overrides } });
      } else {
        updateLead({ ...lead, ...data });
      }
    });
  };

  return (
    <React.Fragment>
      <TransactionType
        leadTypes={ LEAD_TYPES }
        updateFieldHandler={ updateFieldHandler }
      />

      <ClientInformation
        micrositeOptions={ MICROSITE_OPTIONS }
        radioFieldOptions={ RADIO_FIELD_OPTIONS }
        updateFieldHandler={ updateFieldHandler }
      />

      <Search
        updateFieldHandler={ updateFieldHandler }
      />

      <div className="section footer">
        <button type="button" className="rex-button" disabled={ false } onClick={ () => { console.log('handleFormSubmit') } }>Submit</button>
      </div>
    </React.Fragment>
  );
};

export default Live;
