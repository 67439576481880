import { Component }          from 'react';
import request                from 'superagent';

export default class Selection extends Component {
  constructor(props){
    super(props);

    this.removeWorkingList = this.removeWorkingList.bind(this);
    this.removeMonitorAgent = this.removeMonitorAgent.bind(this);
    this.migrateWorkingList = this.migrateWorkingList.bind(this);
  }

  removeWorkingList(e){
    request
      .post('/agent_insights/monitors/remove_working_lists')
      .query({ agent_id: this.props.agentId })
      .then(this.props.closeModal)
      .then(this.props.loadInitialData)
  }

  removeMonitorAgent(e){
    request
      .post('/agent_insights/monitors/remove_monitors')
      .query({ agent_id: this.props.agentId })
      .then(this.props.closeModal)
      .then(this.props.loadInitialData)
    }

  workingListButton() {
    return (
      <input
        type='button'
        className='monitoring-button'
        value='Working List'
        onClick={() => this.props.selectMonitorView('workingList')}
      />
    );
  }

  monitorAgentButton() {
    return (
      <input
        type='button'
        className='monitoring-button'
        value='Refer Agent'
        onClick={() => this.props.selectMonitorView('monitorAgent')}
      />
    )
  }

  removeWorkingListButton() {
    return (
      <input
        type='button'
        className='monitoring-button'
        value='No Longer Working'
        onClick={(e) => this.removeWorkingList(e)}
      />
    )
  }

  removeMonitorAgentButton() {
    return (
      <input
        type='button'
        className='monitoring-button'
        value='Remove Referrals'
        onClick={(e) => this.removeMonitorAgent(e)}
      />
    )
  }

  createMonitorbuttons() {
    let { initWorkingLists } = this.props.state;
    if (initWorkingLists && initWorkingLists.length){
      return (
        <working-list-buttons className='working-list-buttons'>
          <h3>CREATE REFERRAL:</h3>
          <div className='syndication-buttons'>
            {initWorkingLists.map((workingList, idx) =>  {
              return(
                <input
                  type='button'
                  key={idx}
                  className='syndication-button'
                  value={workingList.assigned_to_name}
                  onClick={(e) => this.migrateWorkingList(workingList.id, e)}
                />
              )
            })}
          </div>
        </working-list-buttons>
      )
    }
  }

  migrateWorkingList(workingListId, e){
    let button = e.currentTarget;
    button.value = 'Submitting...';

    request
      .post('/agent_insights/monitors/migrate_working_list')
      .query({ working_list_id: workingListId, task: {}, agent_id: this.props.agentId })
      .then(this.props.closeModal)
      .then(this.props.loadInitialData)
  }

  render(){
    let { initBrandsSelected, initWorkingLists } = this.props.state;
    let workingLists = initWorkingLists && initWorkingLists.length;
    let monitorLists = initBrandsSelected && initBrandsSelected.length;
    if (workingLists || monitorLists) {
      return (
        <div>
          <div className='header'><h3>Branding</h3></div>
          <div className="content">
            <div className='list-buttons'>
              { workingLists ? this.removeWorkingListButton() : this.workingListButton() }
              or
              { monitorLists ? this.removeMonitorAgentButton() : this.monitorAgentButton() }
            </div>
            { this.createMonitorbuttons() }
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className='header'><h3>Branding</h3></div>
          <div className="content">
            <div className='list-buttons'>
              { this.workingListButton() }
              { this.monitorAgentButton() }
            </div>
          </div>
        </div>
      )
    }
  }
}
