import { Component } from 'react';

import QualHeader from './QualHeader';
import LiveCallControlContainer from '../../LiveCallControls/LiveCallControlContainer';

const Header = ({ reloadCompanies, state, updateState, phoneStrategy }) => {
  let {
    dismount,
    live_disposition,
    live_lead,
  } = state.live;

  let liveTransferPadding = document.getElementById('live-transfer-padding');

  if (live_lead && !dismount) {
    if (live_disposition === 'submitted_rex') {
      // Render call control container except hidden, show regular header
      return (
        <div>
          <LiveCallControlContainer
            data        = { state }
            updateState = { updateState }
            updateLead  = { reloadCompanies }

            // strategy determines how we place/connect to the call
            phoneStrategy = { phoneStrategy }
          />

          <QualHeader
            state       = { state }
            updateState = { updateState }
          />
        </div>
      )
    } else {
      if (liveTransferPadding) { liveTransferPadding.style.display = 'block' }
      return (
        <LiveCallControlContainer
          data        = { state }
          updateState = { updateState }
          updateLead  = { reloadCompanies }

          // strategy determines how we place/connect to the call
          phoneStrategy = { phoneStrategy }
        />
      )
    }
  } else {
    return (
      <QualHeader
        state={state}
        updateState={updateState}
      />
    )
  }
}

export default Header;