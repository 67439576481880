import { Component } from 'react';
import NewNoteModal from './modals/NewNoteModal';
export default class Notes extends Component {
  constructor(props){
    super(props)
    this.state = {
      showNewNote: false
    }
    this.toggleNewNote = this.toggleNewNote.bind(this);
    this.markRead = this.markRead.bind(this);
    this.markUnread = this.markUnread.bind(this);
    this.completeTask = this.completeTask.bind(this);
  }

  toggleNewNote(){
    this.setState({ showNewNote: !this.state.showNewNote });
  }

  markRead(id){
    Util.markRead(id)
      .then(this.props.fetchAgent(this.props.agentId));
  }

  markUnread(id){
    Util.markUnread(id)
      .then(this.props.fetchAgent(this.props.agentId));
  }

  completeTask(id){
    Util.completeSendingAgentStatusUpdateTask(id)
      .then(this.props.fetchAgent(this.props.agentId));
  }

  newNote(){
    let { currentUser, agentId, obtainAgentInfo, user } = this.props;
    if(this.state.showNewNote){
      return (
        <NewNoteModal
          user={user}
          agentId={agentId}
          currentUser={currentUser}
          toggleNewNote={this.toggleNewNote}
          obtainAgentInfo={obtainAgentInfo}
        />
      )
    }
  }

  renderNotes(){
    let { notes, showAppointment } = this.props;

    return(
      <div className={showAppointment ? 'react-notes-data-mini' : 'react-notes-data-expand'}>
        {
          notes.map((note, idx) => {
            let creator = note.originator ? note.originator : 'Unknown';
            let status;
            if(typeof(note.status) === 'boolean'){
              status = 'Support Message';
            } else if(note.status === null){
              status = 'Untitled';
            } else if(note.type === 'Sending Agent Status Update'){
              status = 'Tracker Outbound';
            } else {
              status = note.status;
            }
            let noteType = () => {
              if(note.type === 'Message' && !note.status){
                return(
                  <div className='react-note-read-red' onClick={() => this.markRead(note.id)}>
                    Mark read
                  </div>
                )
              } else if(note.type === 'Message' && note.status){
                return(
                  <div className='react-note-read-green' onClick={() => this.markUnread(note.id)}>
                    Mark Unread
                  </div>
                )
              } else if(note.type === 'Sending Agent Status Update' && note.status === 'incomplete'){
                // These are not actual notes, they are tasks
                return(
                  <div className='react-note-read-red' onClick={() => this.completeTask(note.id)}>
                    Click!
                  </div>
                )
              } else if(note.type === 'Sending Agent Status Update' && note.status === 'completed'){
                return
              } else {
                return <label>{note.type}</label>
              }
            }
            return(
              <div key={idx} className='react-note'>
                <div className='react-note-status'>
                  <label>{status}</label>
                  { noteType() }
                </div>
                <div className='react-note-message'>
                  {note.message}
                </div>
                <div className='react-note-title'>
                  {creator + ' created at ' + note.pretty_time}
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
  render(){
    let { currentUser, notes } = this.props;
    if(notes === null) return <div/>;
    return(
      <div className='react-notes-panel'>
        <div className='react-header'>
          <label>Notes</label>
          <div
            className='react-create-button'
            onClick={this.toggleNewNote}
          >
            Create +
          </div>
          { this.newNote() }
        </div>
        { this.renderNotes() }
      </div>
    )
  }
}
