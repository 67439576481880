import Area from './current_areas/Area';

const CurrentAreas = (props) => {
  const {
    currentAreas,
  } = props;

  function buildAreas() {
    return Object.keys(currentAreas).map(k => <Area key={ k } cityName={ k } geos={ currentAreas[k] } />);
  }

  return (
    <div className='current-service-areas mt-15'>
      <h2>Current Service Areas</h2>
      <div className='row'>
        { buildAreas() }
      </div>
    </div>
  );
};

export default CurrentAreas;
