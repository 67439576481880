import React from 'react';

import './styles.scss';

const createSelect = ({ data, disposition, onChange, options, value }) => {
  const { disabled, display } = data;

  return (
    <div className='select-group'>
      <select
        disabled={ disabled }
        id={ `${ disposition }-select` }
        name={ disposition }
        onChange={ onChange }
        value={ value }
      >
        <option value=''>{ display }</option>
        { options.map((o, i) => renderOption(o, i, disposition)) }
      </select>
    </div>
  );
};

const createButton = ({ data, disposition, onClick }) => {
  const { disabled, display } = data;
  return (
    <button
      id={ `submit-${ disposition }` }
      className='active-dialer-action'
      disabled={ disabled }
      onClick={ () => onClick(disposition) }
    >
      <span>{ display }</span>
    </button>
  );
};

const renderOption = (o, i, block) => (<option key={ i } value={ o } id={ `disposition-${ block }-${ i }`}>{ o }</option>);

const Actions = (props) => {
  const {
    calling,
    data,
    isValidForm,
    maxedAttempts,
    submitting,

    handleChange,
    submit,
  } = props;

  if (!data.killOptions) {
    return null;
  }

  const {
    disposition,
    killOptions,
    matchingOptions,
    qualOptions,
    value,
  } = data;

  const actionMap = {
    kill: {
      select: {
        disabled: calling || submitting,
        display: '-- CHOOSE KILL REASON --',
      },
      options: killOptions,
      button: {
        disabled: submitting || disposition !== 'kill' || !value.length,
        display: 'Kill Lead'
      }
    },
    qual: {
      select: {
        disabled: calling || maxedAttempts || submitting,
        display: '-- CHOOSE REQUAL REASON --',
      },
      options: qualOptions,
      button: {
        disabled: submitting || disposition !== 'qual' || !value.length,
        display: 'Requal Lead'
      },
    },
    matching: {
      select: {
        disabled: !isValidForm || submitting,
        display: '-- CHOOSE MATCH REASON --',
      },
      options: matchingOptions,
      button: {
        disabled: !isValidForm || submitting || disposition !== 'matching' || !value.length,
        display: 'Match Lead'
      },
    },
  };

  return (
    <div className='active-dialer-actions'>
      {
        Object.keys(actionMap).map((disposition, index) => {
          const action = actionMap[disposition];
          const { select, options, button } = action;

          return (
            <div key={`group-${ index }`} className={ `active-dialer-action-group ${ disposition }` }>
              { createSelect({ data: select, disposition, onChange: handleChange, options, value }) }
              { createButton({ data: button, disposition, onClick: submit }) }
            </div>
          )
        })
      }
    </div>
  );
};

export default Actions;
