const Head = () => (
  <thead>
    <tr>
      <th>To</th>
      <th>From</th>
      <th>Type</th>
      <th>Sent On</th>
      <th>Status</th>
    </tr>
  </thead>
);

export default Head;
