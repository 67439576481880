import { Component } from 'react';
import Timer from         './top_panel/Timer';
import Viewing from       './top_panel/Viewing';

export default class TopPanel extends Component {
  constructor(props){
    super(props)
  }
  render(){
    let { user, agentId, currentUser, calendar, fetchAgent } = this.props;
    return(
      <div className='cs-top-panel'>
        <Timer calendar={calendar}/>
        <Viewing
          currentUser={currentUser}
          user={user}
          agentId={agentId}
          fetchAgent={fetchAgent}
        />
      </div>
    )
  }
}
