import { Component } from 'react';

const LeadInfo = props => {
  const {
    id,
    first_name,
    last_name,
    email,
    phone_number,
    time_frame,
    type_home,
    lead_type,
    blacklisted,
    referral_fee,
    max_agents,
    comments,
    home_price_min,
    home_price_max,
    buyer_city1,
    buyer_state1,
    buyer_city2,
    buyer_state2,
    buyer_city3,
    buyer_state3,
    buyer_city4,
    buyer_state4,
    street_number,
    street_name,
    unit_number,
    city,
    state,
    zip,
  } = props.lead;

  const fullName = `${first_name} ${last_name}`

  const sellerGoogleUrl = `https://www.google.com/search?q=${street_number}+${street_name}+${unit_number}+${city}+${state}+${zip}`;

  const buyerArea = (city, state) => {
    return city ? `${city}, ${state}` : '';
  }

  const formatReferralFee = referralFee => {
    return (referralFee * 100) + "%"
  }

  const parsePrice = price => {
    return numeral(price).format('$0,0[.]00')
  }

  const formatedPriceRange = (home_price_min == null || home_price_min == '')
    ? parsePrice(home_price_max)
    : `${parsePrice(home_price_min)}-${parsePrice(home_price_max)}`;

  const callLead = () => {
    blacklisted
      ? alert(`Lead is designated as "Don't Call"`)
      : props.callLead(id);
  }

  const buyerCityGoogleLogo = (buyerCity, buyerState) => {
    if(buyerCity && buyerCity.length && buyerState && buyerState.length) {
      const url = `https://www.google.com/search?q=${buyerCity}+${buyerState}`;

      return(
        <div className='google-icon inline-block'>
          <a href={url} target='_blank'>
            <img src={window.image_path('google')}/>
          </a>
        </div>
      );
    }
  }

  const locationInfo = () => {
    return lead_type === 'BUYER'
      ? <div className='lead-info-row'>
          <div className='buyer-block block-height border-box no-left-padding'>
            <div className='attribute'>Looking in</div>
            <div className='value inline-block'>{buyerArea(buyer_city1, buyer_state1)}</div>
            {buyerCityGoogleLogo(buyer_city1, buyer_state1)}
          </div>
          <div className='buyer-block block-height border-box'>
            <div className='attribute'></div>
            <div className='value inline-block'>{buyerArea(buyer_city2, buyer_state2)}</div>
            {buyerCityGoogleLogo(buyer_city2, buyer_state2)}
          </div>
          <div className='buyer-block block-height border-box'>
            <div className='attribute'></div>
            <div className='value inline-block'>{buyerArea(buyer_city3, buyer_state3)}</div>
            {buyerCityGoogleLogo(buyer_city3, buyer_state3)}
          </div>
          <div className='buyer-block block-height border-box no-right-padding'>
            <div className='attribute'></div>
            <div className='value inline-block'>{buyerArea(buyer_city4, buyer_state4)}</div>
            {buyerCityGoogleLogo(buyer_city4, buyer_state4)}
          </div>
        </div>
      : <div className='lead-info-row'>
          <div className='col-lg-3 block-height border-box no-left-padding'>
            <div className='attribute inline-block'>Address</div>
            <div className='google-icon inline-block' style={{ verticalAlign: 'text-bottom' }}>
              <a href={sellerGoogleUrl} target='_blank'>
                <img src={window.image_path('google')} />
              </a>
            </div>
            <div className='value'>{street_number} {street_name} {unit_number}</div>
          </div>
          <div className='col-lg-3 block-height border-box'>
            <div className='attribute'>City</div>
            <div className='value'>{city}</div>
          </div>
          <div className='col-lg-4 block-height border-box'>
            <div className='attribute'>State</div>
            <div className='value'>{state}</div>
          </div>
          <div className='col-lg-2 block-height border-box no-right-padding'>
            <div className='attribute'>Zip</div>
            <div className='value'>{zip}</div>
          </div>
          {dualTransactionBuyer()}
        </div>
  }

  const dualTransactionBuyer = () => {
    const buyer = props.dual_transaction_buyer;
    if (props.dual_transaction){
      return (
        <div className='dual-buyer-info'>
          <div className='col-lg-3 block-height border-box no-left-padding'>
            <div className='attribute inline-block'>Looking In</div>
            <div className='value'>{ buyer.buyer_cities.split('&')[0].replace(',', ', ') }</div>
          </div>
          <div className='col-lg-3 block-height border-box'>
            <div className='attribute'>Min Beds</div>
            <div className='value'>{ buyer.beds ? buyer.beds : '--' }</div>
          </div>
          <div className='col-lg-4 block-height border-box'>
            <div className='attribute'>Min Baths</div>
            <div className='value'>{ buyer.baths ? buyer.baths : '--' }</div>
          </div>
          <div className='col-lg-2 block-height border-box no-right-padding'>
            <div className='attribute'>Mortgage Status</div>
            <div className='value'>{ buyer.mortgage_status ? buyer.mortgage_status : '--' }</div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className='profile-review-lead-info border-box'>
      <div className='lead-info-row'>
        <div className='col-lg-3 block-height border-box no-left-padding'>
          <div className='attribute'>Name</div>
          <div className='value'>{fullName}</div>
        </div>
        <div className='col-lg-3 block-height border-box'>
          <div className='attribute'>Phone</div>
          <div className={blacklisted ? 'value phone blacklisted' : 'value phone'} onClick={callLead}>
            {Util.formatPhoneNumber(phone_number)}
          </div>
        </div>
        <div className='col-lg-4 block-height border-box'>
          <div className='attribute'>Email</div>
          <div className='value'>{email}</div>
        </div>
        <div className='col-lg-2 block-height border-box no-right-padding'>
          <div className='attribute'>Time Frame</div>
          <div className='value'>{time_frame}</div>
        </div>
      </div>
      <div className='lead-info-row'>
        <div className='col-lg-3 block-height border-box no-left-padding'>
          <div className='attribute'>Home Type</div>
          <div className='value'>{type_home}</div>
        </div>
        <div className='col-lg-3 block-height border-box'>
          <div className='attribute'>Price</div>
          <div className='value'>{formatedPriceRange}</div>
        </div>
        <div className='col-lg-4 block-height border-box'>
          <div className='attribute'>Referral Fee</div>
          <div className='value'>{formatReferralFee(referral_fee)}</div>
        </div>
        <div className='col-lg-2 block-height border-box no-right-padding'>
          <div className='attribute'>Max Agents</div>
          <div className='value'>{max_agents}</div>
        </div>
      </div>
      { locationInfo() }
      <div className='lead-info-row'>
        <div className='col-lg-12 height-100 border-box no-left-padding no-right-padding'>
          <div className='attribute'>Comments</div>
          <div className='value'>{comments}</div>
        </div>
      </div>
    </div>
  );
}

export default LeadInfo;
