import React, { Component } from 'react';

import LiveManagement from './ManagementView';

export default class LiveAccountManagementContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasActiveSubscription: props.has_active_subscription,
      hasEnabledLiveFeature: props.has_enabled_live_feature,
      canceledLive: props.canceled_live,
      hasLegacyProduct: props.has_legacy_product,
      hasActiveLiveInbox: props.has_active_live_inbox,
      modalOpen: false,
      displaying: null,
      loading: false,
      userRexProductOffering: props.user_rex_product_offering,
      balanceOwed: props.balance_owed
    };

    this.display = this.display.bind(this);
    this.updateManagement = this.updateManagement.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.unsuccessfulRequest = this.unsuccessfulRequest.bind(this);
  }

  toggleModal(bool) {
    this.setState({ displaying: null, modalOpen: bool });
  }

  display(view) {
    this.setState({ displaying: view });
  }

  updateManagement(stateProp) {
    this.setState(stateProp);
  }

  unsuccessfulRequest(errors) {
    this.setState({makingRequest: false})
    alert(errors.responseJSON.error)
  }

  render() {
    const {
      displaying,
      hasActiveLiveInbox,
      hasActiveSubscription,
      canceledLive,
      hasEnabledLiveFeature,
      hasLegacyProduct,
      loading,
      modalOpen,
      userRexProductOffering,
      balanceOwed
    } = this.state;

    const {
      agent_id,
      rex_product_offerings
    } = this.props;

    return (
      <div>
        { modalOpen &&
          <LiveManagement
            agentId={ agent_id }
            displaying={ displaying }
            hasActiveLiveInbox={ hasActiveLiveInbox }
            hasActiveSubscription={ hasActiveSubscription }
            hasEnabledLiveFeature={ hasEnabledLiveFeature }
            canceledLive={ canceledLive }
            hasLegacyProduct={ hasLegacyProduct }
            loading={ loading }
            rexProductOfferings={ rex_product_offerings }
            userRexProductOffering={ userRexProductOffering }
            balanceOwed={ balanceOwed }
            display={ this.display }
            toggleModal={ this.toggleModal }
            updateManagement={ this.updateManagement }
            unsuccessfulRequest={ this.unsuccessfulRequest }
          />
        }
        <button
          onClick={ () => this.toggleModal(!modalOpen) }
          label="LIVE Account Management"
          className="live-button">
          LIVE ACCOUNT MGMT
        </button>
      </div>
    );
  }
}
