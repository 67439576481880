import { Component } from 'react';
import Header from './Header';
import Reviews from './Reviews';
import Modal from './Modal';

import './styles/index.scss';

const initState = {
  avgRating: 0,
  fetching: false,
  filter: '',
  page: 1,
  perPage: 5,
  profileReviews: [],
  totalCrmReviews: 0,
  totalFilteredReviews: 0,
};

const quarterRoundSplit = num => {
  const quarterRound = Math.round(num * 4) / 4.0;
  let [int, fract] = (quarterRound).toString().split('.');

  fract = (fract && fract < 10) ? fract * 10 : (fract || 0) * 1;
  int = (int || 0) * 1;

  return [int, fract];
};

export default class Container extends Component {
  constructor(props) {
    super(props);

    this.state = initState;

    this.setAttribute = this.setAttribute.bind(this);
    this.fetchReviews = this.fetchReviews.bind(this);
  }

  componentDidMount() {
    this.fetchReviews();
  }

  setAttribute(attr, value, callback) {
    this.setState({ [attr]: value }, callback);
  }

  fetchReviews() {
    const {
      reviews_management_index_path,
      agent_id,
    } = this.props;

    const {
      page,
      perPage: per_page,
      filter
    } = this.state;

    const queryString = Util.toQueryString({
      agent_id,
      page,
      per_page,
      filter,
    });

    this.setState({ fetching: true });

    fetch(
      `${reviews_management_index_path}?${queryString}`,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      this.setState({ fetching: false });

      if (response.success) {
        const {
          reviews: profileReviews,
          avg_rating: avgRating,
          total_crm_reviews: totalCrmReviews,
          total_filtered_reviews: totalFilteredReviews,
        } = response;

        this.setState({
          avgRating,
          profileReviews,
          totalCrmReviews,
          totalFilteredReviews,
        });

      } else {
        console.log(response.errors);
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  render() {
    const {
      avgRating,
      fetching,
      filter,
      page,
      perPage,
      profileReviews,
      totalCrmReviews,
      totalFilteredReviews,
    } = this.state;

    const {
      reporting_options,
      reviews_management_index_path,
    } = this.props;

    return (
      <div className="profile-reviews-content mt-10">
        <Modal
          reportingOptions={ reporting_options }
          reviewsManagementIndexPath={ reviews_management_index_path }
          fetchReviews={ this.fetchReviews }
        />

        <Header
          avgRating={ avgRating }
          fetchReviews={ this.fetchReviews }
          filter={filter}
          page={ page }
          perPage={ perPage }
          quarterRoundSplit={ quarterRoundSplit }
          setAttribute={ this.setAttribute }
          totalCrmReviews={ totalCrmReviews }
          totalFilteredReviews={ totalFilteredReviews }
        />

        <Reviews
          fetching={ fetching }
          profileReviews={ profileReviews }
          quarterRoundSplit={ quarterRoundSplit }
        />
      </div>
    );
  }
}
