const submitted_rex = {
  buyer: (data) => {
    const {
      info,
      live,
    } = data;

    const {
      buyer_city_0,
      buyer_state_0,
      first_name,
      last_name,
    } = info;

    var bc = `${buyer_city_0}, ${buyer_state_0}`;
    return {
      introduction: {
        title: 'introduction',
        greeting: `Hi <strong>${first_name} ${last_name}</strong>,`,
        copy: `
        "I'm calling from ReferralExchange. This is in regards to your interest to speak with a real estate agent in ${bc.split('&')[0].replace(',', ', ')}. I want to make sure I can get you the information you’re looking for. Were you interested in purchasing or selling property at this time?”
        <br /><br />
        - Select 'Buyer' or 'Seller'
        <br /><br />
        “Oh, perfect. Our agents know the area well so if I can get a little more information from you, we can get started.”
        `
      },
      questions: {
        title: 'questions',
        greeting: false,
        copy: "I’d be happy to get you in touch with the top local agents to help you. To make sure I recommend the best agents for your needs, I’d like to confirm...",
        questions: [
          'Verify location',
          'Verify price range',
          'Verify mortgage status',
          'Is the client working with an agent',
          'Verify email',
        ],
        bonus: `Are you also selling any property?`
      },
      closing: {
        title: 'closing',
        greeting: false,
        copy: `“Great! I’ll get started on this right away. You can expect an email from us with the profiles of the top 2-4 local agents who are having the most success with property similar to what you’re looking for. The agents may follow up with you as well. Is there anything else I can help you with before we finish up?<br /><br />Thank you.”`
      }
    };
  },
  seller: (data) => {
    const { info } = data;

    const {
      city,
      first_name,
      last_name,
      state,
      street_name,
      street_number,
    } = info;

    return {
      introduction: {
        title: 'introduction',
        greeting: `Hi <strong>${first_name} ${last_name}</strong>,`,
        copy: `
        "I'm calling from ReferralExchange. This is in regards to your interest to speak with a real estate agent for ${`${street_number} ${street_name} in ${city}, ${state}`}. I want to make sure I can get you the information you’re looking for. Were you interested in purchasing or selling property at this time?”
        <br /><br />
        - Select 'Buyer' or 'Seller'
        <br /><br />
        “Oh, perfect. Our agents know the area well so if I can get a little more information from you, we can get started.”
        `
      },
      questions: {
        title: 'questions',
        greeting: false,
        copy: `I’d be happy to get you in touch with the top agents in the area. To make sure I recommend the best agents for your specific property, I’d like to confirm...`,
        questions: [
          'Verify location',
          'Verify property type',
          'Is the client working with an agent',
          'Is the property listed',
          'Verify email',
        ],
        bonus: `Are you also purchasing any Property?`
      },
      closing: {
        title: 'closing',
        greeting: false,
        copy: `“Great! I’ll get started on this right away. You can expect an email from us with the profiles of the top 2-4 local agents who are having the most success with homes like yours. The agents may follow up with you as well. Is there anything else I can help you with before we finish up?<br /><br />Thank you.`
      }
    };
  },
};

const submitted_agent = {
  buyer: (data) => {
    const {
      info,
      live,
    } = data;

    const {
      buyer_city_0,
      buyer_state_0,
      city,
      first_name,
      last_name,
      state,
      street_name,
      street_number,
    } = info;

    const {
      submitting_agent_name
    } = live;

    var bc = `${buyer_city_0}, ${buyer_state_0}`;

    return {
      introduction: {
        title: 'introduction',
        greeting: `Hi <strong>${first_name} ${last_name}</strong>,`,
        copy: `
        "I'm calling from ReferralExchange. This is in regards to your interest to speak with a real estate agent in ${bc.split('&')[0].replace(',', ', ')}. I want to make sure I can get you the information you’re looking for. Were you interested in purchasing or selling property at this time?”
        <br /><br />
        - Select 'Buyer' or 'Seller'
        <br /><br />
        “Oh, perfect. Our agents know the area well so if I can get a little more information from you, we can get started.”
        `
      },
      questions: {
        title: 'questions',
        greeting: false,
        copy: `${submitting_agent_name} knows the area well so I’d love to get you two connected while I have you on the phone. Let me confirm a couple of details to make sure what I see here is accurate...`,
        questions: [
          'Verify location',
          'Verify price range',
          'Verify mortgage status',
          'Is the client working with an agent',
          'Verify email'
        ],
        bonus: `Are you also selling any property?`
      },
      closing: {
        title: 'closing',
        greeting: false,
        copy: `“Great! I’m going to speak with ${submitting_agent_name} now and go over everything we discussed together. [He/she] will be reaching out to go over this further, but I’ll email you their contact information so you can get in touch with them as well. Now, is there anything else I can help you with while I have you on the phone?<br /><br />Thank you.”`
      }
    }
  },
  seller: (data) => {
    const {
      info,
      live,
    } = data;

    const {
      buyer_city_0,
      buyer_state_0,
      city,
      first_name,
      last_name,
      state,
      street_name,
      street_number,
    } = info;

    const {
      submitting_agent_name
    } = live;

    return {
      introduction: {
        title: 'introduction',
        greeting: `Hi <strong>${first_name} ${last_name}</strong>,`,
        copy: `
        "I'm calling from ReferralExchange. This is in regards to your interest to speak with a real estate agent for ${`${street_number} ${street_name} in ${city}, ${state}`}. I want to make sure I can get you the information you’re looking for. Were you interested in purchasing or selling property at this time?”
        <br /><br />
        - Select 'Buyer' or 'Seller'
        <br /><br />
        “Oh, perfect. Our agents know the area well so if I can get a little more information from you, we can get started.”
        `
      },
      questions: {
        title: 'questions',
        greeting: false,
        copy: `${submitting_agent_name} knows the area well so I’d love to get you two connected while I have you on the phone. Let me confirm a couple of details to make sure what I see here is accurate...`,
        questions: [
          'Verify location',
          'Verify property type',
          'Is the client working with an agent',
          'Is the property listed',
          'Verify email',
        ],
        bonus: `Are you also purchasing any Property?`
      },
      closing: {
        title: 'closing',
        greeting: false,
        copy: `“Great! I’m going to speak with ${submitting_agent_name} now and go over everything we discussed together. [He/she] will be reaching out to go over this further, but I’ll email you their contact information so you can get in touch with them as well. Now, is there anything else I can help you with while I have you on the phone?<br /><br />Thank you.”`
      }
    };
  }
};

const default_live = (data) => {
  const { info } = data;

  const {
    buyer_city_0,
    buyer_state_0,
    city,
    first_name,
    last_name,
    state,
    street_name,
    street_number,
  } = info;

  var bc = `${buyer_city_0}, ${buyer_state_0}`;

  return {
    introduction: {
      title: 'introduction',
      greeting: `Hi <strong>${first_name} ${last_name}</strong>,`,
      copy: `
      "I'm calling from ReferralExchange. This is in regards to your interest to speak with a real estate agent for ${buyer_city_0 && buyer_city_0.length > 0 ? bc.split('&')[0].replace(',', ', ') : `${ street_number || '(street number)' } ${ street_name || '(street name)' } in ${ city || '(city)' }, ${ state || '(state)' }`}. I want to make sure I can get you the information you’re looking for. Were you interested in purchasing or selling property at this time?”
      <br /><br />
      - Select 'Buyer' or 'Seller'
      <br /><br />
      “Oh, perfect. Our agents know the area well so if I can get a little more information from you, we can get started.”
      `
    },
    questions: {},
    closing: {}
  };
};

const scriptMap = {
  submitted_rex,
  submitted_agent,
  default_live
};

export default scriptMap;
