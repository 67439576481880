import Lead from './body/Lead';
import Script from './body/Script';
import Agent from './body/Agent';

const Body = (props) => {
  const {
    lead,
    agent,
  } = props;

  return(
    <div id="dialer-recruit-top-agents-body">
      <div className="row">
        <div className="col-md-6">
          <Lead lead={ lead } />
          <Script agent={ agent } lead={ lead } />
        </div>
        <div className="col-md-6">
          <Agent agent={ agent } lead={ lead } />
        </div>
      </div>
    </div>
  );
};

export default Body;
