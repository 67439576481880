import { Component } from 'react';

export default class AgentSlots extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let { agents } = this.props.state;

    let committedIcon = (agent) => {
      if (agent.has_committed) {
        return (
          <i className='fa fa-handshake-o'>
            <span className='ai-tooltip'>Committed</span>
          </i>
        )
      }
    }

    let appointmentIcon = (agent) => {
      if (agent.has_appointment) {
        return (
          <i className='fa fa-calendar-check-o'>
            <span className='ai-tooltip'>Appointment</span>
          </i>
        )
      }
    }

    return (
      <div className='agent-slots'>
        <div className='header'>
          <div className="title">
            <h3>Monitored Agents</h3>
            <div className="counter">{agents.length > 0 ? `(${agents.length})` : ''}</div>
          </div>
          <input
            type='button'
            className='edit-button'
            value='Edit Slots'
            onClick={() => this.props.updateState({ showAgentModal: true })}
          />
        </div>
        <div className='list h257'>
          <div className='list-inner'>
            {agents.map((agent, idx) => {
              let execs = agent.sales_executives.join(', ')
              return (
                <div className='list-item-centered' key={idx}>
                  <div className='list-item-centered-detail w40 name'>{idx + 1}</div>
                  <div className='list-item-centered-detail w200 name'>{agent.full_name}</div>
                  <div className='list-item-centered-detail w200 execs'>Executives: {execs}</div>
                  <div className='list-item-centered-detail w20 icon'>{committedIcon(agent)}</div>
                  <div className='list-item-centered-detail w20 icon'>{appointmentIcon(agent)}</div>
                  <a href={`/a/${agent.id}`} target='_blank' className='backend-button'>backend</a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    )
  }
}
