import  { Component } from  'react';
import ButtonBase from './ButtonBase';

export default class Conference extends ButtonBase {
  determineStatusClass() {
    switch(this.props.callStatus) {
      case 'init':
        return 'disabled'
      case 'contacting_lead':
        return 'disabled'
      case 'contacting_agent':
        return 'disabled'
      case 'agent_contacted':
        return 'disabled'
      case 'agent_connected':
        return 'on-line'
      case 'lead_connected':
        return 'disabled'
      case 'agent_ready':
        return 'call'
      case 'agent_unavailable':
        return 'disabled'
      case 'solo_agent':
        return 'call'
      case 'solo_lead':
        return 'call'
      case 'conference':
        return 'on-line'
      case 'complete':
        return 'disabled'
      default:
        return 'disabled'
    }
  }

  renderIcon() {
    return (<i className='fa fa-users with-text'/>)
  }

  modeType() {
    return 'conference';
  }
}
