const ServiceArea = (props) => {
  const {
    cityName,
    children,
  } = props;

  return (
    <div className='service-area' data-city={ cityName }>
      <h3>{ cityName }</h3>
      { children }
    </div>
  );
};

export default ServiceArea;
