const buildStatusIcon = status => {
  let klasses;

  switch (status) {
    case 'processing':
      klasses = 'fa fa-spinner fa-pulse fa-spin green-text';
      break;
    case 'complete':
      klasses = 'fa fa-check-circle-o green-text';
      break;
    case 'failed':
      klasses = 'fa fa-times-circle-o red-text';
      break;
    default:
      klasses = 'fa fa-circle-o grey-text';
      break;
  }

  return <i className={ `${klasses} batch-status` }></i>;
};

const buildData = batch => {
  const data = [];

  const {
    id,
    requested,
    allocated,
    status,
    created_at,
    updated_at,
    created_by,
  } = batch;

  data.push(<td>{ requested }</td>);
  data.push(<td>{ allocated }</td>);
  data.push(<td>{ buildStatusIcon(status) } { status }</td>);
  data.push(<td>{ moment(created_at).format('ddd, MM/DD/YYYY hh:mm A') }</td>);
  data.push(<td>{ moment(updated_at).format('ddd, MM/DD/YYYY hh:mm A') }</td>);
  data.push(<td>{ created_by }</td>);

  return data;
};

const buildRows = batches => {
  const rows = [];

  batches.forEach(batch => rows.push(<tr>{ buildData(batch) }</tr>));

  return rows;
};

const Body = ({ batches }) => {
  return (
    <tbody>
      { buildRows(batches) }
    </tbody>
  );
};

export default Body;
