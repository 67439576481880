import SuccessIcon from '../../../../LiveAccountManagement/images/success.png';

const Success = (props) => {
  const {
    toggleModal,
  } = props;

  return (
    <div id='disable-success'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ SuccessIcon } />
      </div>

      <div className='rex-modal-heading'>
        PRIME Disabled
      </div>

      <div className='rex-modal-contents'>
        <div className='rex-modal-actions rex-modal-actions__centered'>
          <button
            className='rex-modal-action'
            onClick={ () => toggleModal(false) }
          >
            CLOSE THIS WINDOW
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
