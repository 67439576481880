import { all, call, put, takeLatest } from 'redux-saga/effects';
import request from 'superagent';

const getMatches = (data) => {
  const { id } = data;

  return request
    .get('/dialer/profile_review/content')
    .query({ lead_id: id })
    .then(({ body }) => {
      return body.matches;
    });
};

function* getMatchesData(action) {
   try {
      const data = yield call(getMatches, action.data);
      yield put({type: 'SET_MATCHES_DATA', data});
   } catch (e) {
      yield put({type: 'GET_MATCHES_DATA_FAILURE', message: e.message});
   }
}

function* getMatchesSaga() {
  yield takeLatest('GET_MATCHES_DATA', getMatchesData);
}

function* showNewLeadModal() {
  try {
    const newLeadFromLeadModal = document.getElementById('new-lead-from-lead-modal');

    if (newLeadFromLeadModal) {
      newLeadFromLeadModal.classList.remove('hidden');
    }

    yield put({type: 'SHOW_NEW_LEAD_MODAL_SUCCESS', message: e.message});
  } catch (e) {
    yield put({type: 'SHOW_NEW_LEAD_MODAL_FAILURE', message: e.message});
  }
}

function* showNewLeadModalSaga() {
  yield takeLatest('SHOW_NEW_LEAD_MODAL', showNewLeadModal);
}

export default [
  getMatchesSaga(),
  showNewLeadModalSaga(),
];
