import { combineReducers } from 'redux';
import { Map } from 'immutable';

import actions from './Actions/reducers';
import focused from './focus/reducers';
import headers from './Header/reducers';
import info from './Info/reducers';
import lead from './lead/reducers';
import live from './live/reducers';
import metadata from './Metadata/reducers';
import newLead from './NewLead/reducers';
import note from './Note/reducers';
import qual from './Qual/reducers';
import submittingAgent from './SubmittingAgent/reducers';
import timeSlots from './timeSlots/reducers';

import validations from './validations/reducers';

const initialState = Map({
  data: null,
  leadId: null,
});

const actionsMap = {
  ['SET_ACTIONDIALER_DATA']: (state, action) => {
    const { data } = action;

    return state.merge(Map({
      data,
      leadId: data.lead.id
     }));
  },
  ['RESET_ACTIONDIALER_DATA']: (state) => {
    return state.merge(Map({ data: null, leadId: null }));
  },
};

function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}

const actiondialer = reducer;

const reducers = combineReducers({
  actiondialer,
  actions,
  focused,
  headers,
  lead,
  info,
  live,
  metadata,
  newLead,
  note,
  qual,
  submittingAgent,
  timeSlots,
  validations,
});

export default reducers;
