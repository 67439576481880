import TextareaField from './../../_common/TextareaField.js';

const ClientComments = props => {
  const {
    updateFieldHandler,
  } = props;

  return (
    <div className="section">
      <div className="header">
        <div className="icon-wrapper"><i className="icon annotation"></i></div>
        <div className="title-wrapper">
          <h2>Client Comments</h2>
        </div>
      </div>

      <div className="input-row">
        <TextareaField fieldTitle="Client Comments" placeholder="Please write your notes" required={ false } updateFieldHandler={ updateFieldHandler } />
      </div>
    </div>
  );
};

export default ClientComments;
