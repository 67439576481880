import React from 'react';

const AltosDetails = (props) => {
  const {
    handleToggledDetails,
    altosAgentInfo,
    handleServiceArea
  } = props;

  const {
    name,
    phone_number,
    email,
    license_number,
    license_state,
    brokerage,
    service_area,
    years_experience,
  } = altosAgentInfo;

  return (
    <div className="dialer-agent-details-altos">
      <div className="agent-details-header">
        <div className='agent-name'>
          { name }
        </div>
        <div>
          <span className="agent-pill altos-pill">Altos</span>
        </div>
      </div>
      <div className="number-agent-link">
        <div className="agent-details">
          <i className="phone" />
          <div className="agent-details-value">
            { phone_number }
          </div>
        </div>
      </div>
      <div className="agent-details">
        <i className="envelope" />
        <div className="agent-details-value">
          { email }
        </div>
      </div>
      <hr />
      <table>
        <thead>
          <tr>
            <th>License Number</th>
            <th>License State</th>
            <th>Brokerage</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{ license_number }</td>
            <td>{ license_state }</td>
            <td>{ brokerage }</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <div id="toggled-details-altos" className="hidden">
        <div className="service-area">
          <div className="service-area-header">
            Service Areas
          </div>
        <div className="service-area-value">
          <i className="map-marker" />
          <div className="service-area-cities">
            { handleServiceArea(service_area) }
          </div>
        </div>
        </div>
        <div className="experience">
          <div className="experience-header">
            Years of Experience
          </div>
          <div className="experience-years">
            { years_experience }
          </div>
        </div>
      </div>
      <div className="toggle-icons">
        <i id="angle-up-altos" className="hidden" onClick={ handleToggledDetails }/>
        <i id="angle-down-altos" onClick={ handleToggledDetails }/>
      </div>
    </div>
  )
};

export default AltosDetails;
