import React          from 'react';
import Communications from './images/communications.png';
import RadioOption    from './CancelReactivateRadioView';

const CancelReactivateReasonView = (props) => {
  const {
    selectedText,
    toggleModal,
    goTo,
    selectRadio,
    fillOther,
    otherText,
  } = props;

  const heading = 'WHY DOES THE AGENT WANT TO CANCEL?';

  const buttonOneText     = 'CONTINUE CANCELING';
  const buttonOneOnClick  = () => goTo('confirm');
  let buttonOneDisabled = true;
  if (selectedText) buttonOneDisabled = false;

  const buttonTwoText     = 'KEEP ACCOUNT ACTIVE';
  const buttonTwoOnClick  = () => toggleModal(false);

  const reasons = [
    'Unhappy with service/No movement on submitted leads',
    'Misunderstood membership/Subscription cost/Lead processing fees',
    'Technical issues (i.e. leads not routing correctly)',
    'Lack of support/Customer Service',
    'Happy with service, but no longer have a need for the product',
    'Other'
  ];

  return (
    <div className='toggle-live-subscription'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ Communications } />
      </div>
      <div className='rex-modal-wrapper'>
        <div className='rex-modal-heading'>
          { heading }
        </div>
        <div className='rex-modal-contents'>
          <div className='rex-modal-subheading'>
            {
              reasons.map((reason, i) => {
                return (
                  <RadioOption
                    key={ i }
                    reason={ reason }
                    selectRadio={ selectRadio }
                    selectedText={ selectedText }
                  />
                );
              })
            }
            <textarea
              className='reason-other-text'
              placeholder='Please input feedback here.'
              onChange={ fillOther }></textarea>
          </div>
          <div className='rex-modal-actions rex-modal-actions__multiple-actions'>
            <button
              className='rex-modal-action rex-modal-multiple-action'
              onClick={ buttonOneOnClick }
              disabled={ buttonOneDisabled }
            >
              { buttonOneText }
            </button>
            <button
              className='rex-modal-action rex-modal-multiple-action'
              onClick={ buttonTwoOnClick }
            >
              { buttonTwoText }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelReactivateReasonView;
