import { Component } from 'react';

import OriginCallSelector from './OriginCallSelector';

export default class LeadPersonalInfo extends Component {
  constructor(props){
    super(props)

    this.state = {
      micrositeSelected:          [],
      phoneNumberNational: this.props.phoneNumberNational,
      // If we already have an originCallID, default to enabled.
      displayOriginCallSelector:  props.originCallID !== null ? true : false
    }

    this.toggleDislayOriginCallSelector = this.toggleDislayOriginCallSelector.bind(this);
    this.updateE164Number = this.updateE164Number.bind(this);
  }

  buildLanguageOptions() {
    return (
      <select value={ this.props.languagePreference } onChange={ e => this.props.handleChange('languagePreference', e) }>
        { window.SERVICEABLE_LANGUAGES.map(([displayName, code]) => <option value={ code }>{ displayName }</option>) }
      </select>
    );
  }

  micrositesList(){
    return(
      <select value={this.props.microsite} disabled={this.props.isExclusive} onChange={(e) => this.handleMicrositeChange('microsite', e)}>
        {this.props.formData.microsites.map(function(microPair, idx) {
            let name = microPair[0];
            if (microPair[2]) name += ` - ${microPair[2]}`;
            return (<option value={microPair[1]} key={idx + 1}>{name}</option>);
          })
        }
      </select>
    )
  }

  componentDidMount() {
    Util.initPhoneCountrySelectors(['#phone_number_national'], (this.props.phoneNumberCountry || 'US'), this.updateE164Number);
  }

  changeNationalPhoneNumber(e) {
    // Replace invalid phone characters (anything except digits, parenthesis, space, dash, leading plus sign)
    const prefix = e.target.value.trim().startsWith('+') ? '+' : '';
    let newNumber = prefix + e.target.value.replace(/[^\d\(\)\- ]/g, '');
    this.setState({ phoneNumberNational: newNumber });
  }

  updateE164Number(_, iti) {
    const dummyEvent = {
      currentTarget: {
        value: iti.getE164PhoneNumber()
      }
    }
    this.props.handleChange('phoneNumber', dummyEvent);
  }

  handleMicrositeChange(microsite, e){
    this.props.handleChange('microsite', e)
    // Reset previous innerText if there
    if (this.state.micrositeSelected.length != 0) {
      let previousIndex = this.state.micrositeSelected[0].index
      let previousInnerText = this.state.micrositeSelected[0].innerText
      e.target.options[previousIndex].innerText = previousInnerText;
      this.setState({micrositeSelected: []});
    }

    // Store selected option and truncate text
    if (e.target.options[e.target.selectedIndex].innerText.length > 28) {
      let selectedOptionId = e.target.options[e.target.selectedIndex].value;
      let selectedOptionText = e.target.options[e.target.selectedIndex].innerText;
      let selectedOptionIndex = e.target.selectedIndex;
      let micrositeSelected = [{index: selectedOptionIndex, innerText: selectedOptionText}];
      this.setState({micrositeSelected: micrositeSelected});
      e.target.options[e.target.selectedIndex].innerText = selectedOptionText.substr(0, 28) + "...";
    }
  }

  render() {
    let { firstName, lastName, email, languagePreference, phoneNumber, comments, microsite, handleChange, handleToggle } = this.props;

    return (
      <div>
      <div className='fieldset'>
        <div className='row'>
          <div className='col-sm-5 left-column'>
            <label className='field-label'>First Name<span className='required-field'>*</span></label>
            <input
              type='text'
              value={firstName}
              onChange={(e) => handleChange('firstName', e)}
            />
          </div>
          <div className='col-sm-5 right-column'>
            <label className='field-label'>Last Name<span className='required-field'>*</span></label>
            <input
              type='text'
              value={lastName}
              onChange={(e) => handleChange('lastName', e)}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-5 left-column'>
            <label className='field-label'>Email<span className='required-field'>*</span></label>
            <input
              type='text'
              value={email}
              onChange={(e) => handleChange('email', e)}
            />
          </div>
          <div className='col-sm-5 right-column'>
            <label className='field-label'>Phone Number</label>
            <input
              id='phone_number_national'
              type='text'
              maxLength='20'
              value={this.state.phoneNumberNational}
              onChange={(e) => this.changeNationalPhoneNumber(e)}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-5 left-column'>
            <label className='select-label field-label'>Microsite</label>
            { this.micrositesList() }
          </div>
          <div className='col-sm-5 left-column'>
            <label className='select-label field-label'>Language</label>
            { this.buildLanguageOptions() }
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-10'>
            <label className='field-label comments-label'>Comments</label>
            <textarea
              value={comments}
              onChange={(e) => handleChange('comments', e)}
            />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-10'>
          <h2 className='source-field-label source-label'>Source</h2>
        </div>
      </div>

      <div className='row'>
        <div className="col-sm-3 mb-20 lead-source-button-wrapper">
          <label className={"button-label " + (this.props.isQualContactBonus === true ? 'label-highlight' : '')} onClick={() => handleToggle('isQualContactBonus')}>QC - BONUS
          </label>
        </div>

        <div className="col-sm-3 mb-20 lead-source-button-wrapper">
          <label className={"button-label " + (this.props.isLFU === true ? 'label-highlight' : '')} onClick={() => handleToggle('isLFU')}>LFU
          </label>
        </div>

        <div className="col-sm-3 mb-20 lead-source-button-wrapper">
          <label className={"button-label " + (this.state.displayOriginCallSelector === true ? 'label-highlight' : '')} onClick={ this.toggleDislayOriginCallSelector }>Inbound Call
          </label>
        </div>

        <div className="col-sm-3 mb-20 lead-source-button-wrapper">
          <label className={ "button-label " + (this.props.isTvAd === true ? 'label-highlight' : '') } onClick={ () => handleToggle('isTvAd') }>TV Ad
          </label>
        </div>
      </div>

      { this.state.displayOriginCallSelector && this.renderOriginCallSelector() }

      <div className='row key-row'>
        <div className='col-sm-5'>
          <p className='required-key-text'>* Indicates required field</p>
        </div>
      </div>

      <div className='row'>
        <hr className='hr-bottom'></hr>
      </div>
    </div>
    )
  }

  /*
   * Toggle rendering of the origin call selector.
   */
  toggleDislayOriginCallSelector() {
    this.setState(
      { displayOriginCallSelector: !this.state.displayOriginCallSelector },
      () =>  {
        // keep the parent state up to date with whether or not this box is selected
        this.props.handleChange('originatedFromInboundCall', { currentTarget: { value: this.state.displayOriginCallSelector } });
      }
    );
  }

  /*
   * Render the origin call selector.
   */
  renderOriginCallSelector() {
    let { originCallID, handleChange } = this.props;

    return (
      <div className="fieldset">
        <div className='row'>
          <div className="col-sm-10 left-column">
            <label class="origin-call-field-label field-label">Origin Call</label>

            <OriginCallSelector
              selectedCall  = { originCallID  }
              handleChange  = { event => handleChange('originCallID', event) }
            />
          </div>
        </div>
      </div>
    );
  }
}
