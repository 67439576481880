import { Component } from 'react';

export default class AgentInsightExecutives extends Component {
  constructor(props){
    super(props)

    this.parseClick2CallHref = this.parseClick2CallHref.bind(this);
  }

  parseClick2CallHref(number) {
    if(!number) return '';
    number = number.replace(/\D+/g, '');
    return (
      '/click2call?call_destination=sales_manager&call_destination_id=' +
      this.props.state.salesExecutives.id +
      '&call_type=standard&phone=' + number
    )
  }

  render(){
    let { salesExecutives } = this.props.state;
    return(
      <div className="sales-execs">
        <div className="header">
          <div className="title">
            <h3>Sales Executives</h3>
            <div className="counter">{salesExecutives.length > 0 ? `(${salesExecutives.length})` : ''}</div>
          </div>
          <input
            type="button"
            className='edit-button'
            value='Edit Executives'
            onClick={() => this.props.updateState({ showExecutiveModal: true })}
          />
        </div>
        <div className="list h257">
          <div className="list-inner">
            { salesExecutives.map((exec, idx) => {
              let name = `${exec.first_name} ${exec.last_name}`;
              let phone = Util.formatPhoneNumber(exec.phone);
              return(
                <div className="list-item" key={idx}>
                  <div className="list-item-detail w195 name">{ name }</div>
                  <div className="list-item-detail w195 agents">Agents: { exec.agent_count }</div>
                  <div className="list-item-detail w195 phone">
                    Phone: { phone } &nbsp;&nbsp;
                    <a
                      data-remote="true"
                      rel="nofollow"
                      data-method="post"
                      className='click-to-call-phone-type'
                      href={this.parseClick2CallHref(exec.phone)}>
                      <i className="fa fa-phone-square"></i>
                    </a>
                  </div>
                  <div className="list-item-detail w300 email">Email: { exec.email }</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    )
  }
}
