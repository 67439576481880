import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'superagent';

const getValidationRecords = ({ leadId }) => {
  return request
    .get('/qual/queue/validation_records')
    .query({ lead_id: leadId })
    .then(({ body }) => body.validation_records);
};

function* fetchVerificationRecords(action) {
   try {
      const data = yield call(getValidationRecords, action.data);
      yield put({ type: 'SET_VERIFICATION_RECORDS', data });
   } catch (e) {
      yield put({type: 'GET_VERIFICATION_RECORDS_FAILED', message: e.message});
   }
}

function* verificationRecordSaga() {
  yield takeLatest('GET_VERIFICATION_RECORDS', fetchVerificationRecords);
}

export default [
  verificationRecordSaga(),
];
