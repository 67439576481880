import Header from './Header';
import Body from './Body';

const Wrapper = (props) => {
  const {
    fetched,
    history,
  } = props;

  return (
    <table className='table table-striped email-history-table'>
      <Header />
      <Body
        fetched={ fetched }
        history={ history }
      />
    </table>
  )
};

export default Wrapper;
