const RadioField = props => {
  const {
    fieldId,
    fieldText,
    fieldValue,
    fieldName,
    fieldChecked,
    onClickHandler,
  } = props;

  return (
    <label htmlFor={ id } className="mr-10">
      <input type="radio" className="mr-10" id={ fieldId } name={ fieldName } checked={ fieldChecked } value={ fieldValue } onClick={ onClickHandler } />
      { fieldText }
    </label>
  );
};

export default RadioField;
