import React from 'react';
import Alert from './images/alert.png';

const CancelReactivateConfirmView = (props) => {
  const {
    canceledLive,
    toggleModal,
    reset,
    loading,
    handleCheckbox,
    toggle,
    balanceOwed
  } = props;

  let subheading = `
    "YES I WANT TO CANCEL" will cancel the agent's account and their card on file will be immediately charged for their remaining balance of $${ balanceOwed }.
    If the card is declined, we cannot cancel the account.
    The agent will retain access to LIVE, and you should assist the agent in updating their card on file in order to successfully cancel their account.
  `
  let buttonOneText    = 'YES, I WANT TO CANCEL';
  let buttonOneOnClick = toggle;
  let buttonTwoText    = 'NO, KEEP ACCOUNT ACTIVE';
  let buttonTwoOnClick = (() => toggleModal(false));

  if (canceledLive) {
    subheading = `
      Selecting "YES, REACTIVATE ACCOUNT" will immediately reactivate the agent’s LIVE subscription.
      By checking the box below, the agent’s billing date will be reset, and the agent will be charged the monthly subscription fee.
    `
    buttonOneText    = 'NO, KEEP ACCOUNT CANCELED';
    buttonOneOnClick = (() => toggleModal(false));
    buttonTwoText    = 'YES, REACTIVATE ACCOUNT';
    buttonTwoOnClick = toggle;
  }

  return (
    <div className='toggle-live-subscription'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ Alert } />
      </div>
      <div className='rex-modal-wrapper'>
        <div className='rex-modal-heading'>
          Please Read and Proceed with Caution
        </div>
        <div className='rex-modal-contents'>
          <div className='rex-modal-subheading'>
            { subheading }
          </div>
          {
            canceledLive &&
            <div className='rex-modal-checkbox-contents'>
              <input
                type='checkbox'
                onChange={ handleCheckbox }
                checked={ reset }
              />
            <div className='rex-modal-checkbox-text'>
                Reset billing date and charge monthly subscription fee
              </div>
            </div>
          }
          <div className='rex-modal-actions rex-modal-actions__multiple-actions'>
            <button
              className={ `rex-modal-action rex-modal-multiple-action ${ (loading && !canceledLive) ? 'rex-modal-action__loading' : '' }` }
              onClick={ buttonOneOnClick }
              disabled={ loading && !canceledLive }
            >
              { (loading && !canceledLive) ? 'Loading' : buttonOneText }
            </button>
            <button
              className={ `rex-modal-action rex-modal-multiple-action ${ (loading && canceledLive) ? 'rex-modal-action__loading' : '' }` }
              onClick={ buttonTwoOnClick }
              disabled={ loading && canceledLive }
            >
              { (loading && canceledLive) ? 'Loading' : buttonTwoText }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelReactivateConfirmView;
