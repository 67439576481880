import { Component } from 'react';

export default class AppointmentOnboarded extends Component {
  constructor(props){
    super(props);
    this.state = {
      setupCalendar: false
    }
    this.updateAppointment = this.updateAppointment.bind(this);
  }

  containsErrors(){
    let { followUpTime, nextTask, errors, updateErrors } = this.props;
    let { assigned_to, disposition } = nextTask;

    let assignedToError = !assigned_to ? true : false;
    let followUpTimeError = () => {
      if(disposition === 'refund_requested') return false;
      if(!followUpTime) return true;
      return false;
    }
    let dispositionError = !disposition ? true : false;

    errors.assignedTo = assignedToError;
    errors.followUpTime = followUpTimeError();
    errors.disposition = dispositionError;
    updateErrors(errors);

    return assignedToError || followUpTimeError() || dispositionError;
  }

  updateAppointment(){
    if(this.containsErrors()) return;
    let { nextTask, currentTask, followUpTime, currentUser, agentId, timeZone, resetState, setUpdating, note } = this.props;
    let parsedTime = Util.parseDateTime(followUpTime, -1 * (timeZone.offset + 8));
    let tempDate = new Date(parsedTime);
    let submitDisposition;
    let parsedTimePlusOneDay = Util.parseForSQL(tempDate.setDate(tempDate.getDate() + 1))

    setUpdating(true);
    let updateParams = {
      completed_task: { task_id: currentTask.id },
      new_task: {
        taskable_id: agentId,
        follow_up: parsedTime,
        assigned_to: nextTask.assigned_to
      },
      note: {
        noteable_id: agentId
      }
    }

    switch(nextTask.disposition){
      case 'call_completed':
        updateParams.note.message = `Follow-up call completed. ${note}.`;
        submitDisposition = Util.onboardedCompleted;
        break;
      case 'call_later':
        updateParams.note.message = `Follow-up call rescheduled for ${followUpTime}.\n ${note}`;
        submitDisposition = Util.onboardedCallLater;
        break;
      case 'no_answer':
        updateParams.note.message = `Follow up call missed. ${note}`;
        submitDisposition = Util.onboardedNoAnswer;
        break;
      case 'refund_requested':
        updateParams.note.message = `Refund requested by Agent. ${note}`;
        updateParams.new_task.follow_up = null;
        submitDisposition = Util.onboardedRefundRequested;
        break;
    }

    submitDisposition(updateParams)
      .then(() => resetState());
  }

  callLaterSelector(){
    return(
      <select id='call_later_days' onChange={(e) => this.props.changeFollowUp(Util.numberToDateTime(e))} >
        <option value=''></option>
        <option value='7'>7</option>
        <option value='15'>15</option>
        <option value='30'>30</option>
        <option value='45'>45</option>
      </select>
    )
  }

  attachCalendar(){
    let timeZone = this.props.timeZone
    if(!this.state.setupCalendar && $('#call_date_setter')){
      setTimeout(() => {
        $('#call_date_setter').attachLinkedDateTimePickerWithTimezone('#call_later_days', 30, timeZone.time_zone);
        $('.ui-datepicker-trigger').hide();
        $(document).on('valueChange', '#call_date_setter', () => {
          this.props.changeFollowUp($('#call_date_setter').val());
        });
        this.setState({setupCalendar: true});
      }, 200)
    }
  }

  render(){
    let { showAppointment, assigneeOptions, errors, followUpTime, updateNote, note, updating, nextTask, appointmentTime, statusOptions } = this.props;

    return(
      <div className={showAppointment ? 'cs-call-from' : 'cs-call-from-collapse'}>
        <div className='cs-call-from-inner'>
          <label>
            Date: &nbsp;
            <span>{ Util.dateTimetoDate(nextTask.follow_up) }</span>
          </label>
          <label>
            Time: &nbsp;
            { appointmentTime(nextTask.follow_up) }
          </label>
          <label>With: </label>
          { assigneeOptions() }
          <label>Appointment Status</label>
          { statusOptions() }
          <div className={['call_completed', 'call_later', 'no_answer'].includes(nextTask.disposition) ? 'follow-up' : 'hidden'}>
            <label>Follow Up (agent time)</label>
            <div className={errors.followUpTime ? 'follow-up-fields cs-error' : 'follow-up-fields'}>
              <input
                id='call_date_setter'
                className='follow-up-calendar'
                type='input'
                placeholder='Follow up'
              />
              { this.callLaterSelector() }
              { this.attachCalendar() }
            </div>
          </div>
          <label>Note: </label>
          <textarea
            className={errors.note ? 'cs-error' : ''}
            onChange={updateNote}
            value={note}
          />
          <input
            className='button'
            disabled={updating}
            type='button'
            value='Save'
            onClick={this.updateAppointment}
          />
        </div>
      </div>
    );
  }
}
