import { Component } from 'react';

export default class CompaniesToBlock extends Component {
  constructor( props ) {
    super( props );
  }

  componentDidMount() {
    var brands = [];
    var vc = [];
    if( this.props.data.lead.blocked_company_brand_ids !== null && this.props.data.lead.blocked_company_brand_ids.length)
      brands = this.props.data.lead.blocked_company_brand_ids.split( '&' );

    if( this.props.data.lead.blocked_verified_company_ids !== null && this.props.data.lead.blocked_verified_company_ids.length )
      vc = this.props.data.lead.blocked_verified_company_ids.split( '&' );
    this._addtoPMRData( brands, 'blocked_company_brand_ids' );
    this._addtoPMRData( vc, 'blocked_verified_company_ids' );
  }

  render() {
    let companies = this.props.data.companies.brands ? this.props.data.companies.brands : [];
    let verifiedCompanies = this.props.data.companies.verified ? this.props.data.companies.verified : [];
    return(
      <div className='field full companies_to_block'>
        { this._loadingMessage() }
        <div className='label'>
          Companies to block
        </div>
        <input
          type='text'
          id='companyFilter'
          data-optional='true'
          placeholder='Filter companies'
          onKeyUp={this._filterCompanies}
        />
        <input
          type='button'
          className='companyFilterButton'
          value='Select All'
          id='selectVisible'
          onClick={this._selectVisible}
        />
        <input
          type='button'
          className='companyFilterButton'
          value='De-select All'
          id='unselectVisible'
          onClick={this._unselectVisible}
        />
        <br />
        { companies.map( this._renderBrandCompany.bind( this ) ) }
        <hr/>
        { verifiedCompanies.map( this._renderVCCompany.bind( this ) ) }
      </div>
    )
  }

  _filterCompanies() {
    if (!$('#companyFilter').val()) {
      $('.cbox').show();
      return;
    }

    // ignore any special characters and combine whitespace to single space
    const string = $('#companyFilter').val().replace(/[^a-z0-9\s]/gmi, '').replace(/\s+/, ' ').toLowerCase();
    $('.cbox').show().filter(function() {
      return $(this).text().replace(/[^a-z0-9\s]/gmi, '').replace(/\s+/, ' ').toLowerCase().indexOf(string) < 0
    }).hide()
  }

  _selectVisible(event) {
    event.preventDefault();
    $('.cbox:visible input:not(:checked)').click();
  }

  _unselectVisible(event) {
    event.preventDefault();
    $('.cbox:visible input:checked').click();
  }

  _loadingMessage() {
    return this.props.data.companies_loading ? 'Companies loading, may take a while...' : ''
  }

  _renderBrandCompany( company, i ) {
    var name = company.table.name.toLowerCase().replace(/ /g, '_');
    if (name === '') return null;

    var id = `${company.table.id}`;
    var checked;
    var brands = [];

    if( this.props.data.lead.blocked_company_brand_ids !== null )
      brands = this.props.data.lead.blocked_company_brand_ids.split( '&' );

    checked = brands.includes( id );

    return(
      <div className='cbox full' key={ i }>
        <input
          type="checkbox"
          value={ company.table.id }
          checked={ checked ? 'checked' : '' }
          name={ name } id={ name + '_' + company.table.id }
          onChange={ this._handleBrandCheckBox.bind( this ) }
          />
        <label htmlFor={ name + '_' + company.table.id }>{ company.table.name }</label>
      </div>
    )
  }

  _renderVCCompany( company, i ) {
    var name = company.table.name.toLowerCase().replace(/ /g, '_');
    var id = `${company.table.id}`;
    var checked;
    var vc = [];

    if( this.props.data.lead.blocked_verified_company_ids !== null )
      vc = this.props.data.lead.blocked_verified_company_ids.split( '&' );

    checked = vc.includes( id );

    return(
      <div className='cbox full' key={ i }>
        <input
          type="checkbox"
          value={ company.table.id }
          checked={ checked ? 'checked' : '' }
          name={ name }
          id={ name + '_' + company.table.id }
          onChange={ this._handleVCCheckBox.bind( this ) }
          />
        <label htmlFor={ name + '_' + company.table.id }>{ company.table.name }</label>
      </div>
    )
  }

  _addtoPMRData( arr, type ) {
   arr.forEach( ( id ) => {
     this.props.data.pmr_form[ type ][ id ] = '1';
   });

   this.props.updateState( this.props.data );
  }

  _handleBrandCheckBox( e ) {
    var brands = [];

    let blockedCompanyBrandIds = this.props.data.lead.blocked_company_brand_ids;
    if( blockedCompanyBrandIds !== null && blockedCompanyBrandIds !== '' )
      brands = blockedCompanyBrandIds.split( '&' );

    if( !brands.includes( e.target.value ) ) {
      brands.push( e.target.value );
    } else {
      var index = brands.indexOf( e.target.value );
      brands.splice( index, 1 );
    }

    this.props.data.pmr_form.blocked_company_brand_ids = this._prepareCompanies( brands );
    this.props.data.lead.blocked_company_brand_ids = brands.join( '&' );
    this.props.updateState( this.props.data );
  }

  _handleVCCheckBox( e ) {
    var vc = [];

    let blockedVerifiedCompanyIds = this.props.data.lead.blocked_verified_company_ids;
    if( blockedVerifiedCompanyIds !== null && blockedVerifiedCompanyIds !== '' )
      vc = blockedVerifiedCompanyIds.split( '&' );

    if( !vc.includes( e.target.value ) ) {
      vc.push( e.target.value );
    } else {
      var index = vc.indexOf( e.target.value );
      vc.splice( index, 1 );
    }

    this.props.data.pmr_form.blocked_verified_company_ids = this._prepareCompanies( vc );
    this.props.data.lead.blocked_verified_company_ids = vc.join( '&' );
    this.props.updateState( this.props.data );
  }

  _prepareCompanies( companies ) {
    var obj = {};

    companies.forEach( function( id ) {
      obj[ id ] = '1';
    });

    return obj;
  }
}
