import Head from './email_history/Head';
import Body from './email_history/Body';

const EmailHistory = ({ emails }) => (
  <div id="recruit-email-history">
    <table className="table table-striped">
      <Head />
      <Body emails={ emails } />
    </table>
  </div>
);

export default EmailHistory;
