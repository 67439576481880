import React, { Component }  from 'react';

const days = new Map([
  ['Tomorrow', 1],
  ['1 Week', 7],
  ['15 Days', 15],
  ['30 Days', 30],
  ['45 Days', 45],
  ['2 Months', 60],
  ['3 Months', 90],
  ['4 Months', 120],
  ['5 Months', 150],
  ['6 Months', 180],
  ['1 Year', 365],
]);

const today = (new Date()).toISOString().slice(0, -8);

export default class Container extends Component {
  constructor(props) {
    super(props);

    this.taskTypes = this.props.task_types;
    this.state = this.initialState();

    this.loadNextLeadForDialer = this.loadNextLeadForDialer.bind(this);
  }

  initialState() {
    const {
      assignees,
      default_product_type,
      products,
      statuses,
    } = this.props;

    return {
      assignee: '',
      assignees: new Map(assignees),
      dateTime: '',
      disableTaskField: 'disabled',
      inDays: '',
      message: '',
      product: default_product_type || '',
      products: new Map(products),
      status: '',
      statuses: new Map(statuses),
    };
  }

  resetForm(event = undefined) {
    if (event) event.preventDefault();
    this.clearFormErrors();
    Util.clearFlash('.product-sales-form');
    this.setState(this.initialState);
  }

  submitForm(event) {
    event.preventDefault();

    this.clearFormErrors();
    Util.clearFlash('.product-sales-form');

    if (this.validForm()) {
      const form = document.querySelector('.product-sales-form form');

      this.toggleForm('disable');

      fetch(
        form.action,
        {
          method: form.method,
          credentials: 'same-origin',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sales: this.buildFormData() })
        }
      )
      .then(response => response.json())
      .then(response => {
        this.toggleForm('enable');

        if (response.success) {
          const { loggable_type } = this.props;

          this.resetForm();
          Util.createFlash(`Product sales status for ${loggable_type} successfully logged`, 'success', '.product-sales-form');

          // Dispatch custom event to update Product Sales History tab count
          document.dispatchEvent(new CustomEvent('productSales.EventSaved'));

          if (this.props.for_dialer) {
            this.loadNextLeadForDialer();
          }

        } else {
          throw new Error(response.errors.join(' '));
        }
      })
      .catch(error => {
        Util.createFlash(error, 'danger', '.product-sales-form');
      });
    }
  }

  noAnswer(e) {
    e.preventDefault();

    const target = e.currentTarget;

    target.disabled = true;

    this.toggleForm('disable');

    fetch(
      '/dialer/prime_eligible/no_answer',
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.buildFormData()),
      }
    )
      .then(response => response.json())
      .then(() => {
        target.disabled = false;
        this.toggleForm('enable');
        this.loadNextLeadForDialer();
      })
      .catch(error => {
        target.disabled = false;
        Util.createFlash(error, 'danger', '.product-sales-form');
      });
  }

  loadNextLeadForDialer() {
    document.getElementById('prime-eligible-agent-info').dispatchEvent(new Event('dialer:nextLead'));
  }

  buildFormData() {
      const {
        assignee,
        dateTime,
        message,
        product,
        status,
      } = this.state;

      const {
        loggable_id,
        loggable_type,
      } = this.props;

      return ({
        message: message,
        product_type: product,
        status: status,
        assign_to: assignee,
        follow_up_date: dateTime,
        loggable_id: loggable_id,
        loggable_type: loggable_type,
      });
  }

  validForm() {
    const valid = [];

    const {
      assignee,
      dateTime,
      product,
      status,
    } = this.state;

    const general = new Map([
      ['sales_product_type', product],
      ['sales_status', status],
    ]);

    const taskSpecific = new Map([
      ['sales_assign_to', assignee],
      ['sales_follow_up_date', dateTime],
    ]);

    const map = this.isTaskType(status) ? new Map([...general, ...taskSpecific]) : general;

    map.forEach((v, k) => {
      if (v == '') {
        document.getElementById(k).closest('.form-group').classList.add('has-error');
        valid.push(false);
      }
    });

    return !valid.includes(false);
  }

  clearFormErrors() {
    const inputs = document.querySelectorAll('.product-sales-form .has-error')

    inputs.forEach(input => input.classList.remove('has-error'));
  }

  isTaskType(status) {
    // passive_prospect is an oddball task that doesn't get assignee or follow-up
    return this.taskTypes.includes(status) && status !== 'passive_prospect';
  }

  toggleForm(operation) {
    const salesFormWrapper = document.querySelector('.product-sales-form');
    const action = /disable/.test(operation) ? 'add' : 'remove';

    salesFormWrapper.classList[action]('disabled');
  }

  handleStatusChange(event) {
    const { value } = event.target;

    this.setValue(event, 'status');

    if (this.isTaskType(value)) {
      this.setState({ disableTaskField: '' });
    } else {
      this.setState({
        disableTaskField: 'disabled',
        assignee: '',
        dateTime: '',
        inDays: '',
      });
    }
  }

  disableOption(enabledValue, value) {
    if (!enabledValue) {
      return;
    } else {
      return enabledValue != value;
    }
  }

  renderGroupedOptions(opts = {}) {
    const options = [];
    const {
      data,
      enabledValue,
      includeBlank,
    } = opts;

    if ('includeBlank' in opts) options.push(this.renderOptions({ data: [], enabledValue: enabledValue, includeBlank: includeBlank }));

    data.forEach((v, k) => {
      options.push(<optgroup key={ k } label={ k }>{ this.renderOptions({ data: new Map(v), enabledValue: enabledValue }) }</optgroup>);
    });

    return options;
  }

  renderOptions(opts = {}) {
    const options = [];
    const {
      data,
      enabledValue,
      includeBlank,
    } = opts;

    if ('includeBlank' in opts) options.push(<option key='blank' value='' disabled={ this.disableOption(enabledValue, '') }>{ includeBlank }</option>);

    data.forEach((k, v) => {
      options.push(<option key={ k } value={ k } disabled={ this.disableOption(enabledValue, k) }>{ v }</option>);
    });

    return options;
  }

  setValue(event, attribute) {
    const { value } = event.target;

    if (value.length) {
      this.setState({ [attribute]: value });
    } else {
      this.setState({ [attribute]: '' });
    }
  }

  setDate(event) {
    const { value, id } = event.target;

    if (/^sales_n_days$/.test(id) && value.length) {
      const date = new Date();

      date.setDate(date.getDate() + parseInt(value));
      date.setMinutes(0);
      date.setHours(6);

      this.setState({ dateTime: moment(date).format('YYYY-MM-DDTHH:mm') });
      this.setState({ inDays: value });
    } else if (/^sales_follow_up_date$/.test(id)) {
      this.setState({ dateTime: value });
      this.setState({ inDays: '' });
    } else {
      this.setState({ dateTime: '' });
      this.setState({ inDays: '' });
    }
  }

  render() {
    const {
      assignee,
      assignees,
      dateTime,
      disableTaskField,
      inDays,
      message,
      product,
      products,
      status,
      statuses,
    } = this.state;

    const {
      form_action,
      form_method,
      default_product_type,
      for_dialer,
    } = this.props;

    return (
      <div className='product-sales-form'>
        <div className='columns-wrapper'>
          <div className='column-1 width-100'>
            <div id='flash-container'></div>

            <form action={ form_action } method={ form_method }>
              <div className='row mb-15'>
                <div className='col-md-8 pull-right'>
                  <div className='form-group'>
                    <label htmlFor='sales_message'>Message</label>
                    <textarea name='sales[message]' id='sales_message' className='form-control message-container' value={ message } onChange={ e => this.setValue(e, 'message') } ></textarea>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='sales_product'>Product</label>
                    <select className='form-control' name='sales[product_type]' id='sales_product_type' value={ product } onChange={ e => this.setValue(e, 'product') } >
                      { this.renderOptions({ data: products, includeBlank: '-- Product --', enabledValue: default_product_type }) }
                    </select>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='sales_status'>Status</label>
                    <select className='form-control' name='sales[status]' id='sales_status' value={ status } onChange={ e => this.handleStatusChange(e) } >
                      { this.renderGroupedOptions({ data: statuses, includeBlank: '-- Status --' }) }
                    </select>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='sales_assign_to'>Assign To</label>
                    <select className='form-control'name='sales[assign_to]'id='sales_assign_to'value={ assignee } onChange={ e => this.setValue(e, 'assignee') } disabled={ disableTaskField } >
                      { this.renderOptions({ data: assignees, includeBlank: '-- Assign To --' }) }
                    </select>
                  </div>

                  <div className='row'>
                    <div className='form-group col-md-8'>
                      <label htmlFor='sales_follow_up_date'>Follow-up</label>
                      <input type='datetime-local'className='form-control'name='sales[follow_up_date]'id='sales_follow_up_date'value={ dateTime } min={ today } onChange={ e => this.setDate(e) } disabled={ disableTaskField } />
                    </div>

                    <div className='form-group col-md-4'>
                      <label htmlFor='sales_n_days'>In Days</label>
                      <select className='form-control'name='sales[n_days]'id='sales_n_days'value={ inDays } onChange={ e => this.setDate(e) } disabled={ disableTaskField } >
                        { this.renderOptions({ data: days, includeBlank: '' }) }
                      </select>
                    </div>
                  </div>
                  {
                    for_dialer
                      ? (
                        <>
                          <button
                            className='large-button'
                            disabled={!(status && product)}
                            onClick={e => this.submitForm(e)}
                          >
                            Submit
                          </button>
                          <button
                            className='large-button'
                            onClick={(e) => this.noAnswer(e)}
                          >
                            No Answer
                          </button>
                        </>
                      )
                      : (
                        <>
                          <button className = 'btn btn-md btn-primary mr-10' onClick = { e => this.submitForm(e) } >Submit</button>
                          <button className='btn btn-md btn-default' onClick={e => this.resetForm(e)} >Cancel</button>
                        </>
                      )
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
