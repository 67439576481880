import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlTelInput from 'intl-tel-input';
import IntlTelInputUtils from 'intl-tel-input/build/js/utils';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { store } from '../../../index';

import View from './view';

function invert(obj) {
  const new_obj = {};
  for (let prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      const value = obj[prop];
      new_obj[value] = prop;
    }
  }
  return new_obj;
};

function formatForError(string) {
  let str = string.toLowerCase().replace(/_/g, ' ');
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function getUnformattedValue(phone, country) {
  const ph = parsePhoneNumberFromString(phone, country);
  if (ph) {
    return ph.nationalNumber;
  }
  return phone;
}

function mapStateToProps(state) {
  return {
    valid: state.validations.get('phone_number'),
    focused: state.focused.get('phone_number'),
  };
};

class PhoneNumberComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      country: '',
      nationalNumber: '',
    };

    this.errorMap = null;
    this.input = null;

    this.handleChange = this.handleChange.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
  }

  componentDidMount() {
    const { property } = this.props;
    const phone_number = store.getState().info.get(property);

    let nationalNumber = phone_number;
    let country = null;

    if (phone_number) {
      const parsedPhoneNumber = parsePhoneNumberFromString(phone_number);
      if (parsedPhoneNumber) {
        country = parsedPhoneNumber.country || 'US';
        nationalNumber = parsedPhoneNumber.nationalNumber;
      }
    }

    this.setState({ country, nationalNumber });
    this.initIntlTelInput(country);
    this.errorMap = invert(intlTelInputUtils.validationError);
  }

  initIntlTelInput(country) {
    const { index, property } = this.props;
    const elementId = `info-input-${ property }-${ index || 0 }`;
    const input = document.getElementById(elementId);
    input.addEventListener('countrychange', this.countryChange.bind(this));
    this.input = IntlTelInput(input, {
      initialCountry: country,
      preferredCountries: ['US', 'CA'],
      utilsScript: IntlTelInputUtils,
    });
  }

  countryChange() {
    const countryData = this.input.getSelectedCountryData();
    const country = countryData.iso2.toUpperCase();
    this.setState(
      { country },
      () => {
        this.input.setCountry(country);
        this.updateStore();
      }
    );
  }

  updateStore() {
    this.setInfoField();
    this.setValidation(this.input.getValidationError());
  }

  setValidation(validation) {    
    const { dispatch, property } = this.props;
    const valid = this.errorMap[validation];

    let value = true;

    // workaround until
    // https://github.com/jackocnr/intl-tel-input/pull/989
    // is merged
    if (validation === 5) {
      value = 'Invalid Length';
    } else if (validation === 6) {
      value = 'Not A Number';
    } else if (validation > 0) {
      value = formatForError(valid);
    }

    dispatch({
      type: 'VALIDATE_PHONE_FIELD',
      data: {
        key: property,
        value,
      },
    });
  }

  setInfoField() {
    const { dispatch } = this.props;
    const { country, nationalNumber } = this.state;
    let value = '';

    const parsedNumber = parsePhoneNumberFromString(nationalNumber, country);
    if (parsedNumber) {
      value = parsedNumber.number;
    }

    dispatch({
      type: 'SET_INFO_FIELD',
      data: {
        key: 'phone_number',
        value,
      },
    });
  };

  handleChange(e) {
    const { value } = e.target;

    this.setState(
      { nationalNumber: value },
      () => {
        this.updateStore();
      }
    );
  }

  handleOnFocus(e) {
    const { name, value } = e.target;
    const { dispatch } = this.props;

    dispatch({
      type: 'FIELD_FOCUSED',
      data: {
        key: name,
      },
    });
  }

  render() {
    const {
      classNames,
      display,
      focused,
      property,
      parentClassNames,
      valid,
    } = this.props;

    const { nationalNumber } = this.state;

    return (
        <View
          classNames={ classNames }
          display={ display }
          focused={ focused }
          name={ property }
          parentClassNames={ parentClassNames }
          valid={ valid }
          value={ nationalNumber }

          onChange={ this.handleChange }
          onFocus={ this.handleOnFocus }
        />
    );
  }
}

export default connect(mapStateToProps)(PhoneNumberComponent);
