import { Component } from 'react';
import AgentLeadEdit  from './details/AgentLeadEdit';
import AgentLead      from './details/AgentLead';

export default class Details extends Component {
  constructor(props){
    super(props)
    this.state = {
      agent: props.agent,
      user: props.user,
      showSalesEditPage: false,
      showAgentEditPage: false,
      assignees: null
    }
    this.toggleSalesEditPage = this.toggleSalesEditPage.bind(this);
    this.toggleAgentEditPage = this.toggleAgentEditPage.bind(this);
  }

  componentDidMount(){
    Util.getAssignees()
      .then((results) => { this.setState({ assignees: results.assignees }) });
  }

  componentWillReceiveProps(nextProps){
    if(JSON.stringify(this.props) !== JSON.stringify(nextProps)){
      let { agent, user } = nextProps
      this.setState({ agent, user });
    }
  }

  toggleSalesEditPage(){
    this.setState({ showSalesEditPage: !this.state.showSalesEditPage })
  }

  toggleAgentEditPage(){
    this.setState({ showAgentEditPage: !this.state.showAgentEditPage })
  }

  renderBlock(header, content){
    return (
      <div className='cs-details-block'>
        <label>{ header }: </label>
        { content }
      </div>
    )
  }

  renderAgentEditPage(){
    if (this.state.showAgentEditPage) {
      let { agent, currentUser, questionResponses, obtainAgentInfo, agentCompany, user, agentFeatures, timeZone, afssId } = this.props;
      return (
        <AgentLeadEdit
          user={user}
          afssId={afssId}
          timeZone={timeZone}
          currentUser={currentUser}
          currentUserRoles={this.props.currentUserRoles}
          toggleAgentEditPage={this.toggleAgentEditPage}
          questionResponses={questionResponses}
          obtainAgentInfo={obtainAgentInfo}
          agent={agent}
          assignees={this.state.assignees}
          welcomeCallTask={this.props.welcomeCallTask}
          assignees={this.state.assignees}
          agentCompany={agentCompany}
          agentFeatures={agentFeatures}
        />
      );
    };
  }

  render(){
    let salesTab;
    let { agent } = this.state;
    let { photo, questionResponses, agentCompany, agentMetrics, user, currentUser, agentFeatures, timeZone, welcomeCallTask } = this.props;
    if(agent === null) return <div />;

    return(
      <div className='cs-details-panel'>
        { this.renderAgentEditPage() }
        <div className='cs-tab-header'>
          <div>
            <input
              className='tab selected'
              type='button'
              value='Agent Info'
            />
          </div>
          <div>
            <button onClick={() => ReferralExchange.copyCrmLoginLinkToClipboard('agent', '/profile', agent.id)}>Agent CRM URL</button>
            <a href={`../agents/${agent.id}`} target='_blank'>Agent Backend</a>
          </div>
        </div>
        <div>
          <AgentLead
            agent={agent}
            user={user}
            photo={photo}
            timeZone={timeZone}
            agentFeatures={agentFeatures}
            agentCompany={agentCompany}
            agentMetrics={agentMetrics}
            welcomeCallTask={welcomeCallTask || {}}
            questionResponses={questionResponses}
            toggleAgentEditPage={this.toggleAgentEditPage}
          />
        </div>
      </div>
    )
  }
}
