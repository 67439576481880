import React from 'react';

const dateFormat = 'MM/DD/YYYY hh:mm A';

const Body = ({ fetching, profileReviews, quarterRoundSplit }) => {
  const overlayKlass = fetching ? 'overlay' : '';

  const cityStateZip = ({ city, state, zip }) => {
    const text = [city];

    if (city && state) text.push(', ');

    text.push(state, ' ', zip);

    return text.join('');
  };

  const buildReviewer = client => {
    const {
      name,
      email,
      address,
    } = client;

    const emailAttrs = [email, <br/>];
    const addressAttrs = [address, <br/>];

    return (
      <td>
        <strong>{ name }</strong><br/>
        { email && emailAttrs }
        { address && addressAttrs }
        { cityStateZip(client) }
      </td>
    );
  };

  const buildReviewedOn = ({ created_at, agent_curated, published }) => {
    const agentCurated = <span className="status-pill status-info">Agent Curated</span>;

    return (
      <td>
        { moment(created_at).format(dateFormat) }<br/>
        { agent_curated && agentCurated }
        {
          published && (
            <>
             <br />
              <span className="status-pill status-info">Published</span>
            </>
          )
        }
      </td>
    );
  };

  const buildTransaction = client => {
    const {
      type,
      close_date,
      close_price,
    } = client;

    const text = /Seller/i.test(type) ? 'Sell a home' : 'Buy a home';
    const textAttrs = [text, <br/>]
    const closeDateAttrs = [moment(close_date).format(dateFormat), <br/>];

    return (
      <td>
        <strong>{ type }</strong><br/>
        { type && textAttrs }
        { close_date && closeDateAttrs }
        { close_price }
      </td>
    );
  };

  const buildReview = ({ rating, comment }) => {
    const [int, fract] = quarterRoundSplit(rating);
    const ratingKlass = `rated-${int}-${fract || 0}`;
    const ratingText = (rating / 1.0).toFixed(2).replace(/0$/, '');

    return (
      <td>
        <span className={ `profile-review-rating rating-sm ${ratingKlass} mr-5` }></span>{ ratingText }<br/>
        { comment }
      </td>
    );
  };

  const buildValidated = ({ validated, moderated_by, moderated_at }) => {
    if (!moderated_at) return(<td></td>);

    const pillKlass = validated ? 'status-info' : 'status-danger';
    const pillText = validated ? 'Valid' : 'Invalid';

    return (
      <td>
        <span id="validation-status" className={ `status-pill ${pillKlass}` }>{ pillText }</span><br/>
        { moderated_by }<br/>
        { moment(moderated_at).format(dateFormat) }
      </td>
    );
  };

  const handleReportClick = event => {
    const { dataset, classList } = event.currentTarget;

    if (!classList.contains('link')) return;

    document.body.dispatchEvent(new CustomEvent('report_review.modal_open', { detail: dataset.reviewId }));
  };

  const buildReported = ({ id, reported, reported_by, reported_at }) => {
    const pillKlass = reported ? 'status-danger' : 'link';
    const pillText = reported ? 'Reported' : 'Report';
    const reportedByAttrs = [reported_by, <br/>];

    return (
      <td>
        <span id="reported-status" className={ `status-pill ${pillKlass}` } data-review-id={ id } onClick={ handleReportClick }>{ pillText }</span><br/>
        { reported_by && reportedByAttrs }
        { reported_at && moment(reported_at).format(dateFormat) }
      </td>
    );
  };

  const buildData = profileReview => {
    const data = [];
    const { client, review } = profileReview;

    data.push(buildReviewer(client));
    data.push(buildReviewedOn(review));
    data.push(buildTransaction(client));
    data.push(buildReview(review));
    data.push(buildValidated(review));
    data.push(buildReported(review));

    return data;
  };

  const buildRows = () => {
    const rows = [];

    profileReviews.forEach(profileReview => {
      rows.push(<tr>{ buildData(profileReview) }</tr>);
    });

    return rows;
  };

  return (
    <tbody className={ overlayKlass }>
      { buildRows() }
    </tbody>
  )
};

export default Body;
