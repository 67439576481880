const toggleTab = event => {
  const { currentTarget } = event;
  const { tab } = currentTarget.dataset;
  const tabs = document.querySelectorAll('.section-tab');
  const sectionContents = document.querySelectorAll('.section-content');
  const activeContent = document.getElementById(tab);

  tabs.forEach(tab => tab.classList.remove('active'));
  sectionContents.forEach(sectionContent => sectionContent.classList.add('hidden'));

  currentTarget.classList.add('active');
  activeContent.classList.remove('hidden');
};

const Script = (props) => {
  const { agent, lead } = props;
  const purchaseSell = /Buyer/.test(lead.type) ? 'purchase' : 'sell';

  return (
    <div className="floating-pane mb-30" id="script-container">
      <div className="section-headers">
        <ul>
          <li data-tab="introduction" className="section-tab active" onClick={ toggleTab }><i className="fa fa-bars script-icon"></i> Introduction</li>
          <li data-tab="questions" className="section-tab" onClick={ toggleTab }><i className="fa fa-question script-icon"></i> Questions</li>
          <li data-tab="closing" className="section-tab" onClick={ toggleTab }><i className="fa fa-heart script-icon"></i> Closing</li>
        </ul>
      </div>

      <div id="introduction" className="section-content">
        <p>Hi { agent.name }!</p>
        <p>I'm calling on behalf of { lead.microsite_name }. We have a request from a { lead.type } to { purchaseSell } a property in { lead.location }.</p>
      </div>

      <div id="questions" className="section-content hidden">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab error saepe sunt, id minus, facere officia non, dolor inventore odit totam?</p>
        <p>Eligendi eaque dolorum cupiditate explicabo libero maiores. Officia doloremque nam earum, molestiae sed?</p>
        <p>Praesentium blanditiis repellat fugiat asperiores illo veritatis, corrupti corporis facilis nemo possimus, est velit ducimus. Officiis maxime reiciendis, dolores deleniti tenetur iusto rerum ut cum dolor illum iste magnam quibusdam corrupti animi quam doloribus molestias. Ipsum cupiditate debitis, praesentium quaerat ullam, voluptate ut vel corrupti magni qui iure vero, nostrum, at. Sunt suscipit neque repudiandae eius fugit omnis, delectus cupiditate sint maiores, aspernatur quo aliquid ullam, eligendi nisi minus officiis adipisci voluptates voluptatibus ad consectetur ducimus. Velit deleniti in consequatur, doloribus. Libero, officiis, deleniti! Eos quisquam iure laboriosam, distinctio vitae rem, provident sint id, dolore, sequi magni dicta. Odio vitae minima, exercitationem, debitis dicta in obcaecati nihil dolores quisquam, labore quos temporibus accusamus necessitatibus. Accusantium, pariatur?</p>
      </div>

      <div id="closing" className="section-content hidden">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab error saepe sunt, id minus, facere officia non, dolor inventore odit totam.</p>
        <p>Labore quos temporibus accusamus necessitatibus. Accusantium, pariatur. Praesentium blanditiis repellat fugiat asperiores illo veritatis, corrupti corporis facilis nemo possimus, est velit ducimus. Officiis maxime reiciendis, dolores deleniti tenetur iusto rerum ut cum dolor illum iste magnam quibusdam corrupti animi quam doloribus molestias. Ipsum cupiditate debitis, praesentium quaerat ullam, voluptate ut vel corrupti magni qui iure vero, nostrum, at. Sunt suscipit neque repudiandae eius fugit omnis, delectus cupiditate sint maiores, aspernatur quo aliquid ullam, eligendi nisi minus officiis adipisci voluptates voluptatibus ad consectetur ducimus. Velit deleniti in consequatur, doloribus. Libero, officiis, deleniti! Eos quisquam iure laboriosam, distinctio vitae rem, provident sint id, dolore, sequi magni dicta. Odio vitae minima, exercitationem, debitis dicta in obcaecati nihil dolores quisquam?</p>
        <p>Eligendi eaque dolorum cupiditate explicabo libero maiores. Officia doloremque nam earum, molestiae sed.</p>
      </div>
    </div>
  )
};

export default Script;
