const Header = (props) => {
  const { header } = props;

  const {
    klass,
    message,
  } = header;

  function headerIcon() {
    switch (klass) {
      case 'success':
        return <i className='fa fa-check-circle mr-5'></i>
      case 'danger':
        return <i className='fa fa-times-circle mr-5'></i>
      case 'warning':
      case 'info':
        return <i className='fa fa-exclamation-triangle mr-5'></i>
      default:
        return undefined;
    }
  }

  return (
    <div className={ `modal-header ${klass}` }>
      <button type='button' className='close' data-dismiss='modal'>&times;</button>
      <h3 className='modal-title'>{ headerIcon() }{ message }</h3>
    </div>
  );
};

export default Header;
