import { Component } from 'react';
import MatchedAgents from './change_winning_agent/MatchedAgents';
import ErrorWrapper from '../ErrorWrapper';

export default class ChangeWinningAgent extends Component {
  constructor() {
    super();

    this.state = {
      matchedResults: null,
      reset: false,
      searchDisabled: true
    };

    this.fetchMatches = this.fetchMatches.bind(this);
    this.toggleSearchButton = this.toggleSearchButton.bind(this);
  }

  componentDidMount() {
    const leadIdInput = document.querySelector('form .lead-id');

    leadIdInput.addEventListener('keyup', this.toggleSearchButton);
  }

  fetchMatches(event) {
    event.preventDefault();
    this.setState({ matchedResults: null });
    Util.clearFlash();

    const leadID = document.querySelector('.lead-id').value;
    const form = event.target;

    fetch(
      `${form.action}${leadID}`,
      {
        method: form.method,
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' }
      }
    )
    .then(response => {
      if (response.ok) {
        return response.json();
      }

      throw new Error('No results found for Lead ID.');
    })
    .then(parsedJson => this.showResults(parsedJson))
    .then(() => {
      this.clearSearch();
      this.toggleSearchButton();
    })
    .catch(error => this.toggleErrors(error));
  }

  showResults(data) {
    const matchedAgentResults = <MatchedAgents data={data} />

    this.setState({ matchedResults: matchedAgentResults });
  }

  toggleSearchButton() {
    const searchButton = document.querySelector('form .search-button');
    const leadIdInput = document.querySelector('form .lead-id');

    // clear any errors
    this.toggleErrors();

    if (leadIdInput.value) {
      searchButton.classList.remove('disabled');
      this.setState({ searchDisabled: false });
    } else {
      searchButton.classList.add('disabled');
      this.setState({ searchDisabled: true });
    }
  }

  clearSearch() {
    const leadIdInput = document.querySelector('form .lead-id');

    leadIdInput.value = null;
  }

  toggleErrors(error = {}) {
    const errorMsg = document.querySelector('.change-winning-agent .error-msg');

    if ('message' in error) {
      errorMsg.innerHTML = error.message;
      errorMsg.closest('.form-group').classList.add('has-error');
      errorMsg.classList.remove('hidden');
    } else {
      errorMsg.innerHTML = null;
      errorMsg.closest('.form-group').classList.remove('has-error');
      errorMsg.classList.add('hidden');
    }
  }

  render() {
    const { close } = this.props;

    return (
      <div className='dashboard-tool'>
        <div className='change-winning-agent'>
          <form action='/product_dashboard/match/search/' method='get' className='match-search' onSubmit={this.fetchMatches}>
            <div className='form-group mb-0'>
              <div className='col-md-6 input-group input-group-lg'>
                <input type='number' className='form-control lead-id' placeholder='Lead ID' />
                <span className='input-group-btn'>
                  <button className='btn btn-md btn-rex btn-orange search-button disabled' type='submit' disabled={this.state.searchDisabled}>SEARCH</button>
                </span>
              </div>
              <div className='error-msg hidden'></div>
            </div>
          </form>

          <div className='row matched-results'>
            <div className='col-md-12'>
              {this.state.matchedResults}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
