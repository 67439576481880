import React, { useState, useEffect } from 'react';

import CheckboxField from './../../../_common/CheckboxField.js';

const AGENT_ATTRS = [
  'id',
  'name',
  'email',
  'phone_mobile',
  'company_city',
  'company_state',
];

const Results = props => {
  const {
    results,
    fetching,
    updateFieldHandler,
  } = props;

  const [checkAll, toggleCheckAll] = useState(false);
  const [checkText, setCheckText] = useState('+ Check All');

  useEffect(() => {
    const text = checkAll ? '- Uncheck All' : '+ Check All';

    toggleCheckboxes();
    setCheckText(text);
  }, [checkAll]);

  useEffect(() => toggleCheckAll(false), [results]);

  const toggleCheckboxes = () => {
    const checkboxes = document.querySelectorAll('#exclusive-agent-results input[type="checkbox"]');

    [...checkboxes].filter(checkbox => !checkbox.disabled && checkbox.checked != checkAll).forEach(checkbox => {
      setTimeout(() => checkbox.click(), 100);
    });
  };

  const handleOnClick = event => {
    const table = event.target.closest('table');
    const inputs = table.querySelectorAll('input[type="checkbox"]');
    const checked = [...inputs].filter(i => !i.disabled && i.checked).length;

    if (checked === 0) toggleCheckAll(false);
    if (checked === 2) toggleCheckAll(true);
  };

  const buildRow = result => {
    const row = [];

    row.push(<td key="force_agent_ids"><CheckboxField fieldType="array" fieldName="force_agent_ids" required={ false } updateFieldHandler={ updateFieldHandler } fieldValue={ result.id } fieldChecked={ true } fieldDisabled={ true } /></td>);

    AGENT_ATTRS.forEach(key => {
      row.push(<td key={ result[key] }>{ result[key] }</td>);
    });

    return row;
  };

  const buildRows = () => {
    const rows = [];

    results.forEach(result => {
      rows.push(<tr key={ result.id }>{ buildRow(result) }</tr>);

      rows.push(
        <tr key="working_with_agent_ids">
          <td><CheckboxField fieldType="array" fieldName="working_with_agent_ids" required={ false } updateFieldHandler={ updateFieldHandler } handleOnClick={ handleOnClick } fieldValue={ result.id } /></td>
          <td colSpan="6">The client is currently working with { result.name }</td>
        </tr>
      );

      rows.push(
        <tr key="force_match_exclusively">
          <td><CheckboxField fieldType="bool" fieldName="force_match_exclusively" required={ false } updateFieldHandler={ updateFieldHandler } handleOnClick={ handleOnClick } fieldValue={ false } /></td>
          <td colSpan="6">The client is interested in being contacted by other agents</td>
        </tr>
      );
    });

    return rows;
  };

  const renderView = () => {
    switch (fetching) {
    case 'fetch':
      return (
        <div className="input-row center flex-init">
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      );

      break;
    case 'no-results':
      return (
        <div className="input-row center flex-init">
          <strong>No Results</strong>
        </div>
      );

      break;
    case 'results':
      return (
        <div className="input-row">
          <table id="exclusive-agent-results" className="table table-striped">
            <thead>
              <tr>
                <th className="check-all" onClick={ () => toggleCheckAll(!checkAll) }>{ checkText }</th>
                <th>Agent ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>City</th>
                <th>State</th>
              </tr>
            </thead>
            <tbody>
              { buildRows() }
            </tbody>
          </table>
        </div>
      );

      break;
    default:
      return undefined;
    }
  };

  return (
    <div className="section">
      { renderView() }
    </div>
  );
};

export default Results;
