import React from 'react';
import moment from 'moment';

import Context from '../Context';
import TextareaField from '../../FormBuilder/TextareaField';
import DisplayBlock from '../shared/DisplayBlock';

import { formatCurrency } from '../../util/textMasks';

const LinkButton = ({ title, url }) => (
  <a
    href={url}
    target="_blank"
    rel="nofollow"
    className="link-button"
  >
    <i className="search" />
    {title}
  </a>
);

const Summary = () => (
  <Context.Consumer>
    {(props) => {
      const {
        brokerageName,
        selections: {
          foundAgent,
          foundSale,
          foundAgentMatches,
          foundAgentName,
          foundAgentNameFreeText,
          foundListing,
          foundBrokerage,
          foundBrokerageName,
          foundOtherBrokerage,
          listingDetailsComplete,
          source,
        },
        setSelections,
        submitReview,
        transaction: {
          address_house_number,
          address_street_name,
          address_unit,
          address_city,
          address_state,
          address_zip,
          agent_name,
          transaction_date,
          sale_price,
          lead_type,
          zillow_link,
          realtor_link,
          redfin_link,
          xome_link,
          movoto_link,
          google_link,
        },
      } = props;

      const leadType = (lead_type === 'seller') ? "seller's" : "buyer's";

      function isSubmitEnabled() {
        return listingDetailsComplete
          && (
            (foundListing === false && foundSale === false)
            || (foundBrokerage === 'yes')
            || (foundBrokerage === 'unsure')
            || (foundBrokerage === 'no' && foundBrokerageName.length > 0)
            || (foundOtherBrokerage === false)
            || (foundOtherBrokerage === true && foundBrokerageName.length > 0)
          );
      }

      function handleChange(e) {
        const { value } = e.currentTarget;

        setSelections((prevState) => ({
          ...prevState,
          source: value,
        }));
      }

      function agentName() {
        if (foundAgent) {
          if (foundAgentMatches) {
            return agent_name.length ? agent_name : '?';
          } else if (foundAgentName) {
            return foundAgentName;
          } else if (foundAgentNameFreeText) {
            return foundAgentNameFreeText;
          }
        }

        return '?';
      }

      function salePrice() {
        return (foundSale && sale_price) ? formatCurrency(sale_price) : '?';
      }

      const address = (
        <>
          <p>{[address_house_number, address_street_name, address_unit].join(' ')}</p>
          <p>{`${address_city}, ${address_state} ${address_zip}`}</p>
        </>
      );

      return (
        <div id="summary-container">
          <div className="form-builder stage-final-submit">
            <div className="button-container search">
              <LinkButton
                title="Zillow"
                url={zillow_link}
              />
              <LinkButton
                title="Realtor"
                url={realtor_link}
              />
              <LinkButton
                title="Redfin"
                url={redfin_link}
              />
              <LinkButton
                title="Google"
                url={google_link}
              />
              <LinkButton
                title="Movoto"
                url={movoto_link}
              />
              <LinkButton
                title="Xome"
                url={xome_link}
              />
            </div>
            <DisplayBlock
              title="ADDRESS"
              value={address}
            />
            <DisplayBlock
              title="SALE DATE"
              value={moment(transaction_date).format('LL')}
            />
            <DisplayBlock
              title="SALE PRICE"
              value={salePrice()}
            />
            <DisplayBlock
              title={`${leadType.toUpperCase()} AGENT`}
              value={agentName()}
            />
            <DisplayBlock
              title={`${leadType.toUpperCase()} BROKERAGE`}
              value={brokerageName() || '?'}
            />
            <TextareaField
              id="source"
              placeholder="Provide your sources here (one URL per line)"
              value={source}
              klass="full"
              handleChange={handleChange}
            />
            <div className="button-container">
              <button
                type="submit"
                onClick={submitReview}
                disabled={!isSubmitEnabled()}
              >
                Submit Completed Research
              </button>
            </div>
          </div>
        </div>
      )
    }}
  </Context.Consumer>
);

export default Summary;
