import React from 'react';

const perPageOptions = [5, 10, 25, 50, 100];

const pagination = (current, total) => {
  if (total === 1 || total === 0) return [1];

  const center = [current - 2, current - 1, current, current + 1, current + 2];
  const filteredCenter = center.filter(p => p > 1 && p < total);
  const includeThreeLeft = current === 5;
  const includeThreeRight = current === total - 4;
  const includeLeftDots = current > 5;
  const includeRightDots = current < total - 4;

  if (includeThreeLeft) filteredCenter.unshift(2)
  if (includeThreeRight) filteredCenter.push(total - 1)

  if (includeLeftDots) filteredCenter.unshift('...');
  if (includeRightDots) filteredCenter.push('...');

  return [1, ...filteredCenter, total];
};

const filterOptions = [
  'Published',
  'Agent Curated',
  'Awaiting Moderation',
  'Reported',
  'Low Rated',
  'Medium Rated',
  'High Rated',
];

const Pagination = props => {
  const {
    totalFilteredReviews,
    page,
    perPage,
    filter,
    setAttribute,
    fetchReviews,
  } = props;

  const totalPages = Math.ceil(totalFilteredReviews / perPage);

  const handlePageLinkClick = event => {
    event.preventDefault();

    const { innerText, classList } = event.currentTarget;

    if (classList.contains('disabled')) return;

    if (classList.contains('next-page')) {
      const newPage = classList.contains('increment') ? page + 1 : page - 1;

      setAttribute('page', newPage, fetchReviews);
    } else {
      const value = parseInt(innerText);

      setAttribute('page', value, fetchReviews);
    }
  };

  const buildPaginationLinks = () => {
    const pages = pagination(page, totalPages);
    const decrDisabled = page === 1 ? 'disabled' : '';
    const incrDisabled = page === totalPages ? 'disabled' : '';

    const selectClassList = (p) => {
      const classList = ['paginate-link'];

      if (page === p) {
        classList.push('selected disabled');
      }

      return classList.join(' ');
    };

    return (
      <>
        <a
          href="#"
          className={`paginate-link next-page decrement ${decrDisabled}`}
          onClick={handlePageLinkClick}
        >
          <i className="fa fa-angle-left" />
        </a>
        {
          pages.map((p, key) => (
            isNaN(p)
              ? <span key={key}>&hellip;</span>
              : (
                <a
                  key={key}
                  href="#"
                  className={selectClassList(p)}
                  onClick={handlePageLinkClick}
                >
                  {p}
                </a>
              )
          ))
        }
        <a
          href="#"
          className={`paginate-link next-page increment ${incrDisabled}`}
          onClick={handlePageLinkClick}
        >
          <i className="fa fa-angle-right" />
        </a>
      </>
    )
  };

  const handlePerPageChange = event => {
    const { value } = event.currentTarget;

    setAttribute('page', 1);
    setAttribute('perPage', parseInt(value), fetchReviews);
  };

  const handleFilterChange = (e) => {
    const { value } = e.currentTarget;

    setAttribute('page', 1);
    setAttribute('filter', value, fetchReviews);
  }

  const buildPerPage = () => {
    const opts = [];

    perPageOptions.forEach(i => {
      opts.push(<option key={ i } value={ i }>{ i }</option>);
    });

    return opts;
  };

  const buildFilterOptions = () => {
    const opts = [];

    opts.push(<option key="" value="">-- Filter --</option>);

    filterOptions.forEach((filter, key) => {
      opts.push(<option key={ key } value={ filter }>{ filter }</option>);
    });

    return opts;
  };

  return (
    <>
      <select onChange={ handleFilterChange } value={ filter }>
        { buildFilterOptions() }
      </select>

      <select id="reviews-to-display" className="ml-10" onChange={ handlePerPageChange } value={ perPage }>
        { buildPerPage() }
      </select>

      <span className="paginate-container">
        { buildPaginationLinks() }
      </span>
    </>
  )
};

export default Pagination;
