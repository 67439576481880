import Header from './Header';
import Body from './Body';

const Reviews = ({ fetching, profileReviews, quarterRoundSplit }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <table className="table table-striped profile-reviews-table">
          <Header
            quarterRoundSplit={ quarterRoundSplit }
          />

          <Body
            fetching={ fetching }
            profileReviews={ profileReviews }
            quarterRoundSplit={ quarterRoundSplit }
          />
        </table>
      </div>
    </div>
  )
};

export default Reviews;
