import { classList } from '../utils';

const StandardInput = (props) => {
  const {
    classNames,
    display,
    focused,
    id,
    index,
    name,
    valid,
    value,

    onChange,
    onFocus,
  } = props;

  const classes = classList({
    classNames,
    def: 'info-field',
    focused,
    valid
  });

  const error = typeof valid === 'string' ? valid : false;

  return (
    <div className={` ${ classes }`}>
      <label htmlFor={ name }> { display }</label>
      <input
        name={ name }
        value={ value || '' }
        onChange={ onChange }
        id={ id ||  `info-input-${ name }-${ index || 0 }`}
        data-index={ index || 0 }
        onFocus={ onFocus }
      />
      {
        error &&
        <span className='info-field-error'>{ error }</span>
      }

    </div>
  );
};

export default StandardInput;
