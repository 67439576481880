import { Component } from 'react';

import Billing        from './tabs/Billing';
import Feed           from './tabs/Feed';
import Invite         from './tabs/Invite';
import Wishlist       from './tabs/Wishlist';
import Notes          from './tabs/Notes';
import WorkingList    from './tabs/WorkingList';

export default class Tabs extends Component {
  constructor(props){
    super(props)
    this.tabContainerClass = this.tabContainerClass.bind(this);
    this.tabClass = this.tabClass.bind(this);
    this.updateTab = this.updateTab.bind(this);
  }

  tabContainerClass(tab){
    return this.props.state.tab === tab
      ? 'tab-container ' + tab
      : 'hidden';
  }

  tabClass(tab) {
    return this.props.state.tab === tab
      ? `tm-tab ${tab} active`
      : `tm-tab ${tab}`;
  }

  updateTab(e){
    this.props.updateState({ tab: e.currentTarget.classList[1] });
  }

  render(){
    let { state, updateState, submitInviteEmail, removeWish, fetchFeed, submitUpdatedSalesManager, billingSynced, searchWishList } = this.props;

    let billingTab;
    let billingTabHeader;

    if (!state.salesManager.parent_id) {
      billingTab = (
        <Billing
          state={state}
          updateState={updateState}
          klass={this.tabContainerClass('billing')}
          submitUpdatedSalesManager={submitUpdatedSalesManager}
          billingSynced={billingSynced}
        />
      );
      billingTabHeader = <div className={this.tabClass('billing')} onClick={this.updateTab}>Billing</div>;
    }

    return(
      <div className='monitor-tools'>
        <div className='tab-header'>
          <div className={this.tabClass('working-list')} onClick={this.updateTab}>Working List</div>
          <div className={this.tabClass('notes')} onClick={this.updateTab}>Notes</div>
          <div className={this.tabClass('feed')} onClick={this.updateTab}>Feed</div>
          <div className={this.tabClass('wish-list')} onClick={this.updateTab}>Agent<br/>Suggestions</div>
          <div className={this.tabClass('invite')} onClick={this.updateTab}>Invite</div>
          {billingTabHeader}
        </div>
        <Notes
          state={state}
          updateState={updateState}
          klass={this.tabContainerClass('notes')}
        />
        <Feed
          state={state}
          updateState={updateState}
          klass={this.tabContainerClass('feed')}
          fetchFeed={fetchFeed}
        />
        <Wishlist
          state={state}
          updateState={updateState}
          klass={this.tabContainerClass('wish-list')}
          removeWish={removeWish}
          searchWishList={searchWishList}
        />
        <Invite
          state={state}
          updateState={updateState}
          klass={this.tabContainerClass('invite')}
          submitInviteEmail={submitInviteEmail}
        />
        <WorkingList
          state={state}
          updateState={updateState}
          klass={this.tabContainerClass('working-list')}
        />
        {billingTab}
      </div>
    )
  }
}
