import { Component } from 'react';
import DateTimeSelector from '../CustomerSuccess/DateTimeSelector';

export default class DialerDispo extends Component {
  constructor(props){
    super(props);
    this.state = {
      setupCallLaterCalendar: false,
      setupFollowUpCalendar: false,
      submittingDispoe: false,
      showDateSelector: false,
      note: '',
      callLaterFollowUp: '',
      scheduleApptFollowUp: '',
      followUpApptFollowUp: '',
      scheduleNowSelected: false,
      transition: 'none',
      callLaterFollowUpError: false,
      scheduleApptFollowUpError: false
    }

    this.requestFormURL = 'https://docs.google.com/forms/d/e/1FAIpQLScn5f4Hf6h_Bzre59DGb0ZNt-MOA8SDKj64I8fvJESfXHx2yA/viewform'
    this.handleDispoe = this.handleDispoe.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateNumbericDate = this.updateNumbericDate.bind(this);
    this.changeFollowUp = this.changeFollowUp.bind(this);
    this.toggleDateSelector = this.toggleDateSelector.bind(this);
    this.transitionDispoe = this.transitionDispoe.bind(this);
  }

  dateSelector(){
    let { showDateSelector, welcomeTeamSchedule } = this.state;
    if(showDateSelector){
      return(
        <DateTimeSelector
          toggleDateSelector={this.toggleDateSelector}
          changeFollowUp={this.changeFollowUp}
          currentUserId={this.props.currentUser.id}
          timeZone={this.props.timeZone}
        />
      )
    }
  }

  changeFollowUp(scheduleApptFollowUp){
    if(scheduleApptFollowUp === undefined) return;
    this.setState({ scheduleApptFollowUp });
  }

  toggleDateSelector(){
    this.setState({ showDateSelector: !this.state.showDateSelector })
  }

  resetState(){
    this.setState({
      note: '',
      callLaterFollowUp: '',
      scheduleApptFollowUp: '',
      followUpApptFollowUp: '',
      scheduleNowSelected: false,
      transition: 'none',
      followUpError: false,
      callLaterFollowUpError: false,
      scheduleApptFollowUpError: false
    })

    $('#call_date_setter').val('');
    $('#call_later_days').val('');
  }

  transitionDispoe(dispoe){
    this.setState({ transition: dispoe })
  }

  containsErrors(option){
    let {
      callLaterFollowUp,
      scheduleApptFollowUp,
      scheduleNowSelected
    } = this.state;

    switch(option){
      case 'Call Later':
        if(!callLaterFollowUp){
          this.setState({ callLaterFollowUpError: true });
          return true;
        }
        break;
      case 'Schedule Appointment':
        if(!scheduleApptFollowUp){
          this.setState({ scheduleApptFollowUpError: true });
          return true;
        }
        break;
      case 'Live Transfer':
        if(!scheduleNowSelected){
          this.setState({ scheduleApptFollowUpError: true });
          return true;
        }
        break;
      default:
        this.setState({
          callLaterFollowUpError: false,
          scheduleApptFollowUpError: false
        });
        return false;
    }
  }

  handleDispoe(option){
    if(this.containsErrors(option)) return;
    let { task, agentId, currentUser, timeZone, queue, fetchAgentFromQueue, pauseDialer, setSubmitting, redirectDialer } = this.props;
    let {
      callLaterFollowUp, note,
      followUpApptFollowUp, scheduleApptFollowUp,
    } = this.state;
    let submitDisposition;
    setSubmitting(true);
    let updateParams = {
      completed_task: { task_id: task.id },
      new_task: { taskable_id: agentId },
      note: { noteable_id: agentId }
    }
    switch(option){
      case 'No Answer':
        updateParams.note.message = 'Reschedule call set for next day. ' + note;
        submitDisposition = Util.welcomeCallNoAnswer;
        break;
      case 'Not Interested':
        // need to talk to brad about this
        // updateParams.new_task.task_type = task.task_type;
        // updateParams.note.message = note;
        // updateParams.note.status = 'Not Interested';
        // break;
      case 'Refund Requested':
        updateParams.note.message = note;
        submitDisposition = Util.welcomeCallRefundRequested;
        pauseDialer();
        redirectDialer();
        window.open(this.requestFormURL);
        break;
      case 'Call Later':
        updateParams.new_task.follow_up = Util.parseDateTime(callLaterFollowUp);
        updateParams.note.message = `Call was rescheduled for a later date ${callLaterFollowUp}. ${note}`;
        submitDisposition = Util.welcomeCallCallLater;
        break;
      case 'Schedule Appointment':
        updateParams.new_task.assigned_to = currentUser.id;
        updateParams.new_task.follow_up = Util.parseDateTime(scheduleApptFollowUp, -1 * (timeZone.offset + 8))
        updateParams.note.message = `Appointment was scheduled for ${scheduleApptFollowUp}. ${note}`;
        submitDisposition = Util.welcomeCallAppointmentScheduled;
        break;
      case 'Live Transfer':
        updateParams.new_task.assigned_to = currentUser.id;
        updateParams.new_task.follow_up = this.closestHalfHour();
        updateParams.note.message = `Appointment was live transferred. ${note}`;
        submitDisposition = Util.welcomeCallAppointmentScheduled;
        pauseDialer();
        this.loadCustomerSuccessTool();
        break;
    }

    this.setState({ submittingDispoe: true })
    submitDisposition(updateParams)
      .then(() => fetchAgentFromQueue())
      .then(() => this.resetState())
      .then(() => this.setState({ submittingDispoe: false }))
  }

  loadCustomerSuccessTool(){
    let win = window.open(`/customer_success/${this.props.agentId}`, '_blank');
    win.focus();
  }

  updateField(field, e){
    this.setState({ [field]: e.currentTarget.value })
  }

  updateNumbericDate(field, value){
    this.setState({ [field]: value })
  }

  closestHalfHour(){
    let timeNow = new Date;
    let minutes = timeNow.getMinutes();
    if(minutes < 30){
      timeNow.setMinutes(30)
    } else {
      timeNow.setHours(timeNow.getHours() + 1, 0, 0)
    }
    return Util.parseForSQL(timeNow)
  }

  callLaterSelector(){
    return(
      <select id='call_later_days' onChange={(e) => this.updateNumbericDate('callLaterFollowUp', Util.numberToDateTime(e))} >
        <option value=''>Days</option>
        <option value='1'>1</option>
        <option value='7'>7</option>
        <option value='15'>15</option>
        <option value='30'>30</option>
        <option value='45'>45</option>
      </select>
    )
  }

  followUpSelector(){
    return(
      <select id='follow_up_later_days' onChange={(e) => this.updateNumbericDate('followUpApptFollowUp', Util.numberToDateTime(e))} >
        <option value=''></option>
        <option value='1'>1</option>
        <option value='2'>2</option>
        <option value='3'>3</option>
        <option value='4'>4</option>
        <option value='5'>5</option>
        <option value='6'>6</option>
        <option value='7'>7</option>
        <option value='8'>8</option>
        <option value='9'>9</option>
        <option value='10'>10</option>
      </select>
    )
  }

  slideTwo(){
    let {
      scheduleApptFollowUp,
      transition,
      submittingDispoe,
      note,
      scheduleNowSelected,
      callLaterFollowUpError,
      scheduleApptFollowUpError
    } = this.state;

    let schedulingDispoe = scheduleNowSelected ? 'Live Transfer' : 'Schedule Appointment'
    return(
      <div className='wc-dispoe-secondary-options'>
        <div
          className='wc-back-button'
          onClick={() => this.setState({ transition: 'none'})}
        >
          <i className="fa fa-angle-double-left"></i>
        </div>
        <div className='wc-dispoe-note'>
          <textarea
            className='wc-leave-note'
            placeholder='Leave note...'
            value={note}
            onChange={(e) => this.updateField('note', e)}
          />
        </div>
        <div className={transition === 'Call Later' ? 'wc-dispoe-secondary-options-inner' : 'hidden'}>
          <div className='wc-dispoe-secondary-option'>
            <h3>Call Later</h3>
            <div className='wc-follow-up-container'>
              <input
                id='call_date_setter'
                className={callLaterFollowUpError ? 'follow-up-calendar error-box' : 'follow-up-calendar' }
                type='input'
                placeholder='Pick Date & Time'
              /> &nbsp;
              { this.callLaterSelector() }
            </div>
            <input
              className='wc-dispoe-btn'
              type='button'
              value={submittingDispoe ? 'Submitting...' : 'Submit' }
              disabled={submittingDispoe ? true : false }
              onClick={() => this.handleDispoe('Call Later')}
            />
            { this.attachCallLaterCalendar() }
          </div>
        </div>
        <div className={transition === 'Schedule Appointment' ? 'wc-dispoe-secondary-options-inner' : 'hidden'}>
          <div className='wc-dispoe-secondary-option'>
            <h3>Schedule Appointment</h3>
            <div className={scheduleApptFollowUpError ? 'follow-up-calendar error-box' : 'follow-up-calendar'}>
              <input
                type='input'
                disabled={scheduleNowSelected}
                value={scheduleApptFollowUp}
                onClick={this.toggleDateSelector}
                placeholder='Select a date'
              />
            </div>
            <div className='schedule-now'>
              <input
                className='checkbox'
                type='checkbox'
                checked={scheduleNowSelected}
                onClick={() => this.setState({ scheduleNowSelected: !scheduleNowSelected })}
              /> &nbsp;
              Schedule Now
            </div>
            <input
              className='wc-dispoe-btn'
              type='button'
              value={submittingDispoe ? 'Submitting...' : 'Submit' }
              disabled={submittingDispoe ? true : false }
              onClick={() => this.handleDispoe(schedulingDispoe)}
            />
          </div>
        </div>
      </div>
    )
  }

  attachCallLaterCalendar(){
    let timeZone = this.props.timeZone
    if(!this.state.setupCallLaterCalendar && $('#call_date_setter')){
      setTimeout(() => {
        $('#call_date_setter').attachLinkedDateTimePickerWithTimezone('#call_later_days', 30, timeZone.time_zone);
        $('.ui-datepicker-trigger').hide();
        $(document).on('valueChange', '#call_date_setter', () => {
          this.setState({ callLaterFollowUp: $('#call_date_setter').val() });
        });
        this.setState({setupCallLaterCalendar: true});
      }, 200)
    }
  }

  attachFollowUpCalendar(){
    let timeZone = this.props.timeZone
    if(!this.state.setupFollowUpCalendar && $('#follow_up_call_later_date')){
      setTimeout(() => {
        $('#follow_up_call_later_date').attachLinkedDateTimePickerWithTimezone('#follow_up_later_days', 30, timeZone.time_zone);
        $('.ui-datepicker-trigger').hide();
        $(document).on('valueChange', '#follow_up_call_later_date', () => {
          this.setState({ followUpApptFollowUp: $('#follow_up_call_later_date').val() });
        });
        this.setState({setupFollowUpCalendar: true});
      }, 200)
    }
  }
  render(){
    let {
      callLaterFollowUp, note, scheduleApptFollowUp,
      followUpApptFollowUp, submittingDispoe,
      transition
    } = this.state;
    let { timeZone, queue, task } = this.props;

    let queueDependentDisposition = () => {
      if(queue === 'follow_up' || (task && task.task_type === 'Outbound Agent - Follow-up')){
        return(
          <input
            className='wc-dispoe-btn'
            type='button'
            value={submittingDispoe ? 'Submitting...' : 'Schedule Follow Up' }
            disabled={submittingDispoe ? true : false }
            onClick={() => this.handleDispoe('Follow Up Complete')}
          />
        )
      } else {
        return(
          <input
            className='wc-dispoe-btn'
            type='button'
            value={submittingDispoe ? 'Submitting...' : 'Schedule Appointment' }
            disabled={submittingDispoe ? true : false }
            onClick={() => this.transitionDispoe('Schedule Appointment')}
          />
        )
      }
    };
    if(timeZone && Object.keys(timeZone).length){
      return (
        <section className='wc-dispoe'>
          <div className='react-header'>
            <h3>Disposition</h3>
          </div>
          <div className={transition === 'none' ? 'wc-dispoe-slider' : 'wc-dispoe-slider slide-on'}>
            <div className='wc-dispoe-slide-1'>
              <div className='wc-dispoe-note'>
                <textarea
                  className='wc-leave-note'
                  placeholder='Leave note...'
                  value={note}
                  onChange={(e) => this.updateField('note', e)}
                />
              </div>
              <div className='wc-dispoe-options'>
                <div className='wc-dispoe-option'>
                  <input
                    className='wc-dispoe-btn'
                    type='button'
                    value={submittingDispoe ? 'Submitting...' : 'No Answer' }
                    disabled={submittingDispoe ? true : false }
                    onClick={() => this.handleDispoe('No Answer')}
                  />
                </div>
                <div className='wc-dispoe-option'>
                  <input
                    className='wc-dispoe-btn'
                    type='button'
                    value={submittingDispoe ? 'Submitting...' : 'Call Later' }
                    disabled={submittingDispoe ? true : false }
                    onClick={() => this.transitionDispoe('Call Later')}
                  />
                </div>
                <div className='wc-dispoe-option'>
                  <input
                    className='wc-dispoe-btn'
                    type='button'
                    value={submittingDispoe ? 'Submitting...' : 'Refund Requested' }
                    disabled={submittingDispoe ? true : false }
                    onClick={() => this.handleDispoe('Refund Requested')}
                  />
                </div>
                <div className='wc-dispoe-option'>
                  { queueDependentDisposition() }
                </div>
              </div>
            </div>
            <div className='wc-dispoe-slide-2'>
              { this.slideTwo() }
            </div>
          </div>
          { this.dateSelector() }
        </section>
      )
    } else {
      return <section className='wc-dispoe' />
    }
  }
}
