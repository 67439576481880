import { Component } from 'react';
import ScheduleRowAssociate from './ScheduleRowAssociate';

export default class Schedule extends Component {
  constructor(props){
    super(props);
    this.state = {
      afss: [],
      salesAssociates: [],
      currentKey: undefined,
      refreshSchedule: false
    }
  }

  componentDidMount(){
    this.startTimerLine();
  }

  componentWillReceiveProps(nextProps) {
    // update the agents of a new day has been selected
    this.startTimerLine();
  }

  componentWillUnmount() {
    if(this.interval) clearInterval(this.interval);
  }

  startTimerLine(){
    let times = Object.keys(this.props.calendar).reverse();
    let timeCheck = Date.now() / 1000;
    if (timeCheck > parseInt(times[0])) return;   // return if the work day is over

    if(!this.interval) {
      this.interval = setInterval(() => {
        let currentTime = Date.now() / 1000;
        let currentKey;
        for(let i = 0; i < times.length; i ++) {
          if (currentTime > parseInt(times[i])) {
            currentKey =  parseInt(times[i]);
            break;
          }
        }
        if (currentKey && currentKey !== this.state.currentKey) {
          this.setState({ currentKey });
        }
      }, 3000);
    }
  }

  renderSchedule() {
    return (
      <div className='cs-schedule-row-associate'>
        { Object.keys(this.props.calendar).map((time, index) => {
          return(
            <ScheduleRowAssociate
              key={index}
              time={time}
              currentUser={this.props.currentUser}
              currentUserRoles={this.props.currentUserRoles}
              scheduleRow={this.props.calendar[time]}
              fetchAgent={this.props.fetchAgent}
              updateAppointmentAssignee={this.props.updateAppointmentAssignee}
              isCurrentAppointment={this.state.currentKey === parseInt(time)}
            />
          )
        }) }
      </div>
    )
  }

  renderCurrentDateText() {
    let { activeDate } = this.props
    if(activeDate == Util.MMDDYYYY()) {
      return "Today's Schedule";
    } else {
      let date = activeDate.split('/')
      return date[0] + '/' + date[1] + ' Schedule';
    }
  }

  render() {
    return (
      <div>
        <div className='cs-current-date'>
          {this.renderCurrentDateText()}
        </div>
        {this.renderSchedule()}
      </div>
    )
  }
}
