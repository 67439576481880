import { Component } from 'react';

export default class ExecutiveView extends Component {
  constructor(props){
    super(props);
  }

  render(){
    let { executives, executivesSelected } = this.props.state;
    return (
      <div className="content">
        <div className="checkboxes">
          {executives.map((exec, idx) => {
            let execIds = executivesSelected.map(e => { return e.id });
            return (
              <div className="checkbox" key={idx}>
                <input
                  type="checkbox"
                  checked={execIds.includes(exec.id)}
                  onChange={() => this.props.updateList('executivesSelected', exec, 'id')}
                />
                <span>{exec.name}</span>
              </div>
            )
          })}
        </div>
        <div className="prompt-options">
          <div className="prompt-cancel" onClick={this.props.returnToAdmins}>
            Back
          </div>
          <div className="prompt-confirm" onClick={() => this.props.updateState({ view: 'review' })}>
            Next
          </div>
        </div>
      </div>
    )
  }
}