import { Component } from 'react';
import ExclusiveLogo from '../../../../images/lead_icons/exclusive.png';
import DirectLogo from '../../../../images/lead_icons/direct_referral.png';

const Header = (props) => {
  const {
    lead,
    toggleEditContainer,
    toggleMultipleContainer,
    directReferral,
    dual_transaction,
    dual_transaction_buyer,
    exclusive,
    exclusiveAgentId,
    exclusiveAgentLink
  } = props;

  const image = () => {
    switch (lead.microsite) {
      case 'AgentMachine':
        return <img style={{ width: '44px', height: '33px' }} src={window.image_path('am_logo')} />;
        break;
      case 'ReferralExchange':
        return <img className='width-100' style={{ height: '23px' }} src={window.image_path('rex_logo')} />;
        break;
      case 'TopAgentsRanked':
        return <img style={{ width: '35px' }} src={'/images/tar_logo.png'} />;
        break;
      default:
        return <img className='width-100' src={window.image_path('leadPhotoSellerSquare')} />;
        break;
    }
  }

  const renderButton = () => {
    if(lead.users.length > 1) {
      return <button style={{color: 'white', backgroundColor: '#358ed7', fontSize: '12px'}} onClick={toggleMultipleContainer}>MULTIPLE</button>
    }
  }

  const renderDualButton = () => {
    if(dual_transaction) {
      return (
        <button
          style={{color: 'white', backgroundColor: '#358ed7', fontSize: '12px'}}
          onClick={()=> window.open(`/l/${dual_transaction_buyer.id}`, "_blank")}
        >DUAL
        </button>
      )
    }
  }

  const url =  `${window.BACKEND_PATH}/l/${lead.id}`;

  const flag = () => {
    if (lead.users[0] && lead.users[0].lightning){
      return <div className='lightning'>LIGHTNING</div>;
    }
  }

  const exclusiveOrDirectIcon = () => {
    let icon = ExclusiveLogo;
    let referralType = 'exclusive';
    let text = 'directed with this agent only'

    if (directReferral) {
      icon = DirectLogo;
      referralType = 'direct';
      text = 'to this agent plus other agent options';
    }

    if ((exclusive || directReferral) && exclusiveAgentId){
      return (
        <div className='exclusive-logo'>
          <img className='exclusive-logo-img' src={ icon } />
          <div className='exclusive-text'>
            This { referralType } referral resulted from a consumer visiting Agent&nbsp;
            <a href={ exclusiveAgentLink } target='_blank'>{ exclusiveAgentId }</a>
            &nbsp;REA.com page and asking to be connected { text }
          </div>
        </div>
      )
    }
  }

  return (
    <div className='profile-review-header row'>
      <div className='col-lg-6 height-100 no-right-padding border-box'>
        <div className='lead-header height-100 display-flex-vertical-center'>
          <div className='lead-header-container height-100'>
            <div className='lead-image inline-block display-inline-vertical-center'>
              { image() }
            </div>
            <div className='info-text v-align-top inline-block'>
              {dual_transaction ? 'SELLER + BUYER' : lead.lead_type} LEAD &nbsp;
              <a target='_blank' href={url}>{'#' + lead.id}</a>
              { flag() }
            </div>
            { exclusiveOrDirectIcon() }
          </div>
        </div>
      </div>
      <div className='col-lg-6 height-100 no-left-padding border-box'>
        <div className='col-lg-3 height-100 border-box'>
          <div className='width-100 button-container height-100 border-box display-flex-vertical-center'>
            { renderDualButton() }
          </div>
        </div>
        <div className='col-lg-3 height-100 border-box'>
          <div className='width-100 button-container height-100 border-box display-flex-vertical-center'>
            { renderButton() }
          </div>
        </div>
        <div className='col-lg-3 height-100 border-box'>
          <div className='width-100 button-container height-100 border-box display-flex-vertical-center'>
            <button onClick={ () => window.open(`/api/leads/new?lead_id=${lead.id}`, '_blank') }>CREATE LEAD</button>
          </div>
        </div>
        <div className='col-lg-3 height-100 border-box'>
          <div className='width-100 button-container height-100 border-box display-flex-vertical-center'>
            <button onClick={toggleEditContainer}>EDIT</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
