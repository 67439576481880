import React, { useState, useEffect } from 'react';

import String from '../../../../../../common/String.js'

const string = new String();

const CheckboxField = props => {
  const {
    klasses = '',
    fieldValue = 'true',
    fieldChecked,
    fieldDisabled,
    fieldName: fldName,
    fieldPrefix,
    fieldTitle,
    fieldType,
    required,
    placeholder,
    updateFieldHandler,
    handleOnClick,
  } = props;

  const fieldName = fldName || string.snakeCase(fieldTitle.replace(/[^\w\s]/g, ""));
  const name = !!fieldPrefix ? `${fieldPrefix}[${fieldName}]` : fieldName;
  const id = string.snakeCase(name);

  const [checked, _setChecked] = useState(!!fieldChecked);
  const [disabled, _setDisabled] = useState(!!fieldDisabled);

  useEffect(() => {
    const input = document.getElementById(id);

    if (checked) input.click();
    if (disabled) input.disabled = disabled;
  }, []);

  const labelText = () => {
    return required ? `${fieldTitle}*` : fieldTitle;
  };

  const handleFieldTypeValue = input => {
    switch (fieldType) {
    case 'bool':
    case 'boolean':
      return /true/.test(input.value) ? input.checked : !input.checked;
      break;
    case 'array':
      const inputs = document.getElementsByName(input.name);

      return [...inputs].filter(input => input.checked).map(input => input.value);

      break;
    default:
      return input.value;
    }
  };

  const updateField = event => {
    const value = handleFieldTypeValue(event.target);
    const data = !!fieldPrefix ? { [fieldPrefix]: { [fieldName]: value } } : { [fieldName]: value };

    updateFieldHandler(data);
  };

  return (
    <div className={ `input-group ${klasses}`}>
      <label htmlFor={ id }>
        { fieldTitle && <span className="label-text m-0">{ labelText() }</span> }
        <input type="checkbox" id={ id } name={ name } placeholder={ placeholder } onChange={ updateField } onClick={ handleOnClick } defaultValue={ fieldValue } />
      </label>
    </div>
  );
};

export default CheckboxField;


