const Header = () => {
  return (
    <thead>
      <tr>
        <th>From</th>
        <th>To</th>
        <th>Type</th>
        <th>Sent On</th>
        <th>Status</th>
      </tr>
    </thead>
  )
};

export default Header;
