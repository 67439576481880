import React, { Component } from 'react';
import { connect } from 'react-redux';

import { allTrue, getStateProps } from '../utils';
import { QualLiveCallControls } from '../../LiveCallControlsV2';

import './styles.scss';

const common = [
  'country',
  'email_address',
  'first_name',
  'home_price_min',
  'home_price_max',
  'last_name',
  'lead_type',
  'phone_number',
];

const sellers = common.concat([
  'city',
  'state',
  'zip',
]);

const buyers = common.concat([
  'buyer_cities',
]);

const leadTypeMap = {
  buyer: buyers,
  seller: sellers,
};

function mapStateToProps(state) {
  return {
    agent: {
      id: getStateProps(state.submittingAgent).info.id,
    },
    lead: getStateProps(state.info),
    validations: getStateProps(state.validations),
    focused: getStateProps(state.focused),
    live: {
      status: state.live.get('status'),
    },
  };
}

class Controls extends Component {
  render() {
    const {
      focused,
      leadID,
      repID,
      sessionUUID,
      lead,
      validations,
      conferenceType,
    } = this.props;

    const agent = {
      id: this.props.agent.id,
    }

    const rep = {
      id: repID,
    };

    const live = {
      live_disposition: 'submitted_agent',
      status: this.props.live.status
    };

    const requiredProps = leadTypeMap[lead.lead_type];

    const allValid = allTrue(requiredProps, validations);
    const areAllFocused = allTrue(requiredProps, focused);

    return (
      <div className='active-dialer-controls' id='active-dialer-controls'>
        <div className='active-dialer-controls-content'>
          <QualLiveCallControls
            sessionUUID     = { sessionUUID }
            lead            = { lead }
            agent           = { agent }
            rep             = { rep }
            live            = { live }
            allValid        = { allValid && areAllFocused }
            conferenceType  = { conferenceType }
          />
        </div>
     </div>
    );
  }
}

export default connect(mapStateToProps)(Controls);
