import { Component } from 'react';

export default class AppointmentScheduled extends Component {
  constructor(props){
    super(props);
    this.state = {
      setupCalendar: false,
    }

    this.requestFormURL = 'https://docs.google.com/forms/d/e/1FAIpQLScn5f4Hf6h_Bzre59DGb0ZNt-MOA8SDKj64I8fvJESfXHx2yA/viewform'
    this.updateAppointment = this.updateAppointment.bind(this);
  }

  containsErrors(){
    let { followUpTime, nextTask, errors, updateErrors } = this.props;
    let { assigned_to, disposition } = nextTask;

    let assignedToError = !assigned_to ? true : false;
    let followUpTimeError = () => {
      if(disposition === 'no_answer' || disposition === 'refund_requested') return false;
      if(!followUpTime) return true;
      return false;
    }
    let dispositionError = !disposition ? true : false;
    errors.assignedTo = assignedToError;
    errors.followUpTime = followUpTimeError();
    errors.disposition = dispositionError;
    updateErrors(errors);

    return assignedToError || followUpTimeError() || dispositionError;
  }

  updateAppointment(){
    if(this.containsErrors()) return;
    let { nextTask, currentTask, followUpTime, currentUser, agentId, activeDate, timeZone, resetState, setUpdating, note } = this.props;
    let parsedTime =  Util.parseDateTime(followUpTime, -1 * (timeZone.offset + 8));
    let tempDate = new Date(parsedTime);
    let parsedTimePlusOneDay = Util.parseForSQL(tempDate.setDate(tempDate.getDate() + 1))
    let submitDisposition;
    setUpdating(true);

    let updateParams = {
      completed_task: { task_id: currentTask.id },
      new_task: { taskable_id: agentId, assigned_to: nextTask.assigned_to },
      note: { noteable_id: agentId }
    }
    switch(nextTask.disposition){
      case 'call_completed':
        updateParams.new_task.follow_up = parsedTime;
        updateParams.note.message = `Customer onboarded. ${note}`;
        submitDisposition = Util.appointmentCompleted;
        break;
      case 'call_later':
        updateParams.new_task.follow_up = parsedTime;
        updateParams.note.message = `Customer success call rescheduled for ${followUpTime}. ${note}`;
        submitDisposition = Util.appointmentCallLater;
        break;
      case 'cancelled':
        updateParams.new_task.follow_up = parsedTime;
        updateParams.note.message = `Customer success call cancelled. ${note}`;
        submitDisposition = Util.appointmentCancelled;
        break;
      case 'refund_requested':
        updateParams.new_task.follow_up = null;
        updateParams.note.message =  `Refund requested by Agent. ${note}`;
        window.open(this.requestFormURL)
        submitDisposition = Util.appointmentRefundRequested;
        break;
      case 'no_answer':
        updateParams.new_task.follow_up = parsedTimePlusOneDay;
        updateParams.note.message = `Reschedule call set for next day. ${note}`;
        submitDisposition = Util.appointmentNoAnswer;
        break;
    }

    submitDisposition(updateParams)
      .then(() => resetState());
  }

  callLaterSelector(){
    return(
      <select id='call_later_days' onChange={(e) => this.props.changeFollowUp(Util.numberToDateTime(e))} >
        <option value=''></option>
        <option value='7'>7</option>
        <option value='15'>15</option>
        <option value='30'>30</option>
        <option value='45'>45</option>
      </select>
    )
  }

  attachCalendar(){
    let timeZone = this.props.timeZone;
    if(!this.state.setupCalendar && $('#call_date_setter')){
      setTimeout(() => {
        $('#call_date_setter').attachLinkedDateTimePickerWithTimezone('#call_later_days', 30, timeZone.time_zone);
        $('.ui-datepicker-trigger').hide();
        $(document).on('valueChange', '#call_date_setter', () => {
          this.props.changeFollowUp($('#call_date_setter').val());
        });
        this.setState({setupCalendar: true});
      }, 200)
    }
  }

  render(){
    let { showAppointment, assigneeOptions, errors, followUpTime, updateNote, note, updating, nextTask, currentTask, appointmentTime, statusOptions, toggleDateSelector, dateSelector } = this.props;

    let date = Util.dateTimetoDate(currentTask.follow_up);
    let time = appointmentTime(currentTask.follow_up);
    return(
      <div className={showAppointment ? 'cs-call-from' : 'cs-call-from-collapse'}>
        <div className='cs-call-from-inner'>
          <label>
            Date: &nbsp; <span>{ date }</span>
          </label>
          <label>
            Time: &nbsp; { time }
          </label>
          <label>With: </label>
          { assigneeOptions() }
          <label>Appointment Status</label>
          { statusOptions() }
          <div className={['call_completed', 'cancelled'].includes(nextTask.disposition) ? 'follow-up' : 'hidden'}>
            <label>Follow Up (agent time)</label>
            <div className={errors.followUpTime ? 'follow-up-fields cs-error' : 'follow-up-fields'}>
              <input
                id='call_date_setter'
                className='follow-up-calendar'
                type='input'
                placeholder='Follow up'
              />
              { this.callLaterSelector() }
              { this.attachCalendar() }
            </div>
          </div>
          <div className={nextTask.disposition === 'call_later' ? 'follow-up' : 'hidden'}>
            <label>Appointment Time(agent time)</label>
            <div className={errors.followUpTime ? 'follow-up-fields cs-error' : 'follow-up-fields'}>
              <input
                className='date-picker'
                type='input'
                placeholder='Select a date'
                onClick={toggleDateSelector}
                value={followUpTime || ''}
              />
            </div>
          </div>
          <label>Note: </label>
          <textarea
            className={errors.note ? 'cs-error' : ''}
            onChange={updateNote}
            value={note}
          />
          <input
            className='button'
            disabled={updating}
            type='button'
            value='Save'
            onClick={this.updateAppointment}
          />
          { dateSelector() }
        </div>
      </div>
    );
  }
}
