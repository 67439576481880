const FormBuilder = ({ fields, state, updateState, error }) => {

  const titleLabel = (label, stateProp) => {
    if (label) { return <label htmlFor={stateProp}>{label}</label> };
  }

  const errorNotice = error => {
    if (error) { return <div className="error-message">{error}</div> };
  }

  const initOptions = (field) => {
    const { label, stateProp, alias, options = {} } = field;
    const { checkboxOptions, selectOptions } = options;
    const style = options.style || {};
    const klass = `input-field ${options.class || "w50p"}`;
    const error = (!!state.errors[alias || stateProp]).toString();
    const errorMessage = error === 'true'
      ? state.errors[alias || stateProp].join(', ')
      : null

    return { label, errorMessage, stateProp, options, style, error, klass, checkboxOptions, selectOptions };
  }

  const fieldInterpreter = (field, idx) => {
    switch(field.type){
      case 'input':
        return inputField(field, idx); break;
      case 'select':
        return selectField(field, idx); break;
      case 'checkbox':
        return checkBoxField(field, idx); break;
      default:
        return inputField(field, idx);
    }
  }

  const inputField = (field, idx) => {
    const { label, errorMessage, stateProp, style, error, klass } = initOptions(field);

    return(
      <div key={idx} className={klass} style={style}>
        {titleLabel(label, stateProp)}
        <input
          type='text'
          id={stateProp}
          name={stateProp}
          error={error}
          value={state[stateProp] || ''}
          onChange={e => updateState({ [stateProp]: e.currentTarget.value })}
        />
        {errorNotice(errorMessage)}
      </div>
    )
  }

  const selectField = (field, idx) => {
    const { label, errorMessage, stateProp, style, error, klass, options, selectOptions } = initOptions(field);

    return (
      <div key={idx} className={klass} style={style}>
        {titleLabel(label, stateProp)}
        <select
          id={stateProp}
          name={stateProp}
          value={state[stateProp]}
          error={error}
          onChange={e =>
            updateState({ [stateProp]: e.currentTarget.value })
          }
        >
          {options.allowBlank ? <option value="" /> : null}
          {Array.isArray(selectOptions)
            ? selectOptions.map((option, key) => (
                <option key={key} value={option}>
                  {option}
                </option>
              ))
            : Object.keys(selectOptions).map((option, key) => (
                <option key={key} value={option}>
                {options.keyOnly ? option : selectOptions[option]}
                </option>
              ))
          }
        </select>
        <i className="fa fa-caret-down" />
        {errorNotice(errorMessage)}
      </div>
    );
  }

  const updateCheckbox = (stateProp, e) => {
    const set = state[stateProp];
    const val = e.currentTarget.value;

    set.includes(val)
      ? set.splice(set.indexOf(val), 1)
      : set.push(val)

    updateState({ [stateProp]: set })
  }

  const checkBoxField = (field, idx) => {
    const { label, errorMessage, stateProp, style, error, klass, checkboxOptions } = initOptions(field);

    return (
      <div key={idx} className={klass} style={style}>
        {titleLabel(label, stateProp)}
        <section className="checkbox-container">
          {checkboxOptions.map((checkbox, idx) => (
            <label key={idx} htmlFor={checkbox.value}>
              <input
                type="checkbox"
                id={checkbox.value}
                name={checkbox.value}
                checked={state[stateProp].includes(checkbox.value)}
                value={checkbox.value}
                error={error}
                onChange={e => updateCheckbox(stateProp, e)}
              />
              {checkbox.label}
            </label>
          ))}
        </section>
        {errorNotice(errorMessage)}
      </div>
    );
  }

  return(
    <section id='form-builder'>
      {fields.map((field, idx) => fieldInterpreter(field, idx))}
    </section>
  )
}

export default FormBuilder;
