const agentLink = slug => {
  const link = `https://www.realestateagents.com/agent/${slug}`;

  return slug ? <a href={ link } target="_blank">{ slug }</a> : 'N/A';
};

const Agent = ({ agent }) => {
  const {
    id,
    brokerage,
    email,
    name,
    url_slug,
  } = agent;

  return (
    <div className="col-md-6">
      <div className="agent-details">
        <h3>Agent</h3>
        <table className="table">
          <tbody>
            <tr>
              <th className="col-md-2">Name</th>
              <td className="col-md-10">{ name || 'N/A' }</td>
            </tr>
            <tr>
              <th className="col-md-2">Email</th>
              <td className="col-md-10">{ email || 'N/A' }</td>
            </tr>
            <tr>
              <th className="col-md-2">Brokerage</th>
              <td className="col-md-10">{ brokerage || 'N/A' }</td>
            </tr>
            <tr>
              <th className="col-md-2">Agent URL</th>
              <td className="col-md-10">{ agentLink(url_slug) }</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
};

export default Agent;
