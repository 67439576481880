import React from 'react';
import Transaction from './TransactionView'

const HistoryView = (props) => {
  const {
    has_permission,
    hasRefundPermission,
    refundUrl,
    reloadTransactions,
    retry_payments_path,
    transactions,
  } = props;

  return (
    <div data-retry-payments-path={ retry_payments_path } className='payment-rows'>
      {
        transactions.map((transaction, i) => (
          <Transaction
            has_permission={has_permission}
            hasRefundPermission={hasRefundPermission}
            reloadTransactions={reloadTransactions}
            refundUrl={refundUrl}
            key={i}
            transaction={transaction}
          />
        ))
      }
    </div>
  );
}

export default HistoryView;
