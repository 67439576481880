import  { Component } from  'react';
import mainIcon from '../images/call-outgoing.svg';

export default class Contacting extends Component {
  render(){
    return (
      <div className="button contacting">
        <div className='row'>
          <div className='col-sm-2'>
            <img src={ mainIcon }/>
          </div>
          <div className='col-sm-6'>
            <div className='row top-row status-text'>
              <strong>Contacting...</strong>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
