import React from 'react';

const CancelReactivateRadioView = (props) => {
  const {
    reason,
    selectRadio,
    selectedText,
  } = props;

  return (
    <div className='rex-modal-radio-wrapper'>
      <input
        type='radio'
        name='reason'
        onChange={ selectRadio }
        checked={ selectedText === reason }
        value={ reason }
      />
      <label>{ reason }</label>
    </div>
  );
}

export default CancelReactivateRadioView;
