import { Map } from 'immutable';

const initialState = Map({
  info: {
    city: null,
    company: null,
    country: null,
    id: null,
    image: null,
    name: null,
    phone_office: null,
    phone_mobile: null,
    state: null,
  },
  source: null,
  type: null,
  rawAddressFields: {
    address: '',
    city: '',
    country: '',
    state: '',
    street_name: '',
    street_number: '',
    unit_number: '',
  },
  rawLocation: null,
  rawName: null,
  rawPrice: null,
  notes: null,
});

const actionsMap = {
  ['SET_SUBMITTING_AGENT']: (st, action) => {
    const { live, sending_agent } = action.data;

    const {
      address,
      city,
      country,
      id,
      phone_office,
      phone_mobile,
      state,
    } = sending_agent;

    const {
      from_the_agent,
      raw_address_fields,
      raw_location_for_client,
      raw_name,
      raw_price_input,
      source,
      submitting_agent_name,
      submitting_agent_company_name,
      submitting_agent_photo,
      status,
    } = live;

    return st.merge(Map({
      info: {
        address,
        city,
        company: submitting_agent_company_name,
        country,
        id,
        image: submitting_agent_photo,
        name: submitting_agent_name,
        phone_office,
        phone_mobile,
        state,
      },
      source,
      type: status || null,
      rawAddressFields: raw_address_fields,
      rawLocation: raw_location_for_client,
      rawName: raw_name,
      rawPrice: raw_price_input,
      notes: from_the_agent
    }));
  },
  ['SET_LIMITED_LEAD_AGENT_DATA']: (state, action) => {
    const {
      agent_name,
      agent_company_city,
      agent_company_name,
      agent_company_state,
      raw_lead_name,
    } = action.customAttributes;

    return state.merge(Map({
      info: {
        city: agent_company_city,
        company: agent_company_name,
        name: agent_name,
        state: agent_company_state,
      },
      rawName: raw_lead_name,
    }));
  },
  ['RESET_SUBMITTING_AGENT']: (state) => {
    return state.merge(initialState);
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
