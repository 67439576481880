import { Component } from 'react';

import LeftContainer    from './LeftContainer';
import RightContainer   from './RightContainer';
import OutcomeContainer from './OutcomeContainer';

import MultipleLeads    from './MultipleLeads';
import EditLeadInfo     from './EditLeadInfo';
import TimeSlots        from './TimeSlots';

import { NewLeadFromLeadModal } from '../LeadCreation';
import * as QualQueue from '../QualQueue';

export default class MainContainer extends Component {
  constructor(props) {
    super(props);
    TimeSlots.set(this.props.time_slots);

    this.events = EventBus;
    this.state = {
      lead: {
        first_name: '',
        last_name:  '',
        users:      [],
      },
      matches: [],
      leadWall: [],
      renderOutcomeContainer: false,
      outcome: '',
      lead_note: '',
      toggleEditContainer: false,
      toggleMultipleContainer: false,
      leadUpdated: false,
      pause: false,
      callsToday: 0,
      follow_up_date: '',
      dual_transaction: false,
      dual_transaction_buyer: '',
    };

    this.callLead                   = this.callLead.bind(this);
    this.clearFollowUpDate          = this.clearFollowUpDate.bind(this);
    this.hideOutcomeContainer       = this.hideOutcomeContainer.bind(this);
    this.parseBuyerCities           = this.parseBuyerCities.bind(this);
    this.refreshDialer              = this.refreshDialer.bind(this);
    this.setFollowUpDateState       = this.setFollowUpDateState.bind(this);
    this.showOutcomeContainer       = this.showOutcomeContainer.bind(this);
    this.submitCallOutcome          = this.submitCallOutcome.bind(this);
    this.toggleEditContainer        = this.toggleEditContainer.bind(this);
    this.toggleFollowUpDate         = this.toggleFollowUpDate.bind(this);
    this.toggleMultipleContainer    = this.toggleMultipleContainer.bind(this);
    this.toggleSubmitNextButton     = this.toggleSubmitNextButton.bind(this);
    this.updateLeadInfo             = this.updateLeadInfo.bind(this);
    this.updateLeadNote             = this.updateLeadNote.bind(this);
    this.updateOutcome              = this.updateOutcome.bind(this);
    this.updatePause                = this.updatePause.bind(this);
  }

  componentDidMount() {
    var lead_id = this.getParameterByName('lead_id');
    this.obtainNextLead(lead_id);
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  obtainNextLead(lead_id) {
    if (lead_id)
      var options = { lead_id: lead_id }
    else
      var options = {};
    $.get('/dialer/profile_review/next_lead', options, function(result) {
      if (result !== "window.location.href = '/dialer';") {
        this.obtainLeadInfo(result.lead_id);
      }
    }.bind(this));
  }

  obtainLeadInfo(lead_id) {
    var options = { lead_id: lead_id };
    $.get('/dialer/profile_review/content', options, function(result) {
      const { lead, matches, dual_transaction, dual_transaction_buyer } = result;
      var leadKeys = Object.keys(lead);
      leadKeys.forEach(function(key) {
        lead[key] = lead[key] || '';
      });

      this.setState({
        lead,
        matches,
        dual_transaction,
        dual_transaction_buyer,
        leadWall: result.lead_wall,
      });

      var object_ids = { lead_id: lead.id, match_ids: matches.map(function(data) { return data.match.id }) };
      $.get('/dialer/profile_review/profile_pictures', object_ids, function(data) {
        data.match_photos.forEach(function(photo, index) {
          matches[index].agent['photo'] = photo;
        });
        lead.photo = data.lead_photo;
        this.setState({matches: matches, lead: lead});

        this.events.emit( 'dialer:loaded', { name: lead.first_name + ' ' + lead.last_name } );
        if (!result.lead.blacklisted)
          this.callLead(lead_id);
        window.scrollTo(0, 0);
      }.bind(this))
    }.bind(this));

    $.get('/dialer/profile_review/call_data', function(result){
      this.setState({ callsToday: result.calls_today })
    }.bind(this));
  }

  callLead(lead_id) {
    var options = { lead_id: lead_id };
    $.post('/dialer/profile_review/call_lead', options, function(result) {});
  }

  renderOutcomeContainer() {
    if(this.state.renderOutcomeContainer) {
      return (
        <OutcomeContainer
          hideOutcomeContainer={this.hideOutcomeContainer}
          matches={this.state.matches}
          email={this.props.email}
          name={this.props.name}
          currentUser={this.props.currentUser}
          user_mailer_css={this.props.user_mailer_css}
          outcome={this.state.outcome}
          lead_note={this.state.lead_note}
          lead={this.state.lead}
          callLead={this.callLead}
          refreshDialer={this.refreshDialer}
          leadOptions={this.leadOptions()}
          leadUpdated={this.state.leadUpdated}
          pause={this.state.pause}
          events={this.events}
        />
      );
    }
  }

  submitCallOutcome(e) {
    if (e.target.classList.contains('disabled')) return;

    if (this.state.lead_note.length) {
      this.setState({ noteError: false });
    } else {
      this.setState({ noteError: true });
      return;
    }

    var options = {
      note_data:      { note_message: this.state.lead_note },
      outcome:        this.state.outcome,
      lead_id:        this.state.lead.id,
      lead_options:   this.leadOptions(),
      lead_updated:   this.state.leadUpdated,
      pause:          this.state.pause,
      answered_phone: this.state.outcome,
      follow_up_date: this.state.follow_up_date
    };

    this.events.emit( 'dialer:submit' );

    $.post('/dialer/profile_review/submit', options, function(result) {
      this.refreshDialer();
    }.bind(this), this.toggleFollowUpDate());
  }

  refreshDialer() {
    this.setState(
      {
        renderOutcomeContainer: false,
        outcome: '',
        lead_note: '',
        matches: [],
        toggleEditContainer: false,
        toggleMultipleContainer: false,
        leadUpdated: false,
        leadWall: [],
        pause: false,
        follow_up_date: '',
        lead: {
          first_name: '',
          last_name: '',
          users: []
        },
      }
    );
    this.obtainNextLead();
  }

  updateOutcome(e) {
    const outcome = e.target.value;

    this.setState({ outcome: outcome });
    this.toggleFollowUpDate(outcome);
    this.toggleSubmitNextButton(outcome);
  }

  updateLeadNote(e) {
    this.setState({ lead_note: e.target.value });
  }

  showOutcomeContainer(e) {
    this.updateOutcome(e);
    if(e.target.value.length)
      this.setState({ renderOutcomeContainer: true });
  }

  hideOutcomeContainer() {
    var confirmation = confirm('Are you sure you want to leave? You will lose any unsaved work.');
    if (confirmation)
      this.setState({ renderOutcomeContainer: false, outcome: '' });
  }

  toggleEditContainer() {
    this.setState({toggleEditContainer: !this.state.toggleEditContainer});
  }

  toggleMultipleContainer() {
    this.setState({toggleMultipleContainer: !this.state.toggleMultipleContainer});
  }

  updateLeadInfo(data) {
    this.setState({lead: data, leadUpdated: true});
    this.toggleEditContainer();
  }

  setFollowUpDateState(date) {
    this.setState({ follow_up_date: this.parseDate(date) });
  }

  parseDate(date) {
    if (date == undefined || !date.length) return;

    const parts = date.match(/(\d+|AM|PM)/g);
    const month = parts[0];
    const day = parts[1];
    const year = parts[2];
    let hour = parseInt(parts[3]);
    const minute = parts[4];
    const amPM = parts[5];

    if (amPM == 'PM' && hour < 12) hour += 12;
    if (amPM == 'AM' && hour == 12) hour -= 12;

    return `${year}-${month}-${day} ${hour}:${minute}`
  }

  clearFollowUpDate() {
    const followUpDays = document.querySelector('.follow-up-days');

    followUpDays.value = '';
    followUpDays.dispatchEvent(new Event('change'));
  }

  toggleFollowUpDate(outcome) {
    const followUpInputs = document.querySelector('.follow-up-inputs');

    if (outcome === 'too_early_for_profile_review') {
      followUpInputs.classList.remove('hidden');
    } else {
      followUpInputs.classList.add('hidden');
      this.clearFollowUpDate();
    }
  }

  toggleSubmitNextButton(outcome) {
    const submitAndNextButton = document.querySelector('.submit-and-next');

    if (outcome.length > 0) {
      submitAndNextButton.classList.remove('disabled');
    } else {
      submitAndNextButton.classList.add('disabled');
    }
  }

  parseBuyerCities() {
    let buyer_cities = [];
    if(this.state.lead.buyer_city1.length && this.state.lead.buyer_state1.length) {
      buyer_cities.push([this.state.lead.buyer_city1 + ',' + this.state.lead.buyer_state1]);
    }
    if(this.state.lead.buyer_city2.length && this.state.lead.buyer_state2.length) {
      buyer_cities.push([this.state.lead.buyer_city2 + ',' + this.state.lead.buyer_state2]);
    }
    if(this.state.lead.buyer_city3.length && this.state.lead.buyer_state3.length) {
      buyer_cities.push([this.state.lead.buyer_city3 + ',' + this.state.lead.buyer_state3]);
    }
    if(this.state.lead.buyer_city4.length && this.state.lead.buyer_state4.length) {
      buyer_cities.push([this.state.lead.buyer_city4 + ',' + this.state.lead.buyer_state4]);
    }
    return buyer_cities.join('&');
  }

  leadOptions() {
    if(this.state.lead.lead_type === 'BUYER') {
      return {
        lead_options: {
          first_name:   this.state.lead.first_name,
          last_name:    this.state.lead.last_name,
          email:        this.state.lead.email,
          phone_number: this.state.lead.phone_number,
          price:        this.state.lead.price,
          type_home:    this.state.lead.type_home,
          comments:     this.state.lead.comments,
          time_frame:   this.state.lead.time_frame,
          max_agents:   this.state.lead.max_agents,
          buyer_cities: this.parseBuyerCities(),
        },
        lead_id: this.state.lead.id
      }
    } else {
      return {
        lead_options: {
          first_name:     this.state.lead.first_name,
          last_name:      this.state.lead.last_name,
          email:          this.state.lead.email,
          phone_number:   this.state.lead.phone_number,
          street_number:  this.state.lead.street_number,
          street_name:    this.state.lead.street_name,
          unit_number:    this.state.lead.unitNumber,
          city:           this.state.lead.city,
          state:          this.state.lead.state,
          zip:            this.state.lead.zip,
          price:          this.state.lead.price,
          time_frame:     this.state.lead.time_frame,
          max_agents:     this.state.lead.max_agents,
          type_home:      this.state.lead.type_home,
          comments:       this.state.lead.comments
        },
        lead_id: this.state.lead.id
      }
    }
  }

  renderEditContainer() {
    if(this.state.toggleEditContainer) {
      return (
        <EditLeadInfo
          lead={this.state.lead}
          currentUser={this.props.currentUser}
          toggleEditContainer={this.toggleEditContainer}
          updateLeadInfo={this.updateLeadInfo}
          leadUpdated={this.state.leadUpdated}
          parseBuyerCities={this.parseBuyerCities}
          dual_transaction={this.state.dual_transaction}
          dual_transaction_buyer={this.state.dual_transaction_buyer}
        />
      );
    }
  }

  renderMultipleContainer() {
    if(this.state.toggleMultipleContainer) {
      return (
        <MultipleLeads
          lead={this.state.lead}
          currentUser={this.props.currentUser}
          toggleMultipleContainer={this.toggleMultipleContainer}
        />
      );
    }
  }

  renderCreateLeadContainer() {
    if(this.state.lead.first_name.length) {
      var lead = this.state.lead
      lead.user = { email: this.state.lead.email }
      return (
        <NewLeadFromLeadModal
          formData  =   { this.props.new_lead_form_data}
          state     =   { this.state.lead.fullState}
          lead      =   { lead }
          matches   =   { this.matches() }
          is_matcher  = { true }
        />
      );
    } else {
      return null;
    }
  }

  matches() {
    return this.state.matches.map(function(data, idx) {
      var match = data.match;
      match['agent'] = {};
      match['agent']['id'] = data.agent.id;
      match['agent']['name_on_license'] = data.agent.name_on_license;
      return match;
    }.bind(this));
  }

  pauseText() {
    if(this.state.pause) {
      return 'RESUME';
    } else {
      return 'PAUSE';
    }
  }

  updatePause() {
    this.setState({pause: !this.state.pause})
  }

  pauseIcon() {
    if(this.state.pause) {
      return <i className='fa fa-play'></i>
    } else {
      return <i className='fa fa-pause'></i>
    }
  }

  render() {
    const {
      callsToday,
      dual_transaction,
      dual_transaction_buyer,
      lead,
      lead_note,
      leadWall,
      matches,
      noteError,
      outcome,
    } = this.state;

    const {
      come_home_analytic_path: comeHomeAnalyticPath,
      come_home_mailer_path: comeHomeMailerPath,
      direct_referral: directReferral,
      exclusive,
      exclusive_agent_id: exclusiveAgentId,
      exclusive_agent_link: exclusiveAgentLink,
    } = this.props;

    return (
      <div className='row profile-review-container'>
        <div className='pause-container'>
          <div className='pause' onClick={ this.updatePause }>
            <span className='btn_text'>{ this.pauseText() }</span>
            { this.pauseIcon() }
          </div>
          <div className='calls'>
            <span>Calls Made</span>
            <div className='count'>{ callsToday }</div>
          </div>
        </div>

        <QualQueue.Loader events={ this.events } />

        { this.renderCreateLeadContainer() }
        { this.renderOutcomeContainer() }
        { this.renderEditContainer() }
        { this.renderMultipleContainer() }

        <LeftContainer
          lead={ lead }
          matches={ matches }
          showOutcomeContainer={ this.showOutcomeContainer }
          callLead={ this.callLead }
          outcome={ outcome }
          lead_note={ lead_note }
          updateLeadNote={ this.updateLeadNote }
          submitCallOutcome={ this.submitCallOutcome }
          toggleEditContainer={ this.toggleEditContainer }
          toggleMultipleContainer={ this.toggleMultipleContainer }
          updateOutcome={ this.updateOutcome }
          noteError={ noteError }
          setFollowUpDateState={ this.setFollowUpDateState }
          dual_transaction={ dual_transaction }
          dual_transaction_buyer={ dual_transaction_buyer }
          directReferral={ directReferral }
          exclusive={ exclusive }
          exclusiveAgentId={ exclusiveAgentId }
          exclusiveAgentLink={ exclusiveAgentLink }
        />

        <RightContainer
          matches={ matches }
          leadWall={ leadWall }
          lead={ lead }
          comeHomeAnalyticPath={ comeHomeAnalyticPath }
          comeHomeMailerPath={ comeHomeMailerPath }
        />
      </div>
    )
  }
}
