import { Map } from 'immutable';

const initialState = Map({
  live_disposition: null,
  live_lead: null,
  source: null,
  status: null,
  submitting_agent_name: null,
  submitting_agent_timezone: null,
});

const actionsMap = {
  ['SET_LIVE']: (state, action) => {
    const { live } = action.data;

    const {
      live_disposition,
      live_lead,
      source,
      status,
      submitting_agent_name,
      submitting_agent_timezone,
    } = live;

    return state.merge(Map({
      live_disposition,
      live_lead,
      source,
      status,
      submitting_agent_name,
      submitting_agent_timezone,
    }));
  },
  ['SET_LIMITED_LEAD_LIVE_DATA']: (state, action) => {
    const { live_lead } = action.customAttributes;
    return state.merge(Map({ live_lead }));
  },
  ['RESET_LIVE']: (state) => {
    return state.merge(initialState);
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
