import React from 'react';

import Input from '../inputs/StandardInput/view';
import Select from '../inputs/Select/view';
import { selectedCountryInfo } from '../inputs/utils';

import GoogleSearch from '../GoogleSearch/buyerCities';
import RemoveIcon from '../../images/times-square@2x.png';

const BuyerCities = (props) => {
  const {
    data,
    focus,
    focused,
    validations,

    addCityState,
    addBuyerCityFields,
    handleBuyerCityFocus,
    removeCityState,
  } = props;

  const { buyer_cities, country } = data;

  const countryInfo = selectedCountryInfo(country);

  const { labels, statesSelectValues } = countryInfo;

  if (statesSelectValues[0] !== '') {
    statesSelectValues.unshift('');
  }

  const buyerCities = ({city, state}, index) => {
    const isValid = (index) => {
      return validations ? validations[index] : false;
    };

    const rowClassList = ['info-row', 'city-state-row'];
    const inputClasses = ['city-state__city'];
    const selectClasses = ['city-state__state'];

    if (!focused[index]) {
      inputClasses.push('untouched');
      selectClasses.push('untouched');
    }

    return (
      <div className={ rowClassList.join(' ') } key={ `city-state-${ index }`}>
        <Input
          classNames={ inputClasses.join(' ') }
          display=' '
          focused={ true }
          id={ `buyer_cities_city-${ index }` }
          index={ index }
          name='city'
          valid={ isValid(index) }
          value={ city }

          onChange={ addCityState }
          onFocus={ handleBuyerCityFocus }
        />
        <Select
          classNames={ selectClasses.join(' ') }
          data={ statesSelectValues }
          display=' '
          focused={ true }
          id={ `buyer_cities_state-${ index }` }
          index={ index }
          name='state'
          valid={ isValid(index) }
          value={ state }

          onChange={ addCityState }
          onFocus={ handleBuyerCityFocus }
        />
        <GoogleSearch
          properties={[city, state]}
        />
        { index > 0 &&
          <button onClick={ () => removeCityState(index) }>
            <img className='remove-icon' src={ RemoveIcon } />
          </button>
        }
      </div>
    );
  };

  return (
    <div className='buyer-cities'>
      <div className='info-row'>
        <div className='info-field city-state__city'>
          <label> City </label>
        </div>
        <div className='info-field city-state__state'>
          <label> { labels.state } </label>
        </div>
      </div>
      {
        buyer_cities.length &&
        buyer_cities.map(buyerCities)
      }

      {
        buyer_cities.length < 4 &&
        <div className='info-row add-cities'>
          <button className='add-buyer-city' onClick={ addBuyerCityFields }>Add Buyer City</button>
        </div>
      }
    </div>
  );
};

export default BuyerCities;
