import React from 'react';
import Context from '../Context';
import StageOne from './StageOne';
import StageTwo from './StageTwo';
import StageThree from './StageThree';

const Main = () => (
  <Context.Consumer>
    {({
      reviewStage,
      selections: {
        foundSale,
        foundListing,
      },
    }) => {
      return (
        <div id="main-container">
          {
            reviewStage === 'stage3'
              ? <StageThree />
              : (
                <>
                  <StageOne />
                  {
                    (foundSale || foundListing) && <StageTwo />
                  }
                </>
              )
          }
        </div>
      )

    }}
  </Context.Consumer>
);

export default Main;
