import { Component } from  'react';
import classNames from 'classnames';

export default class ButtonBase extends Component {
  constructor(props){
    super(props);

    this.state = {
      wasClicked: false
    };

    this.reinitialize = this.reinitialize.bind(this);
  }

  render() {
    const { disabled } = this.props;
    const classes = classNames('button', 'grey', this.determineStatusClass());

    return (
      <button
        className={ classes }
        disabled={ disabled }
        onClick={this.updateCallMode.bind(this)}
      >
        { this.renderButtonContents() }
      </button>
    );
  }

  updateCallMode(mode) {
    let statusClass = this.determineStatusClass();
    if (statusClass == 'disabled' || statusClass == 'on-line' || this.state.wasClicked == true) return;
    this.setState({wasClicked: true});

    this.props.updateCallMode(this.modeType())
      .then((res) => {
        this.reinitialize();
      });
  }

  reinitialize() {
    this.setState({ wasClicked: false });
  }

  determineStatusClass() {
    throw 'Must be implemented.';
  }

  modeType() {
    throw 'Must be implemented.';
  }

  renderButtonContents() {
    throw 'Must be implemented.';
  }
}
