import { Component } from 'react';

export default class LeadSellerPropertyInfo extends Component {
  constructor(props){
    super(props)
  }

  countryList() {
    let { handleChange, country, formData } = this.props;
    let countryCodes = Object.keys(formData.countries);
    return (
      <select value={country || ''} onChange={(e) => handleChange('country', e)}>
        {countryCodes.map((code, idx) => <option value={code} key={idx}>{formData.countries[code]}</option>)}
      </select>
    )
  }

  statesList() {
    let { handleChange, state, formData, country } = this.props;
    let stateType = country === 'CA' ? formData.provinces : formData.states;

    return (
      <select value={state || ''} onChange={(e) => handleChange('state', e)}>
        <option value='' key={0}>Select</option>
        {stateType.map((name, idx) => <option value={name} key={idx + 1}>{name}</option>)}
      </select>
    )
  }

  typeOfHomesList() {
    return(
      <select value={this.props.typeHome} onChange={(e) => this.props.handleChange('typeHome', e)}>
        <option value='' key={0}>Select</option>
        {this.props.formData.seller.type_of_home.map((homePair, idx) => <option value={homePair[0]} key={idx + 1}>{homePair[0]}</option>)}
      </select>
    )
  }

  timeFramesList() {
    return(
      <select value={this.props.timeFrame} onChange={(e) => this.props.handleChange('timeFrame', e)}>
        <option value='' key={0}>Select</option>
        {this.props.formData.seller.time_frame.map((time, idx) => <option value={time} key={idx + 1}>{time}</option>)}
      </select>
    )
  }

  homePricesList() {
    const homePrices = this.props.formData.seller.home_prices;

    return(
      <select value={ this.props.homePrice } onChange={(e) => this.props.handleChange('homePrice', e)}>
        <option value='' key={0}>Select</option>

        {
          Object.keys(homePrices).map((key, i) => {
            const value = homePrices[key];
            const encodedPriceRange = btoa(JSON.stringify(value));

            return <option value={ encodedPriceRange } key={ i }>{ key }</option>
          })
        }
      </select>
    )
  }

  reasonForSellingList() {
    return(
      <select value={this.props.reasonForSelling} onChange={(e) => this.props.handleChange('reasonForSelling', e)}>
        <option value='' key={0}>Select</option>
        {this.props.formData.seller.reason_for_selling.map((reason, idx) => <option value={reason} key={idx + 1}>{reason}</option>)}
      </select>
    )
  }

  render() {
    let { handleChange, streetNumber, streetName, unitNumber, city, state, zip, country, typeOfHome, timeFrame, reasonForSelling, formData } = this.props;
    let stateType = country === 'CA' ? 'Province' : 'State';
    let geoType = country === 'CA' ? 'Postal Code' : 'Zip';
    return(
      <div className='fieldset'>
        <div className='address-group'>
        <div className='row'>
          <div className='col-sm-5 left-column'>
            <label className='field-label'>Country:<span className='required-field'>*</span></label>
          </div>
          <div className='col-sm-5 right-column'>
            <label className='address-select-arrow' />
            {this.countryList()}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-5 left-column'>
            <label className='field-label'>Street Number:<span className='required-field'>*</span></label>
          </div>
          <div className='col-sm-5 right-column'>
            <input
              type='text'
              value={streetNumber}
              maxLength='10'
              onChange={(e) => handleChange('streetNumber', e)}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-5 left-column'>
            <label className='field-label'>Street Name:<span className='required-field'>*</span></label>
          </div>
          <div className='col-sm-5 right-column'>
            <input
              type='text'
              value={streetName}
              onChange={(e) => handleChange('streetName', e)}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-5 left-column'>
            <label className='field-label'>Unit Number:</label>
          </div>
          <div className='col-sm-5 right-column'>
            <input
              type='text'
              value={unitNumber}
              onChange={(e) => handleChange('unitNumber', e)}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-5 left-column'>
            <label className='field-label'>City:<span className='required-field'>*</span></label>
          </div>
          <div className='col-sm-5 right-column'>
            <input
              type='text'
              value={city}
              onChange={(e) => handleChange('city', e)}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-5 left-column'>
            <label className='field-label'>{ stateType }:<span className='required-field'>*</span></label>
          </div>
          <div className='col-sm-5 right-column'>
            <label className='address-select-arrow'></label>
            {this.statesList()}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-5 left-column'>
            <label className='field-label'>{ geoType }:<span className='required-field'>*</span></label>
          </div>
          <div className='col-sm-5 right-column'>
            <input
              type='text'
              value={zip}
              onChange={(e) => handleChange('zip', e)}
            />
          </div>
        </div>
      </div>
      <hr></hr>
        <div className='row'>
          <div className='col-sm-5 left-column balance-non-required'>
            <label className='select-label field-label'>Type of Home</label>
            {this.typeOfHomesList()}
          </div>
          <div className='col-sm-5 right-column'>
            <label className='required-select-label field-label'>Target Price Range<span className='required-field'>*</span></label>
            {this.homePricesList()}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-5 left-column'>
            <label className='select-label field-label'>Selling Time Frame</label>
            {this.timeFramesList()}
          </div>
          <div className='col-sm-5 right-column'>
            <label className='select-label field-label'>Reason for Selling</label>
            {this.reasonForSellingList()}
          </div>
        </div>
        <div className='row key-row'>
          <div className='col-sm-5'>
            <p className='required-key-text'>* Indicates required field</p>
          </div>
        </div>
        <div className='row hr-row'>
          <hr className='hr-bottom'></hr>
        </div>
      </div>
    )
  }
}
