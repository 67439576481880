import { Component } from 'react';
import ExclusiveLogo from '../../../../../images/lead_icons/exclusive.png';
import DirectLogo from '../../../../../images/lead_icons/direct_referral.png';

const BuyerForm = props => {
  const {
    data,
    updateState,
    click2CallLink,
    directReferral,
    exclusive,
    exclusiveAgentId,
    exclusiveAgentLink,
  } = props;

  const {
    blacklisted,
    comments,
    email,
    home,
    lead,
    mortgage_status,
    origin,
    pmr_form,
    provinces,
    rex_referral,
    sending_agent,
    states,
    validations,
    stand_in_data: {
      buyer_cities,
      country,
      lead_price,
      min_price,
      max_price,
    },
  } = data;

  const highendClass = (parseInt(home.price.min_price) >= 1000000)
    ? 'highend'
    : 'highend hidden';

  const displayBuyerCitiesDefault = () => {
    const keys = Object.keys(lead).filter(k => /^buyer_(city|state)/.test(k));
    const leadBuyerCities = keys ? keys.map(k => lead[k]).toString().replace(/\s+/, '') : undefined;
    const standInBuyerCities = buyer_cities ? buyer_cities.replace(/\s+/, '') : undefined;

    return (leadBuyerCities === standInBuyerCities);
  }

  const displayMinPriceDefault = () => {
    return (parseInt(`${lead.home_price_min}`.replace(/\W/g, '')) === min_price);
  }

  const displayMaxPriceDefault = () => {
    return (parseInt(`${lead.home_price_max}`.replace(/\W/g, '')) === max_price);
  }

  const displayPriceDefault = () => {
    return displayMinPriceDefault() && displayMaxPriceDefault();
  }

  const handleLeadChange = e => {
    let val = e.target.value;
    const name = e.target.name;

    if (name == 'home_price_max') {
      val = val.replace(/\D/g, '')
    } else if (e.target.type == 'tel') {
      let prefix = val.startsWith('+') ? '+' : ''
      val = prefix + val.replace(/[^\d\(\) -]/g, '').replace(/\s+/g, ' ').replace(/\(+/g, '(').replace(/\)+/g, ')').replace(/-+/g, '-')
    } else if (e.target.type == 'digits') {
      val = val.replace(/\D/g, ' ')
    }

    lead[name] = val;
    updateState(data);
  }

  const handleChange = e => {
    const name = e.target.name;
    const val = e.target.value;

    if (name === 'qual_note') pmr_form.matcher_notes = val

    data[name] = val;

    updateState(data);
  }

  const handleCommentsChange = e => {
    const name = e.target.name;
    const val = e.target.value;

    data[name] = val;
    data.pmr_form[name] = val;
    updateState(data);
  }

  const handleCheckChange = e => {
    lead[ e.target.name ] = e.target.checked;
    updateState(data);
  }

  const highlightIntro = () => {
    EventBus.emit('highlight:intro');
  }

  const highlightQuestions = () => {
    EventBus.emit('highlight:questions');
  }

  const highlightClosing = () => {
    EventBus.emit('highlight:closing');
  }

  const twoFAInfo = () => {
    return(
      <div className='twofa-box'>
        <div className='twofa-text'>2FA</div>
        <div className='twofa-logo'></div>
      </div>
    )
  }

  const googleSearch = e => {
    const key = e.target.parentElement.className.slice(-1)
    const buyer_city = `buyer_city_${key}`
    const buyer_state = `buyer_state_${key}`

    window.open(`https://www.google.com/search?q=${lead[buyer_city]}+${lead[buyer_state]}`)
  }

  const onBuyerCityClick = e => {
    let num = $(e.target).data('num');
    if (num === 3) { return false; }
    const nextIndex = num += 1;

    lead.buyer_cities += '& , ';
    lead[`buyer_city_${nextIndex}`] = '';
    lead[`buyer_state_${nextIndex}`] = '';
    $('[data-num="' + num + '"]').hide();
    updateState(data);
  }

  /**
   * Renders sending agent content if present
   * @return {Object} HTML to be rendered
   */
  const renderSendingAgentContent = () => {
    const {
      id,
      name,
      company,
      city,
      state,
      lat,
      lng,
    } = sending_agent;

    if (Object.keys(sending_agent).length) {
      return(
        <div className={ rex_referral ? 'content_header' : 'hidden' }>
          <i className='fa fa-paper-plane'></i>
          Sending Agent Info:
          <span>
            <div className='sending-agent-info'>
              <a href={ `/agents/${id}` } target='_blank'>{ name } - { company }</a> <br />
              <a
                className='sending-agent-location'
                href={ `https://www.google.com/maps/place/${city.replace(/\s/g, '+')}+${state}/@${lat},${lng},14z` }
                target='_blank'
              >
                {`${city}, ${state}`}
              </a>
            </div>
          </span>
        </div>
      );
    }
  }

  const exclusiveOrDirectIcon = () => {
    let icon = ExclusiveLogo;
    let referralType = 'exclusive';
    let text = 'directed with this agent only'

    if (directReferral) {
      icon = DirectLogo;
      referralType = 'direct';
      text = 'to this agent plus other agent options';
    }

    if ((exclusive || directReferral) && exclusiveAgentId){
      return (
        <div
          className='exclusive-logo-qq'
          >
          <img className='exclusive-logo-img-qq' src={ icon } />
          <div className='exclusive-text-qq'>
            <p>This { referralType } referral resulted from a consumer visiting Agent <a href={ exclusiveAgentLink } target='_blank'>{ exclusiveAgentId }</a> REA.com page and asking to be connected { text }</p>
          </div>
        </div>
      )
    }
  }

  const buildLabel = (text, defaultData) =>  {
    const domEls = [text];

    if (defaultData) domEls.push(<span className="default-stand-in">DEFAULT</span>);

    return domEls;
  }

  const buyerCityField = (_buyerCity, i) =>  {
    let classString = 'buyer_city bc_option_' + i;
    let name = 'buyer_city_' + i;
    let state = 'buyer_state_' + i;
    let stateType = lead.country == 'CA'
      ? provinces
      : states;

    return (
      <div className={classString} key={i}>
        <input
          data-optional='true'
          type='text'
          name={name}
          value={lead[`buyer_city_${i}`] || ''}
          onFocus={highlightIntro}
          onChange={handleLeadChange}
          className={ displayBuyerCitiesDefault() ? 'w240 has-stand-in-data' : 'w240' }
        />
        <select data-optional='true'
          name={state}
          value={lead[`buyer_state_${i}`].trim() || ''}
          onFocus={highlightIntro}
          onChange={handleLeadChange}
          className={ displayBuyerCitiesDefault() ? 'has-stand-in-data' : '' }
        >
          <option value=""></option>
          {
            stateType.map((state, idx) => (
              <option key={idx} value={state}>{state}</option>
            ))
          }
        </select>
        <img
          src={window.image_path('google-icon')}
          className='icon'
          onClick={googleSearch} />
        <i
          className={i === 3 ? 'hidden' : 'fa fa-plus-circle'}
          data-num={i}
          onClick={onBuyerCityClick}
        />
        <div className='clear'></div>
      </div>
    )
  }

  return(
    <div>
      <div className='content_header'>
        <i className='fa fa-user' />
        Buyer Lead Info:
        <span> {`${lead.first_name} ${lead.last_name}`}</span>
        &nbsp; &nbsp;
        <span className='country-header-outer'>
          <span className={lead.country === 'CA' ? 'country-header tg ca' : 'country-header tg us'} />
        </span>
        { exclusiveOrDirectIcon() }
        <a href={ `${window.location.protocol}//${window.location.hostname}/leads/${lead.id}` } target='_blank' className='lead_id_link'>{ lead.id }</a>
      </div>

      { renderSendingAgentContent() }

      <div className='content_form'>
        <div className='field odd'>
          <div className='label'>
            First Name
          </div>
          <input
            type='text'
            name='first_name'
            value={ lead.first_name }
            onChange={ handleLeadChange }
            onFocus={ highlightIntro }
            />
        </div>
        <div className='field'>
          <div className='label'>
            Last Name
          </div>
          <input
            type='text'
            name='last_name'
            value={ lead.last_name }
            onChange={ handleLeadChange }
            onFocus={ highlightIntro }
            />
        </div>
        <div className='clear'></div>

        {
          displayBuyerCitiesDefault() &&
          <div>
            <div className='field'>
              <div className='label'>
                { buildLabel(null, displayBuyerCitiesDefault()) }
              </div>
            </div>
            <div className='clear'></div>
          </div>
        }

        <div className='buyer_cities_field'>
          <div className='label'>
            Buyer Cities
            <span className={validations.invalid_location ? 'invalid-location' : 'invalid-location hidden'}>INVALID LOCATION</span>
          </div>
          <div className='cities-container'>
            <div className={ displayBuyerCitiesDefault() ? 'fields has-stand-in-data' : 'fields' }>
              { lead.buyer_cities.split( '&' ).map((city, idx) => buyerCityField(city, idx)) }
            </div>
          </div>
        </div>
        <div className='clear'></div>

        <div className='field odd phone_fields'>
          <div className='phone_field'>
            <div className={ lead.twofa_at ? 'label twofa' : 'label' }>
              Phone
              <span className={validations.phone_number_valid && !validations.blacklisted_reasoning ? 'invalid-phone hidden' : 'invalid-phone'}>{ validations.blacklisted_reasoning || 'INVALID PHONE' }</span>
              { lead.twofa_at ? twoFAInfo() : null }
            </div>
            <input
              type='tel'
              className={ blacklisted ? 'blacklisted' : '' }
              className='phone_number'
              name='phone_number_national' value={ lead.phone_number_national }
              onChange={ handleLeadChange }
              onFocus={ highlightIntro }
              id='intl_phone_target'
              maxLength={20}
              />
          </div>
          <div className='click-to-call-button'>
              {click2CallLink(lead.phone_number_base)}
            </div>
          <div className='extension_field'>
            <div className='label'>
              Extension
            </div>
            <input
              type='digits'
              name='extension' value={ lead.extension }
              className='extension'
              onChange={ handleLeadChange }
              onFocus={ highlightIntro }
              maxLength={10}
              />
          </div>
        </div>
        <div className='clear'></div>

        {
          displayPriceDefault() &&
          <div>
            <div className='field'>
              <div className='label'>
                { buildLabel(null, (!!min_price || !!max_price)) }
              </div>
            </div>
            <div className='clear'></div>
          </div>
        }

        <div className='field odd hp price-range-field'>
          <div className='label'>
            Price Range
            <span className={highendClass}>HIGH END</span>
          </div>
          <div className='clear'></div>
          <input
            type='text'
            name='home_price_min'
            className={ displayPriceDefault() ? 'price-range price-range-left has-stand-in-data' : 'price-range price-range-left' }
            data-optional='true'
            value={ numeral(lead.home_price_min).format('0,0') }
            onChange={ handleLeadChange }
            onFocus={ highlightIntro }
            placeholder='Min'
            />
          <input
            type='text'
            name='home_price_max'
            className={ displayPriceDefault() ? 'price-range has-stand-in-data' : 'price-range' }
            data-optional='true'
            value={ numeral(lead.home_price_max).format('0,0') }
            onChange={ handleLeadChange }
            onFocus={ highlightIntro }
            placeholder='Max'
            />
        </div>
        <div className='field selectdd'>
          <div className='label'>
            Mortgage Status
          </div>
          <select
            name='mortgage_status'
            value={ lead.mortgage_status || '' }
            onChange={ handleLeadChange }
            onFocus={ highlightQuestions }
          >
            { mortgage_status.options.map((option, idx) => (
              <option key={idx} value={option}>{option}</option>
            )) }
          </select>
        </div>
        <div className='clear'></div>
        <div className='field odd selectdd'>
          <div className='label'>
            Min Beds
          </div>
          <select
            data-optional='true'
            name='beds'
            value={ lead.beds || '' }
            onChange={ handleLeadChange }
            onFocus={ highlightQuestions }
          >
            <option value=''>--</option>
            <option value='0'>0</option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
            <option value='7'>7</option>
          </select>
        </div>
        <div className='field selectdd'>
          <div className='label'>
            Min Baths
          </div>
          <select
            data-optional='true'
            name='baths'
            value={lead.baths || '' }
            onChange={ handleLeadChange }
            onFocus={ highlightQuestions }
          >
            <option value=''>--</option>
            <option value='0'>0</option>
            <option value='1'>1</option>
            <option value='1.5'>1.5</option>
            <option value='2'>2</option>
            <option value='2.5'>2.5</option>
            <option value='3'>3</option>
            <option value='3.5'>3.5</option>
            <option value='4'>4</option>
            <option value='4.5'>4.5</option>
            <option value='5'>5</option>
          </select>
        </div>
        <div className='field odd selectdd'>
          <div className='label'>
            Time Frame
          </div>
          <select
            data-optional='true'
            name='time_frame'
            value={lead.time_frame || ''}
            onChange={handleLeadChange}
            onFocus={highlightQuestions}
          >
            <option value=''>--</option>
            <option value='1 - 2 weeks'>1 - 2 weeks</option>
            <option value='2 - 3 weeks'>2 - 3 weeks</option>
            <option value='3 - 4 weeks'>3 - 4 weeks</option>
            <option value='1 - 2 months'>1 - 2 months</option>
            <option value='2 - 3 months'>2 - 3 months</option>
            <option value='3 - 6 months'>3 - 6 months</option>
            <option value='6 - 9 months'>6 - 9 months</option>
            <option value='More than 9 months'>More than 9 months</option>
          </select>
        </div>
        <div className='field selectdd'>
          <div className='label'>
            Property Type
          </div>
          <select
            name='type_home' value={lead.type_home || '' }
            onChange={ handleLeadChange }
            onFocus={ highlightQuestions }
          >
            <option>Choose One</option>
            <option value='Single Family'>Single Family</option>
            <option value='Condominium'>Condominium</option>
            <option value='Town Home'>Town Home</option>
            <option value='Mobile Home'>Mobile Home with land</option>
            <option value='2-4 Units'>2-4 Units</option>
            <option value='Vacation Home'>Vacation Home</option>
            <option value='Rental'>Rental</option>
            <option value='Co-op'>Co-op</option>
            <option value='Loft'>Loft</option>
            <option value='Land/Lot'>Land/Lot</option>
            <option value='Unknown'>Unknown</option>
          </select>
        </div>
        <div className='clear'></div>
        <div className='field odd'>
          <div className={ lead.twofa_email_at ? 'label twofa' : 'label' }>
            Confirm Email *
            { lead.twofa_email_at ? twoFAInfo() : null }
          </div>
          <input
            type='email'
            name='email'
            value={ email }
            onChange={ handleChange }
            onFocus={ highlightQuestions }
            />
        </div>
        <div className='field checkboxes'>
          <input
            checked={lead.opt_out}
            type="checkbox"
            data-optional='true'
            name='opt_out'
            id='opt_out'
            onChange={ handleCheckChange }
          />
          <label htmlFor="opt_out">
            Opt Out
          </label>
        </div>
        <div className='field checkboxes'>
          <input
            checked={lead.commercial}
            type="checkbox"
            data-optional='true'
            id="commercial"
            name='commercial'
            onChange={ handleCheckChange }
          />
          <label htmlFor="commercial">
            Commercial
          </label>
        </div>
        <div className='clear'></div>
        <div className='field selectdd'>
          <div className='label'>
            What is the client's preferred language?
          </div>
          <select
            name='language_preference' value={lead.language_preference || '' }
            onChange={ handleLeadChange }
            onFocus={ highlightQuestions }
          >
            <option value='en'>English</option>
            <option value='es'>Spanish</option>
            <option value='fr'>French</option>
          </select>
        </div>
        <div className='clear'></div>
        <div className='field full'>
          <div className='label'>
            Qual Note
          </div>
          <textarea
            data-optional='true'
            name='qual_note'
            onChange={handleChange}
            value={pmr_form.matcher_notes || '' }
            onFocus={ highlightQuestions }
          />
        </div>
        <div className='clear'></div>
          <div className='field full'>
            <div className='label'>
              {
                origin.indexOf( 'Home Captain' ) !== -1
                  ? 'Client Comments (will be viewed as a custom message to agents) - Include lender info'
                  : 'Client Comments (will be viewed as a custom message to agents)'
              }
            </div>
          <textarea
            data-optional='true'
            name='comments'
            onChange={handleCommentsChange}
            value={comments || ''}
            onFocus={highlightClosing}
          />
          </div>
      </div>
    </div>
  )
}

export default BuyerForm;
