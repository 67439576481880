import StringFormatter from '../../../../../../../../../common/String';

const stringFormatter = new StringFormatter;

const buildData = email => {
  const data = [];

  const {
    id,
    email_type,
    to_email,
    from_email,
    created_at,
    status,
  } = email;

  const emailPath = `/emails/${id}/message`;

  data.push(<td>{ to_email }</td>);
  data.push(<td>{ from_email }</td>);
  data.push(<td>{ email_type }</td>);
  data.push(<td>{ moment(created_at).format('MM/DD/YYYY') }</td>);
  data.push(<td><a className="btn btn-xs btn-default" data-remote="true" href={ emailPath }>{ stringFormatter.titleCase(status) }</a></td>);

  return data;
};

const buildRows = emails => {
  const rows = [];

  emails.forEach(email => rows.push(<tr data-email-id={ email.id }>{ buildData(email) }</tr>));

  return rows;
};

const Body = ({ emails }) => (
  <tbody>
    { buildRows(emails) }
  </tbody>
);

export default Body;
