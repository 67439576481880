import React from 'react';
import ReactDOM from 'react-dom';
import ProductSalesHistoryContainer from './Container';

/**
 * Initialize the ProductSalesHistory tool.
 */

export function initialize(containerElement, props) {
  let component = React.createElement(ProductSalesHistoryContainer, props);
  ReactDOM.render(component, containerElement);
}
