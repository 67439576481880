import React from 'react';
import Fail  from './images/fail.png'

const CancelReactivateFailView = (props) => {
  const {
    errorMessage,
    toggleModal,
  } = props;

  return (
    <div className='toggle-live-subscription'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ Fail } />
      </div>
      <div className='rex-modal-heading'>
        Payment Failed
      </div>
      <div className='rex-modal-contents'>
        <div className='rex-modal-error'>
          { errorMessage }
        </div>
        <div className='rex-modal-subheading'>
          Please assist the agent in updating the card they have on file and try again.
        </div>
        <div className='rex-modal-actions rex-modal-actions__centered'>
          <button
            className='rex-modal-action rex-modal-actions__centered'
            onClick={ () => toggleModal(false) }
          >
            ClOSE THIS WINDOW
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelReactivateFailView;
