import { Component } from 'react';

export default class EditLeadInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lead: props.lead,
      stagedLead: {...props.lead}
    };

    this.updateLeadInfo         = this.updateLeadInfo.bind(this);
    this.stageLeadChanges       = this.stageLeadChanges.bind(this);
    this.submitLeadInfoChanges  = this.submitLeadInfoChanges.bind(this);
    this.updateE164PhoneNumber  = this.updateE164PhoneNumber.bind(this);
  }

  updateLeadInfo(e) {
    var id = e.target.id;
    var data = this.state.lead;
    data[id] = e.target.value;
    this.setState({lead: data});
  }

  stageLeadChanges(e) {
    var id = e.target.id;
    var data = this.state.stagedLead;
    data[id] = e.target.value;
    this.setState({stagedLead: data});
  }

  // Callback triggered when phone_number or selected country changes
  updateE164PhoneNumber(_, iti) {
    var data = this.state.stagedLead;
    data.phone_number = iti.getE164PhoneNumber();
    this.setState({stagedLead: data});
  }

  _parsePrice( price ) {
    return numeral(price).format('$0,0[.]00')
  }

  componentDidMount() {
    const selectors = ['#phone_number_national'];
    const countries = [this.state.stagedLead.phone_number_country || 'US'];
    Util.initPhoneCountrySelectors(selectors, countries, this.updateE164PhoneNumber)
  }

  submitLeadInfoChanges(e) {
    $('#update-lead-button').prop('disabled', true);
    $('#update-lead-button').html('UPDATING...');
    let parsed_buyer_cities = (this.props.lead.lead_type == 'BUYER' ? this.props.parseBuyerCities : null);

    // For some reason the comma moves to wrong place, parse just digits
    var pattern = /\d/g;
    var parsedHomePriceMin = this.state.stagedLead.home_price_min.match(pattern).join('');
    var parsedHomePriceMax = this.state.stagedLead.home_price_max.match(pattern).join('');
    const extension = this.state.stagedLead.extension;
    const ext = (!!extension ? `ext${extension}` : '' );
    this.state.stagedLead.phone_number += ext;
    this.state.stagedLead.home_price_min = parsedHomePriceMin;
    this.state.stagedLead.home_price_max = parsedHomePriceMax;

    this.serverRequest = $.ajax({
      type: 'POST',
      url: '/dialer/profile_review/update_lead',
      data: { lead: this.state.stagedLead, formatted_buyer_cities: parsed_buyer_cities },
      context: this,
      success: data => {
        this.props.updateLeadInfo(this.state.stagedLead);
      },
      error: data => {
        this.showErrors(data);
      }
    });
  }

  showErrors(responseData) {
    $('#update-lead-button').prop('disabled', false);
    $('#update-lead-button').html('UPDATE');

    $('.form-error').hide();
    $('.form-error').html('');

    errors = responseData['responseJSON']['errors'];
    for (var key in errors) {
      var element = $(`#form-error-${key}`)
      element.show();
      element.html(errors[key]);
    }
  }

  renderBuyerInfo() {
    const buyer = this.props.dual_transaction_buyer;
    if (this.props.dual_transaction) {
      return (
        <div className='edit-lead-info-row'>
          <div className='col-lg-3 height-100 border-box no-left-padding'>
            <div className='attribute'>Looking In</div>
            <div className='value'>{ buyer.buyer_cities.split('&')[0].replace(',', ', ') }</div>
          </div>
          <div className='col-lg-3 height-100 border-box'>
            <div className='attribute'>Min Beds</div>
            <div className='value'>{ buyer.beds ? buyer.beds : '--' }</div>
          </div>
          <div className='col-lg-3 height-100 border-box'>
            <div className='attribute'>Min Baths</div>
            <div className='value'>{ buyer.baths ? buyer.baths : '--' }</div>
          </div>
          <div className='col-lg-3 height-100 border-box no-right-padding'>
            <div className='attribute'>Mortgage Status</div>
            <div className='value'>{ buyer.mortgage_status ? buyer.mortgage_status : '--' }</div>
          </div>
        </div>
      );
    }
  }

  renderForm() {
    if(this.state.lead.lead_type === 'BUYER') {
      return (
        <div className='top-container'>
          <div className='edit-lead-info-row'>
            <div className='col-lg-2 height-100 border-box no-left-padding'>
              <div className='attribute'>First Name</div>
              <input id='first_name' className='border-box' value={this.state.stagedLead.first_name} onChange={this.stageLeadChanges}/>
            </div>
            <div className='col-lg-3 height-100 border-box'>
              <div className='attribute'>Last Name</div>
              <input id='last_name' className='border-box' value={this.state.stagedLead.last_name} onChange={this.stageLeadChanges}/>
            </div>
            <div className='col-lg-3 height-100 border-box'>
              <div className='attribute'>Phone</div>
              <input id='phone_number_national' className='border-box' value={this.state.stagedLead.phone_number_national} onChange={this.stageLeadChanges}/>
            </div>
            <div className='col-lg-1 height-100 border-box'>
              <div className='attribute'>Ext</div>
              <input id='extension' className='border-box' value={this.state.stagedLead.extension} onChange={this.stageLeadChanges}/>
            </div>
            <div className='col-lg-3 height-100 border-box no-right-padding'>
              <div className='attribute'>Email</div>
              <span id='form-error-email' className='form-error'></span>
              <input id='email' className='border-box' value={this.state.stagedLead.email} onChange={this.stageLeadChanges}/>
            </div>
          </div>
          <div className='edit-lead-info-row'>
            <div className='col-lg-3 height-100 border-box no-left-padding'>
              <div className='attribute'>Time Frame</div>
              <div className='relative select-box'>
                <select id='time_frame' className='border-box' value={this.state.stagedLead.time_frame} onChange={this.stageLeadChanges}>
                  {
                    FormHelper.timeFrameOptions().map(function(option, idx) {
                      if(idx == 0) {
                        return(
                          <option value={option} key={idx}>-- SELECT --</option>
                        );
                      } else {
                        return(
                          <option value={option} key={idx}>{option}</option>
                        );
                      }
                    })
                  }
                </select>
              </div>
            </div>
            <div className='col-lg-3 height-100 border-box'>
              <div className='attribute'>Home Type</div>
              <div className='relative select-box'>
                <select id='type_home' className='border-box' value={this.state.stagedLead.type_home} onChange={this.stageLeadChanges}>
                  {
                    FormHelper.homeTypeOptions().map(function(option, idx) {
                      if(idx == 0) {
                        return(
                          <option value={option} key={idx}>-- SELECT --</option>
                        );
                      } else {
                        return(
                          <option value={option} key={idx}>{option}</option>
                        );
                      }
                    })
                  }
                </select>
              </div>
            </div>
            <div className='col-lg-2 height-100 border-box'>
              <div className='attribute'>Price Range</div>
              <div className='relative'>
                <input
                  id='home_price_min'
                  className='border-box'
                  value={numeral(this.state.stagedLead.home_price_min).format('0,0')}
                  onChange={this.stageLeadChanges}
                  placeholder='Min'
                />
              </div>
            </div>
            <div className='col-lg-2 height-100 border-box'>
              <div className='relative'>
              <div className='attribute'></div>
                <input
                  id='home_price_max'
                  className='border-box'
                  value={numeral(this.state.stagedLead.home_price_max).format('0,0')}
                  onChange={this.stageLeadChanges}
                  placeholder='Max'
                />
              </div>
            </div>
            <div className='col-lg-2 height-100 border-box no-right-padding'>
              <div className='attribute'>Max Agents</div>
              <div className='relative select-box'>
                <select id='max_agents' className='border-box' value={this.state.stagedLead.max_agents} onChange={this.stageLeadChanges}>
                  {
                    FormHelper.maxAgentOptions().map(function(option, idx) {
                      if(idx == 0) {
                        return(
                          <option value={option} key={idx}>-- SELECT --</option>
                        );
                      } else {
                        return(
                          <option value={option} key={idx}>{option}</option>
                        );
                      }
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div className='edit-lead-info-row'>
            <div className='col-lg-3 height-100 border-box no-left-padding'>
              <div className='attribute'>Looking in</div>
              <input id='buyer_city1' className='border-box inline-block location-input' value={this.state.stagedLead.buyer_city1} onChange={this.stageLeadChanges}/>
              <div className='relative location-select inline-block'>
                <select id='buyer_state1' className='border-box width-100' value={this.state.stagedLead.buyer_state1} onChange={this.stageLeadChanges}>
                  {
                    FormHelper.stateOptions().map(function(option, idx) {
                      if(idx == 0) {
                        return(
                          <option value={option} key={idx}>--</option>
                        );
                      } else {
                        return(
                          <option value={option} key={idx}>{option}</option>
                        );
                      }
                    })
                  }
                </select>
              </div>
            </div>
            <div className='col-lg-3 height-100 border-box'>
              <div className='attribute'></div>
              <input id='buyer_city2' className='border-box inline-block location-input' value={this.state.stagedLead.buyer_city2} onChange={this.stageLeadChanges}/>
              <div className='relative location-select inline-block'>
                <select id='buyer_state2' className='border-box width-100' value={this.state.stagedLead.buyer_state2} onChange={this.stageLeadChanges}>
                  {
                    FormHelper.stateOptions().map(function(option, idx) {
                      if(idx == 0) {
                        return(
                          <option value={option} key={idx}>--</option>
                        );
                      } else {
                        return(
                          <option value={option} key={idx}>{option}</option>
                        );
                      }
                    })
                  }
                </select>
              </div>
            </div>
            <div className='col-lg-3 height-100 border-box'>
              <div className='attribute'></div>
              <input id='buyer_city3' className='border-box inline-block location-input' value={this.state.stagedLead.buyer_city3} onChange={this.stageLeadChanges}/>
              <div className='relative location-select inline-block'>
                <select id='buyer_state3' className='border-box width-100' value={this.state.stagedLead.buyer_state3} onChange={this.stageLeadChanges}>
                  {
                    FormHelper.stateOptions().map(function(option, idx) {
                      if(idx == 0) {
                        return(
                          <option value={option} key={idx}>--</option>
                        );
                      } else {
                        return(
                          <option value={option} key={idx}>{option}</option>
                        );
                      }
                    })
                  }
                </select>
              </div>
            </div>
            <div className='col-lg-3 height-100 border-box no-right-padding'>
              <div className='attribute'></div>
              <input id='buyer_city4' className='border-box inline-block location-input' value={this.state.stagedLead.buyer_city4} onChange={this.stageLeadChanges}/>
              <div className='relative location-select inline-block'>
                <select id='buyer_state4' className='border-box width-100' value={this.state.stagedLead.buyer_state4} onChange={this.stageLeadChanges}>
                  {
                    FormHelper.stateOptions().map(function(option, idx) {
                      if(idx == 0) {
                        return(
                          <option value={option} key={idx}>--</option>
                        );
                      } else {
                        return(
                          <option value={option} key={idx}>{option}</option>
                        );
                      }
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div className='edit-lead-info-row'>
            <div className='col-lg-12 height-100 border-box no-left-padding no-right-padding'>
              <div className='attribute'>Comments</div>
              <textarea id='comments' value={this.state.stagedLead.comments} onChange={this.stageLeadChanges}></textarea>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='top-container'>
          <div className='edit-lead-info-row'>
            <div className='col-lg-3 height-100 border-box no-left-padding'>
              <div className='attribute'>First Name</div>
              <input id='first_name' className='border-box' value={this.state.stagedLead.first_name} onChange={this.stageLeadChanges}/>
            </div>
            <div className='col-lg-3 height-100 border-box'>
              <div className='attribute'>Last Name</div>
              <input id='last_name' className='border-box' value={this.state.stagedLead.last_name} onChange={this.stageLeadChanges}/>
            </div>
            <div className='col-lg-3 height-100 border-box'>
              <div className='attribute'>Phone</div>
              <input id='phone_number' className='border-box' value={this.state.stagedLead.phone_number} onChange={this.stageLeadChanges}/>
            </div>
            <div className='col-lg-3 height-100 border-box no-right-padding'>
              <div className='attribute'>Email</div>
              <span id='form-error-email' className='form-error'></span>
              <input id='email' className='border-box' value={this.state.stagedLead.email} onChange={this.stageLeadChanges}/>
            </div>
          </div>
          <div className='edit-lead-info-row'>
            <div className='col-lg-3 height-100 border-box no-left-padding'>
              <div className='attribute'>Time Frame</div>
              <div className='relative select-box'>
                <select id='time_frame' className='border-box' value={this.state.stagedLead.time_frame} onChange={this.stageLeadChanges}>
                  {
                    FormHelper.timeFrameOptions().map(function(option, idx) {
                      if(idx == 0) {
                        return(
                          <option value={option} key={idx}>-- SELECT --</option>
                        );
                      } else {
                        return(
                          <option value={option} key={idx}>{option}</option>
                        );
                      }
                    })
                  }
                </select>
              </div>
            </div>
            <div className='col-lg-3 height-100 border-box'>
              <div className='attribute'>Home Type</div>
              <div className='relative select-box'>
                <select id='type_home' className='border-box' value={this.state.stagedLead.type_home} onChange={this.stageLeadChanges}>
                  {
                    FormHelper.homeTypeOptions().map(function(option, idx) {
                      if(idx == 0) {
                        return(
                          <option value={option} key={idx}>-- SELECT --</option>
                        );
                      } else {
                        return(
                          <option value={option} key={idx}>{option}</option>
                        );
                      }
                    })
                  }
                </select>
              </div>
            </div>
            <div className='col-lg-2 height-100 border-box'>
              <div className='attribute'>Price Range</div>
              <div className='relative'>
                <input
                  id='home_price_min'
                  className='border-box'
                  value={numeral(this.state.stagedLead.home_price_min).format('0,0')}
                  onChange={this.stageLeadChanges}
                  placeholder='Min'
                />
              </div>
            </div>
            <div className='col-lg-2 height-100 border-box'>
              <div className='relative'>
              <div className='attribute'></div>
              <input
                id='home_price_max'
                className='border-box'
                value={numeral(this.state.stagedLead.home_price_max).format('0,0')}
                onChange={this.stageLeadChanges}
                placeholder='Max'
              />
              </div>
            </div>
            <div className='col-lg-2 height-100 border-box no-right-padding'>
              <div className='attribute'>Max Agents</div>
              <div className='relative select-box'>
                <select id='max_agents' className='border-box' value={this.state.stagedLead.max_agents} onChange={this.stageLeadChanges}>
                  {
                    FormHelper.maxAgentOptions().map(function(option, idx) {
                      if(idx == 0) {
                        return(
                          <option value={option} key={idx}>-- SELECT --</option>
                        );
                      } else {
                        return(
                          <option value={option} key={idx}>{option}</option>
                        );
                      }
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div className='edit-lead-info-row'>
            <div className='col-lg-2 height-100 border-box no-left-padding'>
              <div className='attribute'>Street #</div>
              <input id='street_number' className='border-box' value={this.state.stagedLead.street_number} onChange={this.stageLeadChanges}/>
            </div>
            <div className='col-lg-3 height-100 border-box no-left-padding'>
              <div className='attribute'>Street Name</div>
              <input id='street_name' className='border-box' value={this.state.stagedLead.street_name} onChange={this.stageLeadChanges}/>
            </div>
            <div className='col-lg-1 height-100 border-box no-left-padding'>
              <div className='attribute'>Unit #</div>
              <input id='unit_number' className='border-box' value={this.state.stagedLead.unit_number} onChange={this.stageLeadChanges}/>
            </div>
            <div className='col-lg-3 height-100 border-box'>
              <div className='attribute'>City</div>
              <input id='city' className='border-box inline-block width-100' value={this.state.stagedLead.city} onChange={this.stageLeadChanges}/>
            </div>
            <div className='col-lg-1 height-100 border-box no-left-padding no-right-padding'>
              <div className='attribute'>State</div>
              <div className='relative select-box'>
                <select id='state' className='border-box' value={this.state.stagedLead.state} onChange={this.stageLeadChanges}>
                  {
                    FormHelper.stateOptions().map(function(option, idx) {
                      if(idx == 0) {
                        return(
                          <option value={option} key={idx}>-- SELECT --</option>
                        );
                      } else {
                        return(
                          <option value={option} key={idx}>{option}</option>
                        );
                      }
                    })
                  }
                </select>
              </div>
            </div>
            <div className='col-lg-1 height-100 border-box no-right-padding'>
              <div className='attribute'>Zip</div>
              <input id='zip' className='border-box inline-block' value={this.state.stagedLead.zip} onChange={this.stageLeadChanges}/>
            </div>
          </div>
          {this.renderBuyerInfo()}
          <div className='edit-lead-info-row'>
            <div className='col-lg-12 height-100 border-box no-left-padding no-right-padding'>
              <div className='attribute'>Comments</div>
              <textarea id='comments' value={this.state.stagedLead.comments} onChange={this.stageLeadChanges}></textarea>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className='cs-overshadow'>
        <div className='profile-edit-lead-container relative'>
          <div className='close absolute'>
            <i onClick={this.props.toggleEditContainer} className='fa fa-times'></i>
          </div>
          {this.renderForm()}
          <div className='bottom-container'>
            <button id='update-lead-button' className='edit' onClick={this.submitLeadInfoChanges}>UPDATE</button>
          </div>
        </div>
      </div>
    );
  }
}
