import { Component } from 'react';

export default class ScheduleRowAssociate extends Component {
  constructor(props){
    super(props);
    this.state = {
      toggleInfo: false
    }
    this.toggleInfo = this.toggleInfo.bind(this);
    this.claimAppointment = this.claimAppointment.bind(this);
  }

  componentDidMount() {
    if(this.props.scheduleRow){
      this.setState({ scheduleRow: this.props.scheduleRow }, () => {
        this.toggleOpenOnInit();
      })
    }
    if(this.props.time) this.listenUpdateSchedule();
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.time !== this.props.time){
      this.unmountListenUpdateSchedule();
      this.listenUpdateSchedule(nextProps.time);
    };

    this.setState({ scheduleRow: nextProps.scheduleRow }, () => {
      this.toggleOpenOnInit();
    })
  }

  componentWillUnmount(){
    this.unmountListenUpdateSchedule()
  }

  listenUpdateSchedule(time = this.props.time){
    $(document).on('updateSchedule', '#schedule-row-container-' + time, () => {
      this.updateTimeSlotSchedule(time);
    });
  }

  unmountListenUpdateSchedule(){
    $(document).off('updateSchedule', '#schedule-row-container-' + this.props.time, () => {
      this.updateTimeSlotSchedule();
    });
  }

  updateTimeSlotSchedule(time = this.props.time){
    let params = { time: time };
    $.get('/customer_success/schedule_row', params, (result) => {
      this.setState({ scheduleRow: result.schedule_row })
    })
  }

  toggleInfo() {
    this.setState({ toggleInfo: !this.state.toggleInfo });
  }

  toggleOpenOnInit(){
    // only expands the not expired time slots of the current user, collapse everything else
    let assignees = this.assigneesOfAppointments()
    if(assignees === undefined) return;

    if(assignees.includes(this.props.currentUser.id)
      && parseInt(this.props.time) + 1799 > Date.now()/1000) {
      this.setState({ toggleInfo: true });
    } else {
      this.setState({ toggleInfo: false });
    }
  }

  assigneesOfAppointments() {
    // returns all the sales team id's
    let { scheduleRow } = this.state;
    if(!scheduleRow.appointments) return;
    return scheduleRow.appointments.reduce((result, appointment) => {
      if(appointment.assigned_to) result.push(appointment.assigned_to);
      return result;
    },[])
  }

  parseFirstName(name) {
    if(name && name.length > 10) {
      return name.substring(0, 10) + '..';
    } else {
      return name;
    }
  }

  fetchAgent(appointment) {
    event.stopPropagation();
    event.preventDefault();
    this.props.fetchAgent(appointment.agent_id);
  }

  claimButtonId() {
    return 'appointment-claim-button-' + this.state.time
  }

  unClaimCountId() {
    return 'appointment-unclaim-count-' + this.state.time
  }

  unclaimedAppointmentCount() {
    let { scheduleRow } = this.state;
    if(scheduleRow.appointments === undefined) return;
    let unclaimed = scheduleRow.appointments.filter((appt) => {
      return !appt.assigned_to;
    });
    return unclaimed.length;
  }

  associateClaimed() {
    if(this.state.scheduleRow.appointments === undefined) return;
    var claimed = this.state.scheduleRow.appointments.filter((appt) => {
      return appt.assigned_to == this.props.currentUser.id;
    });
    return claimed.length;
  }

  currentAppointmentForAssignee() {
    if(this.state.scheduleRow.appointments === undefined) return;
    var filtered = this.state.scheduleRow.appointments.filter((appt) => {
      return appt.assigned_to == this.props.currentUser.id;
    });
    return filtered[0];
  }

  claimAppointment(e) {
    e.preventDefault();
    e.stopPropagation();
    let { updateAppointmentAssignee, currentUser, fetchAgent } = this.props;
    let scheduleRow = this.state.scheduleRow;
    let openIndex = scheduleRow.appointments;
    let appointment = scheduleRow.appointments[this.openIndex()];
    updateAppointmentAssignee(appointment, currentUser.id);
    fetchAgent(appointment.taskable_id);
    this.setState({ toggleInfo: true });
  }

  openIndex(){
    let idx;
    let appt = this.state.scheduleRow.appointments;
    for (let i = 0; i < appt.length; i++) {
      if(appt[i].status === 'incomplete' && appt[i].assigned_to === null) {
        idx = i;
        break;
      }
    }
    return idx;
  }

  appointmentClass(){
    let klass = 'cs-schedule-container'
    let { currentUser, isCurrentAppointment } = this.props;
    let assignees = this.state.scheduleRow.extra_appointment_info.map((appt) => appt.assignee_id)

    if(assignees && assignees.includes(currentUser.id)) klass += ' involved';
    if(isCurrentAppointment) klass += ' current';
    return klass;
  }

  validTimespan(){
    let appointmentTime = parseInt(this.props.time);
    let currentTime = (new Date()).getTime()/1000
    let tenMinutesBefore = appointmentTime - 600;
    let thirtyMinutesAfter = appointmentTime + 1800;
    // return currentTime < appointmentTime;
    return tenMinutesBefore < currentTime && currentTime < thirtyMinutesAfter;
  }

  underAppointmentLimit(){
    let { currentUser } = this.props;
    let { scheduleRow } = this.state;
    let count = 0;
    scheduleRow.appointments.forEach(appt => {
      if(appt.assigned_to === currentUser.id && appt.status === 'incomplete') count++;
    })
    return count < 1;
  }

  renderHeader(){
    let unclaimedCount = this.unclaimedAppointmentCount();
    if(unclaimedCount && this.validTimespan() && this.underAppointmentLimit()){
      return(
        <div className='cs-schedule-slot' onClick={this.toggleInfo}>
          <div className='cs-schedule-slot-time'> {this.state.scheduleRow.pretty_time} </div>
          <div className='cs-schedule-slot-available'>
            Available
            <div className='number-bubble-outer'>
              <div className='number-bubble'>
                {unclaimedCount}
              </div>
            </div>
          </div>

          { this.claimButton() }
          { this.renderInfoToggler() }
        </div>
      )
    } else {
      return(
        <div className='cs-schedule-slot' onClick={this.toggleInfo}>
          <div> {this.state.scheduleRow.pretty_time} </div>
          {this.renderInfoToggler()}
        </div>
      )
    }
  }

  claimButton(){
    if(this.unclaimable()){
      return <input type='submit' className='claim-button-disabled' disabled value='Claim' />
    } else {
      return <input type='submit' className='claim-button' onClick={this.claimAppointment} value='Claim' />
    }
  }

  unclaimable(){
    console.log(this.availableSlotAppointees());
    return this.availableSlotAppointees().includes(this.props.currentUser.id);
  }

  availableSlotAppointees(){
    let { scheduleRow } = this.state;
    if(scheduleRow === undefined) return [];
    return scheduleRow.appointments.reduce((appointees, appt) => {
      if(appt.status === 'incomplete') appointees.push(appt.assigned_to)
      return appointees;
    }, []);
  }

  renderInfoToggler() {
    let assignees = this.assigneesOfAppointments();
    let { toggleInfo } = this.state;
    if(!assignees.length) return;
    return <i className={toggleInfo ? 'fa fa-caret-right rotate90' : 'fa fa-caret-right'}></i>
  }

  renderAppointmentInfo() {
    if(this.state.toggleInfo) {
      let scheduleRow = this.state.scheduleRow;
      return(
        <div className='cs-schedule-popout'>
          <div className='cs-schedule-slot-title'>
            <div className='cs-schedule-block'>Leads</div>
            <div className='cs-schedule-block'>Assigned To</div>
            <div className='cs-schedule-block'>Status</div>
          </div>
          {
            scheduleRow.extra_appointment_info.map((appt, index) => {
              return(
                <div key={index} className='cs-schedule-slot-data' onClick={() => this.fetchAgent(appt)}>
                  <div className='cs-schedule-block'>
                    <a>
                      { this.parseFirstName(appt.first_name) }
                    </a>
                  </div>
                  <div className='cs-schedule-block'>
                    { appt.assignee }
                  </div>
                  <div className='cs-schedule-block'>
                    { appt.status === 'incomplete' ? 'scheduled' : appt.status }
                  </div>
                </div>
              )
            })
          }
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    if(this.state.scheduleRow){
      return (
        <div className={this.appointmentClass()} id={'schedule-row-container-' + this.props.time}>
          { this.renderHeader() }
          { this.renderAppointmentInfo() }
        </div>
      )
    } else {
      return <div />
    }
  }
}
