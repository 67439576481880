import { Component } from 'react';

const QualHeader = ({ state, updateState }) => {
  let {
    last_lead,
    lead,
    pause,
    blacklisted,
    phone,
    location,
    call_count,
    match_count,
  } = state;

  let dialer = document.getElementById('dialer')

  const handleClick = () => {
    dialer.classList.toggle('paused');
    updateState({ pause: !pause });
  }

  return (
    <div className='fresh_header'>
      <div className='super_header'>
        Last Viewed: <a href={`/leads/${last_lead}`} target='_blank'>{last_lead}</a>
      </div>
      <div className='left_content'>
        <div className='pause_container'>
          <div className='pause' onClick={handleClick}>
            <span className='btn_text'>{pause ? 'Resume' : 'Pause'}</span>
            <i className={pause ? 'fa fa-play' : 'fa fa-pause'}></i>
          </div>
        </div>
        <div className='phone_container'>
          <i className='fa fa-phone-square'></i>
          <span className={blacklisted ? 'caller-id-requal blacklisted' : 'caller-id-requal'}>
            {Util.formatPhoneNumber(phone)}
          </span>
        </div>
        <div className='location_container'>
          <i className='fa fa-map-marker'></i>
          <span className='caller-city-requal'>{location}</span>
        </div>
        <div className='created_container'>
          <i className='fa fa-calendar'></i>
          {moment(lead.created_at).format('M/D/YYYY, h:mm a')}
        </div>
      </div>
      <div className='right_content'>
        <div className='calls_made_box'>
          <div className='label'>
            Calls Made
            </div>
          <div className='count_box'>
            {call_count || 0}
          </div>
        </div>
        <div className='matches_box'>
          <div className='label'>
            Matches
            </div>
          <div className='count_box green'>
            {match_count}
          </div>
        </div>
      </div>
    </div>
  )
}

export default QualHeader;
