import React from 'react';
import { errorNotice } from './shared/PropTypes';

const ErrorNotice = ({ error, children }) => (
  <div className="error-message-container">
    {error && <div className="error-message">{error}</div>}
    {children}
  </div>
);

ErrorNotice.propTypes = errorNotice.props;
ErrorNotice.defaultProps = errorNotice.defaultProps;

export default ErrorNotice;
