import React from 'react';

const AgentDetails = (props) => {
  const {
    handleToggledDetails,
    rexAgentInfo,
    altosAgentInfo,
    handleServiceArea
  } = props;

  const {
    name,
    phone_number,
    raw_phone_number,
    email,
    license_number,
    license_state,
    brokerage,
    service_area,
    years_experience,
    agent_id,
    created_at,
    updated_at
  } = rexAgentInfo;

  const addBackgroundRed = (rexDetail, altosDetail) => {
    if (rexDetail && rexDetail !== altosDetail) {
      return "background-red";
    }
  };

  const parsePhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
      const digits = phoneNumber.match( /\d+/g ).join('');
      return digits.substr(digits.length - 10);
    }
  };

  const rexDigits = parsePhoneNumber(phone_number);

  const altosDigits = parsePhoneNumber(altosAgentInfo.phone_number);


  return (
    <div className="dialer-agent-details-claimed">
      <div className="claimed-details">
        { `Claimed ${created_at} with phone ${raw_phone_number}` }
        {
          updated_at !== created_at &&
            ` (Edited ${updated_at})`
        }
      </div>
      <div className="agent-details-header">
        <div className="agent-name">
          <span className={addBackgroundRed(name, altosAgentInfo.name)}>{ name }</span>
        </div>
        <div>
          <span className="agent-pill">Sign Up</span>
        </div>
      </div>
      <div className="number-agent-link">
        <div className="agent-details">
          <i className="phone" />
          <div className="agent-details-value">
            <span className={addBackgroundRed(rexDigits, altosDigits)}>{ phone_number }</span>
            <div className="phone-mobile"> | Mobile</div>
          </div>
        </div>
        <div className="agent-details">
          <p><span>
            <a href={ `/a/${agent_id}` } target='_blank'>{`#${agent_id}`}</a>
          </span></p>
        </div>
      </div>
      <div className="agent-details">
        <i className="envelope" />
        <div className="agent-details-value">
          <span className={addBackgroundRed(email, altosAgentInfo.email)}>{ email }</span>
        </div>
      </div>
      <hr />
      <table>
        <thead>
          <tr>
            <th>License Number</th>
            <th>License State</th>
            <th>Brokerage</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span className={addBackgroundRed(license_number, altosAgentInfo.license_number)}>{ license_number }</span></td>
            <td><span className={addBackgroundRed(license_state, altosAgentInfo.license_state)}>{ license_state }</span></td>
            <td><span className={addBackgroundRed(brokerage, altosAgentInfo.brokerage)}>{ brokerage }</span></td>
          </tr>
        </tbody>
      </table>
      <hr />
      <div id="toggled-details-claimed" className="hidden">
        <div className="service-area">
          <div className="service-area-header">
            Service Areas
          </div>
        <div className="service-area-value">
          <i className="map-marker" />
          <div className="service-area-cities">
            { handleServiceArea(service_area) }
          </div>
        </div>
        </div>
        <div className="experience">
          <div className="experience-header">
            Years of Experience
          </div>
          <div className="experience-years">
            { years_experience }
          </div>
        </div>
      </div>
      <div className="toggle-icons">
        <i id="angle-up-claimed" className="hidden" onClick={ handleToggledDetails }/>
        <i id="angle-down-claimed" onClick={ handleToggledDetails }/>
      </div>
    </div>
  )
};

export default AgentDetails;
