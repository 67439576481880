import  { Component } from  'react';

import mainIcon from '../images/call-initiate.svg';
import { request } from 'https';
import classNames from 'classnames';

export default class Init extends Component {
  constructor(props){
    super(props);

    this.state = {
      wasClicked: false
    };

    this.reinitialize = this.reinitialize.bind(this);
    this.initiate     = this.initiate.bind(this);
  }

  initiate() {
    if (this.state.wasClicked == true) return;

    this.setState({ wasClicked: true });

    // TODO: fix mounting issue here https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
    this.props.initiate()
      .finally(this.reinitialize);
  }

  reinitialize() {
    this.setState({ wasClicked: false });
  }

  render(){
    const { disabled, buttonText } = this.props;
    const classes = classNames('button', 'green');
    return (
        <button
          className={ classes }
          disabled={ disabled }
          onClick={ this.initiate }
        >
            { buttonText }
        </button>
      );
  }
}
