import { default as Header } from './Header';
import { default as Body } from './Body';

const Wrapper = (props) => {
  const {
    historyLogs,
    historyKeys,
    showHistoryTable,
  } = props;

  const showTable = showHistoryTable ? '' : 'collapse';

  return (
    <table className={ `table table-striped sales-history-table mb-0 ${showTable}` }>
      <Header historyKeys={ historyKeys } />
      <Body historyLogs={ historyLogs } />
    </table>
  );
};

export default Wrapper;
