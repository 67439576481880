import { Component }  from 'react';

import Header from './views/Header';
import Body from './views/Body';
import Footer from './views/Footer';
import RecruitForm from './views/recruit/Form';
import AddContactModal from './../RecruitTopAgentsModals/AddContact';

const initRecruitFormData = {
  rid: '',
  cid: '',
  existing_agent_id: '',
  canonical_aid: '',
  user: {},
  company: {},
  agent: {},
}

const randomHex = size => {
  return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
};

const buildUser = agent => {
  const firstName = agent.name.split(/\s/)[0];
  const lastName = agent.name.replace(firstName, '').trim();

  return({
    first_name: firstName,
    last_name: lastName,
    email: agent.email,
    phone_office: agent.phone,
    phone_mobile: '',
    direct_line: '',
    secret_question: 'Not used but required',
    password: randomHex(10),
  });
};

const buildCompany = agent => ({
  name: agent.brokerage,
  brand_id: '',
  address: '',
  city: agent.city,
  state: agent.state,
  zip: '',
});

const buildAgent = (agent, lead) => ({
  broker_name: '',
  original_real_estate_license_year: '',
  past_12_months_sales: '',
  license_type: '',
  license_number: '',
  license_state: agent.state,
  ip_address: 0,
  country: agent.country,
  lead_id: lead.id,
  text_message_number: '',
  text_message: 1,
  zip_codes: []
});

export default class Container extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lead: {},
      agent: {},
      click2call: {},
      recruitmentNote: '',
      recruitFormShow: '',
      recruitFormData: initRecruitFormData,
      paused: false,
    }

    this.setAttributeState = this.setAttributeState.bind(this);
    this.readyToFetchProspect = this.readyToFetchProspect.bind(this);
    this.fetchNewProspect = this.fetchNewProspect.bind(this);
    this.dispositionProspect = this.dispositionProspect.bind(this);
    this.handleBlacklistedNumber = this.handleBlacklistedNumber.bind(this);
    this.handleRecruitmentNote = this.handleRecruitmentNote.bind(this);
    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.toggleRecruitForm = this.toggleRecruitForm.bind(this);
    this.setRecruitFormData = this.setRecruitFormData.bind(this);
    this.resetRecruitForm = this.resetRecruitForm.bind(this);
  }

  componentDidMount() {
    this.readyToFetchProspect();
  }

  setAttributeState(attribute, value) {
    this.setState({ [attribute]: value });
  }

  logError(context, error) {
    Bugsnag.notify(error, e => {
      e.addMetadata('context', { name: 'Recruit Top Agents - Dialer', data: context });
    });
  }

  readyToFetchProspect() {
    const interval = setInterval(() => {
      if (document.readyState !== 'complete') return;

      clearInterval(interval);
      this.fetchNewProspect();
    }, 5);
  }

  fetchNewProspect() {
    const queryString = window.location.search;
    const { new_dialer_recruit_top_agents_path } = this.props;

    Util.clearFlash('.dialer-flash-wrapper');
    this.toggleOverlay('enable', 'calling');

    fetch(
      `${new_dialer_recruit_top_agents_path}${queryString}`,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        const { lead, agent, click2call } = response;
        const container = document.getElementById('dialer-recruit-top-agents-recruit-form');
        const timerDisplay = document.getElementById('rex-stat-timer');
        const callStatsDisplay = document.getElementById('rex-call-stats');

        const origData = {
          rid: agent.rid,
          cid: window.current_user_id,
          existing_agent_id: '',
          canonical_aid: agent.canonical_aid,
          user: buildUser(agent),
          company: buildCompany(agent),
          agent: buildAgent(agent, lead)
        };

        container.setAttribute('data-orig-recruit-data', JSON.stringify(origData));

        this.toggleOverlay('disable');

        this.setState({
          lead,
          agent,
          click2call,
          recruitFormData: origData,
          paused: !!queryString,
        });

        if (!queryString) this.placeCallToProspect();

        callStatsDisplay.dispatchEvent(new CustomEvent('agent_recruitment.fetch_stats'));
        timerDisplay.dispatchEvent(new CustomEvent('agent_recruitment.start_timer'));
      } else {
        Util.createFlash(response.errors.join(', '), 'warning', '.dialer-flash-wrapper');
        this.toggleOverlay('enable', 'no-prospect');
      }
    });
  }

  handleBlacklistedNumber() {
    const button = document.querySelector('[data-disposition="bad_number"]');

    this.toggleOverlay('enable', 'no-prospect');
    button.click();
  }

  placeCallToProspect() {
    const { click2call } = this.state;
    const { click2call_path } = this.props;

    fetch(
      click2call_path,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(click2call),
      }
    )
    .then(response => {
      if (response.headers.get('blacklisted')) {
        this.handleBlacklistedNumber();
      } else {
        this.toggleOverlay('disable');
      }
    })
    .catch(error => {
      const context = { errorType: 'rta_dialer_place_call_to_prospect', ...click2call }

      logError(context, error);
    });
  }

  dispositionProspect(event) {
    const recruit = /agent_recruitment.submit_form/.test(event.type);

    if (!recruit) event.preventDefault();
    Util.clearFlash('.dialer-flash-wrapper');

    const { dataset } = recruit ? event.detail : event.currentTarget;
    const { disposition } = dataset;

    if (disposition === 'recruit_prep') {
      this.toggleRecruitForm('show');
    } else {
      this.toggleRecruitForm('hide');
      this.toggleOverlay('enable', 'submitting');

      const { lead, agent, paused, recruitmentNote, recruitFormData } = this.state;
      const { dialer_recruit_top_agents_path, dialer_recruit_top_agents_queue_path } = this.props;
      const data = JSON.stringify({
        ...dataset,
        note: recruitmentNote,
        rta_lead_id: lead.rta_lead_id,
        rta_agent_id: agent.rta_agent_id,
        rta_agent_contact_id: agent.rta_agent_contact_id,
        recruit_form_data: recruitFormData,
      });

      fetch(
        dialer_recruit_top_agents_path,
        {
          method: 'PUT',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json'
          },
          body: data,
        }
      )
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          if (paused) {
            window.location = dialer_recruit_top_agents_queue_path
          } else {
            this.fetchNewProspect();
            this.setState({ recruitmentNote: '' });
          }
        } else {
          Util.createFlash(response.errors.join(', '), 'danger', '.dialer-flash-wrapper');
          this.toggleOverlay('disable');
        }
      });
    }
  }

  handleRecruitmentNote(event) {
    const { value } = event.currentTarget;

    this.setState({ recruitmentNote: value });
  }

  toggleOverlay(operation, action) {
    const container = document.getElementById('dialer-recruit-top-agents-container');

    // Reset
    container.classList = '';

    if (/enable/.test(operation)) container.classList.add('overlay', action);
    if (/calling|no-prospect|submitting/.test(action)) container.classList.add('request');
  }

  resetRecruitForm() {
    const formContainer = document.getElementById('dialer-recruit-top-agents-recruit-form');
    const { origRecruitData } = formContainer.dataset;
    const origData = JSON.parse(origRecruitData);

    this.setState({ recruitFormData: origData });
  }

  toggleRecruitForm(operation) {
    const form = document.getElementById('dialer-recruit-top-agents-recruit-form').querySelector('form');
    const klass = /show/.test(operation) ? 'show' : '';
    const method = /show/.test(operation) ? 'enable' : 'disable';

    this.toggleOverlay(method);
    this.setState({ recruitFormShow: klass });

    if (/show/.test(operation)) form.scrollTop = 0;
  }

  setRecruitFormData(key1, key2, value) {
    const { recruitFormData } = this.state;

    if (key1 === undefined) {
      recruitFormData[key2] = value;
    } else {
      recruitFormData[key1][key2] = value;
    }

    this.setState({ recruitFormData: recruitFormData });
  }

  render() {
    const {
      lead,
      agent,
      recruitmentNote,
      recruitFormShow,
      recruitFormData,
      paused,
    } = this.state;

    const {
      duplicate_check_path,
      stats_dialer_recruit_top_agents_path,
    } = this.props;

    return (
      <div id="dialer-recruit-top-agents-wrapper">
        <AddContactModal />

        <Header
          agent={ agent }
          paused={ paused }
          setAttributeState={ this.setAttributeState }
          statsDialerRecruitTopAgentsPath={ stats_dialer_recruit_top_agents_path }
        />

        <div id='dialer-recruit-top-agents-flash-wrapper' className='dialer-flash-wrapper'>
          <div id='flash-container'></div>
        </div>

        <Body
          lead={ lead }
          agent={ agent }
        />

        <Footer
          agent={ agent }
          recruitmentNote={ recruitmentNote }
          handleRecruitmentNote={ this.handleRecruitmentNote }
          dispositionProspect={ this.dispositionProspect }
        />

        <RecruitForm
          recruitFormData={ recruitFormData }
          recruitFormShow={ recruitFormShow }
          duplicateCheckPath={ duplicate_check_path }
          setRecruitFormData={ this.setRecruitFormData }
          toggleRecruitForm={ this.toggleRecruitForm }
          resetRecruitForm={ this.resetRecruitForm }
          dispositionProspect={ this.dispositionProspect }
        />
      </div>
    );
  }
}
