import React, { Component } from 'react';
import { connect } from 'react-redux';

import transformForSubmission from './submission';
import View from './view';

import { allTrue, getStateProps } from '../utils';

const common = [
  'country',
  'email_address',
  'first_name',
  'home_price_min',
  'home_price_max',
  'last_name',
  'lead_type',
  'phone_number',
];

const sellers = common.concat([
  'city',
  'state',
  'street_number',
  'street_name',
  'zip',
]);

const buyers = common.concat([
  'buyer_cities',
]);

const leadTypeMap = {
  buyer: buyers,
  seller: sellers,
};

const dispositionPrefix = {
  kill: 'Bad -',
  qual: 'Unknown -',
  matching: 'Good -',
};

function mapStateToProps(state) {
  return {
    calling: state.actions.get('calling'),
    killOptions: state.actions.get('killOptions'),
    matchingOptions: state.actions.get('matchingOptions'),
    qualOptions: state.actions.get('qualOptions'),
    info: getStateProps(state.info),
    lead: getStateProps(state.lead).lead,
    note: state.note.get('note'),
    submitted: state.actions.get('submitted'),
    submitting: state.actions.get('submitting'),
    validations: getStateProps(state.validations),
    focused: getStateProps(state.focused),
    max_requal_attempts: state.headers.get('max_requal_attempts'),
    attempts: state.headers.get('attempts'),
    disposition: state.actions.get('disposition'),
    value: state.actions.get('value'),
  };
}

class Actions extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { dispatch, hooks } = this.props;

    const wasSubmitting = prevProps.submitting === true;
    const isSubmitted = this.props.submitting === false && this.props.submitted === true;

    if (wasSubmitting && isSubmitted) {
      hooks.setTaskComplete();
    }
  }

  handleChange(e) {
    const { dispatch } = this.props;
    const { name, value } = e.target;

    dispatch({
      type: 'SET_DISPOSITION',
      data: {
        disposition: name,
        value
      },
    });
  }

  submit(disposition) {
    const {
      dispatch,
      info,
      lead,
      note,
      value,
    } = this.props;

    const status = `${dispositionPrefix[disposition]} ${value}`;

    const data = transformForSubmission[disposition]({
      info,
      lead,
      note,
      status,
    });

    dispatch({
      type: 'SUBMIT',
      data,
    });
  }

  render() {
    const {
      calling,
      disposition,
      focused,
      info,
      killOptions,
      matchingOptions,
      qualOptions,
      submitting,
      validations,
      max_requal_attempts,
      attempts,
      value
    } = this.props;

    let requiredProps = leadTypeMap[info.lead_type];

    if (requiredProps && (info.text_consent || info.contact_date || info.contact_time)) requiredProps = requiredProps.concat(['contact_date', 'contact_time', 'text_consent']);

    const isValidForm = allTrue(requiredProps, validations);
    const areAllFocused = allTrue(requiredProps, focused);

    const data = {
      disposition,
      killOptions,
      matchingOptions,
      qualOptions,
      value,
    };

    const maxedAttempts = attempts >= max_requal_attempts;

    return(
      <View
        calling={ calling }
        data={ data }
        isValidForm={ isValidForm && areAllFocused }
        submitting={ submitting }
        maxedAttempts= { maxedAttempts }

        handleChange={ this.handleChange }
        submit={ this.submit }
      />
    );
  }
}

export default connect(mapStateToProps)(Actions);
