/* eslint-disable camelcase */
import React from 'react';

const Modal = (props) => {
  const {
    id,
    prompt,
    showHeader,
    showFooter,
    contentClass,
    status,
    children,
    headerContent,
    footerContent,
    close,
  } = props;

  const overlayClass = ['modal-outer'];
  if (status === 'hidden') { overlayClass.push('hidden'); }
  if (prompt) { overlayClass.push('prompt'); }

  const containerClass = ['modal-container'];
  const modalClass = ['modal-content'];

  if (!showHeader) { modalClass.push('no-header'); }
  if (!showFooter) { modalClass.push('no-footer'); }
  if (contentClass) {
    containerClass.push(contentClass);
    modalClass.push(contentClass);
  }

  function stopPropagation(e) {
    e.stopPropagation();
  }

  function header() {
    return showHeader
      ? (
        <div id={`${id}-header`} className="modal-header">
          {headerContent}
        </div>
      )
      : null;
  }

  function footer() {
    return showFooter
      ? (
        <div className="modal-footer">
          {footerContent}
        </div>
      )
      : null;
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={overlayClass.join(' ')}
      onClick={close}
      onKeyPress={close}
    >
      <div
        id={id}
        role="button"
        tabIndex={0}
        className={containerClass.join(' ')}
        onClick={stopPropagation}
        onKeyPress={stopPropagation}
      >
        {header()}
        <div className={modalClass.join(' ')}>
          {
            status === 'loading'
              ? <div className="modal-loading" />
              : children
          }
        </div>
        {footer()}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  showHeader: false,
  showFooter: false,
  contentClass: null,
  children: [],
  headerContent: [],
  footerContent: [],
};

export default Modal;
