import { Component } from 'react';

import MatchingStatus from './MatchingStatus';
import CompaniesToBlock from './CompaniesToBlock';
export default class PreMatchResearchBuyerForm extends Component {
  constructor( props ) {
    super( props );

    this.minChars = 150;
    this.commentsOption = null;
    this.commentsChange = this.commentsChange.bind(this);
  }

  componentDidMount() {
    this.props.data.pmr_form.comments = this.props.data.comments
    this.props.updateState( this.props.data );
  }

  commentsChange(e) {
    this._onCommentsChange({
      target: {
        value: this.props.commentsMap[e.target.value],
      },
    });
  }

  checkComments(comment) {
    if (comment === null) {
      return 'none';
    }

    const { commentsMap } = this.props

    const providedCommentIndex = Object.values(commentsMap).indexOf(comment)

    if (providedCommentIndex > -1) {
      return Object.keys(commentsMap)[providedCommentIndex];
    }

    return 'freeText';
  }

  languagePreferenceChange( { target} ) {
    const { data, updateState } = this.props;
    const { value } = target;
    data.lead.language_preference = value;
    updateState( data );
  }

  render() {
    let submitClasses = 'modal_button';
    const { comments, pmr_form } = this.props.data;

    this.commentsOption = this.checkComments(comments);

    if (pmr_form.is_an_agent) {
      submitClasses += ' kill';
    } else if (this.commentsOption === null) {
      submitClasses += ' disabled';
    } else if (this.commentsOption === 'freeText' && comments.length < this.minChars) {
      submitClasses += ' disabled';
    }

    return(
      <div className='content_form buyer_modal_form'>
        <div className='field full'>
          <div className='label'>
            Any additional notes / comments?
          </div>
          <textarea
            name='matcher_notes'
            value={ this.props.data.pmr_form.matcher_notes }
            data-optional='true'
            onChange={ this._handlePmrChange.bind(this) }
            placeholder='ex. Client requests Spanish-speaking agents, client will be in town this weekend. etc.'
          >
          </textarea>
        </div>
        <MatchingStatus
          data={ this.props.data }
          updateState={ this.props.updateState.bind( this ) }
        />
        <div className='field full'>
          <div className='label'>
            Is this lead an agent?
          </div>
          <div>
            <input type='radio' name='is_an_agent' id='is_an_agent_false' value='false' onChange={ this._handlePmrChange.bind( this ) } />
            <label htmlFor='is_an_agent_false'>Not an agent</label>
          </div>
          <div>
            <input type='radio' name='is_an_agent' id='is_an_agent_true' value='true' onChange={ this._handlePmrChange.bind( this ) } />
            <label hmtlfor='is_an_agent_true'>Is an Agent</label>
          </div>
        </div>
        <div className='field full selectdd'>
          <div className='label'>
            What is the client’s preferred language?
          </div>
          <select
            name='language_preference'
            value={ this.props.data.lead.language_preference }
            onChange={ this.languagePreferenceChange.bind(this) }
          >
            <option value='en'>English</option>
            <option value='es'>Spanish</option>
            <option value='fr'>French</option>
          </select>
        </div>
        { this._renderAgentInvitationCheckbox() }
        <div className={ this.props.data.pmr_form.is_an_agent ? 'field full' : 'hidden' }>
          <div className='label'>
            If this lead is an agent please provide a link
          </div>
          <input type='text' name='agent_link' data-optional='true' value={ this.props.data.pmr_form.agent_link } onChange={ this._handlePmrChange.bind( this ) } />
        </div>
        <div className={ this.props.data.pmr_form.is_an_agent ? 'hidden' : '' }>
          <div className='field full'>
            <div className='label'>
              Are there companies to block?
            </div>
            <input type='checkbox' name='companies_block' id='companies_true' data-optional={ this._companies() ? 'true' : 'false' } defaultChecked={ this._companies() } onChange={ this._onCompanyChange.bind( this ) } />
            <label htmlFor='companies_true'>Companies to Block</label>
          </div>
          { this._renderCompanies() }
          <div className='field full label-only'>
            <div className='label'>
              Confirm client comments
              <span>
                { this.props.data.origin.indexOf( 'Home Captain' ) !== -1 ? 'Client comments will be viewed as a custom message to agents. Please confirm that all spelling and content is correct. Include lender info before sending to Matching.' : 'Client comments will be viewed as a custom message to agents. Please confirm that all spelling and content is correct.' }
              </span>
            </div>
          </div>
          <div className='field full selectdd no-label'>
            <select value={ this.commentsOption } onChange={ this.commentsChange }>
              <option value='none'> </option>
              <option value='goodA'>Good - Answering Machine/Name</option>
              <option value='goodB'>Good - Phone/Address Record Match</option>
              <option value='goodC'>Good - 2-Factor Authentication Match</option>
              <option value='freeText'>Free Text</option>
            </select>
          </div>
          <div className='field full'>
            <textarea
              name='comments'
              value={ comments === null ? ' ' : comments } data-optional='true'
              onChange={ this._onCommentsChange.bind(this) }
              disabled={ this.commentsOption !== 'freeText' }
            ></textarea>
            { (this.commentsOption == 'freeText' && (!comments || comments.length < this.minChars)) &&
              <div className='textarea-minimum'>Minimum { this.minChars } characters - { this.minChars - this.props.data.comments.length } remaining</div>
            }
          </div>
          <div className='clear'></div>
        </div>
        <div className='clear'></div>
        <div onClick={ this._handleSubmit.bind(this) } className={ submitClasses }>
          { this.props.data.pmr_form.is_an_agent ? 'Update and Kill Lead' : 'Update and Send to Matching' }
        </div>
      </div>
    )
  }

  _companies() {
    if( this.props.data.pmr_form.companies_block )
      return true;

    if((this.props.data.lead.blocked_company_brand_ids && this.props.data.lead.blocked_company_brand_ids.constructor === Object && Object.keys(this.props.data.lead.blocked_company_brand_ids).length === 0) && (this.props.data.lead.blocked_verified_company_ids && this.props.data.lead.blocked_verified_company_ids.constructor === Object && Object.keys(this.props.data.lead.blocked_verified_company_ids).length === 0))
      return false;

    if( this.props.data.lead.blocked_company_brand_ids == '' || this.props.data.lead.blocked_verified_company_ids == '' )
      return false;

    if( this.props.data.lead.blocked_company_brand_ids == null || this.props.data.lead.blocked_verified_company_ids == null )
      return false;

    return true;
  }

  _handlePmrChange( e ) {
    var val = e.target.value;

    if( val === 'on' )
      val = '1';

    if( val === 'off' )
      val = '0';

    if( val === 'true' )
      val = true;

    if( val === 'false' )
      val = false;

    this.props.data.pmr_form[ e.target.name ] = val;
    this.props.updateState( this.props.data );
  }

  _onCompanyChange( e ) {
    var val = e.target.checked;

    if( !val ){
      this.props.data.lead.blocked_company_brand_ids = null;
      this.props.data.lead.blocked_verified_company_ids = null;
      this.props.data.pmr_form.blocked_company_brand_ids = {};
      this.props.data.pmr_form.blocked_verified_company_ids = {};
    }

    this.props.data.pmr_form[ e.target.name ] = val;
    this.props.updateState( this.props.data );
  }

  _onCommentsChange( e ) {
    this.props.data.comments = e.target.value;
    this.props.data.pmr_form.comments = e.target.value;
    this.props.updateState( this.props.data );
  }

  _onAgentInvitationEmailChange( e ) {
    this.props.data.send_agent_invitation = e.target.value;
    this.props.updateState( this.props.data );
  }

  _handleSubmit( e ) {
    e.preventDefault();
    if( this._validateForm() ){
      this.props.closeModal();
      this.props.pmrSubmit();
    }
  }

  _validateForm() {
    var that = this;
    var invalids = [];
    var fields = $('.buyer_modal_form input, .buyer_modal_form select, .buyer_modal_form textarea' );

    fields.removeClass( 'error' );

    if (!this.props.data.pmr_form.is_an_agent && this.commentsOption === 'none') {
      return false;
    }

    if( this.props.data.pmr_form.is_an_agent === true ) {
      if( this.props.data.pmr_form.agent_link === '' ){
        invalids.push( 'agent_link' );
        invalids.forEach( function( name ) {
          $( '[name="' + name + '"]' ).addClass( 'error' );
        });
        return false;
      } else {
        return true;
      }
    }

    fields.each(function() {
      if( $( this ).attr( 'data-optional' ) !== 'true' ) {
        if( $( this ).val() == '' )
          invalids.push( $( this ).attr( 'name' ) );

        if( $( this ).attr('type') === 'radio' ){
          if( that.props.data.pmr_form[ $( this ).attr('name') ] === '' )
            invalids.push( $( this ).attr( 'name' ) );
        }
      }
    });

    if( invalids.length > 0 ) {
      invalids.forEach( function( name ) {
        $( '[name="' + name + '"]' ).addClass( 'error' );
      });
      return false;
    }

    return true;
  }

  _renderAgentInvitationCheckbox() {
    if (this.props.data.render_agent_invitation_checkbox) {
      return(
        <div className={ this.props.data.pmr_form.is_an_agent ? 'field full' : 'hidden' }>
          <div>
            <input type='checkbox' name='send_agent_invitation' id='send_agent_invitation' value='1' onChange={ this._onAgentInvitationEmailChange.bind( this ) }/>
            <label htmlFor='send_agent_invitation'>Send Agent Invitation email</label>
          </div>
        </div>
      )
    }
  }

  _renderCompanies() {
    if( this._companies() ){
      return(
        <CompaniesToBlock
          data={ this.props.data }
          updateState={ this.props.updateState.bind( this ) }
        />
      )
    }
  }
}
