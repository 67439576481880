import React, { Component } from 'react';
import { connect } from 'react-redux';
import View from './view';
import { store } from '../../index';

import { getStateProps } from '../../utils';

import BaseGoogleSearch from './index';

export default class SellerGoogleSearch extends BaseGoogleSearch {
  constructor(props) {
    super(props);

    const { properties } = props;
    this.state = {
      values: [...properties],
    };
  }

  componentDidUpdate(prevProps) {
    const { properties } = this.props;
    const citiesMatch = prevProps.properties[0] === properties[0];
    const stateMatch = prevProps.properties[1] === properties[1];

    if (!citiesMatch || !stateMatch) {
      this.setState({ values: [...properties] });
    }
  }
}
