import React, { useState, useEffect } from 'react';
import Context from './Context';
import Main from './Main';
import Summary from './Summary';
import Fetch from '../../common/fetch';

const initialSeletions = {
  listingDetailsComplete: false,
  foundListing: null,
  foundSale: null,
  foundAgent: null,
  foundAgentMatches: null,
  foundAgentName: '',
  foundAgentNameFreeText: '',
  foundBrokerage: '',
  foundOtherBrokerage: null,
  foundBrokerageName: '',
  source: '',
};

const selectionDecisionOrder = [
  'source',
  'foundListing',
  'foundSale',
  'foundAgent',
  'foundAgentMatches',
  'foundAgentName',
  'foundAgentNameFreeText',
  'foundBrokerage',
  'foundOtherBrokerage',
  'foundBrokerageName',
  'listingDetailsComplete',
];

const Attom = (props) => {
  const { snapshot_id, uuid, task_id } = props;

  const [transaction, setTransaction] = useState({
    errors: {},
    states: [],
  });
  const [dupTransaction, setDupTransaction] = useState({});
  const [reviewStage, setReviewStage] = useState(null);
  const [selections, setSelections] = useState(initialSeletions)

  useEffect(() => {
    fetchTransaction();
    setReviewStage('stage1');
  }, []);

  window.transaction = transaction;
  window.reviewStage = reviewStage;
  window.selections = selections;

  async function updateTransaction(options, cb) {
    setTransaction((prevState) => ({ ...prevState, ...options }));
    if (cb) { return cb(); }
    return null;
  }

  function allClicked() {
    return document.getElementsByClassName('faux-error').length === 0;
  }

  function updateSelections(options = {}) {
    const updateSelections = Object.keys(options);

    updateSelections.forEach(updateSelection => {
      const prevSelectionsAfterRest = selectionsAfterReset(updateSelection);

      // Timeout required to wait for allClicked() event to complete
      setTimeout(() => {
        setSelections({
          ...prevSelectionsAfterRest,
          ...options,
          listingDetailsComplete: allClicked(),
        })
      }, 50);
    });

    return null;
  }

  function selectionsAfterReset(selection) {
    const resetSelections = Array.from(selectionDecisionOrder).splice(
      selectionDecisionOrder.indexOf(selection),
      selectionDecisionOrder.length
    )

    resetSelections.forEach((resetKey) => {
      selections[resetKey] = initialSeletions[resetKey];
    });

    return selections;
  }

  async function fetchTransaction() {
    const res = await Fetch.get({ url: `/attom/research/${snapshot_id}/fetch` });

    setSelections(initialSeletions);
    setReviewStage('stage1');
    setTransaction(res.transaction);
    setDupTransaction(res.transaction);
  }

  function hideTaskCard() {
    const card = document.getElementById(`queue_item_AttomTransactionResearch_${task_id}`);
    if (card) {
      card.classList.add('hidden');
    }
  }

  function brokerageName() {
    const {
      foundAgent,
      foundAgentName,
      foundAgentNameFreeText,
      foundBrokerage,
      foundBrokerageName,
      foundAgentMatches,
      foundOtherBrokerage,
    } = selections;

    //  Agent not found abut another brokerage found
    if (foundAgent === false && foundOtherBrokerage) {
      return foundBrokerageName;

    // Agent found and agent matches
    } else if (foundAgentMatches) {

      if (foundBrokerage === 'yes') {
        return transaction.broker_name;
      } else if (foundBrokerage === 'no') {
        return null;
      }

    // Agent doesn't match but found on potential agent list
    } else if (foundAgentName && foundAgentName.length > 0) {

      if (foundBrokerage === 'yes') {
        const agentNames = transaction.potential_agents.map(a => a.name);
        const index = agentNames.indexOf(foundAgentName);

        return transaction.potential_agents[index].broker_name;
      } else if (foundBrokerage === 'no') {
        return null;
      }

    // Agent doesn't match, another agent found not on potential agent list
    } else if (foundAgentNameFreeText && foundOtherBrokerage) {
      return foundBrokerageName;
    } else {
      return null;
    }
  }

  function formatSalePrice(price) {
    if (typeof(price) == 'number') {
      return price;
    } else {
      return price.replace(/\D/g, '');
    }
  }

  function submitPayload() {
    const {
      rex_transaction_id,
      transaction_date,
      sale_price,
      address_house_number,
      address_street_name,
      address_unit,
      address_city,
      address_state,
      address_zip,
      lead_type,
      agent_name,
    } = transaction;

    const {
      foundListing,
      foundSale,
      foundAgentName,
      foundAgentNameFreeText,
      source,
    } = selections;

    let agentName = agent_name;
    let jsonSource = JSON.stringify(source.split('\n'));

    // Determine agent name
    if (foundAgentName) {
      agentName = foundAgentName;
    } else if (foundAgentNameFreeText) {
      agentName = foundAgentNameFreeText;
    } else {
      agentName = null;
    }

    // Determine brokerage name
    const brokerName = brokerageName();
    const agentNameProp = (lead_type === 'buyer') ? 'buyer_agent_name' : 'seller_agent_name';
    const brokerNameProp = (lead_type === 'buyer') ? 'buyer_broker_name' : 'seller_broker_name';

    return {
      task_id,
      rex_transaction_id,
      address_house_number,
      address_street_name,
      address_unit,
      address_city,
      address_state,
      address_zip,
      transaction_date: foundSale ? transaction_date : null,
      sale_price: foundSale ? formatSalePrice(sale_price) : null,
      sources: jsonSource,
      found_listing: foundListing,
      found_sale: foundSale,
      [agentNameProp]: agentName,
      [brokerNameProp]: brokerName,
    };
  }

  async function submitReview() {
    await Fetch.post({
      url: `/attom/research/${snapshot_id}`,
      payload: submitPayload(),
     });

    setReviewStage('stage3');
    hideTaskCard();
  }

  return (
    <Context.Provider
      value={{
        initialSeletions,
        brokerageName,
        dupTransaction,
        transaction,
        updateTransaction,
        reviewStage,
        selections,
        updateSelections,
        setSelections,
        submitReview,
      }}>
      <Main />
      {
        reviewStage !== 'stage3' && (
          <Summary />
        )
      }
    </Context.Provider>
  )
};

export default Attom;
