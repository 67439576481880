import React from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({ tip }) => (
  <div className="tooltip">
    <span className="tooltip-caret" />
    <span className="tooltip-text">
      {tip}
    </span>
  </div>
);

Tooltip.propTypes = {
  tip: PropTypes.string,
};

Tooltip.defaultProps = {
  tip: '',
};

export default Tooltip;
