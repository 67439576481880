import { Component } from 'react';

export default class ScriptIntroduction extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      highlight: false
    };
  }

  componentDidMount() {
    EventBus.on( 'highlight:intro', this._onHighlight.bind( this ) );
    EventBus.on( 'highlight:questions', this._onHighlightOther.bind( this ) );
    EventBus.on( 'highlight:closing', this._onHighlightOther.bind( this ) );
  }

  render() {
    return(
      <div className='script'>
        <div className='heading'>
          <i className='fa fa-commenting-o'></i>
          { this.props.data.title }
        </div>
        <div className={ this.state.highlight ? 'scriptlet highlighted' : 'scriptlet' }>
          <div className={ this.props.data.greeting ? 'greeting' : 'hidden' } dangerouslySetInnerHTML={{ __html: this.props.data.greeting || '' }}></div>
          <div className='copy' dangerouslySetInnerHTML={{ __html: this.props.data.copy }}></div>
        </div>
      </div>
    )
  }

  _onHighlight() {
    this.setState({
      highlight: true
    });
  }

  _onHighlightOther() {
    this.setState({
      highlight: false
    });
  }
}
