import  { Component } from  'react';
import { AgentConnected, AgentReady, AgentUnavailable, Conference, Contacting, Init, LeadConnected } from './CallStatusButtons';

export default class CallStatus extends Component {
  constructor(props){
    super(props);
    this.renderStatusButton = this.renderStatusButton.bind(this);
  }

  renderStatusButton(){
    switch(this.props.callStatus) {
      case 'init':
        return (
          <Init
            startCall = { this.props.startCall }
            contactAgent = { this.props.contactAgent }
            callStatus = { this.props.callStatus }
            verifications = { this.props.verifications }
            updateState={this.props.updateState.bind(this)}
            updateLead={this.props.updateLead.bind(this)}
          />
        )
      case 'contacting_lead':
        return (
          <Init
            startCall = { this.props.startCall }
            contactAgent = { this.props.contactAgent }
            callStatus = { this.props.callStatus }
            verifications = { this.props.verifications }
            updateState={this.props.updateState.bind(this)}
            updateLead={this.props.updateLead.bind(this)}
          />
        )
      case 'contacting_agent':
        return (
          <Contacting
          />
        )
      case 'lead_connected':
      case 'lead_contacted':
        return (
          <Init
            startCall = { this.props.startCall }
            contactAgent = { this.props.contactAgent }
            callStatus = { this.props.callStatus }
            verifications={ this.props.verifications }
            updateState={this.props.updateState.bind(this)}
            updateLead={this.props.updateLead.bind(this)}
          />
        )
      case 'agent_contacted':
        return (
          <Contacting
          />
        )
      case 'agent_ready':
        return (
          <AgentReady
          />
        )
      case 'solo_agent':
        return (
          <AgentConnected
            agentCallStartTime = {this.props.agentCallStartTime}
          />
        )
      case 'solo_lead':
        return (
          <LeadConnected
            leadCallStartTime = {this.props.leadCallStartTime}
          />
        )
      case 'agent_unavailable':
      case 'agent_disconnected':
      case 'complete':
        return (
          <AgentUnavailable
            agentCallStartTime = {this.props.agentCallStartTime}
          />
        )
      case 'conference':
        return (
          <Conference
            conferenceCallStartTime = {this.props.conferenceCallStartTime}
          />
        )
      case 'lead_disconnected':
        return (
          <Init
            startCall = { this.props.startCall }
            contactAgent = { this.props.contactAgent }
            callStatus = { this.props.callStatus }
            verifications = { this.props.verifications }
            updateState={this.props.updateState.bind(this)}
            updateLead={this.props.updateLead.bind(this)}
          />
        )
    }
  }

  render(){
    return (
      <div className='status-container'>
        <div className='col-sm-2'>
          { this.renderStatusButton() }
        </div>
      </div>
    );
  }
}
