import React, { Component } from 'react';
import { store } from '../../../index';
import { getStateProps } from '../../../utils';
import Locations from '../../../locations';

import BaseSelectContainer from './index';

import View from './view';

export default class TypeHomeContainer extends BaseSelectContainer {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      classNames,
      display,
      id,
      index,
      property
    } = this.props;

    const {
      focused,
      valid,
      value,
     } = this.state;

    const { type_home } = Locations.get();

    return (
      <View
        classNames={ classNames }
        data={ type_home }
        display={ display }
        focused={ focused }
        id={ id }
        index={ index }
        name={ property }
        valid={ valid }
        value={ value }

        onChange={ this.handleChange }
        onFocus={ this.handleOnFocus }
      />
    );
  }

}
