import React, { Component } from 'react';

import RexInbox from './RexInboxView';

export default class RexInboxContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: 'confirm',
      loading: false,
    };

    this.finished = this.finished.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const {
      agentId,
      hasActiveLiveInbox,
      unsuccessfulRequest,
      updateManagement
    } = this.props;
    const url = hasActiveLiveInbox ? `/agents/${agentId}/deactivate_live_inbox` : `/agents/${agentId}/reactivate_live_inbox`;

    updateManagement({ loading: true });
    this.setState({ loading: true });

    fetch(url, { credentials: 'same-origin', method: 'POST' })
      .then(res => {
        this.setState({
          display: 'success',
          loading: false,
        });
        updateManagement({
          hasActiveLiveInbox: !hasActiveLiveInbox,
          loading: false
        });
      })
      .catch(err => {
        unsuccessfulRequest(err);
      });
  }

  finished() {
    const { updateManagement } = this.props;
    updateManagement({
      displaying: null,
      modalOpen: false,
    });
  }

  render() {
    const { hasActiveLiveInbox } = this.props;
    const { display, loading } = this.state;
    return (
      <RexInbox
        hasActiveLiveInbox={ hasActiveLiveInbox }
        display={ display }
        loading={ loading }
        finished={ this.finished }
        toggle={ this.toggle }
      />
    );
  }
}
