import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { createSectionRow } from '../utils';

import './styles.scss';

const headerSection = ({ key, image, values, styles }, index) => {
  const classNames = ['header-section'];

  if (image) {
    classNames.push('has-image');
  }

  function mapValue(value, i, values, styles) {
    let labelClass = '';
    let valIdx = values.indexOf(value);
    if ( !(typeof(styles)== 'undefined') ) {
      labelClass = styles[valIdx];
    }
    return (
      <span className={ labelClass } key={ i }>{ value || '-' }</span>
    );
  }

  return (
    <div className={ classNames.join(' ') } key={ index }>
      <div className='section-key'>{ key }</div>
      <div className='section-value-container'>
        { image &&
          <div className='person-image'>
            <img src={ image } />
          </div>
        }
        <div className='section-value'>
          { values.map((value, i) => mapValue(value, i, values, styles)) }
        </div>
      </div>
    </div>
  );
};

const differenceDisplayText = (ms) => {
  function dayHoursMinutes(t){
    var cd = 24 * 60 * 60 * 1000,
        ch = 60 * 60 * 1000,
        d = Math.floor(t / cd),
        h = Math.floor( (t - d * cd) / ch),
        m = Math.round( (t - d * cd - h * ch) / 60000),
        pad = function(n){ return n < 10 ? '0' + n : n; };
    if( m === 60 ){
      h++;
      m = 0;
    }
    if( h === 24 ){
      d++;
      h = 0;
    }
    // minutes: pad(m)
    return {
      days: d,
      hours: pad(h),
    };
  }

  const now = Date.now();
  const timeDifference = moment(ms, 'YYYY-MM-DD, hh:mm A').diff(now, 'YYYY-MM-DD, hh:mm A');
  const asPositiveNumber = Math.abs(timeDifference);
  const differenceObj = dayHoursMinutes(asPositiveNumber);
  const { days, hours } = differenceObj;
  if (days == 1) {
    return `(${days} day ago)`
  } else if (days > 1) {
    return `(${days} days ago)`;
  } else {
    return `(${hours} hours ago)`;
  }
};

const Header = (props) => {
  const { data } = props;

  const {
    leadInfo,
    liveLead,
    sourceInfo,
    submittingAgent,
  } = data;

  const {
    first_name,
    last_name,
    rawName,
    wpSubscriberName
  } = leadInfo;

  const { origin, submission } = sourceInfo;

  const leadName = `${ first_name } ${ last_name }`;
  const differenceDisplay = !isNaN(submission) ? '-' : differenceDisplayText(submission);

  const currentName = `${leadName}`;
  const originalName = `${wpSubscriberName}`;
  const subsLabel = 'Subscriber Name';
  const LeadInfo = {
    key: 'Lead',
    values: [ currentName, subsLabel, originalName ],
    styles: [null, 'subsc-name-label', null],
  };

  const SourceInfo = {
    key: 'Source',
    values: [ origin, differenceDisplay ],
  };

  const displayFields = [
    LeadInfo,
    SourceInfo,
  ];

  if (liveLead) {
    const {
      city,
      company,
      image,
      name,
      state,
    } = submittingAgent;

    const SubmittingAgentInfo = {
      key: 'Submitting Agent',
      image,
      values: [ name, company, `${ city }, ${ state}`],
    };

    displayFields.splice(1, 0, SubmittingAgentInfo);
  };

  setTimeout(() => {
    const mainContainer = document.querySelector('.main-container-dialer');
    const navHeader = document.querySelector('.nav-container');
    const stickyHeader = document.getElementById('sticky-header');

    const navHeaderHeight = navHeader.getBoundingClientRect().height;
    const stickyHeaderHeight = stickyHeader.getBoundingClientRect().height;
    const stickyHeaderMarginBottom = 24;

    const paddingTop = [
      navHeaderHeight,
      stickyHeaderHeight,
      stickyHeaderMarginBottom
    ].reduce((a, b) => a + b, 0);
    mainContainer.style.paddingTop = `${ paddingTop }px`;
  }, 200);

  return (
    <div className='active-dialer-lead-header' id='sticky-header'>
      { displayFields.map((field, i) => headerSection(field, i)) }
    </div>
  );
};

export default Header;
