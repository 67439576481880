import { all, call, put, takeLatest } from 'redux-saga/effects';
import request from 'superagent';

const submitData = (data) => {
  return request
    .post('/qual/queue/submit_v2')
    .query(data)
    .then(({ body }) => {
      body.errors
        ? alert(body.errors)
        : console.log('success');
    });
};

function* submitLeadData(action) {
   try {
      const data = yield call(submitData, action.data);
      yield put({type: 'SUBMISSION_SUCCESS', data});
   } catch (e) {
      yield put({type: 'SUBMISSION_FAILURE', message: e.message});
   }
}

function* mySaga() {
  yield takeLatest('SUBMIT', submitLeadData);
}

export default [
  mySaga(),
];
