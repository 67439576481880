import FailIcon from '../../../../LiveAccountManagement/images/fail.png';

const Fail = ({ error, toggleModal }) => (
  <div id='disable-fail'>
    <div className='rex-modal-icon-container'>
      <img className='rex-modal-icon' src={ FailIcon } />
    </div>
    <div className='rex-modal-heading'>
      Switch Failed
    </div>
    <div className='rex-modal-contents'>
      {
        error &&
        <div className='rex-modal-error'>
          { error }
        </div>
      }
      <div className='rex-modal-subheading'>
        <p className='centered'>An error occured, please go back and try again.</p>
      </div>
      <div className='rex-modal-actions rex-modal-actions__centered'>
        <button
          className='rex-modal-action'
          onClick={ () => toggleModal(false) }
        >
          CLOSE THIS WINDOW
        </button>
      </div>
    </div>
  </div>
);

export default Fail;
