import { Map } from 'immutable';

const initialState = Map({
  matches: null,
});

const actionsMap = {
  ['SET_MATCHES_DATA']: (state, action) => {
    return state.merge(Map({
      matches: action.data,
    }));
  },
  ['RESET_NEW_LEAD']: (state) => {
    return state.merge(initialState);
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
