import { Component } from 'react';
import LiveBuyerForm from './BuyerForm'
import LiveSellerForm from './SellerForm'

export default class LiveBaseForm extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      lead: {}
    }

    this.handleLeadChange = this.handleLeadChange.bind(this);
    this.handleLeadCountryChange = this.handleLeadCountryChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.highlightIntro = this.highlightIntro.bind(this);
    this.verifyField = this.verifyField.bind(this);
  }

  componentDidMount(){
    this.setLead();
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.data && nextProps.data.lead.id !== this.state.lead.id) this.setLead(nextProps);
  }

  setLead(props = this.props){
    if(props.data) this.setState({ lead: Object.assign({}, props.data.lead) })
  }

  handleLeadChange( e ) {
    let targetVal = e.target.value;
    let targetName = e.target.name;
    const { live_verifications, lead } = this.props.data;

    if(targetName === 'home_price_max'){
      targetVal = targetVal.replace(/\D/g,'');
    }

    if (Object.keys(live_verifications).includes(targetName)){
      live_verifications[targetName] = true
    }

    lead[targetName] = targetVal;
    this.props.updateState(this.props.data);
  }

  handleChange( e ) {
    let targetName = e.target.name;
    let targetVal = e.target.value;
    const { pmr_form, live_verifications } = this.props.data;

    if(targetName === 'qual_note'){
      pmr_form.matcher_notes = targetVal;
    }

    if(Object.keys(live_verifications).includes(targetName)){
      live_verifications[targetName] = true
    }

    this.props.data[ targetName ] = targetVal;
    this.props.updateState( this.props.data );
  }

  handleCommentsChange( e ) {
    this.props.data[ e.target.name ] = e.target.value;
    this.props.data.pmr_form[ e.target.name ] = e.target.value;
    this.props.updateState( this.props.data );
  }

  handleCheckChange( e ) {
    this.props.data.lead[ e.target.name ] = e.target.checked;
    this.props.updateState( this.props.data );
  }

  handleLeadCountryChange(e) {
    let lead = this.props.data.lead;
    for (let i = 0; i < 4; i++) {
      lead[`buyer_city_${i}`] = '';
      lead[`buyer_state_${i}`] = '';
    }

    this.props.updateState(this.props.data);
    this.handleLeadChange(e);
  }

  verifyField(e) {
    if (!Object.keys(this.props.data.live_verifications).includes(e.target.name)) { return }

    this.props.data.live_verifications[e.target.name] = true
    this.props.updateState(this.props.data)
  }

  highlightClass(field) {
    if (this.props.data.live.ops_created && !!field) {
      if (Object.keys(this.props.data.live_verifications).includes(field)) {
        this.props.data.live_verifications[field] = true;
      }
      return 'verified'
    }

    if (!Object.keys(this.props.data.live_verifications).includes(field)) {
      return ''
    } else if (this.props.data.live_verifications[field]) {
      return 'verified'
    } else {
      return 'verification-needed'
    }
  }

  onHomeSelectChange( e ) {
    this.props.data.home_price = e.target.value;
    var vals = e.target.value.split( '-' );

    vals.forEach( function( val, i ) {
      if( vals.length === 1 && val !== 'Up to $100,000'){
        this.props.data.highend = true;
      } else {
        this.props.data.highend = parseInt( val.replace(/\D/g,'') ) >= 1000000;
      }
    }.bind( this ));
    this.props.updateState( this.props.data );
  }

  countryOptions() {
    let countries = this.props.data.country_options;

    return Object.keys(countries).map((country, idx) => (
      <option key={idx} className='selectdd' value={country}>{countries[country]}</option>
    ));
  }

  renderValidationForm() {
    const { lead_type } = this.props.data.lead;

    if (lead_type === 'buyer') {
      return(
        <LiveBuyerForm
          data={this.props.data}
          events={this.props.events}
          click2CallLink={this.props.click2CallLink.bind(this)}
          updateState={this.props.updateState}
        />
      )
    } else if (lead_type === 'seller') {
      return(
        <LiveSellerForm
          data={this.props.data}
          events={this.props.events}
          click2CallLink={this.props.click2CallLink.bind(this)}
          updateState={this.props.updateState}
        />
      )
    } else {
      return('')
    }
  }

  /**
   * Renders sending agent content if present
   * @return {Object} HTML to be rendered
   */
  renderSendingAgentContent() {
    const rexReferral = this.props.data.rex_referral;
    const sendingAgent = this.props.data.sending_agent;

    if (Object.keys(sendingAgent).length) {
      return(
        <div className={ rexReferral ? 'content_header' : 'hidden' }>
          <i className='fa fa-paper-plane'></i>
          Sending Agent Info:
          <span>
            <div className='sending-agent-info'>
              <a href={ `/agents/${sendingAgent.id}` } target='_blank'>{ sendingAgent.name } - { sendingAgent.company }</a> <br />
              <a className='sending-agent-location' href={ `https://www.google.com/maps/place/${sendingAgent.city.replace(/\s/g, '+')}+${sendingAgent.state}/@${sendingAgent.lat},${sendingAgent.lng},14z` } target='_blank'>{ sendingAgent.city }, { sendingAgent.state }</a>
            </div>
          </span>
        </div>
      );
    } else {
      return(<div></div>);
    }
  }

  render() {
    const {
      blacklisted,
      email,
      lead,
      live,
      live_verifications,
      qual_note,
    } = this.props.data;

    const {
      id,
      first_name,
      last_name,
      phone_number,
      country,
    } = lead;

    return(
      <div>
        <div className='content_header'>
          <i className='fa fa-user'></i>
          LIVE Info:
          <span> { first_name + ' ' + last_name }</span>
          <a
            href={ `${window.location.protocol}//${window.location.hostname}/leads/${id}` }
            target='_blank'
            className='lead_id_link'
          >
            { id }
          </a>
        </div>

        { this.renderSendingAgentContent() }

        <div className='content_form'>
          <div className='field odd'>
            <div className='label'>
              First Name
              {live.was_unverified &&
                <span className='italicized'>{live.first_name}</span>
              }
            </div>
            <input
              type='text'
              name='first_name'
              value={ first_name }
              onChange={ this.handleLeadChange }
              onFocus={ this.highlightIntro }
              onBlur={ this.verifyField }
              />
          </div>
          <div className='field'>
            <div className='label'>
              Last Name
              {live.was_unverified &&
                <span className='italicized'>{live.last_name}</span>
              }
            </div>
            <input
              type='text'
              name='last_name'
              value={ last_name }
              onChange={ this.handleLeadChange }
              onFocus={ this.highlightIntro }
              onBlur={ this.verifyField }
              />
          </div>
          <div className='field odd'>
            <div className='absolute-click-to-call'>
              {this.props.click2CallLink(phone_number)}
            </div>
            <div className='label'>
              Phone
            </div>
            <input
              type='tel'
              name='phone_number'
              className={blacklisted ? 'blacklisted' : ''}
              value={phone_number}
              onChange={this.handleLeadChange}
              onFocus={this.highlightIntro}
              onBlur={this.verifyField}
            />
          </div>
          <div className='field odd'>
            <div className='label'>
              {`Email` + (live_verifications.email ? "  \u2705" : '')}
            </div>
            <input
              type='email'
              name='email'
              className={ this.highlightClass('email') }
              value={ email }
              onChange={ this.handleChange }
              onFocus={(e) => { this.highlightIntro(e); this.verifyField(e) }}
              />
          </div>
          <div className='clear'></div>
          <div className='field odd selectdd'>
            <div className='label'>Country</div>
            <select name='country'
              value={country}
              onChange={this.handleLeadCountryChange}
              onFocus={this.highlightIntro}
              onBlur={ this.verifyField}
            >
              {this.countryOptions()}
            </select>
          </div>
          <div className='clear'></div>
          {this.renderValidationForm()}
          <div className='field full'>
            <div className='label'>
            From the Agent
            </div>
            <textarea
              disabled
              data-optional='true'
              name='from_the_agent'
              value={live.from_the_agent || ''}
            >
            </textarea>
          </div>
          <div className='field full'>
            <div className='label'>
            Qual Note
            </div>
            <textarea
              data-optional='true'
              name='qual_note'
              onChange={this.handleChange}
              value={qual_note || ''}
              onFocus={this.highlightQuestions.bind(this)}
            >
            </textarea>
          </div>
        </div>
      </div>
    )
  }

  highlightIntro() {
    EventBus.emit( 'highlight:intro' );
  }

  highlightQuestions() {
    EventBus.emit( 'highlight:questions' );
  }

  highlightClosing() {
    EventBus.emit( 'highlight:closing' );
  }
}
