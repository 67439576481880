import React from 'react';

import LiveManagement from './images/live_management.png';

const LiveAccountManagementOptions = (props) => {
  const {
    display,
    hasActiveLiveInbox,
    hasActiveSubscription,
    canceledLive,
    hasLegacyProduct
  } = props;
  const rexInbox = hasActiveLiveInbox ? 'Disable' : 'Re-Enable';
  const subscriptionStatus = hasActiveSubscription ? 'Cancel' : 'Enable';
  const cancelStatus = canceledLive ? 'Reactivate' : 'Cancel'

  return (
    <div>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ LiveManagement } />
      </div>
      <div className='rex-modal-heading'>LIVE Account Management</div>
      <div className='rex-modal-actions'>
        { hasLegacyProduct &&
          <button
            className='rex-modal-action'
            onClick={ () => display('toggleSubscription') }
          >
            { subscriptionStatus } Subscription
          </button>
        }
        {
          !hasLegacyProduct &&
          <button
            className='rex-modal-action'
            onClick={ () => display('cancelReactivate') }
          >
            { cancelStatus } Subscription
          </button>
        }
        <button
          className='rex-modal-action'
          onClick={ () => display('rexInbox') }
        >
          { rexInbox } REX Inbox
        </button>
        <button
          className='rex-modal-action'
          onClick={ () => display('disableAccess') }
        >
          Disable All Access To LIVE
        </button>
        <button
          className='rex-modal-action'
          onClick={ () => display('changePackage') }
        >
          Change Package
        </button>
      </div>
    </div>
  );
};

export default LiveAccountManagementOptions;
