import React from 'react';

import './styles.scss';

const Viewing = () => {
  return (
    <div className='active-dialer-viewing'>
      <span className='viewing-label'>Also Viewing:</span>
      <span className='viewing-viewers'></span>
    </div>
  );
};

export default Viewing;
