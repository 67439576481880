import React, { useState } from 'react';
import InputField from '../../../FormBuilder/InputField';
import GreenCheckIcon from '../images/green-check.svg';
import RedExclamationIcon from '../images/red-exclamation.svg';

const MergeRecords = (props) => {
  const {
    closeModal,
    prospect: {
      prospect_id: rtaAgentId
    },
    broadcastId
  } = props;

  const [agentId, setAgentId] = useState(undefined);
  const [agent, setAgent] = useState(undefined);
  const [fetched, setFetched] = useState(false);
  const [checked, toggleChecked] = useState(false);
  const [disabled, toggleDisabled] = useState(!checked);
  const [view, setView] = useState(undefined);
  const [mergeErrors, setMergeErrors] = useState(undefined);

  const handleChange = event => {
    const { value } = event.currentTarget;

    setAgentId(value);
  }

  const handleChecked = _event => {
    toggleChecked(!checked);
    toggleDisabled(!disabled);
  }

  const handleSearchRequest = event => {
    const { currentTarget, key } = event;

    if (key === 'Enter' || currentTarget.classList.contains('fa-search')) {
      toggleDisabled(true);
      setFetched(false);
      setAgent(undefined);
      performSearch();
      setFetched(true);
    }
  }

  const performSearch = () => {
    const agentsShowPath = `/agents/show/${agentId}?tab=All Agents`;

    fetch(
      agentsShowPath,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      setAgent(response.agent);
    })
    .catch(error => {
      console.log(error);
    });
  }

  const performMerge = _event => {
    if (disabled) return;

    toggleDisabled(true);

    fetch(
      '/recruit_top_agent/merge',
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
        body: JSON.stringify(
          { rta_agent_id: rtaAgentId, agent_id: agentId, broadcast_id: broadcastId }
        ),
      }
    )
    .then(response => response.json())
    .then(response => {
      const event = new CustomEvent('prime_eligible_dialer.disposition_updater', { detail: { success: response.success } });
      const result = response.success ? 'success' : 'error';

      if (!response.success) setMergeErrors(response.errors);

      setView(result);
      document.querySelector('body').dispatchEvent(event);
    })
    .catch(error => {
      console.log(error);
    });
  }

  switch(view) {
    case 'success':
      return (
        <div className="modal-container">
          <i className="close-button" onClick={closeModal} />
          <img className="my-25" src={GreenCheckIcon} />
          <h2>Altos record and agent profile successfully merged.</h2>
          <div className="button-container my-25">
            <button
              type="button"
              onClick={closeModal}
              className="dialer-button orange filled m-0"
            >
              Done
            </button>
          </div>
        </div>
      );
    case 'error':
      return (
        <div className="modal-container">
          <i className="close-button" onClick={closeModal} />
          <img className="my-25" src={RedExclamationIcon} />
          <h2>Something went wrong</h2>
          <p>{mergeErrors}</p>
          <div className="button-container mb-25">
            <button
              type="button"
              onClick={closeModal}
              className="dialer-button orange filled m-0"
            >
              Done
            </button>
          </div>
        </div>
      );
    default:
      return (
        <div className="modal-container">
          <i className="close-button" onClick={closeModal} />
          <h2>Merge with Agent Profile?</h2>
          <p>To remove this agent from the dialer, connect their Altos <br />record to their agent profile:</p>
          <div className="button-container form-builder">
            <InputField
              id="agent-id"
              label="Agent ID:"
              klass="faux-error inline input-icon-group"
              value={agentId}
              handleChange={handleChange}
              onKeyPress={handleSearchRequest}
              iconKlass="fas fa-search input-icon-right"
              iconOnClick={handleSearchRequest}
            />
          </div>
          { fetched &&
            <div className="button-container form-builder">
              { agent &&
                <InputField
                  id="select-agent"
                  type="checkbox"
                  klass="faux-error inline"
                  inputClass="orange"
                  inlineText={ `${agent.name} ${Util.formatPhoneNumber(agent.phone_office)} ${agent.email}` }
                  value={checked}
                  handleChange={handleChecked}
                />
              }
              { !agent &&
                <>No agent records found</>
              }
            </div>
          }
          <div className="button-container stacked">
            <button
              type="button"
              disabled={disabled}
              onClick={performMerge}
              className="dialer-button orange filled"
            >
              Merge Records
            </button>
            <button
              type="button"
              onClick={closeModal}
              className="dialer-button orange plain m-0"
            >
              Cancel
            </button>
          </div>
        </div>
      );
    }

}

export default MergeRecords;
