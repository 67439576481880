import { Component } from  'react';
import request       from 'superagent';
import { ActionCable } from 'react-actioncable-provider';
import './styles.scss';

export default class LiveControls extends Component {
  constructor(props){
    super(props);

    this.state = {
      callStatus: 'init',

      callControlUUID: null,

      leadCallStartTime:        null,
      agentCallStartTime:       null,
      conferenceCallStartTime:  null,
      callerId:                 null,
      location:                 null,
      pause:                    null
    };

    this.initiateConference = this.initiateConference.bind(this);
    this.contactAgent       = this.contactAgent.bind(this);
    this.renderSubscription = this.renderSubscription.bind(this);
    this.onEventReceived    = this.onEventReceived.bind(this);
    this.updateCallMode     = this.updateCallMode.bind(this);
  }

  /**
   * initiate comprises of transfering control of the session with the Live Conference system,
   * then contacting the agent
   */
  initiateConference() {
    return new Promise((resolve, reject) => {
      this.startLiveTransfer()
        .then(callControlUUID => {
          this.setState({ callControlUUID })
          console.debug('set call control UUID', callControlUUID)
        })
        .then(this.contactAgent)
        .then(resolve)
        .catch(err => {
          console.error('could not start live transfer', err)
          reject(err);
        })
    });
  }

  /**
   * Make the API call to initiate the Live Transfer system.
   */
  startLiveTransfer() {
    return new Promise((resolve, reject) => {
      request
        .post('/phone/live_conference/v2')
        .send({
          session_uuid:     this.props.sessionUUID,
          rep_id:           this.props.rep.id,
          lead_id:          this.props.lead.id,
          agent_id:         this.props.agent.id,
          conference_type:  this.props.conferenceType,
        })
        .then(({ body }) => {
          console.debug('start live transfer API response', body)
          resolve(body.call_control_uuid);
        })
        .catch(err => reject(err) )
    });
  }

  /**
   * Handle incoming event from WebSocket
   *
   * @param {object} payload
   */
  onEventReceived(payload) {
    console.log(`Event received: ${payload.event}`)
    console.log(payload)
    switch(payload.event) {
      case 'call_mode_changed':
        this.setState({ callStatus: payload.payload.call_mode });
        break
      case 'conference_call_start':
        this.setState({ conferenceCallStartTime: Date.parse(payload.timestamp) });
        break
      case 'lead_connected':
        this.setState({ leadCallStartTime: Date.parse(payload.timestamp) });
        this.setState({ callStatus: payload.event });
        break
      case 'agent_ready':
        this.setState({ agentCallStartTime: Date.parse(payload.timestamp) });
        this.setState({ callStatus: payload.event });
        break
      case 'agent_disconnected':
        this.setState({ agentCallStartTime: null });
        this.setState({ callStatus: payload.event });
        break
      default:
        this.setState({ callStatus: payload.event });
    }
  };

  renderSubscription() {
    return <ActionCable channel={ {channel: 'Phone::LiveConferenceChannel', id: this.state.callControlUUID } } onReceived={ this.onEventReceived } />;
  };

  updateCallMode(mode, callback = () => {}) {
    return new Promise((resolve, reject) => {
      request.post('/phone/live_conference/v2/update_call_mode')
        .send({
          call_control_uuid: this.state.callControlUUID,
          call_mode: mode
        }).then(res => { resolve(res); })
        .catch(err => {
          console.error('error on updateCallMode', err);
          reject(err);
        });
    });
  }
}
