const disableOption = (id, currentMatchID) => {
  return id && (id === currentMatchID);
};

const MatchedAgentOptions = ({ matches, currentMatch }) => {
  const dupMatches = matches.slice();
  dupMatches.unshift({});

  const options = dupMatches.map(match => {
    const { match_id, agent_id, agent_name } = match;
    return <option disabled={disableOption(match_id, currentMatch.match_id)} key={agent_id} value={match_id}>{agent_name}</option>;
  });

  return (
    <select className='form-control input-lg rex-custom-select' name='new_match_id' id='new_match_id'>
      {options}
    </select>
  );
};

export default MatchedAgentOptions;
