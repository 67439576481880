import request from 'superagent';
import { Component } from 'react';

export default class LeadBuyerPropertyInfo extends Component {
  constructor(props){
    super(props)

    this.state = {
      searchingForCities: false,
      foundCities: []
    };

    this.fetchNearbyCities = this.fetchNearbyCities.bind(this);
  }

  homePriceList() {
    const homePrices = this.props.formData.buyer.home_prices;

    return(
      <select value={ this.props.homePrice } onChange={(e) => this.props.handleChange('homePrice', e)}>
        <option value='' key={0}>Select</option>

        {
          Object.keys(homePrices).map((key, i) => {
            const value = homePrices[key];
            const encodedPriceRange = btoa(JSON.stringify(value));

            return <option value={ encodedPriceRange } key={ i }>{ key }</option>
          })
        }
      </select>
    )
  }

  statesList() {
    let { handleChange, state, formData, country } = this.props;
    let stateType = country === 'CA' ? formData.provinces : formData.states
    return (
      <select value={state || ''} onChange={(e) => handleChange('state', e)}>
        <option value='' key={0}>Select</option>
        {stateType.map((name, idx) => <option value={name} key={idx + 1}>{name}</option>)}
      </select>
    )
  }

  countryList() {
    let { handleChange, country, formData } = this.props;
    let countryCodes = Object.keys(formData.countries);
    return (
      <select value={country || ''} onChange={(e) => handleChange('country', e)}>
        {countryCodes.map((code, idx) => <option value={code} key={idx}>{formData.countries[code]}</option>)}
      </select>
    )
  }

  bedsList() {
    return(
      <select value={this.props.beds} onChange={(e) => this.props.handleChange('beds', e)}>
        <option value='' key={0}>Select</option>
        {this.props.formData.buyer.beds.map((number, idx) => <option value={number} key={idx + 1}>{number}</option>)}
      </select>
    )
  }

  bathsList() {
    return(
      <select value={this.props.baths} onChange={(e) => this.props.handleChange('baths', e)}>
        <option value='' key={0}>Select</option>
        {this.props.formData.buyer.baths.map((number, idx) => <option value={number} key={idx + 1}>{number}</option>)}
      </select>
    )
  }

  timeFramesList() {
    return(
      <select value={this.props.timeFrame} onChange={(e) => this.props.handleChange('timeFrame', e)}>
        <option value='' key={0}>Select</option>
        {this.props.formData.buyer.time_frame.map((time, idx) => <option value={time} key={idx + 1}>{time}</option>)}
      </select>
    )
  }

  mortgageStatusList() {
    return(
      <select value={this.props.mortgageStatus} onChange={(e) => this.props.handleChange('mortgageStatus', e)}>
        {this.props.formData.buyer.mortgage_status.map((statusPair, idx) => <option value={statusPair[1]} key={idx + 1}>{statusPair[0]}</option>)}
      </select>
    )
  }

  cityStateData() {
    return {
      city: this.props.city,
      state: this.props.state
    }
  }

  fetchNearbyCities() {
    if (this.props.city == null || this.props.state == null || this.props.city == '' || this.props.state == '') {
      let errors = {
        city_state: ["City and State can't be blank"]
      }
      this.props.handleResponseErrors(errors);
    } else {
      if (this.state.searchingForCities == false) {
        this.props.handleResponseErrors({});
        this.setState({ searchingForCities: true });

        request
          .get('/api/locations/nearby_cities')
          .query(this.cityStateData())
          .then(({ body }) => {
            console.log(`Received buyer cities data.`);
            console.log(body);

            this.props.handleFoundCitiesSet(body.cities || []);
            this.setState({ searchingForCities: false });
          })
          .catch(error => {
            console.error('Failed to get nearby cities.');
            console.error(error);
          });
      }
    }
  }

  inNearbyCities(city) {
    let inCities = false
    this.props.nearbyCities.forEach(function(nc) {
      if (nc.city == city) {
        inCities = true;
      }
    });
    return inCities;
  }

  renderNearbyCities() {
    return this.props.foundCities.map((obj) => {
      return <label className={"city-button-label " + (this.inNearbyCities(obj.city) ? 'label-highlight' : '')} onClick={() => this.props.handleNearbyCitiesChange(obj)}>{obj.city}, {obj.state} ({obj.distance.toFixed(2)} mi.)</label>
    });
  }

  renderLoadingCities() {
    if (this.state.searchingForCities) {
      return <p>Searching...</p>
    }
  }

  render() {
    let { handleChange, formData, city, state, homePrice, beds, baths, timeFrame, mortgageStatus, country } = this.props
    let stateType = country === 'CA' ? 'Province' : 'State';

    return(
      <div className='fieldset'>
        <div className='row'>
          <div className='col-sm-5 right-column'>
            <label className='required-select-label field-label'>Country<span className='required-field'>*</span></label>
            {this.countryList()}
          </div>
          <div className='col-sm-5 left-column'>
            <label className='field-label'>City<span className='required-field'>*</span></label>
            <input
              type='text'
              value={city}
              onChange={(e) => handleChange('city', e)}
            />
          </div>
          <div className='col-sm-5 right-column'>
            <label className='required-select-label field-label'>{ stateType }<span className='required-field'>*</span></label>
            {this.statesList()}
          </div>
      </div>
      <div className='row'>
        <div className='col-sm-3 left-column'>
          <a className='nearby-city-link' onClick={this.fetchNearbyCities}>Select Nearby Cities</a>
          {this.renderLoadingCities()}
        </div>
        <div className='col-sm-7 right-column'>
          <ul>
            {this.renderNearbyCities()}
          </ul>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-5 left-column'>
          <label className='required-select-label field-label'>Home Price<span className='required-field'>*</span></label>
          {this.homePriceList()}
        </div>
        <div className='col-sm-5 right-column'>
          <label className='select-label field-label'>Time Frame</label>
          {this.timeFramesList()}
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-5 left-column'>
          <label className='select-label field-label'>Min. Beds</label>
          {this.bedsList()}
        </div>
        <div className='col-sm-5 right-column'>
          <label className='select-label field-label'>Min. Baths</label>
          {this.bathsList()}
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-5 left-column'>
         <label className='select-label field-label'>Mortgage Status</label>
          {this.mortgageStatusList()}
        </div>
      </div>
      <div className='row key-row'>
        <div className='col-sm-5'>
          <p className='required-key-text'>* Indicates required field</p>
        </div>
      </div>
      <div className='row hr-row'>
        <hr className='hr-bottom'></hr>
      </div>
    </div>

    )
  }
}
