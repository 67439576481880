import React from 'react';

import BaseInputContainer from '../index';

import View from './view';

export default class CheckboxComponent extends BaseInputContainer {
  handleChange(e) {
    const { dispatch } = this.props;
    const { name, checked } = e.target;

    this.validateField(name, checked);
    this.setInfoField(name, checked);
  }

  render() {
    const {
      classNames,
      display,
      id,
      index,
      property
    } = this.props;

    const {
      focused,
      valid,
      value,
     } = this.state;

    return(
      <View
        classNames={ classNames }
        display={ display }
        focused={ focused }
        id={ id }
        index={ index }
        name={ property }
        valid={ valid }
        value={ value }

        onChange={ this.handleChange }
        onFocus={ this.handleOnFocus }
      />
    );
  }
}
