import { Component }  from 'react';

export default class ErrorWrapper extends Component {
  constructor() {
    super();

    this.state = {};
  }

  componentWillReceiveProps(props) {
    this.setState({ hasError: props.error });
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='tool-error'>
          <i className='fa fa-exclamation-triangle fa-5x warn'></i>
          <h3>Something went wrong</h3>
          <p>{ this.props.errorMessage }</p>
        </div>
      );
    }

    return this.props.children;
  }
}
