import React, { useEffect, useState } from 'react';
import AddAltosStreamModal from './AddAltosStreamModal';
import AgentCard from './AgentCard';
import EditProfileUrlModal from './EditProfileUrlModal';
import Tooltip from '../../FormBuilder/Tooltip';

import Fetch from '../../../common/fetch';
import { altosTableHeaders, rexAgentsTableHeaders } from '../shared/tables';
import "../styles.scss";

const Details = (props) => {
  const {
    paths,
    canonicalAid: initCanonicalAid,
  } = props.data;

  const [canonicalAid, setCanonicalAid] = useState(initCanonicalAid)
  const [state, setState] = useState(props.data);
  const [altosStreamModalStatus, setAltosStreamModalStatus] = useState('hidden');
  const [editSlugModalStatus, setEditSlugModalStatus] = useState('hidden');

  const submit = async (additionalOptions = {}) => {
    const res = await Fetch.post({
      url: paths.submit,
      payload: {
        canonicalAid,
        altosStreams,
        rexAgents,
        agentVerified,
        agentHidden,
        optOutAdspend,
        ...additionalOptions,
      }
    })

    if (res.cluster) {
      setState(res.cluster);
      setCanonicalAid(res.cluster.canonicalAid);
      window.location.pathname = `/rea_clusters/details/${res.cluster.canonicalAid}`
    } else if (res.serverErrors || res.error) {
      alert(res.serverErrors || res.error);
    }
  }

  useEffect(() => {
    document.title = 'Agent Cluster Editor';
  }, [])

  useEffect(() => {
    if (state.updated) {
      window.onbeforeunload = function () {
        return 'Are you sure you want to leave?';
      };
    } else {
      window.onbeforeunload = null;
    }
  }, [state.updated])

  const {
    address,
    agentHidden,
    agentVerified,
    agentVerifiedText,
    altosStreams,
    company,
    name,
    opsVerified,
    opsVerifiedText,
    optOutAdspend,
    photo,
    rexAgents,
    slug,
    updated,
  } = state

  const updateState = (options) => {
    setState((prevState) => ({ ...prevState, ...options }));
  }

  const handleSubmit = async (e) => {
    const target = e.currentTarget;
    target.disabled = true;
    target.classList.add('submitting');

    await submit();

    updateState({ updated: false })
    target.disabled = false;
    target.classList.remove('submitting');
  }

  const markAgentVerified = async () => {
    if (window.confirm('Clicking MARK AGENT VERIFIED will record this Agent as being self verified.')) {
      submit({ agentVerified: true });
    }
  }

  const toggleAdspend = async () => {
    if (!optOutAdspend) {
      if (window.confirm(`Are you sure you want to opt out of Adspend?`)) {
        submit({ optOutAdspend: !optOutAdspend });
      }
    } else {
      submit({ optOutAdspend: !optOutAdspend });
    }
  }

  const hideAgent = async (e) => {
    const target = e.currentTarget;

    if (window.confirm(`Are you sure you want to ${agentHidden ? 'publish' : 'hide'} this profile from realestateagents.com?`)) {
      target.disabled = true;

      submit({ agentHidden: !agentHidden });

      target.disabled = false;
    }
  }

  const promptMarkPrimary = (e) => {
    const { index } = e.currentTarget.dataset;
    const updatedRexAgents = rexAgents;

    if (window.confirm('Clicking MARK AS PRIMARY will mark the selected REX Agent acount as the primary account')) {
      for (let i = 0; i < updatedRexAgents.length; i += 1) {
        updatedRexAgents[i].isPrimary = (i === parseInt(index, 10))
        if (i === parseInt(index, 10)) {
          updatedRexAgents[i].isPrimary = true;
          updatedRexAgents[i].selected = true;
        }
      }

      updateState({
        rexAgents: updatedRexAgents,
        updated: true,
      });
    }
  }

  const handleCheckAndUpdate = (listString, e) => {
    if (!listString) {
      return;
    }

    const updatedStreams = [...state[listString]];
    const {
      checked,
      dataset: {
        index,
      },
    } = e.currentTarget;

    updatedStreams[parseInt(index, 10)].selected = checked;
    updateState({
      [listString]: updatedStreams,
      updated: true,
     });
  }

  const handleAltosCheck = (e) => handleCheckAndUpdate('altosStreams', e);

  const handleRexCheck = (e) => handleCheckAndUpdate('rexAgents', e);

  const altosTableRows = (rows, handleCheck) => {
    if (rows && rows.length < 1) {
      return (
        <div className="form-table-row">
          <div className="table-column col-width-100">
            No Altos data found.
          </div>
        </div>
      )
    }

    return (
      <>
        {
          rows.map(({
            name,
            canonicalAid,
            phone,
            email,
            brokerage,
            cities,
            zipCodes,
            transactions,
            selected,
            suggested,
            urlSlug,
          }, idx) => (
            <div
              key={idx}
              className="form-table-row"
            >
              <div className="table-column col-width-5">
                <input
                  type="checkbox"
                  data-index={idx}
                  onChange={handleCheck}
                  checked={selected}
                />
              </div>
              <div className="table-column col-width-10">
                <span className="font-2">{name}</span>
                {
                  suggested && (<div className="suggested-pill">SUGGESTED</div>)
                }
              </div>
              <div className="table-column col-width-10">
                <span className="font-1">{canonicalAid}</span>
              </div>
              <div className="table-column col-width-10">
                <span className="font-1">{phone}</span>
              </div>
              <div className="table-column col-width-15">
                <span className="font-1">{email}</span>
              </div>
              <div className="table-column col-width-10">
                <span className="font-1">{brokerage}</span>
              </div>
              <div className="table-column col-width-10">
                <span className="font-1">{cities}</span>
              </div>
              <div className="table-column col-width-10">
                {
                  zipCodes.map((zip, idx) => <div key={idx} className="jucy font-1">{zip}</div>)
                }
              </div>
              <div className="table-column col-width-10">
                {
                  transactions.map((trans, idx) => <div key={idx} className="jucy font-1">{trans}</div>)
                }
              </div>
              <div className="table-column col-width-10">
                <a target="_blank" href={`${props.reaUrl}agent/${urlSlug}`}>
                  {urlSlug}
                </a>
              </div>
            </div>
          ))
        }
      </>
    )
  }

  const rexAgentRows = (rows, handleCheck) => {
    if (rows && rows.length < 1) {
      return (
        <div className="form-table-row">
          <div className="table-column col-width-100">
            No agents found.
          </div>
        </div>
      )
    }

    return (
      <>
        {
          rows.map(({
            name,
            phone,
            email,
            brokerage,
            id,
            status,
            createdDate,
            isPrimary,
            selected,
          }, idx) => (
            <div
              key={idx}
              className="form-table-row"
            >
              <div className="table-column col-width-5">
                <input
                  type="checkbox"
                  data-index={idx}
                  onChange={handleCheck}
                  checked={selected}
                />
              </div>
              <div className="table-column col-width-10">
                <span className="font-2">{name}</span>
              </div>
              <div className="table-column col-width-10">
                <span className="font-1">{phone}</span>
              </div>
              <div className="table-column col-width-20">
                <span className="font-1">{email}</span>
              </div>
              <div className="table-column col-width-10">
                <span className="font-1">{brokerage}</span>
              </div>
              <div className="table-column col-width-10">
                <a href={`/a/${id}`} target="_blank">
                  <span className="font-1">{id}</span>
                </a>
              </div>
              <div className="table-column col-width-10">
                <span className="font-1">{status}</span>
              </div>
              <div className="table-column col-width-10">
                <span className="font-1">{createdDate}</span>
              </div>

              <div className="table-column col-width-15">
                {
                  isPrimary
                    ? <span className="profile-link-button no-underline primary">PRIMARY</span>
                    : (
                      <button
                        className="profile-link-button no-underline"
                        type="button"
                        data-index={idx}
                        onClick={promptMarkPrimary}
                      >
                        Mark Primary
                      </button>
                    )
                }
              </div>
            </div>
          ))
        }
      </>
    )
  }

  const promptBannerClassList = ['prompt-banner'];

  if (updated) {
    promptBannerClassList.push('visible');
  }

  return (
    <div className="rea-details-wrapper">
      <div className="prompt-banner-wrapper">
        <div className={promptBannerClassList.join(' ')}>
          You have unsaved changes.  Please save before leaving this page. &nbsp;&nbsp;
          <button
            type="button"
            className="profile-edit-button"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
      <div className="rea-details">
        <a
          href={paths.search}
        >
          Back to Search
        </a>
        <AgentCard
          agent={{
            agentHidden,
            address,
            company,
            name,
            photo,
            slug,
            opsVerified,
            opsVerifiedText,
            optOutAdspend,
            agentVerified,
            agentVerifiedText,
          }}
          hideAgent={hideAgent}
          markAgentVerified={markAgentVerified}
          reaUrl={props.reaUrl}
          showSlugEditModal={() => setEditSlugModalStatus('show')}
          toggleAdspend={toggleAdspend}
        />
        <div className="rea-cluster-tables form-builder">
          <div className="header-wrapper">
            <h2>Associated Altos Streams</h2>
            <Tooltip
              tip="Uncheck the box next to the data stream in order to remove it from the cluster."
            />
          </div>
          <div className="form-table">
            {altosTableHeaders()}
            <div className="form-table-data">
              {altosTableRows(altosStreams, handleAltosCheck)}
            </div>
            <div className="form-table-bottom-utilities">
              <button
                type="button"
                className="profile-link-button no-underline"
                onClick={() => setAltosStreamModalStatus('show')}
              >
                + ADD AGENTS TO CLUSTER
              </button>
            </div>
          </div>
          <h2>Associated REX Agents</h2>
          <div className="form-table">
            {rexAgentsTableHeaders()}
            <div className="form-table-data">
              {rexAgentRows(rexAgents, handleRexCheck)}
            </div>
          </div>
        </div>
        <button
          type="button"
          className="profile-edit-button submit"
          onClick={handleSubmit}
        >
          <i className="fas fa-sync-alt fa-spin" />
          Save Changes
        </button>
      </div>
      <AddAltosStreamModal
        altosStreamModalStatus={altosStreamModalStatus}
        altosStreams={altosStreams}
        altosTableRows={altosTableRows}
        rexAgents={rexAgents}
        closeModal={() => setAltosStreamModalStatus('hidden')}
        apListingSearchPath={paths.searchListings}
        apAgentsSearchPath={paths.searchApAgents}
        updateState={updateState}
      />
      <EditProfileUrlModal
        closeModal={() => setEditSlugModalStatus('hidden')}
        editPath={paths.search}
        editSlugModalStatus={editSlugModalStatus}
        slug={slug}
        updateState={updateState}
      />
    </div>
  )
};

export default Details;
