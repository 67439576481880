import request from "superagent";

const MarketBuilder = ({ state, updateState }) => {
  const marketResults = state.market.marketResults;

  const updateSearchInput = (e) => {
    const market = { ...state.market, searchInput: e.currentTarget.value }
    updateState({ market })
  }

  const updateMarketAreas = (e) => {
    const geo = e.currentTarget.value;
    const selected = state.market.selected;

    selected.includes(geo)
      ? selected.splice(selected.indexOf(geo), 1)
      : selected.push(geo)
    updateState({ market: { ...state.market, selected } })
  }

  // Group market areas by their name
  const groupAreas = areas => {
    return areas.reduce(
      (result, area) => ({
        ...result,
        [area["name"]]: [...(result[area["name"]] || []), area]
      }),
      {}
    );
  }

  const lookupMarket = () => {
    const geo = state.market.searchInput;

    if(!geo) {
      alert("Search input is empty");
      return;
    }

    request
      .get("/ingest/lookup_market")
      .query({ geo })
      .then(({ body }) => {
        const marketResults = groupAreas(body.market_area)
        const market = { ...state.market, marketResults }
        updateState({ market })
       })
      .catch(() => alert('An error has occurred. Please double check the geo.'))
  }

  return (
    <section className="marketing-area-section">
      <section className="input-field w24p">
        <label>Market area postal codes</label>
        <section className="submit">
          <input
            type="input"
            value={state.market.searchInput}
            onChange={updateSearchInput}
          />
          <input
            type="submit"
            name="Search"
            value="Search"
            onClick={lookupMarket}
          />
        </section>
        <section className={state.market.selected.length ? 'selected' : 'hidden'}>
          <label>Selected geos</label>
          <div>{state.market.selected.join(', ')}</div>
        </section>
      </section>
      <section className="marketing-area-results w75p">
        {Object.keys(marketResults)
          .sort()
          .map((area, idx) => (
            <div key={idx} className="area w24p">
              <label className="area-title">{area}</label>
              <div className="area-values">
                {marketResults[area].map((location, idx2) => (
                  <label key={idx2} className="area-value">
                    <input
                      type="checkbox"
                      value={location.geo}
                      checked={state.market.selected.includes(location.geo)}
                      onClick={updateMarketAreas}
                    />
                    {location.geo}
                  </label>
                ))}
              </div>
            </div>
          ))}
      </section>
    </section>
  );
};

export default MarketBuilder;
