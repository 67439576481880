import { Component } from 'react';

export default class WishlistSearchModal extends Component {
  constructor(props) {
    super(props)

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  showModal() {
    return this.props.state.showWishListSearchModal
      ? 'modal-overlay'
      : 'hidden';
  }

  closeModal() {
    this.props.updateState({ showWishListSearchModal: false });
  }

  handleSearch(e) {
    if (e.key === 'Enter') this.props.searchWishList(e.currentTarget.value)
  }

  searchBar(){
    return(
      <div className="search-bar">
        Search &nbsp;
        <input
          className='search-wish-list-input'
          onKeyUp={(e) => this.handleSearch(e)}
        />
      </div>
    )
  }

  render() {
    return (
      <div className={this.showModal()} onClick={this.closeModal}>
        <div className='title-modal' onClick={(e) => e.stopPropagation()}>
          <div className='header'>
            <h3>Wishlist Search</h3>
            <div className='close-button' onClick={this.closeModal}>
              <i className='fa fa-times-circle' />
            </div>
          </div>
          { this.content() }
        </div>
      </div>
    )
  }

  content() {
    let wishlists = this.props.state.wishlistSearchQueryResults
    if (wishlists.length){
      return (
        <div className='content'>
          { this.searchBar() }
          <div className="wishlist-table-content">
            <div className='wishlist-table-header'>
              <div className="wishlist-column w140 first-name">First Name</div>
              <div className="wishlist-column w140 last-name">Last Name</div>
              <div className="wishlist-column w300 email">Email</div>
              <div className="wishlist-column w140 phone">Phone</div>
              <div className="wishlist-column w140 manager">Manager</div>
              <div className="wishlist-column w140 brand">Brand</div>
              <div className="wishlist-column w140 city">City</div>
              <div className="wishlist-column w140 state">State</div>
            </div>
            { wishlists.map((wish, idx) => {
              return(
                <div key={idx} className='wishlist-table-row'>
                  <div className='wishlist-column w140 first-name'>{wish.first_name}</div>
                  <div className='wishlist-column w140 last-name'>{wish.last_name}</div>
                  <div className="wishlist-column w300 email">{wish.email}</div>
                  <div className="wishlist-column w140 phone">{wish.phone}</div>
                  <div className="wishlist-column w140 manager">{wish.manager.name}</div>
                  <div className="wishlist-column w140 brand">{wish.manager.brand}</div>
                  <div className="wishlist-column w140 city">{wish.manager.city}</div>
                  <div className="wishlist-column w140 state">{wish.manager.state}</div>
                </div>
              )
            })}
          </div>
        </div>
      )
    } else {
      return (
        <div className='content'>
          {this.searchBar()}
          No results
        </div>
      )
    }
  }
}
