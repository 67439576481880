const Lead = ({ lead }) => (
  <div className="floating-pane lead-pane mb-30">
    <div className="row">
      <div className="col-md-8 name-type">
        { lead.name }
      </div>
      <div className="col-md-4 text-right">
        <span className="rex-link my-5 mr-15">
          <a href={ `/l/${lead.id}` } target="_blank">#{ lead.id }</a>
        </span>
        <span className="pill-type">{ lead.type }</span>
      </div>
    </div>
    <div className="mb-25">
      <div className="row">
        <div className="col-md-12">
          <div className="info-type">
            <i className="fa fa-phone mr-5"></i> { lead.phone }
          </div>
          <div className="info-type">
            <i className="fa fa-map-marker mr-5"></i> { lead.location } <span className="label-type">| { lead.language }</span>
          </div>
        </div>
      </div>
    </div>
    <div className="mb-25">
      <div className="row">
        <div className="col-md-12 user-details">
          <div className="user-detail-item">
            <span className="label-type">Price Range</span>
            <div className="item-detail">{ lead.price_range }</div>
          </div>
          <div className="user-detail-item">
            <span className="label-type">Referral Fee</span>
            <div className="item-detail">{ lead.referral_fee }</div>
          </div>
          <div className="user-detail-item">
            <span className="label-type">Branding</span>
            <div className="item-detail">{ lead.microsite_name }</div>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 section-header">
        <i className="fa fa-commenting"></i> Lead Comments
      </div>
      <div className="col-md-12">
        <pre className="lead-comments mb-0">{ lead.comments }</pre>
      </div>
    </div>
  </div>
);

export default Lead;
