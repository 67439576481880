import React, { Component } from 'react';

import MatchWall from './MatchWall';

export default class AgentContainer extends Component {
  constructor(props) {
    super(props);

    // Must use new OOP refs
    this.agentList = React.createRef();

    this.state = {
      currentMatch: {
        agent: {
          past_12_months_sales: '',
          experience_in_community: '',
          specialties: '',
          designations: '',
          name_on_license: '',
          years_in_real_estate: '',
          education: '',
          license_state: '',
          license_type: '',
          agent_awards: '',
          company_position: '',
          comapny_name: '',
          comapny_address: '',
          comapny_city: '',
          comapny_state: '',
          comapny_zip: '',
          languages: '',
          hobbies: '',
          community_involvement: '',
          personal_family: '',
          text_message_number: '',
          phone_office: '',
          email: ''
        },
        lead: {},
        match: {
          proposal_submitted_date: '',
          proposal_viewed_date: ''
        },
        wall: []
      },
      selectedMatch: 0,
      disableCallAgent: false,
    };

    this.scrollRight = this.scrollRight.bind(this);
    this.scrollLeft  = this.scrollLeft.bind(this);
    this.stopScroll  = this.stopScroll.bind(this);
    this.scroll      = this.scroll.bind(this);
    this.switchMatch = this.switchMatch.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.matches.length) {
      this.setState({ currentMatch: nextProps.matches[0], selectedMatch: 0 });
    }
  }

  scrollRight() {
    this.scrollTimeout = setInterval(this.scroll, 50, 'right');
  }

  scrollLeft() {
    this.scrollTimeout = setInterval(this.scroll, 50, 'left');
  }

  stopScroll() {
    clearInterval(this.scrollTimeout);
  }

  scroll(direction) {
    let agentListRef = this.agentList.current;

    if (direction === 'right') {
      agentListRef.scrollLeft += 40;
    } else {
      agentListRef.scrollLeft += 40;

      if (agentListRef.scrollLeft < 40) {
        agentListRef.scrollLeft = 0;
      } else {
        agentListRef.scrollLeft -= 40;
      }
    }
  }

  brand(match) {
    if (match.agent.company_name && match.agent.company_name.length && match.agent.company_name !== 'No Brand') {
      return '(' + Util.shortenString(match.agent.company_name, 17) + ')';
    } else {
      return '(N/A)';
    }
  }

  switchMatch(e) {
    const id = parseInt(e.currentTarget.dataset.id)
    const { matches } = this.props;

    this.setState({ currentMatch: matches[id], selectedMatch: id });
  }

  renderAgentNames() {
    const {
      matches,
    } = this.props;

    const {
      selectedMatch,
    } = this.state;

    const agents = matches.map(function(match, idx) {
      if (idx === matches.length - 1) {
        var style = { marginRight: '100px' };
      } else {
        var style = null;
      }

      if (selectedMatch === idx) {
        var classes = 'agent-name inline-block height-100 agent-name-selected';
      } else {
        var classes = 'agent-name inline-block height-100';
      }

      return (
        <div key={idx} data-id={idx} onClick={this.switchMatch} className={classes} style={style}>
          { match.agent.rex_prime === true ?
              <img style={{ height: '15px', paddingBottom: '3px' }} src={'/images/prime.png'} /> :
              null
          }
          <div className='name'>
            {match.agent.first_name} {match.agent.last_name}
          </div>
          <div className='brand'>
            {this.brand(match)}
          </div>
        </div>
      )
    }.bind(this));

    return agents;
  }

  currentMatch() {
    return this.state.currentMatch;
  }

  proposalSent() {
    if(this.currentMatch().match.proposal_submitted_date && this.currentMatch().match.proposal_submitted_date.length) {
      return `Yes (${this.currentMatch().match.proposal_submitted_date})`;
    } else {
      return 'No';
    }
  }

  proposalViewed() {
    if(this.currentMatch().match.proposal_viewed_date && this.currentMatch().match.proposal_viewed_date.length) {
      return `Yes (${this.currentMatch().match.proposal_viewed_date})`;
    } else {
      return 'No';
    }
  }

  companyBrand() {
    if (this.currentMatch().agent.company_name && this.currentMatch().agent.company_name.length && this.currentMatch().agent.company_name !== 'No Brand') {
      return this.currentMatch().agent.company_name;
    } else {
      return 'N/A';
    }
  }

  companyAddress() {
    let agent = this.currentMatch().agent;
    if(agent.company_address) {
      return `${agent.company_address}, ${agent.company_city}, ${agent.company_state} ${agent.company_zip}`;
    } else {
      return 'N/A';
    }
  }

  brandStyle() {
    if (this.companyBrand().length > 30) {
      return { fontSize: '14px' };
    } else {
      return { fontSize: '16px' };
    }
  }

  renderControlSection() {
    var paneHeader = document.getElementsByClassName('agent-pane-header')[0];
    if(paneHeader && paneHeader.offsetWidth < paneHeader.scrollWidth) {
      return (
        <div className='control-button-container absolute'>
          <div className='left-control inline-block display-inline-vertical-center text-center' onMouseDown={this.scrollLeft} onMouseUp={this.stopScroll}>
            <i className='fa fa-caret-left'></i>
          </div>
          <div className='right-control inline-block display-inline-vertical-center text-center' onMouseDown={this.scrollRight} onMouseUp={this.stopScroll}>
            <i className='fa fa-caret-right'></i>
          </div>
        </div>
      )
    }
  }

  renderPast12Months() {
    if(this.currentMatch().agent.past_12_months_sales.length) {
      return (
        <div className='info-block border-box'>
          <div className='title'>Past 12 mo Sales</div>
          <div className='value'>{this.currentMatch().agent.past_12_months_sales}</div>
        </div>
      );
    }
  }

  renderDesignations() {
    if(this.currentMatch().agent.designations.length) {
      return (
        <div className='info-block border-box'>
          <div className='title'>Designations</div>
          <div className='value'>{this.currentMatch().agent.designations}</div>
        </div>
      )
    }
  }

  renderYearsInRealEstate() {
    if(this.currentMatch().agent.years_in_real_estate) {
      return (
        <div className='info-block border-box'>
          <div className='title'>Years in Real Estate</div>
          <div className='value'>{this.currentMatch().agent.years_in_real_estate}</div>
        </div>
      )
    }
  }

  experienceInCommunity() {
    if(this.currentMatch().agent.experience_in_community.length) {
      return (
        <div className='info-block border-box'>
          <div className='title'>Neighborhoods</div>
          <div className='value'>
            {this.currentMatch().agent.experience_in_community}
          </div>
        </div>
      );
    }
  }

  renderSpecialites() {
    if(this.currentMatch().agent.specialties.length) {
      return (
        <div className='info-block border-box'>
          <div className='title'>Specialty Markets</div>
          <div className='value'>
            {this.currentMatch().agent.specialties}
          </div>
        </div>
      )
    }
  }

  url() {
    return `${window.BACKEND_PATH}/a/${this.currentMatch().agent.id}`
  }

  education() {
    if(this.currentMatch().agent.education.length) {
      return (
        <div className='info-block border-box'>
          <div className='title'>Education</div>
          <div className='value'>
            {this.currentMatch().agent.education}
          </div>
        </div>
      )
    }
  }

  licenseLocation() {
    if(this.currentMatch().agent.license_state.length || this.currentMatch().agent.license_type.length) {
      return (
        <div className='info-block border-box'>
          <div className='title'>Licensing State</div>
          <div className='value'>
            {`${this.currentMatch().agent.license_state} ${this.currentMatch().agent.license_type}`}
          </div>
        </div>
      )
    }
  }

  agentAwards() {
    if(this.currentMatch().agent.agent_awards.length) {
      return (
        <div className='info-block border-box'>
          <div className='title'>Agent Awards</div>
          <div className='value'>
            {this.currentMatch().agent.agent_awards}
          </div>
        </div>
      )
    }
  }

  companyPosition() {
    if(this.currentMatch().agent.company_position.length) {
      return (
        <div className='info-block border-box'>
          <div className='title'>Company Position</div>
          <div className='value'>
            {this.currentMatch().agent.company_position}
          </div>
        </div>
      )
    }
  }

  languages() {
    if(this.currentMatch().agent.languages.length) {
      return (
        <div className='info-block border-box'>
          <div className='title'>Languages</div>
          <div className='value'>
            {this.currentMatch().agent.languages}
          </div>
        </div>
      )
    }
  }

  hobbies() {
    if(this.currentMatch().agent.hobbies.length) {
      return (
        <div className='info-block border-box'>
          <div className='title'>Hobbies</div>
          <div className='value'>
            {this.currentMatch().agent.hobbies}
          </div>
        </div>
      )
    }
  }

  communityInvolvement() {
    if(this.currentMatch().agent.community_involvement.length) {
      return (
        <div className='info-block border-box'>
          <div className='title'>Community Involvement</div>
          <div className='value'>
            {this.currentMatch().agent.community_involvement}
          </div>
        </div>
      )
    }
  }

  personalFamily() {
    if(this.currentMatch().agent.personal_family.length) {
      return (
        <div className='info-block border-box'>
          <div className='title'>Personal Family</div>
          <div className='value'>
            {this.currentMatch().agent.personal_family}
          </div>
        </div>
      )
    }
  }

  phoneNumber() {
    if(this.currentMatch().agent.text_message_number) {
      return <div style={this.brandStyle()} className='agent-phone'>{`M: ${Util.formatPhoneNumber(this.currentMatch().agent.text_message_number)}`}</div>
    }else if(this.currentMatch().agent.phone_office) {
      return <div style={this.brandStyle()} className='agent-phone'>{`O: ${Util.formatPhoneNumber(this.currentMatch().agent.phone_office)}`}</div>
    }else {
      return
    }
  }

  render() {
    return (
      <div className='profile-review-agent-container relative'>
        <div className='agent-pane-header' ref={ this.agentList }>
          <div className='agents-title inline-block'>
            AGENTS:
          </div>
          {this.renderAgentNames()}
          {this.renderControlSection()}
        </div>
        <div className='agent-info-container'>
          <div className='col-lg-5 height-100 border-box left-container'>
            <div className='match-wall-container'>
              <div className='wall-container'>
                <div className='profile-section'>
                  <div className='profile-pic'>
                    <img className='inner' src={this.currentMatch().agent.photo}/>
                  </div>
                  <div className='name'>
                    <a href={this.url()} target='_blank'>
                      {`${this.currentMatch().agent.first_name} ${this.currentMatch().agent.last_name}`}
                    </a>
                  </div>
                  <div style={this.brandStyle()} className='brand'>{this.companyBrand()}</div>
                  <br/>
                  {this.phoneNumber()}
                  <div style={this.brandStyle()} className='agent-phone'>{this.currentMatch().agent.email}</div>
                  <div style={this.brandStyle()} className='address'>{this.companyAddress()}</div>
                  <MatchWall match={this.currentMatch()}/>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-7 height-100 border-box right-container'>
            <div className='proposal-section height-100'>
              <div className='info-container'>
                <div className='proposal-header'>
                  <div className='col-lg-4 height-100 border-box no-left-padding'>
                    <div className='attribute'>Proposal Sent</div>
                    <div className='value'>{this.proposalSent()}</div>
                  </div>
                  <div className='col-lg-4 height-100 border-box no-left-padding'>
                    <div className='attribute'>Proposal Viewed</div>
                    <div className='value'>{this.proposalViewed()}</div>
                  </div>
                  <div className='col-lg-4 height-100 border-box no-left-padding'>
                    <div className='attribute'>Sign-Up Date</div>
                    <div className='value'>{this.currentMatch().agent.created_at}</div>
                  </div>
                </div>
                {this.renderYearsInRealEstate()}
                {this.renderPast12Months()}
                {this.renderDesignations()}
                {this.experienceInCommunity()}
                {this.renderSpecialites()}
                {this.education()}
                {this.licenseLocation()}
                {this.agentAwards()}
                {this.languages()}
                {this.hobbies()}
                {this.communityInvolvement()}
                {this.personalFamily()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
