import React, { Component } from 'react';
import { store } from '../../../index';

import StandardInput from './index';

import { selectedCountryInfo } from '../utils';
import { getStateProps } from '../../../utils';


import View from './view';

export default class ZipContainer extends StandardInput {
  constructor(props) {
    super(props);
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { dispatch } = store;
    const country = store.getState().info.get('country');

    dispatch({
      type: 'VALIDATE_ZIP',
      data: {
        country,
        key:  name,
        value,
      },
    });

    this.setInfoField(name, value);
  }

  render() {    
    const country = store.getState().info.get('country');
    const countryInfo = selectedCountryInfo(country);

    const { labels } = countryInfo;

    const {
      classNames,
      display,
      id,
      index,
      property
    } = this.props;

    const {
      focused,
      valid,
      value,
     } = this.state;

    return (
      <View
        classNames={ classNames }
        display={ labels.zip }
        focused={ focused }
        id={ id }
        index={ index }
        name={ property }
        valid={ valid }
        value={ value }

        onChange={ this.handleChange }
        onFocus={ this.handleOnFocus }
      />
    );
  }
}
