import React, { useState, useEffect } from 'react';

import ButtonGroup from './_common/ButtonGroup.js';
import OpsPpc from './views/OpsPpc.js';
import Exclusive from './views/Exclusive.js';
import Live from './views/Live.js';
import LeadFromLead from './views/LeadFromLead.js';

import './styles/index.scss';

const LEAD_SERVICE_BUTTONS = [
  "Microsite/Partner",
  "Exclusive",
  "LIVE",
];

const queryString = window.location.search;
const params = new URLSearchParams(queryString);
const lead_id = params.get('lead_id');
const exclusive_id = params.get('exclusive_id');

const initServiceType = () => {
  if (lead_id) return 'lead_from_lead';
  if (exclusive_id) return 'exclusive';

  return 'microsite_partner';
};

const initService = initServiceType();

const LeadCreationForm = props => {
  const {
    lead,
    agent,
    matches,
    consent_options,
    country_options,
    us_state_options,
    ca_province_options,
    source_options,
    microsite_options,
    language_options,
    type_home_options,
    time_frame_options,
    reason_options,
    mortgage_options,
    bed_options,
    bath_options,
  } = props;

  const [service, updateService] = useState(initService);

  const updateFieldHandler = data => {
    const { service } = data;

    updateService(service);
  };

  const resetView = () => {
    const currService = service;

    updateService("reset");
    updateService(currService);
  };

  const updateView = () => {
    switch (service) {
    case "microsite_partner":
      return (
        <OpsPpc
          resetView={ resetView }
          consent_options={ consent_options }
          country_options={ country_options }
          us_state_options={ us_state_options }
          ca_province_options={ ca_province_options }
          source_options={ source_options }
          microsite_options={ microsite_options }
          language_options={ language_options }
          type_home_options={ type_home_options }
          time_frame_options={ time_frame_options }
          reason_options={ reason_options }
          mortgage_options={ mortgage_options }
          bed_options={ bed_options }
          bath_options={ bath_options }
        />
      );

      break;
    case "lead_from_lead":
      return (
        <LeadFromLead
          resetView={ resetView }
          lead={ lead }
          matches={ matches }
          consent_options={ consent_options }
          country_options={ country_options }
          us_state_options={ us_state_options }
          ca_province_options={ ca_province_options }
          source_options={ source_options }
          microsite_options={ microsite_options }
          language_options={ language_options }
          type_home_options={ type_home_options }
          time_frame_options={ time_frame_options }
          reason_options={ reason_options }
          mortgage_options={ mortgage_options }
          bed_options={ bed_options }
          bath_options={ bath_options }
        />
      );

      break;
    case "live":
      return <Live resetView={ resetView } />;
      break;
    case "exclusive":
      return (
        <Exclusive
          agent={ agent }
          resetView={ resetView }
          consent_options={ consent_options }
          country_options={ country_options }
          us_state_options={ us_state_options }
          ca_province_options={ ca_province_options }
          source_options={ source_options }
          microsite_options={ microsite_options }
          language_options={ language_options }
          type_home_options={ type_home_options }
          time_frame_options={ time_frame_options }
          reason_options={ reason_options }
          mortgage_options={ mortgage_options }
          bed_options={ bed_options }
          bath_options={ bath_options }
        />
      );

      break;
    default:
      return undefined;
    }
  };

  const renderHeader = () => {
    const header = (service !== 'lead_from_lead') ? 'Lead Service' : 'Lead From Lead';

    return (
      <div className="header">
        <div className="icon-wrapper"><i className="icon home"></i></div>
        <div className="title-wrapper">
          <h2>{ header }</h2>
        </div>
      </div>
    );
  };

  const renderLeadServiceButtons = () => {
    return (
      <div className="input-row">
        <ButtonGroup attribute="service" buttons={ LEAD_SERVICE_BUTTONS } buttonSelected={ initService } updateFieldHandler={ updateFieldHandler } disabledButtons={ ["LIVE"] } />
      </div>
    );
  };

  return (
    <div className="wrapper">
      <div className="section mt-30">
        { renderHeader() }
        { (service !== 'lead_from_lead') && renderLeadServiceButtons() }
      </div>

      { updateView() }
    </div>
  );
}

export default LeadCreationForm;
