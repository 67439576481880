import { parsePhoneNumberFromString } from 'libphonenumber-js';

const leadParams = lead => {
  const {
    baths,
    beds,
    buyer_cities,
    city,
    comments,
    commercial,
    contact_date,
    country,
    email_address,
    first_name,
    highend,
    home_price_max,
    home_price_min,
    id,
    last_name,
    lead_type,
    contact_time,
    mortgage_status,
    opt_out,
    phone_number_ext,
    rex,
    state,
    street_name,
    street_number,
    text_consent,
    type_home,
    unit_number,
    user_id,
    zip,
  } = lead;

  const buyer_cities_map = {
    buyer_city_0: null,
    buyer_city_1: null,
    buyer_city_2: null,
    buyer_city_3: null,
    buyer_state_0: null,
    buyer_state_1: null,
    buyer_state_2: null,
    buyer_state_3: null,
  };

  if (lead.buyer_cities.length) {
    lead.buyer_cities.forEach((pair, index) => {
      buyer_cities_map[`buyer_city_${index}`] = pair.city;
      buyer_cities_map[`buyer_state_${index}`] = pair.state;
    });
  }

  const intlPhoneNumber = parsePhoneNumberFromString(lead.phone_number, country);

  let phone_number = intlPhoneNumber.number;

  if (phone_number_ext && phone_number_ext.length) {
    phone_number += `ext${phone_number_ext}`
  }

  const {
    buyer_city_0,
    buyer_city_1,
    buyer_city_2,
    buyer_city_3,
    buyer_state_0,
    buyer_state_1,
    buyer_state_2,
    buyer_state_3,
  } = buyer_cities_map;

  return {
    user_attributes: {
      id: user_id,
      first_name,
      last_name,
      email: email_address,
    },
    id,
    lead_type,
    rex,
    contact_date,
    contact_time,
    text_consent,
    buyer_city_0,
    buyer_state_0,
    buyer_city_1,
    buyer_state_1,
    buyer_city_2,
    buyer_state_2,
    buyer_city_3,
    buyer_state_3,
    street_number,
    street_name,
    unit_number,
    city,
    state,
    country,
    zip,
    phone_number,
    home_price_min,
    home_price_max,
    type_home,
    beds,
    baths,
    opt_out,
    mortgage_status,
    highend,
    comments,
  };
};

const dialerFormParams = (type, data) => {
  const {
    info,
    lead,
    note,
    status,
  } = data;

  return {
    channel: 'Fresh',
    lead: leadParams({ ...lead, ...info }),
    requal_email: null,
    conditional_requal_email: null,
    [`submitted_via_${type}`]: true,
    note: {
      status,
      message: note
    },
  };
}


const partnerIdMap = {
  50: 'cinc',
  67: 'compass',
};

const pmr = {
  additional_notes: '',
  agent_link: '',
  blocked_company_brand_ids: {},
  blocked_verified_company_ids: {},
  comments: '',
  companies: {},
  companies_block: false,
  estimate: '',
  home: undefined,
  home_price: '',
  intent: undefined,
  is_an_agent: '',
  listed: '',
  listing_info: '',
  listing_link: false,
  matcher_notes: '',
  no_estimate: '0',
  qual_queue: true,
  qual_status: '',
};

const kill = (data) => {
  // let conditional_requal_email = state.conditional_requal_email === '1' ? '1' : null;
  // let send_agent_invitation = state.send_agent_invitation === '1' ? '1' : null;

  const {
    info,
    lead,
    note,
    status,
  } = data;

  const { id, partner_id } = lead;

  return {
    channel: 'Fresh',
    lead: leadParams({ ...lead, ...info }),
    note: {
      status,
      message: note,
    },
    lead_id: id,
    conditional_requal_email: null,
    send_agent_invitation: null,
  };
};

const matching = (data) => {
  const {
    info,
    lead,
    note,
  } = data;

  const { id } = lead;

  return {
    pre_match_research: { ...pmr, matcher_notes: note, comments: info.comments },
    dialerFormParams: dialerFormParams('pmr', data),
    lead_id: id
  }
};

const qual = (data) => {
  const {
    lead,
    note,
    status,
  } = data;

  const { id } = lead;

  return {
    ...dialerFormParams('liv', data),
    ...{
      note: {
        status,
        message: note,
      },
      requal_email: false,
      qual_queue: true,
    },
    lead_id: id,
   }
};

export default {
  kill,
  matching,
  qual,
};
