import { Component } from 'react';
import QualBlock    from './QualBlock';
import RequalBlock  from './RequalBlock';
import KillBlock    from './KillBlock';

const NextStep = props => {
  let {
    requalSubmit,
    finalAttempt,
    state,
    submitButtonClick,
    updateState,
    validHCSubmitParams,
    isCompassLead,
    isCincLead,
  } = props;

  let {
    intent,
    live,
    live_verifications,
    origin,
    qual_attempt_count,
    skip_pmr,
    status_button,
  } = state;

  const nextSteps = () => {
    let isHomeCaptainAndSecondQual = origin.indexOf('Home Captain') !== -1 && qual_attempt_count !== 2;

    return (isHomeCaptainAndSecondQual || !intent || live.live_lead)
      ? 'Send to matching only if you have spoken with the client and confirmed intent.'
      : 'Send to matching if this is a working number and phone records match, unless you speak with the client and they are not interested.';
  }

  if (skip_pmr) {
    return (
      <div className='next_steps'>
        <div className='heading'>
          <i className='fa fa-refresh'></i>
          Next Steps
        </div>
        <label>
          Lead is matched already, click to proceed to next lead
        </label>
        <div
          className='next_lead_button'
          onClick={requalSubmit}
        >
          NEXT
        </div>
      </div>
    )
  } else {
    return (
      <div className='next_steps'>
        <div className='heading'>
          <i className='fa fa-refresh'></i>
          Next Steps ( <span style={{ color: '#b0b0b0' }}> Select Only 1 Drop Down</span> )
        </div>

        <div className='subheading'>
          {nextSteps()}
        </div>

        <div className='action_dropdowns'>
          <QualBlock
            data={state}
            updateState={updateState}
            disabled={live.live_lead && !Object.values(live_verifications).every(val => val === true)}
          />
          <RequalBlock
            data={state}
            updateState={updateState}
            finalAttempt={finalAttempt}
            isCompassLead={isCompassLead}
          />
          <KillBlock
            data={state}
            updateState={updateState}
            isCompassLead={isCompassLead}
            isCincLead={isCincLead}
          />
          <div className='research_box'>
            <div
              className={status_button.text && validHCSubmitParams ? 'research_button' : 'hidden'}
              data-action={status_button.action}
              onClick={submitButtonClick}
            >
              {status_button.text}
            </div>
          </div>
          <div className='clear'></div>
        </div>
      </div>
    )
  }
};

export default NextStep;
