import Controls from './header/Controls';
import Stats from './header/Stats';

const Header = (props) => {
  const {
    agent,
    paused,
    setAttributeState,
    statsDialerRecruitTopAgentsPath,
  } = props;

  return(
    <div id="dialer-recruit-top-agents-header">
      <div className="my-15">
        <div className="row valign-center">
          <Controls
            agent={ agent }
            paused={ paused }
            setAttributeState={ setAttributeState }
          />

          <Stats statsDialerRecruitTopAgentsPath={ statsDialerRecruitTopAgentsPath } />
        </div>
      </div>
    </div>
  );
};

export default Header;
