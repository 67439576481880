import React from 'react';

import Finished from './FinishedContainer';

import Alert from './images/alert.png';
import Success from './images/success.png';

const RexInboxView = (props) => {
  const {
    hasActiveLiveInbox,
    display,
    finished,
    loading,
    toggle
  } = props;

  let heading;
  let subheading;
  let finishedMessage;
  if (display === 'confirm') {
    heading = hasActiveLiveInbox ? 'Disable Rex Inbox' : 'Re-enable Rex Inbox';
    subheading = hasActiveLiveInbox ? 'disable' : 're-enable';
    finishedMessage = hasActiveLiveInbox ? 'Successfully Disabled REX LIVE Inbox.' : 'Successfully Re-Enabled REX LIVE Inbox.';
  } else if (display === 'success') {
    heading = !hasActiveLiveInbox ? 'Disable Rex Inbox' : 'Re-enable Rex Inbox';
    subheading = !hasActiveLiveInbox ? 'disable' : 're-enable';
    finishedMessage = !hasActiveLiveInbox ? 'Successfully Disabled REX LIVE Inbox.' : 'Successfully Re-Enabled REX LIVE Inbox.';
  }

  const icon = display === 'confirm' ? Alert : Success;

  return (
    <div className='toggle-rex-inbox'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ icon } />
      </div>
      <div className='rex-modal-heading'>
        LIVE Account Management: { heading }
      </div>
      { display === 'confirm' &&
        <div className='rex-modal-contents'>
          <div className='rex-modal-subheading'>
            Are you sure you want to { subheading } Rex Inbox?
          </div>
          <div className='rex-modal-actions rex-modal-actions__centered'>
            <button
              className={`rex-modal-action ${loading ? 'rex-modal-action__loading' : ''}`}
              onClick={ () => toggle() }
              disabled={ loading }
            >
              { loading ? 'Loading' : 'Confirm' }
            </button>
          </div>
        </div>
      }
      { display === 'success' &&
        <Finished
          message={ finishedMessage }
          close={ finished }
        />
      }
    </div>
  );
};

export default RexInboxView;
