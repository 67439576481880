import { Component }  from 'react';

import Header from './views/Header';
import Body from './views/Body';
import Footer from './views/Footer';

const filterValues = inputs => {
  const values = [];

  [...inputs].filter(i => i.value).map(i => values.push(i.value));

  return values;
};

const cleanInputs = inputs => {
  inputs.forEach(input => {
    const formGroup = input.closest('.form-group');
    const removeInput = formGroup.querySelector('.remove-input')

    input.value = '';
    formGroup.classList.remove('has-error');
    removeInput.click();
  });
};

const cleanFieldInputs = () => {
  const container = document.getElementById('dialer-recruit-top-agents-research-container');
  const agentContacts = container.querySelectorAll('.agent-contacts');

  agentContacts.forEach(agentContact => {
    const inputs = agentContact.querySelectorAll('input');

    cleanInputs(inputs);
  });
};

export default class Container extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agent: {},
      paused: false,
    }

    this.dispositionProspect = this.dispositionProspect.bind(this);
    this.verifyContacts = this.verifyContacts.bind(this);
    this.setAttributeState = this.setAttributeState.bind(this);
  }

  componentDidMount() {
    this.fetchNewProspect();
  }

  setAttributeState(attribute, value) {
    this.setState({ [attribute]: value });
  }

  fetchNewProspect() {
    const container = document.getElementById('dialer-recruit-top-agents-research-container');
    const queryString = window.location.search;
    const { new_dialer_recruit_top_agents_research_path } = this.props;

    Util.clearFlash('.dialer-recruit-top-agents-research-flash-wrapper');
    this.toggleOverlay('enable', 'reserve-agent');
    cleanFieldInputs();

    container.dispatchEvent(new CustomEvent('agent_research.needs_verification'));
    container.dispatchEvent(new CustomEvent('agent_research.verification_results'));
    container.dispatchEvent(new CustomEvent('agent_research.clear_history'));

    // Start paused if user forces prospect into dialer
    this.setState({ paused: !!queryString });

    fetch(
      `${new_dialer_recruit_top_agents_research_path}${queryString}`,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        const { agent } = response;
        const timerDisplay = document.getElementById('rex-stat-timer');
        const statsDisplay = document.getElementById('rex-call-stats');

        this.toggleOverlay('disable');
        this.setState({ agent });

        statsDisplay.dispatchEvent(new CustomEvent('agent_research.fetch_stats'));
        timerDisplay.dispatchEvent(new CustomEvent('agent_research.start_timer'));
      } else {
        Util.createFlash(response.errors.join(', '), 'warning', '.dialer-recruit-top-agents-research-flash-wrapper');
        this.toggleOverlay('enable', 'no-prospect');
      }
    });
  }

  toggleOverlay(operation, action) {
    const container = document.getElementById('dialer-recruit-top-agents-research-container');

    // Reset
    container.classList = '';

    if (/enable/.test(operation)) container.classList.add('overlay', action);
    if (/reserve-agent|no-prospect|submitting/.test(action)) container.classList.add('request');
  }

  contactValues() {
    const container = document.getElementById('dialer-recruit-top-agents-research-container');
    const agentContacts = container.querySelectorAll('.agent-contacts');
    const contacts = {};

    agentContacts.forEach(agentContact => {
      const { contactType } = agentContact.dataset;
      const inputs = agentContact.querySelectorAll('.form-group:not(.has-error) input');

      contacts[contactType] = filterValues(inputs);
    });

    return contacts;
  }

  dispositionProspect(event) {
    const { currentTarget } = event;
    const { dialer_recruit_top_agents_research_path, dialer_recruit_top_agents_queue_path } = this.props;
    const { agent, paused } = this.state;
    const success = currentTarget.classList.contains('success');
    const contacts = success ? this.contactValues() : {};

    Util.clearFlash('.dialer-recruit-top-agents-research-flash-wrapper');
    this.toggleOverlay('enable', 'submitting');

    const data = JSON.stringify({
      ...contacts,
      agent_type: agent.agent_type,
      canonical_aid: agent.canonical_aid,
      agent_found: success,
    });

    fetch(
      dialer_recruit_top_agents_research_path,
      {
        method: 'PUT',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        body: data,
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        if (paused) {
          window.location = dialer_recruit_top_agents_queue_path
        } else {
          this.fetchNewProspect();
        }
      } else {
        Util.createFlash(response.errors.join(', '), 'danger', '.dialer-recruit-top-agents-research-flash-wrapper');
        this.toggleOverlay('disable');
      }
    });
  }

  verifyContacts(event) {
    const container = document.getElementById('dialer-recruit-top-agents-research-container');
    const { dialer_recruit_top_agents_research_verify_path } = this.props;
    const { agent } = this.state;
    const { phones } = this.contactValues();
    const queryString = Util.toQueryString({ canonical_aid: agent.canonical_aid, agent_type: agent.agent_type, phones: phones });

    Util.clearFlash('.dialer-recruit-top-agents-research-flash-wrapper');
    this.toggleOverlay('enable', 'submitting');

    fetch(
      `${dialer_recruit_top_agents_research_verify_path}?${queryString}`,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        container.dispatchEvent(new CustomEvent('agent_research.verification_results', { detail: response.results }));
      } else {
        container.dispatchEvent(new CustomEvent('agent_research.needs_verification'));
        Util.createFlash(response.errors.join(', '), 'danger', '.dialer-recruit-top-agents-research-flash-wrapper');
      }

      this.toggleOverlay('disable');
    });
  }

  render() {
    const { agent, paused } = this.state;

    const {
      stats_dialer_recruit_top_agents_research_path,
      dialer_recruit_top_agents_research_contact_history_path
    } = this.props;

    return (
      <div className="dialer-recruit-top-agents-research-wrapper">
        <div id='dialer-recruit-top-agents-research-flash-wrapper' class='dialer-recruit-top-agents-research-flash-wrapper'>
          <div id='flash-container'></div>
        </div>

        <Header
          paused={ paused }
          setAttributeState={ this.setAttributeState }
          statsDialerRecruitTopAgentsResearchPath={ stats_dialer_recruit_top_agents_research_path }
        />

        <Body
          agent={ agent }
          researchContactHistoryPath={ dialer_recruit_top_agents_research_contact_history_path }
        />

        <Footer
          dispositionProspect={ this.dispositionProspect }
          verifyContacts={ this.verifyContacts }
        />
      </div>
    );
  }
}
