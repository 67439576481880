import Input from './contacts/Input';

const Contacts = () => {
  return (
    <div className="row">
      <div className="col-md-6 agent-contacts " data-contact-type="phones">
        <p className="bold">Phones</p>
        <Input />
      </div>
      <div className="col-md-6 agent-contacts" data-contact-type="emails">
        <p className="bold">Emails</p>
        <Input />
      </div>
    </div>
  );
};

export default Contacts;
