import AlertIcon from '../../../../LiveAccountManagement/images/alert.png';

const Warning = (props) => {
  const {
    moveTo,
    toggleModal,
  } = props;

  return (
    <div id='cancel-warning'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ AlertIcon } />
      </div>

      <div className='rex-modal-heading'>
        Please Read and Proceed with Caution
      </div>

      <div className='rex-modal-contents'>
        <div className='rex-modal-subheading full-width'>
          <p className='center'>Selecting "CANCEL PRIME" will cancel the agent's PRIME subscription on the agent's plan end-date. <br />If cancelled <strong>prior</strong> to the plan's end-date:</p>

          <ul>
            <li>The agent will continue to be charged $99/monthly until the plan end-date</li>
            <li>The agent will retain access to their PRIME Dashboard and services until the plan end-date</li>
            <li>On their plan end-date, the subscription will be cancelled</li>
          </ul>

          <p>Once cancelled:</p>

          <ul>
            <li>The agent will no longer be charged the monthly fee</li>
            <li>The agent will not have access to their PRIME Dashboard or services</li>
            <li>The agent will be able to access their PRIME leads in their CRM account</li>
          </ul>

          <p className='center'>If the subscription is cancelled <strong>after</strong> their plan end-date, they will retain access to their PRIME Dashboard and services <br />until their next billing date - at which point their subscription will be cancelled as outlined above.</p>
        </div>

        <div className='rex-modal-actions'>
          <button
            className='rex-modal-action'
            onClick={ () => moveTo('reason') }
          >
            CANCEL PRIME
          </button>

          <button
            className='rex-modal-action rex-modal-action__loading'
            onClick={ () => toggleModal(false) }
          >
            KEEP PRIME ACTIVE
          </button>
        </div>
      </div>
    </div>
  )
};

export default Warning;
