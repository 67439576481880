import { Component }      from 'react';
import request            from 'superagent';
import SelectionView      from './views/SelectionView';
import TagAgentView       from './views/TagAgentView';
import FollowUpView       from './views/FollowUpView';
import TargetedAgentView  from './views/TargetedAgentView';
export default class WorkingListModal extends Component {
  constructor(props){
    super(props)

    this.state                = this.initialState();
    this.showModal            = this.showModal.bind(this);
    this.showLoading          = this.showLoading.bind(this);
    this.closeModal           = this.closeModal.bind(this);
    this.switchView           = this.switchView.bind(this);
    this.updateState          = this.updateState.bind(this);
    this.tagAgent             = this.tagAgent.bind(this);
    this.targetAgent          = this.targetAgent.bind(this);
    this.findUserAndWorkAgent = this.findUserAndWorkAgent.bind(this);
    this.retryFindAgent       = this.retryFindAgent.bind(this);
    this.findAgent            = this.findAgent.bind(this);
  }

  initialState() {
    return {
      workingListView: 'selection',
      task: {
        follow_up: '',
        message: ''
      },
      newTargetedAgent: {
        first_name: '',
        last_name: '',
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone_mobile: '',
        email: ''
      },
      taggedAgentId: '',
      submitting: false
    }
  }

  resetState() {
    return {
      workingListView: 'selection',
      task: {
        follow_up: '',
        message: ''
      },
      taggedAgentId: '',
      submitting: false
    }
  }

  componentDidMount(){
    if (Object.keys(this.props.state.selectedWishlist).length){
      this.prefillNewTargetedAgent(this.props.state.selectedWishlist);
    }
  }

  componentWillReceiveProps(nextProps){
    let wishList = this.props.state.selectedWishlist;
    let newWishlist = nextProps.state.selectedWishlist;
    if (wishList.id !== newWishlist.id) this.prefillNewTargetedAgent(newWishlist);
  }

  prefillNewTargetedAgent(wish){
    this.setState({
      newTargetedAgent: {
        first_name:   wish.first_name,
        last_name:    wish.last_name,
        name:         wish.broker,
        city:         wish.city,
        state:        wish.state.toUpperCase(),
        zip:          wish.zip,
        phone_mobile: wish.phone,
        email:        wish.email
      }
    })
  }

  updateState(hash){
    this.setState(hash)
  }

  switchView(view) {
    this.setState({ workingListView: view })
  }

  showModal() {
    return this.props.state.showWorkingListModal
      ? 'modal-overlay'
      : 'hidden';
  }

  showLoading() {
    return this.state.submitting
      ? 'modal-overlay-submitting'
      : 'hidden';
  }

  removeWishList(id) {
    let wishlists = this.props.state.agentWishLists;
    let wishlistIndex = wishlists.map((wl) => {return wl.id}).indexOf(id);
    wishlists.splice(wishlistIndex, 1);
    this.props.updateState({ agentWishLists: wishlists });
  }

  tagAgent(){
    this.setState({ submitting: true });
    let { taggedAgentId, task } = this.state;
    let { salesManagerId } = this.props.state;
    let options = {
      find_agent_options: { id: taggedAgentId },
      sales_manager_id: salesManagerId,
      task
    };

    request
      .post('/agent_insights/monitors/migrate_from_wishlist')
      .query(options)
      .then(this.closeModal)
      .then(this.props.loadWorkingList)
      .catch(err => {
        alert(JSON.parse(err.response.text).message);
        this.closeModal();
      });
  }

  targetAgent() {
    this.setState({ submitting: true });
    let { newTargetedAgent } = this.state;
    newTargetedAgent.phone_mobile = newTargetedAgent.phone_mobile.replace(/\D/g, '');
    request
      .post('/ingest/targeted_agent/submit')
      .query(newTargetedAgent)
      .then(({ body }) => { return this.retryFindAgent(body); })
      .then(res => {
        return res.uuid
          ? this.findUserAndWorkAgent(res.uuid)
          : this.errorSearchForAgent(res.error, newTargetedAgent.email); })
      .then(this.closeModal)
      .then(this.props.loadWorkingList);
  }

  retryFindAgent(response){
    const pause = (duration) => { return new Promise(res => setTimeout(res, duration)); }
    const retry = (fn, attempts = 5, delay = 3000) => {
      return fn(response).then(res => {
        if (res.data.results.user_uuid){
          return { uuid: res.data.results.user_uuid };
        } else if (attempts > 0) {
          return pause(delay).then(() => { return retry(fn, attempts - 1, delay) });
        } else {
          return { error: 'Created agent not found' };
        }
      });
    };

    return retry(this.findAgent);
  }

  findAgent(response){
    return request
      .get('/ingest/targeted_agent/ready')
      .query(response)
      .then(({ body }) => { return body });
  }

  findUserAndWorkAgent(userUUID) {
    let { salesManagerId, selectedWishlist } = this.props.state;
    let options = {
      user_uuid: userUUID,
      sales_manager_id: salesManagerId,
      wishlist_id: selectedWishlist.id
    };
    return request
      .post('/agent_insights/monitors/migrate_from_wishlist')
      .query(options)
      .then(res => { if (res.error) alert(res.error) })
      .then(() => this.removeWishList(selectedWishlist.id));
  }

  errorSearchForAgent(error, email){
    if(confirm(`${error}. Do you want to search for Agent?`)){
      window.open(`/search?page=1&search_type=quick_search&quick_search_type=email&search_data=${email}`, '_blank');
    };
  }



  closeModal(){
    this.props.updateState({ showWorkingListModal: false });
    this.setState(this.resetState());
  }

  modalHeader(){
    switch (this.state.workingListView) {
      case 'selection':
        return( <h3>Agent Information</h3> ); break;
      case 'tagAgent':
        return ( <h3>Tag Agent Id</h3> ); break;
      case 'tagAgentFollowUp':
        return (<h3>Follow Up Task</h3>); break;
      case 'targetedAgentFollowUp':
        return (<h3>Follow Up Task</h3>); break;
      case 'targetedAgent':
        return (<h3>New Targeted Agent</h3>); break;
    }
  }

  modalContent(){
    switch (this.state.workingListView) {
      case 'selection':
        return (
          <SelectionView
            state={this.state}
            switchView={this.switchView}
            closeModal={this.closeModal}
          />
        );
        break;
      case 'tagAgent':
        return (
          <TagAgentView
            state={this.state}
            updateState={this.updateState}
            switchView={this.switchView}
            closeModal={this.closeModal}
          />
        );
        break;
      case 'targetedAgent':
        return (
          <TargetedAgentView
            state={this.state}
            updateState={this.updateState}
            switchView={this.switchView}
            closeModal={this.closeModal}
          />
        );
        break;
      case 'tagAgentFollowUp':
        return (
          <FollowUpView
            state={this.state}
            updateState={this.updateState}
            loadPrevView={() => this.switchView('tagAgent')}
            submit={this.tagAgent}
            closeModal={this.closeModal}
          />
        );
        break;
      case 'targetedAgentFollowUp':
        return (
          <FollowUpView
            state={this.state}
            updateState={this.updateState}
            loadPrevView={() => this.switchView('targetedAgent')}
            submit={this.targetAgent}
            closeModal={this.closeModal}
          />
        );
        break;
    }
  }

  render(){
    return(
      <div className={this.showModal()} onClick={this.closeModal}>
        <div className='title-modal' onClick={(e) => e.stopPropagation()}>
          <div className='header'>
            { this.modalHeader() }
            <div className='close-button' onClick={this.closeModal}>
              <i className='fa fa-times-circle' />
            </div>
          </div>
          { this.modalContent() }
          <div className={this.showLoading()}>
            <i className='fa fa-refresh fa-spin fa-5x fa-fw'></i>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      </div>
    )
  }
}
