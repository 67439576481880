import { Component } from 'react';

export default class Header extends Component {
  constructor(props){
    super(props);

    this.state = {
      talkTime: 0,
      callsToday: 0,
      setsToday: 0
    }
    this.updateCallData = this.updateCallData.bind(this);
  }

  componentDidMount(){
    this.updateCallData();
    this.interval = setInterval(this.updateCallData, 300000);
  }

  componentWillReceiveProps(nextProps){
    this.updateCallData();
  }

  componentWillUnmount(){
    clearInterval(this.interval);
  }

  updateCallData(){
    Util.fetchCallData()
      .then(result => this.setState({
        talkTime: result.talk_time || 0,
        callsToday: result.calls_today || 0,
        setsToday: result.sets_today || 0
      }))
  }

  render(){
    let { location, time, agentId, paused, togglePause, agentSearch, number } = this.props;
    let { talkTime, callsToday, setsToday } = this.state;
    return(
      <section className='wc-header'>
        <div className='pause-button' onClick={togglePause}>
          { paused ? 'UNPAUSE' : 'PAUSE'}
          <i className="fa fa-pause" aria-hidden="true"></i>
        </div>
        <div className='header-content-col'>
          <div id='regional_number' className='title'>Regional Number</div>
          <div className='value'>{ number }</div>
        </div>
        <div className='header-content-col'>
          <div className='title'>Location</div>
          <div className='value'>{ location }</div>
        </div>
        <div className='header-content-row'>
          <div className='title'>Talk Time</div>&nbsp;
          <div className='value white-bubble'>{ talkTime } mins</div>
        </div>
        <div className='header-content-row'>
          <div className='title'>Calls Today</div>&nbsp;
          <div className='value white-bubble'>{ callsToday }</div>
        </div>
        <div className='header-content-row'>
          <div className='title'>Sets Today</div>&nbsp;
          <div className='value green-bubble'>{ setsToday }</div>
        </div>
      </section>
    )
  }
}
