import React, { Component } from 'react';

import Close from '../LiveAccountManagement/images/close.png';

const PrimeActivationView = (props) => {
  const {
    toggleModal,
  } = props;

  return (
    <div className='rex-modal-overlay'>
      <div className='rex-modal-content'>
        <div className='rex-modal-close'>
          <button
            onClick={ () => toggleModal(false) }
          >
            <img src={ Close } />
          </button>
        </div>
        <div className='rex-modal-back'></div>
        <div className='toggle-live-subscription'>
          <div className='rex-modal-heading'>
            URL Copied to Clipboard
          </div>
          <div className='rex-modal-contents'>
            <div className='rex-modal-subheading'>
              To access the PRIME pay funnel, paste the URL into an incognito window. The URL will expire in 60 seconds.
            </div>
            <div className='rex-modal-actions rex-modal-actions__centered'>
              <button
                className='rex-modal-action rex-modal-actions__centered'
                onClick={ () => toggleModal(false) }
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default PrimeActivationView;
