import React from 'react';
import ReactDOM from 'react-dom';
import Details from './index';

/**
 * Initialize ReaClustersDetails
 */

export function initialize(containerElement, props) {
  let component = React.createElement(Details, props);
  ReactDOM.render(component, containerElement);
}
