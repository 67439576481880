import Controls from './header/Controls';
import Stats from './header/Stats';

const Header = (props) => {
  const {
    paused,
    setAttributeState,
    statsDialerRecruitTopAgentsResearchPath,
  } = props;

  return (
    <div className="header">
      <div className="my-15">
        <div className="row valign-center">
          <Controls paused={ paused } setAttributeState={ setAttributeState } />
          <Stats statsDialerRecruitTopAgentsResearchPath={ statsDialerRecruitTopAgentsResearchPath } />
        </div>
      </div>
    </div>
  );
};

export default Header;
