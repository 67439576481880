import { Component } from 'react';

export default class ScriptQuestions extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      highlight: false
    };

    this.createLead = this.createLead.bind(this);
  }

  componentDidMount() {
    EventBus.on( 'highlight:intro', this._onHighlightOther.bind( this ) );
    EventBus.on( 'highlight:questions', this._onHighlight.bind( this ) );
    EventBus.on( 'highlight:closing', this._onHighlightOther.bind( this ) );
  }

  createLead() {
    document.getElementById('new-lead-from-lead-modal').classList.remove('hidden');
  }

  renderCreateLead() {
    if (this.props.rex_referral && !this.props.live_lead) {
      return(
        <a className='create_lead' href={ this.props.crm_link } target='_blank' style={{textDecoration: 'none'}}>
          Create Lead in CRM +
        </a>
      );
    } else {
      return(
        <div className='create_lead' onClick={ this.createLead } >
          Create Lead +
        </div>
      );
    }
  }

  render() {
    return(
      <div className='script'>
        <div className='heading'>
          <i className='fa fa-question-circle-o'></i>
          { this.props.data.title }
        </div>
        <div className={ this.state.highlight ? 'scriptlet highlighted' : 'scriptlet' }>
          <div className='copy'>
            <div dangerouslySetInnerHTML={{ __html: this.props.data.copy }}></div>
            <div dangerouslySetInnerHTML={{ __html: this.props.data.questions }}></div>
            {this.props.data.button && this.props.data.button()}
            <div className={ this.props.data.bonus === false ? 'hidden' : '' }>
              <strong className={ this.props.data.bonus === ' ' ? 'hidden' : '' }>Bonus</strong>
              <blockquote>
                <div dangerouslySetInnerHTML={{ __html: this.props.data.bonus }}></div>
                { this.renderCreateLead() }
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _onHighlight() {
    this.setState({
      highlight: true
    });
  }

  _onHighlightOther() {
    this.setState({
      highlight: false
    });
  }
}
