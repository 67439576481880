import React, { useState, useEffect } from 'react';

const formatTime = time => {
  return moment().startOf('day').add(time || 0, 'seconds').format('HH:mm:ss').replace(/^(?:00:)?0?/, '');
};

const Stats = (props) => {
  const { statsDialerRecruitTopAgentsResearchPath } = props;

  const [researchTime, setResearchTime] = useState('0:00');
  const [researched, setResearched] = useState(0);
  const [fetched, setFetched] = useState(false);

  function fetchAgentRecruitmentStats(event) {
    if (fetched) return;

    fetch(
      statsDialerRecruitTopAgentsResearchPath,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      const { research_time , agents_researched } = response;

      setResearchTime(formatTime(research_time));
      setResearched(agents_researched);
      setFetched(true);
    });
  }

  useEffect(() => {
    const researchedStatsDisplay = document.getElementById('rex-call-stats');

    researchedStatsDisplay.addEventListener('agent_research.fetch_stats', fetchAgentRecruitmentStats);
  });

  return (
    <div className="col-md-7 text-right" id="rex-call-stats">
      <strong>Today's Stats:</strong>
      <div className="rex-stat ml-30">
        <span className="label-type mr-15">Agents Researched</span>
        <span className="rex-stat-pill">
          <strong>{ researched }</strong>
        </span>
      </div>
      <div className="rex-stat ml-30">
        <span className="label-type mr-15">Research Time</span>
        <span className="rex-stat-pill">
          <strong>{ researchTime }</strong>
        </span>
      </div>
    </div>
  )
};

export default Stats;
