import React from 'react';
import ReactDOM from 'react-dom';
import DialerRecruitTopAgentsContainer from './Container';

/**
 * Initialize the DialerRecruitTopAgents modal.
 */

export function initialize(containerElement, props) {
  let component = React.createElement(DialerRecruitTopAgentsContainer, props);
  ReactDOM.render(component, containerElement);
}
