import { Component } from 'react';
import { AsYouType } from 'libphonenumber-js';

export default class AgentLead extends Component {
  constructor(props){
    super(props);
    this.state = {
      questionResponses: null
    }
    this.replaceImage = this.replaceImage.bind(this);
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      questionResponses: nextProps.questionResponses,
      photo: nextProps.photo
    })
  }

  replaceImage(){
    this.setState({ photo: '/images/blank_photo-original.png'})
  }

  renderBlock(header, content){
    return (
      <div className={content ? 'cs-details-block' : 'cs-details-block missing'}>
        <label>{ header } </label>
        { content || 'None' }
      </div>
    )
  }

  renderCallBlock(header, number){
    if(number){
      const parts = number.split('ext')
      const base = parts[0]
      const ext = !!parts[1] ? ` ext ${parts[1]}` : ''
      const e164Number = new AsYouType('US').input(base) + ext
      return (
        <div className='cs-details-block'>
          <label>{ header }: </label>
          <a
            data-remote="true"
            rel="nofollow"
            data-method="post"
            className='click-to-call-phone-type'
            href={this.parseClick2CallHref(base)}>
            { e164Number }
          </a>
        </div>
      )
    } else {
      return (
        <div className='cs-details-block missing'>
          <label>{ header }: </label>
          None
        </div>
      )
    }
  }

  parseClick2CallHref(number) {
    return (
      '/click2call?number_type=customer_success&call_destination=agent&call_destination_id=' + this.props.agent.id +
      '&call_type=customer_success&phone=' + number
    )
  }

  renderLine(header, content){
    return(
      <div className='cs-details-line'>
        <div className='title'>{ header }: </div>
        <div className='value'>{ content || '-'}</div>
      </div>
    )
  }

  render(){
    let { questionResponses, toggleAgentEditPage, agentCompany, agent, agentMetrics, user, agentFeatures, timeZone, welcomeCallTask } = this.props
    let photo = this.state.photo;
    let company, fullName, features;
    let companyAddress = agentCompany.address + " " + agentCompany.city + ", " + agentCompany.state + " " + agentCompany.zip;

    if(user) fullName = user.first_name + ' ' + user.last_name;

    if(agentFeatures && Object.keys(agentFeatures).length){
      features = Object.keys(agentFeatures).map((feature, idx) => {
        return (
          <div key={idx} className='cs-details-block'>
            <label>{ agentFeatures[feature].name === 'rex_personal' ? 'Rex Send' : 'Rex Receive' } </label>
            { agentFeatures[feature].enabled ? 'Enabled' : '-'}
          </div>
        )
      })
    };

    return(
      <div className='cs-details'>
        <div className='cs-details-left'>
          <img className='react-user-photo' src={photo} onError={this.replaceImage}/>
        </div>
        <div className='cs-details-right'>
          <div className='cs-details-info'>
            <div className='cs-details-title'>
              <h3>USER CONTACT</h3>
              <div className='react-edit-button'onClick={toggleAgentEditPage}>Edit</div>
            </div>
            <div className='cs-details-blocks'>
              { this.renderBlock('Name', fullName) }
              { this.renderBlock('Login/Email', user.email) }
              { this.renderBlock('Time Zone', timeZone.time_zone) }
              { this.renderBlock('Assigned To', welcomeCallTask.assignee) }
              { this.renderCallBlock('Office', user.phone_office) }
              { this.renderCallBlock('Mobile', user.phone_mobile) }
              { this.renderCallBlock('Home', user.phone_home) }
              { features }
            </div>
            <div className='cs-details-title'>
              <h3>LICENSE INFORMATION</h3>
            </div>
            <div className='cs-details-blocks'>
              { this.renderBlock('Name on Licence', agent.name_on_license) }
              { this.renderBlock('License Type', agent.license_type) }
              { this.renderBlock('License Number', agent.license_number) }
              { this.renderBlock('Broker Name', agent.broker_name) }
              { this.renderBlock('Company Name', agentCompany.name) }
              { this.renderBlock('Company Address', companyAddress) }
            </div>
            <div className='cs-details-title'>
              <h3>QUESTIONAIRE RESPONSES</h3>
            </div>
            <div className='cs-details-blocks'>
              { this.renderBlock('Years Active in Real Estate?', questionResponses[1]) }
              { this.renderBlock('Roles at the brokerage?', questionResponses[2]) }
              { this.renderBlock('Number of transactions per year?', questionResponses[7]) }
              { this.renderBlock('Price range of typical service?', questionResponses[8]) }
              { this.renderBlock('% of business from sellers?', questionResponses[4]) }
              { this.renderBlock('Lead sources: ', questionResponses[5]) }
              { this.renderBlock('Monthly Expenses: ', questionResponses[6]) }
            </div>
            <div className='cs-details-title'>
              <h3>AGENT METRICS</h3>
            </div>
            <div className='cs-details-blocks'>
              { this.renderLine('Most Recent Match', agentMetrics.mostRecentMatchDate) }
              { this.renderLine('Last Lead Accepted', agentMetrics.lastLeadAccepted) }
              { this.renderLine('Last Lead Declined', agentMetrics.lastLeadDeclined) }
              { this.renderLine('Number of Timeouts', agentMetrics.numberOfTimeouts) }
              { this.renderLine('Rex Streak', agentMetrics.rexStreak) }
              { this.renderLine('AM Streak', agentMetrics.amStreak) }
              { this.renderLine('Current Clients', agentMetrics.currentClients) }
              { this.renderLine('Leads Given', agentMetrics.leadsGiven) }
              { this.renderLine('Leads Accepted', agentMetrics.leadsAccepted) }
              { this.renderLine('Leads Closed', agentMetrics.leadsClosed) }
            </div>
          </div>
        </div>
      </div>
    );
  }

}
