import { Component } from 'react';
import TopPanel     from './TopPanel';
import Calendar     from './Calendar';
import Appointments from './Appointments';
import Notes        from './Notes';
import Details      from './Details';
import Emails       from './Emails';

export default class CustomerSuccess extends Component {
  constructor(props) {
    super(props);
    let date = new Date;
    this.state = {
      agentId: null,
      agent: null,
      notes: null,
      questionResponses: null,
      showAppointment: true,
      activeDay: date.getDate(),
      activeDate: Util.MMDDYYYY(),
      activeMonth: date.getMonth(),
      activeYear: date.getFullYear(),
      calendar: null,
      errorMessage: 'Error',
      showErrorMessage: false
    }
    this.fetchAgent = this.fetchAgent.bind(this);
    this.obtainAgentInfo = this.obtainAgentInfo.bind(this);
    this.toggleAppointment = this.toggleAppointment.bind(this);
    this.updateActiveDay = this.updateActiveDay.bind(this);
    this.loadCustomerSuccessSchedule = this.loadCustomerSuccessSchedule.bind(this);
    this.updateAppointmentAssignee = this.updateAppointmentAssignee.bind(this);
  }

  componentDidMount(){
    if(this.props.agent_id){
      window.history.replaceState({agentId: this.props.agent_id}, null, '/customer_success/' + this.props.agent_id);
      this.obtainAgentInfo(this.props.agent_id);
    }
    this.loadCustomerSuccessSchedule(this.state.activeDate);
    $('.container-fluid').css('background-color', '#f2f2f2')
    window.onpopstate = () => { location.reload(); };
  }

  fetchAgent(agentId = this.state.agentId){
    if(agentId) window.history.pushState({agentId: agentId}, null, '/customer_success/' + agentId);
    this.setState({ agentId }, () => {
      this.obtainAgentInfo();
    });
  }

  updateActiveDay(date, day){
    let params = { weekday: date };
    Util.getCustomerSuccessSchedule(params)
      .then((result) => {
        this.setState({
          activeDate: date,
          activeDay: parseInt(day),
          calendar: result.calendar
        });
      });
  }

  toggleAppointment(option = true){
    this.setState({ showAppointment: option })
  }

  loadCustomerSuccessSchedule(date = Util.MMDDYYYY()) {
    let params = { weekday: date };
    Util.getCustomerSuccessSchedule(params)
      .then((result) => { this.setState({ calendar: result.calendar }) });
  }

  updateAppointmentAssignee(appointment, assignedTo) {
    let updateParams = {
      task_id: appointment.id,
      assigned_to: assignedTo
    };
    Util.updateWelcomeTaskAssignee(updateParams)
      .then((result) => {
        if(result.error){
          this.setState({ errorMessage: result.error });
          this.showError();
        } else{
          this.loadCustomerSuccessSchedule();
          this.fetchAgent(appointment.taskable_id);
        }
      })
  }

  showError(){
    this.setState({ showError: true });
    this.errorTimeout = setTimeout(() => this.setState({ showError: false }), 4000);
  }

  obtainAgentInfo(agentId = this.state.agentId){
    if(agentId){
      let options = { agent_id: agentId };

      Util.getAgentData(options)
        .then(result => this.setAgent(result))
        .then(() => Util.fetchPhoto({ user_id: this.state.user.id }))
        .then(result => this.setState({ photo: result.photo }))
        .then(() => this.toggleAppointment())
    }
  }

  setAgent(result) {
    if(result.error){
      this.setState({
        errorMessage: result.error,
        agentId: null,
      });
      this.showError();
    } else{
      if(result.user) document.title = result.user.first_name + ' ' + result.user.last_name;
      this.setState({
        afssId: result.afss_id,
        agent: result.agent,
        agentId: result.agent.id,
        agentCompany: result.agent_company,
        agentFeatures: result.agent_features,
        timeZone: {
          time_zone: result.user.time_zone,
          time_zone_short: result.user.time_zone_short,
          offset: result.user.offset
        },
        emails: result.emails,
        user: result.user,
        notes: result.notes,
        questionResponses: result.responses,
        welcomeCallTask: result.task,
        agentMetrics: {
          mostRecentMatchDate: result.agent_metrics.most_recent_match_date,
          lastLeadAccepted: result.agent_metrics.last_lead_accepted,
          lastLeadDeclined: result.agent_metrics.last_lead_declined,
          numberOfTimeouts: result.agent_metrics.number_of_timeouts,
          rexStreak: result.agent_metrics.rex_streak,
          amStreak: result.agent_metrics.am_streak,
          currentClients: result.agent_metrics.current_clients,
          leadsGiven: result.agent_metrics.leads_given,
          leadsAccepted: result.agent_metrics.leads_accepted,
          leadsClosed: result.agent_metrics.leads_closed,
        },
      });
    }
  }

  render() {
    let { currentUser, currentUserRoles } = this.props;
    let {
      notes, agent, agentId, photo, questionResponses,
      task, showAppointment, welcomeCallTask, afssId, emails,
      agentCompany, agentMetrics, user, activeDate, activeDay,
      activeMonth, activeYear, calendar, agentFeatures, timeZone
    } = this.state;

    let errorBar = () => {
      return(
        <div className={this.state.showErrorMessage ? 'cs-panel-error-message' : 'hidden'}>
          {this.state.errorMessage}
        </div>
      )
    }
    return(
      <div className='cs-panel'>
        { errorBar() }
        <div>
          <TopPanel
            currentUser={currentUser}
            agentId={agentId}
            calendar={calendar}
            fetchAgent={this.fetchAgent}
          />
        </div>
        <div className='cs-bottom-panel'>
          <Calendar
            currentUser={currentUser}
            currentUserRoles={currentUserRoles}
            fetchAgent={this.fetchAgent}
            activeDate={activeDate}
            activeDay={activeDay}
            activeMonth={activeMonth}
            activeYear={activeYear}
            updateActiveDay={this.updateActiveDay}
            calendar={calendar}
            updateAppointmentAssignee={this.updateAppointmentAssignee}
          />
          <div className='cs-bottom-center-panel'>
            <div className='cs-center-panel'>
              <Appointments
                  agentId={agentId}
                  task={task}
                  timeZone={timeZone}
                  activeDate={activeDate}
                  currentUser={currentUser}
                  showAppointment={showAppointment}
                  fetchAgent={this.fetchAgent}
                  toggleAppointment={this.toggleAppointment}
                  agentFeatures={agentFeatures}
                  welcomeCallTask={welcomeCallTask}
                  loadCustomerSuccessSchedule={this.loadCustomerSuccessSchedule}
                />
                <Notes
                  currentUser={currentUser}
                  user={user}
                  agentId={agentId}
                  notes={notes}
                  fetchAgent={this.fetchAgent}
                  obtainAgentInfo={this.obtainAgentInfo}
                  showAppointment={showAppointment}
                />
            </div>
          </div>
          <div className='cs-bottom-right-panel'>
            <Details
              agent={agent}
              afssId={afssId}
              agentCompany={agentCompany}
              agentFeatures={agentFeatures}
              agentMetrics={agentMetrics}
              user={user}
              photo={photo}
              agentId={agentId}
              timeZone={timeZone}
              currentUser={currentUser}
              welcomeCallTask={welcomeCallTask}
              questionResponses={questionResponses}
              obtainAgentInfo={this.obtainAgentInfo}
            />
            <Emails
              agentId={agentId}
              emails={emails}
            />
          </div>
        </div>
      </div>
    );
  }
}
