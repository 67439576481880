import React from 'react';
import ReactDOM from 'react-dom';
import Content from './Content';

export { default as Loader } from './Loader';

export function initializeContent(containerElement, options) {
  let component = React.createElement(Content, options);
  ReactDOM.render(component, containerElement);
}
