import React from 'react';

import { NewLeadFromLeadModal } from '../../LeadCreation';

const transformMatch = (data) => {
  const match = data.match;
  match['agent'] = {};
  match['agent']['id'] = data.agent.id;
  match['agent']['name_on_license'] = data.agent.name_on_license;
  return match;
};

const NewLead = (props) => {
  const { data } = props;

  if (!data) {
    return null;
  }
  const {
    formData,
    lead,
    state
  } = data;

  const matches = props.matches ? props.matches.map(transformMatch) : null;
  const is_matcher = true;

  return (
    <NewLeadFromLeadModal
      formData  =   { formData }
      state     =   { state }
      lead      =   { lead }
      matches   =   { matches }
      is_matcher  = { is_matcher }
    />
  );
};

export default NewLead;
