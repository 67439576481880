import ButtonGroup from './../../_common/ButtonGroup.js';

const TransactionType = props => {
  const {
    leadTypeButtons,
    updateFieldHandler,
  } = props;

  return (
    <div className="section">
      <div className="header">
        <div className="icon-wrapper"><i className="icon home"></i></div>
        <div className="title-wrapper">
          <h2>Transaction Type</h2>
        </div>
      </div>

      <div className="input-row" data-attribute="type">
        <ButtonGroup attribute="lead_type" buttons={ leadTypeButtons } updateFieldHandler={ updateFieldHandler } />
      </div>
    </div>
  );
};

export default TransactionType;
