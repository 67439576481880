import React, { useState, useEffect, useRef } from 'react';

const Controls = (props) => {
  const { paused, setAttributeState } = props;
  const klass = paused ? 'fa-play' : 'fa-pause';
  const text = paused ? 'RESUME' : 'PAUSE';
  const [duration, setDuration] = useState('0:00');
  const [pauseIcon, setPauseIcon] = useState(klass);
  const [pauseText, setPauseText] = useState(text);
  const intervalId = useRef(undefined);

  function startTimer(event) {
    const { currentTarget } = event;
    const start = moment().startOf('day');
    const interval = 1000;

    if (intervalId.current) clearInterval(intervalId.current);

    intervalId.current = setInterval(() => {
      start.add(interval, 'milliseconds');
      setDuration(start.format('HH:mm:ss').replace(/^(?:00:)?0?/, ''));
    }, interval);
  }

  function handlePause(event) {
    setAttributeState('paused', !paused);
    setPauseIcon(klass);
    setPauseText(text);
  }

  useEffect(() => {
    setPauseIcon(klass);
    setPauseText(text);
  }, [paused]);

  useEffect(() => {
    const timerDisplay = document.getElementById('rex-stat-timer');

    timerDisplay.addEventListener('agent_research.start_timer', startTimer);
  });

  return (
    <div className="col-md-5">
      <button className="btn btn-xl btn-rex-blue btn-pause-dialer" onClick={ handlePause }>
        { pauseText } <i className={ `fa ${pauseIcon} ml-5` }></i>
      </button>
      <div className="rex-stat ml-30">
        <span className="rex-stat-pill">
          <strong id="rex-stat-timer">{ duration }</strong>
        </span>
      </div>
    </div>
  )
};

export default Controls;
