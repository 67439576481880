import Locations from './locations';

export const getStateProps = map => {
  if (map) {
    const obj = {};
    map.forEach((v,k) => { obj[k] = v });
    return obj;
  }
};

export const noop = () => {};

export const createSectionRow = (props) => {
  const {
    classNames,
    id,
    key,
    section,
    value,
    valueOnClick,
  } = props;

  const generatedId = `${ section }-${ key.replace(/ /g, '-').toLowerCase() }`;
  return (
    <div id={ id || generatedId } className={ `section-row ${ classNames }` }>
      <div className='section-key'>{ key }</div>
      <div className='section-value' onClick={ valueOnClick || noop }>{ value || '-' }</div>
    </div>
  );
};

export const allTrue = (requiredProps, values) => {
  if (!requiredProps || !values) {
    return false;
  }
  const properties = requiredProps.reduce((acc, val) => {
    acc[val] = Array.isArray(values[val]) ? values[val].every(v => v === true) : values[val];
    return acc;
  }, {});

  const submittable = Object.keys(properties).every(j => properties[j] === true);
  return submittable;
};

export const cityState = (c,s) => ({ city: c || '', state: s || '' });

export const isStateInCountry = ({ country, state }) => {
  if (!country || !state) {
    return false;
  }
  
  const Country = country.toUpperCase();
  const key = Country === 'US' ? 'states' : 'provinces';
  const states = Locations.get()[key];
  return states.includes(state.toUpperCase());
};
