import { Component } from 'react';
import WishlistRow from './WishlistRow';
export default class WishList extends Component {
  constructor(props) {
    super(props)
    this.handleSearch = this.handleSearch.bind(this);
    this.loadWorkingListModal = this.loadWorkingListModal.bind(this);
  }

  handleSearch(e){
    if(e.key === 'Enter') this.props.searchWishList(e.currentTarget.value)
  }

  loadWorkingListModal(wish){
    this.props.updateState({ selectedWishlist: wish, showWorkingListModal: true })
  }

  isWishListPopulated() {
    let wishList = this.props.state.agentWishLists;
    return wishList && wishList.length;
  }

  wishList() {
    let wishList = this.props.state.agentWishLists;

    return wishList.map((wish, idx) => (
      <WishlistRow
        key={idx}
        wish={wish}
        removeWish={this.props.removeWish}
        loadWorkingListModal={this.loadWorkingListModal}
      />
    ))
  }

  emptyList() {
    return (
      <div className='list-item' style={{ height: 100 }}>
        <div className="list-item-centered-detail w1000 message">Agent Suggestions Empty</div>
      </div>
    )
  }

  render() {
    let { klass, updateState } = this.props;
    return (
      <div className={klass}>
        <div className='toolbar'>
          <h3>Agent Suggestions</h3>
          <input
            type='button'
            className='add-button'
            onClick={() => { updateState({ showAddToWishListModal: true }) }}
            value='Add'
          />
          <h3>Search Master List</h3>
          <input
            className='search-wish-list-input'
            onKeyUp={(e) => this.handleSearch(e)}
          />
        </div>
        <div className='tab-content'>
          <div className='list h280'>
            <div className='list-inner'>
              {this.isWishListPopulated() ? this.wishList() : this.emptyList()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
