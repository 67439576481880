export const generateClassName = (addedClass) => {
  let className = ['input-field'];

  if (Array.isArray(addedClass)) {
    className = className.concat(addedClass);
  } else {
    className.push(addedClass);
  }
  return className.join(' ');
};

export const generateErrors = (errors) => {
  const errorMessage = errors.join(', ');
  const errorBool = (errors.length > 0).toString();

  return { errorMessage, errorBool };
};
