import React from 'react';

import FormLabel from '../FormLabel';
import ErrorNotice from '../ErrorNotice';
import { selectField } from '../shared/PropTypes';
import { generateClassName, generateErrors } from '../shared/generate';

const SelectField = (props) => {
  const {
    allowBlank,
    blankText,
    disabled,
    error,
    handleChange,
    id,
    keyOnly,
    klass,
    label,
    required,
    onFocus,
    selectOptions,
    sortOptions,
    sortBy,
    value,
  } = props;

  const className = generateClassName(klass);
  const { errorMessage, errorBool } = generateErrors(error);

  function renderOptions() {
    const isArray = Array.isArray(selectOptions);
    const iterator = isArray
      ? selectOptions
      : Object.keys(selectOptions);

    if (sortOptions) {
      if (sortBy === 'key') {
        iterator.sort();
      } else if (sortBy === 'value') {
        // eslint-disable-next-line react/prop-types
        iterator.sort((a, b) => selectOptions[a].localeCompare(selectOptions[b]));
      }
    }

    function optionText(option) {
      return (isArray || keyOnly) ? option : selectOptions[option];
    }

    return (
      <>
        {iterator.map((option) => (
          <option key={option} value={option}>{optionText(option)}</option>
        ))}
      </>
    );
  }

  return (
    <div className={className}>
      <FormLabel
        label={label}
        htmlFor={id}
        required={required}
      >
        <div className="select-wrapper">
          <select
            id={id}
            name={id}
            value={value || ''}
            error={errorBool}
            onFocus={onFocus}
            disabled={disabled}
            onChange={handleChange}
          >
            {
              allowBlank
                && (
                  blankText
                    ? <option value="" aria-label="blank">{blankText}</option>
                    : <option value="" aria-label="blank" />
                )
            }
            {renderOptions()}
          </select>
        </div>
      </FormLabel>
      <ErrorNotice error={errorMessage} />
    </div>
  );
};

SelectField.propTypes = selectField.props;
SelectField.defaultProps = selectField.defaultProps;

export default SelectField;
