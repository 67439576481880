/**
 * Entrypoint for our next-generation JavaScript environment.
 */

import './images';

// Core tinymce
import 'tinymce/tinymce';

import 'tinymce/models/dom';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/content/default/content';
import 'tinymce/skins/ui/oxide/skin';


// Plugins tinymce
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';


/* Export initializeCustomerSuccess to the window. */
import { initialize as initializeCustomerSuccess } from '../components/CustomerSuccess';
window.initializeCustomerSuccess = initializeCustomerSuccess;

import { initializeAppointment as initializeCustomerSuccessBookAppointment } from '../components/CustomerSuccess';
window.initializeCustomerSuccessBookAppointment = initializeCustomerSuccessBookAppointment;

/* Export initializeWelcomeCallDialer to the window. */
import { initialize as initializeWelcomeCallDialer } from '../components/WelcomeCallDialer';
window.initializeWelcomeCallDialer = initializeWelcomeCallDialer;

/* Export initializeNewLeadForm to the window. */
import { initialize as initializeNewLeadForm }  from '../components/LeadCreation';
window.initializeNewLeadForm = initializeNewLeadForm;

import { initialize as initializeLeadCreationForm } from '../components/Lead/Creation/Form/initialize';
window.initializeLeadCreationForm = initializeLeadCreationForm;

/* Export initializeProfileReview to the window. */
import { initialize as initializeProfileReview } from '../components/ProfileReview';
window.initializeProfileReview = initializeProfileReview;

/* Export initializeProfileReviewManagementTool to the window. */
import { initialize as initializeProfileReviewManagementTool } from '../components/ProfileReview/ManagementTool';
window.initializeProfileReviewManagementTool = initializeProfileReviewManagementTool;

/* Export initializeAgentInsight to the window. */
import { initialize as initializeAgentInsight } from '../components/AgentInsight';
window.initializeAgentInsight = initializeAgentInsight;

/* Export initializeAgentInsightMonitor to the window. */
import { initializeMonitor as initializeAgentInsightMonitor } from '../components/AgentInsightMonitor';
window.initializeAgentInsightMonitor = initializeAgentInsightMonitor;

/* Export initializeAgentServiceArea to the window. */
import { initialize as initializeAgentServiceArea } from '../components/AgentServiceArea';
window.initializeAgentServiceArea = initializeAgentServiceArea;

/* Export initializeQualQueue to the window. */
import {initializeContent as initializeQualQueueContent } from '../components/QualQueue';
window.initializeQualQueueContent           = initializeQualQueueContent;

/* Export ConferenceCall to the window. */
import { initialize as initializeLiveCallControls }  from '../components/LiveCallControls';
window.initializeLiveCallControls = initializeLiveCallControls;

/* Export Counts to the window. */
import { initialize as initializeCounts } from '../components/Counts';
window.initializeCounts = initializeCounts;

/* Export CreateCanadianAgent to the window. */
import { initialize as initializeCreateCanadianAgent } from "../components/CreateCanadianAgent";
window.initializeCreateCanadianAgent = initializeCreateCanadianAgent;

/* Export initializeProductDashboard to the window. */
import { initialize as initializeProductDashboard } from '../components/ProductDashboard';
window.initializeProductDashboard = initializeProductDashboard;

import { initialize as initializeRexProductSalesBatchProcessing } from '../components/RexProductSales/BatchProcessing';
window.initializeRexProductSalesBatchProcessing = initializeRexProductSalesBatchProcessing;

import { initialize as initializeRexProductSalesManagement } from '../components/RexProductSales/Management';
window.initializeRexProductSalesManagement = initializeRexProductSalesManagement;

import { initialize as initializeRexProductSalesForm } from '../components/RexProductSales/Form';
window.initializeRexProductSalesForm = initializeRexProductSalesForm;

import { initialize as initializeRexProductSalesHistory } from '../components/RexProductSales/History';
window.initializeRexProductSalesHistory = initializeRexProductSalesHistory;

import { initialize as initializeLiveAccountManagement } from '../components/LiveAccountManagement';
window.initializeLiveAccountManagement = initializeLiveAccountManagement;

import { initialize as initializeLiveRefundManagement } from '../components/LiveRefundManagement';
window.initializeLiveRefundManagement = initializeLiveRefundManagement;

import { initialize as initializePrimeActivation } from '../components/PrimeActivation';
window.initializePrimeActivation = initializePrimeActivation;

import { initialize as initializePrimeAccountManagement } from '../components/Prime/Management';
window.initializePrimeAccountManagement = initializePrimeAccountManagement;

import { initialize as initializePrimePerformanceInsights } from '../components/Prime/PerformanceInsights';
window.initializePrimePerformanceInsights = initializePrimePerformanceInsights;

import { initialize as initializeStripeTransactionHistory } from '../components/StripeTransactionHistory';
window.initializeStripeTransactionHistory = initializeStripeTransactionHistory;

/* Export initializeStuckLeadTool to the window. */
import { initialize as initializeStuckLeadTool } from '../components/StuckLeadTool';
window.initializeStuckLeadTool = initializeStuckLeadTool;

/* Export initializeEditableAvatar to the window. */
import { initialize as initializeEditableAvatar } from '../components/EditableAvatar';
window.initializeEditableAvatar = initializeEditableAvatar;

import { initialize as initializeAttom } from '../components/Attom/initialize';
window.initializeAttom = initializeAttom;

import { initialize as initializeReviewModeration } from '../components/ReviewModeration/initialize';
window.initializeReviewModeration = initializeReviewModeration;

import { initialize as initializeRecruitTopAgentEmailHistory } from '../components/RecruitTopAgent/EmailHistory';
window.initializeRecruitTopAgentEmailHistory = initializeRecruitTopAgentEmailHistory;

import { initialize as initializeReaClustersDetails } from '../components/ReaClusters/Details/initialize';
window.initializeReaClustersDetails = initializeReaClustersDetails;

import { initialize as initializeReaClustersManagement } from '../components/ReaClusters/Management/initialize';
window.initializeReaClustersManagement = initializeReaClustersManagement;

import { initialize as initializeDialerRecruitTopAgents } from '../components/Dialer/RecruitTopAgents';
window.initializeDialerRecruitTopAgents = initializeDialerRecruitTopAgents;

import { initialize as initializeDialerRecruitTopAgentsResearch } from '../components/Dialer/RecruitTopAgentsResearch';
window.initializeDialerRecruitTopAgentsResearch = initializeDialerRecruitTopAgentsResearch;

import { initialize as initializeDialerRecruitTopAgentsQueue } from '../components/Dialer/RecruitTopAgentsQueue';
window.initializeDialerRecruitTopAgentsQueue = initializeDialerRecruitTopAgentsQueue;

import { initialize as initializeQuickSearchAgentProfileReviewsSearchResults } from '../components/QuickSearch/AgentProfileReviews/SearchResults';
window.initializeQuickSearchAgentProfileReviewsSearchResults = initializeQuickSearchAgentProfileReviewsSearchResults;

import { initialize as initializeLeadSMS } from '../components/LeadSMS';
window.initializeLeadSMS = initializeLeadSMS;

import { initialize as initializePrimeEligibleDialer } from '../components/Prime/EligibleDialer/initialize';
window.initializePrimeEligibleDialer = initializePrimeEligibleDialer;

import { initialize as initializeClaimValidationDialer } from '../components/Claim/ValidationDialer/initialize';
window.initializeClaimValidationDialer = initializeClaimValidationDialer;
