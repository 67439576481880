import { Component } from 'react';

export default class Feed extends Component {
  constructor(props) {
    super(props)

    this.feedTypes = ['Agent', 'Manager', 'Executive']
  }

  feed() {
    let feeds = this.props.state.feed;

    if (feeds.length) {
      return feeds.map((feed, idx) => {
        return feed.event_type === 'note'
          ? this.noteFeed(feed, idx)
          : this.matchFeed(feed, idx);
      })
    } else {
      return this.emptyList();
    }
  }

  matchFeed(feed, idx){
    let leadAddress = [feed.lead.city, feed.lead.state, feed.lead.zip].filter(Boolean).join(', ');
    return(
      <div className="list-item-centered" style={{height: '80px', padding: '1px'}}key={idx}>
        <div className="feed-container">
          <div className="left update">
            <div className="primary">{ this.matchFeedTitle(feed.event_type) }</div>
            <div className="secondary">
              <a href={`/a/${feed.agent.id}`} target='_blank'>
                {feed.agent.full_name}
              </a>
            </div>
            <div className="tertiary">{feed.lead.full_name} - {feed.lead.lead_type}</div>
            <div className="tertiary">{leadAddress}</div>
          </div>
          <div className="right">
            <div className="primary">{feed.event_at}</div>
            <div className="secondary orange">AGENT</div>
        </div>
        </div>
      </div>
    )
  }

  noteFeed(feed, idx){
    let message = feed.note.body || 'blank';
    return(
      <div className="list-item-centered" style={{ height: '80px', padding: '1px' }}key={idx}>
        <div className="feed-container">
          <div className="left ai-note">
            <div className="primary">{ feed.note.status }: { feed.agent.full_name }</div>
            <div className="secondary">{feed.user.full_name}</div>
            <div className="tertiary">{ message }</div>
          </div>
          <div className="right">
            <div className="primary">{feed.event_at}</div>
            <div className="secondary blue">{ feed.user.rank === 0 ? 'Manager' : 'Exec' }</div>
        </div>
        </div>
      </div>
    )
  }

  emptyList() {
    return (
      <div className='list-item-centered'>
        <div className="list-item-centered-detail w1000 message">Feed is empty</div>
      </div>
    )
  }

  matchFeedTitle(type){
    switch (type) {
      case 'accept':
        return 'Lead Accepted'; break;
      case 'list':
        return 'Property Listed'; break;
      case 'close':
        return 'Listing Closed'; break;
      case 'escrow':
        return 'Lead Now in Escrow'; break;
    };
  }

  render() {
    let { klass, fetchFeed } = this.props;
    let { salesManager, feedFilter, feedCurrentPage } = this.props.state;
    return (
      <div className={klass}>
        <div className='toolbar'>
          <h3>Feed Options</h3>
          &nbsp;&nbsp;&nbsp;
          <h3>Filter by feed</h3>
          <select
            value={feedFilter}
            onChange={(e) => fetchFeed(e.currentTarget.value)}
          >
            <option value=""></option>
            {this.feedTypes.map((feed, idx) => {
              return <option key={idx} value={feed}>{feed}</option>
            })}
          </select>
          <div className='pager'>
            <div className='option first' onClick={() => fetchFeed('first')}>First</div>
            <div className='option prev'>
              <i className='fa fa-caret-left' onClick={() => fetchFeed('prev')}></i>
            </div>
            <div className='page-number'>Page {feedCurrentPage}</div>
            <div className='option next'>
              <i className='fa fa-caret-right' onClick={() => fetchFeed('next')}></i>
            </div>
            <div className='option last' onClick={() => fetchFeed('last')}>Last</div>
          </div>
        </div>
        <div className='tab-content'>
          <div className='list h280'>
            <div id={`sales_manager_${salesManager.id}_feed`} className='list-inner'>
              { this.feed() }
            </div>
          </div>
        </div>
      </div>
    )
  }
}