const buildData = record => {
  const data = [];

  const {
    id,
    note,
    created_at,
    name,
    lead_id,
  } = record;

  data.push(<td>{ note }</td>);
  data.push(<td>{ lead_id }</td>);
  data.push(<td>{ moment(created_at).format('MM/DD/YYYY') }</td>);
  data.push(<td>{ name }</td>);

  return data;
};

const buildRows = notes => {
  const rows = [];

  notes.forEach(note => rows.push(<tr data-note-id={ note.id }>{ buildData(note) }</tr>));

  return rows;
};

const Body = ({ notes }) => (
  <tbody>
    { buildRows(notes) }
  </tbody>
);

export default Body;
