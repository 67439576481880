import React from 'react';
import DateField from '../../../FormBuilder/DateField';
import SelectField from '../../../FormBuilder/SelectField';
import InputField from '../../../FormBuilder/InputField';

import MergeRecords from './MergeRecords';

const days = {
  1: 'Tomorrow',
  7: '1 Week',
  15: '15 Days',
  30: '30 Days',
  45: '45 Days',
  60: '2 Months',
  90: '3 Months',
  120: '4 Months',
  150: '5 Months',
  180: '6 Months',
  365: '1 Year',
};

const Modals = (props) => {
  const {
    modal,
    setModal,
    followUp,
    setFollowUp,
    followUpDays,
    setFollowUpDays,
    prospect,
    broadcastId,
  } = props;

  const closeModal = () => {
    setModal({
      show: false,
      type: '',
    })
  };

  const clearFollowUpAndClose = () => {
    setFollowUp('');
    closeModal();
  }

  const showSuccess = () => {
    setModal({ show: true, type: 'success' })
  }

  const handleSelectChange = (e) => {
    const { value } = e.currentTarget;

    setFollowUpDays(value)
    setFollowUp(moment().add(value, 'days').format('YYYY-MM-DDTHH:mm'))
  }

  const handleChange = (e) => {
    e.currentTarget.type = 'datetime-local';
    setFollowUpDays('')
    setFollowUp(moment.utc(e.currentTarget.value).format('YYYY-MM-DDTHH:mm'))
  }

  const modalContent = () => {
    switch (modal.type) {
      case 'copy-dialer-link':
        return (
          <div className="modal-container prompt">
            <i className="close-button" onClick={closeModal} />
            <h2>URL Copied to Clipboard</h2>
            <p>
              Paste the URL into Calendly scheduled appointment.
            </p>
            <div className="button-container">
              <button
                type="button"
                onClick={closeModal}
                className="dialer-button blue"
              >
                Close
              </button>
            </div>
          </div>
        );
      case 'activate-prime':
        return (
          <div className="modal-container prompt">
            <i className="close-button" onClick={closeModal} />
            <h2>URL Copied to Clipboard</h2>
            <p>
              To access the PRIME pay funnel, paste the URL into an incognito window. The URL will expire in 60 seconds.
            </p>
            <div className="button-container">
              <button
                type="button"
                onClick={closeModal}
                className="dialer-button blue"
              >
                Close
              </button>
            </div>
          </div>
        );
      case 'schedule-follow-up':
        return (
          <div className="modal-container">
            <i className="close-button" onClick={closeModal} />
            <h2>Schedule Follow-up</h2>
            <p>
              Schedule a follow-up date via calendar picker or select a day from the preset list.
            </p>
            <div className="button-container form-builder">
              <DateField
                id="followUp"
                label="Follow-up"
                placeholder="Select date & Time"
                value={followUp}
                handleChange={handleChange}
              />
              <SelectField
                id="followUp"
                blankText="Select follow-up"
                label="In Days"
                value={followUpDays}
                selectOptions={days}
                handleChange={handleSelectChange}
                sortBy="none"
                allowBlank
              />
            </div>
            <div className="button-container">
              <button
                type="button"
                onClick={clearFollowUpAndClose}
                className="dialer-button blue"
              >
                Close
              </button>
              <button
                type="button"
                onClick={showSuccess}
                className="dialer-button blue filled"
              >
                Save
              </button>
            </div>
          </div>
        );
      case 'merge-records':
        return (
          <MergeRecords
            closeModal={closeModal}
            prospect={prospect}
            broadcastId={broadcastId}
          />
        );
      case 'success':
        return (
          <div className="modal-container prompt">
            <i className="close-button" onClick={closeModal} />
            <h2>Follow-up saved</h2>
            <i className="check-circle" />
          </div>
        );
      case 'loader':
        return (
          <div className="dialer-loader">
            <i className="cog fa fa-spin" />
          </div>
        )
      case 'warning':
        return (
          <div className="dialer-loader">
            <i className="warning fa fa-exclamation-triangle" />
          </div>
        )
      case 'calling':
        return (
          <div className="dialer-loader">
            <i className="calling fa fa-phone" />
          </div>
        )
      default:
        return (<div></div>)
    }
  }

  const modalClasslist = ['modal'];

  if (modal.show) {
    modalClasslist.push('show-flex');
  }

  return (
    <div className={modalClasslist.join(' ')}>
      {modalContent()}
    </div>
  )
};

export default Modals;
