import React, { Component } from 'react';
import { store } from '../../../index';
import { getStateProps } from '../../../utils';

import BaseSelectContainer from './index';

import View from './view';

export default class TimeContainer extends BaseSelectContainer {
  constructor(props) {
    super(props);
  }

  validateField(name, value) {
    const { dispatch } = store;
    const text_consent = store.getState().info.get('text_consent');
    const contact_date = store.getState().info.get('contact_date');

    dispatch({
      type: 'VALIDATE_CONTACT_DATE_TIME',
      data: {
        text_consent,
        contact_date,
        contact_time: value,
      },
    })
  }

  render() {
    const {
      classNames,
      display,
      id,
      index,
      property,
    } = this.props;

    const {
      focused,
      valid,
      value,
     } = this.state;

    const timeSlots = getStateProps(store.getState().timeSlots).timeSlots;

    return (
      <View
        classNames={ classNames }
        data={ timeSlots }
        display={ display }
        focused={ focused }
        id={ id }
        index={ index }
        name={ property }
        valid={ valid }
        value={ value }

        onChange={ this.handleChange }
        onFocus={ this.handleOnFocus }
      />
    );
  }

}
