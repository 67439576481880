import React, { Component } from 'react';
import { store } from '../../../index';

import { getStateProps } from '../../../utils';

import BaseNumberInput from '../index';
import View from './view';

// const strip = value => value.replace(/[^\d.-]/g, '');
const strip = value => value.replace(/[^\d-]/g, '');
const addComma = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export default class NumberInputContainer extends BaseNumberInput {
  constructor(props) {
    super(props);

    this.type = 'text';
  }

  handleOnFocus(e) {
    const { name } = e.target;
    const { dispatch } = store;

    dispatch({
      type: 'FIELD_FOCUSED',
      data: {
        key:  name,
      },
    });
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { dispatch } = store;

    const { home_price_max, home_price_min } = getStateProps(store.getState().info);

    const data = {
      home_price_max,
      home_price_min
    };

    data[name] = strip(value);

    dispatch({
      type: 'VALIDATE_MIX_MAX_PRICE',
      data,
    });

    Object.keys(data).forEach(key => {
      this.setInfoField(key, data[key]);
    });
  }

  render() {
    const {
      classNames,
      display,
      id,
      index,
      property
    } = this.props;

    const {
      focused,
      valid,
      value,
     } = this.state;

    return (
      <View
        classNames={ classNames }
        display={ display }
        focused={ focused }
        id={ id }
        index={ index }
        name={ property }
        type={ this.type }
        valid={ valid }
        value={ addComma(value) }

        onChange={ this.handleChange }
        onFocus={ this.handleOnFocus }
      />
    );
  }
}
