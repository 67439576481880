import { Map } from 'immutable';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { isStateInCountry } from '../utils';

import {
  dateValidation,
  email,
  hasNonNumeric,
  isNonEmptyInt,
  isNonEmptyString,
  isNull,
  isTrue,
  maxPrice,
  minPrice,
  zipCode,
} from './rules';

const formFields = [
  'city',
  'comments',
  'country',
  'email_address',
  'first_name',
  'home_price_min',
  'home_price_max',
  'id',
  'last_name',
  'lead_type',
  'opt_out',
  'phone_number',
  'state',
  'street_name',
  'street_number',
  'type_home',
  'unit_number',
  'zip',
];

const buyerCities = ['buyer_cities'];

const nonStandardValidations = [].concat(buyerCities);

const infoMap = {
  city: isNonEmptyString,
  comments: isTrue,
  country: isNonEmptyString,
  email_address: email,
  first_name: isNonEmptyString,
  home_price_min: minPrice,
  home_price_max: maxPrice,
  id: isTrue,
  last_name: isNonEmptyString,
  lead_type: isNonEmptyString,
  opt_out: isTrue,
  phone_number: isTrue,
  phone_number_ext: hasNonNumeric,
  state: isStateInCountry,
  street_name: isNonEmptyString,
  street_number: isNonEmptyString,
  type_home: isNull,
  unit_number: isNull,
  zip: zipCode,
};

const initialState = Map({
  buyer_cities: [false],
  city: null,
  comments: null,
  contact_date: null,
  contact_time: null,
  country: null,
  email_address: null,
  first_name: null,
  home_price_min: null,
  home_price_max: null,
  id: null,
  last_name: null,
  lead_type: null,
  opt_out: null,
  phone_number: true,
  phone_number_ext: null,
  state: null,
  street_name: null,
  street_number: null,
  text_consent: null,
  type_home: null,
  unit_number: null,
  zip: null,
});

const zipValidation = (value, country) => infoMap['zip'](value, country);
const homePriceMaxValidation = (homePriceMax, homePriceMin) => {
  return infoMap['home_price_max'](homePriceMax, homePriceMin);
};

const actionsMap = {
  ['VALIDATE_INFO']: (state, action) => {
    const { email, lead } = action.data;
    const {
      country,
      home_price_max,
      home_price_min,
      zip,
      contact_date,
      contact_time,
      text_consent,
    } = lead;

    const mapped = formFields.reduce((acc, field, i) => {
      let value = false;
      if (field === 'state') {
        value = infoMap[field]({ country: lead['country'], state: lead[field] });
      } else if (field === 'zip') {
        value = zipValidation(zip, country);
      } else if (field === 'home_price_max') {
        value = homePriceMaxValidation(home_price_max, home_price_min);
      } else if (field === 'phone_number') {
        const intlPhoneNumber = parsePhoneNumberFromString(lead[field], country);
        value = intlPhoneNumber.isValid();
      } else if (field === 'email_address') {
        value = infoMap[field](email);
      } else if (!nonStandardValidations.includes(field)) {
        const leadField = lead[field];
        value = infoMap[field](leadField);
      }
      acc[field] = value;
      return acc;
    }, {});

    return state.merge(Map({ ...mapped }));
  },
  ['VALIDATE_FIELD']: (state, action) => {
    const { key, value } = action.data;

    if (!infoMap[key]) {
      return state;
    }

    if (!nonStandardValidations.includes(key)) {
      return state.merge(Map({
        [key]: infoMap[key](value),
      }));
    }
  },
  ['INVALIDATE_BUYER_CITIES']: (state) => {
    const buyer_cities = state.get('buyer_cities');
    return state.merge(Map({
      buyer_cities: Array(buyer_cities.length).fill(false),
      city: false,
      state: false,
    }));
  },
  ['VALIDATE_COUNTRY']: (state, action) => {
    const { country, zip } = action.data;
    return state.merge(Map({
      country: infoMap['country'](country),
      zip: zipValidation(zip, country),
    }));
  },
  ['VALIDATE_PHONE_FIELD']: (state, action) => {
    const { key, value } = action.data;
    return state.merge(Map({
      [key]: value,
    }));
  },
  ['VALIDATE_MIX_MAX_PRICE']: (state, action) => {
    const { home_price_max, home_price_min } = action.data;
    return state.merge(Map({
      home_price_max: homePriceMaxValidation(home_price_max, home_price_min),
      home_price_min: infoMap['home_price_min'](home_price_min),
    }));
  },
  ['VALIDATE_ZIP']: (state, action) => {
    const { country, key, value } = action.data;
    return state.merge(Map({
      country: infoMap['country'](country),
      zip: zipValidation(value, country),
    }));
  },
  ['SET_VERIFIED_LOCATION']: (st, action) => {
    const { state, valid } = action.data;
    return st.merge(Map({
      city: valid,
      state
    }));
  },
  ['VALIDATE_CONTACT_DATE_TIME']: (state, action) => {
    const { text_consent, contact_date, contact_time } = action.data;

    const allEmpty = !text_consent && !contact_date && !contact_time;


    return state.merge(Map({
      text_consent: allEmpty ? null : text_consent,
      contact_date: allEmpty ? null : !!contact_date,
      contact_time: allEmpty ? null : !!contact_time,
    }))
  },
  ['SET_BUYER_CITY_LOCATION']: (state, action) => {
    const { index, valid } = action.data;
    const buyerCities = state.get('buyer_cities');
    buyerCities[index] = valid;
    return state.merge(Map({
      ...state,
      buyer_cities: buyerCities
    }));
  },
  ['SET_BUYER_CITY_LOCATIONS']: (state, action) => {
    return state.merge(Map({
      ...state,
      buyer_cities: action.data
    }));
  },
  ['REMOVE_BUYER_CITY_FIELD_VALIDATION']: (state, action) => {
    const { index } = action;
    const buyerCities = state.get('buyer_cities');
    buyerCities.splice(index, 1);
    return state.merge(Map({ buyer_cities: buyerCities }));
  },
  ['VALIDATE_RAW_LOCATION_FIELDS']: (state, action) => {
    const { lead_type, rawAddressFields } = action.data;
    const fields = Object.keys(rawAddressFields).reduce((acc, key) => {
      if ((['city', 'state'].includes(key) && lead_type === 'buyer') || !infoMap[key]) {
        return acc;
      }
      if (key === 'zip') {
        acc['zip'] = infoMap['zip'](rawAddressFields['zip'], rawAddressFields['country']);
      } else if (!nonStandardValidations.includes(key)) {
        acc[key] = infoMap[key](rawAddressFields[key]);
      }
      return acc;
    }, {});

    return state.merge(Map({
      ...state,
      ...fields,
    }))
  },
  ['RESET_VALIDATIONS']: (state) => {
    return state.merge(initialState);
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
