import Checkbox from './index';
import React from 'react';
import { store } from '../../../index';

import View from './view';

export default class TextConsent extends Checkbox {
  validateField(name, value) {
    const { dispatch } = store;
    const contact_date = store.getState().info.get('contact_date');
    const contact_time = store.getState().info.get('contact_time');

    dispatch({
      type: 'VALIDATE_CONTACT_DATE_TIME',
      data: {
        text_consent: value,
        contact_date,
        contact_time,
      },
    })
  }
}
