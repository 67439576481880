import React, { Component } from 'react';

import ChangePackage from './ChangePackageView';

export default class ChangePackageContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: 'choices',
      chosen: null,
      loading: false,
      userRexProductOffering: props.userRexProductOffering
    };

    this.confirm = this.confirm.bind(this);
    this.finished = this.finished.bind(this);
    this.handlePackageChange = this.handlePackageChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  handlePackageChange(offering) {
    this.setState({ chosen: offering });
  }

  submit() {
    this.setState({ display: 'confirmation' });
  }

  confirm() {
    const {
      agentId,
      rexProductOfferings,
      updateManagement,
      unsuccessfulRequest,
      userRexProductOffering
    } = this.props;
    const { chosen } = this.state;

    updateManagement({ loading: true });
    this.setState({ loading: true });

    const oldPackage = rexProductOfferings.find(offering => offering.id === userRexProductOffering.product_offering_id);

    fetch(`/agents/${agentId}/change_live_package`,
      {
        credentials: 'same-origin',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ package: chosen, old_package: oldPackage })
    })
      .then(res => {
        const planName = document.querySelector('.plan-name');
        const planCosts = document.querySelector('.plan-costs');

        planName.innerText = chosen.name;
        planCosts.innerText = `($${chosen.initial_price/100},$${chosen.recurring_price/100},$${chosen.coin_price/100})`;

        updateManagement({
          loading: false,
          userRexProductOffering: {
            product_offering_id: chosen.id
          }
        });
        this.setState({ display: 'success', loading: false });
      })
      .catch(err => {
        unsuccessfulRequest(err);
      });
  }

  finished() {
    const { updateManagement } = this.props;

    updateManagement({
      displaying: null,
      modalOpen: false,
    });
  }

  render() {
    const {
      chosen,
      display,
      loading,
      userRexProductOffering
    } = this.state;
    const {
      rexProductOfferings,
      updateManagement,
    } = this.props;

    return(
      <ChangePackage
        chosen={ chosen }
        display={ display }
        loading={ loading }
        rexProductOfferings={ rexProductOfferings }
        updateManagement={ updateManagement }
        userRexProductOffering={ userRexProductOffering }
        confirm={ this.confirm }
        finished={ this.finished }
        handlePackageChange= { this.handlePackageChange }
        submit={ this.submit }
      />
    );
  }
}
