import React from 'react';
import ReactDOM from 'react-dom';
import PrimePerformanceInsights from './Insights';

/**
 * Initialize the PrimeManagement modal.
 */

export function initialize(containerElement, props) {
  const component = React.createElement(PrimePerformanceInsights, props);
  ReactDOM.render(component, containerElement);
}
