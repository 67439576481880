const Moderator = ({ moderator }) => {
  const {
    name,
    moderated_at,
  } = moderator;

  const formattedDate = moderated_at ? moment.utc(moderated_at).format('dddd, MMMM Do YYYY h:mm A') : 'N/A';

  return (
    <div className="col-md-6">
      <div className="moderator-details">
        <h3>Moderator</h3>
        <table className="table">
          <tbody>
            <tr>
              <th className="col-md-2">Name</th>
              <td className="col-md-10">{ name || 'N/A' }</td>
            </tr>
            <tr>
              <th className="col-md-2">Moderated Date</th>
              <td className="col-md-10">{ formattedDate || 'N/A' }</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
};

export default Moderator;
