import LiveControls from '../../LiveCallControlsV2/LiveControls';
import LiveControlsAgent from './LiveControlsAgent';
import Agent from '../../LiveCallControlsV2/Agent';
import Lead from '../../LiveCallControlsV2/Lead';
import Conference from '../../LiveCallControlsV2/Conference';
import * as Phone from '@referralexchange/phone';
import request from 'superagent';
import './styles.scss';

export default class LiveControlsUI extends LiveControls {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      activeAgentID: null,
      notice:        null
    };

    this.phoneClient = new Phone.Client({
      portType: Phone.PortType.EmbeddedWeb,
      remoteExtensionID: 'ngjhphgjojadbfihoaleohdbnekniddo'
    });
  }

  componentDidMount() {
    this.phoneClient.getActiveConnection().then(({ connection, isConnected }) => {
      if(isConnected) {
        if (!connection.callControlUUID || !connection.sessionUUID) {
          this.setState({
            callControlUUID: connection.callControlUUID,
            sessionUUID: connection.sessionUUID,
            leadCallStartTime: Date.parse(connection.connectedAt)
          });
        } else {
          this.setState({ notice: 'No call control UUID or session UUID detected, check your extension version please.' })
        }
      } else {
        this.setState({ notice: 'No active connection/call detected, please check your extension and re-open the transfer tab.' })
      }
    });
  }

  componentDidUpdate(_, prevState) {
    const agentDisconnect = ['agent_disconnected', 'agent_unavailable'].includes(this.state.callStatus),
          callStatusChanged = this.state.callStatus !== prevState.callStatus
    if (callStatusChanged && agentDisconnect) {
      const match = this.props.matches.filter(match => match.agent.id === prevState.activeAgentID)[0]
      this.setState({
        activeAgentID: null,
        callControlUUID: null,
        notice: !!match ? `Agent ${match.agent.first_name} ${match.agent.last_name} is not available.` : null
      });
    }
  }

  /**
 * Begin the process of patching the agent into the call.
 */
  contactAgent() {
    return new Promise((resolve, reject) => {
      console.debug('contacting agent')

      this.setState({ callStatus: 'agent_contacted' });

      request.post('/phone/live_conference/v2/contact_agent_profile_review')
        .send({
          call_control_uuid: this.state.callControlUUID
        }).catch(err => {
          console.error(err);
          reject(err);
        });
    });
  }

  /**
   * Make the API call to initiate the Live Transfer system.
   */
  startLiveTransfer(agentID) {
    return new Promise((resolve, reject) => {
      request
        .post('/phone/live_conference/v2')
        .send({
          session_uuid: this.state.sessionUUID,
          rep_id: this.props.rep.id,
          lead_id: this.props.lead.id,
          agent_id: agentID,
          conference_type: this.props.conferenceType,
        })
        .then(({ body }) => {
          console.debug('start live transfer API response', body)
          resolve(body.call_control_uuid);
        })
        .catch(err => reject(err))
    });
  }

  initiate(agentID) {
    return new Promise((resolve, reject) => {
      this.setState({ activeAgentID: agentID });

      this.startLiveTransfer(agentID)
        .then(callControlUUID => {
          this.setState({ callControlUUID })
          console.debug('set call control UUID', callControlUUID)
        })
        .then(this.contactAgent)
        .then(resolve)
        .catch(err => {
          console.error('could not start live transfer', err)
          reject(err);
        })
    });
  }

  renderNotice() {
    return(
      this.state.notice ?
        <div className='live-call-control-error'>
          {this.state.notice}
        </div>
      : null
    )
  }

  render() {
    let { matches } = this.props;
    let { callStatus, activeAgentID, conferenceCallStartTime, agentCallStartTime, leadCallStartTime } = this.state;
    let primeEnabledMatches = matches.filter(match => match.agent.rex_prime === true)

    return (
      <div className="live-call-control-container control-user-info">
        {this.state && this.state.callControlUUID && this.renderSubscription()}
        <div className="transfer-header">
          Warm Transfer is available for ReferralExchange Prime members only.
        </div>
        {this.renderNotice()}

        {primeEnabledMatches.map(match =>
          <LiveControlsAgent
            callStatus={callStatus}
            agent={match.agent}
            activeAgentID={activeAgentID}
            initiate={this.initiate.bind(this)}
            conferenceCallStartTime={conferenceCallStartTime}
            agentCallStartTime={agentCallStartTime}
            leadCallStartTime={leadCallStartTime}
          />  
        )}
        <div className="conference-controls">
          <Agent
            updateCallMode={this.updateCallMode}
            callStatus={callStatus}
          />

          <Conference
            updateCallMode={this.updateCallMode}
            callStatus={callStatus}
          />

          <Lead
            updateCallMode={this.updateCallMode}
            callStatus={callStatus}
          />
        </div>
      </div>
    );
  }
}
