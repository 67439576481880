import React from 'react';

const FinishedView = (props) => {
  const { close, message } = props;

  return (
    <div className='rex-modal__finished'>
      { message &&
        <div className='rex-modal-subheading'>
          { message }
        </div>
      }      
      <div className='rex-modal-actions rex-modal-actions__centered'>
        <button
          className='rex-modal-action'
          onClick={ close }
        >
          Finished
        </button>
      </div>
    </div>
  );
}

export default FinishedView;
