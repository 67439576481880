import StringFormatter from '../../../../../../common/String';

const stringFormatter = new StringFormatter;

const Email = (props) => {
  const {
    fetched,
    history,
  } = props;

  function buildHistory() {
    const rows = [];

    history.forEach((hist, i) => {
      const {
        created_at,
        email_type,
        from_email,
        id,
        status,
        to_email,
      } = hist;

      const formattedDate = moment.utc(created_at).format('dddd, MMMM Do YYYY h:mm A');
      const values = [];

      values.push(<td key={ `from_email.${id}.${i}` }>{ from_email }</td>);
      values.push(<td key={ `to_email.${id}.${i}` }>{ to_email }</td>);
      values.push(<td key={ `email_type.${id}.${i}` }>{ email_type }</td>);
      values.push(<td key={ `formattedDate.${id}.${i}` }>{ formattedDate }</td>);

      values.push(
        <td key={ `status.${id}.${i}` }>
          <a className='btn btn-xs btn-default' data-remote='true' href={ `/emails/${id}/message` }>{ stringFormatter.titleCase(status) }</a>
        </td>
      );

      rows.push(<tr key={ `row.${id}.${i}` } data-email-id={ id }>{ values }</tr>);
    });

    return rows;
  }

  return (
    <tbody>
      { fetched && buildHistory() }
    </tbody>
  )
};

export default Email;
