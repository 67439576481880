import CommunicationsIcon from '../../../../LiveAccountManagement/images/communications.png';

const _renderReasons = (props) => {
  const reasons = [
    'Unhappy with service/leads',
    'Misunderstood membership/Subscription cost/Lead processing fees',
    'Technical issues (i.e. leads not routing correctly)',
    'Lack of support/Customer Service',
    'Happy with service, but no longer have a need for the product',
    'Other',
  ];

  const {
    selectedText,
    setSelected,
  } = props;

  const content =
    reasons.map((reason, index) => {
      return (
        <div className='rex-modal-radio-wrapper'>
          <label key={ index } htmlFor={ `reason-${index}` }>
            <input
              key={ index }
              type='radio'
              name='reasons'
              id={ `reason-${index}` }
              value={ reason }
              checked={ reason == selectedText }
              onChange={ setSelected }
            />
            { reason }
          </label>
        </div>
      );
    });

  return content;
};

const _cancellationReason = (props) => {
  const {
    otherText,
    selectedText,
  } = props;

  const reason = [selectedText];

  if (otherText && otherText.trim()) reason.push(otherText);

  return reason.join(', ').trim();
};

const _validateReasonSelected = (props) => {
  const {
    otherText,
    selectedText,
  } = props;

  const otherSelected = (/^Other$/.test(selectedText));
  const optionSelected = (selectedText != '');
  const textPresent = (otherText.trim() != '');

  if (otherSelected) {
    return textPresent;
  } else {
    return optionSelected;
  }
};

const _cancellationSubmit = (event, props) => {
  const {
    action,
    toggleButtons,
    moveTo,
    primeSubscriptionPath,
    setAndGo,
    setAttribute,
  } = props;

  toggleButtons(event, 'disable');

  const reasonOtherTextarea = document.querySelector('.prime-management-content .reason-other-text');
  const data = JSON.stringify({ action: action, reason: _cancellationReason(props) });

  reasonOtherTextarea.classList.remove('error');

  if (_validateReasonSelected(props)) {
    fetch(
      primeSubscriptionPath,
      {
        method: 'PUT',
        credentials: 'same-origin',
        headers: {
          'Content-type': 'application/json',
        },
        body: data,
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        setAttribute('planCancelled', true);
        moveTo('success');
      } else {
        throw new Error(response.errors);
      }
    })
    .catch(error => {
      setAndGo('error', error.message, 'fail');
    });
  } else {
    toggleButtons(event, 'enable');
    reasonOtherTextarea.classList.add('error');
  }
};

const Reason = (props) => {
  const {
    fillOther,
    moveTo,
    otherText,
    selectedText,
    toggleModal,
  } = props;

  return (
    <div id='cancel-reason'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ CommunicationsIcon } />
      </div>

      <div className='rex-modal-heading'>
        WHY DOES THE AGENT WANT TO CANCEL?
      </div>

      <div className='rex-modal-contents'>
        <div className='rex-modal-subheading'>
          { _renderReasons(props) }
          <textarea className='reason-other-text' placeholder='Please input feedback here.' value={ otherText } onChange={ fillOther }></textarea>
        </div>

        <div className='rex-modal-actions'>
          <button
            className='rex-modal-action'
            onClick={ (e) => _cancellationSubmit(e, props) }
            disabled={ selectedText == '' }
          >
            CONTINUE CANCELLING
          </button>

          <button
            className='rex-modal-action rex-modal-action__loading'
            onClick={ () => toggleModal(false) }
          >
            KEEP ACCOUNT ACTIVE
          </button>
        </div>
      </div>
    </div>
  )
};

export default Reason;
