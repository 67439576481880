import { Component } from 'react';

export default class QualAttempts extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      qual_note_data: []
    }
  }

  componentDidMount() {
    if(this.props.lead_id)
      this.obtainQualAttempts()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lead_id != this.props.lead_id) {
      this.obtainQualAttempts(nextProps.lead_id)
    }
  }

  obtainQualAttempts(id) {
    let lead_id = id || this.props.lead_id;
    $.get('/qual/queue/requal_attempts', {lead_id: lead_id}, function(result) {
      this.setState({
        qual_note_data: result.qual_note_data
      })
    }.bind(this))
  }

  renderQualAttempts() {
    var attempts = [];
    if(this.state.qual_note_data) {
      attempts = this.state.qual_note_data.map(function(record, idx) {
        return (
          <div key={idx} className='requal-attempt-record' style={{marginBottom: '10px'}}>
            {' '}
            {record.created_at}
            {' - '}
            {record.created_by}
          </div>
        )
      })
      return attempts;
    } else {
      return null;
    }
  }

  renderQualNotes() {
    if(this.state.qual_note_data.length) {
      return (
        this.state.qual_note_data.map(function(record, idx) {
          return (
            <div key={idx} className='requal-attempt-record' style={{marginBottom: '10px'}}>
              {record.status}
            </div>
          )
        })
      );
    } else {
      return null;
    }
  }

  resetForm() {
    $('#new_validation_record').reset()
  }

  render() {
    return(
      <div className='results inline-block v-align-top width-100' style={{padding: '15px 0px'}}>
        <div className=''>
          <h3 style={{padding: 0, marginBottom: '10px'}}>Qual Notes: </h3>
        </div>
        <div className='v-align-top inline-block' style={{whiteSpace: 'normal', wordWrap: 'break-word', width: '34%'}}>
          {this.renderQualAttempts()}
        </div>
        <div className='v-align-top inline-block' style={{whiteSpace: 'normal', wordWrap: 'break-word', width: '55%'}}>
          {this.renderQualNotes()}
        </div>
      </div>
    );
  }
}
