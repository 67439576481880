import React, { useState, useEffect } from 'react';

import CheckboxField from './../../_common/CheckboxField.js';

const AGENT_ATTRS = [
  'id',
  'name',
  'email',
  'phone_mobile',
  'company_city',
  'company_state',
];

const Matches = props => {
  const {
    matches = [],
    updateFieldHandler,
  } = props;

  const [checkAll, toggleCheckAll] = useState(false);
  const [checkText, setCheckText] = useState('+ Check All');

  useEffect(() => {
    const text = checkAll ? '- Uncheck All' : '+ Check All';

    toggleCheckboxes();
    setCheckText(text);
  }, [checkAll]);

  useEffect(() => toggleCheckAll(false), [matches]);

  const toggleCheckboxes = () => {
    const checkboxes = document.querySelectorAll('#lead-from-lead-matches input[type="checkbox"]');

    [...checkboxes].filter(checkbox => !checkbox.disabled && checkbox.checked != checkAll).forEach(checkbox => {
      setTimeout(() => checkbox.click(), 100);
    });
  };

  const handleOnClick = event => {
    const table = event.target.closest('table');
    const inputs = table.querySelectorAll('input[type="checkbox"]');
    const totalCheckable = [...inputs].filter(i => !i.disabled).length;
    const numChecked = [...inputs].filter(i => !i.disabled && i.checked).length;

    if (numChecked === 0) toggleCheckAll(false);
    if (numChecked === totalCheckable) toggleCheckAll(true);
  };

  const buildRow = match => {
    const row = [];

    row.push(<td key="agent_ids"><CheckboxField fieldType="array" fieldName="agent_ids" required={ false } updateFieldHandler={ updateFieldHandler } handleOnClick={ handleOnClick } fieldValue={ match.id } /></td>);

    AGENT_ATTRS.forEach(key => {
      row.push(<td key={ match[key] }>{ match[key] }</td>);
    });

    return row;
  };

  const buildRows = () => {
    const rows = [];

    matches.forEach(match => rows.push(<tr key={ match.id }>{ buildRow(match) }</tr>));

    return rows;
  };

  const renderView = () => {
    return (
      <React.Fragment>
        <div className="header sub-header">
          <div className="icon-wrapper"><i className="icon user-circle"></i></div>
          <div className="title-wrapper">
            <p>Match Lead</p>
          </div>
        </div>

        <div className="input-row">
          <table id="lead-from-lead-matches" className="table table-striped">
            <thead>
              <tr>
                <th className="check-all" onClick={ () => toggleCheckAll(!checkAll) }>{ checkText }</th>
                <th>Agent ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>City</th>
                <th>State</th>
              </tr>
            </thead>
            <tbody>
              { buildRows() }
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="section">
      { renderView() }
    </div>
  );
};

export default Matches;
