import { Component } from 'react';

import Header from './Header';
import LeadInfo from './LeadInfo';
import AgentContainer from './AgentContainer';

const TopContainer = props => {
  const {
    callLead,
    lead,
    matches,
    toggleEditContainer,
    toggleMultipleContainer,
    dual_transaction,
    dual_transaction_buyer,
    directReferral,
    exclusive,
    exclusiveAgentId,
    exclusiveAgentLink,
  } = props;

  function sortedMatches() {
    return matches.sort(match => match.agent.rex_prime === true ? -1 : 1);
  }

  return (
    <div className='profile-review-top-container'>
      <Header
        lead={lead}
        toggleEditContainer={toggleEditContainer}
        toggleMultipleContainer={toggleMultipleContainer}
        dual_transaction={dual_transaction}
        dual_transaction_buyer={dual_transaction_buyer}
        directReferral={directReferral}
        exclusive={exclusive}
        exclusiveAgentId={exclusiveAgentId}
        exclusiveAgentLink={exclusiveAgentLink}
      />
      <LeadInfo
        lead={lead}
        callLead={callLead}
        dual_transaction={dual_transaction}
        dual_transaction_buyer={dual_transaction_buyer}
      />
      <AgentContainer matches={ sortedMatches() } />
    </div>
  );
}

export default TopContainer;
