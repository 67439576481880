import  { Component } from  'react';
import houseBlack from './images/house_svg_black.svg';
import houseBlue from './images/house_svg_blue.svg';
import houseGrey from './images/house_svg_grey.svg';
import houseWhite from './images/house_svg_white.svg';
import houseYellow from './images/house_svg_yellow.svg';
import ButtonBase from './ButtonBase';

export default class Lead extends ButtonBase {
  determineStatusClass() {
    switch(this.props.callStatus) {
      case 'init':
        return 'on-line'
      case 'contacting_lead':
        return 'on-line'
      case 'contacting_agent':
        return 'on-line'
      case 'agent_contacted':
        return 'on-line'
      case 'lead_connected':
        return 'on-line'
      case 'agent_ready':
        return 'on-line'
      case 'agent_connected':
        return 'call'
      case 'agent_unavailable':
        return 'on-line'
      case 'solo_agent':
        return 'hold'
      case 'solo_lead':
        return 'on-line'
      case 'conference':
        return 'call'
      case 'complete':
        return 'disabled'
      default:
        return 'disabled'
    }
  }

  renderButtonContents() {
    let iconMap = {
      'init'             : houseWhite,
      'contacting_lead'  : houseWhite,
      'contacting_agent' : houseWhite,
      'agent_contacted'  : houseWhite,
      'agent_connected'  : houseBlue,
      'lead_connected'   : houseWhite,
      'agent_ready'      : houseWhite,
      'agent_unavailable': houseWhite,
      'solo_agent'       : houseYellow,
      'solo_lead'        : houseWhite,
      'conference'       : houseBlue,
      'complete'         : houseGrey
    }
    let iconType = iconMap[this.props.callStatus] || houseBlack;

    return <span>Lead</span>; 
  }

  modeType() {
    return 'solo_lead';
  }
}
