const groupedResults = (geos) => {
  const results = {};

  geos.forEach(geo => {
    const { city, state, country } = geo;
    const key = `${country}.${state}.${city}`;

    if (key in results) {
      results[key].push(geo);
    } else {
      results[key] = [geo];
    }
  });

  return results;
};

const updateListItem = (item, action) => {
  const icon = item.querySelector('.fa');
  const iconKlass = /add/.test(action) ? 'fa fa-check mr-5' : 'fa fa-plus mr-5';

  if (icon) icon.classList = iconKlass;

  item.classList[action]('selected');
};

const toggleSelection = (event) => {
  const option = event.currentTarget;
  const options = option.closest('ul').querySelectorAll('.location-option');
  const action = option.classList.contains('selected') ? 'remove' : 'add';

  if (option.classList.contains('select-all')) {
    options.forEach(opt => updateListItem(opt, action));
  } else {
    updateListItem(option, action);
  }
};

const buildHeader = (geo) => {
  const { city, state, country } = geo;
  const header = `${city}, ${state} ${country}`;

  return <div className='col-md-12 h3'>{ header }</div>;
};

const buildListItems = (geos) => {
  const items = [];

  items.push(<li className='location-option select-all' onClick={ toggleSelection }>Select All</li>);

  geos.forEach(geo => {
    const { city, state, zip, country } = geo;

    items.push(<li className='location-option' data-city={ city } data-state={ state } data-zip={ zip } data-country={ country } onClick={ toggleSelection }><i className='fa fa-plus mr-5'></i>{ zip }</li>);
  });

  return items;
};

const buildList = (geos) => {
  return (
    <ul className='col-md-12'>
      { buildListItems(geos) }
    </ul>
  );
};

const buildRow = (geos) => {
  const inner = [];

  inner.push(buildHeader(geos[0]));
  inner.push(buildList(geos));

  return inner;
};

const buildRows = (geos) => {
  const rows = [];

  for (const i in geos) {
    rows.push(<div className='location-results row'>{ buildRow(geos[i]) }</div>);
  }

  return rows;
};

const Areas = ({ areas }) => {
  const gResults = groupedResults(areas);

  return (
    <div className='locations-wrapper'>
      { !!areas.length && buildRows(gResults) }
      { !areas.length && <p className='text-center'>No locations to display</p> }
    </div>
  );
};

export default Areas;
