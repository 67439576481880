import React, { useState, useEffect } from 'react';

import InputField from './../../../../_common/InputField.js';
import SelectField from './../../../../_common/SelectField.js';

const initLocation = {
  street_number: null,
  street_name: null,
  city: null,
  state: "AL",
  country: "US",
  zip: null,
};

const Address = props => {
  const {
    fieldPrefix = "buyer",
    country_options,
    us_state_options,
    ca_province_options,
    updateFieldHandler,
  } = props;

  const isSeller = /seller/.test(fieldPrefix);

  const [clients_location, updateClientsLocation] = useState(initLocation);
  const [stateOptions, updateStateOptions] = useState(us_state_options);
  const [fieldTitleState, updateFieldTitleState] = useState("State");
  const [fieldTitleZip, updateFieldTitleZip] = useState("Zip Code");

  useEffect(() => {
    const data = !!fieldPrefix ? { [fieldPrefix]: { clients_location } } : { clients_location };

    updateFieldHandler(data);
  }, [clients_location]);

  useEffect(() => {
    const { country } = clients_location;
    const options = /US/.test(country) ? us_state_options : ca_province_options;
    const stateTitle = /US/.test(country) ? "State" : "Province";
    const zipTitle = /US/.test(country) ? "Zip Code" : "Postal Code";

    updateFieldTitleState(stateTitle);
    updateFieldTitleZip(zipTitle);
    updateStateOptions(options);
    updateClientsLocation({ ...clients_location, ...{ state: options[0][1] } });
  }, [clients_location.country]);

  const handleUpdateClientsLocation = details => {
    const data = !!fieldPrefix ? details[fieldPrefix] : details;
    const location = { ...clients_location, ...data };

    updateClientsLocation(location);
  };

  const {
    state,
    country,
  } = clients_location;

  return (
    <React.Fragment>
      {
        isSeller &&
        <div className="input-row">
          <InputField fieldType="text" fieldTitle="Street Number" fieldPrefix={ fieldPrefix } fieldName="street_number" required={ true } updateFieldHandler={ handleUpdateClientsLocation } />
          <InputField fieldType="text" fieldTitle="Street Name" fieldPrefix={ fieldPrefix } fieldName="street_name" required={ true } updateFieldHandler={ handleUpdateClientsLocation } />
        </div>
      }

      <div className="input-row">
        <InputField fieldType="text" fieldTitle="City" fieldPrefix={ fieldPrefix } fieldName="city" required={ true } updateFieldHandler={ handleUpdateClientsLocation } />
        <SelectField fieldTitle={ fieldTitleState } fieldPrefix={ fieldPrefix } fieldName="state" options={ stateOptions } required={ true } updateFieldHandler={ handleUpdateClientsLocation } fieldValue={ state } />
        {
          isSeller &&
          <InputField fieldType="text" fieldTitle={ fieldTitleZip } fieldPrefix={ fieldPrefix } fieldName="zip" required={ true } updateFieldHandler={ handleUpdateClientsLocation } />
        }
        <SelectField fieldTitle="Country" fieldPrefix={ fieldPrefix } fieldName="country" options={ country_options } required={ true } updateFieldHandler={ handleUpdateClientsLocation } fieldValue={ country } />
      </div>
    </React.Fragment>
  );
};

export default Address;
