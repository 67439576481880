import React from 'react';
import Alert from '../LiveAccountManagement/images/alert.png';

const ProcessRefundSelection = (props) => {
  const {
    errorMessage,
    loading,
    goTo,
    netInitialFee,
    submitRefund,
  } = props;

  let subheading       = `Choose either a partial refund (and the amount you are refunding) or a full refund which will also block/disable this agent from LIVE entirely. There is $${ netInitialFee / 100 } available to refund.`;
  let buttonOneText    = 'Partial Refund';
  let buttonOneOnClick = () => goTo('refundPartial');
  let buttonTwoText    = 'Full Refund';
  let buttonTwoOnClick = () => submitRefund(true);

  return (
    <div className='process-refund'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ Alert } />
      </div>
      <div className='rex-modal-wrapper'>
        <div className='rex-modal-heading'>
          PROCESS THIS REFUND
        </div>
        <div className='rex-modal-contents'>
          <div className='rex-modal-subheading'>
            { subheading }
          </div>
          <div className='rex-modal-error'>
            { errorMessage }
          </div>
          <div className='rex-modal-actions rex-modal-actions__multiple-actions'>
            <button
              className='rex-modal-action rex-modal-multiple-action'
              onClick={ buttonOneOnClick }
              disabled={ loading }
            >
              { buttonOneText }
            </button>
            <button
              className={ `rex-modal-action rex-modal-multiple-action ${ loading ? 'rex-modal-action__loading' : '' }` }
              onClick={ buttonTwoOnClick }
              disabled={ loading }
            >
              { loading ? 'Loading' : buttonTwoText }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessRefundSelection;
