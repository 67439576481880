import React from 'react';
import { classList} from '../utils';

const Checkbox = (props) => {
  const {
    classNames,
    display,
    id,
    focused,
    name,
    valid,
    value,
    onChange,
  } = props;

  const classes = classList({
    classNames,
    focused,
    valid,
  });

  return(
    <div className={ classes }>
      <label>
        <input type='checkbox' name={ name } checked={ value } onChange={ onChange } id={ id }/>
        { display }
      </label>
    </div>
  );
};

export default Checkbox;
