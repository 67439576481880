import React from 'react';

import Finished from './FinishedContainer';

import Alert from './images/alert.png';
import LiveManagement from './images/live_management.png';
import Success from './images/success.png';
import Caret from './images/caret.png';

const ChangePackageView = (props) => {
  const {
    chosen,
    confirm,
    display,
    finished,
    handlePackageChange,
    loading,
    rexProductOfferings,
    submit,
    updateManagement,
    userRexProductOffering
  } = props;

  const availableOfferings = rexProductOfferings.filter(offering => userRexProductOffering.product_offering_id !== offering.id);

  const offeringMap = {
    1: 'Legacy',
    2: 'Unsubscribed',
    3: 'Legacy',
    4: 'Unsubscribed',
    5: 'Legacy',
    6: 'Unsubscribed',
    7: 'Standard',
    9: 'Enterprise',
    11: 'NAR'
  };

  const offeringDisplay = (offering) => {
    if (offering && offeringMap[offering.id]) {
      const monthly = offering.recurring_price / 100;
      const perLead = offering.coin_price / 100;
      return `${offeringMap[offering.id]} ($${monthly}/month, $${perLead}/lead)`
    }
  };

  const currentDisplay = (id) => {
    const offering = rexProductOfferings.filter(o => id === o.id);
    if (offering) {
      return offeringDisplay(offering[0]);
    }
  };

  const iconMap = {
    choices: LiveManagement,
    confirmation: Alert,
    success: Success
  };

  const headingMap = {
    choices: {
      heading: 'LIVE Account Management: Change Package'
    },
    confirmation: {
      heading: 'Are You Sure?',
      subheading: "Clicking CONFIRM will immediately charge the agent the monthly subscription price, change the agent’s cost per lead, and change the billing date to today’s day going forward."
    },
    success: {
      heading: 'Package Successfully Updated'
    }
  };

  let active = null;

  if (userRexProductOffering) {
    active = rexProductOfferings.filter(o => userRexProductOffering.product_offering_id === o.id);
  }

  const icon = iconMap[display];

  return (
    <div className='change-package'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ icon } />
      </div>
      <div className='rex-modal-heading'>
        { headingMap[display].heading }
      </div>
      { headingMap[display].subheading &&
        <div className='rex-modal-subheading'>
          { headingMap[display].subheading }
        </div>
      }
      { display === 'choices' &&
        <div>
          <div className='rex-modal-inputs-group'>
            <div className='rex-modal-input-group'>
              <div className='rex-modal-input-group-label'>
                Current Package
              </div>
              <div className='rex-modal-input-group-input'>
                { userRexProductOffering &&
                  offeringDisplay(active[0])
                }
              </div>
            </div>
            <div className='rex-modal-input-group'>
              <div className='rex-modal-input-group-label'>
                Change Package
              </div>
              <div className='rex-modal-input-group__as-select'>
                <div className='rex-modal-input-group-input is-selected'>
                  { chosen &&
                    currentDisplay(chosen.id)
                  }
                </div>
                <div className='select-options'>
                  {
                    availableOfferings.map(offering => {
                      return (
                        <div
                          key={ offering.id }
                          className='rex-modal-input-group-input select-option'
                          onClick={ () => handlePackageChange(offering) }
                        >
                          { offeringDisplay(offering) }
                        </div>
                      );
                    })
                  }
                </div>
                <div className='select-caret-container'>
                  <img className='select-caret' src={ Caret } />
                </div>
              </div>
            </div>
          </div>
          <div className='rex-modal-actions'>
            <button
              className='rex-modal-action'
              onClick={ submit }
              disabled={ !chosen }
            >
              Confirm
            </button>
            <button
              className='rex-modal-action'
              onClick={ () => updateManagement({ displaying: null }) }
              disabled={ false }
            >
              Cancel
            </button>
          </div>
        </div>
      }
      { display === 'confirmation' &&
        <div className='rex-modal-actions'>
          <button
            className={`rex-modal-action ${loading ? 'rex-modal-action__loading' : ''}`}
            onClick={ confirm }
            disabled={ loading }
          >
            { loading ? 'Loading' : 'Confirm' }
          </button>
          <button
            className='rex-modal-action'
            onClick={ () => updateManagement({ displaying: null }) }
            disabled={ loading }
          >
            Cancel
          </button>
        </div>
      }
      { display === 'success' &&
        <Finished
          message={ false }
          close={ finished }
        />
      }
    </div>
  );
};

export default ChangePackageView;
