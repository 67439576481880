import React, { Component }    from 'react';

import CancelReactivateReason  from './CancelReactivateReasonView';
import CancelReactivateConfirm from './CancelReactivateConfirmView';
import CancelReactivateSuccess from './CancelReactivateSuccessView';
import CancelReactivateFail    from './CancelReactivateFailView';

export default class CancelReactivateContainer extends Component {
  constructor(props) {
    super(props);
    this.finished       = this.finished.bind(this);
    this.toggle         = this.toggle.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.goTo           = this.goTo.bind(this);
    this.selectRadio    = this.selectRadio.bind(this);
    this.fillOther      = this.fillOther.bind(this);

    const display = props.canceledLive ? 'confirm' : 'reason';

    this.state = {
      display:       display,
      loading:       false,
      reset:         true,
      errorMessage:  '',
      selectedText:  '',
      otherText:     '',
    };
  }

  success(canceledLive) {
    const {
      updateManagement,
    } = this.props;

    let agentStatus = document.querySelector('.agent-live-status');
    agentStatus.innerText = canceledLive ? 'canceled' : 'active';

    this.goTo('success');
    updateManagement({ loading: false });
  }

  fail(errorMessage) {
    this.setState({ errorMessage: `Error: ${ errorMessage }` });
    this.goTo('fail');
  }

  finished() {
    const {
      canceledLive,
      updateManagement,
    } = this.props;

    updateManagement({
      canceledLive: !canceledLive,
      displaying:   null,
      loading:      false,
      modalOpen:    false,
    });
  }

  toggle() {
    const {
      agentId,
      canceledLive,
      updateManagement,
    } = this.props;

    const {
      otherText,
      selectedText,
    } = this.state;

    const reason = `${selectedText} - ${otherText}`;
    const url = canceledLive ? `/agents/${agentId}/reactivate_live?reset=${ this.state.reset }` : `/agents/${ agentId }/cancel_live?reason=${ reason }`;

    updateManagement({ loading: true });
    this.setState({ loading: true });

    fetch(url, { credentials: 'same-origin', method: 'POST' })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          this.fail(res.error);
        } else {
          this.success(!canceledLive);
        }
      })
      .catch(err => {
        alert(err);
      });
  }

  handleCheckbox(e) {
    this.setState({ reset: e.target.checked });
  }

  goTo(page) {
    this.setState({
      display: page,
      loading: false,
    });
  }

  selectRadio(e) {
    this.setState({ selectedText: e.target.value });
  }

  fillOther(e) {
    this.setState({ otherText: e.target.value });
  }

  render() {
    const {
      canceledLive,
      toggleModal,
      balanceOwed,
    } = this.props;

    const {
      display,
      loading,
      errorMessage,
      reset,
      selectedText,
      otherText,
    } = this.state;

    return (
      (
        () => {
          switch(display) {
            case 'success':
              return <CancelReactivateSuccess
                canceledLive={ canceledLive }
                reset={ reset }
                finished={ this.finished }
              />;
            case 'fail':
              return <CancelReactivateFail
                errorMessage={ errorMessage }
                toggleModal={ toggleModal }
              />;
            case 'confirm':
              return <CancelReactivateConfirm
                canceledLive={ canceledLive }
                toggleModal={ toggleModal }
                reset={ reset }
                loading={ loading }
                balanceOwed={ balanceOwed }
                handleCheckbox={ this.handleCheckbox }
                toggle={ this.toggle }
              />;
            default:
              return <CancelReactivateReason
                otherText={ otherText }
                selectedText={ selectedText }
                toggleModal={ toggleModal }
                goTo={ this.goTo }
                selectRadio={ this.selectRadio }
                fillOther={ this.fillOther }
              />;
          }
        }
      )()
    )
  }
}
