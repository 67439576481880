import { Component } from 'react';

export default class SalesExecutivesModal extends Component {
  constructor(props){
    super(props)

    this.state = this.initialState();
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showLoading = this.showLoading.bind(this);
    this.resetState = this.resetState.bind(this);
    this.removeSalesExecutive = this.removeSalesExecutive.bind(this);
  }

  initialState(){
    return{
      selectedIdx: '',
      submitting: false,
      removalCompleted: false
    }
  }

  showModal(){
    return this.props.state.showExecutiveModal
      ? 'modal-overlay'
      : 'hidden';
  }

  showLoading() {
    return this.state.submitting
      ? 'modal-overlay-submitting'
      : 'hidden';
  }

  closeModal(){
    this.props.updateState({ showExecutiveModal: false });
    this.resetState();
  }

  removeSalesExecutive(selectedIdx){
    this.setState({ submitting: true});
    this.props.removeSalesExecutive(selectedIdx)
      .then(() => this.setState({ removalCompleted: true, submitting: false }));
  }

  resetState(){
    this.setState({ selectedIdx: '', removalCompleted: false, submitting: false })
  }

  modalContent(){
    let { salesExecutives, removedSalesExecutive } = this.props.state;
    let { selectedIdx, removalCompleted } = this.state;
    if(typeof selectedIdx !== 'number' && !removalCompleted){
      return(
        <div className='content'>
          <div className='list'>
            <div className='list-inner'>
              {salesExecutives.map((exec, idx) => {
                let name = `${exec.first_name} ${exec.last_name}`;
                let phone = Util.formatPhoneNumber(exec.phone);
                return (
                  <div className='list-item-centered' key={idx}>
                    <div className='list-item-centered-detail w140 name'>{name}</div>
                    <div className='list-item-centered-detail w140 agents'>Agents: {exec.agent_count}</div>
                    <div className='list-item-centered-detail w200 phone'>Phone: {phone}</div>
                    <div className='list-item-centered-detail w200 email'>Email: {exec.email}</div>
                    <div className='list-item-centered'>
                      <div className='close-button-2' onClick={() => this.setState({ selectedIdx: idx })}>
                        <i className='fa fa-times-circle' />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )
    } else if (typeof selectedIdx === 'number' && !removalCompleted) {
      let exec = salesExecutives[selectedIdx] || removedSalesExecutive;
      let name = `${exec.first_name} ${exec.last_name}`;
      let phone = Util.formatPhoneNumber(exec.phone);
      return (
        <div className='content'>
          <div className='list'>
            <div className='list-inner'>
              <div className='list-item-centered'>
                <div className='list-item-centered-detail w140 name'>{name}</div>
                <div className='list-item-centered-detail w140 agents'>Agents: {exec.agent_count}</div>
                <div className='list-item-centered-detail w200 phone'>Phone: {phone}</div>
                <div className='list-item-centered-detail w200 email'>Email: {exec.email}</div>
              </div>
            </div>
          </div>
          <div className='prompt'>
            Are you sure you want to remove this sales executive?
          </div>
          <div className='prompt-options'>
            <div className='prompt-cancel' onClick={() => this.setState({ selectedIdx: '' })}>Cancel</div>
            <div className='prompt-confirm' onClick={() => this.removeSalesExecutive(selectedIdx)}>Remove</div>
          </div>
        </div>
      )
    } else if (removalCompleted) {
      let exec = removedSalesExecutive;
      let name = `${exec.first_name} ${exec.last_name}`;
      let phone = Util.formatPhoneNumber(exec.phone);
      return (
        <div className='content'>
          <div className='list'>
            <div className='list-inner'>
              <div className='list-item-centered'>
                <div className='list-item w130 name'>{name}</div>
                <div className='list-item w130 agents'>Agents: {exec.agent_count}</div>
                <div className='list-item w200 phone'>Phone: {phone}</div>
                <div className='list-item w200 email'>Email: {exec.email}</div>
              </div>
            </div>
          </div>
          <div className='prompt'>
            <h2>Sales executive removed!</h2>
            <i className='fa fa-check-circle'></i>
          </div>
          <div className='prompt-options'>
            <div className='prompt-confirm' onClick={this.resetState}>Done</div>
          </div>
        </div>
      )
    }
  }

  render(){
    return(
      <div className={this.showModal()} onClick={this.closeModal}>
        <div className='title-modal' onClick={(e) => e.stopPropagation() }>
          <div className='header'>
            <h3>Edit Sales Executives</h3>
            <div className='close-button' onClick={this.closeModal}>
              <i className='fa fa-times-circle' />
            </div>
          </div>
          { this.modalContent() }
          <div className={this.showLoading()}>
            <i className='fa fa-refresh fa-spin fa-5x fa-fw'></i>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>

      </div>
    );
  }
}
