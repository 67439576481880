import React, { Component } from 'react';
import History from './StripeTransactionHistoryView';
import Fetch from '../../common/fetch';
export default class StripeTransactionHistoryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { transactions: props.transactions };

    this.reloadTransactions = this.reloadTransactions.bind(this);
  }

  async reloadTransactions() {
    const { reloadTransactionsPath } = this.props;
    const { transactions } = await Fetch.get({ url: reloadTransactionsPath });

    if (transactions) {
      this.setState({ transactions });
    }
  }

  render() {
    const {
      transactions,
    } = this.state;

    const {
      has_permission,
      hasRefundPermission,
      refundUrl,
      retry_payments_path,
    } = this.props;

    return (
      <History
        has_permission={has_permission}
        hasRefundPermission={hasRefundPermission}
        refundUrl={refundUrl}
        retry_payments_path={retry_payments_path}
        reloadTransactions={this.reloadTransactions}
        transactions={transactions}
      />
    )
  }
}
