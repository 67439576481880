import React from 'react';

import DateInput from '../Info/inputs/DateInput';
import TimeSelect from '../Info/inputs/Select/time';
import TextConsent from '../Info/inputs/Checkbox/textConsent';

import './styles.scss';

const Contact = (props) => {
  const {
    live,
  } = props;

  const {
    timezone,
  } = live;

  return (
    <div className='active-dialer-lead-section contact-time'>
      <div className='section-row full-width'>
        <div className='section-key'>Preferred Time to Contact Client</div>
      </div>

      <div className='info-row'>
        <DateInput
          display='Date'
          property='contact_date'
          parentClassNames='date-input-container'
        />

        <TimeSelect
          display='Local Time'
          property='contact_time'
        />

      <div className='info-field'>
          <label>Time Zone</label>
          <h2>{ timezone }</h2>
        </div>

      </div>

      <div className='info-row full-width'>
        <div className='checkbox-groups full-width text-consent'>
          <div className='checkbox-group'>
            <TextConsent
              display='Obtained consent to send text message(s)'
              id='text-consent-checkbox'
              property='text_consent'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact;
