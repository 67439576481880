import { all, call, put, takeLatest } from 'redux-saga/effects';
import request from 'superagent';

import ActionsSagas from './Actions/sagas';
import LeadSagas from './lead/sagas';
import MetadataSagas from './Metadata/sagas';
import NewLeadSagas from './NewLead/sagas';
import QualSagas from './Qual/sagas';
import ValidationSagas from './validations/sagas';

export default function* rootSaga() {
  yield all([
    ...ActionsSagas,
    ...LeadSagas,
    ...MetadataSagas,
    ...NewLeadSagas,
    ...QualSagas,
    ...ValidationSagas,
  ]);
}
