import React from 'react';
import { connect } from 'react-redux';

import View from './view';

import BaseInputContainer from '../Info/inputs/index';

function mapStateToProps(state) {
  return {
    submitting: state.actions.get('submitting'),
  };
}

class Comments extends BaseInputContainer {
  constructor(props) {
    super(props);

    this.property = 'comments';
  }

  render() {
    const { submitting } = this.props;
    const { value } = this.state;

    const data = { submitting, value };

    return(
      <View
        data={ data }

        handleChange={ this.handleChange }
      />
    );
  }
}

export default connect(mapStateToProps)(Comments);
