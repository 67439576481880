import React from 'react';

import Caret from './images/caret.png';

const ChooseExclusiveAgent = ({ state, setState, statesAndprovinces }) => {
  let {
    exclusiveAgentSearch,
    exclusiveAgentSearchState,
    exclusiveAgentSearchResults,
    currentAgent,
    forceMatchExclusively,
  } = state;

  function handleChange(e) {
    const { field } = e.target.dataset;
    const { value } = e.target;

    setState({ [field]: value })
  }

  function handleSearchClick() {
    if (exclusiveAgentSearch === '') return;
    setState({
      exclusiveAgentSearchState: 'searching',
      exclusiveAgentSearchResults: []
    });
    searchAgent();
  }

  function searchAgent(){
    let params;
    let baseOptions;

    let searchType = checkType(exclusiveAgentSearch);

    if (searchType === 'phone') {
      exclusiveAgentSearch = exclusiveAgentSearch.replace(/[^0-9]/g, '')
    }

    if (searchType === 'name') {
      let [first_name, last_name] = exclusiveAgentSearch.split(' ');
      baseOptions = { first_name, last_name };
    } else {
      baseOptions = { [searchType]: exclusiveAgentSearch };
    }

    params = {
      search_params: {
        search_type: 'advanced_search',
        advanced_search_options: {
          ...baseOptions,
        }
      }
    }

    Util.searchAgents(params)
      .then((results) => {
        if (searchType === 'name' && !params.search_params.advanced_search_options.last_name) {
          params.search_params.advanced_search_options = { last_name: params.search_params.advanced_search_options.first_name }
          Util.searchAgents(params)
            .then((lastNameResults) => {
              setState({
                exclusiveAgentSearchResults: results.concat(lastNameResults),
                exclusiveAgentSearchState: 'displaying',
              })
            })
        } else {
          setState({
            exclusiveAgentSearchResults: results,
            exclusiveAgentSearchState: 'displaying',
          })
        }
      });
  }

  function handleSelectAgent(agent) {
    const { id, name, email, phone_office, phone_mobile, company_city, company_state } = agent;

    const newAgent = {
      name,
      id,
      email,
      phone_office,
      phone_mobile,
      city: company_city,
      state: statesAndprovinces[company_state],
    }
    setState({
      currentAgent: newAgent,
      forceAgentIds: [newAgent.id],
      city: newAgent.city,
      state: newAgent.state,
      forceMatchExclusively: true,
    })
  }

  function exclusiveAgent() {
    if (!!currentAgent) {
      return(
        <div className='row'>
          <div className='search-results-table'>
            <div className='search-header'>
              <div className='search-column short'></div>
              <div className='search-column'>Name</div>
              <div className='search-column short'>Agent Id</div>
              <div className='search-column'>Email</div>
              <div className='search-column short'>Phone</div>
              <div className='search-column short'>City</div>
              <div className='search-column short'>State</div>
            </div>
            <div className='search-content row-flex-direction'>
              <div className='search-row short'>
                <input
                  type="checkbox"
                  checked={!!currentAgent}
                  className='choose-exclusive-agent-checkbox'
                />
              </div>
              <div className='search-row'>{currentAgent.name}</div>
              <div className='search-row short'>{currentAgent.id}</div>
              <div className='search-row'>{currentAgent.email}</div>
              <div className='search-row short'>{currentAgent.phone_office || currentAgent.phone_mobile}</div>
              <div className='search-row short'>{currentAgent.city}</div>
              <div className='search-row short'>{currentAgent.state}</div>
            </div>
          </div>
        </div>
      )
    }
  }

  function displaySearchResults() {
    switch (exclusiveAgentSearchState) {
      case 'searching':
        return (
          <div className='row'>
            <div className='choose-exclusive-agent'>
              <h2 className='page-title'>Exclusive Agent Search Results Loading...</h2>
            </div>
            <div className='search-loading'>
              <i className="fa fa-cog fa-spin fa-3x fa-fw"></i>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        );
      case 'displaying':
        return (
          <div className='row'>
            <div className='choose-exclusive-agent'>
              <h2 className='page-title'>Exclusive Agent Search Results</h2>
            </div>
            <div className='search-results-table'>
              <div className='search-header'>
                <div className='search-column short'></div>
                <div className='search-column'>Name</div>
                <div className='search-column short'>Agent Id</div>
                <div className='search-column'>Email</div>
                <div className='search-column short'>Phone</div>
                <div className='search-column short'>City</div>
                <div className='search-column short'>State</div>
              </div>
              <div className='search-content'>
                {
                  exclusiveAgentSearchResults.map((agent, idx) => (
                    <div
                      key={idx}
                      className='search-rows'
                      onClick={() => handleSelectAgent(agent)}
                    >
                      <div className='search-row short'>
                        <input
                          type="checkbox"
                          checked={!!currentAgent && currentAgent.id === agent.id}
                          className='choose-exclusive-agent-checkbox'
                        />
                      </div>
                      <div className='search-row'>{agent.name}</div>
                      <div className='search-row short'>{agent.id}</div>
                      <div className='search-row'>{agent.email}</div>
                      <div className='search-row short'>{agent.phone_office || agent.phone_mobile}</div>
                      <div className='search-row short'>{agent.company_city}</div>
                      <div className='search-row short'>{agent.company_state}</div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <div className='choose-exclusive-agent'>
      {exclusiveAgent()}
      <br></br>
      <br></br>
      <div className='row'>
        <div className='col-sm-6'>
          <h2 className='page-title'>Search for an Exclusive Agent</h2>
          <br />
          <div className='agent-search-box'>
            <input
              data-field="exclusiveAgentSearch"
              type='text'
              value={exclusiveAgentSearch || ''}
              onChange={handleChange}
            />
            <div
              className='agent-search-button'
              onClick={handleSearchClick}
            >
              <i className="fa fa-search" />
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      {displaySearchResults()}
    </div>
  );
}

export default ChooseExclusiveAgent;
