import { Component } from 'react';
import Email from './Email';

export default class Emails extends Component {
  constructor(props){
    super(props);
    this.state = {
      emails: null
    }
  }

  componentDidMount(){
    if(this.props.agentId) this.listenEmailChange();
  }

  componentWillReceiveProps(nextProps){
    if(this.props.agentId === nextProps.agentId) return;
    this.unmountListenEmailChange();
    this.listenEmailChange(nextProps.agentId);
  }

  componentWillUnmount(){
    this.unmountListenEmailChange()
  }

  listenEmailChange(agentId){
    $(document).on('obtainEmails', '#agent-emails-' + agentId, () => {
      this.obtainEmails();
    });
  }

  unmountListenEmailChange(){
    $(document).off('obtainEmails', '#agent-emails-' + this.props.agentId, () => {
      this.obtainEmails();
    });
  }

  obtainEmails(){
    let params = { agent_id: this.props.agentId };
    $.get('/customer_success/emails', params, (result) => {
      this.setState({ emails: result.emails })
    })
  }

  newEmailLink(){
    return '/emails/new?object_id=' + this.props.agentId + '&object_type=Agent&skin=agent-fee-skin'
  }

  emails(){
    let emails = this.state.emails || this.props.emails;
    if(emails){
      return(
        emails.map((email, idx) => {
          return (
            <Email
              key={idx}
              email={email}
              id={'agent-emails-' + this.props.agentId}
            />
          )
        })
      )
    }
  }
  render(){
    if(this.props.agentId){
      return (
        <div id={'agent-emails-' + this.props.agentId} className='react-emails-panel'>
          <div className="react-emails-panel-inner">
            <div className='react-header'>
              <h3>Emails</h3>
              <div className='react-create-button'>
                <a href={this.newEmailLink()} data-remote='true'>Create +</a>
              </div>
            </div>
            <div className="react-email-rows">
              { this.emails() }
            </div>
          </div>
        </div>
      )
    } else {
        return <div />
    }
  }
}
