import { Component } from 'react';

import LeadWall from './LeadWall';
import AgentWall from './AgentWall';
import ComeHome from './ComeHomeAnalytics';

export default class RightContainer extends Component {
  renderMatchWalls() {
    return this.props.matches.map(function(match, idx) {
      return <AgentWall key={idx} wallObjects={match.wall} agent={match.agent}/>
    })
  }

  render() {
    const {
      comeHomeAnalyticPath,
      comeHomeMailerPath,
      lead,
      leadWall,
    } = this.props;

    return (
      <div className='profile-review-right-container col-lg-3'>
        <ComeHome
          comeHomeAnalyticPath={ comeHomeAnalyticPath }
          comeHomeMailerPath={ comeHomeMailerPath }
          lead={ lead }
        />

        <div className='header display-flex-vertical-center'>
          <div className='title-container'>TIMELINE</div>
        </div>
        <div className='outer-wall-container'>
          <LeadWall leadWall={ leadWall } lead={ lead }/>
          {this.renderMatchWalls()}
        </div>
      </div>
    );
  }
}
