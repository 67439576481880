import { Map } from 'immutable';

const initialState = Map({
  note: ''
});

const actionsMap = {
  ['SET_INITIAL_NOTE']: (state, action) => {
    const { note } = action.data;

    return state.merge(Map({ note: note.message }));
  },
  ['SET_NOTE']: (state, action) => {
    const { note } = action.data;

    return state.merge(Map({ note: note }));
  },
  ['RESET_NOTE']: (state) => {
    return state.merge(initialState);
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
