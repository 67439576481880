import React from 'react';

import StandardInput from './inputs/StandardInput';
import RadioGroup from './inputs/RadioGroup';
import CountryRadioGroup from './inputs/RadioGroup/country';
import Checkbox from './inputs/Checkbox';
import PhoneNumber from './inputs/PhoneNumber';

import BuyerFields from './Buyer';
import SellerFields from './Seller/view';

import './styles.scss';

const disableAllInputs = (isSubmitting) => {  
  const parent = document.querySelector('#info-section');

  if (parent) {
    const inputElements = parent.querySelectorAll('input, select, checkbox, textarea');
    const inputElementsLength = inputElements.length;

    for (let j = 0; j < inputElementsLength; j += 1) {
      const elem = inputElements[j];
      elem.disabled = isSubmitting;
    }
  }
};

const Info = (props) => {
  const { info, submitting } = props.data;
  const { lead_type } = info;

  if (!info.id) {
    return null;
  }

  disableAllInputs(submitting);

  const leadTypeOptions = [
    {
      name: 'Buyer',
      val: 'buyer'
    },
    {
      name: 'Seller',
      val: 'seller'
    }
  ];

  return (
    <div className='active-dialer-lead-section info' id='info-section'>
      <div className='info-row'>
        <RadioGroup
          options={ leadTypeOptions }
          property='lead_type'
        />
      </div>

      <div className='info-row'>
        <StandardInput
          property='first_name'
          display='First Name'
        />

        <StandardInput
          property='last_name'
          display='Last Name'
        />
      </div>

      <div className='info-row'>
        <PhoneNumber
          display='Phone Number'
          property='phone_number'
          parentClassNames='phone-number-container'
        />

        <StandardInput
          property='phone_number_ext'
          display='Ext.'
          classNames='phone-number-ext'
        />

        <StandardInput
          property='email_address'
          display='Email Address'
        />
      </div>

      <div className='info-row right-align'>
        <div className='checkbox-groups'>
          <div className='checkbox-group'>
            <Checkbox
              display='Opt Out of Receiving Emails'
              property='opt_out'
            />
          </div>
        </div>
      </div>

      <div className='info-row'>
        <CountryRadioGroup />
      </div>

      { lead_type === 'seller' &&
        <SellerFields />
      }

      { lead_type !== 'seller' &&
        <BuyerFields />
      }
    </div>
  );
};

export default Info;
