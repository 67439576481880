import React, { Component } from 'react';
import { connect } from 'react-redux';

import View from './view';

import liveScripts from './live';

function objValuesToUpperCase(obj) {
	let converted = {};
	Object.keys(obj).forEach(key => {
    if (obj.hasOwnProperty(key)) {
      let value = obj[key];
      if (typeof value === 'string') {
        converted[key] = value ? value.toUpperCase() : '';
      } else {
        converted[key] = value;
      }
    }
	});
  return converted;
}

function mapStateToProps(state) {
  return {
    info: {
      buyer_city_0: state.info.get('buyer_city_0'),
      buyer_state_0: state.info.get('buyer_state_0'),
      city: state.info.get('city'),
      first_name: state.info.get('first_name'),
      id: state.info.get('id'),
      last_name: state.info.get('last_name'),
      state: state.info.get('state'),
      street_name: state.info.get('street_name'),
      street_number: state.info.get('street_number'),
    },
    lead: {
      crm_link: state.lead.get('crm_link'),
      rex_referral: state.lead.get('rex_referral'),
    },
    live: {
      live_disposition: state.live.get('live_disposition'),
      live_lead: state.live.get('live_lead'),
      submitting_agent_name: state.live.get('submitting_agent_name'),
    },
  };
}

class Scripts extends Component {
  render() {
    const { info, lead, live } = this.props;
    const { lead_type } = info;
    const {
      live_disposition,
      live_lead,
    } = live;

    if (!info.id) {
      return null;
    }

    let scripts = null;

    if (live_lead) {
      const scriptData = {
        info: objValuesToUpperCase(info),
        live: objValuesToUpperCase(live)
      };

      scripts = liveScripts[live_disposition].hasOwnProperty(lead_type) ? liveScripts[live_disposition][lead_type](scriptData) : liveScripts['default_live'](scriptData);
    }

    return (
      <View
        scripts={ scripts }
        lead={ lead }
        live={ live }
      />
    );
  }
}

export default connect(mapStateToProps)(Scripts);
