import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import EditAvatarModal from './EditAvatarModal';
import drag from '../Modal/draggable';
import loadFileAsCanvas from './ImageUtil';

const EditableAvatar = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [photoError, setPhotoError] = useState(false);
  const [newPhotoUrl, setNewPhotoUrl] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(props.photoUrl)
  const minPixels = 480;

  useEffect(() => {
    const editAvatarModal = document.getElementById('edit-avatar-modal');
    if (editAvatarModal) { drag(editAvatarModal); }
  }, []);

  function toggleModal() {
    setShowModal(!showModal);
  }

  function displayModal() {
    setShowModal(true);
  }

  function loadFile(e) {
    loadFileAsCanvas(
      e,
      (res) => {
        setNewPhotoUrl(res);
        displayModal();
      },
      minPixels,
    );
  }

  function backupPhoto() {
    return (
      <div className="avatar-picture-outer">
        <label htmlFor="backup-avatar" className="backup-avatar">
          <input
            id="backup-avatar"
            type="file"
            onChange={loadFile}
          />
          <i className="backup-camera" />
          ADD PHOTO
        </label>
      </div>
    );
  }

  function avatarPhoto() {
    return (
      <div className="avatar-picture-outer">
        {
          typeof(photoUrl) === 'string'
            ? (
              <>
                <img
                  alt="avatar"
                  className="avatar-picture"
                  src={photoUrl}
                  onError={setPhotoError.bind(null, true)}
                />
                <label htmlFor="avatar-picture-hidden-button" className="avatar-picture-hidden-button">
                  <input
                    id="avatar-picture-hidden-button"
                    type="file"
                    onChange={loadFile}
                  />
                  <i className="backup-camera" />
                  CHANGE PHOTO
                </label>
              </>
            )
            : <i className="avatar-picture av-loader" />
        }
      </div>
    );
  }

  return (
    <div className="avatar-picture-container">
      {photoError ? backupPhoto() : avatarPhoto() }
      <EditAvatarModal
        agentId={props.agentId}
        showModal={showModal}
        setShowModal={setShowModal}
        setPhotoUrl={setPhotoUrl}
        setPhotoError={setPhotoError}
        toggleModal={toggleModal}
        minPixels={minPixels}
        src={newPhotoUrl}
        loadFile={loadFile}
      />
    </div>
  );
};

export function initialize(containerElement, options) {
  let component = React.createElement(EditableAvatar, options);
  ReactDOM.render(component, containerElement);
}
