import { Component } from 'react';

import ModalWrapper from './ModalWrapper';

// currentProductOfferingId not included in initialState because
// we don't want it reverted through toggle.
const initialState = {
  modalOpen: false,
  previousview: '',
  currentview: 'management',
  selectedText: '',
  otherText: '',
  action: '',
  error: '',
  newProductOfferingId: '',
};

const hierarchy = {
  cancel: {
    management: '',
    warning: 'management',
    reason: 'warning',
    success: '',
    fail: 'reason',
  },
  disable: {
    management: '',
    warning: 'management',
    success: '',
    fail: 'warning',
  },
  enable: {
    warning: '',
    success: '',
    fail: 'warning',
  },
  reactivate: {
    management: '',
    warning: 'management',
    success: '',
    fail: 'warning',
  },
  change: {
    management: '',
    change: 'management',
    warning: 'change',
    success: '',
  }
};

export default class Container extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.toggleModal = this.toggleModal.bind(this);
    this.moveTo = this.moveTo.bind(this);
    this.setAttribute = this.setAttribute.bind(this);
    this.setAndGo = this.setAndGo.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.fillOther = this.fillOther.bind(this);
    this.toggleButtons = this.toggleButtons.bind(this);
  }

  componentWillMount() {
    const {
      country,
      current_product_offering_id,
      feature_enabled,
      plan_cancelled,
      prime_eligible,
    } = this.props;

    this.setAttribute('planCancelled', plan_cancelled);
    this.setAttribute('featureEnabled', feature_enabled);
    this.setAttribute('primeEligible', prime_eligible);
    this.setAttribute('currentProductOfferingId', current_product_offering_id);
    this.setAttribute('country', country);
  }

  setAttribute(attr, value) {
    this.setState({ [attr]: value });
  }

  toggleModal(bool) {
    this.setState({ modalOpen: bool });

    // Reset modal to begining state if closed
    if (!bool) this.setState(initialState);
  }

  moveTo(view) {
    const { action } = this.state;
    this.setState({ previousview: hierarchy[action][view], currentview: view });
  }

  setAndGo(attribute, value, view) {
    const { action } = this.state;
    const key = action || value;

    this.setState({ [attribute]: value, previousview: hierarchy[key][view], currentview: view });
  }

  setSelected(e) {
    this.setState({ selectedText: e.target.value });

    // Reset text area if option selected is not Other
    if (!(/^Other$/).test(e.target.value)) this.setState({ otherText: '' });
  }

  fillOther(e) {
    this.setState({ otherText: e.target.value });
  }

  toggleButtons(e, action) {
    const buttons = e.target.closest('.rex-modal-actions').getElementsByTagName('button');
    const method = (/disable/).test(action) ? 'setAttribute' : 'removeAttribute';

    buttons.forEach(button => {
      button[method]('disabled', '');
    });
  }

  render() {
    const {
      action,
      currentview,
      error,
      featureEnabled,
      modalOpen,
      otherText,
      planCancelled,
      previousview,
      primeEligible,
      selectedText,
      newProductOfferingId,
      currentProductOfferingId,
      country,
    } = this.state;

    const {
      eligibility_path,
      plan_end_date_past,
      prime_subscription_path,
      subscription_active,
    } = this.props;

    return (
      <div className='prime-management-container'>
        <button
          type="button"
          onClick={() => this.toggleModal(!modalOpen)}
          className='prime-management-button'
        >
          ACCOUNT MANAGEMENT
        </button>

        {
          modalOpen &&
          <ModalWrapper
            action={action}
            currentview={currentview}
            toggleButtons={this.toggleButtons}
            eligibilityPath={eligibility_path}
            error={error}
            featureEnabled={featureEnabled}
            fillOther={this.fillOther}
            moveTo={this.moveTo}
            otherText={otherText}
            pastEndDate={plan_end_date_past}
            planCancelled={planCancelled}
            previousview={previousview}
            primeEligible={primeEligible}
            primeSubscriptionPath={prime_subscription_path}
            currentProductOfferingId={currentProductOfferingId}
            country={country}
            selectedText={selectedText}
            setAndGo={this.setAndGo}
            setAttribute={this.setAttribute}
            setSelected={this.setSelected}
            subscriptionActive={subscription_active}
            toggleModal={this.toggleModal}
            newProductOfferingId={newProductOfferingId}
          />
        }
      </div>
    );
  }
}
