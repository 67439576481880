import React from 'react';

const CallDispositions = (props) => {
  const { submit, agentsRemaining } = props;

  return (
    <div className="dialer-call-dispositions">
      <div className="disposition-buttons">
        <div className="button-row">
          <button
            type="button"
            className="dialer-button blue"
            value="0"
            disabled={!agentsRemaining}
            onClick={submit}
          >
            FLAG
          </button>
          <button
            type="button"
            className="dialer-button blue filled"
            value="1"
            disabled={!agentsRemaining}
            onClick={submit}
          >
            Validate
          </button>
        </div>
      </div>
    </div>
  )
};

export default CallDispositions;
