import React from 'react';

import ToggleSubscription from './ToggleSubscriptionContainer';
import CancelReactivate from './CancelReactivateContainer';
import ChangePackage from './ChangePackageContainer';
import DisableLiveAccess from './DisableLiveAccessContainer';
import ManagementOptions from './OptionsView';
import RexInbox from './RexInboxContainer';

import BackArrow from './images/icon-arrow-left.png';
import Close from './images/close.png';
import LiveManagement from './images/live_management.png';

const ManagementView = (props) => {
  const {
    agentId,
    display,
    displaying,
    hasActiveLiveInbox,
    hasActiveSubscription,
    canceledLive,
    hasEnabledLiveFeature,
    hasLegacyProduct,
    loading,
    rexProductOfferings,
    toggleModal,
    updateManagement,
    unsuccessfulRequest,
    userRexProductOffering,
    balanceOwed
  } = props;

  return (
    <div className='rex-modal-overlay'>
      <div className='rex-modal-content live-account-management-modal'>
        <div className='rex-modal-close'>
          <button
            onClick={ () => toggleModal(false) }
            disabled={ loading }
          >
            <img src={ Close } />
          </button>
        </div>
        <div className='rex-modal-back'>
          { displaying !== null &&
            <button onClick={ () => display(null) } disabled={ loading }>
              <span className='back-arrow'>
                <img src={ BackArrow } />
              </span>
              <span>Back</span>
            </button>
          }
        </div>
        { !hasEnabledLiveFeature &&
          <div className='toggle-live-subscription'>
            <div className='rex-modal-icon-container'>
              <img className='rex-modal-icon' src={ LiveManagement } />
            </div>
            <div className='rex-modal-heading'>
              LIVE Account Management
            </div>
            <div className='rex-modal-contents'>
              <div className='rex-modal-subheading'>
                LIVE access is currently disabled.
              </div>
            </div>
          </div>
        }
        { hasEnabledLiveFeature && (() => {
            switch(displaying) {
              case 'toggleSubscription':
                return <ToggleSubscription
                  agentId={ agentId }
                  hasActiveSubscription={ hasActiveSubscription }
                  updateManagement={ updateManagement }
                  unsuccessfulRequest={ unsuccessfulRequest }
                />
              case 'cancelReactivate':
                return <CancelReactivate
                  agentId={ agentId }
                  updateManagement={ updateManagement }
                  canceledLive={ canceledLive }
                  unsuccessfulRequest={ unsuccessfulRequest }
                  toggleModal={ toggleModal }
                  balanceOwed={ balanceOwed }
                />
              case 'rexInbox':
                return <RexInbox
                  agentId={ agentId }
                  hasActiveLiveInbox={ hasActiveLiveInbox }
                  updateManagement={ updateManagement }
                  unsuccessfulRequest={ unsuccessfulRequest }
                />;
              case 'disableAccess':
                return <DisableLiveAccess
                  agentId={ agentId }
                  updateManagement={ updateManagement }
                  unsuccessfulRequest={ unsuccessfulRequest }
                  balanceOwed={ balanceOwed }
                />;
              case 'changePackage':
                return <ChangePackage
                  agentId={ agentId }
                  rexProductOfferings={ rexProductOfferings }
                  updateManagement={ updateManagement }
                  unsuccessfulRequest={ unsuccessfulRequest }
                  userRexProductOffering={ userRexProductOffering }
                />
                break;
              default:
                return <ManagementOptions
                  display={ display }
                  hasActiveLiveInbox={ hasActiveLiveInbox }
                  hasActiveSubscription={ hasActiveSubscription }
                  canceledLive={ canceledLive }
                  hasLegacyProduct={ hasLegacyProduct }
                />;
            }
          })() }
      </div>
    </div>
  );
};

export default ManagementView;
