import { Component } from 'react';
import AppointmentWeeklyDisplay from './AppointmentWeeklyDisplay';

export default class AppointmentWidgetModal extends Component {
  constructor(){
    super()
    this.state = {
      currentWeek: 0,
      configMode: false
    }

    this.incrementWeek = this.incrementWeek.bind(this)
    this.decrementWeek = this.decrementWeek.bind(this)
    this.enterConfigMode = this.enterConfigMode.bind(this)
    this.exitConfigMode = this.exitConfigMode.bind(this)
    this.useDefaults = this.useDefaults.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount(){
    $('.appointment-widget').draggable()
  }

  modalClass(){
    return(
      this.props.isOpen ? 'cs-overshadow' : 'cs-overshadow hidden'
    )
  }

  incrementWeek(){
    if (this.state.currentWeek < 4){
      this.setState({currentWeek: this.state.currentWeek + 1})
    }
  }

  decrementWeek(){
    if (this.state.currentWeek > 0){
      this.setState({currentWeek: this.state.currentWeek - 1})
    }
  }

  enterConfigMode(){
    this.setState({ configMode: true })
  }

  exitConfigMode(){
    this.setState({ configMode: false })
  }

  useDefaults(){
    this.child.useDefaults();
  }

  handleSubmit(){
    this.child.handleSubmit()
  }

  render(){
    let calendarButtonClass = this.state.configMode ? 'hidden' : '',
        configHeaderClass = this.state.configMode ? '' : 'hidden',
        topHeaderClass = this.state.configMode ? 'center' : 'space-between',
        bottomHeaderClass = this.state.configMode ? 'space-between' : 'center';

    return(
      <div
        className={this.modalClass()}
      >
        <div className='appointment-widget'>
          <p
            onClick={this.props.toggleModal}
            className='widget-close'
          >Close
          </p>
          <div className={`appointment-widget-header ${topHeaderClass}`}>
            <input
              type='button'
              onClick={this.useDefaults}
              className={calendarButtonClass}
              value='Use Default'
            />
            <h1 className='appointment-widget-title'>
              Set Maximum Appointment Defaults
            </h1>
            <input
              type='button'
              onClick={this.enterConfigMode}
              className={calendarButtonClass}
              value='Set Default'
            />
          </div>
          <div className='appointment-widget-header space-between'>
            <input
              type='button'
              onClick={this.state.configMode ? this.exitConfigMode : this.props.toggleModal}
              className={configHeaderClass}
              value='<< Back'
            />
            <h2
              className={configHeaderClass}
            >
              Current Defaults
            </h2>
            <input
              type='submit'
              name={this.state.configMode ? 'config' : 'timeslots'}
              onClick={this.handleSubmit}
              className={configHeaderClass}
              value='Save / Update'
            />
            <input
              type='button'
              onClick={this.decrementWeek}
              className={calendarButtonClass}
              value='Show prev 5 days'
              disabled={this.state.currentWeek === 0}
            />
            <input
              type='submit'
              name={this.state.configMode ? 'config' : 'timeslots'}
              onClick={this.handleSubmit}
              className={calendarButtonClass}
              value='Update / Save'
            />
            <input
              type='button'
              onClick={this.incrementWeek}
              className={calendarButtonClass}
              value='Show next 5 days'
              disabled={this.state.currentWeek === 4}
            />
          </div>
          <div className='appointment-widget-body'>
            <AppointmentWeeklyDisplay
              onRef={ref => (this.child = ref)}
              configMode={this.state.configMode}
              exitConfigMode={this.exitConfigMode}
              toggleModal={this.props.toggleModal}
              currentWeek={this.state.currentWeek}
            />
          </div>
        </div>
      </div>
    )
  }
}
