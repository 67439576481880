const Body = (props) => {
  const {
    historyLogs,
  } = props;

  function buildHistory() {
    const data = [];

    historyLogs.forEach((log, idx) => {
      const values = [];

      for (const i in log) {
        values.push(<td key={i}>{log[i]}</td>);
      }

      data.push(<tr key={idx}>{values}</tr>);
    });

    return data;
  }

  return (
    <tbody>
      {buildHistory()}
    </tbody>
  );
};

export default Body;
