import React from 'react';

import FormLabel from '../FormLabel';
import ErrorNotice from '../ErrorNotice';
import { inputField } from '../shared/PropTypes';
import { generateClassName, generateErrors } from '../shared/generate';

const InputField = (props) => {
  const {
    disabled,
    error,
    handleChange,
    iconKlass,
    iconOnClick,
    id,
    idPrefix,
    inlineText,
    inputClass,
    klass,
    label,
    labelKlass,
    maxLength,
    mobileSubLabel,
    onBlur,
    onFocus,
    onKeyPress,
    placeholder,
    required,
    tooltip,
    textMask,
    type,
    value,
  } = props;

  const fieldId = ['fb'];
  if (idPrefix) { fieldId.push(idPrefix); }
  fieldId.push(id);

  const className = generateClassName(klass);
  const { errorMessage, errorBool } = generateErrors(error);

  return (
    <div className={className}>
      <FormLabel
        label={label}
        htmlFor={id}
        klass={labelKlass}
        tooltip={tooltip}
        mobileSubLabel={mobileSubLabel}
        required={required}
      >
        { /input-icon-left/.test(iconKlass) && <i className={ `${iconKlass} input-icon` } onClick={iconOnClick}></i> }
        <input
          type={type}
          id={fieldId.join('-')}
          name={id}
          error={errorBool}
          placeholder={placeholder || ''}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          value={textMask ? textMask(value) : value}
          onChange={handleChange}
          disabled={disabled}
          className={inputClass}
          maxLength={maxLength}
        />
        { /input-icon-right/.test(iconKlass) && <i className={ `${iconKlass} input-icon` } onClick={iconOnClick}></i> }
        { inlineText && <div className="inline-text">{inlineText}</div> }
      </FormLabel>
      <ErrorNotice error={errorMessage} />
    </div>
  );
};

InputField.propTypes = inputField.props;
InputField.defaultProps = inputField.defaultProps;

export default InputField;
