import { Component } from 'react';

export default class WishlistModal extends Component {
  constructor(props){
    super(props)

    this.state = this.initialState();
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveAgentContext = this.saveAgentContext.bind(this);
    this.addToWishList = this.addToWishList.bind(this);
  }

  initialState(){
    return {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      last_12_month_sales: '',
      broker: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      sales_executive_id: '',
      prior_relationship: '',
      agent_context: '',
    }
  }

  showModal() {
    return this.props.state.showAddToWishListModal
      ? 'modal-overlay'
      : 'hidden';
  }

  closeModal(){
    this.props.updateState({ showAddToWishListModal: false });
    this.setState(this.initialState());
  }

  addToWishList(){
    this.props.addToWishList(this.state);
    this.closeModal();
  }

  saveAgentContext(e){
    let context = e.currentTarget.value.replace(/[^\x00-\x7F]/g, "");
    this.setState({ agent_context: context });
  }

  contentInput(placeholder, field){
    return(
      <input
        type="input"
        placeholder={placeholder}
        className='wishlist-input'
        value={this.state[field]}
        onChange={(e) => this.setState({ [field]: e.currentTarget.value })}
      />
    )
  }

  render(){
    return <div className={this.showModal()} onClick={this.closeModal}>
        <div className="title-modal" onClick={e => e.stopPropagation()}>
          <div className="header">
            <h3>Add Agent to Agent Suggestions</h3>
            <div className="close-button" onClick={this.closeModal}>
              <i className="fa fa-times-circle" />
            </div>
          </div>
          <div className="content" style={{ width: 536, flexDirection: 'row' }}>
            {this.contentInput("First Name", "first_name")}
            {this.contentInput("Last Name", "last_name")}
            {this.contentInput("Direct Line", "phone")}
            {this.contentInput("Email", "email")}
            {this.contentInput("Street Address", "address")}
            {this.contentInput("City", "city")}
            {this.contentInput("State", "state")}
            {this.contentInput("Zip", "zip")}
            {this.contentInput("Last 12 month sales", "last_12_month_sales")}
            {this.contentInput("Brokerage Name", "broker")}
            <select
              className="wishlist-select"
              value={this.state.sales_executive_id || ''}
              onChange={e => this.setState({ sales_executive_id: e.currentTarget.value })}
            >
              <option value="">Sales Executive?</option>
              {this.props.state.salesExecutives.map((exec, idx) => (
                <option key={idx} value={exec.id}>
                  {`${exec.first_name} ${exec.last_name}`}
                </option>
              ))}
            </select>
            <select
              className="wishlist-select"
              value={this.state.prior_relationship || ''}
              onChange={e => this.setState({ prior_relationship: e.currentTarget.value })}
            >
              <option value="">Prior Relationship?</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
            <textarea
              placeholder="Add a note"
              className="wishlist-textarea"
              value={this.state.agent_context}
              onChange={this.saveAgentContext}
              maxLength="255"
            />
            <div className="prompt-options">
              <div className="prompt-cancel" onClick={this.closeModal}>
                Cancel
              </div>
              <div className="prompt-confirm" onClick={this.addToWishList}>
                Create
              </div>
            </div>
          </div>
        </div>
      </div>;
  }
}
