class Locations {
  set(data) {
    const {
      country_options,
      provinces,
      states,
      type_home,
    } = data;

    this.locations = {
      country_options,
      provinces,
      states,
      type_home,
    };
  };

  get() {
    return this.locations;
  }
}

export default new Locations();
