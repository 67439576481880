import React from 'react';
import ReactDOM from 'react-dom';
import LiveRefundManagement from './ManagementContainer';

/**
 * Initialize the LiveRefundManagement system.
 */

export function initialize(containerElement, props) {
  let component = React.createElement(LiveRefundManagement, props);
  ReactDOM.render(component, containerElement);
}
