import React, { useState, useEffect } from 'react';
import intlTelInput from 'intl-tel-input';
import IntlTelInputUtils from 'intl-tel-input/build/js/utils';

import String from '../../../../../../common/String.js'

const string = new String();

const formatE164Number = iti => {
  if (!iti.telInput.value) return undefined;
  if (iti.isValidNumber()) return iti.getNumber();

  const number = iti.getNumber().replace(/\D/g, '');
  const dialCode = iti.selectedCountryData.dialCode;

  iti.handleUtils();

  return number.startsWith('+') ? number : `+${dialCode}${number}`;
};

const PhoneField = props => {
  const {
    fieldValue,
    fieldName: fldName,
    fieldPrefix,
    fieldTitle,
    fieldType,
    required,
    placeholder,
    updateFieldHandler,
  } = props;

  const [phone, setPhone] = useState(fieldValue);

  const fieldName = fldName || string.snakeCase(fieldTitle.replace(/[^\w\s]/g, ""));
  const name = !!fieldPrefix ? `${fieldPrefix}[${fieldName}]` : fieldName;
  const id = string.snakeCase(name);

  const labelText = () => {
    return required ? `${fieldTitle}*` : fieldTitle;
  };

  const updatePhone = (_, iti) => {
    const value = formatE164Number(iti);

    setPhone(value);
  };

  useEffect(() => {
    const input = document.getElementById(id);

    const iti = intlTelInput(input, {
      initialCountry: 'US',
      preferredCountries: ['US', 'CA'],
      utilsScript: IntlTelInputUtils,
    });

    input.addEventListener('change', e => updatePhone(e, iti));
    input.addEventListener('countrychange', e => updatePhone(e, iti));
  }, [fieldType]);

  const updateField = event => {
    const { value } = event.target;
    const data = !!fieldPrefix ? { [fieldPrefix]: { [fieldName]: value } } : { [fieldName]: value };

    updateFieldHandler(data);
    setPhone(value);
  };

  return (
    <div className="input-group">
      <label htmlFor={ id }>
        { fieldTitle && <span className="label-text">{ labelText() }</span> }
        <input type="tel" id={ id } name={ name } placeholder={ placeholder } value={ phone } onChange={ updateField } />
      </label>
    </div>
  );
};

export default PhoneField;
