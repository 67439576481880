import React from 'react';
import ReactDOM from 'react-dom';
import AgentServiceAreaContainer from './Container';

/**
 * Initialize the AgentServiceArea modal.
 */

export function initialize(containerElement, props) {
  let component = React.createElement(AgentServiceAreaContainer, props);
  ReactDOM.render(component, containerElement);
}
