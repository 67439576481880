import Header from './Header';
import Body from './Body';

const Table = ({ history }) => {
  return (
    <div id="research-history-table">
      <table className="table table-striped">
        <Header />
        <Body history={ history } />
      </table>
    </div>
  );
};

export default Table;
