export function calculateSecondsSinceCallStart(callStartTime) {
  if (callStartTime == null) { return "0 sec."; }
  let secondsElapsedTime = Math.round((Date.now() - callStartTime) / 1000);
  let minutes = Math.floor(secondsElapsedTime / 60);
  let secondsRemainder = secondsElapsedTime % 60;
  if (minutes > 0) {
    return minutes + " min. " + secondsRemainder + " sec.";
  } else {
    return secondsElapsedTime + " sec.";
  }
}
