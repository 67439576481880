import SellerForm from './SellerForm';
import BuyerForm from './BuyerForm';
import LiveBaseForm from '../live/BaseForm';

const LeadForm = props => {
  let {
    events,
    click2CallLink,
    country,
    state,
    updateState,
    directReferral,
    exclusive,
    exclusiveAgentId,
    exclusiveAgentLink,
  } = props;

  let { live, lead } = state;

  if (live.live_lead) {
    return (
      <LiveBaseForm
        data={state}
        events={events}
        click2CallLink={click2CallLink}
        updateState={updateState}
      />
    )
  } else if (lead.lead_type == 'seller') {
    return (
      <SellerForm
        data={state}
        events={events}
        country={country}
        click2CallLink={click2CallLink}
        updateState={updateState}
        directReferral={directReferral}
        exclusive={exclusive}
        exclusiveAgentId={exclusiveAgentId}
        exclusiveAgentLink={exclusiveAgentLink}
      />
    )
  } else if (lead.lead_type == 'buyer') {
    return (
      <BuyerForm
        data={state}
        events={events}
        country={country}
        click2CallLink={click2CallLink}
        updateState={updateState}
        directReferral={directReferral}
        exclusive={exclusive}
        exclusiveAgentId={exclusiveAgentId}
        exclusiveAgentLink={exclusiveAgentLink}
      />
    )
  } else {
    return <section />
  }
}

export default LeadForm;
