import { Component } from 'react';
import AgentSearchRow from './AgentSearchRow';

export default class AgentSearchRows extends Component {
  constructor(props){
    super(props)
  }

  column(name){
    return <div className='search-column'>{ name }</div>
  }

  imageColumn(name){
    return <div className='search-column-large'>{ name }</div>
  }

  render(){
    let { agents, fetchAgent, forceClose } = this.props;

    return(
      <div className='search-results-table'>
        <div className='search-header'>
          { this.imageColumn('Photo') }
          { this.column('Agent ID') }
          { this.column('Name') }
          { this.column('Company Name') }
          { this.column('Company Address') }
          { this.column('Company City') }
          { this.column('Company State') }
          { this.column('Company Zip') }
          { this.column('Broker Name') }
          { this.column('Agent Status') }
          { this.column('Phone Office') }
          { this.column('Phone Mobile') }
        </div>
        <div className='search-content'>
          { agents.map((agent, idx) => (
            <AgentSearchRow
              key={idx}
              agent={agent}
              fetchAgent={fetchAgent}
              forceClose={forceClose}
            />
          )) }
        </div>
        <div className='search-footer'>
          Search found { agents.length } Agents
        </div>
      </div>
    )
  }
}
