import React from 'react';

import FormLabel from '../FormLabel';
import { dateField } from '../shared/PropTypes';
import { generateClassName } from '../shared/generate';

const DateField = (props) => {
  const {
    disabled,
    handleChange,
    id,
    idPrefix,
    inputClass,
    klass,
    label,
    labelKlass,
    maxLength,
    mobileSubLabel,
    onBlur,
    onFocus,
    placeholder,
    required,
    tooltip,
    value,
  } = props;

  const fieldId = ['fb'];
  if (idPrefix) { fieldId.push(idPrefix); }
  fieldId.push(id);

  const handleOnBlur = (e) => {
    if(onBlur) {
      onBlur();
    }

    if(value && value.length > 0) {
      return;
    }

    e.currentTarget.type = 'text';
  }

  const handleOnFocus = (e) => {
    e.currentTarget.type = 'datetime-local';

    if (onFocus) {
      onFocus();
    }
  }

  const className = generateClassName(klass);

  return (
    <div className={className}>
      <FormLabel
        label={label}
        htmlFor={id}
        klass={labelKlass}
        tooltip={tooltip}
        mobileSubLabel={mobileSubLabel}
        required={required}
      >
        <input
          type="text"
          id={fieldId.join('-')}
          name={id}
          placeholder={placeholder || ''}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          className={inputClass}
          maxLength={maxLength}
        />
      </FormLabel>
    </div>
  );
};

DateField.propTypes = dateField.props;
DateField.defaultProps = dateField.defaultProps;

export default DateField;
