import { Component } from 'react';
import request       from 'superagent';

/**
 * OriginCallSelector renders the most recent calls for a backend user, and prompts the user
 * to select the call that generated a lead on lead creation.
 */
export default class OriginCallSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recentCalls:  null,
      error:        null
    };

    this.loadRecentCalls    = this.loadRecentCalls.bind(this);
    this.renderCallSelector = this.renderCallSelector.bind(this);
    this.renderCallOption   = this.renderCallOption.bind(this);
  }

  componentDidMount() {
    this.loadRecentCalls();
  }

  render() {
    if(this.state.recentCalls) {
      return this.renderCallSelector();
    } else if(this.state.error) {
      return <p class="error">{ this.state.error }</p>;
    } else {
      return <p>Loading recent calls...</p>;
    }
  }

  renderCallSelector() {
    let { selectedCall, handleChange } = this.props;
    let { recentCalls   } = this.state;

    return (
      <select className="origin-call-select" value={ selectedCall } onChange={ handleChange }>
        <option value=''>-- Choose call --</option>
        { recentCalls.map(this.renderCallOption) }
      </select>
    )
  }

  /**
   * Render an <option> element for a given call record.
   */
  renderCallOption(call, index) {
    let date            = new Date(call.created_at);
    let formattedDate   = date.toLocaleString();
    let formattedPhone  = Util.formatPhoneNumber(call.from_number);
    let durationWords   = call.duration_words;
    let callLabel       = `${formattedPhone} - Started ${formattedDate} - Lasted ${durationWords} (#${call.id})`;

    return <option value={ call.id } key={ index }>{ callLabel }</option>;
  }

  /**
   * Request the most recent inbound calls for this backend user.
   */
  loadRecentCalls() {
    request
      .get('/api/calls/recent_inbound')
      .then(response => {
        let calls = response.body.calls;

        console.debug('Received recent call data.');
        console.debug(calls);

        this.setState({ recentCalls: calls });
      })
      .catch(error => {
        console.error('Unable to load recent calls.');
        console.error(error);

        this.setState({ error: 'Unable to load recent calls.' });
      });
  }
}
