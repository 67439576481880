import React, { useState, useEffect } from 'react';

import RadioField from './RadioField.js';
import String from '../../../../../../common/String.js'

const string = new String();

const RadioFields = props => {
  const {
    fieldValue,
    fieldName: fldName,
    fieldPrefix,
    fieldTitle,
    required,
    options = [],
    inline = false,
    updateFieldHandler,
  } = props;

  const radioGroupKlass = inline ? 'radio-input-group inline' : 'radio-input-group';
  const fieldName = fldName || string.snakeCase(fieldTitle.replace(/[^\w\s]/g, ""));
  const name = !!fieldPrefix ? `${fieldPrefix}[${fieldName}]` : fieldName;
  const id = string.snakeCase(name);

  const [radioValue, setRadioValue] = useState(fieldValue);

  const labelText = () => {
    return required ? `${fieldTitle}*` : fieldTitle;
  };

  const updateField = event => {
    const { value } = event.target;
    const data = !!fieldPrefix ? { [fieldPrefix]: { [fieldName]: value } } : { [fieldName]: value };

    setRadioValue(value);
    updateFieldHandler(data);
  };

  const buildRadioOptions = () => {
    const radios = [];

    options.forEach((option, idx) => {
      const rId = `${id}${idx}`;
      const [text, value] = option;

      radios.push(<RadioField fieldId={ rId } fieldName={ name } fieldChecked={ radioValue === value } fieldText={ text } fieldValue={ value } onClickHandler={ updateField } />);
    });

    return radios;
  };

  return (
    <div className="input-group">
      <label htmlFor={ id }>
        { fieldTitle && <span className="label-text">{ labelText() }</span> }
        <div className={ radioGroupKlass }>
          { buildRadioOptions() }
        </div>
      </label>
    </div>
  );
};

export default RadioFields;
