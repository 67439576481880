import React from 'react';
import ReactDOM from 'react-dom';
import EligibleDialer from './index';

/**
 * Initialize PrimeEligibleDialer
 */

export function initialize(containerElement, props) {
  let component = React.createElement(EligibleDialer, props);
  ReactDOM.render(component, containerElement);
}
