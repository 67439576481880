import React from 'react';
import Context from '../../Context';

const StageThree = () => (
  <Context.Consumer>
    {() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

      return (
        <div className="stage3 form-builder">
          <h2 className="title">Done! Please proceed to the next available task in the sidebar.</h2>
        </div>
      )
    }}
  </Context.Consumer>
)

export default StageThree;
