import React from 'react';

const LiveLeadType = ({ setState }) => {
  function handleServiceTypeChange(e) {
    const leadServiceType = e.target.value;
    setState({ leadServiceType });
  }

  return (
    <div className='row'>
      <div className='lead-service-type'>
        <h3>Type of LIVE lead</h3>
        <select onChange={handleServiceTypeChange}>
          <option value=''></option>
          <option value='880'>CINC</option>
          <option value='899'>Compass</option>
          <option value='705'>ReferralExchange</option>
        </select>
      </div>
    </div>
  );
}

export default LiveLeadType;
