import React from 'react';

import LiveManagement from '../LiveAccountManagement/images/live_management.png';

const LiveRefundManagementOptions = (props) => {
  const {
    display,
  } = props;

  return (
    <div>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ LiveManagement } />
      </div>
      <div className='rex-modal-heading'>LIVE Refund Management</div>
      <div className='rex-modal-actions'>
        <button
          className='rex-modal-action'
          onClick={ () => display('processRefund') }
        >
          Process Refund
        </button>
      </div>
    </div>
  );
};

export default LiveRefundManagementOptions;
