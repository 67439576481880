import React from 'react';
import Fetch from '../../common/fetch';
import Modal from './Modal';

const ReviewModeration = (props) => {
  const {
    review: {
      client_city,
      client_email,
      client_name,
      client_state,
      client_type,
      client_zip,
      close_date,
      close_price,
      created_at,
      id,
      property_address,
      moderated_by,
      moderated_value,
      other_reviews,
      rating,
      rea_agent_id,
      review,
      reported,
      reported_at,
      reported_by,
      reported_reason,
      reported_comment,
      rex_agent_id,
      service_date,
      service_month,
      service_provided,
      service_year,
      url,
    },
    moderation_result_options,
  } = props;

  const displayModal = () => {
    document.body.dispatchEvent(new Event('review_moderation.modal_open'));
  }

  const hideCard = () => {
    const card = document.getElementById(`queue_item_AgentReviews_${id}`);
    if (card) {
      card.classList.add('hidden');
    }
  }

  const validateReview = async (payload) => {
    const container = document.querySelector('.caesar-container');

    const resp = await Fetch.post({
      url: '/review_moderation/review/validate',
      payload,
    })

    if (resp.success) {
      hideCard();
      container.innerHTML = 'COMPLETED'
    } else {
      console.warn('error');
    }
  }

  const location = () => {
    const stateZip = [client_state, client_zip]
      .filter((x) => x)
      .join(' ');

    if (client_city) {
      return [client_city, stateZip].join(', ');
    }

    return stateZip;
  }

  const buildOtherReviewLinks = ids => {
    const links = []

    ids.forEach((id, idx) => {
      const onClickAction = () => ReferralExchange.showDetail('AgentReviews', id);

      links.push(<a key={ idx } className="other-review" onClick={ onClickAction }>{ id }</a>);

      if (idx < ids.length - 1) links.push(', ');
    });

    return links;
  }

  const serviceDate = [
    service_month,
    service_date,
    service_year,
  ].filter((x) => x).join(' / ')

  return(
    <div id="main-container">
      <Modal
        moderationResultOptions={ moderation_result_options }
        reviewId={ id }
        validateReview={ validateReview }
      />

      <div className="main-container-review">
        {
          reported && (
            <span className="reported-pill">Reported</span>
          )
        }
        <ul>
          <li>
            <span>Reviewed on:</span>
            {created_at}
          </li>
          <li>
            <span>Name:</span>
            {client_name}
          </li>
          <li>
            <span>Email:</span>
            {client_email}
          </li>
          {
            property_address && (
              <li>
                <span>Address:</span>
                {property_address}
              </li>
            )
          }
          {
            location() && (
              <li>
                <span>Location:</span>
                {location()}
              </li>
            )
          }
          {
            client_type && (
              <li>
                <span>Type:</span>
                {client_type}
              </li>
            )
          }
          {
            service_provided && (
              <li>
                <span>Service Provided:</span>
                {service_provided}
              </li>
            )
          }
          {
            serviceDate && (
              <li>
                <span>Service Date:</span>
                {serviceDate}
              </li>
            )
          }
          {
            close_date && (
              <li>
                <span>Close Date:</span>
                {close_date}
              </li>
            )
          }
          {
            close_price && (
              <li>
                <span>Close Price:</span>
                {close_price}
              </li>
            )
          }
          <li>
            <span>Rating:</span>
            <b>{rating}</b>
          </li>
          {
            (other_reviews.length > 0) && (
              <li>
                <span>Other Reviews:</span>
                <p>{ buildOtherReviewLinks(other_reviews) }</p>
              </li>
            )
          }
          {
            review && (
              <li>
                <span>Review:</span>
                <p className="review-block">{review}</p>
              </li>
            )
          }
          {
            reported_comment && (
              <li>
                <span>Reported Comment:</span>
                <p className="review-block reported">{reported_comment}</p>
              </li>
            )
          }
          {
            url && (
              <li>
                <span>URL:</span>
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {url}
                </a>
              </li>
            )
          }
          {
            rea_agent_id && (
              <li>
                <span>REA Agent ID:</span>
                {rea_agent_id}
              </li>
            )
          }
          {
            rex_agent_id && (
              <li>
                <span>REX Agent ID:</span>
                {rex_agent_id}
              </li>
            )
          }
          {
            reported_reason && (
              <li>
                <span>Reported reason:</span>
                {reported_reason}
              </li>
            )
          }
          {
            reported_at && (
              <li>
                <span>Reported date: </span>
                {reported_at}
              </li>
            )
          }
          {
            reported_by && (
              <li>
                <span>Reported by: </span>
                {reported_by}
              </li>
            )
          }
          {
            moderated_by && (
              <li>
                <span>Moderated by:</span>
                {moderated_by}
                &nbsp;
                <b>{moderated_value}</b>
              </li>
            )
          }
        </ul>
      </div>
      <div className="caesar-container">
        <div className="thumb-wrapper">
          <button
            type="button"
            className="thumb-button green"
            onClick={ validateReview.bind(null, { review_id: id, validated: true })  }
          >
            <i className="fas fa-thumbs-up" />
          </button>
          <span>This review is valid</span>
        </div>
        <div className="thumb-wrapper">
          <button
            type="button"
            className="thumb-button red"
            onClick={ displayModal }
          >
            <i className="fas fa-thumbs-down" />
          </button>
          <span>This review is invalid (irrelevant, profane, spam)</span>
        </div>
      </div>
    </div>
  )
}

export default ReviewModeration;
