import { all, call, put, takeLatest } from 'redux-saga/effects';
import request from 'superagent';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import Locations from '../locations';

import { cityState } from '../utils';

const buyerCities = ({ buyer_cities, country, provinces, states }) => {
  if (!buyer_cities) {
    return [ cityState() ];
  }

  const arr = buyer_cities.split('&');
  const objs = arr.map(pair => {
    const splitPair = pair.split(',');

    const c = splitPair[0].trim();
    const s = splitPair[1].toUpperCase().trim();

    const regions = country === 'US' ? states : provinces;

    if (regions.includes(s)) {
      return cityState(c,s);
    }
  }).filter(Boolean);

  if (!objs.length) {
    return [ cityState() ];
  }

  return objs;
};

const intlNumber = (phone_number, country) => {
  if (!phone_number) {
    return {
      number: null,
      ext: null,
    };
  }

  let c = null;

  if (phone_number.charAt(0) !== '+') {
    c = country;
  }

  return parsePhoneNumberFromString(phone_number, c);
};

const cleanData = (data) => {
  const { lead } = { ...data };
  const updatedLead = {};
  if (lead.home_price_min === null) {
    updatedLead.home_price_min = 0;
  }

  const { buyer_cities, country, type_home } = lead;
  const { provinces, states } = data;

  updatedLead.buyer_cities = buyerCities({ buyer_cities, country, provinces, states });
  updatedLead.home_price_min = lead.home_price_min || 0;
  updatedLead.home_price_max = lead.home_price_max || 0;

  const phoneNumbers = intlNumber(lead.phone_number, lead.country);
  updatedLead.phone_number = phoneNumbers.number;
  updatedLead.phone_number_ext = phoneNumbers.ext;
  updatedLead.type_home = type_home || 'Unknown';

  return { ...data, lead: { ...lead, ...updatedLead } };
};

const getLeadData = ({ leadId, segment }) => {
  let options = {
    no_prepare: false,
    lead_id: leadId
  };

  return request
    .get(`/qual/queue/${segment}/request_call`)
    .query(options)
    .then(({ body }) => {
      return cleanData(body);
    });
};


function* setCustomAttributeData(action) {
   try {
      const { customAttributes } = action.data;
      yield put({ type: 'SET_LIMITED_LEAD_INFO_DATA', customAttributes });
      yield put({ type: 'SET_LIMITED_LEAD_HEADER_DATA', customAttributes });
      yield put({ type: 'SET_LIMITED_LEAD_AGENT_DATA', customAttributes });
      yield put({ type: 'SET_LIMITED_LEAD_LIVE_DATA', customAttributes });
   } catch (e) {
      yield put({type: 'SET_CUSTOM_ATTRIBUTES_FAILED', message: e.message});
   }
}

function* setCustomAttributeDataSaga() {
  yield takeLatest('SET_CUSTOM_ATTRIBUTES', setCustomAttributeData);
}


function* fetchLeadData(action) {
   try {
      const data = yield call(getLeadData, action.data);
      Locations.set(data);
      yield put({ type: 'SET_ACTIONDIALER_DATA', data });
      yield put({ type: 'SET_HEADER', data });
      yield put({ type: 'SET_LEAD_INFO', data });
      yield put({ type: 'SET_METADATA', data });
      yield put({ type: 'SET_INFO', data });
      yield put({ type: 'SET_INITIAL_NOTE', data });
      yield put({ type: 'SET_QUAL', data });
      yield put({ type: 'SET_LIVE', data });
      yield put({ type: 'SET_LOCATIONS', data });
      yield put({ type: 'SET_SUBMITTING_AGENT', data });
      yield put({ type: 'SET_ACTIONS', data });
      yield put({ type: 'SET_INITIAL_BUYER_CITIES_FOCUSED', data });
      yield put({ type: 'SET_TIMESLOTS', data });
      yield put({ type: 'VALIDATE_INFO', data });
      yield put({ type: 'VALIDATE_BUYER_CITY_LOCATIONS', data });
   } catch (e) {
      Bugsnag.notify(e, event => {
        event.addMetadata('context', { name: 'QualQueueV2 Fetch Lead Data', errorType: e.message });
      });

      yield put({type: 'GET_LEAD_DATA_FAILED', message: e.message});
   }
}

function* fetchLeadDataSaga() {
  yield takeLatest('GET_LEAD_DATA', fetchLeadData);
}

function* resetData() {
  yield put({ type: 'RESET_ACTIONDIALER_DATA' });
  yield put({ type: 'RESET_HEADER' });
  yield put({ type: 'RESET_LEAD_INFO' });
  yield put({ type: 'RESET_METADATA' });
  yield put({ type: 'RESET_INFO' });
  yield put({ type: 'RESET_NOTE' });
  yield put({ type: 'RESET_QUAL' });
  yield put({ type: 'RESET_LIVE' });
  yield put({ type: 'RESET_LOCATIONS' });
  yield put({ type: 'RESET_SUBMITTING_AGENT' });
  yield put({ type: 'RESET_ACTIONS' });
  yield put({ type: 'RESET_TIMESLOTS' });
  yield put({ type: 'RESET_VALIDATIONS' });
  yield put({ type: 'RESET_FOCUSED' });
  yield put({ type: 'RESET_NEW_LEAD' });
}

function* resetLeadDataSaga() {
  yield takeLatest('RESET_LEAD_DATA', resetData);
}

export default [
  setCustomAttributeDataSaga(),
  fetchLeadDataSaga(),
  resetLeadDataSaga(),
];
