import { Component } from 'react';

export default class Notes extends Component {
  constructor(props){
    super(props)
  }

  isNotesPopulated(){
    let notes = this.props.state.opsNotes;
    return notes.length > 0;
  }

  notesList(){
    let notes = this.props.state.opsNotes;

    return notes.map((note, idx) => {
      return (
        <div className='list-item-centered' key={idx}>
          <div className="list-item-centered-detail w1000 message">Message: {note.message}</div>
          <div className="list-item-centered-detail w200 createdBy">Created by: {note.created_by}</div>
          <div className="list-item-centered-detail w200 createdAt">Created: {note.created_at}</div>
        </div>
      )
    })
  }

  emptyList(){
    return(
      <div className='list-item-centered'>
        <div className="list-item-centered-detail w1000 message">No notes created</div>
      </div>
    )
  }

  render(){
    let { klass, updateState } = this.props;
    let { salesManager } = this.props.state;
    return(
      <div className={klass}>
        <div className='toolbar'>
          <h3>Ops Notes</h3>
          <input
            type='button'
            className='add-button'
            onClick={() => { updateState({ showAddNoteModal: true }) }}
            value='Create note'
          />
        </div>
        <div className='tab-content'>
          <div className='list h280'>
            <div id={`sales_manager_${salesManager.id}_ops_notes`} className='list-inner'>
              { this.isNotesPopulated() ? this.notesList() : this.emptyList()  }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
