import Form from './footer/Form';

const Footer = (props) => {
  const {
    agent,
    recruitmentNote,
    handleRecruitmentNote,
    dispositionProspect,
  } = props;

  return(
    <div id="dialer-recruit-top-agents-footer">
      <Form
        agent={ agent }
        recruitmentNote={ recruitmentNote }
        handleRecruitmentNote={ handleRecruitmentNote }
        dispositionProspect={ dispositionProspect }
      />
    </div>
  );
};

export default Footer;
