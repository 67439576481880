import ChangeWinningAgent from '../tools/ChangeWinningAgent';
import MasqueradeAsUser from '../tools/MasqueradeAsUser';
import AddAgentCRMFields from '../tools/addAgentCRMFields';
import EmergencyExclusionAreas from '../tools/EmergencyExclusionAreas';

import BlueUserEdit from '../images/blue-user-edit.svg';
import TheaterMasks from '../images/theater-masks.svg';
import PlusCircled from '../images/plus-circled.svg';
import EmergencyIcon from '../images/emergency-icon.svg';

const ToolsList = [
  {
   icon: BlueUserEdit,
   title: 'Change Winning Agent',
   tool: ChangeWinningAgent,
  },
  {
   icon: TheaterMasks,
   title: 'Masquerade As User',
   tool: MasqueradeAsUser,
 },
 {
   icon: PlusCircled,
   title: 'Add AgentCRM Inputs',
   tool: AddAgentCRMFields,
 },
 {
   icon: EmergencyIcon,
   title: 'Emergency Exclusion Areas',
   tool: EmergencyExclusionAreas,
 },
];

export default ToolsList;
