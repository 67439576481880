import React from 'react';
import ReactDOM from 'react-dom';
import WelcomeCallDialer from './WelcomeCallDialer';

/**
 * Initialize the WelcomeCallDialer system.
 */

export function initialize(containerElement, options) {
  let component = React.createElement(WelcomeCallDialer, options);
  ReactDOM.render(component, containerElement);
}
