const Head = () => (
  <thead>
    <tr>
      <th>Note</th>
      <th>Lead ID</th>
      <th>Created On</th>
      <th>Created By</th>
    </tr>
  </thead>
);

export default Head;
