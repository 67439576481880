import React, { Component } from 'react';
import { store } from '../../../index';

import { getStateProps } from '../../../utils';
import { selectedCountryInfo } from '../utils';

import BaseSelect from './index';

import View from './view';

export default class InputContainer extends BaseSelect {
  constructor(props) {
    super(props);
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { dispatch } = store;
    const city = store.getState().info.get('city');
    const country = store.getState().info.get('country');
    const state = store.getState().info.get('state');

    const data = {
      city,
      country,
      state,
    };

    data[name] = value;

    dispatch({
      type: 'GET_VERIFIED_LOCATION',
      data,
    });

    this.setInfoField(name, value);
  }

  render() {
    const country = store.getState().info.get('country');
    const countryInfo = selectedCountryInfo(country);

    const {
      labels,
      statesSelectValues,
    } = countryInfo;

    const {
      classNames,
      display,
      id,
      index,
      property
    } = this.props;

    const {
      focused,
      valid,
      value,
     } = this.state;

    const state = value ? value.toUpperCase() : '';

    return (
      <View
        classNames={ classNames }
        data={ statesSelectValues }
        display={ labels.state }
        focused={ focused }
        name='state'
        valid={ valid }
        value={ state }

        onChange={ this.handleChange }
        onFocus={ this.handleOnFocus }
      />
    );
  }
}
