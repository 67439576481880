import String from '../../../../../../common/String.js'

const string = new String();

const SelectField = props => {
  const {
    fieldValue,
    fieldName: fldName,
    fieldPrefix,
    fieldTitle,
    required,
    includeBlank,
    options,
    updateFieldHandler,
  } = props;

  const fieldName = fldName || string.snakeCase(fieldTitle.replace(/[^\w\s]/g, ""));
  const name = !!fieldPrefix ? `${fieldPrefix}[${fieldName}]` : fieldName;
  const id = string.snakeCase(name);

  const updateField = event => {
    const { value } = event.target;
    const data = !!fieldPrefix ? { [fieldPrefix]: { [fieldName]: value } } : { [fieldName]: value };

    updateFieldHandler(data);
  };

  const labelText = () => {
    return required ? `${fieldTitle}*` : fieldTitle;
  };

  const buildOptions = () => {
    const opts = [];

    if (includeBlank) opts.push(<option key="blank" value="">Select</option>);

    for (const key in options) {
      const [display, value] = options[key];

      opts.push(<option key={ key } value={ value }>{ display }</option>);
    };

    return opts;
  };

  return (
    <div className="input-group">
      <label htmlFor={ id }>
        { fieldTitle && <span className="label-text">{ labelText() }</span> }
        <select id={ id } name={ name } onChange={ updateField } value={ fieldValue }>
          { buildOptions() }
        </select>
      </label>
    </div>
  );
};

export default SelectField;


