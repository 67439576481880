import React, { useState, useEffect, useRef } from 'react';
import { timer } from '../../../util/Util';

const formatTime = time => {
  const m = moment().startOf('day').add(time || 0, 'seconds');
  const times = [];

  if (m.hours()) times.push(`${m.hours()}h`);

  times.push(`${m.minutes()}m`);
  times.push(`${m.seconds()}s`);

  return times.join(' ');
};

const CallStats = (props) => {
  const {
    callStats: { talk_time, calls, scheduled, missed, remaining },
    number,
    togglePause,
    pause,
  } = props;

  const [duration, setDuration] = useState('0:00');
  const intervalId = useRef(undefined);

  useEffect(() => { timer(intervalId, setDuration) }, [number]);

  return (
    <div className="dialer-call-stats">
      <div className="dialer-call-stats-left">
        <button
          type="button"
          className="pause-button"
          onClick={togglePause}
        >
          {pause ? 'Unpause' : 'Pause'}
          &nbsp;&nbsp;
          <i className={pause ? 'play-circle' : 'pause-circle'} />
        </button>
        <div className="call-timer">
          {duration}
        </div>
        <div className="call-number">
          <i className="headphones" />
          &nbsp;
          {number}
        </div>
      </div>
      <div className="dialer-call-stats-right">
        <h3>Today's Stats</h3>
          <div className="call-stat">
            <span className="call-stat-text">Talk time</span>
            <span className="call-stat-value">{ formatTime(talk_time) }</span>
          </div>
          <div className="call-stat">
            <span className="call-stat-text">Calls</span>
            <span className="call-stat-value">{ calls }</span>
          </div>
          <div className="call-stat">
            <span className="call-stat-text">Scheduled</span>
            <span className="call-stat-value">{ scheduled }</span>
          </div>
          <div className="call-stat">
            <span className="call-stat-text">Missed</span>
            <span className="call-stat-value">{ missed }</span>
          </div>
          <div className="call-stat">
            <span className="call-stat-text">Tasks Remaining</span>
            <span className="call-stat-value">{ remaining }</span>
          </div>
      </div>
    </div>
  )
}

export default CallStats;
