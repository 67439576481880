import React from 'react';
import moment from 'moment';

import { createSectionRow } from '../utils';

import './styles.scss';

const historyRow = (data, i) => {
  const {
    created_at,
    created_by,
    status,
  } = data;

  const dateTimeFormat = 'MM/DD/YY hh:mm A';

  const date = moment(created_at, dateTimeFormat).format('MMM. D, YYYY');
  const time = moment(created_at, dateTimeFormat).format('h:mm a');

  const abbreviatedName = (full) => {
  	const name = full.split(' ');

    if (name.length === 1) {
      return full;
    }

  	const abbreviated = name.reduce((acc, n, i) => {
    	if (i !== name.length - 1) {
        return acc += `${ n } `;
      }
      return acc += `${ n.substring(0,1)}.`
  	}, '');

    return abbreviated.trim();
  };

  return (
    <div className='history-row' key={ `qual-${ i }`}>
      <div>{ date }</div>
      <div>{ time }</div>
      <div>{ abbreviatedName(created_by) }</div>
      <div>{ status }</div>
    </div>
  );
}

const noteRows = (data) => {
  if (!data) {
    return null;
  }

  return (
    <div className='qual-notes'>
      {
        data.map((r, i) => <span className='qual-note' key={ i }>{ r }</span>)
      }
    </div>
  );
}

const Qual = (props) => {
  const { data, history, records } = props;
  const {
    attempts,
    instructions,
    max_requal_attempts,
    note,
  } = data;

  const notes = note ? note.split(/\r?\n/).filter(Boolean) : note;
  const historyLabel = `Qual History (Currently on Attempt ${ attempts } of ${ max_requal_attempts })`;
  return (
    <div className='active-dialer-lead-section qual'>

      {
        createSectionRow({
          classNames: 'full-width',
          id: 'qual-instructions',
          key: 'Qual Instructions',
          section: 'qual',
          value: instructions
        })
      }

      {
        createSectionRow({
          classNames: 'full-width',
          id: 'most-recent-qual',
          key: 'Qual Notes',
          section: 'qual',
          value: noteRows(notes)
        })
      }

      {
        createSectionRow({
          classNames: 'full-width history',
          id: 'qual-history-table',
          key: historyLabel,
          section: 'qual',
          value: (() => (
            <div className='history-table'>
              <div className='history-row history-headers'>
                <div>Date</div>
                <div>Time</div>
                <div>Called By</div>
                <div>Disposition</div>
              </div>

              <div className='history-body'>
                { history &&
                  history.map((r, i) => historyRow(r, i))
                }
              </div>
            </div>
          ))()
        })
      }
    </div>
  );
};

export default Qual;
