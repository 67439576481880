import { Component } from 'react';
import NewTaskModal from '../modals/NewTaskModal';
import AgentSearch from '../search_bar/AgentSearch';

export default class Viewing extends Component {
  constructor(props){
    super(props)
    this.state = {
      showNewTask: false
    }
    this.toggleNewTask = this.toggleNewTask.bind(this);
    this.fetchAgent = this.fetchAgent.bind(this);
  }

  toggleNewTask(e){
    this.setState({ showNewTask: !this.state.showNewTask})
  }

  newTask(){
    let { showNewTask } =  this.state;
    let { user, agentId, currentUser } = this.props;

    if(showNewTask && user){
      return(
        <NewTaskModal
          currentUser={currentUser}
          toggleNewTask={this.toggleNewTask}
          agentId={agentId}
          user={user}
        />
      )
    }
  }

  fetchAgent(agent) {
    this.props.fetchAgent(agent.id);
  }

  render(){
    let { currentUser, fetchAgent, agentId }  = this.props;
    let name = currentUser ? currentUser.first_name : ''
    return(
      <div className='cs-viewing'>
        <AgentSearch fetchAgent={this.fetchAgent} />
        <div>
          <div>Viewing:</div>
          <div className={'watching_' + 'Agent_' + agentId}>
            { name }
          </div>
        </div>
        <div>
          <input
            type='button'
            value='Create Task'
            onClick={ this.toggleNewTask }
            className='cs-create-task-button hidden'
          />
        </div>
        { this.newTask() }
      </div>
    )
  }
}
