import React, { Component } from 'react';
import { connect } from 'react-redux';

import View from './view';

function mapStateToProps(state) {
  return {
    first_name: state.info.get('first_name'),
    last_name: state.info.get('last_name'),
    origin: state.live.get('source'),
    rawName: state.submittingAgent.get('rawName'),
    wpSubscriberName: state.lead.get('wp_subscriber_name'),
    submission: state.headers.get('submission'),
    submittingAgent: state.submittingAgent.get('info'),
    liveLead: state.live.get('live_lead'),
  };
}

class Header extends Component {
  render() {
    const {
      attempts,
      first_name,
      last_name,
      liveLead,
      max_requal_attempts,
      origin,
      rawName,
      wpSubscriberName,
      submission,
      submittingAgent
    } = this.props;

    const leadInfo = {
      first_name,
      last_name,
      rawName,
      wpSubscriberName,
    };

    const sourceInfo = { origin, submission };

    const attemptInfo = { attempts, max_requal_attempts };

    const data = {
      attemptInfo,
      leadInfo,
      liveLead,
      sourceInfo,
      submittingAgent,
    };

    return(
      <View data={ data } />
    );
  }
}

export default connect(mapStateToProps)(Header);
