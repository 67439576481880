import React, { Component } from 'react';
import { connect } from 'react-redux';
import request from 'superagent';

import './styles.scss';

import Actions from './Actions';
import Comments from './Comments';
import Controls from './Controls';
import Header from './Header';
import Info from './Info';
import Metadata from './Metadata';
import NewLead from './NewLead';
import Note from './Note';
import Qual from './Qual';
import Scripts from './Scripts';
import SubmittingAgent from './SubmittingAgent';
import Viewing from './Viewing';
import Contact from './Contact';

class UI extends Component {
  componentDidMount() {
    document.body.setAttribute('id', 'active-dialer');
    const {
      customAttributes,
      dispatch,
      leadID,
      segment,
      conferenceType,
    } = this.props;

    if (leadID) {
      dispatch({ type: 'RESET_LEAD_DATA' });
      this.fetchData({ leadId: leadID, segment });
    }

    if (customAttributes) {
      dispatch({
        type: 'SET_CUSTOM_ATTRIBUTES',
        data: { customAttributes }
      });
    }
  }

  fetchData({ leadId , segment }) {
    const { dispatch } = this.props;

    dispatch({
      type: 'GET_LEAD_DATA',
      data: {
        leadId,
        segment,
      }
    });

    dispatch({
      type: 'GET_QUAL_HISTORY',
      data: {
        leadId,
      }
    });

    dispatch({
      type: 'GET_VERIFICATION_RECORDS',
      data: {
        leadId,
      }
    });
  }

  render () {
    const {
      hooks,
      leadID,
      repID,
      sessionUUID,
      conferenceType,
    } = this.props;

    return (
      <div className='qual-queue-v2'>
        <NewLead />
        {
          // <Scripts />
        }
        <div className='active-dialer-content'>
          <div className='active-dialer-sticky-header'>
            {
              // <Viewing />
            }
            <div className='active-dialer-sticky-header-container'>
              <Header />
              <Controls
                leadID={ leadID }
                repID={ repID }
                sessionUUID={ sessionUUID }
                conferenceType={ conferenceType }
              />
            </div>

          </div>
          <div className='active-dialer-lead'>

            <Metadata />
            <SubmittingAgent />
            <Qual />
            <Info />
            <Note />
            <Contact />
            <Comments />
          </div>
          <Actions hooks={ hooks }/>
        </div>
      </div>
    );
  }
}

export default connect()(UI);
