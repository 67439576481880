import Pagination from './Pagination';

const Header = props => {
  const {
    avgRating,
    fetchReviews,
    filter,
    page,
    perPage,
    quarterRoundSplit,
    setAttribute,
    totalCrmReviews,
    totalFilteredReviews,
  } = props;

  const [int, fract] = quarterRoundSplit(avgRating);
  const ratingKlass = `rated-${int}-${fract || 0}`;
  const ratingText = (avgRating / 1.0).toFixed(2).replace(/0$/, '');

  return (
    <div className="row mb-15">
      <div className="col-md-6">
        Reviews Published on Public Profile:
        <span className={ `profile-review-rating ${ratingKlass} mr-5` }></span>
        { ratingText } stars based on { totalCrmReviews } reviews
      </div>
      <div className="col-md-6 text-right">
        <Pagination
          fetchReviews={ fetchReviews }
          filter={filter}
          page={ page }
          perPage={ perPage }
          setAttribute={ setAttribute }
          totalFilteredReviews={ totalFilteredReviews }
        />
      </div>
    </div>
  )
};

export default Header;
