import React, { Component } from 'react';
import { store } from '../../../index';
import { getStateProps } from '../../../utils';

import BaseSelectContainer from './index';

import View from './view';

export default class TypeHomeContainer extends BaseSelectContainer {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      classNames,
      display,
      id,
      index,
      property
    } = this.props;

    const {
      focused,
      valid,
      value,
     } = this.state;

    const { mortgage_status } = getStateProps(store.getState().lead);

    const data = mortgage_status && mortgage_status.hasOwnProperty('options') ? mortgage_status.options : null;

    return (
      <View
        classNames={ classNames }
        data={ data }
        display={ display }
        focused={ focused }
        id={ id }
        index={ index }
        name={ property }
        valid={ valid }
        value={ value }

        onChange={ this.handleChange }
        onFocus={ this.handleOnFocus }
      />
    );
  }

}
