import { Map } from 'immutable';

const initialState = Map({
  history: null,
  instructions: null,
  note: null,
  records: null,
});

const actionsMap = {
  ['SET_QUAL']: (state, action) => {
    const {
      note,
      qual_note,
    } = action.data;

    return state.merge(Map({
      instructions: note.message,
      note: qual_note
    }));
  },
  ['SET_QUAL_HISTORY']: (state, action) => {
    return state.merge(Map({
      history: action.data
    }));
  },
  ['RESET_QUAL']: (state) => {
    return state.merge(initialState);
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
