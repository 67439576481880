import React from 'react';

const LeadService = ({ state, setState }) => {
  const { leadService } = state;

  function handleClick(e) {
    const leadService = e.target.dataset.type;
    const currentPage = leadService === 'microsite'
      ? 'personalInfo'
      : 'liveLeadType';

    setState({
      currentPage,
      leadService
    });
  }

  return(
    <div className='row'>
      <form>
        <div className="button-wrapper">
          <label
            data-type='microsite'
            className={"button-label " + (leadService === 'microsite' ? 'label-highlight' : '')}
            htmlFor='lead-service-microsite-partner'
            onClick={handleClick}
          >
            Microsite/Partner
        </label>
        </div>
        <div className="button-wrapper">
          <label
            data-type='live'
            className={"button-label right-button " + (leadService === 'live' ? 'label-highlight' : '')}
            htmlFor='lead-service-live'
            onClick={handleClick}
          >
            Live
        </label>
        </div>
      </form>
    </div>
  );
}

export default LeadService
