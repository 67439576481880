import React, { useState, useEffect, useRef } from 'react';
import { timer } from '../../../util/Util';

const CallStats = (props) => {
  const {
    callStats: { agents_completed, agents_remaining },
    togglePause,
    pause,
  } = props;

  const [duration, setDuration] = useState('0:00');
  const intervalId = useRef(undefined);

  useEffect(() => { timer(intervalId, setDuration) }, [agents_remaining]);

  return (
    <div className="dialer-call-stats">
      <div className="dialer-call-stats-left">
        <button
          type="button"
          className="pause-button"
          onClick={togglePause}
        >
          {pause ? 'Unpause' : 'Pause'}
          &nbsp;&nbsp;
          <i className={pause ? 'play-circle' : 'pause-circle'} />
        </button>
        <div className="call-timer">
          {duration}
        </div>
      </div>
      <div className="dialer-call-stats-right">
        <div className="call-stat">
          <span className="call-stat-text">Completed Today</span>
          <span className="call-stat-value">{ agents_completed || 0 }</span>
        </div>
        <div className="call-stat">
          <span className="call-stat-text">Agents Remaining</span>
          <span className="call-stat-value">{ agents_remaining || 0 }</span>
        </div>
      </div>
    </div>
  )
}

export default CallStats;
