import { Component } from 'react';

const labels = {
  visited: 'Visited?',
  claimed: 'Claimed home?',
  viewed: 'Properties viewed',
  watched: 'Properties watched',
};

const emailButtonContent = {
  invited: 'Resend invitation email',
  notInvited: 'LEAD NOT ELIGIBLE FOR INVITE',
  processing: <i className='fa fa-spinner fa-pulse'></i>,
  sent: 'Sent',
};

const MAX_PROPERTIES_TO_SHOW = 3;

export default class ComeHomeAnalytics extends Component {
  constructor(props) {
    super(props);

    this.events = EventBus;

    this.state = {
      visited: { occurred: false, date: '' },
      claimed: { occurred: false, date: '', properties: [] },
      viewed: { count: 0, properties: [] },
      watched: { count: 0, properties: [] },
      emailButton: { content: emailButtonContent.notInvited, disabled: true },
    };
  }

  componentDidMount() {
    this.events.on('dialer:loaded', this._handleDialerLoaded.bind(this));
  }

  _handleDialerLoaded() {
    this._ensureContainersClosed();
    this._requestAnalytics();
  }

  _ensureContainersClosed() {
    const containers = document.querySelectorAll('.properties-container');
    containers.forEach(container => container.classList.add('closed'));
  }

  _requestAnalytics() {
    if (this.props.lead.id) {
      const url = this._buildAnalyticsURLForLead();

      fetch(
        url,
        {
          method: 'GET',
          credentials: 'same-origin'
        }
      )
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          this._setAttributes(response.data);
        } else {
          throw new Error(response.errors);
        }
      })
      .catch(error => this._logError('cha_data_request', error));
    }
  }

  _sendEmailInvite() {
    this._toggleEmailButton('disable');

    const { comeHomeMailerPath, lead } = this.props;
    const params = { email_type: 'come_home_invite_reminder', emailable_id: lead.id, recipient_id: lead.id };

    fetch(
      comeHomeMailerPath,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
        body: JSON.stringify(params),
      }
    )
    .then(response => response.json())
    .then(response => {
      const {
        success,
        errors
      } = response;

      if (success) {
        setTimeout(() => this.setState({ emailButton: { content: emailButtonContent.sent, disabled: true } }), 1000);
      } else {
        throw new Error(errors);
      }
    })
    .catch(error => {
      this._logError('cha_email_request', error);
      this._toggleEmailButton('enable');
    });
  }

  _buildAnalyticsURLForLead() {
    const { comeHomeAnalyticPath, lead } = this.props;

    return comeHomeAnalyticPath.replace(/place_holder_for_lead_id/, lead.id);
  }

  _setAttributes(data) {
    const newState = {};
    const {
      invited,
      notInvited,
    } = emailButtonContent;

    for (const i in data) {
      newState[i] = data[i];
    }

    const { occurred } = newState.visited;
    const content = occurred ? invited : notInvited;

    newState['emailButton'] = { content: content, disabled: !occurred };

    this.setState(newState);
  }

  _indicatorClass(key) {
    switch (key) {
      case 'viewed':
      case 'watched':
        return (this.state[key].count > 0) ? 'green' : '';
        break;
      default:
        return this.state[key].occurred ? 'green' : 'brick-red';
    }
  }

  _indicatorValue(key) {
    switch (key) {
      case 'viewed':
      case 'watched':
        return this.state[key].count;
        break;
      default:
        const icon = this.state[key].occurred ? 'fa fa-check fa-2x' : 'fa fa-times fa-2x';

        return <i className={ icon } aria-hidden='true'></i>;
    }
  }

  _timeframeOfEvent(key) {
    switch (key) {
      case 'viewed':
      case 'watched':
        const properties = this.state[key].properties;
        if (properties.length) return properties[0].event_date;
        break;
      default:
        return this.state[key].date;
        break;
    }
  }

  _buildEmailButton() {
    const {
      content,
      disabled,
    } = this.state.emailButton;

    const btnClass = disabled && (content == emailButtonContent.notInvited) ? 'brick-red' : '';

    return (
      <button id='send-email-invite' className={ `btn btn-md btn-purple full-width ${btnClass}` } disabled={ disabled } onClick={ () => this._sendEmailInvite() }>
        { content }
      </button>
    );
  }

  _toggleEmailButton(action) {
    const disabled = (/disable/).test(action);
    const {
      processing,
      invited,
    } = emailButtonContent;
    const content = disabled ? processing : invited;

    this.setState({ emailButton: { content: content, disabled: disabled } });
  }

  _toggleProperties(event) {
    const propertyContainer = event.target.closest('.cha-wrapper').querySelector('.properties-container');
    const method = propertyContainer.classList.contains('closed') ? 'remove' : 'add';

    propertyContainer.classList[method]('closed');
  }

  _linkBuilder(url, content) {
    return url ? <a href={ url } target='_blank'>{ content }</a> : content;
  }

  _propertyBuilder(property, divider) {
    const {
      event_id,
      address,
      city,
      state,
      zip,
      url,
    } = property;

    return (
      <div key={ event_id } data-event-id={ event_id } className={ `property ${divider}` }>
        { address }<br />
        { city }, { state } { zip }
        { url && <i className='fa fa-external-link'></i> }
      </div>
    );
  }

  _buildProperties(key) {
    const properties = this.state[key].properties;
    const propertyDivs = [];

    if (this._hasProperties(key)) {
      properties.slice(0, MAX_PROPERTIES_TO_SHOW).forEach((property, idx) => {
        const dividerClass = (idx == properties.length - 1 || idx == MAX_PROPERTIES_TO_SHOW - 1) ? 'no-divider' : '';
        const content = this._propertyBuilder(property, dividerClass);

        propertyDivs.push(this._linkBuilder(property.url, content));
      });

      return(
        <div className='properties-container closed'>
          { propertyDivs }
        </div>
      );
    }
  }

  _hasProperties(key) {
    const properties = this.state[key].properties;

    return(Array.isArray(properties) && properties.length)
  }

  _buildLabel(key) {
    const indicatorClass = this._indicatorClass(key);
    const indicatorValue = this._indicatorValue(key);
    const timeframeOfEvent = this._timeframeOfEvent(key);
    const hasProperties = this._hasProperties(key) ? 'has-properties' : '';

    return(
      <div className={ `data-container ${hasProperties}` } onClick={ e => hasProperties ? this._toggleProperties(e) : null }>
        <div className={ `indicator ${indicatorClass}` }>{ indicatorValue }</div>
        <div className='text-label pl-5'>{ labels[key] }</div>
        <div className='date-index pr-10'><i>{ timeframeOfEvent }</i></div>
      </div>
    );
  }

  _renderData() {
    const labelDivs = [];

    Object.keys(labels).forEach(key => {
      labelDivs.push(
        <div className='cha-wrapper'>
          { this._buildLabel(key) }
          { this._buildProperties(key) }
        </div>
      );
    });

    return labelDivs;
  }

  _logError(errorType, error) {
    Bugsnag.notify(error, e => {
      e.addMetadata('context', { name: 'ComeHome Analytics', errorType: errorType });
    });
  }

  render() {
    return (
      <div className='come-home-analytics-container'>
        <div className='cha-container partner-logo-container'>
          <img className='partner-logo' src='https://agentmachine.s3.amazonaws.com/logos/ComeHome_2x.png' />
        </div>

        <div className='cha-container py-0'>
          { this._renderData() }
        </div>

        <div className='cha-container pt-15 bottom-border'>
          { this._buildEmailButton() }
        </div>
      </div>
    );
  }
}
