import React from 'react';
import { parsePhoneNumberFromString, getCountryCallingCode } from 'libphonenumber-js';

import { createSectionRow } from '../utils';

import './styles.scss';

const setSectionClass = (type) => {
  if (type) {
    const parent = document.getElementsByClassName('active-dialer-lead');
    if (parent[0]) {
      parent[0].classList.add(`type-${type}`);
    }
  }
};

const statusMap = {
  submitted_agent: 'Refer back to me',
  submitted_rex: 'Refer out to network',
};

function formatPhoneNumber(n) {
  if (!n) {
    return '-';
  }

  const { countryCallingCode, nationalNumber } = n;
  const cleaned = ('' + nationalNumber).replace(/\D/g, '');
  const regex = new RegExp(`^(\\d{3})(\\d{3})(\\d{4})$`);
  const match = cleaned.match(regex);
  if (!match) {
    return `${countryCallingCode}${nationalNumber}`;
  }
  const intlCode = `+${ countryCallingCode } `;
  return [intlCode, '(', match[1], ') ', match[2], '-', match[3]].join('');
}

function getPhoneNumber(n, c) {
  if (!n || !n.trim().length) {
    return null;
  }

  return parsePhoneNumberFromString(n, c);
}

const Agent = (props) => {
  const { data, valueOnClick } = props;

  if (!data) {
    return null;
  }

  const {
    info,
    notes,
    rawLocation,
    rawPrice,
    type,
  } = data;

  const {
    address,
    city,
    company,
    country,
    id,
    image,
    state,
    name,
    phone_office,
    phone_mobile,
  } = info;

  const phoneNumber = getPhoneNumber(phone_mobile, country);
  const formatted = formatPhoneNumber(phoneNumber);

  const forwardingType = statusMap[type] || type;
  setSectionClass(type);

  return (
    <div className='active-dialer-lead-section agent'>
      {
        createSectionRow({
          classNames: 'office-address',
          key: 'Office Address',
          section: 'agent',
          value: (() => (
            <div className='address-content'>
              <span className='agent-address'>{ address }</span>
              <span className='agent-city-state'>{city}, {state}</span>
            </div>
          ))()
        })
      }

      {
        createSectionRow({
          classNames: 'client-facing-number',
          key: 'Client-Facing Number',
          section: 'agent',
          value: formatted
        })
      }

      {
        createSectionRow({
          classNames: `type type-${type}`,
          key: 'Forwarding Type',
          section: 'agent',
          value: forwardingType
        })
      }

      {
        createSectionRow({
          classNames: 'raw-location',
          key: 'Raw Price',
          section: 'agent',
          value: rawPrice
        })
      }

      {
        createSectionRow({
          classNames: 'raw-location',
          key: 'Raw Location',
          section: 'agent',
          value: rawLocation,
          valueOnClick: valueOnClick,
        })
      }

      {
        createSectionRow({
          classNames: 'agent-notes',
          key: 'Notes from the Agent',
          section: 'agent',
          value: notes
        })
      }

    </div>
  );
};

export default Agent;
