import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import request from 'superagent';

import { isStateInCountry } from '../utils';

const isValidLocation = data => data.valid ? true : 'Invalid location';

const getLocationData = ({ city, country, state }) => {
  if (!city || !state) {
    return { valid: false };
  }

  if (country) {
    if (!isStateInCountry({ country, state })) {
      return { valid: false };
    }
  }

  return request
    .get('/qual/queue/verify_location')
    .query({ city, state: state ? state.toUpperCase() : '' })
    .then(({ body }) => body);
};

function* fetchVerifiedLocation(action) {
   try {
      const initialCheck = action.data.hasOwnProperty('lead');
      const info = initialCheck ? action.data.lead : action.data;
      const { city } = info;
      if (!city) {
        yield put({ type: 'SET_VERIFIED_LOCATION', data: { valid: false, state: false } });
      } else {
        if (!initialCheck) {
          yield delay(300);
        }
        const data = yield call(getLocationData, info);
        const isValid = isValidLocation(data);
        yield put({ type: 'SET_VERIFIED_LOCATION', data: { valid: isValid, state: isValid } });
      }
   } catch (e) {
      yield put({type: 'GET_VERIFIED_LOCATION_FAILED', message: e.message});
   }
}

function* getUpdatedLocation() {
  yield takeLatest('GET_VERIFIED_LOCATION', fetchVerifiedLocation);
}

function* initalLocation() {
  yield takeLatest('VALIDATE_INFO', fetchVerifiedLocation);
}


function* buyerCityVerifiedLocation(action) {
  try {
    yield delay(300);
    const { city, country, state, index } = action.data;
    const data = yield call(getLocationData, { city, country, state });
    const isValid = isValidLocation(data);
    yield put({ type: 'SET_BUYER_CITY_LOCATION', data: { valid: isValid, index } });
  } catch (e) {
     yield put({type: 'GET_BUYER_CITY_LOCATION_FAILED', message: e.message});
  }
}

function* getBuyerCityLocation() {
  yield takeLatest('GET_BUYER_CITY_LOCATION', buyerCityVerifiedLocation);
}

function* buyerCitiesVerifiedLocation(action) {
  try {
    const { buyer_cities, country } = action.data.lead;

    if (buyer_cities.length) {
      const data = yield all(buyer_cities.map((buyerCity, index) => call(getLocationData, { ...buyerCity, country })));
      const mapped = data.map(result => result.valid);
      yield put({ type: 'SET_BUYER_CITY_LOCATIONS', data: mapped });
    }
  } catch (e) {
     yield put({type: 'GET_BUYER_CITY_LOCATION_FAILED', message: e.message});
  }
}

function* getBuyerCitiesLocation() {
  yield takeLatest('VALIDATE_BUYER_CITY_LOCATIONS', buyerCitiesVerifiedLocation);
}

export default [
  getUpdatedLocation(),
  initalLocation(),
  getBuyerCityLocation(),
  getBuyerCitiesLocation(),
];
