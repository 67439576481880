import { Component }          from 'react';
import request                from 'superagent';
import UserDetails            from './UserDetails';
import SalesExecutives        from './SalesExecutives';
import AgentSlots             from './AgentSlots';
import TabsContainer          from './TabsContainer';
import SalesExecutiveModal    from './modals/SalesExecutiveModal';
import AgentsModal            from './modals/AgentsModal';
import NewNoteModal           from './modals/NewNoteModal';
import WishlistModal          from './modals/WishlistModal';
import WishlistSearchModal    from './modals/WishlistSearchModal';
import WorkingListModal       from './modals/WorkingListModal';

export default class AgentInsight extends Component {
  constructor(props){
    super(props)
    this.state = this.initialState();
    this.contactFields = Object.freeze(['email', 'phone_mobile']);
    this.officeFields = Object.freeze([
      'brand',
      'address_street',
      'address_city',
      'address_state',
      'address_zip'
    ])
    this.billingFields = Object.freeze([
      'billing_first_name',
      'billing_last_name',
      'billing_address',
      'billing_city',
      'billing_state',
      'billing_zip_code',
      'billing_phone_number',
      'billing_email'
    ])

    this.addSlot                      = this.addSlot.bind(this);
    this.addToWishList                = this.addToWishList.bind(this);
    this.billingSynced                = this.billingSynced.bind(this);
    this.contactSynced                = this.contactSynced.bind(this);
    this.createNote                   = this.createNote.bind(this);
    this.fetchFeed                    = this.fetchFeed.bind(this);
    this.officeSynced                 = this.officeSynced.bind(this);
    this.removeAgent                  = this.removeAgent.bind(this);
    this.removeSalesExecutive         = this.removeSalesExecutive.bind(this);
    this.removeSlot                   = this.removeSlot.bind(this);
    this.removeWish                   = this.removeWish.bind(this);
    this.searchWishList               = this.searchWishList.bind(this);
    this.submitInviteEmail            = this.submitInviteEmail.bind(this);
    this.submitUpdatedSalesManager    = this.submitUpdatedSalesManager.bind(this);
    this.updateRexManager             = this.updateRexManager.bind(this);
    this.updateState                  = this.updateState.bind(this);
    this.loadWorkingList              = this.loadWorkingList.bind(this);
  }

  initialState(){
    return {
      currentUserId: this.props.currentUserId,
      salesManagerId: this.props.salesManagerId,
      salesManager: {},
      salesExecutives: [],
      titleAccountManagers: {},
      showTitleAccountManagers: this.props.showTitleAccountManagers,
      agents: [],
      metrics: {
        slots: 0
      },
      opsNotes: [],
      userInvitations: [],
      feed: [],
      feedFilter: '',
      feedCurrentPage: 1,
      feedTotalPages: 1,
      agentWishLists: [],
      workingLists: [],
      initSalesManager: {},
      removedSalesExecutive: {},
      removedAgent: {},
      updating: false,
      showExecutiveModal: false,
      showAgentModal: false,
      showAddNoteModal: false,
      showAddToWishListModal: false,
      showWishListSearchModal: false,
      showWorkingListModal: false,
      emailInviteInput: '',
      noteMessage: '',
      tab: 'working-list',
      wishlistSearchQuery: '',
      wishlistSearchQueryResults: [],
      selectedWishlist: {}
    };
  }

  componentDidMount(){
    let { salesManagerId } = this.props;
    document.title = 'Agent Insight';

    let options = { sales_manager_id: salesManagerId };

    request
      .get(`/agent_insights/sales_managers/fetch/${salesManagerId}`)
      .then(({ body }) => {
        this.setState({
          salesManager: body.sales_manager,
          initSalesManager: Object.assign({}, body.sales_manager),
          metrics: body.metrics
        });
      });

    request
      .get('/agent_insights/sales_executives')
      .query(options)
      .then(({ body }) => {
        this.setState({ salesExecutives: body || [] })
      });

    request
      .get('/agent_insights/agents')
      .query(options)
      .then(({ body }) => {
        this.setState({ agents: body || [] });
      });

    request
      .get('/agent_insights/ops_notes')
      .query(options)
      .then(({ body }) => {
        this.setState({ opsNotes: body || [] });
      });

    request
      .get('/agent_insights/user_invitations')
      .query(options)
      .then(({ body }) => {
        this.setState({ userInvitations: body || [] });
      });

    this.loadWorkingList();

    request
      .get('/agent_insights/agent_wishlist')
      .query(options)
      .then(({ body }) => {
        this.setState({ agentWishLists: body || [] });
      });

    request
      .get('/agent_insights/feeds/generate_feed')
      .query(options)
      .then(({ body }) => {
        this.setState({
          feed: body.feeds || [],
          feedTotalPages: body.page_count
        });
      });

    request
      .get('/users/title_account_managers')
      .then(({ body }) => {
        this.setState({ titleAccountManagers: body.titleAccountManagers });
      });
  }

  loadWorkingList(){
    request
      .get('/agent_insights/monitors/working_list')
      .query({ sales_manager_id: this.props.salesManagerId })
      .then(({ body }) => {
        this.setState({ workingLists: body || [] });
      });
  }

  updateState(field, value){
    this.setState({ [field]: value })
  }

  updateState(hash){
    this.setState(hash)
  }

  submitUpdatedSalesManager(){
    this.setState({ updating: true });
    let options = { sales_manager: this.state.salesManager };

    delete options.sales_manager.is_super_manager;
    request
      .post(`/agent_insights/sales_managers/${this.state.salesManager.id}`)
      .query(options)
      .then(() => {
          this.setState({
          updating: false,
          initSalesManager: Object.assign({}, this.state.salesManager)
        })
      });
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  submitInviteEmail(){
    let { emailInviteInput, salesManager, userInvitations } = this.state;
    if (!emailInviteInput) return;
    if(!this.validateEmail(emailInviteInput)){
      alert('Please check the email entered!');
      return;
    }
    let options = {
      sales_manager_id: salesManager.id,
      user_invitations: {
        admin_id: salesManager.id,
        email: emailInviteInput
      }
    }
    request
      .post('/agent_insights/user_invitations/invite_user')
      .query(options)
      .then(({ body }) => {
        userInvitations.push(body);
        this.setState({ userInvitations });
      });
  }

  createNote(){
    let { noteMessage, salesManager } = this.state;
    if(!noteMessage) return;

    this.setState({ showAddNoteModal: false })

    request
      .post('/agent_insights/ops_notes/create_note')
      .query({ message: noteMessage, user_id: salesManager.user_id })
      .then(() => this.setState({ noteMessage: '' }));
  }

  updateRexManager(id){
    request
      .post('/agent_insights/sales_managers/assign_account_manager')
      .query({ sales_manager_id: this.state.salesManager.id, account_manager_id: id })
      .then(({ body }) => {
        this.setState({
          salesManager: body.sales_manager,
          initSalesManager: Object.assign({}, body.sales_manager),
          metrics: body.metrics
        });
      })
  }

  addToWishList(agent){
    let { salesManager, agentWishLists } = this.state;
    request
      .post('/agent_insights/agent_wishlist/add_agent_to_wishlist')
      .query({ sales_manager_id: salesManager.id, agent })
      .then(({ body }) => {
        agentWishLists.push(body);
        this.setState({ agentWishLists });
      });
  }

  removeWish(id){
    if(confirm('Do you want to delete this wish?')){
      request
        .post('/agent_insights/agent_wishlist/remove_agent_from_wishlist')
        .query({ wishlist_id: id, sales_manager_id: this.state.salesManagerId })
        .then(({ body }) => {
          this.setState({ agentWishLists: body, emailInviteInput: '' })
        });
    }
  }

  addSlot() {
    return request
      .post('/agent_insights/sales_managers/add_slot')
      .query({ sales_manager_id: this.state.salesManagerId })
      .then(({ body }) => this.setState({ metrics: body.metrics }));
  }

  removeSlot(){
    return request
      .post('/agent_insights/sales_managers/remove_slot')
      .query({ sales_manager_id: this.state.salesManagerId })
      .then(({ body }) => this.setState({ metrics: body.metrics }));
  }

  removeSalesExecutive(execIdx){
    let { salesExecutives, removedSalesExecutive} = this.state;

    return request
      .post('/agent_insights/sales_executives/disable_sales_executive/')
      .query({ sales_executive_id: salesExecutives[execIdx].id })
      .then(({ body }) => {
        if(body && Object.keys(body).length){
          alert('Sales executive removal failed!')
        } else {
          removedSalesExecutive = Object.assign({}, salesExecutives[execIdx]);
          salesExecutives.splice(execIdx, 1)
          this.setState({ salesExecutives, removedSalesExecutive });
        }
      });
  }

  removeAgent(agentIdx) {
    let { agents, removedAgent, salesManager } = this.state;

    return request
      .post('/agent_insights/agents/remove_subscribed_agent/')
      .query({ sales_manager_id: salesManager.id, agent_id: agents[agentIdx].id })
      .then(({ body }) => {
        if (body && Object.keys(body).length) {
          alert('Syndication removal failed!')
        } else {
          removedAgent = Object.assign({}, agents[agentIdx]);
          agents.splice(agentIdx, 1)
          this.setState({ agents, removedAgent });
        }
      });
  }

  synced(list){
    let synced = true;
    let { salesManager, initSalesManager } = this.state;

    list.forEach((field) => {
      if ((salesManager[field] !== initSalesManager[field]) && (salesManager[field] || initSalesManager[field])) {
        synced = false;
      }
    });
    return synced;
  }

  contactSynced(){ return this.synced(this.contactFields); }

  officeSynced(){ return this.synced(this.officeFields); }

  billingSynced(){ return this.synced(this.billingFields); }

  searchWishList(searchQuery) {
    this.setState({ showWishListSearchModal: true, wishlistSearchQuery: searchQuery })
    $.get('/agent_insights/agent_wishlist/search', { query: searchQuery }, (wishlists) => {
      if (wishlists) this.setState({ wishlistSearchQueryResults: wishlists })
    })
  }

  fetchFeed(option) {
    let { feedTotalPages, feedCurrentPage } = this.state;
    let options = { sales_manager_id: this.props.salesManagerId, filter: this.state.feedFilter };

    if(option === 'first' && feedCurrentPage === 1) return;
    if(option === 'prev' && feedCurrentPage === 1) return;
    if(option === 'next' && feedCurrentPage === feedTotalPages) return;
    if(option === 'last' && feedCurrentPage === feedTotalPages) return;

    switch(option){
      case 'first':
        options.page = 1;
        this.setState({ feedCurrentPage: 1 });
        break;
      case 'last':
        options.page = feedTotalPages;
        this.setState({ feedCurrentPage: feedTotalPages });
        break;
      case 'next':
        options.page = feedCurrentPage + 1;
        this.setState({ feedCurrentPage: options.page });
        break;
      case 'prev':
        options.page = feedCurrentPage - 1;
        this.setState({ feedCurrentPage: options.page });
        break;
      default:
        // Changes the feed filter
        this.setState({ feedFilter: option, feedCurrentPage: 1 });
        options.filter = option;
    }

    $.get('/agent_insights/feeds/generate_feed', options, res => {
      this.setState({
        feedTotalPages: res.page_count,
        feed: res.feeds || []
      })
    });
  }

  render(){
    return(
      <div className='sales-manager-container-inner'>
        <div className="manager-view">
          <UserDetails
            state={this.state}
            updateState={this.updateState}
            submitUpdatedSalesManager={this.submitUpdatedSalesManager}
            contactSynced={this.contactSynced()}
            officeSynced={this.officeSynced()}
            updateRexManager={this.updateRexManager}
          />
          <SalesExecutives state={this.state} updateState={this.updateState} />
          <AgentSlots state={this.state} updateState={this.updateState} />
          <TabsContainer
            state={this.state}
            updateState={this.updateState}
            removeWish={this.removeWish}
            submitInviteEmail={this.submitInviteEmail}
            fetchFeed={this.fetchFeed}
            submitUpdatedSalesManager={this.submitUpdatedSalesManager}
            billingSynced={this.billingSynced()}
            searchWishList={this.searchWishList}
          />
        </div>
        <SalesExecutiveModal
          state={this.state}
          updateState={this.updateState}
          removeSalesExecutive={this.removeSalesExecutive}
        />
        <AgentsModal
          state={this.state}
          updateState={this.updateState}
          removeAgent={this.removeAgent}
          removeSlot={this.removeSlot}
          addSlot={this.addSlot}
        />
        <NewNoteModal
          state={this.state}
          updateState={this.updateState}
          createNote={this.createNote}
        />
        <WishlistModal
          state={this.state}
          updateState={this.updateState}
          addToWishList={this.addToWishList}
        />
        <WishlistSearchModal
          state={this.state}
          updateState={this.updateState}
          searchWishList={this.searchWishList}
        />
        <WorkingListModal
          state={this.state}
          updateState={this.updateState}
          loadWorkingList={this.loadWorkingList}
        />
      </div>
    )
  }
}
