import ManagementIcon from '../../../LiveAccountManagement/images/live_management.png';

const _buildButton = (props, data, index) => {
  const {
    setAndGo,
  } = props;

  return(
    <button
      key={ index }
      className='rex-modal-action'
      onClick={ () => setAndGo('action', data.action, data.view) }
    >
      { data.text }
    </button>
  );
};

const _renderButtons = (props) => {
  const {
    featureEnabled,
    planCancelled,
    setAndGo,
  } = props;

  const buttons = [];
  const buttonData = [];

  if (featureEnabled) {
    if (planCancelled) {
      buttonData.push({ text: 'REACTIVATE SUBSCRIPTION', view: 'warning', action: 'reactivate' });
    } else {
      buttonData.push({ text: 'CANCEL SUBSCRIPTION', view: 'warning', action: 'cancel' });
    }

    buttonData.push({ text: 'DISABLE PRIME', view: 'warning', action: 'disable' });
    buttonData.push({ text: 'CHANGE SUBSCRIPTION', view: 'change', action: 'change' });
  } else {
    setAndGo('action', 'enable', 'warning');
  }

  buttonData.forEach((data, index) => {
    buttons.push(_buildButton(props, data, index));
  });

  return(
    <div className='rex-modal-actions'>
      { buttons }
    </div>
  );
};

const AccountManagement = (props) => {
  return (
    <div id='account-management'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ ManagementIcon } />
      </div>

      <div className='rex-modal-heading'>
        PRIME Account Management
      </div>

      { _renderButtons(props) }
    </div>
  )
};

export default AccountManagement;
