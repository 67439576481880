import  { Component } from  'react';
import  request       from  'superagent';
import * as Phone from '@referralexchange/phone';
import querystring from 'querystring';

import LeadType                from './LeadType';
import LeadService             from './LeadService';
import LeadStatus              from './LeadStatus';
import LeadSellerPropertyInfo  from './LeadSellerPropertyInfo';
import LeadBuyerPropertyInfo   from './LeadBuyerPropertyInfo';
import LeadPersonalInfo        from './LeadPersonalInfo';
import LiveLeadType            from './LiveLeadType';
import LiveLeadInfo            from './LiveLeadInfo';
import LiveAgentSearch         from './LiveAgentSearch';
import ChooseExclusiveAgent    from './ChooseExclusiveAgent';
import ExclusiveAgentQuestions from './ExclusiveAgentQuestions';

import { particleInit } from '../CreateCanadianAgent/particleEffect'

const QualQueueLiveBasePath = `/qual/compass`;


const REA_MICROSITE_ID = 917;
export default class NewLeadModal extends Component {
  constructor(props){
    super(props);

    this.initialState = {
      leadType           : null,
      leadStatus         : null,
      leadService        : null,
      leadServiceType    : null,
      liveAgentSearch    : '',
      liveAgentSearchState: null,
      liveAgentSearchResults: [],
      liveAgentName      : '',
      liveAgentId        : '',
      submittingToQual   : false,
      requestType        : null,
      firstName          : '',
      lastName           : '',
      email              : '',
      languagePreference : 'en',
      phoneNumber        : '',
      phoneNumberNational: '',
      comments           : null,
      microsite          : REA_MICROSITE_ID,
      currentAgent       : null,
      currentPage        : 'leadType',
      streetNumber       : null,
      streetName         : null,
      unitNumber         : null,
      city               : null,
      state              : null,
      zip                : null,
      country            : 'US',
      homePrice          : null,
      beds               : null,
      baths              : null,
      timeFrame          : null,
      mortgageStatus     : "none",
      typeHome           : null,
      reasonForSelling   : null,
      nearbyCities       : [],
      foundCities        : [],
      submittingLead     : false,
      reason             : null,

      isQualContactBonus : false,
      isLFU              : false,
      isTvAd             : false,
      isExclusive        : false,

      originCallID              : null, // ID of the inbound analytics.call that originated this lead
      originatedFromInboundCall : false,

      onActivePhoneCall         : false,
      activePhoneCallDetails    : null,

      responseErrors     : {},
      formData           : this.props.formData,

      rental     : 'false',

      exclusiveAgentSearch    : '',
      exclusiveAgentSearchState: null,
      exclusiveAgentSearchResults: [],
      forceMatchExclusively: false,
      workingWithAgentIds:   [],
      forceAgentIds:   [],
      messageSubject: null,
    };

    // Initialize the connection to our phone server
    try {
      this.phoneClient = new Phone.Client({
        portType:           Phone.PortType.EmbeddedWeb,
        // this has to be hardcoded
        remoteExtensionID:  'ngjhphgjojadbfihoaleohdbnekniddo'
      });
    } catch(err) {
      console.error('Could not initialize @referralexchange/phone client.', err);
    }

    this.state                          = this.initialState;
    this.setState                       = this.setState.bind(this);
    this.submitLead                     = this.submitLead.bind(this);
    this.validateLead                   = this.validateLead.bind(this);
    this.nextPage                       = this.nextPage.bind(this);
    this.backPage                       = this.backPage.bind(this);
    this.closeModal                     = this.closeModal.bind(this);
    this.handleChange                   = this.handleChange.bind(this);
    this.handleLeadTypeChange           = this.handleLeadTypeChange.bind(this);
    this.handleToggle                   = this.handleToggle.bind(this);
    this.handleLeadStatusChange         = this.handleLeadStatusChange.bind(this);
    this.handleNearbyCitiesChange       = this.handleNearbyCitiesChange.bind(this);
    this.handleFoundCitiesSet           = this.handleFoundCitiesSet.bind(this);
    this.handleResponseErrors           = this.handleResponseErrors.bind(this);
    this.submitToQual                   = this.submitToQual.bind(this);
    this.submitAndRedirectToQual        = this.submitAndRedirectToQual.bind(this);
    this.waitForTrainCompletion         = this.waitForTrainCompletion.bind(this);
    this.handleIsExclusive              = this.handleIsExclusive.bind(this);
    window.displayNewLeadModal          = this.displayNewLeadModal.bind(this);
  }

  statesAndprovinces = {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AS': 'America Samoa',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'DC': 'District of Columbia',
    'FM': 'Federated States Of Micronesia',
    'FL': 'Florida',
    'GA': 'Georgia',
    'GU': 'Guam',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MH': 'Marshall Islands',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PW': 'Palau',
    'PA': 'Pennsylvania',
    'PR': 'Puerto Rico',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VI': 'Virgin Island',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming',
    'AB': 'Alberta',
    'BC': 'British Columbia',
    'MB': 'Manitoba',
    'NB': 'New Brunswick',
    'NF': 'Newfoundland',
    'NT': 'Northwest Territory',
    'NS': 'Nova Scotia',
    'NU': 'Nunavut',
    'ON': 'Ontario',
    'PE': 'Prince Edward Island',
    'QC': 'Quebec',
    'SK': 'Saskatchewan',
    'YT': 'Yukon'
  }

  componentWillMount() {
    this.initializePhoneEventHandlers();
  }

  componentDidMount() {
    $('#new-lead-modal > .main-modal-panel').draggable();
    this.displayNewLeadModal
  }

  /**
   * handle events
   */
  initializePhoneEventHandlers() {
    if(!this.phoneClient) {
      console.warn('skipping initialization of phone event handlers since the phone client has not initialized...')
      return
    }

    this.checkActiveCallStatus();

    this.phoneClient.events.on('connect', (connection) => {
      this.setState({
        onActivePhoneCall:      true,
        activePhoneCallDetails: connection
      })

      if(this.state.phoneNumber && this.state.phoneNumber.length === 0) {
        this.setState({ phoneNumber: connection.number })
      }
    });

    this.phoneClient.events.on('disconnect', () => { this.setState({ onActivePhoneCall: false, activePhoneCallDetails: null }) });
  }

  displayNewLeadModal({isExclusive, currentAgent}) {
    const forceAgentIds = !!currentAgent ? [currentAgent.id] : [];
    const city = !!currentAgent ? currentAgent.city : null;
    const state = !!currentAgent ? this.statesAndprovinces[currentAgent.state] : null;
    const forceMatchExclusively = !!currentAgent ? true : false;
    this.setState({
      currentAgent,
      isExclusive,
      microsite: REA_MICROSITE_ID,
      forceAgentIds,
      forceMatchExclusively,
      city,
      state,
    })
  }

  checkActiveCallStatus() {
    if(!this.phoneClient) {
      console.warn('cannot check active call status due to missing phone client');
      return
    }

    // Get state of active phone call
    this.phoneClient.getActiveConnection().then(({ connection, isConnected }) => {
      if(isConnected) {
        this.setState({
          onActivePhoneCall:      true,
          activePhoneCallDetails: connection
        })

        if(this.state.phoneNumber && this.state.phoneNumber.length === 0) {
          this.setState({ phoneNumber: connection.number })
        }
      }
    });
  }

  handleChange(param, e){
    let value = e.currentTarget.value;
    this.setState({ [param]: value });
  }

  handleToggle(param){
    this.setState({ [param]: !this.state[param] });
  }

  handleIsExclusive(e){
    this.setState({
      isExclusive: e.target.checked,
    });
  }

  handleLeadTypeChange(leadType){
    const messageSubject = leadType === 'buyer' ? 'buying' : 'selling';
    this.setState({
      leadType,
      messageSubject,
    });
    this.nextPage();
  }

  handleLeadStatusChange(param){
    this.setState({ leadStatus: param});
  }

  handleNearbyCitiesChange(city){
    let foundCity = false;
    // Does city exist in current array
    this.state.nearbyCities.forEach(function(bc) {
      if (bc.city == city.city) {
        foundCity = true;
      }
    });

    // if no, add to nearbyCities, else remove by creating new array without that city
    if (foundCity == false) {
      this.state.nearbyCities.push({city: city.city, state: city.state})
      this.setState({ nearbyCities: this.state.nearbyCities});
    } else {
      var newBuyerCities = []
      this.state.nearbyCities.forEach(function(bc) {
        if (bc.city != city.city) {
          newBuyerCities.push({city: bc.city, state: bc.state});
        };
      });
      this.setState({nearbyCities: newBuyerCities});
    }
  }

  handleFoundCitiesSet(cities){
    this.setState({foundCities:  cities});
  }

  handleResponseErrors(errors){
    this.setState({responseErrors: errors});
  }

  renderCurrentPage(){
    let { currentPage, country, nearbyCities, foundCities, formData, isQualContactBonus, isLFU, isTvAd, originCallID, leadType, leadStatus, leadService, leadServiceType, homePrice, beds, baths, mortgageStatus, typeHome, timeFrame, reasonForSelling, streetNumber, streetName, unitNumber, city, state, zip, firstName, lastName, email, languagePreference, phoneNumber, comments, microsite, isExclusive, currentAgent, forceMatchExclusively, workingWithAgentIds } = this.state;

    switch(currentPage) {
      case 'leadType':
        return (
          <LeadType
            leadType             = {leadType}
            handleLeadTypeChange = {this.handleLeadTypeChange}
            isExclusive          = {isExclusive}
            handleIsExclusive    = {this.handleIsExclusive}
          />
        )
      case 'leadService':
          return (
            <LeadService
              state={this.state}
              setState={this.setState}
            />
          )
      case 'chooseExclusiveAgent':
          return (
            <ChooseExclusiveAgent
            state={this.state}
            setState={this.setState}
            statesAndprovinces={this.statesAndprovinces}
            />
          )
      case 'exclusiveAgentQuestions':
          return (
            <ExclusiveAgentQuestions
              state={this.state}
              setState={this.setState}
              currentAgent={currentAgent}
              forceMatchExclusively={forceMatchExclusively}
              handleToggle={this.handleToggle}
              workingWithAgentIds={workingWithAgentIds}
            />
          )
      case 'personalInfo':
        return (
          <LeadPersonalInfo
            firstName          = {firstName}
            lastName           = {lastName}
            email              = {email}
            phoneNumber        = {phoneNumber}
            languagePreference = {languagePreference}
            comments           = {comments}
            microsite          = {microsite}
            isQualContactBonus = {isQualContactBonus}
            isLFU              = {isLFU}
            isTvAd             = {isTvAd}
            originCallID       = {originCallID}
            handleToggle       = {this.handleToggle}
            handleChange       = {this.handleChange}
            formData           = {formData}
            isExclusive        = {isExclusive}
          />
        )
      case 'sellerInfo':
        return (
          <LeadSellerPropertyInfo
            streetNumber     = {streetNumber}
            streetName       = {streetName}
            unitNumber       = {unitNumber}
            city             = {city}
            state            = {state}
            zip              = {zip}
            country          = {country}
            typeHome         = {typeHome}
            timeFrame        = {timeFrame}
            homePrice        = {homePrice}
            reasonForSelling = {reasonForSelling}
            handleChange     = {this.handleChange}
            formData         = {formData}
            microsite        = {microsite}
          />
        );
      case 'buyerInfo':
        return (
          <LeadBuyerPropertyInfo
            city                     = {city}
            state                    = {state}
            country                  = {country}
            homePrice                = {homePrice}
            beds                     = {beds}
            baths                    = {baths}
            timeFrame                = {timeFrame}
            mortgageStatus           = {mortgageStatus}
            nearbyCities             = {nearbyCities}
            foundCities              = {foundCities}
            handleResponseErrors     = {this.handleResponseErrors}
            handleNearbyCitiesChange = {this.handleNearbyCitiesChange}
            handleFoundCitiesSet     = {this.handleFoundCitiesSet}
            handleChange             = {this.handleChange}
            formData                 = {formData}
          />
        )
      case 'leadStatus':
        return (
          <LeadStatus
            leadStatus               = { leadStatus }
            handleLeadStatusChange   = { this.handleLeadStatusChange }
          />
        )
      case 'liveLeadType':
        return <LiveLeadType setState={this.setState} />
      case 'liveLeadInfo':
        return (
          <LiveLeadInfo
            state={this.state}
            setState={this.setState}

            checkActiveCallStatus = { this.checkActiveCallStatus.bind(this) }
          />
        );
      case 'liveAgentSearch':
        return (
          <LiveAgentSearch
            state={this.state}
            setState={this.setState}
          />
        )
    }
  }

  nextPage(){
    let page;
    const { currentPage, leadService, leadType, isExclusive } = this.state;

    if(['personalInfo', 'sellerInfo', 'buyerInfo'].includes(currentPage)) {
      this.validateLead();
      return;
    }
    switch(currentPage) {
      case 'leadType':
        page = isExclusive
          ? 'chooseExclusiveAgent'
          : 'leadService'; break;
      case 'chooseExclusiveAgent':
        page = 'exclusiveAgentQuestions'; break;
      case 'exclusiveAgentQuestions':
        page = 'personalInfo'; break;
      case 'leadService':
        page = leadService === 'live'
          ? 'liveLeadType'
          : 'personalInfo'; break;
      case 'personalInfo':
        page = leadType === 'seller'
          ? 'sellerInfo'
          : 'buyerInfo'; break;
      case 'sellerInfo':
      case 'buyerInfo':
        page = 'leadStatus'; break;
      case 'liveLeadType':
        page = 'liveLeadInfo'; break;
      case 'liveAgentSearch':
        page = 'liveLeadInfo'; break;
      default:
        page = 'leadType';
    }

    this.setState({ currentPage: page })
  }

  backPage(){
    const { currentPage, leadType, isExclusive } = this.state;
    let page;

    if (['leadType', 'leadService'].includes(currentPage)) return;

    if (['personalInfo', 'sellerInfo', 'buyerInfo'].includes(currentPage)) {
      this.setState({responseErrors: {}});
    }
    switch (currentPage) {
      case 'leadService':
        page = 'leadType'; break;
      case 'chooseExclusiveAgent':
        page = 'leadType'; break;
      case 'exclusiveAgentQuestions':
        page = 'chooseExclusiveAgent'; break;
      case 'personalInfo':
        page = isExclusive
          ? 'exclusiveAgentQuestions'
          : 'leadService'; break;
      case 'sellerInfo':
        page = 'personalInfo'; break;
      case 'buyerInfo':
        page = 'personalInfo'; break;
      case 'leadStatus':
        page = leadType === 'seller' ? 'sellerInfo': 'buyerInfo'; break;
      case 'liveLeadType':
        page ='leadService'; break;
      case 'liveLeadInfo':
        page = 'liveLeadType'; break;
      case 'liveAgentSearch':
        page = 'liveLeadInfo'; break;
    }
    this.setState({ currentPage: page });
  }

  closeModal(){
    $('#new-lead-modal').addClass('hidden')
    this.setState(this.initialState);
  }

  getPageTitle() {
    switch(this.state.currentPage) {
      case 'leadType':
        return 'Select Lead Type';
      case 'chooseExclusiveAgent':
        return 'Choose Exclusive Agent';
      case 'exclusiveAgentQuestions':
        return 'Exclusive Agent Questions';
      case 'leadService':
        return 'Select Lead Service';
      case 'personalInfo':
        return 'Personal Info';
      case 'leadStatus':
        return 'Lead Status';
      case 'sellerInfo':
        return 'Seller Property Info';
      case 'buyerInfo':
        return 'Buyer Property Info';
      case 'liveLeadType':
        return 'Select LIVE Lead Partnership';
      case 'liveLeadInfo':
        return 'Enter Lead Information';
      case 'liveAgentSearch':
        return 'Search Results';
    }
  }

  validateLead() {
    let nextPage;

    const {
      leadType,
      currentPage,
    } = this.state;

    if (/buyerInfo|sellerInfo/.test(currentPage)) {
      nextPage = 'leadStatus';
    } else {
      nextPage = leadType === 'buyer' ? 'buyerInfo' : 'sellerInfo';
    }

    this.setState({ requestType: 'validate' }, function() {
      request
      .post('/api/leads')
      .send(this.leadPayload())
      .then(function({ body }) {
        let { errors = {} } = body;
        let errorCount = Object.keys(errors).length;

        if(errorCount > 0) {
          // Remove page specific errors
          if (currentPage === 'personalInfo') {
            ['street_name', 'street_number', 'city', 'state', 'home_price_max', 'zip', 'lead_type','buyer_cities'].forEach(function(key) {
              delete errors[key]
            });
          }

          let errorCount = Object.keys(errors).length;

          if (errorCount > 0) {
            this.setState({ responseErrors: errors });
          } else {
            this.setState({ responseErrors: {}, currentPage: nextPage });
          }
        } else if (errorCount == 0) {
          // No initial errors, proceed
          this.setState({ responseErrors: {}, currentPage: nextPage });
        }
      }.bind(this))
      .catch(error => {
        console.error(error);

        let response = JSON.parse(error.response);
        this.setState({ responseErrors: response.errors });
      })
    })
  }

  submitLead() {
    const {
      leadType,
      leadStatus,
      submittingLead,
    } = this.state;

    if (leadStatus === null) {
      this.setState({ responseErrors: { lead_status: ["Can't be blank"] } });
      return
    }

    this.setState({ requestType: 'submit' }, function() {
      if (submittingLead == false) {
        this.setState({ submittingLead: true });

        request
        .post('/api/leads')
        .send(this.leadPayload())
        .then(function({ body }) {
          let { success, errors, request_uuid } = body;

          if(errors) {
            if (leadType === 'buyer') {
              delete errors['buyer_cities']
            } else if (leadType === 'seller') {
              if (errors['zip']) {
                errors['zip'] = ['Please provide a zip code.']
              }
            }

            if (errors['home_price_max']) {
              errors['price'] = errors['home_price_max']
              delete errors['home_price_max']
            }

            this.setState({ responseErrors: errors, submittingLead: false });
          } else {
            let leadURL = `/leads/${success.lead_uuid}?request_uuid=${request_uuid}`;
            window.open(leadURL, '_blank');
            this.closeModal();
          }
        }.bind(this))
        .catch(error => {
          console.error(error);

          let response = JSON.parse(error.response);
          this.setState({ responseErrors: response.errors, submittingLead: false });
        })
      }
    })
  }

  extractPriceRange(attr) {
    if (this.state.homePrice) {
      let priceRange = JSON.parse(atob(this.state.homePrice));

      return priceRange[attr];
    }
  }

  leadPayload() {
    let payload = {
      lead: {
        type            : this.state.leadType,
        request_type    : this.state.requestType,
        referral_fee    : '',
        max_agents      : '',
        street_number   : this.state.streetNumber,
        street_name     : this.state.streetName,
        unit_number     : this.state.unitNumber,
        city            : this.state.city,
        state           : this.state.state,
        zip             : this.state.zip,
        country         : this.state.country,
        home_price_min  : this.extractPriceRange('min'),
        home_price_max  : this.extractPriceRange('max'),
        beds            : this.state.beds,
        baths           : this.state.baths,
        time_frame      : this.state.timeFrame,
        mortgage_status : this.state.mortgageStatus,
        type_home       : this.getTypeHomeValue(this.state.typeHome),
        reason          : this.state.reasonForSelling,
        first_name      : this.state.firstName,
        last_name       : this.state.lastName,
        language_preference : this.state.languagePreference,
        forced_lead_status  : this.state.leadStatus,
        phone_number    : this.state.phoneNumber,
        email           : this.state.email,
        microsite_id    : this.state.microsite,
        comments        : this.state.comments,
        buyer_cities    : this.state.nearbyCities,
        qual_contacted  : this.state.isQualContactBonus ? '1'         : '0',
        lfu             : this.state.isLFU ? '1'                      : '0',
        tv_ad           : this.state.isTvAd ? '1' : '0',
        origin_call_id  : this.state.originCallID,
        originated_from_inbound_call : this.state.originatedFromInboundCall,
        force_agent_ids: this.state.forceAgentIds,
        working_with_agent_ids: this.state.workingWithAgentIds,
        force_match_exclusively: this.state.forceMatchExclusively,
        message_subject: this.state.messageSubject,
      }
    }

    if (this.state.leadType == 'buyer') {
      delete payload.lead.type_home;
    }

    return payload;
  }

  addPeriods(errors) {
    if (errors.length == 1) {
      if (errors[0].slice(-1) === '.') {
        return errors[0];
      } else {
        return errors[0] + '.';
      }
    } else {
      let errorString = errors.join(' ');
      if (errorString.slice(-1) === '.') {
        return errorString;
      } else {
        return errorString + '.';
      }
    }
  }

  getTypeHomeValue(string) {
    let foundValue = this.props.formData.seller.type_of_home.filter(arr => arr[0] == string);
    if (foundValue.length == 0) {
      return 'unknown';
    } else if (foundValue[0][0] == 'Unknown') {
      return 'unknown';
    } else {
      return foundValue[0][1];
    }
  }

  validForQual() {
    const {
      liveAgentId,
      firstName,
      lastName,
      email,
      phoneNumber,
      reason,
      leadServiceType,

    } = this.state;

    const isCompass = leadServiceType === '899';

    const fields = [liveAgentId, firstName, lastName, email, phoneNumber];
    if (isCompass) {
      fields.push(reason);
    }

    const empty = field => (!field);

    if(fields.some(empty)) return false;

    return true;
  }

  validForImmediateQual() {
    // we can only move on to immediate qual if we're actively on the phone with the client
    return this.state.onActivePhoneCall && this.validForQual();
  }

  renderErrors() {
    let keys = Object.keys(this.state.responseErrors);

    return keys.map((key) => {
      return <li className='error-text'><strong>{key.replace('_',' ').toUpperCase()}:</strong> {this.addPeriods(this.state.responseErrors[key])}</li>;
    });
  }

  renderNextButton() {
    const { currentPage, currentAgent } = this.state;
    if (['leadStatus'].includes(currentPage)) {
      return (
        <div className='col-md-7'>
          <button className='step-button float-button' onClick={this.submitLead}>SUBMIT</button>
        </div>
      )
    } else if (currentPage === 'liveLeadInfo') {
      return (
        <div className='col-md-7'>
          <button className='step-button float-button' disabled={!this.validForQual()} onClick={this.submitToQual}>FINISH</button>
          <button className='step-button float-button' disabled={!this.validForImmediateQual()} onClick={ this.submitAndRedirectToQual }>OPEN IN QUAL</button>
        </div>
      )
    }
    else if ((currentPage === 'chooseExclusiveAgent' && !!currentAgent) || !['leadType', 'leadService', 'chooseExclusiveAgent'].includes(currentPage) || ['sellerInfo', 'buyerInfo'].includes(currentPage)) {
      return (
        <div className='col-md-7'>
          <button className='step-button float-button' onClick={this.nextPage}>NEXT</button>
        </div>
      )
    }
  }

  renderBackButton() {
    if (!['leadType', 'leadService'].includes(this.state.currentPage)) {
      return (
        <div className='col-md-4'>
        <button className='back-button' onClick={this.backPage}>BACK</button>
      </div>
      )
    }
  }

  qualPayload(additionalProp) {
    const {
      firstName,
      lastName,
      email,
      leadType,
      phoneNumber,
      liveAgentId,
      leadServiceType,
      reason,
      rental
    } = this.state;

    const basePayload = {
      first_name: firstName,
      last_name: lastName,
      email,
      lead_type: leadType,
      phone_number: phoneNumber,
      sending_agent_id: liveAgentId,
      microsite_id: leadServiceType,
      rental,
      reason
    };

    return { ...basePayload, ...additionalProp };
  }

  submitToQual() {
    request.post('/api/live_leads')
      .query(this.qualPayload({ qual_now: false }))
      .then(({ body }) => {
        if(body.errors) {
          console.warn(body.errors);
        } else {
          window.open(`/leads/${body.lead_uuid}?request_uuid=${body.request_uuid}`, '_blank');
          this.closeModal();
        }
      });
  }

  submitAndRedirectToQual() {
    request.post('/api/live_leads')
      .query(this.qualPayload({ qual_now: true }))
      .then(({ body }) => {
        body.errors
          ? console.warn(body.errors)
          : this.waitForTrainCompletion(body.request_uuid, true);
      });
  }

  waitForTrainCompletion(uuid, turnOnParticleEffect) {
    if (!this.state.submittingToQual) {
      this.setState({ submittingToQual: true });
    }

    if (turnOnParticleEffect) {
      particleInit('particle-wrapper');
    }

    setTimeout(() => {
      request
        .get('/api/live_leads/check_live_lead_creation')
        .query({ request_uuid: uuid })
        .then(({ body }) => {
          console.log(body)
          const { lead_id } = body.data.results;

          if(lead_id) {
            this.redirectToQualQueue(lead_id);
          } else {
            this.waitForTrainCompletion(uuid, false);
          }
        });
    }, 5000);
  }

  /**
   * Send browser to the qual queue interface
   * @param {int} leadID
   */
  redirectToQualQueue(leadID) {
    const parameters = {
      lead_id:       leadID,
      session_uuid:  null
    };

    // attach session UUID
    if(this.state.onActivePhoneCall) {
      const { sessionUUID } = this.state.activePhoneCallDetails;
      parameters.session_uuid = sessionUUID;
    }

    parameters.from_lead_form = 'true';
    // convert parameter object to query string
    const queryString  = querystring.encode(parameters);
    const redirectPath = `${QualQueueLiveBasePath}?${queryString}`;

    console.debug('redirecting to qual queue', redirectPath);
    window.location.href = redirectPath;
  }

  render(){
    return (
      <div className='cs-overshadow hidden' id='new-lead-modal'>
        <div className='main-modal-panel' onClick={(e) => e.stopPropagation()}>
          <div className='new-lead-row row header-row'>
            <div className='col-md-8 page-title-row'>
              <h2 className='page-title'>{this.getPageTitle() }</h2>
            </div>
            <div className='col-md-2 right' onClick={this.closeModal}>
              <i className="fa fa-times-circle close-icon"></i>
            </div>
          </div>
          <div className='row'>
            <hr></hr>
          </div>
          <div className='row'>
            <ul>
            { this.renderErrors() }
            </ul>
          </div>

          <div className='new-lead-row row'>
            { this.renderCurrentPage() }
          </div>

          <div className='new-lead-row row next-button-row'>
            {this.renderBackButton()}
            {this.renderNextButton()}
          </div>
        </div>
      </div>
    );
  }
}
