import React, { Component } from 'react';

import PrimeActivationView from './PrimeActivationView';

export default class PrimeActivationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      primeEligible: this.props.prime_eligible,
      primeFunnelAccess: this.props.prime_funnel_access,
    };


    this.toggleEligibility = this.toggleEligibility.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModalAndCopyCrmUrlToClipboard = this.toggleModalAndCopyCrmUrlToClipboard.bind(this);
  }

  toggleModal(bool) {
    this.setState({ modalOpen: bool });
  }

  toggleModalAndCopyCrmUrlToClipboard(bool) {
    ReferralExchange.copyCrmLoginLinkToClipboard('agent', '/prime/overview', this.props.agent_id, undefined, true);
    this.toggleModal(bool)
  }

  toggleEligibility() {
    const { eligibility_path: eligibilityPath } = this.props;
    const checkbox = document.getElementById('prime-eligibility');
    const method = checkbox.checked ? 'POST' : 'DELETE';


    fetch(
      eligibilityPath,
      {
        method: method,
        credentials: 'same-origin'
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        this.setState({
          primeEligible: response.prime_eligible,
          primeFunnelAccess: response.prime_funnel_access,
        });
      } else {
        throw new Error(response.errors);
      }
    })
    .catch(error => {
      checkbox.checked = !checkbox.checked;
    });
  }

  render() {
    const {
      modalOpen,
      primeEligible,
      primeFunnelAccess,
    } = this.state;

    const buttonText = () => {
      if (!primeEligible) {
        return 'Prime funnel disabled';
      }

      if (primeEligible && !primeFunnelAccess) {
        return 'Market full';
      }

      return 'ACTIVATE PRIME';
    }


    return (
      <div className='columns-wrapper'>
        <div className='column-1'>
          <label htmlFor='prime-eligibility'>
            <input
              type='checkbox'
              name='prime-eligibility'
              id='prime-eligibility'
              className='prime-eligibility'
              checked={ primeEligible }
              onChange={ () => this.toggleEligibility() }
            />
            <span className='checkbox-label'>Mark As Eligible</span>
          </label>

          <div className='prime-buttons'>
            <button
              onClick={ () => this.toggleModalAndCopyCrmUrlToClipboard(!modalOpen) }
              label='Activate PRIME'
              className='activate-prime-button big-button'
              disabled={ !primeEligible || !primeFunnelAccess }
            >
              {buttonText()}
            </button>
          </div>

          {
            modalOpen &&
            <PrimeActivationView
              toggleModal={ this.toggleModal }
            />
          }
        </div>
      </div>
    )
  }
}
