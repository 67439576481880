import React, { useState } from 'react';

const Geo = (props) => {
  const {
    geo,
  } = props;

  const [checked, setChecked] = useState(true);

  const faClass = checked ? 'fa-check-square' : 'fa-square-o';
  const chkClass = checked ? 'checked' : '';

  return (
    <div className={ `geo-wrapper ${chkClass}` } data-geo={ geo } onClick={ () => setChecked(!checked) }>
      <i className={ `fa ${faClass} mr-5` }></i>
      { geo }
    </div>
  );
};

export default Geo;
