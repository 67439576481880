import React, { useState, useEffect } from 'react';

import Table from './Table';
import TableRow from './TableRow';
import TableCol from './TableCol';
import Dropdown from './Dropdown';
import DropdownItem from './DropdownItem';
import FeedCount from './FeedCount';

import './styles.css';

const intialState = {
  feeds: [],
  managers: [],
  manager: 'daily_servicing',
  segments: [],
  segment: 'Agent2Agent',
  buttonLabel: 'Checking Report...',
  reportButtonDisabled: true,
  runningCounts: false
};

const Counts = () => {
  const [state, setState] = useState(intialState);

  const updateState = (options) => {
    setState((previousState) => (
      {
        ...previousState,
        ...options,
      }
    ));
  };

  useEffect(() => {
    window.onbeforeunload = (e) => {
      if (state.runningCounts) return 'Are you sure you want to leave?';
    }

    AjaxRequest.get('/channel_data/feeds', handleSuccess, handleError);
  }, []);

  const handleSuccess = ({ feeds, managers, segments }) => {
    updateState({
      feeds,
      managers,
      segments,
    });
  };

  const handleError = (data) => {
    const errorString = data.toString();

    const errorMessage = errorString.includes('Error: Request has been terminated')
      ? 'Report running was interrupted.'
      : 'An error occurred.';

    alert(errorMessage);
  };

  const updateManager = (e) => {
    updateState({ manager: e.target.value });
  };

  const runReport = () => {
    updateState({ buttonLabel: "Report Running...", reportButtonDisabled: true, runningCounts: true });
  };

  const updateSegment = (e) => {
    updateState({ segment: e.target.value });
  }

  const {
    buttonLabel,
    feeds,
    manager,
    managers,
    reportButtonDisabled,
    runningCounts,
    segment,
    segments,
  } = state;

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '1376px' }}>
        <div>
          <h2> Channel Counts </h2>
        </div>
        {
          runningCounts && (
            <div>
              <h3 style={{ color: '#d14a25' }}>
                This page will automatically refresh once the report has finished running. Hang tight!
              </h3>
            </div>
          )
        }
        <div className="buttons">
          <a href='/channel_data/feeds.csv'>
            <BoldButton label='Export to CSV' />
          </a>
        </div>
      </div>
      <br />
      <Table>
        <TableRow>
          <TableCol header style={{ width: 300 }}>Feed</TableCol>
          <TableCol header style={{ width: 100 }}>Type</TableCol>
          <TableCol header style={{ width: 100 }}>Total</TableCol>
          <TableCol header style={{ width: 100 }}>
            <Dropdown
              title='Manager'
              onChange={updateManager}
              style={{ width: 100 }}
            >
              {
                managers.map((m, idx) => (
                  <DropdownItem key={idx} value={m} title={manager} />
                ))
              }
            </Dropdown>
          </TableCol>
          <TableCol header style={{ width: 100 }}>Intent</TableCol>
          <TableCol header style={{ width: 100 }}>NonIntent</TableCol>
          <TableCol header style={{ width: 120 }}>
            <Dropdown
              title='Segment'
              onChange={updateSegment}
              style={{ width: 120 }}
            >
              {
                segments.map((s, idx) => <DropdownItem key={idx} value={s} title={s} /> )
              }
            </Dropdown>
          </TableCol>
          <TableCol header style={{ width: 300 }}>Users</TableCol>
        </TableRow>
        {
          feeds.map((feed, idx) =>
            <FeedCount
              idx={idx}
              feed={feed}
              key={idx}
              manager={manager}
              segment={segment}
            />
          )
        }
      </Table>
      {
        feeds.length === 0 &&
          <div className='loading'>
            Loading Counts
          </div>
      }
    </div>
  );
}

export default Counts;
