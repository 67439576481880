import React, { useState, useEffect } from 'react';

import Results from './search/Results.js';
import InputField from './../../_common/InputField.js';

const Search = props => {
  const {
    matches,
    updateMatches,
    updateFieldHandler,
  } = props;

  const [results, updateResults] = useState([]);
  const [fetching, setFetching] = useState('init');

  useEffect(() => {
    if (!results.length) setFetching('init');
  }, [results]);

  useEffect(() => {
    const input = document.getElementById('searchable_id');

    input.addEventListener('keyup', e => {
      if (e.keyCode === 27) input.value = null; // Esc
      if (e.keyCode === 13) handleSearch(); // Enter
    });
  }, []);

  const validInput = value => {
    const str = `${value}`;

    return !!str.trim().length;
  };

  const submitRequest = payload => {
    const input = document.getElementById('searchable_id');
    const params = Util.toQueryString(payload);

    setFetching('fetch');

    fetch(
      `/customer_success/search?${params}`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.length) {
        updateFieldHandler({ results: response, searchable_id: input.value });
        updateResults(response);
        setTimeout(() => setFetching('results'), 750);

        input.value = null;
      } else {
        updateFieldHandler({ results: [], searchable_id: undefined });
        setTimeout(() => setFetching('no-results'), 750);
      }
    })
    .catch(error => console.log(error))
    ;
  };

  const removeErrors = () => {
    const errorMessages = document.querySelectorAll('.error-message');
    const labels = [...errorMessages].map(e => e.parentElement);

    Util.clearFlash();

    errorMessages.forEach(error => error.remove());
    labels.forEach(label => label.classList.remove('has-error'));
  };

  const addError = (attr, error) => {
    const container = document.getElementById('lead-creation-form-container');
    const label = container.querySelector(`label[for*="${attr}"]`);

    if (label.classList.contains('has-error')) return;

    if (label) {
      const div = document.createElement('div');

      div.classList.add('error-message');
      div.innerHTML = error;

      label.classList.add('has-error');
      label.appendChild(div);
    }
  };

  const formatAdvancedSearchPayload = value => {
    const searchType = checkType(`${value}`);
    let options;

    if (/name/.test(searchType)) {
      const [first_name, last_name] = value.split(' ');

      options = { first_name, last_name };
    } else if (/phone/.test(searchType)) {
      options = { [searchType]: value.replace(/[^0-9]/g, '') };
    } else {
      options = { [searchType]: value };
    }

    return {
      search_params: {
        search_type: 'advanced_search',
        advanced_search_options: { ...options },
      }
    };
  };

  const handleSearch = _event => {
    const input = document.getElementById('searchable_id');
    const { value } = input;

    removeErrors();
    setFetching('init');

    if (validInput(value)) {
      const payload = formatAdvancedSearchPayload(value);

      submitRequest(payload);
    } else {
      addError('searchable_id', 'Invalid input');
    }
  };

  return (
    <React.Fragment>
      <div className="section">
        <div className="header sub-header">
          <div className="icon-wrapper"><i className="icon search"></i></div>
          <div className="title-wrapper">
            <p>Search for an Agent</p>
          </div>
        </div>

        <div className="input-row">
          <div className="input-icon-group">
            <InputField fieldType="text" fieldTitle="Agent Search" fieldName="searchable_id" placeholder="Search for an agent..." required={ false } updateFieldHandler={ updateFieldHandler } >
              <div className="input-icon pull-right" onClick={ handleSearch }><i className="icon search"></i></div>
            </InputField>
          </div>

          <div className="spacer"></div>
        </div>
      </div>

      <Results
        matches={ matches }
        results={ results }
        fetching={ fetching }
        updateMatches={ updateMatches }
        updateResults={ updateResults }
      />
    </React.Fragment>
  );
};

export default Search;
