import { Component } from 'react';

export default class LeadTypeForm extends Component {
  constructor(props) {
    super(props);

    this.buyerVerifications = {
      buyer_city_0: false,
      buyer_state_0: false,
      home_price_min: false,
      home_price_max: false,
      beds: false,
      baths: false,
      mortgage_status: false,
      type_home: false
    }
    this.sellerVerifications = {
      street_number: false,
      street_name: false,
      city: false,
      state: false,
      zip: false,
      home_price_min: false,
      home_price_max: false,
      type_home: false
    }
  }

  componentDidMount() {
    let {
      data,
      updateState,
    } = this.props;

    let live_verifications = {
      email: false,
      lead_type: data.live.ops_created ? true : false
    };

    if (data.lead.lead_type) {
      Object.assign(
        live_verifications,
        data.lead.lead_type === 'buyer'
          ? this.buyerVerifications
          : this.sellerVerifications
      )
    }

    updateState({ live_verifications });
  }

  render() {
    return (
      <div className={['lead_type', this.props.data.live_verifications.lead_type ? '' : 'verification_needed'].join(' ')}>
        <div className='content_header'>
          <i className='fa fa-commenting-o'></i>
          LEAD TYPE
        </div>
        <div className='field radio_buttons'>
          <div className='label'>
            Buyer
          </div>
          <input
            type='radio'
            name='lead_type'
            value='buyer'
            onClick={this.handleLeadTypeChange.bind(this)}
            checked={this.props.data.lead.lead_type === 'buyer'}
          />
          <div className='label'>
            Seller
          </div>
          <input
            type='radio'
            name='lead_type'
            value='seller'
            onClick={this.handleLeadTypeChange.bind(this)}
            checked={this.props.data.lead.lead_type === 'seller'}
          />
          <div className={['label', 'verify', this.props.data.live_verifications.lead_type ? 'hidden' : ''].join(' ')}>IMPORTANT: PLEASE VERIFY</div>
        </div>
      </div>
    )
  }

  handleLeadTypeChange(e) {
    var val = e.target.value;
    var lead = this.props.data.lead;
    var verifications = lead.lead_type !== val ? this.switchVerifications(val) : this.props.data.live_verifications;
    lead.lead_type = val;
    verifications.lead_type = true;
    this.props.updateState({ lead: lead, live_verifications: verifications });
  }

  switchVerifications(lead_type) {
    var sharedVerifications = {
      email: this.props.data.live_verifications.email,
      lead_type: this.props.data.live_verifications.lead_type
    }
    return(Object.assign(sharedVerifications, lead_type === 'buyer' ? this.buyerVerifications : this.sellerVerifications))
  }
}
