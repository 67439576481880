import { Component } from 'react';

import Results from './views/Results';

const profileReviewsMessage = count => {
  if (count < 100) return count;

  const errorMessage = `Your search returned too many results; we are only displaying ${count}. Please narrow down your search.`;

  return <span className="has-error">{ errorMessage }</span>;
};

export default class Container extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      profile_reviews,
      review_moderation_review_validate_path,
    } = this.props;

    return (
      <div className="agent-profile-review-results-wrapper">
        <h1>Agent Profile Review - Search Results</h1>
        <p className="my-15"><strong>Reviews Found: { profileReviewsMessage(profile_reviews.length) }</strong></p>
        <Results
          profileReviews={ profile_reviews }
          reviewModerationReviewValidatePath={ review_moderation_review_validate_path }
        />
      </div>
    );
  }
}
