export const email = (email) => {
  if (!email) {
    return false;
  }

  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!reg.test(String(email).toLowerCase())) {
    return 'Invalid email address';
  }

  return true;
};

export const isNonEmptyInt = (int) => {
  if (!int) {
    return false;
  }

  return true;
};

export const isNonEmptyString = (value) => {
  if (!value || (value && !value.trim().length)) {
    return false;
  }
  return value.trim().length > 0;
};

export const isTrue = () => true;

export const isNull = () => null;

export const maxPrice = (maxP, minP) => {
  if (!maxP) {
    return false;
  }

  const maxPrice = parseInt(maxP, 10);
  const minPrice = parseInt(minP, 10);

  if (!isNaN(maxPrice) && !isNaN(minPrice) && maxPrice < minPrice) {
    return 'Max Price < Min Price';
  } else if (maxPrice > 9999999) {
    return 'Max price is too high';
  }

  return true;
};

export const minPrice = (p) => {
  if (p === undefined) {
    return false;
  }

  const price = parseInt(p, 10);

  if (isNaN(price)) {
    return false;
  }

  if (!isNaN(price) && price < 0) {
    return 'Min price is too low';
  }

  if (price > 9999999) {
    return 'Min price is too high';
  }

  return true;
};

export const zipCode = (value, country) => {
  if (!value) {
    return false;
  }

  if (country === 'US') {
    const zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
    if (!zipCodePattern.test(value)) {
      return 'Invalid ZIP';
    }
  } else {
    const postalCode = /^[A-Za-z]\d[A-Za-z](?:[ -]?\d[A-Za-z]\d)?$/;
    if (!postalCode.test(value)) {
      return 'Invalid Postal Code';
    }
  }
  return true;
};

export const hasNonNumeric = (str) => {
  return !str.match(/[^$,.\d]/);
};
