import React, { useState, useEffect } from 'react';
import HistoryTable from './history/Table';

import './history/styles/index.scss';

const History = (props) => {
  const container = document.getElementById('dialer-recruit-top-agents-research-container');

  const {
    agent: { canonical_aid },
    researchContactHistoryPath
  } = props;

  const [history, setHistory] = useState([]);
  const [canonicalAid, setCanonicalAid] = useState(canonical_aid);

  useEffect(() => {
    container.addEventListener('agent_research.clear_history', () => setHistory([]));
  });

  useEffect(() => setCanonicalAid(canonical_aid));

  useEffect(() => {
    if (canonicalAid) fetchResearchHistory();
  }, [canonicalAid]);

  function toggleOverlay(operation) {
    const container = document.getElementById('research-history-table');

    // Reset
    container.classList = '';

    if (/enable/.test(operation)) container.classList.add('overlay');
  }

  function fetchResearchHistory() {
    toggleOverlay('enable');

    fetch(
      `${researchContactHistoryPath}?canonical_aid=${canonicalAid}`,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      toggleOverlay('disable');

      if (response.success) {
        setHistory(response.phones);
      } else {
        console.log(response.errors);
      }
    })
    .catch(error => console.log(error));
  }

  return (
    <div id="research-history-pane" className="row">
      <div className="col-md-12">
        <h1 className="mt-0">Research History</h1>
        <HistoryTable history={ history } />
      </div>
    </div>
  );
};

export default History;
