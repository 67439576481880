import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'superagent';

const getHistoryData = ({ leadId }) => {
  return request
    .get('/qual/queue/requal_attempts')
    .query({ lead_id: leadId })
    .then(({ body }) => {
      const { qual_note_data } = body;
      return qual_note_data;
    });
}

function* fetchHistoryData(action) {
   try {
      const data = yield call(getHistoryData, action.data);
      yield put({ type: 'SET_QUAL_HISTORY', data });
   } catch (e) {
      yield put({type: 'GET_LEAD_DATA_FAILED', message: e.message});
   }
}

function* historyDataSaga() {
  yield takeLatest('GET_QUAL_HISTORY', fetchHistoryData);
}

export default [
  historyDataSaga(),
];
