import Header from './Header';
import Body from './Body';

const Wrapper = ({ batches }) => {
  return (
    <table className="table table-striped">
      <Header />
      <Body batches={ batches } />
    </table>
  );
};

export default Wrapper;
