import { Component }          from 'react';
import request                from 'superagent';
import BrandView              from './monitor_views/BrandView';
import AdminView              from './monitor_views/AdminView';
import FollowUpView           from './monitor_views/FollowUpView';
import WorkingListReviewView  from './monitor_views/WorkingListReviewView';

export default class WorkingList extends Component {
  constructor(props){
    super(props);

    this.state                  = this.initialState();
    this.loadBrands             = this.loadBrands.bind(this);
    this.loadFollowUp           = this.loadFollowUp.bind(this);
    this.loadReview             = this.loadReview.bind(this);
    this.loadAdmin              = this.loadAdmin.bind(this);
    this.returnToBrands         = this.returnToBrands.bind(this);
    this.returnToAdmins         = this.returnToAdmins.bind(this);
    this.updateList             = this.updateList.bind(this);
    this.updateState            = this.updateState.bind(this);
    this.createWorkingEntry     = this.createWorkingEntry.bind(this);
  }

  initialState(){
    return {
      brands: [],
      admins: [],
      monitorAdmins: [],
      brandsSelected: [],
      adminsSelected: [],
      followUpTime: '',
      followUpMessage: '',
      executivesSelected: [],
      submitting: false,
      brandsLoading: false,
      view: 'brand'
    }
  }

  componentDidMount(){
    this.loadBrands();
    this.loadMonitorAdmins();
  }

  returnToBrands(){
    this.setState({ view: 'brand', brandsSelected: [] });
  }

  loadFollowUp(){
    if(this.state.adminsSelected.length < 1) return;
    this.setState({ view: 'follow-up' });
  }

  loadBrands() {
    this.setState({ submitting: true });
    request
      .get('/agent_insights/monitors/fetch_agent_monitors')
      .then(({ body }) => {
        this.setState({
          submitting: false,
          brands: body.monitors || [],
        });
      });
  }

  loadAdmin(){
    let { brandsSelected } = this.state;
    let { agentId } = this.props;

    if (brandsSelected.length < 1) return;

    this.setState({ submitting: true });
    let brandNames = brandsSelected.map(b => { return b.name })
    let options = { agent_id: agentId, brands: JSON.stringify(brandNames) };

    request
      .get('/agent_insights/monitors/fetch_admins_in_monitors')
      .query(options)
      .then(({ body }) => {
        this.setState({
          admins: body.admins || [],
          view: 'admin',
          submitting: false,
        });
      });
  }

  loadMonitorAdmins() {
    let options = { agent_id: this.props.agentId };

    request
      .get('/agent_insights/monitors/fetch_admins_for_agent')
      .query(options)
      .then(({ body }) => {
        this.setState({ monitorAdmins: body.admins || [] });
      });
  }

  returnToAdmins() {
    this.setState({ view: 'admin', selectedAdmins: [] })
  }

  loadReview(){
    this.setState({ view: 'review'})
  }

  showLoading() {
    return this.state.submitting
      ? 'modal-overlay-submitting'
      : 'hidden';
  }

  updateState(object) {
    this.setState(object)
  }

  createWorkingEntry() {
    this.setState({ submitting: true });
    let { followUpTime, followUpMessage, adminsSelected } = this.state;
    let selectedAdmins = adminsSelected.map(admin => {
      return {
        user_data_id: admin.id,
        admin_id: admin.user_id,
        user_id: admin.user_id,
        brand_id: admin.brand_id
      }
    });

    let options = {
      agent_id: this.props.agentId,
      sales_managers: JSON.stringify(selectedAdmins),
      task: {
        follow_up: followUpTime,
        message: followUpMessage
      }
    }

    return(
      request
        .post('/agent_insights/monitors/submit_working_list')
        .query(options)
        .then(() => this.props.loadInitialData())
        .then(() => this.props.closeModal())
    );
  }

  updateList(listName, listItem, listItemProperty){
    let list = this.state[listName];
    let listPropertyValues = list.map(listItem => { return listItem[listItemProperty]});
    let index = listPropertyValues.indexOf(listItem[listItemProperty]);
    index >= 0
      ? list.splice(index, 1)
      : list.push(listItem);

    this.setState({ [listName]: list });
  }

  updateBrandList(listName, listItem, listItemProperty){
    this.updateList(listName, listItem, listItemProperty);
  }

  modalHeader(){
    switch (this.state.view) {
      case 'brand':
        return <h3>Brands</h3>;
        break;
      case 'admin':
        return <h3>Sales Managers</h3>;
        break;
      case 'follow-up':
        return <h3>Follow Up Task</h3>;
        break;
      case 'review':
        return <h3>Review Selection</h3>;
        break;
    }
  }

  modalContent(){
    switch(this.state.view) {
      case 'brand':
        return(
          <BrandView
            state={this.state}
            history={this.props.history}
            updateList={this.updateList}
            resetState={this.props.resetState}
            loadNextView={this.loadAdmin}
          />
        );
        break;
      case 'admin':
        return(
          <AdminView
            state={this.state}
            type='workingList'
            updateList={this.updateList}
            loadPrevView={this.returnToBrands}
            loadNextView={this.loadFollowUp}
          />
        );
        break;
      case 'follow-up':
        return (
          <FollowUpView
            state={this.state}
            updateState={this.updateState}
            loadPrevView={this.returnToAdmins}
            loadNextView={this.loadReview}
          />
        );
        break;
      case 'review':
        return(
          <WorkingListReviewView
            state={this.state}
            loadPrevView={this.loadFollowUp}
            createWorkingEntry={this.createWorkingEntry}
          />
        );
        break;
    }
  }

  render(){
    return(
      <working-list>
        <div className="header">{ this.modalHeader() }</div>
        { this.modalContent() }
        <div className={this.showLoading()}>
          <i className='fa fa-refresh fa-spin fa-5x fa-fw'></i>
          <span className='sr-only'>Loading...</span>
        </div>
      </working-list>
    )
  }
}