import React, { useState, useEffect } from 'react';

const AGENT_ATTRS = [
  'id',
  'name',
  'email',
  'phone_mobile',
  'company_city',
  'company_state',
];

const Results = props => {
  const {
    matches,
    results,
    fetching,
    updateMatches,
    updateResults,
  } = props;

  const handleOnClick = event => {
    const { agent } = event.target.dataset;

    if (agent) {
      const agentData = JSON.parse(agent);
      const included = matches.map(agent => agent.id).includes(agentData.id);

      updateResults([]);
      if (!included) updateMatches([ ...matches, agentData]);
    }
  };

  const buildRow = result => {
    const row = [];

    row.push(<td key="searchable_ids"><button className="btn btn-xs btn-info" data-agent={ JSON.stringify(result) } onClick={ handleOnClick }>Add</button></td>);

    AGENT_ATTRS.forEach(key => {
      row.push(<td key={ result[key] }>{ result[key] }</td>);
    });

    return row;
  };

  const buildRows = () => {
    const rows = [];

    results.forEach(result => rows.push(<tr key={ result.id }>{ buildRow(result) }</tr>));

    return rows;
  };

  const renderView = () => {
    switch (fetching) {
    case 'fetch':
      return (
        <div className="input-row center flex-init">
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      );

      break;
    case 'no-results':
      return (
        <div className="input-row center flex-init">
          <strong>No Results</strong>
        </div>
      );

      break;
    case 'results':
      return (
        <div className="input-row">
          <table id="match-agent-results" className="table table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>Agent ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>City</th>
                <th>State</th>
              </tr>
            </thead>
            <tbody>
              { buildRows() }
            </tbody>
          </table>
        </div>
      );

      break;
    default:
      return undefined;
    }
  };

  return (
    <div className="section">
      { renderView() }
    </div>
  );
};

export default Results;
