import React, { useState, useReducer } from 'react';

import OpsPpc from './OpsPpc.js';
import Matches from './lead_from_lead/Matches.js';
import Search from './lead_from_lead/Search.js';

const LeadFromLead = props => {
  const {
    resetView,
    lead = {},
    matches: mt,
    consent_options,
    country_options,
    us_state_options,
    ca_province_options,
    source_options,
    microsite_options,
    language_options,
    type_home_options,
    time_frame_options,
    reason_options,
    mortgage_options,
    bed_options,
    bath_options,
  } = props;

  const initDetails = {
    first_name: lead.first_name,
    last_name: lead.last_name,
    phone_number: lead.phone_number,
    email: lead.email_address,
    language: lead.language_preference,
    original_lead_id: lead.id,
    referral_fee: lead.referral_fee,
    referral_fee_agent_cut: lead.referral_fee_agent_cut,
    referral_fee_partner_cut: lead.referral_fee_partner_cut,
    referral_fee_rex_cut: lead.referral_fee_rex_cut,
    agent_ids: [],
  };

  const [leadFromLead, updateLeadFromLead] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    initDetails,
  );

  const [matches, updateMatches] = useState(mt || []);

  const updateFieldHandler = data => {
    Object.keys(data).forEach(key => {
      if (typeof(data[key]) === "object") {
        const overrides = Array.isArray(data[key]) ? data[key] : { ...leadFromLead[key], ...data[key] };

        updateLeadFromLead({ [key]: overrides });
      } else {
        updateLeadFromLead(data);
      }
    });
  };

  return (
    <React.Fragment>
      <Search
        matches={ matches }
        updateMatches={ updateMatches }
        updateFieldHandler={ updateFieldHandler }
      />

      <Matches
        matches={ matches }
        updateFieldHandler={ updateFieldHandler }
      />

      <OpsPpc
        resetView={ resetView }
        leadFromLead={ leadFromLead }
        consent_options={ consent_options }
        country_options={ country_options }
        us_state_options={ us_state_options }
        ca_province_options={ ca_province_options }
        source_options={ source_options }
        microsite_options={ microsite_options }
        language_options={ language_options }
        type_home_options={ type_home_options }
        time_frame_options={ time_frame_options }
        reason_options={ reason_options }
        mortgage_options={ mortgage_options }
        bed_options={ bed_options }
        bath_options={ bath_options }
      />
    </React.Fragment>
  );
};

export default LeadFromLead;
