import { Component } from 'react';
import request from 'superagent';

import FormBuilder from './FormBuilder';
import { buildStateFromFields } from './StateBuilder';
import { particleInit } from './particleEffect';
import MarketBuilder from './MarketBuilder';

export default class CreateCanadianAgent extends Component {
  constructor(props){
    super(props)
    this.fields = [
      {
        type: "input",
        label: "First Name",
        stateProp: "first_name",
        alias: 'first_name'
      },
      {
        type: "input",
        label: "Last Name",
        stateProp: "last_name"
      },
      {
        type: "input",
        label: "Mobile Phone",
        stateProp: "phone_mobile"
      },
      {
        type: "input",
        label: "Email",
        stateProp: "email"
      },
      {
        type: "input",
        label: "Website",
        stateProp: "website"
      },
      {
        type: "input",
        label: "Company",
        stateProp: "name"
      },
      {
        type: "input",
        label: "Company Address",
        stateProp: "address"
      },
      {
        type: "input",
        label: "City",
        stateProp: "city",
        options: {
          class: "w24p"
        }
      },
      {
        type: "select",
        label: "Province",
        stateProp: "state",
        options: {
          class: "w13p",
          selectOptions: this.props.provinces,
          keyOnly: true,
          allowBlank: true
        }
      },
      {
        type: "input",
        label: "Postal Code",
        stateProp: "zip",
        options: {
          class: "w13p"
        }
      },
      {
        type: "select",
        label: "License Type",
        stateProp: "license_type",
        options: {
          selectOptions: ["Broker", "Salesperson"],
          keyOnly: true,
          allowBlank: true
        }
      },
      {
        type: "select",
        label: "License Province",
        stateProp: "license_state",
        options: {
          selectOptions: this.props.provinces,
          keyOnly: false,
          allowBlank: true
        }
      },
      {
        type: "checkbox",
        label: "Other Languages",
        stateProp: "languages",
        options: {
          class: "w100p",
          checkboxOptions: [
            {
              label: "French",
              value: "French"
            },
            {
              label: "Spanish",
              value: "Spanish"
            },
            {
              label: "Arabic",
              value: "Arabic"
            },
            {
              label: "Bosnian",
              value: "Bosnian"
            },
            {
              label: "Cantonese",
              value: "Cantonese"
            },
            {
              label: "Italian",
              value: "Italian"
            },
            {
              label: "Japanese",
              value: "Japanese"
            },
            {
              label: "German",
              value: "German"
            },
            {
              label: "Greek",
              value: "Greek"
            },
            {
              label: "Hebrew",
              value: "Hebrew"
            },
            {
              label: "Hindi",
              value: "Hindi"
            },
            {
              label: "Korean",
              value: "Korean"
            },
            {
              label: "Mandarin",
              value: "Mandarin"
            },
            {
              label: "Polish",
              value: "Polish"
            },
            {
              label: "Portuguese",
              value: "Portuguese"
            },
            {
              label: "Romanian",
              value: "Romanian"
            },
            {
              label: "Russian",
              value: "Russian"
            },
            {
              label: "Tagalog",
              value: "Tagalog"
            },
            {
              label: "Ukrainian",
              value: "Ukrainian"
            },
            {
              label: "Urdu",
              value: "Urdu"
            },
            {
              label: "Vietnamese",
              value: "Vietnamese"
            },
          ]
        }
      }
    ];

    this.initState = {
      market: {
        searchInput: "",
        marketResults: [],
        selected: []
      },
      errors: {},
      submitting: false,
      particleOn: false
    };

    this.state = buildStateFromFields(this.initState, this.fields);
    this.updateState = this.updateState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    document.getElementsByClassName("container-fluid")[0].setAttribute('style', 'background-color: #f2f2f2;');
    $('#phone_mobile').keypress(function(e) {
      return ReferralExchange.onlyNationalPhoneNumberCharacters(e);
    });
  }

  updateState(updateParam){
    this.setState(updateParam);
  }

  handleSubmit(e) {
    const btn = e.currentTarget;
    const {
      first_name,
      last_name,
      phone_mobile,
      email,
      website,
      license_type,
      license_state,
      market,
      languages,
      name,
      address,
      city,
      state,
      zip,
    } = this.state;

    btn.disabled = true;

    // Force +1 country code for North America
    let north_america_phone_mobile;
    if (phone_mobile.charAt(0) == '1') {
      north_america_phone_mobile = '+' + phone_mobile
    } else {
      north_america_phone_mobile = '+1' + phone_mobile
    }

    const payload = {
      format: 'json',
      user: {
        first_name,
        last_name,
        phone_mobile: north_america_phone_mobile,
        email,
        phone_office: north_america_phone_mobile
      },
      agent: {
        license_type,
        license_state,
        languages,
        url: website,
        zip_codes: market.selected,
        text_message_number: north_america_phone_mobile,
      },
      company: {
        name,
        address,
        city,
        state,
        zip
      }
    };

    request
      .post("/ingest/canadian_agent/submit")
      .send(payload)
      .then(({ body }) => {
        body.errors
          ? this.setState({ errors: body.errors })
          : this.waitForTrainCompletion(body.request_uuid);
        btn.disabled = false;
      });
  }

  waitForTrainCompletion(uuid){
    if (!this.state.particleOn) { particleInit('particle-wrapper'); }

    this.setState({ submitting: true, particleOn: true })
    setTimeout(() => {
      request
        .get('/ingest/targeted_agent/ready')
        .query({ request_uuid: uuid })
        .then(({ body }) => {
          body.data.results.user_uuid
            ? window.location.href = `/agents/${body.data.results.user_uuid}`
            : this.waitForTrainCompletion(uuid);
          }
        );
    }, 5000);
  }

  render(){
    return (
      <div id="create-canadian-agent-inner">
        <h1>New Canadian Agent</h1>
        <section className="create-form">
          <FormBuilder
            fields={this.fields}
            state={this.state}
            updateState={this.updateState}
          />
          <MarketBuilder
            state={this.state}
            updateState={this.updateState}
          />
          <input
            type="submit"
            className="submit-button"
            onClick={this.handleSubmit}
            value="CREATE NEW CANADIAN AGENT"
          />
        </section>
        <div className={ this.state.submitting ? 'loading-screen' : 'hidden'}>
          Creating Agent
          <i className="fa fa-cog fa-spin fa-2x fa-fw" aria-hidden="true"></i>
          <div id='particle-wrapper'></div>
        </div>
      </div>
    );
  }
}
