import React from 'react';
import ReactDOM from 'react-dom';
import ReviewModeration from './index';
import '../../../../stylesheets/agent_reviews.scss';

export function initialize(containerElement, options) {
  let component = React.createElement(ReviewModeration, options);

  ReactDOM.unmountComponentAtNode(containerElement);
  ReactDOM.render(component, containerElement);
}
