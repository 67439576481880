import { Component } from 'react';

const TableRow = ({ children }) => {
  const tableRowStyle = {
    display: 'flex',
    width: 'auto',
    clear: 'both',
    borderBottom: '2px solid #dbdbdb',
  }

  return (
    <div style={tableRowStyle}>
      {children}
    </div>
  )
}

export default TableRow
