import React from 'react';
import Context from '../../Context';
import InputField from '../../../FormBuilder/InputField';
import SelectField from '../../../FormBuilder/SelectField';
import DisplayBlock from '../../shared/DisplayBlock';

import { formatCurrency } from '../../../util/textMasks';

const StageOne = () => (
  <Context.Consumer>
    {(props) => {
      const {
        dupTransaction,
        transaction,
        updateTransaction,
        selections: {
          foundSale,
          foundListing,
        },
        updateSelections,
      } = props;

      function updateSelection(selection, e) {
        const target = e.currentTarget;
        const newValue = target.classList.contains('confirm');

        const {
          address_house_number,
          address_street_name,
          address_unit,
          address_city,
          address_state,
          address_zip,
          transaction_date,
          sale_price,
        } = dupTransaction;

        if (newValue === false) {
          if (selection === 'foundListing') {
            updateTransaction({
              address_house_number,
              address_street_name,
              address_unit,
              address_city,
              address_state,
              address_zip,
            });
          } else if (selection === 'foundSale') {
            updateTransaction({
              transaction_date,
              sale_price,
            });
          }
        }

        updateSelections({ [selection]: newValue })
      }

      function answerFoundListing(e) {
        updateSelection('foundListing', e);
      }

      function answerSaleSelection(e) {
        updateSelection('foundSale', e);
      }

      function highlightValid(target) {
        const parent = target.closest('.input-field');

        if ((['address_unit', 'sale_price'].indexOf(target.name) >= 0) || (target.value.length > 0)) {
          parent.classList.remove('faux-error');
          parent.classList.add('faux-validation');
        } else {
          parent.classList.add('faux-error');
          parent.classList.remove('faux-validation');
        }

        updateSelections();
      }

      function applyError(target) {
        const parent = target.closest('.input-field');
        parent.classList.add('faux-error');
        parent.classList.remove('faux-validation');
      }

      function handleChange(e) {
        const target = e.currentTarget;
        updateTransaction({ [target.name]: target.value });
      }

      function validate(e) {
        const target = e.currentTarget;
        const { errors } = transaction;

        switch (target.name) {
          case 'address_zip':
            if (!target.value.match(/^[0-9]{5}(?:-[0-9]{4})?$/)) {
              applyError(target);
              errors.address_zip = ['zip fail'];
            } else {
              delete errors.address_zip;
              highlightValid(target);
            }
            break;
          case 'sale_price':
            if (target.value === '') {
              delete errors.sale_price;
              highlightValid(target);
              break;
            }

            const price = parseInt(target.value.replace(/\D/g, ''), 10);

            if (price < 1 || price > 10000000) {
              applyError(target);
              errors.sale_price = ['Must be between $1 and $10,000,000'];
            } else {
              delete errors.sale_price;
              highlightValid(target);
            }
            break;
          case 'transaction_date':
            const date = new Date(target.value);
            const earliestDate = new Date('2010-01-01');

            if (earliestDate > date) {
              applyError(target);
              errors.transaction_date = ['too early'];
            } else {
              delete errors.transaction_date;
              highlightValid(target);
            }
            break;
          default:
            highlightValid(target);
            break;
        }

        updateTransaction({ errors });
      }

      function handleSelectChange(e) {
        const attribute = e.currentTarget.getAttribute('name');
        const { value } = e.currentTarget.selectedOptions[0];
        updateTransaction({ [attribute]: value });
        validate(e);
      }

      return (
        <div className="form-builder stage1">
          <DisplayBlock title="Can you find a listing of this property?" />
          <div className="button-container">
            <button
              id="foundListingConfirm"
              className={foundListing === true ? 'confirm inverted' : 'confirm'}
              onClick={answerFoundListing}
            >
              Yes
            </button>
            <button
              id="foundListingDeny"
              className={foundListing === false ? 'deny inverted' : 'deny'}
              onClick={answerFoundListing}
            >
              No
            </button>
          </div>
          <DisplayBlock title="Click to confirm the follow listing details. Fill in the missing data, if applicable." />
          <InputField
            id="address_house_number"
            label="STREET NUMBER"
            klass="street-or-unit faux-error"
            value={transaction.address_house_number}
            handleChange={handleChange}
            onFocus={validate}
            onBlur={validate}
          />
          <InputField
            id="address_street_name"
            label="STREET NAME"
            klass="faux-error"
            value={transaction.address_street_name}
            handleChange={handleChange}
            onFocus={validate}
            onBlur={validate}
          />
          <InputField
            id="address_unit"
            label="ADDRESS UNIT"
            klass="street-or-unit faux-error"
            value={transaction.address_unit}
            handleChange={handleChange}
            onFocus={validate}
            onBlur={validate}
          />
          <InputField
            id="address_city"
            label="CITY"
            klass="faux-error"
            value={transaction.address_city}
            handleChange={handleChange}
            onFocus={validate}
            onBlur={validate}
          />
          <SelectField
            id="address_state"
            label="STATE"
            klass="state faux-error"
            value={transaction.address_state}
            selectOptions={transaction.states}
            handleChange={handleSelectChange}
            onFocus={validate}
            keyOnly
            allowBlank
          />
          <InputField
            id="address_zip"
            label="ZIP"
            klass="zip faux-error"
            value={transaction.address_zip}
            error={transaction.errors.address_zip}
            handleChange={handleChange}
            onFocus={validate}
            onBlur={validate}
          />
          <DisplayBlock title="Can you find evidence of a sale?" />
          <div className="button-container">
            <button
              id="foundSaleConfirm"
              className={foundSale === true ? 'confirm inverted' : 'confirm'}
              onClick={answerSaleSelection}
            >
              Yes
            </button>
            <button
              id="foundSaleDeny"
              className={foundSale === false ? 'deny inverted' : 'deny'}
              onClick={answerSaleSelection}
            >
              No
            </button>
          </div>
          {
            foundSale && (
              <>
                <InputField
                  id="transaction_date"
                  label="SALE DATE"
                  klass="faux-error sale"
                  value={transaction.transaction_date}
                  error={transaction.errors.transaction_date}
                  type="date"
                  handleChange={handleChange}
                  onFocus={validate}
                  onBlur={validate}
                />
                <InputField
                  id="sale_price"
                  label="SALE PRICE"
                  klass="faux-error sale"
                  textMask={formatCurrency}
                  value={transaction.sale_price}
                  error={transaction.errors.sale_price}
                  handleChange={handleChange}
                  onFocus={validate}
                  onBlur={validate}
                />
              </>
            )
          }
        </div>
      )
    }}
  </Context.Consumer>
)

export default StageOne;
