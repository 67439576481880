import React from 'react';
import ReactDOM from 'react-dom';
import LiveCallControlContainer from './LiveCallControlContainer';

/**
 * Initialize the LiveCallControl system.
 */

export function initialize(containerElement, options) {
  let component = React.createElement(LiveCallControlContainer, options);
  ReactDOM.render(component, containerElement);
}
