import { Component } from 'react';

export default class MasqueradeAsUser extends Component {
  constructor() {
    super();

    this.state = {
      searchQuery: '',
      disabled: true,
      users: [],
      error: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({ searchQuery: e.target.value });
    this.setState({ disabled: !e.target.value.trim() });
  }

  handleSubmit(e) {
    e.preventDefault();
    $.ajax({
      url: '/product_dashboard/rex_user/search',
      type: 'GET',
      data: { searchQuery : this.state.searchQuery},
      success: (response) => {
        if (response.users.length == 0) {
          this.setState({ users: [] })
          this.setState({ error: 'No results found for that name.' })
        } else {
          this.setState({ users: response.users })
          this.setState({ error: '' })
        }
      },
      error: (response) => {
        this.setState({ users: [] })
        this.setState({ error: response.error })
      }
    });
  }

  render() {
    return (
      <div className='dashboard-tool'>
        <div className='masquerade-as-user'>
          <form className='rex-user-search' onSubmit={this.handleSubmit}>
            <div className='form-group mb-0'>
              <div className='col-md-6 input-group input-group-lg'>
                <input type='text' className='form-control' placeholder='REX User Name' value={this.state.searchQuery} onChange={this.handleChange}/>
                <span className='input-group-btn'>
                  <button className='btn btn-md btn-rex btn-orange search-button' disabled={this.state.disabled}>SEARCH</button>
                </span>
              </div>
              <div className='error-msg'>{this.state.error}</div>
            </div>
          </form>

          <div className='row matched-results'>
            <div className='col-md-12'>
              <RexUserResults users={this.state.users}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const RexUserResults = ({ users }) => {
  if (users.length == 0) {
    return(null)
  } else {
    return (
      <div>
        <h3>Results</h3>
        <table className='table table-borderless spaced-rows blue-rows'>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => <RexUser user={user}/>)}
          </tbody>
        </table>
      </div>
    )
  }
}

const RexUser = ({ user }) => {
  if (user.is_current_user) {
    return(
      <tr key={user.id}>
        <td></td>
        <td>{user.first_name + ' ' + user.last_name + ' (Yourself)'}</td>
        <td>{user.email}</td>
      </tr>
    )
  } else {
    return (
      <tr key={user.id}>
        <td>
          <form action={'/masquerade?user_id=' + user.id} method='POST'>
            <button className='btn btn-md btn-rex btn-orange' type='submit'>Masquerade as this User</button>
          </form>
        </td>
        <td>
          {user.first_name + ' ' + user.last_name}
        </td>
        <td>{user.email}</td>
      </tr>
    )
  }
}
