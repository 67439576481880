import Header from './Header';
import Body from './Body';
import Footer from './Footer';

const Wrapper = (props) => {
  const {
    agentId,
    currentAreas,
    currentVisible,
    header,
    lookupPath,
    serviceAreas,
    serviceAreasPath,
    updateState,
  } = props;

  return (
    <div className='service-area-modal modal fade' id='serviceAreaModal' role='dialog'>
      <div className='modal-dialog modal-lg'>
        <div className='modal-content'>
          <Header
            header={ header }
          />

          <Body
            currentAreas={ currentAreas }
            currentVisible={ currentVisible }
            lookupPath={ lookupPath }
            serviceAreas={ serviceAreas }
            updateState={ updateState }
          />

          <Footer
            agentId={ agentId }
            currentAreas={ currentAreas }
            currentVisible={ currentVisible }
            serviceAreas={ serviceAreas }
            serviceAreasPath={ serviceAreasPath }
            updateState={ updateState }
          />
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
