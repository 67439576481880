import { Component }  from 'react';
import Row from './views/rows/Wrapper';

// 10 seconds
const fetchInterval = 10 * 1000;
let interval;

export default class Container extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: {}
    };
  }

  componentDidMount() {
    this.fetchBatchData();
    interval = setInterval(() => this.fetchBatchData(), fetchInterval);
  }

  componentWillUnmount() {
    clearInterval(interval);
  }

  fetchBatchData() {
    const {
      index_path,
    } = this.props;

    this.toggleRefreshIcon('enable');

    fetch(
      index_path,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-type': 'application/json',
        },
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        this.setState({ userData: response.counts });
      } else {
        Util.createFlash(response.errors.join(', '), 'danger');
      }

      setTimeout(() => this.toggleRefreshIcon('disable'), 1000);
    });
  }

  toggleRefreshIcon(action) {
    const refreshIcon = document.getElementById('refresh-indicator');
    const operation = /enable/.test(action) ? 'add' : 'remove';

    refreshIcon.classList[operation]('fa-spin-2x', 'blue-indicator');
  }

  buildRows() {
    const { userData } = this.state;
    const rows = [];

    for (const k in userData) {
      rows.push(<Row userName={ k } userData={ userData[k] } />);
    }

    return rows;
  }

  render() {
    return (
      <div>
        <i className="fa fa-refresh" id="refresh-indicator"></i>
        { this.buildRows() }
      </div>
    );
  }
}
