import AlertIcon from '../../../../LiveAccountManagement/images/alert.png';

const _disableSubmit = (event, props) => {
  const {
    action,
    toggleButtons,
    moveTo,
    primeSubscriptionPath,
    setAndGo,
    setAttribute,
  } = props;

  toggleButtons(event, 'disable');

  const data = JSON.stringify({ action: action, disable: true, remove_eligibility: true });

  fetch(
    primeSubscriptionPath,
    {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        'Content-type': 'application/json',
      },
      body: data,
    }
  )
  .then(response => response.json())
  .then(response => {
    if (response.success) {
      setAttribute('featureEnabled', false);
      setAttribute('primeEligible', false);
      moveTo('success');
    } else {
      throw new Error(response.errors);
    }
  })
  .catch(error => {
    setAndGo('error', error.message, 'fail');
  });
};

const Warning = (props) => {
  return (
    <div id='disable-warning'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ AlertIcon } />
      </div>

      <div className='rex-modal-heading'>
        Disable PRIME
      </div>

      <div className='rex-modal-contents'>
        <div className='rex-modal-subheading'>
          <p className='center'>Are you sure you want to disable PRIME for this agent? The agent will immediately lose<br /> access to their PRIME Dashboard and PRIME services, their subscription will be<br /> cancelled, and their plan terminated. This action cannot be undone.</p>
        </div>

        <div className='rex-modal-actions rex-modal-actions__centered'>
          <button
            className='rex-modal-action'
            onClick={ (e) => _disableSubmit(e, props) }
          >
            DISABLE PRIME
          </button>
        </div>
      </div>
    </div>
  )
};

export default Warning;
