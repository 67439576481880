import { Component } from 'react';

export default class AppointmentFresh extends Component {
  constructor(props){
    super(props);
    this.state = {
      showCallLaterModal: false
    }
    this.updateAppointment = this.updateAppointment.bind(this);
    this.preventBubbling = this.preventBubbling.bind(this);
  }

  containsErrors(){
    let { followUpTime, welcomeCallTask, errors, updateErrors } = this.props;
    let { assigned_to } = welcomeCallTask;

    let assignedToError = !assigned_to ? true : false;
    let followUpTimeError = !followUpTime  ? true : false;

    errors.assignedTo = assignedToError;
    errors.followUpTime = followUpTimeError;
    updateErrors(errors);

    return assignedToError || followUpTimeError;
  }

  updateAppointment(){
    if(this.containsErrors()) return;
    let { welcomeCallTask, followUpTime, currentUser, agentId, activeDate, timeZone, resetState, setUpdating, note } = this.props;
    setUpdating(true);
    let parsedTime =  Util.parseDateTime(followUpTime, -1 * (timeZone.offset + 8));
    let updateParams = {
      completed_task: { task_id: welcomeCallTask.id },
      new_task: {
        taskable_id: agentId,
        follow_up: parsedTime,
        assigned_to: welcomeCallTask.assigned_to
      },
      note: {
        message: `Appointment was scheduled for ${parsedTime}. ${note}`,
        noteable_id: agentId
      }
    }

    Util.welcomeCallAppointmentScheduled(updateParams)
      .then(() => resetState());
  }

  preventBubbling(e){
    e.stopPropagation();
  }

  render(){
    let {
      showAppointment, assigneeOptions, errors, followUpTime,
      updateNote, note, updating, dateSelector, toggleDateSelector,
      welcomeCallTask
    } = this.props;

    return(
      <div className={showAppointment ? 'cs-call-from' : 'cs-call-from-collapse'}>
        <div className='cs-call-from-inner'>
          <label>With: </label>
          { assigneeOptions() }
          <div className='follow-up'>
            <label>Appointment (agent time)</label>
            <div className={errors.followUpTime ? 'follow-up-fields cs-error' : 'follow-up-fields'}>
              <input
                className='date-picker'
                type='input'
                placeholder='Select a date'
                onClick={() => {if(welcomeCallTask.assigned_to) toggleDateSelector()}}
                value={followUpTime || ''}
              />
            </div>
          </div>
          <label>Note: </label>
          <textarea
            className={errors.note ? 'cs-error' : ''}
            onChange={updateNote}
            value={note}
          />
          <input
            className='button'
            disabled={updating}
            type='button'
            value='Save'
            onClick={this.updateAppointment}
          />
        </div>
        { dateSelector() }
      </div>
    );
  }
}
