import { Component } from 'react';

export default class Loader extends Component {
  constructor( props ) {
    super(props);

    this.state = {
      show: true,
      name: '',
      styles: {
        opacity: 1
      }
    }
  }

  componentDidMount() {
    this.props.events.on( 'dialer:loaded', this._dialerLoaded.bind( this ) );
    this.props.events.on( 'dialer:submit', this._dialerSubmit.bind( this ) );
  }

  render() {
    return(
      <div className='loading-container' style={ this.state.styles }>
        <div className='ripple-loader blue-border'></div>
        <div className='call-started'>
          Call Started with { this.state.name }!
        </div>
      </div>
    )
  }

  _dialerLoaded( data ) {
    this.setState({ name: data.name }, () => {
      $( '.call-started' ).show();

      setTimeout(() => {
        var show = this.state.show;

        this.setState({
          show:   !show,
          styles: { opacity: 0 }
        });
      }, 2000);

      setTimeout(() => {
        this.setState({
          styles: { opacity: 0, zIndex: -1 }
        });
      }, 2600 );
    });
  }

  _dialerSubmit() {
    $( '.call-started' ).hide();

    this.setState({
      show: true,
      styles: {
        opacity: 1,
        zIndex: 900
      }
    });
  }
}
