import { Component } from 'react';

export default class AppointmentDateSelector extends Component {
  constructor(props){
    super(props);
  }

  getDayString(activeDate){
    let { weekdays } = this.props;
    if(Object.keys(weekdays).length > 0) return weekdays[activeDate].displayDate
  }

  updateDate(value){
    let { activeDatetime, updateDate, resetErrorMessage } = this.props;
    resetErrorMessage();
    let newDatetime = this.incrementDate(activeDatetime, value);
    if(this.validRange(newDatetime)) updateDate(new Date(newDatetime));
  }

  validRange(datetime){
    let { weekdays } = this.props;
    let weekdayKeys = Object.keys(weekdays)
    let weekdayLength = weekdayKeys.length;
    let minRange = new Date(weekdays[weekdayKeys[0]].adjustedDate).setHours(0);
    let maxRange = new Date(weekdays[weekdayKeys[weekdayLength - 1]].adjustedDate).setHours(23);

    return minRange < datetime && datetime < maxRange
  }

  incrementDate(datetime, value) {
    let date = new Date(datetime);
    return date.setDate(date.getDate() + value);
  }

  render(){
    let { activeDate } = this.props;
    return(
      <div className='cs-appt-date-selector'>
        <div className='select-container'>
          Date: &nbsp;&nbsp;
          <div className='select-button' onClick={() => this.updateDate(-1)}>
            <i className="fa fa-minus-square" aria-hidden="true"></i>
          </div>
          <div className='date'>
            { this.getDayString(activeDate) }
          </div>
          <div className='select-button' onClick={() => this.updateDate(1)}>
            <i className="fa fa-plus-square" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    )
  }
}
