import BuyerRequest from './referral_details/BuyerRequest.js';
import SellerRequest from './referral_details/SellerRequest.js';

const ReferralDetails = props => {
  const {
    lead: {
      seller: {
        type_home,
      },
      buyer: {
        mortgage_status,
      },
    },
    leadType,
    country_options,
    us_state_options,
    ca_province_options,
    type_home_options,
    reason_options,
    mortgage_options,
    bed_options,
    bath_options,
    seller_range,
    seller_min_price,
    seller_max_price,
    time_frame_options,
    buyer_range,
    buyer_min_price,
    buyer_max_price,
    updateFieldHandler,
  } = props;

  const divider = <div className="divider"></div>;

  const sellerView =  <SellerRequest
    type_home={ type_home }
    leadType={ leadType }
    country_options={ country_options }
    us_state_options={ us_state_options }
    ca_province_options={ ca_province_options }
    reason_options={ reason_options }
    seller_max_price={ seller_max_price }
    seller_min_price={ seller_min_price }
    seller_range={ seller_range }
    time_frame_options={ time_frame_options }
    type_home_options={ type_home_options }
    updateFieldHandler={ updateFieldHandler }
  />;

  const buyerView = <BuyerRequest
    mortgage_status={ mortgage_status }
    leadType={ leadType }
    country_options={ country_options }
    us_state_options={ us_state_options }
    ca_province_options={ ca_province_options }
    bath_options={ bath_options }
    bed_options={ bed_options }
    buyer_max_price={ buyer_max_price }
    buyer_min_price={ buyer_min_price }
    buyer_range={ buyer_range }
    mortgage_options={ mortgage_options }
    time_frame_options={ time_frame_options }
    updateFieldHandler={ updateFieldHandler }
  />;


  const displayView = () => {
    const view = [];

    if (leadType === 'buy_sell') {
      view.push(sellerView);
      view.push(divider);
      view.push(buyerView);
      view.push(divider);
    } else if (leadType === 'sell') {
      view.push(sellerView);
      view.push(divider);
    } else {
      view.push(buyerView);
      view.push(divider);
    }

    return view;
  };

  return (
    <div className="section">
      <div className="header">
        <div className="icon-wrapper"><i className="icon briefcase"></i></div>
        <div className="title-wrapper">
          <h2>Referral Details</h2>
        </div>
      </div>

      { leadType && displayView() }
    </div>
  );
};

export default ReferralDetails;
