import React, { Component } from 'react';
import { store } from '../../../index';

import { getStateProps } from '../../../utils';
import { selectedCountryInfo } from '../utils';

import BaseInputContainer from '../index';

import View from './view';

export default class CountryRadioGroup extends BaseInputContainer {
  constructor(props) {
    super(props);
    this.property = 'country';
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { dispatch } = store;
    const zip = store.getState().info.get('zip');

    dispatch({
      type: 'VALIDATE_COUNTRY',
      data: {
        country: value,
        zip,
      },
    });

    dispatch({
      type: 'VALIDATE_ZIP',
      data: {
        country: value,
        value: zip,
      },
    });

    dispatch({ type: 'COUNTRY_CHANGE' });
    dispatch({ type: 'INVALIDATE_BUYER_CITIES'});

    this.setInfoField(name, value);
  }

  render() {
    const {
      classNames,
      display,
      id,
      index,
    } = this.props;

    const {
      focused,
      valid,
      value,
     } = this.state;

     const countryInfo = selectedCountryInfo(value);
     const { countries } = countryInfo;

    return (
      <View
        classNames={ classNames }
        focused={ focused }
        id={ id }
        index={ index }
        name={ this.property }
        options={ countries }
        valid={ valid }
        value={ value }

        onChange={ this.handleChange }
        onFocus={ this.handleOnFocus }
      />
    );
  }
}
