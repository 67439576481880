import { Component }  from 'react';
import Modal from '../Modal';

export default class Confirm extends Component {
  constructor() {
    super();

    this.abort = this.abort.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  componentDidMount() {
    this.promise = new this.deferredPromise();
    const wrapper = document.querySelector('.product-dashboard-wrapper .dashboard-modal');

    wrapper.addEventListener('closeModal', this.cleanup);

    return ReactDOM.findDOMNode(this.refs.confirm).focus();
  }

  abort() {
    return this.promise.reject();
  }

  confirm() {
    return this.promise.resolve();
  }

  cleanup() {
    const modal = document.querySelector('.product-dashboard-wrapper .dashboard-modal div');
    ReactDOM.unmountComponentAtNode(modal);

    return modal.remove();
  }

  deferredPromise() {
    return ((resolve, reject) => {
      let p = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });

      return {
        promise: p,
        reject,
        resolve
      };
    })();
  }

  static modal(message, options = {}) {
    const props = { message: message, ...options };
    const wrapper = document.querySelector('.product-dashboard-wrapper .dashboard-modal');
    wrapper.innerHTML = null;
    const component = ReactDOM.render(<Confirm {...props}/>, wrapper.appendChild(document.createElement('div')));

    return component.promise.promise;
  }

  render() {
    let modalBody;
    const {
      description,
      message,
      confirmLabel,
      abortLabel,
      modalSize,
      textAlign,
      buttonSize
    } = this.props;
    const btnSize = buttonSize || 'btn-md';
    const txtAlign = textAlign || 'text-left';

    if (description) {
      modalBody = (
        <div className={`modal-body ${txtAlign}`}>
          <div dangerouslySetInnerHTML={{__html: description}} />
        </div>
      );
    }

    return (
      <Modal size={modalSize}>
        <div className={`modal-header ${txtAlign}`}>
          <div className='modal-title' dangerouslySetInnerHTML={{__html: message}} />
          <button type='button' className='close close-icon' onClick={this.abort}>
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        {modalBody}
        <div className='modal-footer'>
          <div className={txtAlign}>
            <button role='confirm' type='button' className={`btn ${btnSize} btn-rex btn-orange`} ref='confirm' onClick={this.confirm}>
              {confirmLabel || 'OK'}
            </button>

            { abortLabel &&
              <button role='abort' type='button' className={`btn ${btnSize} btn-rex btn-orange-tricolor reverse`} onClick={this.abort}>
                {abortLabel || 'Cancel'}
              </button>
            }
          </div>
        </div>
      </Modal>
    );
  }
}
