import  { Component } from  'react';
import mainIcon from '../images/call-connected.svg';
import * as Utilities from '../Utilities';

export default class AgentConnected extends Component {
  constructor(props){
    super(props);

    this.state = {
      secondsSinceCallStart: null
    };
  }

  componentDidMount() {
    this.createTimer();
  }

  componentWillUnmount() {
    clearInterval(this.intervalTimer);
  }

  createTimer() {
    this.intervalTimer = setInterval(() => {
      this.setState({ secondsSinceCallStart: Utilities.calculateSecondsSinceCallStart(this.props.agentCallStartTime)});
    }, 500)
  }

  render(){
    return (
      <div className="button agent-connected">
        <div className='row'>
          <div className='col-sm-2'>
            <img src={ mainIcon } />
          </div>
          <div className='col-sm-6'>
            <div className='row top-row status-text'>
              Agent <span className='timer-text'>{ this.state.secondsSinceCallStart }</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
