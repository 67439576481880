import React, { Component } from 'react';
import { connect } from 'react-redux';
import View from './view';

function mapStateToProps(state) {
  return {
    note: state.note.get('note'),
    submitting: state.actions.get('submitting'),
  }
}

class Note extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { dispatch } = this.props;
    const { value } = e.target;

    dispatch({
      type: 'SET_NOTE',
      data: {
        note: value,
      },
    });
  }

  render() {
    const { note, submitting } = this.props;

    const data = { note, submitting };
    return (
      <View
        data={ data }

        handleChange={ this.handleChange }
      />
    );
  }
}

export default connect(mapStateToProps)(Note);
