import { Component } from 'react';
import request from 'superagent';

export default class LexisNexisModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record_params: {
        result: '',
        source: '',
        attribute1: 'Phone',
        attribute2: 'Name',
        lead_id: this.props.lead_id,
      },
      records: [],
     }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleResultChange = this.handleResultChange.bind(this);
    this.handleSourceChange = this.handleSourceChange.bind(this);
  }

  componentDidMount() {
    console.log('component did mount')
    console.log(this.props)
    if (this.props.lead_id) {
      this.obtainValidationRecords()
    }
  }

  obtainValidationRecords() {
    $.get('/qual/queue/validation_records', { lead_id: this.props.lead_id }, function (result) {
      this.setState({
        records: result.validation_records
      })
    }.bind(this))
  }

  render() {
    return (
      <div className='lexis_nexis_overlay' onClick={this.handleOverlayClick}>
        <div className='lexis_nexis_modal'>
          <div className='heading'>
            <div className='title'>
              <p>{this.props.data.lead.first_name + ' ' + this.props.data.lead.last_name}</p>
              <p>{this.props.data.lead.phone_number}</p>
              <p>{this.props.data.email}</p>
            </div>
            <div className='close_modal' onClick={this.closeModal}>
              X
            </div>
          </div>
          <div className='modal_content'>
            <div className='left_content'>
              <div className='left_content_top'>
                {this.renderRecordsMatchResults()}
              </div>
              <div className='left_content_bottom'>
                {this._renderDispositionButtons()}
              </div>
            </div>
            <div className='right_content'>
              {this._renderLexisNexisSearch()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderRecordsMatchResults() {
    return (
      <div className='records_match_results'>
        <div className='title'>Records Match Results</div>
        <table>
          <thead>
            <tr>
              <th>+/-</th>
              <th>Source</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type='radio'
                  name='result'
                  value='+'
                  checked={this.state.record_params.result === '+'}
                  onChange={this.handleChange}
                /> +
              </td>
              <td>
                <input
                  type='radio'
                  name='source'
                  value='WhitePages'
                  checked={this.state.record_params.source === 'WhitePages'}
                  onChange={this.handleChange}
                /> WhitePages
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type='radio'
                  name='result'
                  value='-'
                  checked={this.state.record_params.result === '-'}
                  onChange={this.handleChange}
                /> -
              </td>
              <td>
                <input
                  type='radio'
                  name='source'
                  value='LexisNexis'
                  checked={this.state.record_params.source === 'LexisNexis'}
                  onChange={this.handleChange}
                /> LexisNexis
              </td>
            </tr>
          </tbody>
        </table>
        <div className='submit_buttons'>
          <div className='button' onClick={this.submitRecordMatch.bind(this)}>
            Save
          </div>
          <div className='button' onClick={this.resetRecordMatch.bind(this)}>
            Reset
          </div>
        </div>
        <div className='verifications'>
          <div className='title'>
            Verifications
          </div>
          {
            this.state.records.length > 0 ? (
              this.state.records.map(function(record, idx) {
                return (
                <div key={idx} style={{ paddingBottom: '10px' }} className={record.class + ' height-100 display-flex-vertical-center'} id={"validation_record_" + record.id} title={record.title}>
                  {' '}
                  {record.result}
                  {' '}
                  {record.source}
                  {' '}
                  {record.attributes}
                </div>
                )
              })
            ) : (
              <div>
                No validations found
              </div>
            )
          }
        </div>
      </div>
    )
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({ 
      ...prevState,
      record_params: {
        ...prevState.record_params,
        [name]: value
      }
    }), () => {
      this.obtainValidationRecords();
    })
  };

  handleResultChange(e) {
    const value = e.target.value;
    e.preventDefault();
    this.setState((prevState) => ({
      ...prevState,
      record_params: {
        ...prevState.record_params,
        result: value,
      },
    }));
  }

  handleSourceChange(e) {
    const value = e.target.value;
    e.preventDefault();
    this.setState((prevState) => ({
      ...prevState,
      record_params: {
        ...prevState.record_params,
        source: value,
      },
    }));
  }

  handleOverlayClick(e) {
    e.preventDefault();
    if (e.target.className === 'lexis_nexis_overlay') {
      this.closeModal();
    }
  }

  submitRecordMatch() {
    request
      .post(`/leads/robinate/${this.props.data.lead.id}`)
      .send({ validation_record: this.state.record_params })
      .end((err, res) => {
        if (err) {
          console.log(err);
        } else {
          console.log(res);
          this.obtainValidationRecords()
        }
      });
  }

  resetRecordMatch() {
    this.setState({
      record_params: {
        result: '',
        source: '',
        attribute1: 'Phone',
        attribute2: 'Name',
        lead_id: this.props.lead_id,
      }
    });
  }

  _renderDispositionButtons() {
    return(
      <div className='disposition_buttons'>
        <div className='requal_button' onClick={this.props.requalSubmit.bind(this)}>Submit Requal Attempt</div>
        <div className='qual_button' onClick={this.props.openPCRModal.bind(this)}>Start Pre Connection Research</div>
      </div>
    )
  }

  _renderLexisNexisSearch() {
    return(
      <iframe className='lexis_nexis_iframe' width='100%' height='100%'
        src={`/leads/${this.props.data.lead.id}/accurint_requests?request_type=reverse_phone_search`}>
      </iframe>
    )
  }
  closeModal() {
    $('lexis_nexis_modal, .lexis_nexis_overlay').hide();
  }
}
