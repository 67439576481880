import React             from 'react';
import CancelSuccess     from './images/cancel_success.png'
import ReactivateSuccess from './images/reactivate_success.png'

const CancelReactivateSuccessView = (props) => {
  const {
    canceledLive,
    finished,
    reset,
  } = props;

  let finishedHeader = 'LIVE Subscription Successfully Canceled';
  let icon = CancelSuccess;
  let finishedSubheadings = [
    'Agent has successfully been charged for any remaining balance',
    'Agent can continue to access LIVE and monitor the progress of their leads, but LIVE submission features have been disabled (including their LIVE email inbox)',
    'Agent can reactivate from within their LIVE account easily and will be charged a monthly fee immediately if they do'
  ];

  if (canceledLive) {
    finishedHeader = 'LIVE Subscription Successfully Reactivated';
    icon = ReactivateSuccess;
    finishedSubheadings = [
      'Agent has full access to all LIVE features',
      "Agent's LIVE email inbox has been enabled"
    ];
  }

  if (canceledLive && reset) {
    finishedSubheadings = ['Agent has been successfully charged and their billing date has been reset'].concat(finishedSubheadings);
  }

  return (
    <div className='toggle-live-subscription'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ icon } />
      </div>
      <div className='rex-modal-heading'>
        { finishedHeader }
      </div>
      <div className='rex-modal-contents'>
        <div className='rex-modal-subheading '>
          <ul>
            {
              finishedSubheadings.map((subheading, key) => (
                <li key={ key }>{ `- ${subheading}` }</li>
              ))
            }
          </ul>
        </div>
        <div className='rex-modal-actions rex-modal-actions__centered'>
          <button
            className='rex-modal-action rex-modal-actions__centered'
            onClick={ finished }
          >
            ClOSE THIS WINDOW
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelReactivateSuccessView;
