import ReactivateSuccessIcon from '../../../../LiveAccountManagement/images/reactivate_success.png';

const Success = (props) => {
  const {
    toggleModal,
  } = props;

  return (
    <div id='reactivate-success'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ ReactivateSuccessIcon } />
      </div>

      <div className='rex-modal-heading'>
        PRIME Subscription Successfully Reactivated
      </div>

      <div className='rex-modal-contents'>
        <div className='rex-modal-subheading'>
          <p className='centered'>Agent has full access to all PRIME features.</p>
        </div>

        <div className='rex-modal-actions rex-modal-actions__centered'>
          <button
            className='rex-modal-action'
            onClick={ () => toggleModal(false) }
          >
            CLOSE THIS WINDOW
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
