import { Component } from 'react';

const RequalBlock = ({ data, updateState, finalAttempt, fromHomeCaptain, isCompassLead }) =>  {

  const {
    home_captain,
    intent,
    partner_id,
    qual_attempt_count,
    status_button,
    requal_email_limit
  } = data;

  const maxHCRequalAttemptsReached = home_captain && qual_attempt_count >= 2;

  const qualLabel = maxHCRequalAttemptsReached
    ? 'Assume intent and send to Matching'
    : 'Send back to Qual';

  const requalStyling = finalAttempt
    ? { opacity: '0.4' }
    : null;

  const selectOptions = () => {
    let options;

    if (fromHomeCaptain) {
      if (maxHCRequalAttemptsReached) {
        options = ['Good - Assume Intent']
      } else {
        options = [
          'Unknown - Answering Machine/Name',
          'Unknown - Answering Machine/No Name',
          'Unknown - Answering Machine/Different Name',
          'Unknown - No Answer',
          'Unknown - Other'
        ];
      }
    } else {
      options = [
        'Unknown - Answering Machine/No Name',
        'Unknown - No Answer',
        'Unknown - Answering Machine/Different Name',
        'Unknown - Other'
      ];
      if (!intent) options.splice(0, 0, 'Unknown - Answering Machine/Name');
    }

    return options;
  }

  const handleChange = e => {
    let selection = e.target.value;
    data.pmr_form.qual_status = selection;

    data.status_button.note_status = selection;
    data.status_button.action = maxHCRequalAttemptsReached ? 'qual' : 'requal';
    data.status_button.text = determineButtonText(selection);

    updateState(data);
  }

  const determineButtonText = selection => {
    if (maxHCRequalAttemptsReached) {
      return 'Start Pre Connection Research';
    } else if (qual_attempt_count > 0 || firstAttemptHC(selection)) {
      return 'Submit Attempt';
    }

    return 'Send to Requal';
  }

  const firstAttemptHC = selection => {
    return home_captain && qual_attempt_count == 0 && selection === 'Unknown - Answering Machine/Name'
  }

  const onRadioChange = e => {
    data[e.target.name] = e.target.value;
    updateState(data);
  }

  return(
    <div className='dropdown_box default' style={requalStyling}>
      <div className='label'>{qualLabel}</div>
      <select
        disabled={finalAttempt}
        value={ status_button.note_status }
        onChange={ handleChange }
      >
        <option>Choose Status</option>
        { selectOptions().map((option, idx) => (
          <option key={idx} value={option}>{option}</option>
        )) }
      </select>
      {isCompassLead || requal_email_limit
        ? null
        : (
          <div className={status_button.action === 'requal' ? 'dropdown_fields' : 'hidden'}>
            <div>
              <input
                type='radio'
                name='requal_email'
                id='requal_email_normal'
                value='normal_requal_email'
                onChange={onRadioChange}
              />
              <label htmlFor='requal_email_normal'>Normal requal email</label>
            </div>
            <div>
              <input
                type='radio'
                name='requal_email'
                id='requal_email_location'
                value='location_requal_email'
                onChange={onRadioChange}
              />
              <label htmlFor='requal_email_location'>Location error email</label>
            </div>
          </div>
        )
      }

    </div>
  )
}

export default RequalBlock;
