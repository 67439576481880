import React, { Component } from 'react';
import DisableLiveAccess from './DisableLiveAccessView';

export default class DisableLiveAccessContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: 'confirm',
      loading: false,
    };

    this.disableAccess = this.disableAccess.bind(this);
    this.finished = this.finished.bind(this);
  }

  disableAccess() {
    const {
      agentId,
      unsuccessfulRequest,
      updateManagement
     } = this.props;

     updateManagement({ loading: true });
     this.setState({ loading: true });

     fetch(`/agents/${agentId}/disable_live`, { credentials: 'same-origin', method: 'POST' })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          this.goTo('failed');
        } else {
          this.goTo('success');
        }
      })
      .catch(err => {
        unsuccessfulRequest(err);
      });
  }

  goTo(display) {
    const {
      updateManagement,
    } = this.props;

    let agentStatus = document.querySelector('.agent-live-status');
    agentStatus.innerText = 'disabled';

    this.setState({
      display: display,
      loading: false,
    });
    updateManagement({
      loading: false,
    });
  }

  finished() {
    const { updateManagement } = this.props;
    updateManagement({
      displaying: null,
      modalOpen: false,
      hasEnabledLiveFeature: false,
    });
  }

  render() {
    const { display, loading } = this.state;
    const { balanceOwed } = this.props;
    return (
      <DisableLiveAccess
        display={ display }
        loading={ loading }
        balanceOwed={ balanceOwed }
        disableAccess={ this.disableAccess }
        finished={ this.finished }
      />
    );
  }
}
