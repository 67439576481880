import { Component }  from 'react';
import Header         from './Header';
import Details        from './Details';
import Emails         from './Emails';
import DialerDispo    from './DialerDispo';
import Notes          from './Notes';
import { AsYouType } from 'libphonenumber-js'

export default class WelcomeCallDialer extends Component {
  constructor(props){
    super(props);
    this.state = {
      queue: props.queue,
      agentId: this.props.agentId,
      paused: false,
      submitting: true,
      updatingAgent: false,
      redirect: false
    }
    this.togglePause         = this.togglePause.bind(this);
    this.pauseDialer         = this.pauseDialer.bind(this);
    this.redirectDialer      = this.redirectDialer.bind(this);
    this.obtainAgentInfo     = this.obtainAgentInfo.bind(this);
    this.setSubmitting       = this.setSubmitting.bind(this);
    this.fetchAgentFromQueue = this.fetchAgentFromQueue.bind(this);
    this.handleUnload        = this.handleUnload.bind(this);
    this.toggleUpdatingAgent = this.toggleUpdatingAgent.bind(this);
  }

  componentDidMount(){
    let { agent, agentId } = this.props;
    if(agent) agentId = agent.id;
    this.setState({ agentId })
    agentId ? this.obtainAgentInfo(agentId) : this.fetchAgentFromQueue();
    $('.container-fluid').css('background-color', '#f2f2f2')
    window.addEventListener('beforeunload', this.handleUnload, false);
    window.addEventListener('unload', this.handleUnload, false);
  }

  componentWillUnmount(){
    window.removeEventListener('beforeunload', this.handleUnload, false);
    window.removeEventListener('unload', this.handleUnload, false);
  }

  handleUnload(){
    this.unreserveAgent();
  }

  unreserveAgent(){
    Util.unreserveAgent({ user_id: this.state.user.id });
  }

  obtainAgentInfo(agentId = this.state.agentId){
    if(agentId){
      let options = { agent_id: agentId };
      Util.fetchAgentData(options)
        .then((result) => {
          if(result.error){
            location.href = '/dialer'
          } else {
            this.setAgent(result);
          }
        })
    }
  }

  fetchAgentFromQueue(){
    if (this.state.redirect) {
      window.location.replace(`/customer_success/${this.state.agentId}`)
      return;
    } else if(this.state.paused === true) {
      window.location.replace('/dialer');
      return;
    }
    window.scrollTo(0, 0);
    if(this.props.queue === 'fresh'){
      Util.fetchFreshAgent()
        .then((result) => {
          if(result.error){
            return $.Deferred().reject(result.error);
          } else {
            this.setAgent(result);
          }
        })
        .then(null, (err) => { location.href = '/dialer' })
    }
  }

  setAgent(result){
    if(result){
      if(result.user) document.title = result.user.first_name + ' ' + result.user.last_name;
      this.setState({
        afssId: result.afss_id,
        agentId: result.agent_id,
        company: result.agent_company || {},
        user: result.user,
        notes: result.notes,
        questionResponses: {
          1: result.responses[1] || '',
          2: result.responses[2] || '',
          4: result.responses[4] || '',
          6: result.responses[6] || '',
          7: result.responses[7] || ''
        },
        welcomeCallTask: result.task,
        emails: result.emails,
        timeZone: {
          time_zone: result.user.time_zone,
          time_zone_short: result.user.time_zone_short,
          offset: result.user.offset
        }
      }, () => {
        Util.fetchPhoto({ user_id: this.state.user.id })
          .then((result) => this.setState({ photo: result.photo, submitting: false, updatingAgent: false }))
      });
    }
  }

  togglePause(){
    this.setState({ paused: !this.state.paused });
  }

  toggleUpdatingAgent(bool = false){
    this.setState({ updatingAgent: bool });
  }

  setSubmitting(bool){
    this.setState({ submitting: bool });
  }

  pauseDialer(){
    this.setState({ paused: true })
  }

  redirectDialer(){
    this.setState({ redirect: true })
  }

  render(){
    let {
      queue, agent, paused, timeZone, notes,
      photo, user, agentId, welcomeCallTask,
      emails, questionResponses, submitting,
      updatingAgent, company, afssId
    } = this.state;

    let time, phoneNumber, formattedPhoneNumber, location, city, state, agentSearch, title;

    if(welcomeCallTask && welcomeCallTask.follow_up && timeZone){
      time = Util.parseDateTime(welcomeCallTask.follow_up, timeZone.offset) + ' ' + timeZone.time_zone_short;
    } else {
      time = 'No Appointment';
    }
    if(user && Object.keys(user).length){
      phoneNumber = user.phone_mobile || user.phone_office;
      let parts = phoneNumber.split('ext')
      let ext = !!parts[1] ? ' ext ' + parts[1] : ''
      formattedPhoneNumber = new AsYouType('US').input(parts[0]) + ext;
      agentSearch = `${user.first_name} ${user.last_name}`;
    }
    if(company){
      city = company.city  ? company.city + ',' : '';
      state = company.state  ? company.state : '';
      location = `${city} ${state}`;
    }

    return(
      <section className='wc-outer'>
        <section className='wc-content'>
          <Header
            togglePause={this.togglePause}
            paused={paused}
            time={time}
            agentSearch={agentSearch}
            location={location}
            number={formattedPhoneNumber}
            agentId={agentId}
          />
          <Details
            afssId={afssId}
            agentId={agentId}
            photo={photo}
            user={user}
            company={company}
            queue={queue}
            timeZone={timeZone}
            updatingAgent={updatingAgent}
            questionResponses={questionResponses}
            toggleUpdatingAgent={this.toggleUpdatingAgent}
            obtainAgentInfo={this.obtainAgentInfo}
            currentUser={this.props.currentUser}
          />
          <Emails
            agentId={agentId}
            emails={emails}
          />
          <Notes
            notes={notes}
            user={user}
            agentId={agentId}
            currentUser={this.props.currentUser}
            obtainAgentInfo={this.obtainAgentInfo}
          />
          <DialerDispo
            agentId={agentId}
            timeZone={timeZone}
            task={welcomeCallTask}
            queue={queue}
            setSubmitting={this.setSubmitting}
            pauseDialer={this.pauseDialer}
            redirectDialer={this.redirectDialer}
            currentUser={this.props.currentUser}
            fetchAgentFromQueue={this.fetchAgentFromQueue}
          />
        </section>
        <section className={submitting ?  'wc-loading' : 'hidden'}>
          <div className='unscheduled-dialer-loading fa fa-spinner fa-spin'></div>
        </section>
      </section>
    )
  }
}
