import React, { Component } from 'react';
import { connect } from 'react-redux';
import View from './view';

function mapStateToProps(state) {
  return {
    duplicates: state.metadata.get('duplicates'),
    id: state.actiondialer.get('leadId'),
    intent: state.metadata.get('intent'),
    phoneToName: state.metadata.get('phoneToName'),
    submission: state.headers.get('submission'),
    subscriberName: state.metadata.get('subscriberName'),
    verifications: state.metadata.get('verifications'),
  }
}

class Metadata extends Component {
  render() {
    const {
      id,
      intent,
      phoneToName,
      submission,
      subscriberName,
      verifications,
      duplicates,
    } = this.props;

    const data = {
      id,
      intent,
      phoneToName,
      submission,
      subscriberName,
      verifications,
      duplicates,
    };

    return (
      <View data={ data }/>
    );
  }
}

export default connect(mapStateToProps)(Metadata);
