import Area from './results/ServiceArea';
import Option from './results/Option';

const Results = (props) => {
  const {
    currentAreas,
    requestingAreas,
    serviceAreas,
  } = props;

  const fetching = requestingAreas ? 'fetching' : '';

  function buildOptions(cityName) {
    const zipCodes = serviceAreas[cityName];
    const currZipCodes = currentAreas[cityName];
    const options = [];

    options.push(<Option key='select-all' selectAll value={ 'Select All' } />);

    zipCodes.forEach(zipCode => {
      options.push(
        <Option
          key={ zipCode }
          value={ zipCode }
          selected={ currZipCodes && currZipCodes.includes(zipCode) }
        />
      );
    });

    return options;
  }

  function buildServiceAreas() {
    const areas = [];

    Object.keys(serviceAreas).forEach(key => {
      areas.push(
        <Area key={ key } cityName={ key }>
          { buildOptions(key) }
        </Area>

      );
    });

    return areas;
  }

  return (
    <div className='service-area-results mt-15'>
      <h2>Search Results</h2>
      <div className={ `service-areas-render ${fetching}` }>
        { !requestingAreas && buildServiceAreas() }
      </div>
    </div>
  );
};

export default Results;
