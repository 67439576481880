import { Component } from 'react';

import VerificationRecords from './VerificationRecords';
import QualAttempts        from './QualAttempts';

const Banner = ({ state, finalAttempt, fromHomeCaptain }) => {
  const {
    duplicates,
    note,
    intent,
    lead,
    origin,
    qual_attempt_count,
    white_pages,
    dual_transaction,
    dual_transaction_buyer_lead,
    robo_calls
  } = state;

  const name = `${lead.first_name} ${lead.last_name}`;

  const whitePagesData = (white_pages && white_pages.data_values)
    ? JSON.parse(white_pages.data_values).phone
    : {};

  const phoneToName = whitePagesData && whitePagesData.is_valid
    ? whitePagesData.phone_to_name
    : 'NO WHITEPAGES';

  const subscriberName = whitePagesData && whitePagesData.is_valid
    ? whitePagesData.subscriber_name
    : 'DATA FOUND';

  const buyerContentClasses = (note.message === '' && duplicates === '')
    ? ['hidden']
    : ['buyer_content'];

  const qualAttempts = finalAttempt
    ? `${qual_attempt_count} (Final)`
    : `${qual_attempt_count}`;

  const renderRequalContent = () => {
    if(parseInt(qual_attempt_count, 10) > 0 || (!!robo_calls && robo_calls.length > 0)) {
      return (
        <div className='requal-content width-100'>
          {verification_records()}
          <div className='right_content width-50 inline-block v-align-top'>
            {qual_attempts()}
            {robo_call_data()}
          </div>
        </div>
      )
    } else {
      return null;
    }
  }

  const verification_records = () => {
    if(parseInt(qual_attempt_count, 10) > 0) {
      return(
        <div className='left_content width-50 inline-block'>
          <VerificationRecords lead_id={lead.id}/>
        </div>
      );
    } else {
      return null;
    }
  }

  const qual_attempts = () => {
    if(parseInt(qual_attempt_count, 10) > 0) {
      return(<QualAttempts lead_id={lead.id}/>);
    } else {
      return null;
    }
  }

  const robo_call_data = () => {
    if (!!robo_calls && robo_calls.length === 0) { return null; }

    return(
      <div className='results inline-block v-align-top width-100' style={{padding: '15px 0px'}}>
        <div className=''>
          <h3 style={{padding: 0, marginBottom: '10px'}}>Automatic Qualification Attempts: </h3>
        </div>
        <div className='v-align-top inline-block' style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
          {robo_calls.map(call => {
            return(
              <div>{call.called_at + ' - ' + call.disposition + ' - ' + call.call_name}</div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderIntent = () => {
    let text = 'Not Confirmed';

    if (fromHomeCaptain) {
      if(qual_attempt_count >= 2) {
        text = 'Confirmed';
      } else if(intent) {
        text =  'DELAYED INTENT';
      }
    } else {
      if (intent){
        text = 'Confirmed'
      }
    }

    return <div className='data'>{text}</div>
  };

  const determineIntentClass = () => {
    if (fromHomeCaptain) {
      if (!intent) {
        return ' not_confirmed';
      } else if (qual_attempt_count !== 2) {
        return ' delayed';
      }
    } else if (!intent) {
      return ' not_confirmed';
    }
  };

  const dualTransactionBuyerLink = () => {
    if (dual_transaction) {
      return (
        <div>
          <div className='content_label'>
            Dual Transaction:
          </div>
          <div className='content_data'>
            <a href={`/l/${dual_transaction_buyer_lead.id}`} target='_blank'>
              {dual_transaction_buyer_lead.id}
            </a>
          </div>
        </div>
      );
    }
  };

  const intentClass = determineIntentClass();

  const leadType = () => {
    let type = 'Seller Name';
    if (dual_transaction) {
      type = 'Lead Name';
    } else if (lead.lead_type == 'buyer') {
      type = 'Buyer Name';
    }
    return type
  };

  return (
    <div className='buyer_intent'>
      <div className={`intent_header ${intentClass}`}>
        <div className='box w248'>
          <div className='heading'>
            { leadType() }
          </div>
          <div className='data'>
            {name}
          </div>
        </div>
        <div className='box w200' style={{ width: '213px' }}>
          <div className='heading'>
            Origin
          </div>
          <div className='data'>
            {origin}
          </div>
        </div>
        <div className='box w200'>
          <div className='heading'>
            Qual Attempts
          </div>
          <div className='data'>
            {qualAttempts}
          </div>
        </div>
        <div className='box w190'>
          <div className='heading'>
            Intent
          </div>
          { renderIntent() }
        </div>
        <div className='box w211'>
          <div className='heading'>
            Phone to Name
          </div>
          <div className='data'>
            {phoneToName}
          </div>
        </div>
        <div className='box'>
          <div className='heading'>
            Subscriber to Name
          </div>
          <div className='data'>
            {subscriberName}
          </div>
        </div>
      </div>
      <div className={ buyerContentClasses }>
        <div className='left_content'>
          <div className={note.message === '' ? 'hidden' : ''}>
            <div className='content_label'>
              Qual Instructions:
            </div>
            <div className='content_data inst'>
              <div className='qual_message'>
                {note.message || ''}
              </div>
              <div className='author'>
                {`- ${note.author}`}
              </div>
            </div>
          </div>
        </div>
        <div className='right_content'>
          <div className={duplicates === '' ? 'hidden' : ''}>
            <div className='content_label dupes'>
              Duplicates:
            </div>
            <div className='content_data'>
              <span className='possible-duplicates' dangerouslySetInnerHTML={{ __html: duplicates }}>
              </span>
            </div>
          </div>
          { dualTransactionBuyerLink() }
        </div>
      </div>
      { renderRequalContent() }
    </div>
  );
}

export default Banner;
