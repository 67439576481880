import BackArrowIcon from '../../LiveAccountManagement/images/icon-arrow-left.png';
import CloseIcon from '../../LiveAccountManagement/images/close.png';

import AccountManagement from './views/AccountManagement';

import {
  Warning as CancelWarning,
  Reason as CancelReason,
  Success as CancelSuccess,
  Fail as CancelFail,
} from './views/cancel';

import {
  Warning as ReactivateWarning,
  Success as ReactivateSuccess,
  Fail as ReactivateFail,
} from './views/reactivate';

import {
  Warning as DisableWarning,
  Success as DisableSuccess,
  Fail as DisableFail,
} from './views/disable';

import {
  Warning as EnableWarning,
  Success as EnableSuccess,
  Fail as EnableFail,
} from './views/enable';

import {
  Change,
  Warning as ChangeWarning,
  Success as ChangeSuccess,
  Fail as ChangeFail,
} from './views/change';

const _renderCancelModalContent = (props) => {
  const {
    action,
    currentview,
    toggleButtons,
    error,
    featureEnabled,
    fillOther,
    moveTo,
    otherText,
    pastEndDate,
    planCancelled,
    primeSubscriptionPath,
    selectedText,
    setAndGo,
    setAttribute,
    setSelected,
    toggleModal,
  } = props;

  switch (currentview) {
    case 'warning':
      return <CancelWarning
        moveTo={ moveTo }
        toggleModal={ toggleModal }
      />
      break;

    case 'reason':
      return <CancelReason
        action={ action }
        toggleButtons={ toggleButtons }
        fillOther={ fillOther }
        moveTo={ moveTo }
        otherText={ otherText }
        primeSubscriptionPath={ primeSubscriptionPath }
        selectedText={ selectedText }
        setAndGo={ setAndGo }
        setAttribute={ setAttribute }
        setSelected={ setSelected }
        toggleModal={ toggleModal }
      />
      break;

    case 'success':
      return <CancelSuccess
        pastEndDate={ pastEndDate }
        toggleModal={ toggleModal }
      />
      break;

    case 'fail':
      return <CancelFail
        error={ error }
        moveTo={ moveTo }
        toggleModal={ toggleModal }
      />
      break;

    default:
      return <AccountManagement
        featureEnabled={ featureEnabled }
        planCancelled={ planCancelled }
        setAndGo={ setAndGo }
        toggleModal={ toggleModal }
      />
  }
};

const _renderReactivateModalContent = (props) => {
  const {
    action,
    currentview,
    toggleButtons,
    error,
    featureEnabled,
    moveTo,
    pastEndDate,
    planCancelled,
    primeSubscriptionPath,
    setAndGo,
    setAttribute,
    toggleModal,
  } = props;

  switch (currentview) {
    case 'warning':
      return <ReactivateWarning
        action={ action }
        toggleButtons={ toggleButtons }
        moveTo={ moveTo }
        pastEndDate={ pastEndDate }
        primeSubscriptionPath={ primeSubscriptionPath }
        setAndGo={ setAndGo }
        setAttribute={ setAttribute }
        toggleModal={ toggleModal }
      />
      break;

    case 'success':
      return <ReactivateSuccess
        toggleModal={ toggleModal }
      />
      break;

    case 'fail':
      return <ReactivateFail
        error={ error }
        moveTo={ moveTo }
        toggleModal={ toggleModal }
      />
      break;

    default:
      return <AccountManagement
        featureEnabled={ featureEnabled }
        planCancelled={ planCancelled }
        setAndGo={ setAndGo }
        toggleModal={ toggleModal }
      />
  }
};

const _renderDisableModalContent = (props) => {
  const {
    action,
    currentview,
    toggleButtons,
    error,
    featureEnabled,
    moveTo,
    planCancelled,
    primeSubscriptionPath,
    setAndGo,
    setAttribute,
    toggleModal,
  } = props;

  switch (currentview) {
    case 'warning':
      return <DisableWarning
        action={ action }
        toggleButtons={ toggleButtons }
        moveTo={ moveTo }
        primeSubscriptionPath={ primeSubscriptionPath }
        setAndGo={ setAndGo }
        setAttribute={ setAttribute }
      />
      break;

    case 'success':
      return <DisableSuccess
        toggleModal={ toggleModal }
      />
      break;

    case 'fail':
      return <DisableFail
        error={ error }
        moveTo={ moveTo }
        toggleModal={ toggleModal }
      />
      break;

    default:
      return <AccountManagement
        featureEnabled={ featureEnabled }
        planCancelled={ planCancelled }
        setAndGo={ setAndGo }
        toggleModal={ toggleModal }
      />
  }
};

const _renderEnableModalContent = (props) => {
  const {
    action,
    currentview,
    toggleButtons,
    eligibilityPath,
    error,
    featureEnabled,
    moveTo,
    planCancelled,
    primeEligible,
    primeSubscriptionPath,
    setAndGo,
    setAttribute,
    toggleModal,
  } = props;

  switch (currentview) {
    case 'warning':
      return <EnableWarning
        action={ action }
        toggleButtons={ toggleButtons }
        eligibilityPath={ eligibilityPath }
        moveTo={ moveTo }
        primeEligible={ primeEligible }
        primeSubscriptionPath={ primeSubscriptionPath }
        setAndGo={ setAndGo }
        setAttribute={ setAttribute }
        toggleModal={ toggleModal }
      />
      break;

    case 'success':
      return <EnableSuccess
        toggleModal={ toggleModal }
      />
      break;

    case 'fail':
      return <EnableFail
        error={ error }
        moveTo={ moveTo }
        toggleModal={ toggleModal }
      />
      break;
    default:
      return <AccountManagement
        featureEnabled={ featureEnabled }
        planCancelled={ planCancelled }
        setAndGo={ setAndGo }
        toggleModal={ toggleModal }
      />
  }
};

const _renderChangeModalContent = (props) => {
  const {
    action,
    error,
    currentview,
    toggleButtons,
    planCancelled,
    featureEnabled,
    moveTo,
    primeSubscriptionPath,
    setAndGo,
    setAttribute,
    toggleModal,
    currentProductOfferingId,
    country,
    newProductOfferingId,
  } = props;

  switch (currentview) {
    case 'change':
      return (
        <Change
          toggleButtons={toggleButtons}
          moveTo={moveTo}
          currentProductOfferingId={currentProductOfferingId}
          country={country}
          setAttribute={setAttribute}
          toggleModal={toggleModal}
          newProductOfferingId={newProductOfferingId}
        />
      );
    case 'warning':
      return (
        <ChangeWarning
          action={action}
          moveTo={moveTo}
          setAndGo={setAndGo}
          toggleModal={toggleModal}
          setAttribute={setAttribute}
          toggleButtons={toggleButtons}
          primeSubscriptionPath={primeSubscriptionPath}
          newProductOfferingId={newProductOfferingId}
        />
      )
    case 'success':
      return <ChangeSuccess toggleModal={toggleModal}/>;
    case 'fail':
      return <ChangeFail toggleModal={toggleModal} error={error}/>;
    default:
      return (
        <AccountManagement
          featureEnabled={featureEnabled}
          planCancelled={planCancelled}
          setAndGo={setAndGo}
          toggleModal={toggleModal}
        />
      );
  }
}

const _renderModalContent = (props) => {
  const {
    action,
  } = props;

  if (/disable/.test(action)) {
    return _renderDisableModalContent(props);
  } else if (/reactivate/.test(action)) {
    return _renderReactivateModalContent(props);
  } else if (/enable/.test(action)) {
    return _renderEnableModalContent(props);
  } else if ((/change/.test(action))) {
    return _renderChangeModalContent(props);
  } else {
    return _renderCancelModalContent(props);
  }
};

const ModalWrapper = (props) => {
  const {
    moveTo,
    previousview,
    toggleModal,
  } = props;

  return (
    <div className='rex-modal-overlay'>
      <div className='rex-modal-content'>
        <div className='rex-modal-close'>
          <button onClick={ () => toggleModal(false) } >
            <img src={ CloseIcon } />
          </button>
        </div>

        {
          previousview &&
          <div className='rex-modal-back'>
            <button onClick={ () => moveTo(previousview) } >
              <span className='back-arrow'>
                <img src={ BackArrowIcon } />
              </span>
              <span>Back</span>
            </button>
          </div>
        }

        { _renderModalContent(props) }
      </div>
    </div>
  )
};

export default ModalWrapper;
