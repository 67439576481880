import String from './../../../../../../../../common/String.js'

const stringFormatter = new String;

const formatDate = date => {
  return moment(date).format('MM/DD/Y hh:mm A');
};

const buildTooltip = source => {
  const data = []

  for (let key in source) {
    const formattedValue = /created_at/.test(key) ? formatDate(source[key]) : source[key];
    const labelText = stringFormatter.titleCase(key.replace(/_/, ' '));
    const label = <td>{ labelText }</td>;
    const value = <td className="no-overflow">{ formattedValue }</td>;

    data.push(<tr>{ label }{ value }</tr>);
  }

  return (
    <div className="tooltip-table">
      <table><tbody>{ data }</tbody></table>
    </div>
  );
};

const buildSources = srcs => {
  const data = [];

  srcs.forEach(src => {
    const klass = src.source.replace(/_/, '-');

    data.push(<span className={ `source ${klass}` }>{ buildTooltip(src) }</span>);
  });

  return data;
};

const buildData = deets => {
  const data = [];

  for (let key in deets) {
    const klass = /updated_by/.test(key) ? 'no-overflow' : '';

    if (/updated_at|created_at/.test(key)) {
      data.push(<td key={ key }>{ formatDate(deets[key]) }</td>);
    } else if (/research_sources/.test(key)) {
      data.push(<td key={ key }>{ buildSources(deets[key]) }</td>);
    } else {
      data.push(<td key={ key } className={ klass }>{ deets[key] }</td>);
    }
  }

  return data;
};

const buildRows = history => {
  const data = [];

  history.forEach((hist, idx) => {
    const { contact_id, contact_type } = hist;

    delete hist.contact_id;
    delete hist.contact_type;

    data.push(<tr data-contact-id={ contact_id } data-contact-type={ contact_type } key={ idx }>{ buildData(hist) }</tr>);
  });

  return data;
};

const Body = ({ history }) => {
  return (
    <tbody>
      { buildRows(history) }
    </tbody>
  );
};

export default Body;
