import { Component } from 'react';

export default class TargetedAgentView extends Component {
  constructor(props) {
    super(props);
    this.updateTargetedAgent = this.updateTargetedAgent.bind(this);
  }

  initialState(){
    return { agentId: '' };
  }

  updateTargetedAgent(keyValue) {
    let { newTargetedAgent } = this.props.state;
    let updatedNewTargetedAgent = Object.assign(newTargetedAgent, keyValue);
    this.props.updateState({ newTargetedAgent: updatedNewTargetedAgent });
  }

  contentInput(placeholder, field) {
    return (
      <input
        type="input"
        placeholder={placeholder}
        className='wishlist-input highlight-empty'
        value={this.props.state.newTargetedAgent[field] || ''}
        onChange={(e) => this.updateTargetedAgent({ [field]: e.currentTarget.value })}
      />
    )
  }

  render() {
    return (
      <div className="content">
        { this.contentInput('First Name', 'first_name') }
        { this.contentInput('Last Name', 'last_name') }
        { this.contentInput('Mobile Number', 'phone_mobile') }
        { this.contentInput('Email', 'email')}
        { this.contentInput('Company', 'name')}
        { this.contentInput('Street Address', 'address') }
        { this.contentInput('City', 'city') }
        { this.contentInput('State', 'state') }
        { this.contentInput('Zip', 'zip') }
        <div className="prompt-options">
          <div className="prompt-cancel" onClick={() => this.props.switchView('selection')}>Back</div>
          <div className="prompt-confirm" onClick={() => this.props.switchView('targetedAgentFollowUp')}>Next</div>
        </div>
      </div>
    )
  }
}
