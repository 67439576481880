class TimeSlots {

  set(timeSlots) {
    this.timeslots = timeSlots;
  }

  get() {
    return this.timeslots;
  }
}

const timeSlots = new TimeSlots();

export default timeSlots;
