import React from 'react';

import BlankPhoto from './blank-photo.png';

const AgentCard = (props) => {
  const {
    agent,
    hideAgent,
    markAgentVerified,
    reaUrl,
    showSlugEditModal,
    toggleAdspend,
  } = props;

  const {
    address,
    agentHidden,
    agentVerified,
    agentVerifiedText,
    company,
    name,
    opsVerified,
    opsVerifiedText,
    optOutAdspend,
    photo,
    slug,
  } = agent;

  const reaSite = `${reaUrl}agent/${slug}`;

  const searchForAgent = () => {
    const query = [
      name,
      company,
      address,
    ].join(' ');

    return `https://www.google.com/search?q=${encodeURIComponent(query)}`;
  }

  const agentCardList = ['rea-agent-card'];

  if (agentHidden) {
    agentCardList.push('unpublished');
  }

  return (
    <div className={agentCardList.join(' ')}>
      <div className="rea-agent-card-image">
        <img src={photo || BlankPhoto} />
        <span className="hidden-banner">
          HIDDEN
        </span>
        <button
          type="button"
          className="profile-edit-button"
          onClick={hideAgent}
        >
          {agentHidden ? 'Publish Profile' : 'Hide Profile'}
        </button>
      </div>
      <div className="rea-agent-card-details">
        <span>{company}</span>
        <h2>{name}</h2>
        <span>{address}</span>
        <span>
          <i className="fa fa-lock" />
          &nbsp;
          {slug}
        </span>
        <span className="hover-container">
          {
            opsVerified
              ? (
                <>
                  <div className="hover-caret" />
                  <div className="hover-text">
                    {opsVerifiedText}
                  </div>
                  <i className="fa fa-check" />
                  Ops Verified
                </>
              )
              : 'Unverified'
          }
        </span>
        <span className="hover-container">
          {
            agentVerified
              ?  (
                <>
                  <div className="hover-caret" />
                  <div className="hover-text">
                    {agentVerifiedText}
                  </div>
                  <i className="fa fa-check" />
                  Agent Verified
                </>
              )
              : (
                <button
                  className="profile-edit-button"
                  onClick={markAgentVerified}
                >
                  Mark as Agent Verified
                </button>
              )
          }
        </span>
        <span className="hover-container">
          <button
            className="profile-edit-button"
            onClick={toggleAdspend}
            disabled={agentHidden}
          >
            {
              optOutAdspend
                ? 'Enable Adspend'
                : 'Disable Adspend'
            }
          </button>
        </span>

      </div>
      <div className="rea-agent-card-buttons">
        <a
          href={reaSite}
          target="_blank"
          className="profile-edit-button"
        >
          View on RealEstateAgents.com
        </a>
        <a
          href={searchForAgent()}
          target="_blank"
          className="profile-edit-button"
        >
          Search on Google
        </a>
        <button
          className="profile-edit-button hidden"
          type="button"
          onClick={showSlugEditModal}
        >
          Edit Profile Url
        </button>
      </div>
    </div>
  )
}

export default AgentCard;
