import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getStateProps } from '../utils';

import View from './view';

function mapStateToProps(state) {
  return {
    options: state.lead.get('new_lead_options'),
    info: getStateProps(state.info),
    matches: state.newLead.get('matches'),
  }
}

class NewLead extends Component {
  // componentDidUpdate(prevProps) {
  //   const { dispatch, info } = this.props;
  //   const { id } = info;
  //   if (!prevProps.info.id && id) {
  //     dispatch({
  //       type: 'GET_MATCHES_DATA',
  //       data: {
  //         id,
  //       },
  //     });
  //   }
  // }

  render() {
    const {
      info,
      matches,
      options
    } = this.props;

    const data = options;

    if (data) {
      data.state = info;
      data.lead = { ...options.lead, ...info };
    }

    return (
      <View
        data={ data }
        matches={ matches }
      />
    );
  }
}

export default connect(mapStateToProps)(NewLead);
