import { Component } from 'react';

export default class SelectionView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="content">
        <div className='list-buttons'>
          <input
            type='button'
            className='monitoring-button'
            value='Tag Agent ID'
            onClick={() => this.props.switchView('tagAgent')}
          />
          <input
            type='button'
            className='monitoring-button'
            value='Create Targeted Agent'
            onClick={() => this.props.switchView('targetedAgent')}
          />
        </div>
      </div>
    )
  }
}
