import React from 'react';

import BuyerCities from '../BuyerCityStates';
import MortgageStatusSelect from '../inputs/Select/mortgageStatus';
import PriceInput from '../inputs/NumberInput/prices';
import Select from '../inputs/Select';
import TypeHomeSelect from '../inputs/Select/typeHome';

const bathOptions = ['', 0, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
const bedOptions = ['', 1, 2, 3, 4, 5, 6, 7];

const BuyerFields = (props) => {
  return (
    <div>
      <BuyerCities />

      <div className='info-row buyer-city-info-row'>

        <PriceInput
          classNames='quarter'
          display='Min Price'
          property='home_price_min'
        />

        <PriceInput
          classNames='quarter'
          display='Max Price'
          property='home_price_max'
        />

        <MortgageStatusSelect
          display='Mortgage Status'
          property='mortgage_status'
        />
      </div>

      <div className='info-row buyer-city-info-row'>
        <Select
          data={ bedOptions }
          display='Min Beds'
          property='beds'
        />

        <Select
          data={ bathOptions }
          display='Min Baths'
          property='baths'
        />

        <TypeHomeSelect
          display='Property Type'
          property='type_home'
        />
      </div>
    </div>
  );
};

export default BuyerFields;
