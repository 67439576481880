import { ActionCableProvider } from 'react-actioncable-provider';
import QualUI from './QualUI';
import LiveControlsUI from '../ProfileReview/Transfer/LiveControlsUI';

export const QualLiveCallControls = ({ allValid, sessionUUID, lead, agent, rep, live, conferenceType }) => {
  const meta = document.querySelector('meta[name="action-cable-url"]');
  const wsURL = meta.content;
  const cable = ActionCable.createConsumer(wsURL);

  return (
    <ActionCableProvider cable={cable}>
      <QualUI
        allValid        = { allValid }
        sessionUUID     = { sessionUUID }
        lead            = { lead }
        agent           = { agent }
        rep             = { rep }
        live            = { live }
        data            = { {} }
        conferenceType  = { conferenceType }
      />
    </ActionCableProvider>
  )
};

export const ProfileReviewLiveCallControls = ({ lead, matches, rep }) => {
  const meta = document.querySelector('meta[name="action-cable-url"]');
  const wsURL = meta.content;
  const cable = ActionCable.createConsumer(wsURL);

  return (
    <ActionCableProvider cable={cable}>
      <LiveControlsUI
        lead              = { lead }
        matches           = { matches }
        rep               = { rep }
        live              = { {} }
        data              = { {} }
        conferenceType    = { 'outbound' }
      />
    </ActionCableProvider>
  )
};
