import  { Component } from  'react';
import  request       from  'superagent';

import LeadType               from './LeadType';
import LeadStatus             from './LeadStatus';
import LeadMatchToAgent       from './LeadMatchToAgent';
import LeadSellerPropertyInfo from './LeadSellerPropertyInfo';
import LeadBuyerPropertyInfo  from './LeadBuyerPropertyInfo';
import LeadPersonalInfo       from './LeadPersonalInfo';

export default class NewLeadFromLeadModal extends Component {
  constructor(props){
    super(props)
    let { lead, matches } = this.props;

    this.state                            = this.initializeState(props);
    this.submitLead                       = this.submitLead.bind(this);
    this.validateLead                     = this.validateLead.bind(this);
    this.nextPage                         = this.nextPage.bind(this);
    this.backPage                         = this.backPage.bind(this);
    this.closeModal                       = this.closeModal.bind(this);
    this.handleChange                     = this.handleChange.bind(this);
    this.handleLeadTypeChange             = this.handleLeadTypeChange.bind(this);
    this.handleToggle                     = this.handleToggle.bind(this);
    this.handleLeadStatusChange           = this.handleLeadStatusChange.bind(this);
    this.handleNearbyCitiesChange         = this.handleNearbyCitiesChange.bind(this);
    this.handleFoundCitiesSet             = this.handleFoundCitiesSet.bind(this);
    this.handleResponseErrors             = this.handleResponseErrors.bind(this);
    this.handleToggleAddedDesignatedAgent = this.handleToggleAddedDesignatedAgent.bind(this);
    this.updateCurrentPage                = this.updateCurrentPage.bind(this);
    this.toggleMatchedAgent               = this.toggleMatchedAgent.bind(this);
    this.selectNoMatch                    = this.selectNoMatch.bind(this);
    this.determineInitialHomePrice        = this.determineInitialHomePrice.bind(this);
  }

  componentDidMount() {
    $('#new-lead-from-lead-modal > .main-modal-panel').draggable();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lead !== this.props.lead) {
      this.setState(this.initializeState(this.props));
    }
  }

  initializeState(props) {
    const { lead, matches } = props;

    return {
      currentPage           : 1,
      leadType              : null,
      requestType           : null,
      leadStatus            : null,
      firstName             : lead.first_name,
      lastName              : lead.last_name,
      email                 : lead.user.email,
      languagePreference    : lead.language_preference || 'en',
      phoneNumber           : lead.phone_number,
      phoneNumberNational   : lead.phone_number_national,
      phoneNumberCountry    : lead.phone_number_country,
      comments              : '',
      microsite             : lead.microsite_id,
      streetNumber          : lead.street_number,
      streetName            : lead.street_name,
      unitNumber            : lead.unit_number,
      city                  : lead.city,
      state                 : this.props.state,
      zip                   : lead.zip,
      country               : lead.country,
      homePrice             : this.determineInitialHomePrice(),
      beds                  : lead.beds,
      baths                 : lead.baths,
      timeFrame             : lead.time_frame,
      mortgageStatus        : lead.mortgage_status == '' || lead.mortgage_status == null || lead.mortgage_status == 'Unknown' || lead.mortgage_status == 'Credit Verified' ? "none" : this.getMortgageValue(lead.mortgage_status),
      typeHome              : lead.type_home == '' || lead.type_home == 'unknown' ? null : lead.type_home,
      reasonForSelling      : lead.reason,
      referralFee           : lead.referral_fee,
      referralFeeRexCut     : lead.referral_fee_rex_cut,
      referralFeeAgentCut   : lead.referral_fee_agent_cut,
      referralFeePartnerCut : lead.referral_fee_partner_cut,
      lead                  : lead,
      matches               : matches,
      nearbyCities          : [],
      foundCities           : [],
      submittingLead        : false,
      isQualContactBonus    : false,
      isLFU                 : false,
      isTvAd                : false,
      originCallID              : null, // ID of the inbound analytics.call that originated this lead
      originatedFromInboundCall : false,
      matchedAgentIds       : [],
      addedDesignatedAgent  : false,
      originalLeadId        : lead.id,
      responseErrors        : {},
      formData              : this.props.formData,
      id: null,
    };
  }

  determineInitialHomePrice() {
    const homePrices = this.props.formData.seller.home_prices;
    let that = this;
    let leadPrice = that.props.lead.home_price_max;
    let selectedKey = Object.keys(homePrices).find(function(key) {
      const value = homePrices[key];
      return value.max >= leadPrice && value.min < leadPrice;
    });
    let selectedValue = homePrices[selectedKey];

    return btoa(JSON.stringify(selectedValue));
  }

  updateCurrentPage(value){
    this.setState({currentPage: value});
  }

  toggleMatchedAgent(id) {
    if (this.state.matchedAgentIds.includes(id)) {
      let newMatches = this.state.matchedAgentIds.filter(agentId => agentId != id);
      this.setState({matchedAgentIds: newMatches});
    } else {
      this.state.matchedAgentIds.push(id);
      this.setState({matchedAgentIds: this.state.matchedAgentIds});
    }
  }

  selectNoMatch() {
    this.setState({matchedAgentIds: [], addedDesignatedAgent: false});
  }

  handleChange(param, e){
    this.setState({ [param]: e.currentTarget.value });
  }

  handleToggle(param){
    this.setState({ [param]: !this.state[param] });
  }

  handleToggleAddedDesignatedAgent() {
    this.setState({ addedDesignatedAgent: !this.state.addedDesignatedAgent });
  }

  handleLeadTypeChange(param){
    this.setState({ leadType: param});
    this.nextPage();
  }

  handleLeadStatusChange(param){
    this.setState({ leadStatus: param});
  }

  handleNearbyCitiesChange(city){
    let foundCity = false;
    // Does city exist in current array
    this.state.nearbyCities.forEach(function(bc) {
      if (bc.city == city.city) {
        foundCity = true;
      }
    });

    // if no, add to nearbyCities, else remove by creating new array without that city
    if (foundCity == false) {
      this.state.nearbyCities.push({city: city.city, state: city.state})
      this.setState({ nearbyCities: this.state.nearbyCities});
    } else {
      var newBuyerCities = []
      this.state.nearbyCities.forEach(function(bc) {
        if (bc.city != city.city) {
          newBuyerCities.push({city: bc.city, state: bc.state});
        };
      });
      this.setState({nearbyCities: newBuyerCities});
    }
  }

  handleFoundCitiesSet(cities){
    this.setState({foundCities:  cities});
  }

  handleResponseErrors(errors){
    this.setState({responseErrors: errors});
  }

  renderCurrentPage(){
    let {
      currentPage,
      matches,
      matchedAgentIds,
      pageTitle,
      nearbyCities,
      country,
      foundCities,
      formData,
      isQualContactBonus,
      isLFU,
      isTvAd,
      originCallID,
      leadType,
      leadStatus,
      homePrice,
      beds,
      baths,
      mortgageStatus,
      typeHome,
      timeFrame,
      reasonForSelling,
      streetNumber,
      streetName,
      unitNumber,
      city,
      state,
      zip,
      firstName,
      lastName,
      email,
      languagePreference,
      phoneNumber,
      phoneNumberNational,
      phoneNumberCountry,
      comments,
      microsite,
      addedDesignatedAgent
    } = this.state;

    switch(currentPage) {
      case 1:
        return (
          <LeadType
            leadType             = {leadType}
            handleLeadTypeChange = {this.handleLeadTypeChange}
          />
        )
        break;
      case 2:
        return (
          <LeadPersonalInfo
            firstName          = {firstName}
            lastName           = {lastName}
            email              = {email}
            languagePreference = {languagePreference}
            phoneNumber        = {phoneNumber}
            phoneNumberNational= {phoneNumberNational}
            phoneNumberCountry = {phoneNumberCountry}
            comments           = {comments}
            microsite          = {microsite}
            isQualContactBonus = {isQualContactBonus}
            isLFU              = {isLFU}
            isTvAd             = {isTvAd}
            originCallID       = {originCallID}
            handleToggle       = {this.handleToggle}
            handleChange       = {this.handleChange}
            formData           = {formData}
          />
        )
        break;
      case 3:
        if (leadType === 'seller') {
          return (
            <LeadSellerPropertyInfo
              streetNumber     = {streetNumber}
              streetName       = {streetName}
              unitNumber       = {unitNumber}
              city             = {city}
              state            = {state}
              zip              = {zip}
              country          = {country}
              typeHome         = {typeHome}
              timeFrame        = {timeFrame}
              homePrice        = {homePrice}
              reasonForSelling = {reasonForSelling}
              handleChange     = {this.handleChange}
              formData         = {formData}
              />
          )
        } else if (leadType === 'buyer') {
          return (
            <LeadBuyerPropertyInfo
              city                     = {city}
              state                    = {state}
              country                  = {country}
              homePrice                = {homePrice}
              beds                     = {beds}
              baths                    = {baths}
              timeFrame                = {timeFrame}
              mortgageStatus           = {mortgageStatus}
              nearbyCities             = {nearbyCities}
              foundCities              = {foundCities}
              handleResponseErrors     = {this.handleResponseErrors}
              handleNearbyCitiesChange = {this.handleNearbyCitiesChange}
              handleFoundCitiesSet     = {this.handleFoundCitiesSet}
              handleChange             = {this.handleChange}
              formData                 = {formData}
            />
          )
        }
        break;
      case 4:
        return (
          <LeadMatchToAgent
            leadType                         = {leadType}
            matches                          = {matches}
            matchedAgentIds                  = {matchedAgentIds}
            addedDesignatedAgent             = {addedDesignatedAgent}
            updateCurrentPage                = {this.updateCurrentPage}
            toggleMatchedAgent               = {this.toggleMatchedAgent}
            handleToggleAddedDesignatedAgent = {this.handleToggleAddedDesignatedAgent}
            selectNoMatch                    = {this.selectNoMatch}
          />

        )
      case 5:
        return (
          <LeadStatus
            leadStatus             = {leadStatus}
            matchedAgentIds        = {matchedAgentIds}
            handleLeadStatusChange = {this.handleLeadStatusChange}
          />
        )
        break;
    }
  }

  nextPage(){
    if(this.state.currentPage === 1) {
      this.setState({currentPage: this.state.currentPage + 1});
    } else {
      this.validateLead();
    }
  }

  getMortgageValue(string) {
    let mortgageKey = {
      "I don't know": 'none',
      "I'm pre-qualified": 'pre-qualified',
      "I'm pre-approved": 'pre-approved',
      'All cash': "cash",
      "Haven't applied yet": "not-applied"
    }

    return mortgageKey[string];
  }

  getTypeHomeValue(string) {
    let foundValue = this.props.formData.seller.type_of_home.filter(arr => arr[0] == string);
    if (foundValue.length == 0) {
      return 'unknown';
    } else if (foundValue[0][0] == 'Unknown') {
      return 'unknown';
    } else {
      return foundValue[0][1];
    }
  }

  backPage(){
    if(this.state.currentPage === 1) return;
    // Skip matching page when user does not have role
    if (!this.props.is_matcher && this.state.currentPage == 5) {
      this.setState({currentPage: this.state.currentPage - 2});
      return;
    }
    if (this.state.currentPage === 2) {
      this.setState({responseErrors: {}});
    }
    this.setState({currentPage: this.state.currentPage - 1});
  }

  closeModal(){
    $('#new-lead-from-lead-modal').each(function() {
      $(this).addClass('hidden');
    })
    this.setState(this.initializeState(this.props));
  }

  getPageTitle() {
    switch(this.state.currentPage) {
      case 1:
        return 'Select Lead Type';
        break;
      case 2:
        return 'Personal Info';
        break;
      case 3:
        if (this.state.leadType === 'seller') {
          return 'Seller Property Info';
        } else if (this.state.leadType === 'buyer') {
          return 'Buyer Property Info';
        }
        break;
      case 4:
        return 'Match To?';
        break;
      case 5:
        return 'Lead Status';
        break;
    }
  }

  validateLead() {
    this.setState({ requestType: 'validate' }, function() {
      request
      .post('/api/leads')
      .send(this.leadPayload())
      .then(function({ body }) {
        let { errors } = body;

        if(errors) {
          if (this.state.currentPage === 2) {
            ['street_name', 'street_number', 'city', 'state', 'home_price_max', 'zip', 'lead_type','buyer_cities'].forEach(function(key) {
              delete errors[key]
            });
          } else if (this.state.currentPage === 3 && this.state.leadType === 'buyer') {
            ['street_name', 'street_number', 'zip', 'lead_type', 'buyer_cities'].forEach(function(key) {
              delete errors[key]
            });

            if (errors['home_price_max']) {
              errors['price'] = errors['home_price_max']
              delete errors['home_price_max']
            }
          } else if (this.state.currentPage === 3 && this.state.leadType === 'seller') {
            if (errors['zip']) {
              errors['zip'] = ['Please provide a zip code.']
            };
          }

          this.setState({ responseErrors: errors });
        }

        if (!errors || Object.keys(errors).length === 0) {
          this.setState({ responseErrors: {} });
          // Skip matching page when user does not have role
          if (!this.props.is_matcher && this.state.currentPage == 3) {
            this.setState({currentPage: this.state.currentPage + 2});
          } else {
            this.setState({currentPage: this.state.currentPage + 1});
          }
        }
      }.bind(this))
      .catch(error => {
        console.error(error);

        let response = JSON.parse(error.response);
        this.setState({ responseErrors: response.errors });
      })
    });
  }

  submitLead() {
    if (this.state.leadStatus == null) {
      this.setState({ responseErrors: { lead_status: ["Can't be blank"] } });
      return
    }

    this.setState({ requestType: 'submit' }, function() {
      if (this.state.submittingLead == false) {
        this.setState({ submittingLead: true });
        request
        .post('/api/leads')
        .send(this.leadPayload())
        .then(function({ body }) {
          let { success, errors, request_uuid } = body;

          if(errors) {
            if (this.state.leadType == 'buyer' && this.state.currentPage === 3) {
              delete errors['buyer_cities']
            }

            this.setState({ responseErrors: errors, submittingLead: false });
          } else {
            let leadURL = `/leads/${success.lead_uuid}?request_uuid=${request_uuid}`;
            window.open(leadURL, '_blank');
            this.closeModal();
          }
        }.bind(this))
        .catch(error => {
          console.error(error);

          let response = JSON.parse(error.response);
          this.setState({ responseErrors: response.errors, submittingLead: false });
        })
      }
    });
  }

  extractPriceRange(attr) {
    if (this.state.homePrice) {
      let priceRange = JSON.parse(atob(this.state.homePrice));

      return priceRange[attr];
    }
  }

  leadPayload() {
    let payload = {
      lead: {
        type                     : this.state.leadType,
        request_type             : this.state.requestType,
        referral_fee             : this.state.referralFee,
        referral_fee_rex_cut     : this.state.referralFeeRexCut,
        referral_fee_agent_cut   : this.state.referralFeeAgentCut,
        referral_fee_partner_cut : this.state.referralFeePartnerCut,
        max_agents               : '',
        street_number            : this.state.streetNumber,
        street_name              : this.state.streetName,
        unit_number              : this.state.unitNumber,
        city                     : this.state.city,
        state                    : this.state.state,
        zip                      : this.state.zip,
        country                  : this.state.country,
        home_price_min           : this.extractPriceRange('min'),
        home_price_max           : this.extractPriceRange('max'),
        beds                     : this.state.beds,
        baths                    : this.state.baths,
        time_frame               : this.state.timeFrame,
        mortgage_status          : this.state.mortgageStatus,
        type_home                : this.getTypeHomeValue(this.state.typeHome),
        reason                   : this.state.reasonForSelling,
        first_name               : this.state.firstName,
        last_name                : this.state.lastName,
        language_preference      : this.state.languagePreference,
        phone_number             : this.state.phoneNumber,
        email                    : this.state.email,
        microsite_id             : this.state.microsite,
        comments                 : this.state.comments,
        buyer_cities             : this.state.nearbyCities,
        qual_contacted           : this.state.isQualContactBonus ? '1' : '0',
        lfu                      : this.state.isLFU ? '1' : '0',
        tv_ad                    : this.state.isTvAd ? '1' : '0',
        original_lead_id         : this.state.originalLeadId,
        agent_ids                : this.state.matchedAgentIds,
        status                   : this.state.leadStatus,
        origin_call_id           : this.state.originCallID,
        originated_from_inbound_call : this.state.originatedFromInboundCall
      }
    };

    if (this.state.leadType == 'buyer') {
      delete payload.lead.type_home;
    }

    if (this.state.typeHome == 'Unknown') {
      payload.lead.type_home = 'unknown';
    }

    return payload;
  }

  addPeriods(errors) {
    if (errors.length == 1) {
      if (errors[0].slice(-1) === '.') {
        return errors[0];
      } else {
        return errors[0] + '.';
      }
    } else {
      let errorString = errors.join(' ');
      if (errorString.slice(-1) === '.') {
        return errorString;
      } else {
        return errorString + '.';
      }
    }
  }

  renderErrors() {
    let keys = Object.keys(this.state.responseErrors)
    return keys.map((key) => {
      return <li className='error-text'><strong>{key.replace('_',' ').toUpperCase()}:</strong> {this.addPeriods(this.state.responseErrors[key])}</li>;
    });
  }

  renderNextButton() {
    if (this.state.currentPage === 5) {
      return (
        <div className='col-md-6'>
          <button className='step-button float-button' onClick={this.submitLead}>SUBMIT</button>
        </div>
      )
    } else if (this.state.currentPage > 1) {
      return (
        <div className='col-md-6'>
          <button className='step-button float-button' onClick={this.nextPage}>NEXT</button>
        </div>
      )
    }
  }

  renderBackButton() {
    if (this.state.currentPage != 1) {
      return (
        <div className='col-md-5'>
        <button className='step-button' onClick={this.backPage}>BACK</button>
      </div>
      )
    }
  }

  render(){
    return (
      <div className='cs-overshadow hidden' id='new-lead-from-lead-modal'>
        <div className='main-modal-panel' onClick={(e) => e.stopPropagation()}>
          <div className='new-lead-row row header-row'>
            <div className='col-md-8 page-title-row'>
              <h2 className='page-title'>{this.getPageTitle() }</h2>
            </div>
            <div className='col-md-2 right' onClick={this.closeModal}>
              <i className="fa fa-times-circle close-icon"></i>
            </div>
          </div>
          <div className='row'>
            <hr></hr>
          </div>
          <div className='row'>
            <ul>
            { this.renderErrors() }
            </ul>
          </div>
          <div className='new-lead-row row'>
            { this.renderCurrentPage() }
          </div>
          <div className='new-lead-row row next-button-row'>
            {this.renderBackButton()}
            {this.renderNextButton()}
          </div>
        </div>
      </div>
    );
  }
}
