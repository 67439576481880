import { Component } from  'react';
import CallStatus from './CallStatus';
import Agent from './Agent';
import Lead from './Lead';
import Conference from './Conference';
import request       from 'superagent';
import { ActionCable } from 'react-actioncable-provider';
import './styles.scss';

import uuid from 'uuid/v4';
import * as Phone from '@referralexchange/phone';

export default class LiveCallControl extends Component {
  constructor(props){
    super(props);
    this.state = {
      callStatus:               'init',
      callControlUUID:          null,
      leadCallStartTime:        null,
      agentCallStartTime:       null,
      conferenceCallStartTime:  null,
      leadId:                   this.props.data.lead.id,
      agentId:                  this.props.data.sending_agent.id,
      callerId:                 null,
      location:                 null,
      pause:                    null
    };

    // Initialize the connection to our phone server
    this.phoneClient = new Phone.Client({
      portType:           Phone.PortType.EmbeddedWeb,

      // See if we can dynamically pass this through
      remoteExtensionID:  'ngjhphgjojadbfihoaleohdbnekniddo'
    });

    this.startCall          = this.startCall.bind(this);
    this.contactAgent       = this.contactAgent.bind(this);
    this.renderSubscription = this.renderSubscription.bind(this);
    this.onEventReceived    = this.onEventReceived.bind(this);
    this.updateCallMode     = this.updateCallMode.bind(this);
  }

  onEventReceived(payload) {
    switch(payload.event) {
      case 'call_mode_changed':
        this.setState({ callStatus: payload.payload.call_mode });
        break
      case 'conference_call_start':
        this.setState({ conferenceCallStartTime: Date.parse(payload.timestamp) });
        break
      case 'lead_connected':
        this.setState({ leadCallStartTime: Date.parse(payload.timestamp) });
        this.setState({ callStatus: payload.event });
        break
      case 'agent_ready':
        this.setState({ agentCallStartTime: Date.parse(payload.timestamp) });
        this.setState({ callStatus: payload.event });
      default:
        this.setState({ callStatus: payload.event });
    }
  };

  componentDidMount() {
    // determine the initial promise method to call
    const initialPromise = this.props.phoneStrategy.action == 'connect_existing_call' ? this.getActiveCall : this.startCall;

    initialPromise.bind(this)().then((data) => {
      const { initialState, callControlUUID } = data;

      console.debug('Initializing Live Conference Controls.', callControlUUID, initialState)
      this.setState({ callStatus: initialState, callControlUUID });
    })
  }

  /**
   * Start a new outbound call
   */
  startCall() {
    return new Promise((resolve, reject) => {
      const callControlUUID = uuid();

      this.phoneClient.transmit({
        event:    Phone.Action.StartCall,
        payload:  {
          call_type:         'live_conference',
          call_control_uuid:  callControlUUID,
          lead_id:            this.state.leadId,
          agent_id:           this.state.agentId
        }
      });

      // Queue up next callback
      resolve({ initialState: 'init', callControlUUID });

      this.getCallDetails(callControlUUID).then(callDetails => {
        this.setState({ callerId: callDetails.callerID, location: callDetails.location })
      });
    });
  }

 /**
   * Retrieve call information for a call that is already running
   */
  getActiveCall() {
    return new Promise((resolve, reject) => {
      const callControlUUID = this.props.phoneStrategy.call_control_uuid;

      // Queue up next callback
      resolve({ initialState: 'lead_connected', callControlUUID })

      this.getCallDetails(callControlUUID).then(callDetails => {
        this.setState({
          callerId:                 callDetails.callerID,
          location:                 callDetails.location,

          leadCallStartTime:        callDetails.lead_connected_at,
          agentCallStartTime:       callDetails.agent_ready_at,
          conferenceCallStartTime:  callDetails.conference_started_at
        })
      });
    })
  }

  /**
   * Get details concerning a new or active live conference call
   * @param {uuid} callControlUUID
   */
  getCallDetails(callControlUUID) {
    return new Promise((resolve, reject) => {
      request.get('/qual/queue/fetch_live_call')
        .query({ call_control_uuid: callControlUUID })
        .then(response => { resolve(response.body) })
        .catch(reject)
      });
  }

  componentWillUnmount() {
    const { callControlUUID } = this.state;

    request.post('/qual/queue/complete_live_conference_call')
      .send({ call_control_uuid: callControlUUID })
      .then( () => {
        this.phoneClient.transmit({ event: Phone.Action.EndCall });
      })
  }

  renderSubscription() {
    return <ActionCable channel={ {channel: 'Phone::LiveConferenceChannel', id: this.state.callControlUUID } } onReceived={ this.onEventReceived } />;
  };

  updateLead() {
    return new Promise((resolve, reject) => {
      request.post('/qual/queue/update_lead')
        .send({ email: this.props.data.email, lead: this.props.data.lead, comments: this.props.data.comments })
        .then( res => {
          resolve(res)
        }).catch( err => {
          console.error('ERROR', err)
          reject(err)
        })
    })
  }

  contactAgent() {
    return new Promise((resolve, reject) => {
      this.setState({callStatus: 'agent_contacted'});

      request.post('/phone/live_conference/contact_agent')
        .send({
          call_control_uuid: this.state.callControlUUID
        }).then(matchData => {
          if (JSON.parse(matchData.text).match_id) {
            this.props.updateState({
              status_button: {
                note_status: '',
              }
            });

            request.post('/qual/queue/create_servicing_complete_note')
              .send({ lead_id: this.state.leadId, qual_note: this.props.data.qual_note })
              .then(noteData => { resolve(noteData) })
          }
          resolve(matchData);
        }).catch(err => {
          console.error('ERROR', err);
          reject(err);
        });
    });
  }

  updateCallMode(mode, callback = () => {}) {
    return new Promise((resolve, reject) => {
      request.post('/phone/live_conference/update_call_mode')
        .send({
          call_control_uuid: this.state.callControlUUID,
          call_mode: mode
        }).then(res => {
          resolve(res);
        }).catch(err => {
          console.error('ERROR', err);
          reject(err);
        });
    });
  }

  _pauseDialer() {
    $('#dialer').toggleClass('paused');
    EventBus.emit('dialer:pause', !this.props.data.pause);
    this.setState({ pause: !this.props.data.pause });
  }

  render(){
    let { match_count, call_count } = this.props.data
    let { callStatus, agentCallStartTime, leadCallStartTime, conferenceCallStartTime  } = this.state;
    let hidden = this.props.data.live.live_disposition !== 'submitted_agent'
    return (
      <div className={hidden ? 'hidden' : 'container live-call-control-container-old'}>
        { this.state && this.state.callControlUUID && this.renderSubscription() }
        <div className='row text-center'>
          <div className='status-container'>
            <div className='col-sm-1'>
              <div className='button init' onClick={ this._pauseDialer.bind(this) }>
                <div className='short-top-row pause-button'>
                  { this.state.pause ? 'Resume' : 'Pause'}
                </div>
              </div>
            </div>
          </div>
          <div className='status-container'>
            <div className='col-sm-1'>
              <div className='button display'>
                <div className='short-top-row count-display'>
                  { call_count }
                  <p>Calls Made</p>
                </div>
              </div>
            </div>
          </div>
          <div className='status-container'>
            <div className='col-sm-1'>
              <div className='button display'>
                <div className='short-top-row count-display'>
                  { match_count }
                  <p>Matches Made</p>
                </div>
              </div>
            </div>
          </div>
          <div className='status-container'>
            <div className='col-sm-2'>
              <div className='button display'>
                <div className='row short-top-row location-text'>
                  Location: { this.state.location }
                  <p>Phone: { this.state.callerId }</p>
                </div>
              </div>
            </div>
          </div>
          <CallStatus
            updateLead={this.updateLead.bind(this)}
            callStatus = {callStatus}
            conferenceCallStartTime = {conferenceCallStartTime}
            agentCallStartTime = {agentCallStartTime}
            leadCallStartTime = {leadCallStartTime}
            startCall = {this.startCall}
            contactAgent = {this.contactAgent}
            verifications = {this.props.data.live_verifications}
            updateState={this.props.updateState.bind(this)}
          />
          <Agent
            updateCallMode = {this.updateCallMode}
            callStatus = {callStatus}
          />
          <Conference
            updateCallMode = {this.updateCallMode}
            callStatus = {callStatus}
          />
          <Lead
            updateCallMode = {this.updateCallMode}
            callStatus = {callStatus}
          />
        </div>
      </div>
    );
  }
}
