import { Component } from 'react';

export default class LeadWall extends Component {
  taskObject(obj, idx) {
    if(obj.creator === 'Flux The Penguin') return;
    if(obj.task_type == 'Agent notify' || obj.task_type == 'notify') return;
    return (
      <div key={idx} className='wall-obj-container'>
        <div className='left-wall-container col-lg-1'>
          <div className='circle' style={{border: 'solid 2px #f6852f'}}></div>
        </div>
        <div className='right-wall-container col-lg-11'>
          <div className='date-type'>
            <div className='date-container inline-block'>{obj.created_at_date} {obj.created_at_time}</div>
            <div className='type-container inline-block'>TASK</div>
          </div>
          <div className='header-container'>{obj.task_type}</div>
          <div className='status-container'>Status: {obj.status}</div>
          <div className='content-container' dangerouslySetInnerHTML={{ __html: obj.message }}></div>
        </div>
        <div style={{clear: 'both'}}></div>
      </div>
    );
  }

  emailObject(obj, idx) {
    if(!obj.from_email && obj.email_type.toLowerCase() !== 'proposal') return;
    return (
      <div key={idx} className='wall-obj-container'>
        <div className='left-wall-container col-lg-1'>
          <div className='circle' style={{border: 'solid 2px #f6852f'}}></div>
        </div>
        <div className='right-wall-container col-lg-11'>
          <div className='date-type'>
            <div className='date-container inline-block'>{obj.created_at_date} {obj.created_at_time}</div>
            <div className='type-container inline-block'>EMAIL</div>
          </div>
          <div className='header-container'>{obj.email_type}</div>
          <div className='status-container'>Status: {obj.status}</div>
          <div className='content-container'>To: {obj.to_email}</div>
          <div className='content-container'>From: {obj.from_email || 'Flux'}</div>
        </div>
        <div style={{clear: 'both'}}></div>
      </div>
    );
  }

  noteObject(obj, idx) {
    return (
      <div key={idx} className='wall-obj-container'>
        <div className='left-wall-container col-lg-1'>
          <div className='circle' style={{border: 'solid 2px #f6852f'}}></div>
        </div>
        <div className='right-wall-container col-lg-11'>
          <div className='date-type'>
            <div className='date-container inline-block'>{obj.created_at_date} {obj.created_at_time}</div>
            <div className='type-container inline-block'>NOTE</div>
          </div>
          <div className='header-container'>{obj.status}</div>
          <div className='content-container'>{obj.message}</div>
        </div>
        <div style={{clear: 'both'}}></div>

      </div>
    );
  }

  renderWallObjects() {
    return this.props.leadWall.map(function(obj, idx) {
      if(obj.type === 'task') {
        return this.taskObject(obj, idx)
      } else if (obj.type === 'note') {
        return this.noteObject(obj, idx)
      } else if (obj.type === 'email') {
        return this.emailObject(obj, idx)
      }
    }.bind(this))
  }

  render() {
    return (
      <div className='agent-wall-container' style={{paddingTop: '0px'}}>
        <div className='profile'>
          <div className='col-lg-3 border-box no-left-padding no-right-padding'>
            <img className='picture-container' src={this.props.lead.photo}/>
          </div>
          <div className='col-lg-9 border-box no-left-padding'>
            <div className='name'>{this.props.lead.first_name} {this.props.lead.last_name}</div>
            <div className='brand'>{this.props.lead.microsite}</div>
            <div className='created_at'>Created at: {this.props.lead.created_at}</div>
          </div>
        </div>
        <div className='wall-container'>
          {this.renderWallObjects()}
        </div>
      </div>
    );
  }
}
