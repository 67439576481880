import React, { useState } from 'react';
import Attempt from './AttemptView';
import moment from 'moment';
import RefundModal from './RefundModal';

const toggleTransactionRow = (e) => {
  const parent = e.currentTarget.closest('.payment-row');

  parent.classList.toggle('expanded');
};

const canRetry = (mappedEvent) => {
  return mappedEvent && mappedEvent['canRetryCharge'];
};

const retryFailedInvoice = (event) => {
  const button = event.currentTarget;
  const confirmation = confirm("RETRY CHARGE will attempt to charge the agent's card on file for their failed payment. Are you sure?");
  const { retryPaymentsPath } = button.closest('.payment-rows').dataset;
  const { stripeInvoiceId } = button.dataset;

  if (!button.disabled && confirmation) {
    document.body.addEventListener(`stripe_retry_charge.${stripeInvoiceId}`, retryFailedInvoiceResult);

    toggleButton(button, 'disable')

    fetch(
      retryPaymentsPath,
      {
        method: 'PUT',
        credentials: 'same-origin',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ stripe_invoice_id: stripeInvoiceId }),
      }
    );
  }
};

const retryFailedInvoiceResult = (event) => {
  const {
    agent_id,
    stripe_invoice_id,
    success,
    errors,
  } = event.detail;

  const retryButton = document.querySelector(`[data-stripe-invoice-id="${stripe_invoice_id}"]`);
  const messageDiv = retryButton.parentElement.querySelector('.stripe-charge-messages');
  const paymentRow = retryButton.closest('.payment-row');

  messageDiv.innerText = '';

  settleInvoice(paymentRow, success);

  if (success) {
    toggleButton(retryButton, 'success');
  } else {
    messageDiv.innerText = errors;
    toggleButton(retryButton, 'enable');
  }
};

const toggleButton = (button, action) => {
  switch (action) {
    case 'enable':
      button.innerHTML = 'Retry Charge';
      button.removeAttribute('disabled');
      button.classList.remove('btn-success');
      button.classList.add('btn-danger');
      break;
    case 'disable':
      button.innerHTML = '<i class="fa fa-spinner fa-spin"></i>';
      button.setAttribute('disabled', true);
      button.classList.remove('btn-success');
      button.classList.add('btn-danger');
      break;
    case 'success':
      button.innerHTML = 'Success';
      button.classList.remove('btn-danger');
      button.classList.add('btn-success');
      break;
  }
};

const settleInvoice = (paymentRow, paid) => {
  const paymentLabel = paymentRow.querySelector('.payment-label');
  const paymentAttemptsDiv = paymentRow.querySelector('.payment-attempts');
  const attemptRows = paymentRow.querySelectorAll('.attempt-row');
  const lastNode = attemptRows[attemptRows.length - 1];

  cloneAndAppendAttemptRow(lastNode, attemptRows.length, paid);
  paymentAttemptsDiv.style.maxHeight = 'initial';

  if (paid) {
    paymentLabel.innerText = 'Monthly Invoice';
    paymentRow.classList.remove('open-unpaid');
  }
};

const cloneAndAppendAttemptRow = (node, rows, paid) => {
  const cloneNode = node.cloneNode(true);
  const label = `Attempt: ${rows + 1}`;
  const timestamp = moment().format('MM/DD/YYYY h:mm a');
  const outcome = paid ? 'SUCCESS - PAID' : '"Generic Decline"';

  [label, timestamp, outcome].forEach((t, i) => {
    cloneNode.children.item(i).innerText = t;
  });

  node.after(cloneNode);
};

const TransactionView = (props) => {
  const {
    has_permission,
    hasRefundPermission,
    refundUrl,
    reloadTransactions,
    transaction,
  } = props;

  const {
    created_at,
    name,
    amount,
    open_unpaid_invoice,
    payment_attempts,
    disputes,
    refund,
    event_type,
    stripe_invoice_id,
    stripe_charge_id: stripeChargeId,
  } = transaction;

  const [showRefundModal, setShowRefundModal] = useState(false)

  const toggleRefundModal = () => {
    setShowRefundModal(!showRefundModal);
  };

  const eventMapping = {
    'invoice.payment_failed': {
      tagName:   ' (Failed)',
      className: 'open-unpaid',
      canRetryCharge: true
    },
    'invoice.marked_uncollectible': {
      tagName:   ' (Invoice Waived)',
      className: '',
      canRetryCharge: false
    },
    'invoice.voided': {
      tagName:   ' (Invoice Waived)',
      className: '',
      canRetryCharge: false
    },
    'invoice.payment_succeeded': {
      tagName:   '',
      className: '',
      canRetryCharge: false
    },
    'charge.refunded': {
      tagName: '',
      className: '',
      canRetryCharge: false
    },
    null: {
      tagName:   ' (Pending)',
      className: '',
      canRetryCharge: false
    }
  };

  const classList = ['payment-row'];
  const nameTag = [name];
  const hasAttempts = payment_attempts.length > 0;
  const hasDisputes = disputes && disputes.length > 0;
  const mappedEvent = eventMapping[event_type];
  const formattedDate = moment(created_at).format('MM/DD/YYYY');
  const formattedAmount = (Math.abs(amount) / 100).toFixed(2);
  let amountClass = '';
  let parsedAmount = `$${ formattedAmount }`;

  const refundView = () => {
    const formattedDate = moment(refund.created_at).format('MM/DD/YYYY h:mm a');

    return (
      <div className='attempt-row'>
        <div className='attempt-column' />
        <div className='attempt-column'>
          {formattedDate}
        </div>
        <div className='attempt-column'>
          CHARGE REFUNDED
        </div>
      </div>
    )
  }

  if (hasAttempts) {
    classList.push('has-payment-attempts');
  }

  if (mappedEvent) {
    classList.push(mappedEvent['className']);
    nameTag.push(mappedEvent['tagName']);
  }

  if (amount < 0) {
    amountClass = 'positive-amount';
    parsedAmount = '+' + parsedAmount;
  } else if (amount > 0) {
    amountClass = 'negative-amount';
    parsedAmount = '-' + parsedAmount;
  }

  return (
    <div className={ classList.join(' ') }>
      <div className='payment-header' onClick={ toggleTransactionRow }>
        <div className='payment-column'>
          {(hasAttempts || hasRefundPermission || hasDisputes) && <div className='right-arrow fa fa-caret-right' /> }
        </div>
        <div className='payment-column'>
          { formattedDate }
        </div>
        <div className='payment-column payment-label'>
          { nameTag.join('') }
          {
            hasDisputes && (
              <div className="disputed">(DISPUTED)</div>
            )
          }
          {refund && (
            <div className="refund-pill">Charge Refunded</div>
          )}
        </div>
        <div className={ `payment-column ${ amountClass }` }>
          { parsedAmount }
        </div>
      </div>
      <div className='payment-attempts'>
        {
          payment_attempts.map((attempt, i) => {
            return (
              <Attempt
                key={ i }
                attempt={ attempt }
                attemptCount={ i + 1 }
              />
            );
          })
        }
        { refund && refundView() }
        {
          (has_permission || hasRefundPermission) && (
            <div className='payment-stripe-retry mt-20'>
              <span className='stripe-charge-messages'></span>
              {
                !refund && hasRefundPermission && (
                  <button
                    className='btn btn-xs btn-danger pull-right retry-stripe-charge'
                    onClick={toggleRefundModal}
                  >
                    Refund Agent
                  </button>
                )
              }
              {
                has_permission && canRetry(mappedEvent) && (
                  <button
                    data-stripe-invoice-id={stripe_invoice_id}
                    className='btn btn-xs btn-danger pull-right retry-stripe-charge'
                    onClick={retryFailedInvoice}
                  >
                    Retry Charge
                  </button>
                )
              }
            </div>
          )
        }
      </div>
      <div className='payment-disputes'>
        {
          disputes.map(((dispute, idx) => (
            <div className='dispute-row'>
              <div className='dispute-column'>
                {`Dispute: ${idx + 1}`}
              </div>
              <div className='dispute-column'>
                {moment(dispute.created_at).format('MM/DD/YY')}
              </div>
              <div className='dispute-column'>
                {dispute.amount_readable}
              </div>
              <div className='dispute-column' title={dispute.status_readable}>
                {dispute.status_readable}
              </div>
              <div className='dispute-column' title={dispute.reason_readable}>
                {dispute.reason_readable}
              </div>
            </div>
          )))
        }
      </div>
      <RefundModal
        refundUrl={refundUrl}
        reloadTransactions={reloadTransactions}
        show={showRefundModal}
        stripeChargeId={stripeChargeId}
        toggle={toggleRefundModal}
      />
    </div>
  );
};

export default TransactionView;
