import { Component }  from 'react';
import Assign from './views/Assign';

export default class Container extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.fetchStats();
  }

  fetchStats() {
    const {
      stats_params,
      stats_path,
    } = this.props;

    fetch(
      `${stats_path}?${stats_params}`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-type': 'application/json',
        },
      }
    )
    .then(response => response.json())
    .then(response => console.log(response.success));
  }

  render() {
    const {
      index_path,
      sales_manager,
      product_type,
      sale_associates,
    } = this.props;

    return(
      sales_manager &&
      <Assign
        indexPath={ index_path }
        productType={ product_type }
        saleAssociates={ sale_associates }
      />
    );
  }
}
