import Client from './results/Client';
import Agent from './results/Agent';
import Moderator from './results/Moderator';
import Actions from './results/Actions';

const Results = ({ profileReviews, reviewModerationReviewValidatePath }) => {
  function buildResult(review) {
    const {
      review_id,
      client,
      agent,
      moderator,
    } = review;

    return (
      <div className="result mb-15">
        <div className="row">
          <Client client={ client } />
        </div>
        <div className="row">
          <Agent agent={ agent } />
          <Moderator moderator={ moderator } />
        </div>
        <div className="row">
          <Actions
            reviewId={ review_id }
            reviewModerationReviewValidatePath={ reviewModerationReviewValidatePath }
          />
        </div>
      </div>
    );
  }

  function displayResults() {
    const results = [];

    profileReviews.forEach(profileReview => {
      results.push(buildResult(profileReview));
    });

    return results;
  }

  return (
    <div className="search-results">
      { profileReviews && displayResults() }
    </div>
  );
};

export default Results;
