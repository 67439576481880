import React, { Component } from 'react';
import { connect } from 'react-redux';
import View from './view';

function mapStateToProps(state) {
  return {
    info: {
      country: state.info.get('country'),
    },
    buyer_cities: state.info.get('buyer_cities'),
    validations: state.validations.get('buyer_cities'),
    focused: state.focused.get('buyer_cities'),
  };
}

class BuyerCities extends Component {
  constructor(props) {
    super(props);

    this.addBuyerCityFields = this.addBuyerCityFields.bind(this);
    this.addCityState = this.addCityState.bind(this);
    this.removeCityState = this.removeCityState.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
  }

  setCityState({ name, value, index }) {
    const { dispatch } = this.props;

    dispatch({
      type: 'ADD_BUYER_CITY',
      data: { [name]: value },
      index,
    });
  }

  addCityState(e) {
    const { buyer_cities, dispatch, info } = this.props;
    const { name, value } = e.target;
    const index = parseInt(e.target.getAttribute('data-index'), 10);

    const cityState = buyer_cities[index];
    cityState[name] = value;
    this.setCityState({ name, value, index });

    dispatch({
      type: 'GET_BUYER_CITY_LOCATION',
      data: {
        city: cityState.city,
        country: info.country,
        state: cityState.state,
        index,
      },
    });
  }

  removeCityState(index) {
    const { dispatch } = this.props;

    dispatch({
      type: 'REMOVE_BUYER_CITY_FIELD',
      index: parseInt(index, 10),
    });

    dispatch({
      type: 'REMOVE_BUYER_CITY_FOCUS',
      index: parseInt(index, 10),
    });

    dispatch({
      type: 'REMOVE_BUYER_CITY_FIELD_VALIDATION',
      index: parseInt(index, 10),
    });
  }

  addBuyerCityFields() {
    const {
      buyer_cities,
      dispatch,
      info
    } = this.props;

    if (buyer_cities.length >= 4 ) {
      return;
    }

    dispatch({
      type: 'ADD_BUYER_CITY_FIELD',
    });
    dispatch({
      type: 'ADD_BUYER_CITY_FOCUS',
    });
  }

  handleOnFocus(e) {
    const { name, value } = e.target;
    const { dispatch } = this.props;
    const index = parseInt(e.target.getAttribute('data-index'), 10);

    dispatch({
      type: 'SET_BUYER_CITY_FOCUS',
      index,
    });
  }

  render() {
    const {
      buyer_cities,
      focused,
      info,
      validations,

      handleBuyerCityFocus,
    } = this.props;

    const { country } = info;

    const data = {
      buyer_cities,
      country,
    };

    return (
      <View
        data={ data }
        focus={ focus }
        focused={ focused }
        validations={ validations }

        addCityState={ this.addCityState }
        addBuyerCityFields={ this.addBuyerCityFields }
        handleBuyerCityFocus={ this.handleOnFocus }
        removeCityState={ this.removeCityState }
      />
    );
  }
}

export default connect(mapStateToProps)(BuyerCities);
