import CurrentAreas from './body/CurrentAreas';
import Results from './body/Results';
import Search from './body/Search';

import React, { useState } from 'react';

const Body = (props) => {
  const {
    currentAreas,
    currentVisible,
    lookupPath,
    serviceAreas,
    updateState,
  } = props;

  const [requestingAreas, setRequestingAreas] = useState(false);

  return (
    <div className='modal-body'>
      <div className='search-service-areas mb-10'>
        <div className='sticky-container'>
          <Search
            lookupPath={ lookupPath }
            setRequestingAreas={ setRequestingAreas }
            updateState={ updateState }
          />
        </div>

        {
          currentVisible ?
          <CurrentAreas
            currentAreas={ currentAreas }
          />
          :
          <Results
            currentAreas={ currentAreas }
            requestingAreas={ requestingAreas }
            serviceAreas={ serviceAreas }
          />
        }
      </div>
    </div>
  );
};

export default Body;
