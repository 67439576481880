import React from 'react';

const Email = ({ email }) => {
  const renderEmailStatus = (status) => {
    if (status === 'sent') {
      return ['fa fa-check-circle'];
    } else if (status === 'unsent') {
      return ['fa fa-question-circle'];
    } else {
      return ['fa fa-times-circle'];
    }
  }

  return (
    <a href={`/emails/${email.id}/message`} data-remote='true'>
      <div className='react-email-row'>
        <div className='status'>
          <i className={renderEmailStatus(email.status)}></i>
        </div>
        <div className='title-block'>
          <div className='title'>
            Date:
          </div>
          <div className='title'>
            Type:
          </div>
        </div>
        <div className='email-block'>
          <div className='value'>
            {email.date}
          </div>
          <div className='value'>
            { email.type ? Util.shortenString(email.type, 30) : 'None' }
          </div>
        </div>
        <div className='title-block'>
          <div className='title'>
            From:
          </div>
          <div className='title'>
            To:
          </div>
        </div>
        <div className='email-block'>
          <div className='value'>
            { email.from ? Util.shortenString(email.from, 30): 'None' }
          </div>
          <div className='value'>
            { email.to ? Util.shortenString(email.to, 30): 'None' }
          </div>
        </div>
      </div>
    </a>
  )
}

export default Email;
