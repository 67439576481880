const piexif = require('piexifjs');

const getOrientation = (img) => {
  if (img.includes('data:image/jpeg')) {
    const exifObj = piexif.load(img);
    return exifObj['0th'][piexif.ImageIFD.Orientation];
  }
  return 1;
};

const createCanvas = async (e, name, cb, minPixels) => {
  const img = e.target;
  const { width, height, src } = img;

  if (minPixels && (width <= minPixels || height <= minPixels)) {
    alert(`
      Pixel size must be at least ${minPixels} x ${minPixels}
      for the best quality photo.`
    );
    return;
  }

  const dx = 0;
  const dy = 0;
  let dWidth = width;
  let dHeight = height;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const orientation = getOrientation(src);
  canvas.width = width;
  canvas.height = height;

  switch (orientation) {
    case 3:
      dWidth = -width;
      dHeight = -height;
      ctx.rotate(Math.PI);
      break;
    case 6:
      canvas.width = height;
      canvas.height = width;
      ctx.translate(height, 0);
      ctx.rotate((90 * Math.PI) / 180);
      dWidth = width;
      dHeight = height;
      break;
    case 8:
      canvas.width = height;
      canvas.height = width;
      dWidth = -width;
      dHeight = -height;
      ctx.translate(height, 0);
      ctx.rotate((270 * Math.PI) / 180);
      break;
    default:
      break;
  }

  ctx.drawImage(
    img,
    dx,
    dy,
    dWidth,
    dHeight,
  );

  const image = await new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Canvas is empty'));
        return;
      }
      blob.name = name; // eslint-disable-line
      blob.lastModifiedDate = new Date(); // eslint-disable-line
      resolve(blob);
    }, 'image/png');
  });
  cb(window.URL.createObjectURL(image));
};

const loadFile = (e, name, cb, minPixels) => {
  const img = new Image();
  img.onload = (event) => createCanvas(event, name, cb, minPixels);
  img.src = e.target.result;
};

const loadFileAsCanvas = (e, cb, minPixels = null) => {
  if (e.target.files && e.target.files.length > 0) {
    const imageFile = e.target.files[0];
    const { name } = imageFile;
    const reader = new FileReader();
    reader.onload = (event) => loadFile(event, name, cb, minPixels);
    reader.readAsDataURL(imageFile);
    e.target.value = null;
  }
};

export default loadFileAsCanvas;
