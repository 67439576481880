import InputField from './../../_common/InputField.js';
import PhoneField from './../../_common/PhoneField.js';
import SelectField from './../../_common/SelectField.js';
import RadioFields from './../../_common/RadioFields.js';

const ClientInformation = props => {
  const {
    micrositeOptions,
    radioFieldOptions,
    updateFieldHandler,
  } = props;

  return (
    <div className="section">
      <div className="header">
        <div className="icon-wrapper"><i className="icon user"></i></div>
        <div className="title-wrapper">
          <h2>Client Information</h2>
        </div>
      </div>

      <div className="input-row">
        <InputField fieldType="text" fieldTitle="First Name" required={ true } updateFieldHandler={ updateFieldHandler } />
        <InputField fieldType="text" fieldTitle="Last Name" required={ true } updateFieldHandler={ updateFieldHandler } />
      </div>

      <div className="input-row">
        <PhoneField fieldType="tel" fieldTitle="Phone Number" required={ true } updateFieldHandler={ updateFieldHandler } />
        <InputField fieldType="email" fieldTitle="Email" required={ true } updateFieldHandler={ updateFieldHandler } />
      </div>

      <div className="input-row">
        <SelectField fieldTitle="Lead Type" fieldName="microsite_id" options={ micrositeOptions } required={ true } includeBlank={ true } updateFieldHandler={ updateFieldHandler } />
        <RadioFields fieldTitle="Open in Qual?" options={ radioFieldOptions } required={ false } inline={ true } updateFieldHandler={ updateFieldHandler } defaultValue="false" />
      </div>

      <div className="divider"></div>
    </div>
  );
};

export default ClientInformation;
