import React from 'react';
import ReactDOM from 'react-dom';
import AgentInsightMonitor from './AgentInsightMonitor';

/**
 * Initialize the AgentInsights system.
 */

export function initializeMonitor(containerElement, options) {
  let component = React.createElement(AgentInsightMonitor, options);
  ReactDOM.render(component, containerElement);
}