import React, { useEffect } from 'react';

import Areas from './Areas';

const groupedResults = (geos) => {
  const results = {};

  geos.forEach(geo => {
    const { city, state, country } = geo;
    const key = `${country}.${state}.${city}`;

    if (key in results) {
      results[key].push(geo);
    } else {
      results[key] = [geo];
    }
  });

  return results;
};

const selectedAreas = () => {
  const wrapper = document.getElementById('location-search-results-wrapper');
  const areas = wrapper.querySelectorAll('.location-option.selected:not(.select-all)');

  return [...areas].map(area => area.dataset);
};

const newAreas = (currentAreas, searchAreas) => {
  const current = groupedResults(currentAreas);
  const selected = groupedResults(selectedAreas());

  return Object.values({ ...current, ...selected }).flat();
};

const SearchResults = ({ fetching, currentAreas, searchAreas, setAttribute, viewExcluded }) => {
  useEffect(() => handleCurrentAreas, [searchAreas]);

  const wrapperKlass = fetching ? 'overlay' : '';

  function handleCurrentAreas() {
    const wrapper = document.getElementById('location-search-results-wrapper');
    const options = wrapper.querySelectorAll('.location-option:not(.select-all)');

    options.forEach(option => {
      const { zip } = option.dataset;

      if (currentAreas.find(area => area.zip === zip)) option.click();
    });
  }

  function handleDiscard() {
    setAttribute('searchAreas', []);
    viewExcluded(true);
  }

  function handleUpdateAreas() {
    setAttribute('currentAreas', newAreas(currentAreas, searchAreas));
    handleDiscard();
  }

  return (
    <div id='location-search-results-wrapper' className={ wrapperKlass }>
      <h2>Search Results</h2>
      <Areas areas={ searchAreas } />
      <div className='footer text-right'>
        <button className='btn btn-lg btn-warning mr-15' onClick={ handleUpdateAreas }>Update Areas</button>
        <button className='btn btn-lg btn-default' onClick={ handleDiscard }>Discard</button>
      </div>
    </div>
  );
};

export default SearchResults;
