import { Map } from 'immutable';

const initialState = Map({
  intent: null,
  phoneToName: null,
  subscriberName: null,
  verifications: null,
  duplicates: null,
});

const actionsMap = {
  ['SET_METADATA']: (state, action) => {
    const {
      duplicate_leads,
      intent,
      white_pages,
    } = action.data;

    let phone_to_name = null;
    let subscriber_name = null;

    if (white_pages) {
      const { data_values } = white_pages;
      const whitePagesData = JSON.parse(data_values); // address, email, phone
      const { phone } = whitePagesData;

      phone_to_name = phone.phone_to_name;
      subscriber_name = phone.subscriber_name;
    }

    return state.merge(Map({
      intent,
      phoneToName: phone_to_name,
      subscriberName: subscriber_name,
      duplicates: duplicate_leads,
    }));
  },
  ['SET_VERIFICATION_RECORDS']: (state, action) => {
    return state.merge(Map({
      verifications: action.data
    }));
  },
  ['RESET_METADATA']: (state) => {
    return state.merge(initialState);
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
