const Form = props => {
  const {
    agent,
    recruitmentNote,
    handleRecruitmentNote,
    dispositionProspect,
  } = props;

  return (
    <form id="dialer-recruit-top-agents-disposition-form" className="my-30">
      <div className="row">
        <div className="col-md-8">
          <div className="label-type mb-15">Recruitment Notes (optional)</div>
          <div className="form-group mb-0">
            <textarea rows="5" className="form-control" name="note" onChange={ handleRecruitmentNote } value={ recruitmentNote }></textarea>
          </div>
        </div>
        <div className="col-md-4 mt-35">
          <div className="row">
            <div className="col-md-6 mb-25">
              <button className="btn btn-lg btn-rex-white width-100" data-disposition="recruit_prep" onClick={ dispositionProspect }>Recruit</button>
            </div>
            <div className="col-md-6 mb-25">
              <button className="btn btn-lg btn-rex-white width-100" data-disposition="block" onClick={ dispositionProspect }>Block</button>
            </div>
            <div className="col-md-6">
              <button className="btn btn-lg btn-rex-white width-100" data-disposition="voicemail" onClick={ dispositionProspect } disabled={ !agent.phone }>No answer / VM</button>
            </div>
            <div className="col-md-6">
              <button className="btn btn-lg btn-rex-white width-100" data-disposition="bad_number" onClick={ dispositionProspect } disabled={ !agent.phone }>Bad number</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};

export default Form;
