import { Component } from 'react';

import TopContainer from './TopContainer';
import BottomContainer from './BottomContainer';

export default class LeftContainer extends Component {
  render() {
    return (
      <div className='profile-review-left-container'>
        <TopContainer
          lead                      = {this.props.lead}
          matches                   = {this.props.matches}
          callLead                  = {this.props.callLead}
          toggleEditContainer       = {this.props.toggleEditContainer}
          toggleMultipleContainer   = {this.props.toggleMultipleContainer}
          dual_transaction          = {this.props.dual_transaction}
          dual_transaction_buyer    = {this.props.dual_transaction_buyer}
          directReferral            = {this.props.directReferral}
          exclusive                 = {this.props.exclusive}
          exclusiveAgentId          = {this.props.exclusiveAgentId}
          exclusiveAgentLink        = {this.props.exclusiveAgentLink}
        />

        <BottomContainer
          lead_note            = {this.props.lead_note}
          lead_type            = {this.props.lead.lead_type}
          matches              = {this.props.matches}
          noteError            = {this.props.noteError}
          outcome              = {this.props.outcome}
          setFollowUpDateState = {this.props.setFollowUpDateState}
          showOutcomeContainer = {this.props.showOutcomeContainer}
          submitCallOutcome    = {this.props.submitCallOutcome}
          updateLeadNote       = {this.props.updateLeadNote}
          updateOutcome        = {this.props.updateOutcome}
        />
      </div>
    );
  }
}
