import { Component } from 'react';

export default class LiveSellerForm extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      lead: {},
      invalid_city_state: props.data.validations.invalid_location
    };

    this.checkCityState       = this.checkCityState.bind(this);
    this.checkZip             = this.checkZip.bind(this);
    this.handleLeadChange     = this.handleLeadChange.bind(this);
    this.highlightAndVerify   = this.highlightAndVerify.bind(this);
    this.verifyPrice          = this.verifyPrice.bind(this);
    this.handleCheckChange    = this.handleCheckChange.bind(this);
    this.handleCommentsChange = this.handleCommentsChange.bind(this);
  }

  componentDidMount(){
    this.setLead();
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.data && nextProps.data.lead.id !== this.state.lead.id) this.setLead(nextProps);
  }

  setLead(props = this.props){
    if(props.data) this.setState({ lead: Object.assign({}, props.data.lead) })
  }

  handleLeadChange( e ) {
    let targetVal = e.target.value;
    let targetName = e.target.name;

    if (['home_price_max', 'home_price_min'].includes(targetName)) {
      targetVal = targetVal.replace(/\D/g,'');
    };

    this.props.data.lead[targetName] = targetVal;
    this.props.updateState( this.props.data );
  }

  handleChange( e ) {
    let targetVal = e.target.value;
    let targetName = e.target.name;

    if(targetName === 'qual_note') {
      this.props.data.pmr_form.matcher_notes = targetVal;
    }

    this.props.data[targetName] = targetVal;
    this.props.updateState( this.props.data );
  }

  handleCommentsChange( e ) {
    this.props.data[ e.target.name ] = e.target.value;
    this.props.data.pmr_form[ e.target.name ] = e.target.value;
    this.props.updateState( this.props.data );
  }

  handleCheckChange( e ) {
    this.props.data.lead[ e.target.name ] = e.target.checked;
    this.props.updateState( this.props.data );
  }

  verifyField(e) {
    if (!Object.keys(this.props.data.live_verifications).includes(e.target.name)) { return }

    this.props.data.live_verifications[e.target.name] = true
    this.props.updateState(this.props.data)
  }

  verifyPrice(e) {
    if (!Object.keys(this.props.data.live_verifications).includes(e.target.name)) { return }

    if (['home_price_min', 'home_price_max'].includes(e.target.name) && e.target.value == 0) {
      this.props.data.live_verifications[e.target.name] = false
      this.props.updateState(this.props.data)
    }
  }

  checkCityState() {
    // Check with marketing_geos api db for valid city/state pair
    const { city, state } = this.props.data.lead;

    $.get('/qual/queue/verify_location', { city, state }, res => {
      this.setState({ invalid_city_state: !res.valid })
      this.props.data.live_verifications.city = res.valid
      this.props.data.live_verifications.state = res.valid
      this.props.updateState(this.props.data)
    })
  }

  checkZip() {
    if (!this.props.data.lead.zip) {
      this.props.data.live_verifications.zip = false;
    } else {
      const zip = this.props.data.lead.zip.replace(/\s/g, '');
      const regex = /^([a-zA-Z0-9]){3,6}$/;
      this.props.data.live_verifications.zip = regex.test(zip);
    }
    this.props.updateState(this.props.data);
  }

  highlightClass(field) {
    if (!Object.keys(this.props.data.live_verifications).includes(field)) {
      return ''
    } else if (this.props.data.live_verifications[field]) {
      return 'verified'
    } else {
      return 'verification-needed'
    }
  }

  highlightAndVerify(e) {
    this.highlightIntro(e);
    this.verifyField(e);
  }

  render() {
    const {
      lead,
      live,
      live_verifications,
      comments,
      validations,
    } = this.props.data;

    return(
      <div>
        <div className='raw_input'>
          { `RAW INPUT: ` + (this.props.data.live.raw_location_for_client || 'n/a') }
        </div>
        <div className='field w157 odd'>
          <div className='label'>
            {'Street Number' + (live_verifications.street_number ? "  \u2705" : '')}
          </div>
          <input
            type='text'
            name='street_number'
            className={ this.highlightClass('street_number') }
            value={ lead.street_number || '' }
            onChange={ this.handleLeadChange }
            onFocus={this.highlightAndVerify}
            />
        </div>
        <div className='field w319 odd'>
          <div className='label'>
            {`Street Name` + (live_verifications.street_name ? "  \u2705" : '')}
          </div>
          <input
            type='text'
            name='street_name'
            className={ this.highlightClass('street_name') }
            value={ lead.street_name || '' }
            onChange={ this.handleLeadChange }
            onFocus={this.highlightAndVerify}
            maxLength={ 35 }
            />
        </div>
        <div className='field w85'>
          <div className='label'>
            Unit Number
          </div>
          <input
            type='text'
            data-optional='true'
            name='unit_number'
            value={ lead.unit_number || '' }
            onChange={ this.handleLeadChange }
            onFocus={this.highlightAndVerify}
            />
        </div>
        <img
          src={window.image_path('google-icon')}
          className='icon'
          onClick={ this.googleSearch.bind( this ) }
        />
        <div className='clear'></div>
        <div className='field w330 odd'>
          <div className='label'>
            {`City` + (live_verifications.city ? "  \u2705" : '')}
          </div>
          <input
            type='text'
            name='city'
            className={ this.highlightClass('city') }
            value={ lead.city || '' }
            onChange={ this.handleLeadChange }
            onFocus={this.highlightAndVerify}
            onBlur={ this.checkCityState }
            />
          <span className={this.state.invalid_city_state ? 'invalid-location' : 'invalid-location hidden'}>INVALID LOCATION</span>
        </div>
        <div className='field w138 odd'>
          <div className='label'>
            {lead.country === 'CA' ? 'Province' : 'State' }
            {live_verifications.state ? "  \u2705" : ''}
          </div>
          <input
            type='text'
            name='state'
            className={ this.highlightClass('state') }
            value={ lead.state || '' }
            onChange={ this.handleLeadChange }
            onFocus={this.highlightAndVerify}
            onBlur={ this.checkCityState }
            />
        </div>
        <div className='field w143'>
          <div className='label'>
            {lead.country === 'CA' ? 'Postal Code' : 'Zip'}
            {live_verifications.zip ? "  \u2705" : ''}
            <span className={validations.invalid_zip ? 'invalid-zip' : 'invalid-zip hidden' }>INVALID ZIP</span>
          </div>
          <input
            type='text'
            name='zip'
            className={ this.highlightClass('zip') }
            value={ lead.zip || ''}
            onChange={ this.handleLeadChange }
            onFocus={this.highlightAndVerify}
            onBlur={this.checkZip}
            />
        </div>
        <div className='clear'></div>
        <div className='field odd hp price-range-field'>
          <div className='label'>
            {`Price Range` + (live_verifications.home_price_max && live_verifications.home_price_min ? "  \u2705" : '')}
            <span className='italicized'>{`Raw Input: ` + (live.raw_price_input || 'n/a')}</span>
            <span className={ lead.home_price_max >= 1000000 ? 'highend' : 'highend hidden' }>HIGH END</span>
          </div>
          <div className="clear"></div>
          <input
            type='text'
            name='home_price_min'
            className={['price-range', 'price-range-left', this.highlightClass('home_price_min')].join(' ')}
            data-optional='true'
            value={ numeral(lead.home_price_min).format('0,0') }
            onChange={ this.handleLeadChange }
            onFocus={this.highlightAndVerify}
            onBlur={ this.verifyPrice }
            placeholder='Min'
            />
          <input
            type='text'
            name='home_price_max'
            className={['price-range', this.highlightClass('home_price_max')].join(' ')}
            data-optional='true'
            value={ numeral(lead.home_price_max).format('0,0') }
            onChange={ this.handleLeadChange }
            onFocus={this.highlightAndVerify}
            onBlur={this.verifyPrice}
            placeholder='Max'
            />
        </div>
        <div className='field selectdd'>
          <div className='label'>
            {`Property Type` + (live_verifications.type_home ? "  \u2705" : '')}
          </div>
          <select
            name='type_home'
            value={ lead.type_home }
            className={this.highlightClass('type_home')}
            onChange={ this.handleLeadChange }
            onFocus={this.highlightAndVerify}
          >
            <option>Choose One</option>
            <option value='Single Family'>Single Family</option>
            <option value='Condominium'>Condominium</option>
            <option value='Town Home'>Town Home</option>
            <option value='Mobile Home'>Mobile Home with land</option>
            <option value='2-4 Units'>2-4 Units</option>
            <option value='Vacation Home'>Vacation Home</option>
            <option value='Rental'>Rental</option>
            <option value='Co-op'>Co-op</option>
            <option value='Loft'>Loft</option>
            <option value='Land/Lot'>Land/Lot</option>
            <option value='Unknown'>Unknown</option>
          </select>
        </div>
        <div className='clear'></div>
        <div className='field'>
          <div className='field checkboxes-live'>
            <input
              checked={lead.opt_out || ''}
              type="checkbox"
              data-optional='true'
              name='opt_out'
              id='opt_out'
              onChange={ this.handleCheckChange }
            />
            <label htmlFor="opt_out">
              Opt Out
            </label>
          </div>
          <div className='field checkboxes-live'>
            <input
              checked={lead.commercial || ''}
              type="checkbox"
              data-optional='true'
              id="commercial"
              name='commercial'
              onChange={ this.handleCheckChange }
            />
            <label htmlFor="commercial">
              Commercial
            </label>
          </div>
        </div>
        <div className='clear'></div>
        <div className='clear'></div>
        <div className='field full'>
          <div className='label'>
            Client Comments (will be viewed as a custom message to agents)
          </div>
          <textarea
            data-optional='true'
            name='comments'
            onChange={ this.handleCommentsChange }
            value={ comments || '' }
            onFocus={ this.highlightClosing.bind( this ) }
          >
          </textarea>
        </div>
      </div>
    )
  }

  googleSearch( e ) {
    let data = this.props.data.lead,
        street_number = data.street_number || '',
        street_name = data.street_name || '',
        city = data.city || '',
        state = data.state || '',
        zip = data.zip || '',
        unit_number = data.unit_number || ''

    window.open(`https://www.google.com/search?q=${street_number}%20${street_name}%20${unit_number.replace(/#/, '')}%20${city}%20${state}%20${zip}`)
  }

  highlightIntro() {
    EventBus.emit( 'highlight:intro' );
  }

  highlightQuestions() {
    EventBus.emit( 'highlight:questions' );
  }

  highlightClosing() {
    EventBus.emit( 'highlight:closing' );
  }
}
