import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Link from './images/link.png';
import Agent from './images/agent.png';
import Fetch from '../../common/fetch';
import './styles.scss';

export function initialize(containerElement, options) {
  let component = React.createElement(LeadSMS, options);
  ReactDOM.render(component, containerElement);
}

const textAreaMaxLength = 1600;

const LeadSMS = (props) => {
  const {
    lead_id,
    text_messages,
  } = props;
  const [threads, setThreads] = useState(text_messages);
  const [filterBy, setFilterBy] = useState('View All Threads');
  const [message, setMessage] = useState('');
  const [filterOptions, setFilterOptions] = useState(['View All Threads']);
  const [messagingServiceSID, setMessagingServiceSID] = useState('');
  const [fromNumber, setFromNumber] = useState('');
  const [unresolvedThreads, setUnresolvedThreads] = useState({});
  const disableMarkResolve = !unresolvedThreads[filterBy];
  const disableSendSMS = filterBy === 'View All Threads'
    || message === '';
  const filterThreads = filterBy === 'View All Threads'
    ? threads
    : threads.filter(text => text.display_name === filterBy);
  const handleMessagingServiceSIDs = threads
    .filter(({display_name, direction}) => display_name === filterBy && direction === 'inbound')
    .map(thread => thread.messaging_service_sid);

  useEffect(() => {
    const smsThread = document.getElementsByClassName('sms-thread')[0];
    const filterOptionsCopy = [...filterOptions];
    let newMessagingServiceSID = '';
    let newFromNumber = '';
    let newUnresolvedThreads = {};
    let newFilterby = 'View All Threads';

    threads.forEach((thread) => {
      if (thread.display_name !== null) {
        if (!filterOptionsCopy.includes(thread.display_name)) {
          filterOptionsCopy.unshift(thread.display_name);
        }
        if (newMessagingServiceSID === '') {
          newMessagingServiceSID = thread.messaging_service_sid;
        }
        if (thread.direction === 'inbound') {
          if (newFromNumber === '') {
            newFromNumber = thread.from_number;
          }
          if (thread.resolved_at === null) {
            newUnresolvedThreads[thread.display_name] = true;
            newFilterby = thread.display_name;
          }
        }
      }
    });
    setFilterOptions(filterOptionsCopy);
    setMessagingServiceSID(newMessagingServiceSID);
    setFromNumber(newFromNumber);
    setUnresolvedThreads(newUnresolvedThreads);
    setFilterBy(newFilterby);

    if (smsThread) {
      smsThread.scrollTop = smsThread.scrollHeight;
    }
  }, []);

  const copyClientLink = async () => {
    const resp = await Fetch.post({
      url: '/url_shortener/shorten_clients_url',
      payload: {
        lead_id,
      },
    });
    navigator.clipboard.writeText(resp.result).then(() => alert('URL copied to clipboard.'));
  };

  const copyFunnelLink = async () => {
    const result = await Fetch.post({
      url: '/url_shortener/shorten_funnel_url',
      payload: {
        lead_id: lead_id
      },
    });
    navigator.clipboard.writeText(result.result).then(() => alert('URL copied to clipboard.'));
  };

  const addAttachmentAgent = (attachment_agents) => {
    if (attachment_agents.length) {
      return (
        <>
          {
            attachment_agents.map((agent, idx) => (
              <div className="agent-info" key={ idx }>
                <div className="agent-card">
                  <img className="agent-card-img" src={ Agent } />
                </div>
                <div className="agent-name">
                  {`${agent}.vcf`}
                </div>
              </div>
            ))
          }
        </>
      );
    }
  };

  const parseBody = (body) => {
    const isValidUrl = (_string) => {
      const matchpattern = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      return matchpattern.test(_string);
    }

    return body
      .replaceAll('\n', ' <br> ')
      .split(' ')
      .map((word) => {
        if (isValidUrl(word)) {
          return (`<a href=${word} target="_blank" rel="noreferrer noopener">${word}</a>`);
        } else {
          return word;
        }
      })
      .join(' ');
  }

  const viewThreads = () => (
    <>
      { filterThreads.map((text, idx) => {
        const {
          attachment_agents,
          body,
          direction,
          from_number_format,
          display_name,
          timestamp,
          status,
        } = text;
        const fromWho = direction === 'outbound'
          ? 'Flux the Penguin'
          : from_number_format;
        const textRowClasses = ['text-row'];
        if (direction === 'outbound') {
          textRowClasses.push('outbound-text-row');
        }
        return (
          <div className={ textRowClasses.join(' ') } key={ idx }>
            <div className={`${direction}-text`}>
              <div className={`${direction}-info`}>
                <p>from { fromWho }</p>
                <p>via { display_name }</p>
                <p>on { timestamp }</p>
                <p>{ status }</p>
              </div>
              <div className={`${direction}-body`} dangerouslySetInnerHTML={{ __html: parseBody(body) }} />
            </div>
            { addAttachmentAgent(attachment_agents) }
          </div>
        );
      })}
    </>
  );

  const handleMessagingServiceSID = (value) => {
    let newMessagingServiceSID = '';
    threads.forEach(({display_name, messaging_service_sid}) => {
      if (display_name === value) {
        newMessagingServiceSID = messaging_service_sid;
      }
    });
    setMessagingServiceSID(newMessagingServiceSID);
  };

  const filterTextMessages = (e) => {
    setFilterBy(e.target.value);
    handleMessagingServiceSID(e.target.value);
  };

  const renderFilters = () => (
    <div className="lead-sms-thread-filters">
      {
        filterOptions.map((thread, idx) => {
          const buttonClasses = ["thread-filters-button"];
          const redDotClass = ['unresolved-indicator'];
          if (thread === filterBy) {
            buttonClasses.push('highlight');
          }
          if (unresolvedThreads[thread]) {
            redDotClass.push('red-dot');
          }
          return (
            <button
              className={ buttonClasses.join(' ') }
              key={ idx }
              onClick={ filterTextMessages }
              value={ thread }
            >
              { thread }
              <span className={ redDotClass.join(' ') }></span>
            </button>
          );
        })
      }
    </div>
  );

  const sendCustomLeadText = async (e) => {
    const result = await Fetch.post({
      url: '/text_messages_outbound/send_custom_lead_text',
      payload: {
        body: message,
        messaging_service_sid: messagingServiceSID,
        lead_id,
      },
    });

    if (result.text) {
      setThreads([...threads, result.text]);
      setMessage('');
    } else if (result.error) {
      alert(result.error);
    }
  };

  const handleMessageChange = (e) => {
    if (e.target.value.length === textAreaMaxLength) {
      alert(`SMS is at its character limit (${textAreaMaxLength}).`);
    }
    setMessage(e.target.value);
  };

  const removeRedDot = () => {
    const indicators = document.getElementsByClassName('unresolved-indicator');
    indicators.forEach(({classList, parentElement}) => {
      if (classList.contains('red-dot') && parentElement.value === filterBy) {
        classList.remove('red-dot')
      }
    })
  };

  const removeQueueItem = () => {
    const queueItem = document.getElementById(`queue_item_Lead_${lead_id}`);
    if (queueItem) {
      queueItem.style.display = 'none';
    }
  };

  const markAsResolved = async (e) => {
    const newUnresolvedThreads = {...unresolvedThreads};
    const result = await Fetch.post({
      url: '/sms_messages_inbound/mark_as_resolved',
      payload: {
        from_number: fromNumber,
        messaging_service_sids: handleMessagingServiceSIDs,
      },
    });

    if (result.success) {
      delete newUnresolvedThreads[filterBy];
      setUnresolvedThreads(newUnresolvedThreads);
      removeRedDot();
      removeQueueItem();
    } else {
      console.warn('error');
    }
  };

  const linkMouseIn = () => {
    document.getElementsByClassName('funnel-link-helper')[0].style.display = 'block';
  };

  const linkMouseOut = () => {
    document.getElementsByClassName('funnel-link-helper')[0].style.display = 'none';
  };

  return (
    <div className="lead-sms-container-wrapper">
      <div className="lead-sms-container">
        { renderFilters() }
        <div className="lead-sms-thread">
          <div className="sms-thread">
            { viewThreads() }
          </div>
          <div className="sms-thread-compose-wrapper">
            <div className="sms-thread-compose">
              <textarea
                className="send-sms-body"
                disabled={ filterBy === 'View All Threads' }
                maxLength={ textAreaMaxLength.toString() }
                placeholder="Compose your message here"
                onChange={ handleMessageChange }
                value={ message }
              />
              <button
                className="send-sms"
                disabled={ disableSendSMS }
                onClick={ sendCustomLeadText }
              >
                Send SMS
              </button>
              <button
                className="mark-resolved"
                disabled={ disableMarkResolve }
                onClick={ markAsResolved }
              >
                Mark as Resolved
              </button>
            </div>
            <div className="funnel-link-wrapper">
              <div
                className="funnel-link"
                onClick={copyClientLink}
                onMouseOver={linkMouseIn}
                onMouseOut={linkMouseOut}
              >
                <img className='link-img' src={Link} />
                Clients App URL
              </div>
              <div className="funnel-link-helper">
                Use this link to direct clients back to their agent list homepage.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
