import { Component } from 'react';
import MatchedAgentOptions from './matched_agents/MatchedAgentOptions';
import Confirm from '../../modals/Confirm';
import * as DashboardUtils from '../../common/dashboard_utils';

export default class MatchedAgents extends Component {
  constructor() {
    super();

    this.state = {
      saveDisabled: true
    };

    this.handleForm = this.handleForm.bind(this);
    this.toggleSaveButton = this.toggleSaveButton.bind(this);
  }

  componentDidMount() {
    const newMatchSelector = document.querySelector('form.match-update .new-winning-agent select');

    newMatchSelector.addEventListener('change', this.toggleSaveButton);
    DashboardUtils.initSelectInputs();
  }

  handleForm(event) {
    event.preventDefault();

    const closeModal = new CustomEvent('closeModal');
    const modalWrapper = document.querySelector('.product-dashboard-wrapper .dashboard-modal');
    const form = event.target;

    const valid = this.validateForm(form);

    if (valid) {
      Util.clearFlash();
    } else {
      Util.createFlash('Please review form errors', 'danger');
      return;
    }

    Confirm.modal('<img class="mb-15" src="images/product_dashboard/yellow-alert.svg" /><h3>Are you sure?</h3>', {
      description: 'You are about to change the winning agent for this lead.',
      confirmLabel: 'CONTINUE',
      abortLabel: 'CANCEL',
      modalSize: 'modal-xxl',
      textAlign: 'text-center',
      buttonSize: 'btn-lg'
    })
    .then(response => {
      modalWrapper.dispatchEvent(closeModal);

      fetch(
        form.action,
        {
          method: 'PUT',
          credentials: 'same-origin',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ match: Util.serializeForm(form) })
        }
      )
      .then(response => {
        if (response.ok) {
          this.updateResults();
          return;
        }

        throw new Error('Unable to save new winning agent for lead')
      })
      .then(() => {
        DashboardUtils.initSelectInputs();
      })
      .catch(error => {
        Util.createFlash(error.message, 'danger');
      });
    })
    .catch(response => {
      modalWrapper.dispatchEvent(closeModal);
    });
  }

  validateForm(form) {
    this.removeErrors(form);

    const inputs = form.querySelectorAll('input,select');
    let vi = [];

    inputs.forEach(input => {
      if (input.value) {
        vi.push(true);
      } else {
        vi.push(false);
        this.addError(input);
      }
    });

    return !vi.includes(false);
  }

  addError(input) {
    if (/select/.test(input.type)) {
      input.classList.add('has-error');
    } else {
      input.closest('.form-group').classList.add('has-error');
    }
  }

  removeErrors(form) {
    const inputs = form.querySelectorAll('.has-error');
    inputs.forEach(input => input.classList.remove('has-error'));
  }

  toggleSaveButton() {
    const saveButton = document.querySelector('form.match-update .save-btn');
    const newMatchSelector = document.querySelector('form.match-update .new-winning-agent select');

    if (newMatchSelector.value) {
      saveButton.classList.remove('disabled');
      this.setState({ saveDisabled: false });
    } else {
      saveButton.classList.add('disabled');
      this.setState({ saveDisabled: true });
    }
  }

  updateResults() {
    const currentWinner = document.querySelector('form.match-update .current-winning-agent');
    const currentMatchID = document.querySelector('form.match-update #current_match_id');
    const newMatchSelector = document.querySelector('form.match-update .new-winning-agent select');
    const selectedOption = newMatchSelector.selectedOptions[0];

    for (let option of newMatchSelector.options) {
      if (!option.value) option.selected = 'selected';
      option.disabled = false;
    }

    currentWinner.innerHTML = selectedOption.innerHTML;
    currentMatchID.value = selectedOption.value;
    selectedOption.disabled = true;

    newMatchSelector.dispatchEvent(new Event('change'));
  }

  render() {
    const { matches, sending_agent_name } = this.props.data;
    const winner = matches.find(match => match.client_chosen_date || match.referrer_chosen_date) || {};

    return (
      <form action='/product_dashboard/match' className='match-update' onSubmit={this.handleForm}>
        <h3>Results</h3>
        <table className='table table-borderless spaced-rows blue-rows'>
          <thead>
            <tr>
              <th>Sending Agent</th>
              <th>Current Winning Agent</th>
              <th>NEW Winning Agent</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{sending_agent_name}</td>
              <td className='current-winning-agent'>{winner.agent_name}</td>
              <td className='new-winning-agent'>
                <MatchedAgentOptions currentMatch={winner} matches={matches} />
              </td>
            </tr>
          </tbody>
        </table>

        <div className='form-group hidden'>
          <input type='text' className='form-control' name='current_match_id' id='current_match_id' defaultValue={winner.match_id} />
        </div>

        <button className='btn btn-lg btn-rex btn-orange save-btn disabled' type='submit' disabled={this.state.saveDisabled}>SAVE</button>
      </form>
    );
  }
}
