import Geo from './Geo';

import React, { useState } from 'react';

const Area = (props) => {
  const {
    cityName,
    geos,
  } = props;

  const [open, toggleOpen] = useState(false);

  const klass = open ? 'open' : '';

  function buildGeos() {
    return geos.map(geo => <Geo key={ geo } geo={ geo } />);
  }

  return (
    <div className='col-md-3'>
      <div className={ `service-area ${klass}` }>
        <div className='area-header' onClick={ () => toggleOpen(!open) }>
          <strong>{ cityName }</strong>
          <i className='fa fa-chevron-right pull-right'></i>
        </div>
        <div className='service-area-geos'>
          { buildGeos() }
        </div>
      </div>
    </div>
  );
};

export default Area;
