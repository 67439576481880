import FailIcon from '../../../../LiveAccountManagement/images/fail.png';

const Fail = (props) => {
  const {
    error,
    toggleModal,
  } = props;

  return (
    <div id='reactivate-fail'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ FailIcon } />
      </div>

      <div className='rex-modal-heading'>
        Payment Failed
      </div>

      <div className='rex-modal-contents'>
        {
          error &&
          <div className='rex-modal-error'>
            { error }
          </div>
        }

        <div className='rex-modal-subheading'>
          <p className='centered'>Please assist the agent in updating the card they have on file and try again.</p>
        </div>

        <div className='rex-modal-actions rex-modal-actions__centered'>
          <button
            className='rex-modal-action'
            onClick={ () => toggleModal(false) }
          >
            CLOSE THIS WINDOW
          </button>
        </div>
      </div>
    </div>
  );
};

export default Fail;
