import react, { Component } from 'react';
import { connect } from 'react-redux';

class NewLeadTrigger extends Component {
  constructor(props) {
    super(props);

    this.showNewLeadModal = this.showNewLeadModal.bind(this);
  }

  showNewLeadModal() {
    const { dispatch } = this.props;

    dispatch({
      type: 'SHOW_NEW_LEAD_MODAL'
    });
  }

  render() {
    return (
      <button
        onClick={ this.showNewLeadModal }
      >
        Create New Lead with Existing Info
      </button>
    );
  }
}

export default connect()(NewLeadTrigger);
