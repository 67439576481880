import { Component, Children, cloneElement } from 'react';

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    this.setState({ value: event.target.value })
    this.props.onChange(event)
  }

  render() {
    const childrenWithProps = Children.map(this.props.children,
      (child) => cloneElement(child, { foo: 'bar' })
    );

    const defaultStyle = {
      color: 'black',
      width: 200
    }

    return (
      <select
        onChange={this.handleChange}
        value={this.state.value}
        style={Object.assign(defaultStyle, this.props.style)}
      >
        {childrenWithProps}
      </select>
    )
  }
}
