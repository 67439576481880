export const leadParams = state => {
  let { lead } = state;

  return {
    user_attributes: {
      id: lead.user_id,
      first_name: lead.first_name,
      last_name: lead.last_name,
      email: state.email
    },
    id: lead.id,
    lead_type: lead.lead_type,
    rex: lead.rex,
    buyer_city_0: lead.buyer_city_0,
    buyer_state_0: lead.buyer_state_0,
    buyer_city_1: lead.buyer_city_1,
    buyer_state_1: lead.buyer_state_1,
    buyer_city_2: lead.buyer_city_2,
    buyer_state_2: lead.buyer_state_2,
    buyer_city_3: lead.buyer_city_3,
    buyer_state_3: lead.buyer_state_3,
    street_number: lead.street_number,
    street_name: lead.street_name,
    unit_number: lead.unit_number,
    city: lead.city,
    state: lead.state,
    zip: lead.zip,
    phone_number: lead.phone_number,
    phone_number_base: lead.phone_number_base,
    extension: lead.extension,
    home_price_min: lead.home_price_min,
    home_price_max: lead.home_price_max,
    type_home: lead.type_home,
    beds: lead.beds,
    baths: lead.baths,
    opt_out: lead.opt_out,
    commercial: lead.commercial,
    mortgage_status: lead.mortgage_status,
    highend: state.highend,
    comments: state.comments,
    time_frame: lead.time_frame,
    language_preference: lead.language_preference,
  }
}

export const dialerFormParams = (type, state) => {
  let {
    requal_email,
    conditional_requal_email
  } = state;

  return {
    channel: 'Fresh',
    lead: leadParams(state),
    requal_email,
    conditional_requal_email,
    [`submitted_via_${type}`]: true,
    note: {
      status: '',
      message: state.qual_note
    },
  }
}

export const killSubmitParams = state => {
  let conditional_requal_email = state.conditional_requal_email === '1' ? '1' : null;
  let send_agent_invitation = state.send_agent_invitation === '1' ? '1' : null;

  return {
    channel: 'Fresh',
    lead: leadParams(state),
    note: {
      status: state.status_button.note_status,
      message: state.qual_note,
    },
    lead_id: state.lead.id,
    conditional_requal_email,
    send_agent_invitation,
  }
}

export const qualSubmitParams = state => {
  return {
    pre_match_research: state.pmr_form,
    dialerFormParams: dialerFormParams('pmr', state),
    lead_id: state.lead.id
  }
}

export const requalSubmitParams = state => {
 return {
    ...dialerFormParams('liv', state),
    ...{
      note: {
        status: state.status_button.note_status,
        message: state.qual_note,
      },
      requal_email: state.requal_email,
      qual_queue: true,
    },
    lead_id: state.lead.id,
  }
}
