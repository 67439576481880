import { Component } from 'react';
export default class UserDetails extends Component {
  constructor(props){
    super(props)
    this.state = {
      brands: []
    };
    this.displayAccountManagers = this.displayAccountManagers.bind(this);
    this.fetchBrands = this.fetchBrands.bind(this);
    this.updateSalesManager = this.updateSalesManager.bind(this);
    this.updateSalesManagerPhone = this.updateSalesManagerPhone.bind(this);
    this.parseClick2CallHref = this.parseClick2CallHref.bind(this);
  }

  componentDidMount() {
    this.fetchBrands();
  }

  fetchBrands() {
    $.get('/agent_insights/monitors/fetch_agent_monitors', { complete_list: true }, (res) => {
      if (res.monitors) this.setState({ brands: res.monitors.map(monitor => monitor.name) });
    });
  }

  updateSalesManager(field, e) {
    let { salesManager } = this.props.state;
    let { updateState } = this.props;

    salesManager[field] = e.currentTarget.value;
    updateState({ salesManager: salesManager });
  }

  updateSalesManagerPhone(e) {
    let { salesManager } = this.props.state;
    let { updateState } = this.props;
    let temp = e.currentTarget.value.replace(/\D+/g, '');

    salesManager['phone_mobile'] = temp !== '' ? temp : null;
    updateState({ salesManager: salesManager });
  }

  parseClick2CallHref(number) {
    return (
      '/click2call?call_destination=sales_manager&call_destination_id=' +
      this.props.state.salesManager.id +
      '&call_type=standard&phone=' + number
    )
  }

  displayAccountManagers() {
    let { salesManager, titleAccountManagers, showTitleAccountManagers } = this.props.state;
    let { updateRexManager } = this.props;
    if (showTitleAccountManagers && salesManager.is_super_manager) {
      return (
        <select
          className='select-input'
          value={salesManager.account_manager_id || ''}
          onChange={(e) => updateRexManager(e.currentTarget.value)}
        >
          <option value=''>--Rex Manager--</option>
          {Object.keys(titleAccountManagers).map((id, idx) => {
            return <option value={id} key={idx}>{ titleAccountManagers[id] }</option>
          })}
        </select>
      )
    } else {
      return titleAccountManagers[salesManager.account_manager_id] || 'None';
    }
  }

  render(){
    let { salesManager, updating, metrics } = this.props.state;
    let { contactSynced, officeSynced, updateRexManager } = this.props;

    let update = this.updateSalesManager;
    let submit = this.props.submitUpdatedSalesManager;

    return(
      <div className="user-details">
        <div className="profile-card">
          <h3>Sales Manager</h3>
          <img className='user-photo' src='/images/blank_photo-original.png' />
          <h3>{salesManager.first_name} {salesManager.last_name }</h3>
          <h3>ID: {salesManager.id}</h3>
          <h3>{this.displayAccountManagers()}</h3>
        </div>
        <div className="details w300 contact">
          <div className="header">
            <h3>Contact</h3>
            <input
              type="button"
              className={contactSynced ? 'hidden' : 'edit-button'}
              value={updating ? 'Updating...' : 'Update'}
              onClick={submit}
            />
          </div>
          <div className="content">
            <div className="field-row">
              <div className="field">Email</div>
              <input
                className='value'
                value={salesManager.email || ''}
                onChange={(e) => update('email', e)}
              />
            </div>
            <div className="field-row">
              <div className="field">Mobile</div>
              <input
                className='value'
                value={Util.formatPhoneNumber(salesManager.phone_mobile) || ''}
                onChange={this.updateSalesManagerPhone}
              />
              <div className='click-to-call-btn'>
                <a
                  data-remote="true"
                  rel="nofollow"
                  data-method="post"
                  className='click-to-call-phone-type'
                  href={this.parseClick2CallHref(salesManager.phone_mobile)}>
                  <i className="fa fa-phone-square"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="details w355 office">
          <div className="header">
            <h3>Office</h3>
            <input
              type="button"
              className={officeSynced ? 'hidden' : 'edit-button'}
              value={updating ? 'Updating...' : 'Update'}
              onClick={submit}
            />
          </div>
          <div className="content">
            <div className="field-row">
              <div className="field">Brand</div>
              <select
                className='dropdown-value'
                value={salesManager.brand || ''}
                onChange={(e) => update('brand', e)}
              >
                <option value=''></option>
                {this.state.brands.map((brand, idx) => {
                  return <option key={idx} value={brand}>{ brand }</option>
                })}
              </select>
              <div className="down-arrow">
                <i className="fa fa-angle-down"></i>
              </div>
            </div>
            <div className="field-row">
              <div className="field">Street</div>
              <input
                className='value'
                value={salesManager.address_street || ''}
                onChange={(e) => update('address_street', e)}
              />
            </div>
            <div className="field-row">
              <div className="field">City/State</div>
              <input
                className='value small'
                value={salesManager.address_city || ''}
                onChange={(e) => update('address_city', e)}
              />
              <select
                className='dropdown-value small'
                value={salesManager.address_state || ''}
                onChange={(e) => update('address_state', e)}
              >
                {FormHelper.stateOptions().map((state, idx) => {
                  return <option key={idx} value={state}>{state}</option>
                })}
              </select>
              <div className="down-arrow">
                <i className="fa fa-angle-down"></i>
              </div>
            </div>
            <div className="field-row">
              <div className="field">Zip</div>
              <input
                className='value'
                value={salesManager.address_zip || ''}
                onChange={(e) => update('address_zip', e)}
              />
            </div>
          </div>
        </div>
        <div className="details w300 metrics">
          <div className="header"><h3>Metrics</h3></div>
          <div className="content">
            <div className="field-row">
              <div className="field-long">Available slots</div>
              <div className="metric-value">{ metrics.slots }</div>
            </div>
            <div className="field-row">
              <div className="field-long">Agents Referred</div>
              <div className="metric-value">{ metrics.agents_monitors }</div>
            </div>
            <div className="field-row">
              <div className="field-long">Commits</div>
              <div className="metric-value">{ metrics.commits }</div>
            </div>
            <div className="field-row">
              <div className="field-long">Appointments</div>
              <div className="metric-value">{ metrics.appointments }</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
