import React from 'react';

const altosTableHeadersColumns = [
  { text: 'INCLUDED', width: 5 },
  { text: 'NAME', width: 10 },
  { text: 'CID', width: 10 },
  { text: 'PHONE', width: 10 },
  { text: 'EMAIL', width: 15 },
  { text: 'BROKERAGE', width: 10 },
  { text: 'CITIES', width: 10 },
  { text: 'ZIP CODES', width: 10 },
  { text: 'TRANSACTIONS', width: 10 },
  { text: 'URL', width: 10 },
]

const apAgentsTableHeadersColumns = [
  { text: 'NAME', width: 25 },
  { text: 'AgentID', width: 15 },
  { text: 'URL Slug', width: 10 },
  { text: 'BROKERAGE', width: 25 },
  { text: 'LOCATION', width: 25 },
]

const apAgentsTableHeadersSelectableColumns = [
  { text: 'INCLUDED', width: 5 },
  { text: 'NAME', width: 20 },
  { text: 'AgentID', width: 15 },
  { text: 'URL Slug', width: 10 },
  { text: 'BROKERAGE', width: 25 },
  { text: 'LOCATION', width: 25 },
]

const rexAgentsTableHeadersColumns = [
  { text: 'INCLUDED', width: 5 },
  { text: 'NAME', width: 10 },
  { text: 'PHONE', width: 10 },
  { text: 'EMAIL', width: 20 },
  { text: 'BROKERAGE', width: 10 },
  { text: 'AGENT ID', width: 10 },
  { text: 'STATUS', width: 10 },
  { text: 'DATE CREATED', width: 10 },
  { text: 'PRIMARY ACCT?', width: 15 },
]

const column = ({ text, width }, idx) => (
  <div key={idx} className={`table-column col-width-${width}`}>
    {text}
  </div>
);

const altosTableHeaders = () => (
  <div className="form-table-header">
    { altosTableHeadersColumns.map(column) }
  </div>
);

const apAgentsTableHeaders = () => (
  <div className="form-table-header">
    {apAgentsTableHeadersColumns.map(column)}
  </div>
);

const apAgentsTableHeadersSelectable = () => (
  <div className="form-table-header">
    {apAgentsTableHeadersSelectableColumns.map(column)}
  </div>
);

const rexAgentsTableHeaders = () => (
  <div className="form-table-header">
    {rexAgentsTableHeadersColumns.map(column)}
  </div>
);

export {
  altosTableHeaders,
  apAgentsTableHeadersSelectable,
  apAgentsTableHeaders,
  rexAgentsTableHeaders,
}
