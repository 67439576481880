import { classList } from '../utils';

const Select = (props) => {
  const {
    classNames,
    data,
    display,
    focused,
    index,
    name,
    valid,
    value,

    onChange,
    onFocus,
  } = props;

  const classes = classList({
    classNames,
    def: 'info-field',
    focused,
    valid
  });

  return (
    <div className={ classes }>
      <label>{ display }</label>
      <select
        id={ `select-info-input-${ name }-${ index || 0 }`}
        name={ name }
        onChange={ onChange }
        value={ value || '' }
        data-index={ index || 0 }
        onFocus={ onFocus }
      >
        { data && data.map((c, i) => {
          const value = c.hasOwnProperty('value') ? c.value : c;
          return (
            <option key={`name-${i}`} value={ value }>
              { value }
            </option>
          );
        }) }
      </select>
    </div>
  );
};

export default Select;
