import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import ImageCropper from './ImageCropper';

const EditAvatarModal = ({
  agentId,
  showModal,
  setShowModal,
  setPhotoUrl,
  setPhotoError,
  toggleModal,
  minPixels,
  src,
  loadFile,
}) => {
  const [newImage, setNewImage] = useState({
    image: null,
    imageUrl: null,
  });

  function postImage({ url, image, name }) {
    try {
      const form = document.querySelector(`#edit-agent-ajax-${agentId}`);
      const formData = new FormData(form);
      formData.set('agent[user_attributes][photo]', image, name);
      fetch(url, {
        method: 'POST',
        body: formData
      });

      return null;
    } catch (error) {
      console.warn(error);
      return { serverErrors: error };
    }
  }

  async function updateImage(e) {
    const button = e.target;
    button.disabled = true;

    try {
      setShowModal(false);
      setPhotoUrl(null);
      setPhotoError(false);

      postImage({
        url: `/agents/${agentId}/update_agent_profile_image`,
        image: newImage.image,
        name: newImage.image.name,
      });

      await setPhotoUrl(newImage.imageUrl);
      button.disabled = false;
      return null;
    } catch (imageError) {
      console.error(imageError);
      return imageError;
    }
  }

  const headerContent = (
    <>
      <h1>Edit Your Photo</h1>
      <div
        role="button"
        tabIndex={0}
        className="modal-close-button"
        onClick={toggleModal}
        onKeyPress={toggleModal}
      />
    </>
  );

  const footerContent = (
    <>
      <input
        type="button"
        value="Save Photo"
        className="default-update"
        onClick={updateImage}
      />
      <label htmlFor="change-photo" className="file-upload-button">
        Change Photo
        <input
          id="change-photo"
          type="file"
          onChange={loadFile}
        />
      </label>
    </>
  );

  return (
    <Modal
      id="edit-avatar-modal"
      status={showModal ? 'show' : 'hidden'}
      showHeader
      showFooter
      contentClass="crop-preview"
      headerContent={headerContent}
      footerContent={footerContent}
      close={toggleModal}
    >
      <ImageCropper
        src={src}
        minPixels={minPixels}
        setNewImage={setNewImage}
      >
        <div className="crop-preview-text">Drag to reposition photo</div>
      </ImageCropper>
    </Modal>
  );
};

EditAvatarModal.propTypes = {
  agentId: PropTypes.number.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  setPhotoUrl: PropTypes.func.isRequired,
  setPhotoError: PropTypes.func.isRequired,
  src: PropTypes.string,
  loadFile: PropTypes.func.isRequired,
};

EditAvatarModal.defaultProps = {
  src: null,
};

export default EditAvatarModal;
