import { Component }  from 'react';
import { default as Table } from './views/table/Wrapper';
import { default as Toggle } from './views/table/Toggle';

export default class Container extends Component {
  constructor(props) {
    super(props);

    this.state = {
      handleEvent: false,
      historyKeys: [],
      historyLogs: [],
      showHistoryTable: false,
    };

    this.setHistory = this.setHistory.bind(this);
    this.toggleHandleEvent = this.toggleHandleEvent.bind(this);
    this.toggleHistoryDisplay = this.toggleHistoryDisplay.bind(this);
  }

  componentDidMount() {
    if (this.props.show_sales_history) {
      this.setState({ showHistoryTable: true, handleEvent: true })
    }
    document.addEventListener('productSales.EventSaved', this.toggleHandleEvent);
  }

  toggleHandleEvent() {
    const {
      handleEvent
    } = this.state;

    this.setState({ handleEvent: !handleEvent });
  }

  setHistory(data) {
    this.setState({ historyLogs: data });

    if (data.length) this.setState({ historyKeys: Object.keys(data[0]) });
  }

  toggleHistoryDisplay() {
    const {
      showHistoryTable
    } = this.state;

    this.setState({ showHistoryTable: !showHistoryTable });
  }

  render() {
    const {
      action,
      method,
      params,
      sales_attempts,
    } = this.props;

    const {
      handleEvent,
      historyKeys,
      historyLogs,
      showHistoryTable,
    } = this.state;

    return(
      <div className='product-sales-history'>
        <div className='columns-wrapper'>
          <div className='column-1 width-100'>

            <Table
              historyKeys={ historyKeys }
              historyLogs={ historyLogs }
              showHistoryTable={ showHistoryTable }
            />

            <Toggle
              action={ action }
              method={ method }
              params={ params }
              salesAttempts={ sales_attempts }
              setHistory={ this.setHistory }
              showHistoryTable={ showHistoryTable }
              toggleHistoryDisplay={ this.toggleHistoryDisplay }
              handleEvent={ handleEvent }
              toggleHandleEvent={ this.toggleHandleEvent }
            />

          </div>
        </div>
      </div>
    );
  }
}
