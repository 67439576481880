import { Component } from 'react';

import * as DashboardUtils from '../../common/dashboard_utils';

export default class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      country: 'US'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    DashboardUtils.initSelectInputs();
  }

  handleChange(event) {
    const {
      value,
      dataset: { attrName }
    } = event.target;

    this.setState({ [attrName]: value });
  }

  handleSearch(event) {
    if (event.target.disabled) return;

    const { query, country } = this.state;
    const { setAttribute, toggleFetching, viewExcluded } = this.props;
    const queryString = Util.toQueryString({ query, country });

    setAttribute('searchAreas', []);
    viewExcluded(false);
    toggleFetching(true);

    fetch(
      `/product_dashboard/emergency_exclusion_areas/search?${queryString}`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' }
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        setAttribute('searchAreas', response.centroids);
        toggleFetching(false);
        this.setState({ query: '' });
      } else {
        Util.createFlash(response.errors.join(', '), 'danger');
      }
    })
    .catch(error => console.log(error));
  }

  render() {
    const { country, query } = this.state;
    const disabled = query.length ? '' : 'disabled';

    return (
      <div id='emergency-exclusion-areas-form' className='form-inline row'>
        <div className='form-group col-md-3'>
          <select id='country-selector' className='form-control input-lg rex-custom-select' data-attr-name='country' defaultValue={ country } onChange={ this.handleChange }>
            <option value='US'>United States</option>
            <option value='CA'>Canada</option>
          </select>
        </div>

        <div className='form-group col-md-9 input-group input-group-lg'>
          <input type='text' className='form-control input-lg' placeholder='City, state, or zip...' data-attr-name='query' value={ query } onChange={ this.handleChange } />
          <span className='input-group-btn'>
            <button className={ `btn btn-md btn-rex btn-orange search-button ${disabled}` } type='submit' disabled={ disabled } onClick={ this.handleSearch }>SEARCH</button>
          </span>
        </div>
      </div>
    );
  }
}
