import React from 'react';
import moment from 'moment';

const AttemptView = (props) => {
  const {
    attempt,
    attemptCount,
  } = props;

  const {
    created_at,
    reason,
  } = attempt;

  const formattedDate = moment(created_at).format('MM/DD/YYYY h:mm a');

  return (
    <div className='attempt-row'>
      <div className='attempt-column'>
        { `Attempt: ${ attemptCount }` }
      </div>
      <div className='attempt-column'>
        { formattedDate }
      </div>
      <div className='attempt-column'>
        { reason }
      </div>
    </div>
  );
}

export default AttemptView;
