import { Component } from 'react';

export default class Timer extends Component {
  constructor(props){
    super(props)
    this.state = {
      currentTime: this.obtainCurrentTime(),
      nextApptMin: '',
      nextAppt: ''
    }
  }

  componentDidMount() {
    if(!this.timeInterval) {
      this.timeInterval = setInterval(() => {
        this.updateTime()
      }, 1000);
    }
    if(!this.appointmentInterval) {
      this.appointmentInterval = setInterval(() => {
        this.updateApptTime()
      }, 1000);
    }
  }

  componentWillReceiveProps(nextProps){
    let { calendar } = nextProps;
    let nextAppointment;

    if(calendar){
      let days = Object.keys(calendar);
      for (let i = 0; i < days.length; i++) {
        if(calendar[days[i]].appointments.length > 0){
          if(parseInt(days[i]) > (Date.now()/1000)){
            nextAppointment = days[i];
            break;
          }
        }
      }
    }
    if(nextAppointment) this.setState({ nextAppt: nextAppointment});
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
    clearInterval(this.appointmentInterval);
  }

  updateTime() {
    this.setState({ currentTime: this.obtainCurrentTime() });
  }

  updateApptTime() {
    this.setState({ nextApptMin: this.nextAppointment() });
  }

  obtainCurrentTime() {
    var today = new Date();
    var h = (today.getHours() + 24) % 12 || 12;
    var m = today.getMinutes();
    var s = today.getSeconds();
    m = this.checkTime(m);
    s = this.checkTime(s);
    return h + ":" + m + ":" + s + ' ' + this.getAMPM();
  }

  checkTime(i) {
    if (i < 10) i = "0" + i;
    return i;
  }

  getAMPM() {
    var date = new Date();
    var hours = date.getHours();
    return hours >= 12 ? 'PM' : 'AM';
  }

  nextAppointment(){
    let { nextAppt } = this.state;
    if(nextAppt){
      let time = nextAppt - (Date.now()/1000)
      if(time < 0) return 'Now';
      // let h = Math.floor(time / 3600);
      // let s = this.checkTime(Math.floor(time % 60));
      return Math.floor(time/60) + ' min';
    }
  }
  nextApptClass(min){
    switch(true){
      case (min > 15):
        return 'cs-next-appt cold';
        break;
      case (min > 5):
        return 'cs-next-appt warm';
        break;
      case (min <= 5):
        return 'cs-next-appt hot';
        break;
      default:
        return 'cs-next-appt cold';
    }
  }

  render(){
    let { currentTime, nextApptMin } = this.state
    return(
      <div className='cs-timer'>
        <div>
          Current Time: { currentTime }
        </div>
        <div className='cs-next-appt'>
          Next Appointment:
          <span className={this.nextApptClass(nextApptMin)}>
            { nextApptMin ? nextApptMin : ''}
          </span>
        </div>
      </div>
    )
  }
}
