import React from 'react';

const firstName = name => {
  return !name ? undefined : name.split(' ')[0];
}

const introText = (agentName, opsName) => {
  return (
    `Hi ${agentName}!
    This is ${firstName(opsName)} with ReferralExchange, how are you doing today?
    I am reaching out to tell you about a few changes we're making to our business here at ReferralExchange. We recently launched ReferralExchange PRIME and I'm looking to get you more opportunities and better engagement with clients. I'd love to share more details with you about this opportunity--do you have time for a quick call this week so we can discuss further?`
  );
}

const introTextRta = (location = '', agentName, opsName) => {
  const [city, state, _country] = location.split(',').map(v => v && v.trim());

  return (
    `Hi ${agentName}?

    Hey, this is ${firstName(opsName)} with ReferralExchange. We’re generating numerous buyer and seller referrals monthly and are looking to strengthen our referral network in ${city}, ${state}. I want to help you expand your reach and get in front of more consumers in your market, generate additional client introductions for yourself (and your team if you have one), and secure some additional closings each year. Would you be open to having a 15-20 min conversation with me to see if a partnership with us would be a good fit?

    Use Transition below if agent has initial questions about ReferralExchange:

    We’ve been referring clients to agents in ${city} for the past 15 years; our network is small and invite-only, and right now we’re looking to find new and better agents to handle some of these referrals. I was digging into some market data to find the best agents to work with in ${city} and your name kept coming up as a top producer.

    Questions:
      * Are you a solo agent or have a team?
      * Have you worked with referral clients before?
      * If no, ask why not
      * If yes, proceed to scheduling`
  );
}

const introTextReaClaimed = (location = '', agentName, opsName) => {
  const [city, state, _country] = location.split(',').map(v => v && v.trim());

  return (
    `Hi ${agentName}?

    Hey, this is ${firstName(opsName)} with RealEstateAgents.com. We are so glad that you’ve claimed your RealEstateAgents.com profile as we’re generating numerous buyer and seller referrals monthly and are looking to strengthen our referral network in ${city}. I want to help you expand your reach and get in front of more consumers in your market, generate additional client introductions for yourself (and your team if you have one), and secure some additional closings each year. Would you be open to having a 15-20 min conversation with me to see how upgrading your RealEstateAgents.com profile would benefit you?

    Use Transition below if agent has initial questions about RealEstateAgents.com:

    We’ve been referring clients to agents in ${city} for over 15 years; and RealEstateAgents.com is fast becoming the marketplace for consumers to connect with the best agents around the county. We’re looking for the best agents to handle some of these referrals. I was digging into some market data to find the best agents to work with in ${city} and not only did your name keep coming up as a top producer, but I also saw that you claimed your profile.

    Questions:
      * Are you a solo agent or have a team?
      * Have you worked with referral clients before?
      * If no, ask why not
      * If yes, proceed to scheduling`
  );
}

const introScript = (agentInfo, opsName) => {
  const { name, location, prospect_type, rea_claimed } = agentInfo;

  if (/^Agent$/.test(prospect_type)) {
    return rea_claimed ? introTextReaClaimed(location, name, opsName) : introText(name, opsName);
  } else {
    return introTextRta(location, name, opsName);
  }
}

const Intro = ({ agentInfo, opsName }) => {
  return (
    <div className="dialer-agent-details-intro">
      <i className="comments-alt" /> <span>Introduction</span>
      <br />
      <br />
      { agentInfo.prospect_type && introScript(agentInfo, opsName).split('\n').map((text, idx) => <p key={ idx }>{ text }</p>) }
    </div>
  )
};

export default Intro;
