import  React     from  'react';
import  ReactDOM  from  'react-dom';
import  Container from './MainContainer';

/**
 * Initialize the Profile Review system.
 */
export function initialize(containerElement, options) {
  let profileReviewContainer = React.createElement(Container, options);
  ReactDOM.render(profileReviewContainer, containerElement);
}
