/* eslint-disable no-param-reassign */
function dragElement(elmnt, options = {}) {
  const { parentalConfinement } = options;

  const dragStartEvent = 'onmousedown';
  const dragMoveEvent = 'onmousemove';
  const dargEndEvent = 'onmouseup';

  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;

  // if present, the header is where the drag event gets set on;
  const headerElement = document.getElementById(`${elmnt.id}-header`);
  const moveElement = headerElement || elmnt;

  function closeDragElement() {
    document[dargEndEvent] = null;
    document[dragMoveEvent] = null;
  }

  function toPixels(n) {
    return `${n}px`;
  }

  function boundsData() {
    const bounds = elmnt.parentElement.getBoundingClientRect();
    const edge = elmnt.getBoundingClientRect();
    return {
      bounds,
      exceedsTop: bounds.top < edge.top,
      exceedsBottom: bounds.bottom > edge.bottom,
      exceedsLeft: bounds.left < edge.left,
      exceedsRight: bounds.right > edge.right,
    };
  }

  function moveElementY(delta) {
    if (parentalConfinement) {
      const { bounds, exceedsTop, exceedsBottom } = boundsData();

      if (exceedsTop) { return 0; }
      if (exceedsBottom) { return toPixels(bounds.height - elmnt.height + 2); }
    }

    return toPixels(elmnt.offsetTop - delta);
  }

  function moveElementX(delta) {
    if (parentalConfinement) {
      const { bounds, exceedsLeft, exceedsRight } = boundsData();

      if (exceedsLeft) { return 0; }
      if (exceedsRight) { return toPixels(bounds.width - elmnt.width + 2); }
    }

    return toPixels(elmnt.offsetLeft - delta);
  }

  function elementDrag(e) {
    e.stopPropagation();
    // calculate the new cursor position:

    const xCoord = e.clientX;
    const yCoord = e.clientY;

    pos1 = pos3 - xCoord;
    pos2 = pos4 - yCoord;
    pos3 = xCoord;
    pos4 = yCoord;

    elmnt.style.left = moveElementX(pos1);
    elmnt.style.top = moveElementY(pos2);
  }

  function dragMouseDown(e) {
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;

    document[dargEndEvent] = closeDragElement;
    document[dragMoveEvent] = elementDrag;
  }

  moveElement[dragStartEvent] = dragMouseDown;
}

export default dragElement;
