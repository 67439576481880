import React from 'react';

import FormLabel from '../FormLabel';
import ErrorNotice from '../ErrorNotice';
import { textareaField } from '../shared/PropTypes';
import { generateClassName, generateErrors } from '../shared/generate';

const TextareaField = (props) => {
  const {
    charLimit,
    disabled,
    error,
    handleChange,
    placeholder,
    id,
    klass,
    label,
    required,
    value,
  } = props;

  const charlimitError = charLimit && value.length > charLimit;
  const { errorMessage, errorBool } = generateErrors(error);

  const className = generateClassName(klass);
  const charLimitClass = ['char-limit'];
  if (charlimitError) { charLimitClass.push('error'); }

  const charLimitText = `${value.length}/${charLimit}`;

  return (
    <div className={className}>
      <FormLabel
        label={label}
        htmlFor={id}
        required={required}
      >
        <textarea
          id={id}
          name={id}
          error={errorBool}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={disabled}
        />
      </FormLabel>
      <ErrorNotice error={errorMessage}>
        {charLimit && (
          <span className={charLimitClass.join(' ')}>{charLimitText}</span>
        )}
      </ErrorNotice>
    </div>
  );
};

TextareaField.propTypes = textareaField.props;
TextareaField.defaultProps = textareaField.defaultProps;

export default TextareaField;
