import React from 'react';

const ExclusiveAgentQuestions = ({ setState, currentAgent, handleToggle, forceMatchExclusively, workingWithAgentIds}) => {
  function handleWorkingWithAgentIds(e) {
    const agentIds = e.target.checked ? [currentAgent.id] : [];

    setState({
      workingWithAgentIds: agentIds,
    });
  }

  return(
    <div>
      <div className='row'>
        <form>
          <div>
          <label className="exclusive-questions">
            <input
              type="checkbox"
              checked={workingWithAgentIds.length > 0}
              onChange={(e) => handleWorkingWithAgentIds(e)}
              className="exclusive-questions-checkbox"
            />
            The client is currently working with {currentAgent.name}
          </label>
          </div>
        </form>
      </div>
      <div className='row'>
        <form>
          <div>
          <label className="exclusive-questions">
            <input
              type="checkbox"
              checked={!forceMatchExclusively}
              onChange={() => handleToggle('forceMatchExclusively')}
              className="exclusive-questions-checkbox"
            />
            The client is interested in being contacted by other agents
          </label>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ExclusiveAgentQuestions
