import React from 'react';
import Email from './Email';

const Emails = ({ agentId, emails }) => {

  const newEmailLink = `/emails/new?object_id=${agentId}&object_type=Agent&skin=agent-fee-skin`;
  if(agentId){
    return (
      <section className='wc-emails'>
        <div id={`lead-emails-${agentId}`} className="react-emails-panel-inner">
          <div className='react-header'>
            <h3>Emails</h3>
            <div className='react-create-button'>
              <a href={newEmailLink} data-remote='true'>Create +</a>
            </div>
          </div>
          <div className='react-email-rows'>
            {
              emails.map((email, idx) => <Email key={idx} email={email} />)
            }
          </div>
        </div>
      </section>
    )
  } else {
    return <section className='wc-emails'/>
  }
}

export default Emails;
