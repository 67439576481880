import React from 'react';
import ReactDOM from 'react-dom';
import DialerRecruitTopAgentsResearch from './Container';

/**
 * Initialize the DialerRecruitTopAgentsResearch modal.
 */

export function initialize(containerElement, props) {
  let component = React.createElement(DialerRecruitTopAgentsResearch, props);
  ReactDOM.render(component, containerElement);
}
