import React, { useState, useEffect } from 'react';

const formatTime = time => {
  return moment().startOf('day').add(time || 0, 'seconds').format('HH:mm:ss').replace(/^(?:00:)?0?/, '');
};

const Stats = (props) => {
  const { statsDialerRecruitTopAgentsPath } = props;

  const [talkTime, setTalkTime] = useState('0:00');
  const [calls, setCalls] = useState(0);
  const [fetched, setFetched] = useState(false);
  const [agentsRecruited, setAgentsRecruited] = useState(0);

  function fetchAgentRecruitmentStats(event) {
    if (fetched) return;

    fetch(
      statsDialerRecruitTopAgentsPath,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      const { talk_time , calls, agents_recruited } = response;

      setTalkTime(formatTime(talk_time));
      setCalls(calls);
      setAgentsRecruited(agents_recruited);
      setFetched(true);
    });
  }

  useEffect(() => {
    const callStatsDisplay = document.getElementById('rex-call-stats');

    callStatsDisplay.addEventListener('agent_recruitment.fetch_stats', fetchAgentRecruitmentStats);
  });

  return (
    <div className="col-md-7 text-right" id="rex-call-stats">
      <strong>Today's Stats:</strong>
      <div className="rex-stat ml-30">
        <span className="label-type mr-15">Talk Time</span>
        <span className="rex-stat-pill">
          <strong>{ talkTime }</strong>
        </span>
      </div>
      <div className="rex-stat ml-30">
        <span className="label-type mr-15">Calls</span>
        <span className="rex-stat-pill">
          <strong>{ calls }</strong>
        </span>
      </div>
      <div className="rex-stat ml-30">
        <span className="label-type mr-15">Agents Recruited</span>
        <span className="rex-stat-pill">
          <strong>{ agentsRecruited }</strong>
        </span>
      </div>
    </div>
  )
};

export default Stats;
