import { Component } from 'react';
import CallStatus from '../../LiveCallControlsV2/CallStatus';
import './styles.scss';

export default class LiveControlsAgent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { agent, activeAgentID, callStatus, conferenceCallStartTime, agentCallStartTime, leadCallStartTime } = this.props;
    const disabled = activeAgentID && agent.id !== activeAgentID;
    const adjustedCallStatus = disabled || !activeAgentID ? 'init' : callStatus

    return (
      <div className="profile-review-live-agent-button">
        <CallStatus
          disabled={disabled}
          callStatus={adjustedCallStatus}
          conferenceCallStartTime={conferenceCallStartTime}
          agentCallStartTime={agentCallStartTime}
          leadCallStartTime={leadCallStartTime}
          initiate={() => this.props.initiate(agent.id)}
          buttonText={`Initiate Transfer to Agent ${agent.first_name} ${agent.last_name}`}
        />
      </div>
    );
  }
}
