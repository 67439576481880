import React    from 'react';
import ReactDOM from 'react-dom';
import {
  applyMiddleware,
  createStore,
  combineReducers
} from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import UI from './UI';
import sagas from './sagas';
import reducers from './reducers';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  reducers,
  applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(sagas);

/**
 * createQualQueueV2 creates a new wrapper component for the QualQueueV2.
 * @param {string} segment - Lead segment to pull from.
 */
export function createQualQueueV2(segment, conferenceType) {
  return ({ task, session, rep, hooks }) => {
    return <Provider store={ store }>
      <UI
        segment={ segment }
        leadID={ task.id }
        customAttributes={ task.custom_attributes }
        sessionUUID={ session.uuid }
        repID={ rep.id }
        hooks={ hooks }
        conferenceType={ conferenceType }
      />
    </Provider>;
  };
}
