import { Component } from 'react';
import ExclusiveLogo from '../../../../../images/lead_icons/exclusive.png';
import DirectLogo from '../../../../../images/lead_icons/direct_referral.png';

export default class SellerForm extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      lead: {}
    };

    this.handleLeadChange = this.handleLeadChange.bind(this);
    this.highlightIntro = this.highlightIntro.bind(this);
  }

  componentDidMount(){
    this.setLead();
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.data && nextProps.data.lead.id !== this.state.lead.id) this.setLead(nextProps);
  }

  setLead(props = this.props){
    if(props.data) this.setState({ lead: Object.assign({}, props.data.lead) })
  }

  handleLeadChange( e ) {
    let val = e.target.value;
    const name = e.target.name;

    if (name == 'home_price_max') {
      val = val.replace(/\D/g, '')
    } else if (e.target.type == 'tel') {
      let prefix = val.startsWith('+') ? '+' : ''
      val = prefix + val.replace(/[^\d\(\) -]/g, '').replace(/\s+/g, ' ').replace(/\(+/g, '(').replace(/\)+/g, ')').replace(/-+/g, '-')
    } else if (e.target.type == 'digits') {
      val = val.replace(/\D/g, ' ')
    }

    this.props.data.lead[ name ] = val;
    this.props.updateState( this.props.data );
  }

  handleChange( e ) {
    if(e.target.name === 'qual_note') {
      this.props.data.pmr_form.matcher_notes = e.target.value;
    }
    this.props.data[ e.target.name ] = e.target.value;
    this.props.updateState( this.props.data );
  }

  handleCommentsChange( e ) {
    this.props.data[ e.target.name ] = e.target.value;
    this.props.data.pmr_form[ e.target.name ] = e.target.value;
    this.props.updateState( this.props.data );
  }

  handleCheckChange( e ) {
    this.props.data.lead[ e.target.name ] = e.target.checked;
    this.props.updateState( this.props.data );
  }

  twoFAInfo() {
    return(
      <div className='twofa-box'>
        <div className='twofa-text'>2FA</div>
        <div className='twofa-logo'></div>
      </div>
    )
  }

  /**
   * Renders sending agent content if present
   * @return {Object} Html to be rendered
   */
  _renderSendingAgentContent() {
    const sendingAgent = this.props.data.sending_agent;

    if (Object.keys(sendingAgent).length) {
      return(
        <div className={ this.props.data.rex_referral ? 'content_header' : 'hidden' }>
          <i className='fa fa-paper-plane'></i>
          Sending Agent Info:
          <span>
            <div className='sending-agent-info'>
              <a href={ `/agents/${sendingAgent.id}` } target='_blank'>{ sendingAgent.name } - { sendingAgent.company }</a> <br />
              <a className='sending-agent-location' href={ `https://www.google.com/maps/place/${sendingAgent.city.replace(/\s/g, '+')}+${sendingAgent.state}/@${sendingAgent.lat},${sendingAgent.lng},14z` } target='_blank'>{ sendingAgent.city }, { sendingAgent.state }</a>
            </div>
          </span>
        </div>
      );
    } else {
      return(<div></div>);
    }
  }

  _renderBuyerLeadInfo() {
    const buyer = this.props.data.dual_transaction_buyer_lead;

    if (buyer) {
      return(
        <div>
          <div className='clear'></div>
          <div>
            <div className='field'>
              <div className='label'>Buyer Cities</div>
              <div className='dual-transaction-buyer-info'>
                { buyer.buyer_cities.split('&')[0].replace(',', ', ') }
              </div>
            </div>
            <div className='field'>
              <div className='label'>Mortgage Status</div>
              <div className='dual-transaction-buyer-info'>
                { buyer.mortgage_status ? buyer.mortgage_status : '--' }
              </div>
            </div>
          </div>
          <div className='clear'></div>
          <div>
            <div className='field'>
              <div className='label'>Min Beds</div>
              <div className='dual-transaction-buyer-info'>
                { buyer.beds ? buyer.beds : '--' }
              </div>
            </div>
            <div className='field'>
              <div className='label'>Min Baths</div>
              <div className='dual-transaction-buyer-info'>
                { buyer.baths ? buyer.baths : '--' }
              </div>
            </div>
          </div>
          <div className='clear'></div>
        </div>
      );
    }
    return;
  }

  exclusiveOrDirectIcon() {
    let icon = ExclusiveLogo;
    let referralType = 'exclusive';
    let text = 'directed with this agent only'

    if (this.props.directReferral) {
      icon = DirectLogo;
      referralType = 'direct';
      text = 'to this agent plus other agent options';
    }

    if ((this.props.exclusive || this.props.directReferral) && this.props.exclusiveAgentId){
      return (
        <div
          className='exclusive-logo-qq'
          >
          <img className='exclusive-logo-img-qq' src={ icon } />
          <div className='exclusive-text-qq'>
            <p>This { referralType } referral resulted from a consumer visiting Agent <a href={ this.props.exclusiveAgentLink } target='_blank'>{ this.props.exclusiveAgentId }</a> REA.com page and asking to be connected { text }</p>
          </div>
        </div>
      )
    }
  }

  buildLabel(text, defaultData) {
    const domEls = [text];

    if (defaultData) domEls.push(<span className="default-stand-in">DEFAULT</span>);

    return domEls;
  }

  displayAddressDefault() {
    const {
      lead,
      stand_in_data,
    } = this.props.data;

    const keys = ['street_number', 'street_name', 'city', 'state', 'zip'];
    const leadAddress = keys.map(k => lead[k]).toString();
    const standInAddress = keys.map(k => stand_in_data[k]).toString();

    return (leadAddress == standInAddress);
  }

  displayBuyerCitiesDefault(lead, buyer_cities) {
    const keys = Object.keys(lead).filter(k => /^buyer_(city|state)/.test(k));

    return (buyer_cities && (keys.map(k => lead[k]).toString() === buyer_cities.replace(/\s+/, '')));
  }

  displayMinPriceDefault(lead, min_price) {
    return (parseInt(`${lead.home_price_min}`.replace(/\W/g, '')) === min_price);
  }

  displayMaxPriceDefault(lead, max_price) {
    return (parseInt(`${lead.home_price_max}`.replace(/\W/g, '')) === max_price);
  }

  displayPriceDefault(lead, min_price, max_price) {
    return this.displayMinPriceDefault(lead, min_price) && this.displayMaxPriceDefault(lead, max_price);
  }

  render() {
    const {
      blacklisted,
      comments,
      dual_transaction,
      dual_transaction_buyer_lead,
      email,
      lead,
      pmr_form,
      qual_note,
      rex_referral,
      sending_agent,
      validations,
      stand_in_data: {
        street_number,
        street_name,
        city,
        state,
        zip,
        country,
        lead_price,
        min_price,
        max_price,
      },
    } = this.props.data;

    return(
      <div>
        <div className='content_header'>
          <i className='fa fa-user'></i>
          { dual_transaction ? 'Seller + Buyer' : 'Seller' } Lead Info:
          <span> { lead.first_name + ' ' + lead.last_name }</span>
          &nbsp;&nbsp;
          <span className='country-header-outer'>
            <span className={lead.country === 'CA' ? 'country-header tg ca' : 'country-header tg us'} />
          </span>
          { this.exclusiveOrDirectIcon() }
          <a href={ `${window.location.protocol}//${window.location.hostname}/leads/${lead.id}` } target='_blank' className='lead_id_link'>{ lead.id }</a>
        </div>

        { this._renderSendingAgentContent() }

        <div className='content_form'>
          <div className='field odd'>
            <div className='label'>
              First Name
            </div>
            <input
              type='text'
              name='first_name'
              value={ lead.first_name }
              onChange={this.handleLeadChange}
              onFocus={this.highlightIntro}
              />
          </div>
          <div className='field'>
            <div className='label'>
              Last Name
            </div>
            <input
              type='text'
              name='last_name'
              value={ lead.last_name }
              onChange={this.handleLeadChange}
              onFocus={this.highlightIntro}
              />
          </div>

          <div className='field odd phone_fields'>
            <div className='phone_field'>
              <div className={ lead.twofa_at ? 'label twofa' : 'label' }>
                Phone
                <span className={validations.phone_number_valid ? 'invalid-phone hidden' : 'invalid-phone'}>{validations.blacklisted_reasoning || 'INVALID PHONE'}</span>
                { lead.twofa_at ? this.twoFAInfo() : null }
              </div>
              <input
                type='tel'
                name='phone_number_national'
                className={ blacklisted ? 'blacklisted' : '' }
                className='phone_number'
                value={ lead.phone_number_national }
                onChange={this.handleLeadChange}
                onFocus={this.highlightIntro}
                id='intl_phone_target'
                maxLength={20}
              />
            </div>
            <div className='click-to-call-button'>
              {this.props.click2CallLink(lead.phone_number_base)}
            </div>
            <div className='extension_field'>
              <div className='label'>
                Extension
              </div>
              <input
                type='digits'
                name='extension' value={ lead.extension }
                className='extension'
                onChange={this.handleLeadChange}
                onFocus={this.highlightIntro}
                maxLength={10}
                />
            </div>
          </div>
          <div className='clear'></div>

          {
            this.displayAddressDefault() &&
            <div>
              <div className='field'>
                <div className='label'>
                  { this.buildLabel(null, this.displayAddressDefault()) }
                </div>
              </div>
              <div className='clear'></div>
            </div>
          }

          <div className='field w157 odd'>
            <div className='label'>
              Street Number
            </div>
            <input
              type='text'
              name='street_number'
              className={ this.displayAddressDefault() ? 'has-stand-in-data' : '' }
              value={ lead.street_number }
              onChange={this.handleLeadChange}
              onFocus={this.highlightIntro}
              />
          </div>
          <div className='field w319 odd'>
            <div className='label'>
              Street Name
            </div>
            <input
              type='text'
              name='street_name'
              className={ this.displayAddressDefault() ? 'has-stand-in-data' : '' }
              value={ lead.street_name }
              onChange={this.handleLeadChange}
              onFocus={this.highlightIntro}
              maxLength={ 35 }
              />
          </div>
          <div className='field w85'>
            <div className='label'>
              Unit Number
            </div>
            <input
              type='text'
              data-optional='true'
              name='unit_number'
              value={ lead.unit_number }
              onChange={this.handleLeadChange}
              onFocus={this.highlightIntro}
              />
          </div>
          <img
            src={window.image_path('google-icon')}
            className='icon'
            onClick={ this._googleSearch.bind( this ) }
          />
          <div className='clear'></div>
          <div className='field w330 odd'>
            <div className='label'>
              City
              <span className={validations.invalid_location ? 'invalid-location' : 'invalid-location hidden'}>INVALID LOCATION</span>
            </div>
            <input
              type='text'
              name='city'
              className={ this.displayAddressDefault() ? 'has-stand-in-data' : '' }
              value={ lead.city }
              onChange={this.handleLeadChange}
              onFocus={this.highlightIntro}
              />
          </div>
          <div className='field w138 odd'>
            <div className='label'>
              { lead.country === 'CA' ? 'Province' : 'State' }
            </div>
            <input
              type='text'
              name='state'
              className={ this.displayAddressDefault() ? 'has-stand-in-data' : '' }
              value={ lead.state }
              onChange={this.handleLeadChange}
              onFocus={this.highlightIntro}
              />
          </div>
          <div className='field w143'>
            <div className='label'>
              {lead.country === 'CA' ? 'Postal Code' : 'Zip'}
              <span className={validations.invalid_zip ? 'invalid-zip' : 'invalid-zip hidden' }>INVALID ZIP</span>
            </div>
            <input
              type='text'
              name='zip'
              className={ this.displayAddressDefault() ? 'has-stand-in-data' : '' }
              value={ lead.zip }
              onChange={this.handleLeadChange}
              onFocus={this.highlightIntro}
              />
          </div>
          <div className='clear'></div>


          {
            this.displayPriceDefault(lead, min_price, max_price) &&
            <div>
              <div className='field'>
                <div className='label'>
                  { this.buildLabel(null, this.displayPriceDefault(lead, min_price, max_price)) }
                </div>
              </div>
              <div className='clear'></div>
            </div>
          }

          <div className='field odd hp price-range-field'>
            <div className='label'>
              Price Range
              <span className={ lead.home_price_max >= 1000000 ? 'highend' : 'highend hidden' }>HIGH END</span>
            </div>
            <div className='clear'></div>
            <input
              type='text'
              name='home_price_min'
              className={ this.displayPriceDefault(lead, min_price, max_price) ? 'price-range price-range-left has-stand-in-data' : 'price-range price-range-left' }
              data-optional='true'
              value={ numeral(lead.home_price_min).format('0,0') }
              onChange={this.handleLeadChange}
              onFocus={this.highlightIntro}
              placeholder='Min'
              />
            <input
              type='text'
              name='home_price_max'
              className={ this.displayPriceDefault(lead, min_price, max_price) ? 'price-range has-stand-in-data' : 'price-range' }
              data-optional='true'
              value={ numeral(lead.home_price_max).format('0,0') }
              onChange={this.handleLeadChange}
              onFocus={this.highlightIntro}
              placeholder='Max'
              />
          </div>
          <div className='field selectdd'>
            <div className='label'>
              Time Frame
          </div>
            <select
              data-optional='true'
              name='time_frame'
              value={lead.time_frame || ''}
              onChange={this.handleLeadChange}
              onFocus={this.highlightIntro}
            >
              <option value=''>--</option>
              <option value='1 - 2 weeks'>1 - 2 weeks</option>
              <option value='2 - 3 weeks'>2 - 3 weeks</option>
              <option value='3 - 4 weeks'>3 - 4 weeks</option>
              <option value='1 - 2 months'>1 - 2 months</option>
              <option value='2 - 3 months'>2 - 3 months</option>
              <option value='3 - 6 months'>3 - 6 months</option>
              <option value='6 - 9 months'>6 - 9 months</option>
              <option value='More than 9 months'>More than 9 months</option>
            </select>
          </div>
          <div className='field odd selectdd'>
            <div className='label'>
              Property Type
            </div>
            <select name='type_home' value={ lead.type_home } onChange={this.handleLeadChange} onFocus={ this.highlightQuestions.bind( this ) }>
              <option>Choose One</option>
              <option value='Single Family'>Single Family</option>
              <option value='Condominium'>Condominium</option>
              <option value='Town Home'>Town Home</option>
              <option value='Mobile Home'>Mobile Home with land</option>
              <option value='2-4 Units'>2-4 Units</option>
              <option value='Vacation Home'>Vacation Home</option>
              <option value='Rental'>Rental</option>
              <option value='Co-op'>Co-op</option>
              <option value='Loft'>Loft</option>
              <option value='Land/Lot'>Land/Lot</option>
              <option value='Unknown'>Unknown</option>
            </select>
          </div>

          { this._renderBuyerLeadInfo() }

          <div className='clear'></div>
          <div className='field'>
            <div className={ lead.twofa_email_at ? 'label twofa' : 'label' }>
              Confirm Email *
              { lead.twofa_email_at ? this.twoFAInfo() : null }
            </div>
            <input
              type='email'
              name='email'
              value={ email }
              onChange={ this.handleChange.bind( this ) }
              onFocus={ this.highlightQuestions.bind( this ) }
              />
          </div>
          <div className='field checkboxes'>
            <input checked={lead.opt_out} type="checkbox" data-optional='true' name='opt_out' id='opt_out' onChange={ this.handleCheckChange.bind( this ) } />
            <label htmlFor="opt_out">
              Opt Out
            </label>
          </div>
          <div className='field checkboxes'>
            <input checked={lead.commercial} type="checkbox" data-optional='true' id="commercial" name='commercial' onChange={ this.handleCheckChange.bind( this ) } />
            <label htmlFor="commercial">
              Commercial
            </label>
          </div>
          <div className='clear'></div>
          <div className='field selectdd'>
            <div className='label'>
              What is the client's preferred language?
            </div>
            <select
              name='language_preference' value={lead.language_preference || '' }
              onChange={ this.handleLeadChange.bind(this) }
              onFocus={ this.highlightQuestions.bind(this) }
            >
              <option value='en'>English</option>
              <option value='es'>Spanish</option>
              <option value='fr'>French</option>
            </select>
          </div>
          <div className='clear'></div>
          <div className='field full'>
            <div className='label'>
              Qual Note
            </div>
            <textarea data-optional='true' name='qual_note' onChange={ this.handleChange.bind(this) } value={ qual_note || '' } onFocus={ this.highlightQuestions.bind( this ) }></textarea>
          </div>
          <div className='clear'></div>
            <div className='field full'>
              <div className='label'>
                Client Comments (will be viewed as a custom message to agents)
              </div>
              <textarea data-optional='true' name='comments' onChange={ this.handleCommentsChange.bind(this) } value={ comments || '' } onFocus={ this.highlightClosing.bind( this ) }></textarea>
            </div>
        </div>
      </div>
    )
  }

  _googleSearch( e ) {
    var data = this.props.data.lead,
        street_number = data.street_number || '',
        street_name = data.street_name || '',
        city = data.city || '',
        state = data.state || '',
        zip = data.zip || '',
        unit_number = data.unit_number || ''

    window.open(`https://www.google.com/search?q=${street_number}%20${street_name}%20${unit_number.replace(/#/, '')}%20${city}%20${state}%20${zip}`)
  }

  _renderOption( option, i ) {
    return(
      <option key={ i } value={ option }>{ option }</option>
    )
  }

  highlightIntro() {
    EventBus.emit( 'highlight:intro' );
  }

  highlightQuestions() {
    EventBus.emit( 'highlight:questions' );
  }

  highlightClosing() {
    EventBus.emit( 'highlight:closing' );
  }
}
