import React from 'react';
import moment from 'moment';

import { createSectionRow } from '../utils';
import NewLeadTrigger from '../NewLead/trigger';

import './styles.scss';

const verificiationsLinks = (vers) => {
  return vers.map((v, i) => {
    const { link, source } = v;
    return (
      <a href={ link } key={`verification-${ i }`}>
        { source }
        { (i !== vers.length - 1) && ", "}
      </a>
    );
  });
};

const duplicatesRow = (dupe, i) => {
  const {
    age,
    id,
    lead_type,
    origin,
    status,
  } = dupe;

  const type = lead_type ? lead_type[0].toUpperCase() : '-';

  return (
    <div key={ `duplicate-${ i }`} className={ `duplicate-row lead-status-${ status }` }>
      <div className='duplicate-id'>
        <a href={ `/l/${ id }` } target='_blank' rel='noopener noreferrer'>{ id }</a>
      </div>
      <div className='duplicate-lead-type'>{ type } ({ age }d)</div>
      <div className='duplicate-status'>{ status }</div>
      <div className='duplicate-origin' title={ origin }>{ origin }</div>
    </div>
  );
}

const Metadata = (props) => {
  const { data } = props;

  if (!data) {
    return null;
  }

  const {
    duplicates,
    id,
    phoneToName,
    submission,
    subscriberName,
    verifications,
  } = data;

  const determineIntent = (intent) => {
    if (intent === null) {
      return null;
    } else if (!intent) {
      return 'unconfirmed'
    } else {
      return 'confirmed'
    }
  };

  const intent = determineIntent(data.intent);

  const duplicatesLabel = (dupes) => {
    let label = 'Duplicates';
    if (duplicates && duplicates.length) {
      label += ` (${ duplicates.length })`;
    }
    return label;
  };

  const submissionDate = !isNaN(submission) ? '-' : moment(submission).format('MMM. D, YYYY h:mm a');

  return (
    <div className='active-dialer-lead-section metadata'>
      {
        createSectionRow({
          classNames: 'lead-id',
          key: 'Lead ID',
          section: 'metadata',
          value: <a href={ `/l/${ id }` } target='_blank' rel='noopener noreferrer'>{ id }</a>
        })
      }
      {
        createSectionRow({
          classNames: 'submission-date',
          key: 'Submitted',
          section: 'metadata',
          value: submissionDate
        })
      }
      {
        createSectionRow({
          classNames: `${ intent }`,
          key: 'Intent',
          section: 'metadata',
          value: intent
        })
      }
      {
        createSectionRow({
          classNames: `${ phoneToName ? phoneToName.replace(' ', '-').toLowerCase() : null }`,
          key: 'Phone to Name',
          section: 'metadata',
          value: phoneToName
        })
      }


      {
        createSectionRow({
          classNames: 'verifications',
          key: 'Verifications',
          section: 'metadata',
          value: (verifications && verifications.length) ? verificiationsLinks(verifications) : ''
        })
      }

      {
        createSectionRow({
          classNames: 'duplicates',
          key: duplicatesLabel(duplicates),
          section: 'metadata',
          value: (duplicates && duplicates.length) ? duplicates.map((dupe, i) => duplicatesRow(dupe, i)) : ''
        })
      }
      <div className='metadata-actions'>
        {
          // <NewLeadTrigger />
        }
      </div>
    </div>
  );
};

export default Metadata;
