import { Component } from 'react';

const Table = ({ children }) => {
  const tableStyle = {
    display: 'table',
    width: 'auto',
    backgroundColor: '#f1f1f1',
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '3px'
  }

  return (
    <div style={tableStyle}>
      {children}
    </div>
  )
}

export default Table
