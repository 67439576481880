import EmailHistory from './agents/views/EmailHistory';
import NoteHistory from './agents/views/NoteHistory';

const Agent = (props) => {
  const { agent, lead } = props;
  const recruitEmails = agent.recruitment_emails || [];
  const recruitNotes = agent.recruitment_notes || [];
  const emailPath = `/emails/new?email[to_email]=${agent.email || ''}&object_id=${lead.id}&object_type=Lead&recruit_top_agents=true`;

  function showAddContactModal(event) {
    event.preventDefault();

    const modal = document.getElementById('add-rta-contact-container');
    const modalEvent = new CustomEvent('add_rta.contact', { detail: { rta_agent_id: agent.rta_agent_id, source: 'rta_dialer' } });

    modal.dispatchEvent(modalEvent);
  }

  return (
    <div className="floating-pane agent-pane mb-30">
      <div className="row">
        <div className="col-md-8 name-type">
          { agent.name } <span className="pill-type bright-pill circle-pill"><i className="fa fa-headphones"></i></span>
        </div>
        <div className="col-md-4 text-right">
          <span className="rex-link my-5 mr-15">
            <a href="#" onClick={ showAddContactModal }>+ Add Contact</a>
          </span>
          <span className="pill-type bright-pill">{ agent.type }</span>
        </div>
      </div>
      <div className="mb-25">
        <div className="row">
          <div className="col-md-12">
            <div className="info-type">
              <i className="fa fa-phone mr-5"></i> { agent.phone || 'No phone number' }
            </div>
            <div className="info-type">
              <i className="fa fa-map-marker mr-5"></i> { agent.location }
            </div>
          </div>
        </div>
      </div>
      <div className="mb-25">
        <div className="row">
          <div className="col-md-12 user-details">
            <div className="user-detail-item mr-15">
              <span className="label-type">Company</span>
              <div className="item-detail">{ agent.brokerage }</div>
            </div>
            <div className="user-detail-item">
              <span className="label-type">Recruitment Attempts</span>
              <div className="item-detail">{ agent.recruitment_attempts }</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 section-header">
          <i className="fa fa-sticky-note"></i> <strong>Recruitment Notes ({ recruitNotes.length })</strong>
        </div>
        <div className="col-md-12 mb-30">
          { agent.recruitment_notes && <NoteHistory notes={ recruitNotes } /> }
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 section-header">
          <i className="fa fa-envelope"></i> <strong>Recruitment Emails ({ recruitEmails.length })</strong>
          <span className="rex-link ml-5">
            <a href={ emailPath } data-remote="true">Send Email</a>
          </span>
        </div>
        <div className="col-md-12">
          { agent.recruitment_emails && <EmailHistory emails={ recruitEmails } /> }
        </div>
      </div>
    </div>
  )
};

export default Agent;
