import { Component } from 'react';
import { AsYouType } from 'libphonenumber-js';

export default class Details extends Component {
  constructor(props){
    super(props);

    this.state = {
      showRolesDropdown: false
    };

    this.updateUser = this.updateUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateTimeZone = this.updateTimeZone.bind(this);
    this.replaceImage = this.replaceImage.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.updateResponseField = this.updateResponseField.bind(this);
    this.areDetailsSynced = this.areDetailsSynced.bind(this);
    this.parseClick2CallHref = this.parseClick2CallHref.bind(this);
    this.toggleRolesDropdown = this.toggleRolesDropdown.bind(this);
    this.updateCheckbox = this.updateCheckbox.bind(this);
  }
  componentDidMount(){
    this.syncPropsAndState();
  }

  componentWillReceiveProps(nextProps){
    this.syncPropsAndState(nextProps);
  }

  syncPropsAndState(props = this.props){
    let { agentId, photo, timeZone, user, questionResponses, company } = props;

    this.setState({
      agentId,
      photo,
      company: this.dup(company),
      user: this.dup(user),
      timeZone: this.dup(timeZone),
      questionResponses: this.dup(questionResponses),
    });
  }

  replaceImage(){
    this.setState({ photo: '/images/blank_photo-original.png'})
  }

  dup(target){
    return Object.assign({}, target);
  }

  isSynced(val1, val2){
    return (val1 == val2 || (val1 === '' && val2 === null) || (val1 === null && val2 === '')) ? true : false
  }

  toggleRolesDropdown(){
    this.setState({ showRolesDropdown: !this.state.showRolesDropdown })
  }

  areDetailsSynced(){
    let { user, timeZone, questionResponses, company } = this.state;
    let userP = this.props.user;
    let companyP = this.props.company;
    let timeZoneP = this.props.timeZone;
    let questionResponsesP = this.props.questionResponses;

    let userSynced = this.isSynced(JSON.stringify(user), JSON.stringify(userP))
    let timeZoneSynced = this.isSynced(JSON.stringify(timeZone), JSON.stringify(timeZoneP))
    let questionResponsesSynced = this.isSynced(JSON.stringify(questionResponses), JSON.stringify(questionResponsesP))
    let companySynced = this.isSynced(JSON.stringify(company), JSON.stringify(companyP))
    return (userSynced && timeZoneSynced && questionResponsesSynced && companySynced) ? true : false
  }

  companyFields(header, field, content){
    let synced = this.isSynced(this.state.company[field], this.props.company[field]);
    return(
      <div className='wc-details-content-field'>
        <label>{header}</label>
        <input
          className={synced ? 'input-field' : 'input-field unsynced'}
          value={content || ''}
          onChange={(e) => this.updateCompany(field, e)}
        />
      </div>
    )
  }

  userPhoneField(header, field, content, extraHtmlClass){
    const oldValue = new AsYouType('US').input(this.props.user[field]);
    const newValue = new AsYouType('US').input(this.state.user[field]);
    let synced = this.isSynced(oldValue, newValue);
    let callIconClass = () => {
      if(!content){
        return 'hidden';
      } else if(!synced){
        return 'hidden';
      } else {
        return 'wc-click-to-call-btn';
      }
    }

    // Only format phone if more that 3 digits. This allows for deletion of area code without issue
    let formattedPhone = content || '';
    if (formattedPhone.replace(/\D/g, '').length > 3) {
      const parts = content.split('ext')
      const base = parts[0]
      const ext = !!parts[1] ? ` ext ${parts[1]}` : ''
      formattedPhone = new AsYouType('US').input(base) + ext;
    }

    return(
      <div className={`wc-details-content-field ${extraHtmlClass || ''}`}>
        <label>{header}</label>
        <input
          className={synced ? 'input-field' : 'input-field unsynced'}
          value={formattedPhone}
          onChange={(e) => this.updateUserPhone(field, e)}
        />
        <div className={callIconClass()}>
          <a
            data-remote="true"
            rel="nofollow"
            data-method="post"
            className='click-to-call-phone-type'
              href={this.parseClick2CallHref(content)}>
            <i className="fa fa-phone-square"></i>
          </a>
        </div>
      </div>
    )
  }

  parseClick2CallHref(number) {
    return (
      '/click2call?call_destination=agent&call_destination_id=' + this.props.agentId +
      '&call_type=appointment_set&phone=' + number
    )
  }

  userDetailsField(header, field, content, extraHtmlClass){
    let synced = this.isSynced(this.state.user[field], this.props.user[field]);
    return(
      <div className={`wc-details-content-field ${extraHtmlClass || ''}`}>
        <label>{header}</label>
        <input
          className={synced ? 'input-field' : 'input-field unsynced'}
          value={content || ''}
          onChange={(e) => this.updateUser(field, e)}
        />
      </div>
    )
  }

  timeDetailsField(){
    let synced = this.isSynced(this.state.timeZone.offset, this.props.timeZone.offset);
    return(
      <div className='wc-details-content-field'>
        <label>Time Zone</label>
        <div className='select-field-container'>
          <select
            className={synced ? 'select-field' : 'select-field unsynced'}
            value={this.state.timeZone.offset}
            onChange={(e) => this.updateTimeZone(e)}
          >
            { this.timeZoneOptions() }
          </select>
        </div>
      </div>
    )
  }

  companyAddressField(){
    let { company } = this.state;
    let synced = () => {
      return(
        this.isSynced(company.address, this.props.company.address) &&
        this.isSynced(company.city, this.props.company.city) &&
        this.isSynced(company.state, this.props.company.state) &&
        this.isSynced(company.zip, this.props.company.zip)
      )
    }
    let klass = synced() ? 'fake-input' : 'fake-input unsynced';

    return(
      <div className='wc-details-content-field long'>
        <label>Company Address</label>
        <div className={klass}>
          <input
            className='hidden-input address'
            value={company.address || ''}
            placeholder='Address'
            onChange={(e) => this.updateCompany('address', e)}
          />
          <input
            className='hidden-input city'
            value={company.city || ''}
            placeholder='City'
            onChange={(e) => this.updateCompany('city', e)}
          />
          <input
            className='hidden-input state'
            value={company.state || ''}
            placeholder='State'
            onChange={(e) => this.updateCompany('state', e)}
          />
          <input
            className='hidden-input zip'
            value={company.zip || ''}
            placeholder='Zip'
            onChange={(e) => this.updateCompany('zip', e)}
          />
        </div>
      </div>
    )
  }

  responseInputField(header, questionNumber){
    let synced = this.isSynced(this.state.questionResponses[questionNumber], this.props.questionResponses[questionNumber]);
    return(
      <div className='wc-details-content-field'>
        <label>{header}</label>
        <input
          disabled={!this.props.afssId}
          className={synced ? 'input-field' : 'input-field unsynced'}
          value={this.state.questionResponses[questionNumber] || ''}
          onChange={(e) => this.updateResponseField(e, questionNumber)}
        />
      </div>
    )
  }

  responseOptionField(header, questionNumber){
    let synced = this.isSynced(this.state.questionResponses[questionNumber], this.props.questionResponses[questionNumber]);
    let options = () => {
      switch(questionNumber){
        case 1:
          return FormHelper.activeYears(); break;
        case 2:
          return FormHelper.agentStatus(); break;
        case 4:
          return FormHelper.sellerPercentage(); break;
        case 6:
          return FormHelper.monthlyExpenses(); break;
      }
    }
    return(
      <div className='wc-details-content-field'>
        <label>{header}</label>
        <div className='select-field-container'>
          <select
            disabled={!this.props.afssId}
            className={synced ? 'select-field' : 'select-field unsynced'}
            value={this.state.questionResponses[questionNumber]}
            onChange={(e) => this.updateResponseField(e, questionNumber)}
          >
            { this.getOptions(options()) }
          </select>
        </div>
      </div>
    )
  }

  responseRolesInput(){
    let responses = this.state.questionResponses;
    let synced = this.isSynced(responses[2], this.props.questionResponses[2]);
    return(
      <div className='wc-details-content-field'>
        <label>Roles At the Brokerage</label>
        <div className='select-field-container'>
          <input
            type='text'
            disabled={!this.props.afssId}
            className={synced ? 'input-field' : 'input-field unsynced'}
            value={Util.shortenString(responses[2], 30) || ''}
            onClick={this.toggleRolesDropdown}
          />
        </div>
        <div
          className={this.state.showRolesDropdown ? 'dropdown' : 'dropdown collapse'}
          onClick={(e) => e.stopPropagation()}
        >
          { FormHelper.agentStatus().map((option, idx) => {
            return(
              <label key={idx}>
                <input type='checkbox'
                  disabled={!this.props.afssId}
                  value={option || ''}
                  checked={responses[2] ? responses[2].includes(option) : false }
                  onChange={(e) => this.updateCheckbox(e)}
                />
                {option}
              </label>
            )
          })}
        </div>
        <div
          className={this.state.showRolesDropdown ? 'invisible-overlay' : 'hidden'}
          onClick={this.toggleRolesDropdown}
        />
      </div>
    )
  }

  updateCheckbox(e){
    let responses = this.state.questionResponses;
    let val = e.currentTarget.value;
    let response = responses[2]

    if(response && response.includes(val)){
      response = Util.removeValueFromListString(val, response)
    } else {
      response = Util.addValueToListString(val, response)
    }

    responses[2] = response;
    this.setState({ questionResponses: responses });
  }

  profileLink(){
    return '/a/' + this.props.agentId;
  }

  getOptions(list){
    let options = list.map((option, idx) => {
      return <option value={option} key={idx + 1} >{option}</option>
    });
    options.unshift(<option value='' key={0}></option>)
    return options;
  }

  timeZoneOptions(){
    let options = FormHelper.timeZones().map((option, idx) => {
      return <option value={option.value} key={idx + 1} >{option.text}</option>
    });
    options.unshift(<option value='' key={0}></option>)
    return options;
  }

  updateCompany(key, e){
    let company = this.state.company;
    let temp = e.currentTarget.value;
    company[key] = temp !== '' ? temp : null;
    this.setState({ company });
  }

  updateUserPhone(key, e){
    let user = this.state.user;
    let temp = e.currentTarget.value;
    user[key] = temp !== '' ? temp : null;
    this.setState({ user });
  }

  updateUser(key, e){
    let user = this.state.user;
    let temp = e.currentTarget.value;
    user[key] = temp !== '' ? temp : null;
    this.setState({ user })
  }

  updateTimeZone(e){
    let timeZone = this.state.timeZone;
    let temp = parseInt(e.currentTarget.value);
    timeZone.offset = temp !== '' ? temp : null;
    this.setState({ timeZone });
  }

  updateResponseField(e, field){
    let questionResponses = this.state.questionResponses;
    questionResponses[field] = e.currentTarget.value;
    this.setState({ questionResponses });
  }

  handleSubmit(){
    if(this.props.updatingAgent || this.areDetailsSynced()) return;
    let { user, company, timeZone, questionResponses } = this.state;
    this.props.toggleUpdatingAgent(true)

    let updateParams = {
      id: this.props.agentId,
      user: {
        first_name: user.first_name,
        last_name: user.last_name,
        phone_office: user.phone_office_base + 'ext' + user.phone_office_ext,
        phone_mobile: user.phone_mobile,
        email: user.email,
        gmt_offset: timeZone.offset
      },
      agent_company: company,
      responses: questionResponses
    };

    Util.updateAgentDetails(updateParams)
      .then(() => this.props.obtainAgentInfo())
  }

  submittingLoader(){
    let { updatingAgent } = this.props;
    return(
      <div className={updatingAgent ? 'wc-submit-loader' : 'hidden'}>
        <i className='fa fa-spinner fa-spin'></i>
      </div>
    )
  }

  render(){
    let { agentId, timeZone, user, questionResponses, company } = this.state;
    let { updatingAgent } = this.props;
    let areDetailsSynced = this.areDetailsSynced();
    if(this.state.user && Object.keys(this.state.user).length){
      return(
        <section className='wc-details'>
          <section className='wc-details-header'>
            <h3>AGENT INFORMATION</h3>
            <div>
              <div className='right-menu'>
                <div
                  className='agent-id'
                  onClick={() => window.open(`/customer_success/${agentId}`, '_blank')}
                >
                  ID {agentId}
                </div>
                &nbsp;&nbsp;
                <div className='menu'>
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </section>
          <section className='wc-profile'>
            <img className='react-user-photo' src={this.state.photo} onError={this.replaceImage}/>
            <div className='profile-menu'>
              <input
                className={areDetailsSynced ? 'save-button' : 'save-button unsynced'}
                disabled={areDetailsSynced}
                type='submit'
                onClick={this.handleSubmit}
                value={updatingAgent ? 'Saving...' : 'Save'}
              />
              <div className='link-button'>
                <a href={this.profileLink()} target='_blank'>Backend</a>
              </div>
              <button className='link-button' onClick={() => ReferralExchange.copyCrmLoginLinkToClipboard('agent', '/profile', this.props.agentId)}>
                CRM
              </button>
            </div>
          </section>
          <section className='wc-details-content'>
            { this.submittingLoader() }
            <div className='wc-details-content-fields'>
              { this.userDetailsField('First Name', 'first_name', user.first_name) }
              { this.userDetailsField('Last Name', 'last_name', user.last_name) }
              { this.userPhoneField('Office Phone', 'phone_office_base', user.phone_office_base, 'phone_base') }
              { this.userDetailsField('Office Extension', 'phone_office_ext', user.phone_office_ext, 'phone_ext') }
              { this.userPhoneField('Cell Phone', 'phone_mobile', user.phone_mobile) }
              { this.userDetailsField('Email', 'email', user.email) }
              { this.companyFields('Company', 'name', company.name) }
              { this.companyAddressField() }
              { this.responseOptionField('Years Active In Real Estate', 1)}
              { this.responseRolesInput() }
              { this.responseInputField('Number of Transactions per year', 7)}
              { this.responseOptionField('% of business from sellers', 4)}
              { this.responseOptionField('Monthly Expenses', 6)}
              { this.timeDetailsField() }
            </div>
          </section>
        </section>
      )
    } else {
      return (
        <section className='wc-details-missing'>
          Rex Submit Lead information missing
        </section>
      )
    }
  }
}
