import Head from './note_history/Head';
import Body from './note_history/Body';

const NoteHistory = ({ notes }) => (
  <div id="recruit-note-history">
    <table className="table table-striped">
      <Head />
      <Body notes={ notes } />
    </table>
  </div>
);

export default NoteHistory;
