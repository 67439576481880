import CloseWrapper from './CloseWrapper';

const PageTitle = ({ title, selected, close }) => {
  return (
    <div className='row'>
      <div className='col-md-12'>
        <h2 className='page-title'>
          { title }
          { selected && <CloseWrapper close={ close } /> }
        </h2>
      </div>
    </div>
  );
};

export default PageTitle;
