import { Component } from 'react';

export default class BottomContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noteError: false
    };

    this.badInfoOptions = this.badInfoOptions.bind(this);
  }

  componentDidMount() {
    const followUpDate = document.querySelector('.follow-up-date');
    const uiDatepickerDiv = document.querySelector('#ui-datepicker-div');
    const followUpDays = document.querySelector('.follow-up-days');

    $(followUpDate).attachLinkedDateTimePicker('.follow-up-days');

    // Event listeners for changes in date triggered from followUpDate, uiDatepickerDiv, and followUpDays
    ['focus', 'blur', 'change'].forEach(e => {
      followUpDate.addEventListener(e, () => {
        this.props.setFollowUpDateState(followUpDate.value);
      });
    });

    uiDatepickerDiv.addEventListener('change', () => {
      this.props.setFollowUpDateState(followUpDate.value);
    });

    followUpDays.addEventListener('change', () => {
      this.props.setFollowUpDateState(followUpDate.value);
    });
  }

  badInfoOptions() {
    if (this.props.lead_type !== 'BUYER') {
      return(
        <div className='col-md-6 border-box select-box mb-25'>
          <select value={this.props.outcome} onChange={this.props.updateOutcome} className='try-again width-100 border-box'>
            <option value=''>-- Bad Info --</option>
            <option value='bad_number'>Bad number</option>
            <option value='bad_location'>Bad location</option>
          </select>
        </div>
      )
    }
  }

  followUpInput() {
    return (
      <div className='col-md-6 border-box select-box mb-25 follow-up-inputs hidden'>
        <input className='follow-up-input follow-up-date border-box inline-block' name='follow-up-date' id='follow-up-date' />
        <div className='follow-up-input-days relative inline-block v-align-top'>
           <select className='follow-up-input follow-up-days border-box' id='follow-up-days'>
             <option value=''>--</option>
             <option value='1'>1</option>
             <option value='3'>3</option>
             <option value='7'>7</option>
             <option value='14'>14</option>
             <option value='30'>30</option>
             <option value='60'>60</option>
            </select>
        </div>
      </div>
    );
  }

  killLeadOptions() {
    return(
      <div className='col-md-6 border-box select-box mb-25'>
        <select value={this.props.outcome} onChange={this.props.updateOutcome} className='kill-outcome width-100 border-box'>
          <option value=''>-- Kill Lead --</option>
          <optgroup value='true' label='Answered Phone'>
            <option value='not_interested'>Doesn't want our service</option>
            <option value='this_is_an_agent'>This is an agent</option>
            <option value='bad_location_kill'>Bad location</option>
          </optgroup>
          <optgroup value='false' label='Did Not Answer Phone'>
            <option value='this_is_an_agent'>This is an agent</option>
            <option value='bad_location_kill'>Bad location</option>
          </optgroup>
        </select>
      </div>
    );
  }

  continueServicingOptions() {
    return (
      <div className='col-md-6 border-box select-box mb-25'>
        <select value={this.props.outcome} onChange={this.props.updateOutcome} className='try-again width-100 border-box'>
          <option value=''>-- Continue Servicing --</option>
          <option value='working_number_no_contact'>Working number, no contact</option>
          <option value='too_early_for_profile_review'>Too early for profile review</option>
        </select>
      </div>
    );
  }

  engageAgentsOptions() {
    return (
      <div className='col-md-6 border-box select-box mb-25'>
        <select value={this.props.outcome} onChange={this.props.showOutcomeContainer} className='lfu-rematching width-100 border-box'>
          <option value=''>-- Engage Agents --</option>
          <option value='warm_transfer'>Warm Transfer</option>
          <option value='still_working'>Still working</option>
          <option value='wants_more_agents'>Wants more agents</option>
          <option value='working_relationship'>Working relationship</option>
          <option value='wants_initial_contact'>Wants initial contact from agents</option>
          <option value='wants_to_be_recontacted'>Wants to be recontacted by agents</option>
          <option value='bad_number_engage'>Bad number - Let agents know</option>
        </select>
      </div>
    );
  }

  render() {
    let { lead_note, noteError } = this.props;

    return (
      <div className='profile-review-bottom-container'>
        <div className='row'>
          <div className='col-lg-5 height-100 border-box'>
            <div className='title'>LEAD NOTES <span className='red-font'>(Required)</span></div>
            <div className='note-box-container'>
              <textarea value={lead_note} onChange={this.props.updateLeadNote} className={noteError ? 'error' : ''} />
            </div>
          </div>
          <div className='col-lg-7 border-box'>
            <div className='title'>CALL OUTCOME</div>
            <div className='col-lg-6 border-box px-0 width-100'>
              <div className='row'>

                { this.engageAgentsOptions() }

                { this.killLeadOptions() }

                { this.continueServicingOptions() }

                { this.badInfoOptions() }

                { this.followUpInput() }

                <div className='col-lg-12 border-box'>
                  <button onClick={this.props.submitCallOutcome} className='submit-and-next btn btn-lg btn-primary border-box width-100 disabled' type='button'>Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ clear: 'both' }}></div>
      </div>
    );
  }
}
