import React, { Component } from 'react';

import Finished from './FinishedView';

export default class FinishedContainer extends Component {
  render() {
    const { close, message } = this.props;
    return (
      <Finished
        close={ close }
        message={ message }
      />
    );
  }
}
