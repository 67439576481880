import React from 'react';
import ReactDOM from 'react-dom';
import ProductDashboard from './ProductDashboard';

/**
 * Initialize the ProductDashboard system.
 */

export function initialize(containerElement) {
  let component = React.createElement(ProductDashboard);
  ReactDOM.render(component, containerElement);
}
