import { Component } from 'react';

export default class AgentSearchRow extends Component {
  constructor(props){
    super(props)
  }

  column(prop){
    return <div className='search-row'>{ prop }</div>
  }

  imageColumn(link){
    return(
      <div className='search-row-large'>
        <img src={link} style={{width: '100px'}} />
      </div>
    )
  }

  searchAgent(agent){
    this.props.fetchAgent(agent);
    this.props.forceClose();
  }

  linkColumn(agent){
    return(
      <div className='search-row'>
        <div className='search-row-link' onClick={() => this.searchAgent(agent)}>{agent.id}</div>
      </div>
    )
  }

  render(){
    let { agent } = this.props;
    return(
      <div className='search-rows'>
        { this.imageColumn(agent.photo) }
        { this.linkColumn(agent) }
        { this.column(agent.name) }
        { this.column(agent.broker_name) }
        { this.column(agent.company_address) }
        { this.column(agent.company_city) }
        { this.column(agent.company_state) }
        { this.column(agent.company_zip) }
        { this.column(agent.broker_name) }
        { this.column(agent.status) }
        { this.column(Util.formatPhoneNumber(agent.phone_office)) }
        { this.column(Util.formatPhoneNumber(agent.phone_mobile)) }
      </div>
    )
  }
}
