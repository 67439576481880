import React from 'react';

const Email = ({ email }) => {
  const renderEmailStatus = (status) => {
    if (status === 'sent') {
      return ['fa fa-check-circle-o'];
    } else if (status === 'unsent') {
      return ['fa fa-question-circle'];
    } else {
      return ['fa fa-times-circle-o'];
    }
  };

  return (
    <a href={`/emails/${email.id}/message`} data-remote='true'>
      <div className='react-email-row'>
        <div className='status'>
          <i className={renderEmailStatus(email.status)}></i>
        </div>
        <div className='email-block'>
          <div className='date'>
            { email.date }
          </div>
          <div className='value'>
            { `Email Type: ${email.type ? Util.shortenString(email.type, 30) : 'None'}` }
          </div>
        </div>
      </div>
    </a>
  )
}

export default Email;
