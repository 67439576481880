import React, { useState, useEffect } from 'react';

const Footer = (props) => {
  const { dispositionProspect, verifyContacts } = props;
  const container = document.getElementById('dialer-recruit-top-agents-research-container');

  const [disabled, toggleDisabled] = useState('disabled');
  const [needsVerification, toggleNeedsVerification] = useState(true);

  useEffect(() => {
    container.addEventListener('agent_research.needs_verification', handleInputChange);
  });

  function handleInputChange(event) {
    const inputs = container.querySelectorAll('input');
    const values = [...inputs].filter(i => i.value).map(i => i.value);
    const action = values.length ? '' : 'disabled';

    toggleDisabled(action);
    toggleNeedsVerification(true);
  }

  function handleVerification(event) {
    verifyContacts();
    toggleNeedsVerification(false);
  }

  return (
    <div className="footer">
      <div className="row">
        <div className="col-md-6 justify-content-center">
          <button className="btn btn-lg btn-rex-white no-active-agent" onClick={ dispositionProspect }>NO ACTIVE AGENT FOUND</button>
        </div>

        {
          needsVerification &&
          <div className="col-md-6 justify-content-center">
            <button id="verify-btn" className="btn btn-lg btn-rex-default verify" disabled={ disabled } onClick={ handleVerification } >VERIFY CONTACTS</button>
          </div>
        }

        {
          !needsVerification &&
          <div className="col-md-6 justify-content-center">
            <button id="submit-btn" className="btn btn-lg btn-rex-orange success" disabled={ disabled } onClick={ dispositionProspect }>SUCCESS</button>
          </div>
        }
      </div>
    </div>
  );
};

export default Footer;
