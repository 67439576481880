import  { Component } from  'react';
import mainIcon from '../images/call-connected.svg';
import * as Utilities from '../Utilities';

export default class AgentConnected extends Component {
  constructor(props){
    super(props);

    this.state = {
      secondsSinceCallStart: null
    };
  }

  componentDidMount() {
    this.createTimer();
  }

  componentWillUnmount() {
    clearInterval(this.intervalTimer);
  }

  createTimer() {
    this.intervalTimer = setInterval(() => {
      this.setState({ secondsSinceCallStart: Utilities.calculateSecondsSinceCallStart(this.props.agentCallStartTime)});
    }, 500)
  }

  render() {
    return (
      <button className="button green">
        Agent <span className='timer-text'>{ this.state.secondsSinceCallStart }</span>
      </button>
    );
  }
}