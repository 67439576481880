import { Component } from 'react';

export default class NewNote extends Component {
  constructor(props) {
    super(props);

    this.state = { set: false };

    this.updateSetButton           = this.updateSetButton.bind(this);
    this.updateNoteMessage         = this.updateNoteMessage.bind(this);
  }

  setButton() {
    return this.state.set
      ? <button onClick={this.updateSetButton} className='unset border-box'>UNSET</button>
      : <button onClick={this.updateSetButton} className='set-button border-box'>SET</button>;
  }

  updateSetButton() {
    this.setState({ set: !this.state.set });
  }

  updateNoteMessage(e) {
    var note_data = this.props.note_data;
    note_data['note_message'] = e.target.value;
    this.props.updateState('note_data', note_data);
  }

  render() {
    return (
      <div className='inner-note-container'>
        <div className='inner-container'>
          <textarea
            value={this.props.note_data['note_message']}
            onChange={this.updateNoteMessage}
            className='border-box'
          />
        </div>
      </div>
    );
  }
}
