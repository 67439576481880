import String from '../../../../../../common/String.js'

const string = new String();

const ButtonGroup = props => {
  const {
    buttons,
    buttonSelected,
    attribute,
    disabledButtons = [],
    updateFieldHandler,
  } = props;

  const toggleButtons = event => {
    const btn = event.target;
    const btns = btn.closest('.input-group').querySelectorAll("button");

    if (btn.disabled) return;

    btns.forEach(b => b.classList.add("inverted"));
    btn.classList.remove("inverted");

    updateFieldHandler({ [attribute]: btn.dataset[attribute] });
  };

  const buildButtonGroup = labels => {
    const btns = [];

    labels.forEach((label, idx) => {
      const attrValue = string.snakeCase(label);
      const dataProp = { [`data-${attribute}`]: attrValue };
      const klass = (buttonSelected === attrValue) ? "rex-button" : "rex-button inverted";
      const disabled = disabledButtons.includes(label);

      btns.push(<button key={ idx } type="button" className={ klass } disabled={ disabled } { ...dataProp } onClick={ toggleButtons }>{ label }</button>);
    });

    return btns;
  };

  return (
    <div className="input-group">
      { buttons && buildButtonGroup(buttons) }
    </div>
  );
};

export default ButtonGroup;


