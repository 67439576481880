import  { Component } from  'react';

export default class ButtonBase extends Component {
  constructor(props){
    super(props);

    this.state = {
      wasClicked: false
    };

    this.reinitialize = this.reinitialize.bind(this);
  }

  updateCallMode(mode) {
    let statusClass = this.determineStatusClass();
    if (statusClass == 'disabled' || statusClass == 'on-line' || this.state.wasClicked == true) return;
    this.setState({wasClicked: true});

    this.props.updateCallMode(this.modeType())
      .then((res) => {
        this.reinitialize();
      });
  }

  reinitialize() {
    this.setState({wasClicked: false});
  }

  determineStatusClass() {
    throw 'Must be implemented.';
  }

  modeType() {
    throw 'Must be implemented.';
  }

  renderIcon() {
    throw 'Must be implemented.';
  }

  render(){
    return (
      <div className='agent-call-container'>
        <div className='col-sm-1'>
          <div
            className={['button', this.determineStatusClass()].join(' ')}
            onClick={() => this.updateCallMode() }
          >
            { this.renderIcon() }
            <br></br>
          </div>
        </div>
      </div>
    );
  }
}
