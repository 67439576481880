import React, { Component } from 'react';

import LiveManagement from './ManagementView';

export default class LiveRefundManagementContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      displaying: null,
      loading: false,
    };

    this.display = this.display.bind(this);
    this.updateManagement = this.updateManagement.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.unsuccessfulRequest = this.unsuccessfulRequest.bind(this);
  }

  toggleModal(bool) {
    this.setState({ displaying: null, modalOpen: bool });
  }

  display(view) {
    this.setState({ displaying: view });
  }

  updateManagement(stateProp) {
    this.setState(stateProp);
  }

  unsuccessfulRequest(errors) {
    this.setState({makingRequest: false});
    alert(errors.responseJSON.error);
  }

  render() {
    const {
      displaying,
      loading,
      modalOpen,
    } = this.state;

    const {
      agent_id,
      net_initial_fee,
    } = this.props;

    return (
      <div>
        { modalOpen &&
          <LiveManagement
            agentId={ agent_id }
            displaying={ displaying }
            display={ this.display }
            loading={ loading }
            netInitialFee={ net_initial_fee }
            toggleModal={ this.toggleModal }
            updateManagement={ this.updateManagement }
            unsuccessfulRequest={ this.unsuccessfulRequest }
          />
        }
        <button
          onClick={ () => this.toggleModal(!modalOpen) }
          label="LIVE Refund Management"
          className="live-button">
          REFUND MGMT
        </button>
      </div>
    );
  }
}
