import { Component } from 'react';

export default class WorkingList extends Component {
  constructor(props) {
    super(props)
  }

  isWorkingListPopulated() {
    let workingLists = this.props.state.workingLists;
    return workingLists && Object.keys(workingLists).length;
  }

  workingLists() {
    return this.props.state.workingLists.map((list, idx) => {
      let letter = list.agent.name[0].toLowerCase();
      let initials = list.agent.name.split(' ').map(l => l[0]).join('').toUpperCase();
      let intent = list.has_intent ? 'green' : 'lightgray';
      let matched = list.matched ? 'green' : 'lightgray';
      return (
        <div className='list-item-centered' style={{ height: '100px' }} key={idx}>
          <div className='working-list-container'>
            <div className='working-list-status-container'>
              <div className='flex-centered w140'>
                <div className={'user-icon letter-pallete letter-pallete-black'}>{initials}</div>
                <a href={`/a/${list.agent.id}`} target='_blank'>{list.agent.name}</a>
              </div>
              <div className='flex-centered w100'>
                <h3>Tasks Due</h3>
                <div className='tasks-bubble'>{list.tasks_count}</div>
              </div>
              <div className='flex-centered w100'>
                <h3>Intent</h3>
                <i className='fa fa-4x fa-check-circle' style={{ color: intent }} aria-hidden='true'></i>
              </div>
              <div className='flex-centered w100'>
                <h3>Matched</h3>
                <i className='fa fa-4x fa-check-circle' style={{ color: matched }} aria-hidden='true'></i>
              </div>
            </div>
            <div className='working-list-notes-container'>
              NOTES AREA
            </div>
          </div>
        </div>
      )
    })
  }

  emptyList() {
    return (
      <div className='list-item-centered' style={{ height: '100px' }}>
        <div className="list-item-centered-detail w1000 message">Working Lists Empty</div>
      </div>
    )
  }

  render() {
    let { klass } = this.props;
    return (
      <div className={klass}>
        <div className='toolbar'>
          <h3>Working List</h3>
          &nbsp;&nbsp;&nbsp;
          <h3>Sort By</h3>
        </div>
        <div className='tab-content'>
          <div className='list h280'>
            <div className='list-inner'>
              {this.isWorkingListPopulated() ? this.workingLists() : this.emptyList()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
