const location = ({ city, state }) => {
  let loc = city || '';

  if (loc.length) loc += ', ';
  if (loc.length) loc += state;

  return loc;
};

const googleLink = ({ name, city, state }) => {
  let link = 'http://www.google.com/search';
  const search = [name, 'Real Estate', city, state].filter(Boolean);

  if (search.length > 1) link += `?q=${search.map(e => e.replace(/\s+/g, "+")).join("+")}`;

  return link.toLowerCase();
};

const Agent = (props) => {
  const { agent } = props;

  return (
    <div className="row">
      <div className="col-md-9">
        <h1 className="mt-0">{ agent.name }</h1>
        <p>{ agent.brokerage }</p>
        <p>{ location(agent) }</p>
      </div>
      <div className="col-md-3">
        <a href={ googleLink(agent) } className="btn btn-lg btn-default btn-block" target="_blank">
          <i className="fa fa-google"></i> Search
        </a>
      </div>
    </div>
  );
};

export default Agent;
