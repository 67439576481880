import React, { Component } from 'react';
import { connect } from 'react-redux';

import View from './view';

import { store } from '../index';
import { getStateProps } from '../utils';

function mapStateToProps(state) {
  return {
    info: state.submittingAgent.get('info'),
    type: state.submittingAgent.get('type'),
    rawAddressFields: state.submittingAgent.get('rawAddressFields'),
    rawLocation: state.submittingAgent.get('rawLocation'),
    rawPrice: state.submittingAgent.get('rawPrice'),
    notes: state.submittingAgent.get('notes'),
  }
}

class SubmittingAgent extends Component {
  constructor(props) {
    super(props);

    this.valueOnClick = this.valueOnClick.bind(this);
  }

  valueOnClick() {
    const { dispatch, rawAddressFields } = this.props;

    const info = getStateProps(store.getState().info);
    const { lead_type } = info;

    dispatch({ type: 'SET_FROM_RAW_LOCATION', data: { rawAddressFields }});
    dispatch({ type: 'RAW_LOCATIONS_FOCUS', data: { info, rawAddressFields }});

    const {
      city,
      country,
      state,
    } = rawAddressFields;

    const data = {
      city,
      country,
      state,
    };
    if (lead_type === 'buyer') {
      dispatch({
        type: 'GET_BUYER_CITY_LOCATION',
        data: {
          ...data,
          index: 0,
        },
      });
    } else {
      dispatch({
        type: 'GET_VERIFIED_LOCATION',
        data,
      });
    }
    dispatch({ type: 'VALIDATE_RAW_LOCATION_FIELDS', data: { lead_type, rawAddressFields }});
  }

  render() {
    const {
      info,
      type,
      rawLocation,
      rawPrice,
      notes,
    } = this.props;

    const data = {
      info,
      type,
      rawLocation,
      rawPrice,
      notes,
    };

    return (
      <View
        data={ data }
        valueOnClick={ this.valueOnClick }
      />
    );
  }
}

export default connect(mapStateToProps)(SubmittingAgent);
