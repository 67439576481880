import { Component } from 'react';
import DateTimeSelector     from './DateTimeSelector';
import AppointmentOnboarded from './appointments/AppointmentOnboarded';
import AppointmentScheduled from './appointments/AppointmentScheduled';
import AppointmentFresh     from './appointments/AppointmentFresh';

export default class Appointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCallLaterModal: false,
      welcomeCallTask: {},
      welcomeTeamSchedule: null,
      assignees: null,
      note: '',
      title: '',
      calendar: null,
      followUpTime: '',
      setupCalendar: false,
      showDateSelector: false,
      updating: false,
      errors: {
        status: false,
        assignedTo: false,
        followUpTime: false,
        disposition: false
      }
    }
    this.toggleDateSelector = this.toggleDateSelector.bind(this);
    this.toggleCallLaterSelector = this.toggleCallLaterSelector.bind(this);
    this.updateNote = this.updateNote.bind(this);
    this.changeFollowUp = this.changeFollowUp.bind(this);
    this.setUpdating = this.setUpdating.bind(this);
    this.resetState = this.resetState.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.callLater = this.callLater.bind(this);
    this.getWelcomeAgentOutboundAssignees = this.getWelcomeAgentOutboundAssignees.bind(this);
  }

  componentDidMount(){
    this.updateCalendar({ weekday: Util.MMDDYYYY() });
    this.props.toggleAppointment(true);
    this.getWelcomeAgentOutboundAssignees();
  }

  componentWillReceiveProps(nextProps){
    if(JSON.stringify(nextProps.welcomeCallTask) === JSON.stringify(this.props.welcomeCallTask)) return;
    let welcomeCallTask = nextProps.welcomeCallTask || {};
    welcomeCallTask.assigned_to = welcomeCallTask.assigned_to || this.props.currentUser.id
    this.setState({ welcomeCallTask })
    this.props.toggleAppointment(true);
  }

  getWelcomeAgentOutboundAssignees(){
    Util.getAssignees()
      .then((results) => {
        let assignees = [];
        for (let i = 0; i < results.assignees.length; i++) {
          if (results.assignees[i].user_welcome_agent_outbound) {
            assignees.push(results.assignees[i]);
          }
        }
        this.setState({ assignees: assignees });
      });
  }

  updateCalendar(params){
    Util.getCustomerSuccessSchedule(params)
      .then((result) => this.setState({ calendar: result.calendar }))
  }

  updateErrors(errors){
    this.setState({ errors })
  }

  toggleDateSelector(){
    this.setState({ showDateSelector: !this.state.showDateSelector })
  }

  setUpdating(bool){
    this.setState({ updating: bool })
  }

  setTitle(){
    let { welcomeCallTask, agentFeatures } = this.props;
    if(this.state.updating) return 'Saving...'

    if(welcomeCallTask){
      switch(welcomeCallTask.task_type){
        case 'Welcome Agent (Outbound)':
          return 'Appointment';
          break;
        case 'Welcome Appointment (Outbound)':
          return 'Welcome Appointment';
          break;
        case 'Outbound Agent - Follow-up':
          return 'Follow up Appointment';
      }
    } else if(agentFeatures && agentFeatures.rex_personal && agentFeatures.rex_personal.enabled){
      return 'Appointment';
    }else {
      return 'Agent Not Applicable'
    }
  }

  changeFollowUp(followUpTime){
    if(followUpTime === undefined) return;
    this.setState({ followUpTime });
  }

  resetState(){
    this.props.toggleAppointment(false);
    $('#call_date_setter').val('');
    $('#call_later_days').val('');
    this.setState({
      setupCalendar: false,
      followUpTime: null,
      note: '',
      updating: false,
      welcomeCallTask: {},
      errors: {
        assignedTo: false,
        followUpTime: false,
        disposition: false
      }
    }, () => this.reloadForm());
  }

  reloadForm(){
    let { agentId, activeDate, fetchAgent, loadCustomerSuccessSchedule } = this.props;
    fetchAgent(agentId);
    loadCustomerSuccessSchedule(activeDate);
  }

  callLater(){
    if(this.missingFollowUp()) return;

    let { followUpTime, note } = this.state;
    let { fetchAgent, agentId, timeZone, currentUser } = this.props;
    this.setUpdating(true);

    let parsedTime = Util.parseDateTime(followUpTime, -1 * (timeZone.offset + 8));

    let updateParams = {
      completed_task: { task_id: this.state.welcomeCallTask.id },
      new_task: {
        taskable_id: agentId,
        follow_up: parsedTime
      },
      note: {
        message: `Call was rescheduled for a later date ${followUpTime}. ${note}`,
        noteable_id: agentId
      }
    }
    this.setState({ showCallLaterModal: false })
    Util.welcomeCallCallLater(updateParams)
      .then(() => this.resetState());
  }

  updateField(e, field){
    if(field === 'disposition') this.setState({ followUpTime: null });
    // if the disposition changes we want the follow up time to reset to null.  This is important for updating missed appointments.
    this.setState({
      welcomeCallTask: { ...this.state.welcomeCallTask,
        [field]: e.currentTarget.value
      }
    });
  }

  updateNote(e){
    this.setState({ note: e.currentTarget.value });
  }

  toggleCallLaterSelector(){
    this.setState({ showCallLaterModal: !this.state.showCallLaterModal });
    this.changeFollowUp('');
  }

  attachCalendar(){
    let timeZone = this.props.timeZone
    if(!this.state.setupCalendar && $('#call_date_setter') && timeZone){
      setTimeout(() => {
        $('#call_date_setter').attachLinkedDateTimePickerWithTimezone('#call_later_days', 30, timeZone.time_zone);
        $('.ui-datepicker-trigger').hide();
        $(document).on('valueChange', '#call_date_setter', () => {
          this.changeFollowUp($('#call_date_setter').val());
        });
        this.setState({ setupCalendar: true });
      }, 200)
    }
  }

  callLaterSelector(){
    return(
      <select id='call_later_days' onChange={(e) => this.changeFollowUp(Util.numberToDateTime(e))} >
        <option value=''></option>
        <option value='1'>1</option>
        <option value='2'>2</option>
        <option value='3'>3</option>
        <option value='4'>4</option>
        <option value='5'>5</option>
        <option value='6'>6</option>
        <option value='7'>7</option>
        <option value='8'>8</option>
        <option value='9'>9</option>
        <option value='10'>10</option>
      </select>
    )
  }

  missingFollowUp(){
    let { followUpTime, errors } = this.state;
    let followUpTimeError = followUpTime === null ? true : false;

    errors.followUpTime = followUpTimeError;
    this.updateErrors(errors);

    return followUpTimeError;
  }

  showCallLaterButtonValid(){
    let task = this.props.welcomeCallTask;
    let features = this.props.agentFeatures;

    return (task && task.task_type === 'Welcome Agent (Outbound)') ||
      (!task && features && features.rex_personal && features.rex_personal.enabled)
  }

  render() {
    let { agentId, timeZone, task, currentUser, agentFeatures, fetchAgent, showAppointment } = this.props;
    let { errors, welcomeCallTask, welcomeTeamSchedule, note, assignees, title, followUpTime, showDateSelector, calendar, updating } = this.state;
    let assigneeOptions;

    let callLaterButton = () => {
      if(this.showCallLaterButtonValid()){
        return(
          <div onClick={(e) => e.stopPropagation()}>
            <input
              className='react-call-later-btn'
              type='submit'
              onClick={this.toggleCallLaterSelector}
              value='CALL LATER'
              disabled={updating}
            />
          </div>
        )
      }
    }

    let callLaterModal = () => {
      return(
        <div className={this.state.showCallLaterModal ? 'react-mask-layer' : 'hidden'}onClick={this.toggleCallLaterSelector}>
          <div className='cs-appt-content' onClick={(e) => e.stopPropagation()}>
            <div className='call-later-time'>
              <div className='header'>
                <label>Call Later Date/Time (agent time)</label>
              </div>
              <div className='content'>
                <div className={errors.followUpTime ? 'follow-up-fields cs-error' : 'follow-up-fields'}>
                  <input
                    id='call_date_setter'
                    className='follow-up-calendar'
                    type='input'
                    placeholder='Select a date'
                  /> &nbsp;
                  { this.callLaterSelector() }
                  { this.attachCalendar() }
                </div>
                <textarea
                  className={errors.note ? 'cs-error' : ''}
                  onChange={this.updateNote}
                  value={note}
                  placeholder='Note'
                />
                <input
                  className='button'
                  type='submit'
                  onClick={this.callLater}
                  value='CALL LATER'
                  disabled={updating}
                />
              </div>
            </div>
          </div>
        </div>
      )
    }

    if(agentId && assignees){
      for (let i = 0; i < assignees.length; i++) {
        if(assignees[i].user_id === currentUser.id){
          // moves current user to the front of the list
          assignees.splice(1, 0, assignees.splice(i, 1)[0]);
        }
      }
      assigneeOptions = () => {
        if(welcomeCallTask){
          return(
            <select
              className={errors.assignedTo ? 'cs-error' : ''}
              value={welcomeCallTask.assigned_to}
              onChange={(e) => {
                this.updateField(e, 'assigned_to');
                this.changeFollowUp('');
              }}
            >
              {assignees.map((assignee) => {
                return(
                  <option key={assignee.user_id} value={assignee.user_id}>
                    { assignee.user_name }
                  </option>
                )
              })}
            </select>
          )
        }
      }

      let welcomeCallStatusOptions = () => {
        return(
          <select
            className={errors.disposition ? 'cs-error' : ''}
            value={welcomeCallTask.disposition || ''}
            onChange={(e) => this.updateField(e, 'disposition')}
          >
            { FormHelper.welcomeCallStatus().map((status, idx) => {
              return <option key={idx} value={status.value}>{status.text}</option>
            })}
          </select>
        )
      }

      let followUpStatusOptions = () => {
        return(
          <select
            className={errors.disposition ? 'cs-error' : ''}
            value={welcomeCallTask.disposition || ''}
            onChange={(e) => this.updateField(e, 'disposition')}
          >
            { FormHelper.followUpStatus().map((status, idx) => {
              return <option key={idx} value={status.value}>{status.text}</option>
            })}
          </select>
        )
      }

      let appointmentTime = (time) => {
        if(time){
          if(timeZone && timeZone.time_zone === 'Pacific'){
            return <span>{ Util.dateTimetoTime(time) }</span>
          } else {
            return(
              <span>{ Util.dateTimetoTime(time) }
                ({ Util.dateTimetoTime(time, timeZone.offset + 8) } { timeZone.time_zone})
              </span>
            )
          }
        }
      }

      let dateSelector = () => {
        if(showDateSelector){
          return(
            <DateTimeSelector
              toggleDateSelector={this.toggleDateSelector}
              changeFollowUp={this.changeFollowUp}
              currentUserId={welcomeCallTask.assigned_to}
              timeZone={timeZone}
            />
          )
        }
      }

      let appointmentForm = () => {
        if(this.props.welcomeCallTask && this.props.welcomeCallTask.task_type === 'Outbound Agent - Follow-up'){
          return(
            <AppointmentOnboarded
              showAppointment={showAppointment}
              updateNote={this.updateNote}
              setUpdating={this.setUpdating}
              resetState={this.resetState}
              changeFollowUp={this.changeFollowUp}
              assigneeOptions={assigneeOptions}
              updating={updating}
              updateErrors={this.updateErrors}
              errors={errors}
              note={note}
              agentId={agentId}
              timeZone={timeZone}
              fetchAgent={fetchAgent}
              currentUser={currentUser}
              followUpTime={followUpTime}
              currentTask={this.props.welcomeCallTask}
              nextTask={welcomeCallTask}
              appointmentTime={appointmentTime}
              statusOptions={followUpStatusOptions}
            />
          )
        } else if(this.props.welcomeCallTask && this.props.welcomeCallTask.task_type === 'Welcome Appointment (Outbound)') {
          return(
            <AppointmentScheduled
              showAppointment={showAppointment}
              updateNote={this.updateNote}
              setUpdating={this.setUpdating}
              resetState={this.resetState}
              changeFollowUp={this.changeFollowUp}
              assigneeOptions={assigneeOptions}
              updating={updating}
              updateErrors={this.updateErrors}
              errors={errors}
              note={note}
              agentId={agentId}
              timeZone={timeZone}
              fetchAgent={fetchAgent}
              currentUser={currentUser}
              followUpTime={followUpTime}
              currentTask={this.props.welcomeCallTask}
              nextTask={welcomeCallTask}
              toggleDateSelector={this.toggleDateSelector}
              dateSelector={dateSelector}
              appointmentTime={appointmentTime}
              statusOptions={welcomeCallStatusOptions}
            />
          )
        } else if((this.props.welcomeCallTask && this.props.welcomeCallTask.task_type === 'Welcome Agent (Outbound)') || (agentFeatures && agentFeatures.rex_personal && agentFeatures.rex_personal.enabled)){
          return(
            <AppointmentFresh
              showAppointment={showAppointment}
              updateNote={this.updateNote}
              setUpdating={this.setUpdating}
              toggleDateSelector={this.toggleDateSelector}
              resetState={this.resetState}
              changeFollowUp={this.changeFollowUp}
              assigneeOptions={assigneeOptions}
              dateSelector={dateSelector}
              updating={updating}
              updateErrors={this.updateErrors}
              errors={errors}
              note={note}
              agentId={agentId}
              timeZone={timeZone}
              fetchAgent={fetchAgent}
              currentUser={currentUser}
              followUpTime={followUpTime}
              welcomeCallTask={welcomeCallTask}
            />
          )
        } else {
          return(
            <div className={showAppointment ? 'cs-call-from' : 'cs-call-from-collapse'}>
              <div className='cs-call-from-inner'>
                <label>Appointment Options not valid for this user </label>
              </div>
            </div>
          )
        }
      }

      return(
        <div className='cs-task-panel'>
          <div
            className='react-header'
            onClick={() => this.props.toggleAppointment(!showAppointment)}
          >
            <label>{ this.setTitle() }</label>
            { callLaterButton() }
            <i className={showAppointment ? 'fa fa-caret-right rotate90' : 'fa fa-caret-right'}></i>
          </div>
          { appointmentForm() }
          { callLaterModal() }
        </div>
      );
    } else {
      return <div />
    }
  }
}
