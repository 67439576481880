import Locations from '../../locations';

 export const classList = ({ classNames, def, focused, valid }) => {
   const classes = [];

   if (def) {
     classes.push(def);
   }
   if (classNames) {
     classes.push(classNames);
   }

   if (focused === false) {
     classes.push('untouched');
   }

   if (valid !== undefined) {
     if (valid === true) {
       classes.push('valid');
     } else if (valid !== null) {
       classes.push('invalid');
     }
   }

   return classes.join(' ').trim();
 };

 export const selectedCountryInfo = (country) => {
   const {
     country_options,
     provinces,
     states,
   } = Locations.get();

   if (!country_options) {
     return {
       labels: null,
       countries: [],
       statesSelectValues: [],
     };
   }

   const labels = {
     state: country === 'US' ? 'State' : 'Province',
     zip: country === 'US' ? 'ZIP' : 'Postal Code',
   };

   const countries = Object.keys(country_options).map((key, i) => {
     return {
       name: country_options[key],
       val: key
     };
   }).reverse();

   const statesSelectValues = country === 'US' ? states : provinces;

   if (statesSelectValues[0] !== '') {
     statesSelectValues.unshift('');
   }

   return {
     labels,
     countries,
     statesSelectValues,
   };
 };
