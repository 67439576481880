import request from 'superagent';
import { Component } from 'react';

export default class LeadMatchToAgent extends Component {
  constructor(props){
    super(props)
    this.state = {
      showDesignatedAgent: false,
      agentId: '',
      agent: {}
    };

    this.handleAgentIdInput = this.handleAgentIdInput.bind(this);
    this.matchToAgent = this.matchToAgent.bind(this);
  }

  matchToAgent(id) {
    this.props.toggleMatchedAgent(id);
  }

  matchDesignatedAgent(id) {
    this.props.toggleMatchedAgent(id);
    this.props.handleToggleAddedDesignatedAgent();
  }

  showDesignatedAgent() {
    this.setState({showDesignatedAgent: true});
  }

  searchDesignatedAgent() {
    let { agentId } = this.state;

    request
      .get(`/agents/${agentId}/search_designated_agent`)
      .then(({ body }) => {
        let { agent } = body;
        this.setState({ agent });

        console.debug('Agent returned.');
        console.debug(agent);
      })
      .catch(error => {
        alert('Search failed. Please try again');
        console.log('Search failed error: ', e);
      });
  }

  handleAgentIdInput(e) {
    this.setState({agentId: e.currentTarget.value});
  }

  isMatched(id) {
    return this.props.matchedAgentIds.includes(id);
  }

  renderSearchDesignatedAgent() {
    if (this.state.showDesignatedAgent) {
      return <div className="row">
          <div className='col-sm-5 col-sm-offset-2'>
            <input type='text' placeholder='Agent ID' value={this.state.agentId} onChange={(e) => this.handleAgentIdInput(e) }/>
          </div>
          <div className='col-sm-3'>
            <button className='step-button search-button' onClick={() => this.searchDesignatedAgent()}>Search</button>
          </div>
        </div>
    }
  }

  renderMatches() {
    return this.props.matches.map((match) => {
      if (match.match_accepted_at != null) {
        return <div className="row">
            <div className='col-sm-11'>
              <label className={"match-button " + (this.isMatched(match.agent.id) ? 'label-highlight' : '')} onClick={() => this.matchToAgent(match.agent.id)}> {match.agent.name_on_license}</label>
            </div>
          </div>
      }
    });
  }

  renderSearchDesignatedAgentResults() {
    let agent = this.state.agent;
    if (agent.name != null) {
      return <div className="row">
          <div className='col-sm-5 col-sm-offset-2'>
            <h4 className='agent-info'><span className='agent-info-heading'>Name: </span>{agent.name}</h4><br></br>
            <h4 className='agent-info'><span className='agent-info-heading'>Company: </span>{agent.company_name}</h4><br></br>
          </div>
          <div className='col-sm-3'>
            <button className='step-button search-button' onClick={() => this.matchDesignatedAgent(agent.id)}>Add match</button>
          </div>
        </div>
    }
  }

  renderMatchesBorder() {
    let acceptedMatches = this.props.matches.filter(match => match.match_accepted_at != null);
    if (acceptedMatches.length > 0) {
      return <hr></hr>
    }
  }

  render() {
    let { leadType, handleMatchSelect, matches, agentId} = this.props;
    let { agent } = this.state;
    return(
      <div className='fieldset'>
          { this.renderMatches() }
          { this.renderMatchesBorder() }
          <div className="row">
            <div className='col-sm-11'>
              <label className={"match-button " + (this.state.showDesignatedAgent || this.props.addedDesignatedAgent ? 'label-highlight' : '')} onClick={() => this.setState({showDesignatedAgent: true})}>Add Agent by ID
              </label>
            </div>
          </div>
          { this.renderSearchDesignatedAgent() }
          { this.renderSearchDesignatedAgentResults() }
          <hr></hr>
          <div className="row">
            <div className='col-sm-11'>
              <label className={"match-button " + (this.props.matchedAgentIds.length == 0   ? 'label-highlight' : '')} onClick={() => this.props.selectNoMatch()}>No Match
              </label>
            </div>
          </div>
      </div>
    )
  }
}
