import React, { useState, useEffect, useRef } from 'react';
import AgentInfo from './AgentInfo';
import CallStats from './CallStats';
import CallDispositions from './CallDispositions';
import Intro from './Intro';
import PrimeHistory from './PrimeHistory';
import Modals from './Modals';
import NewAgentForm from './NewAgentForm';
import AddContactModal from './../../Dialer/RecruitTopAgentsModals/AddContact';

import './styles/index.scss';

const initModalState = {
  show: false,
  type: '',
}

const initData = {
  agentInfo: {},
  opsUser: {},
  click2call: {},
  salesStatus: {},
}

const { parsePhoneNumber } = window.libphonenumber;

const EligibleDialer = (props) => {
  const queryString = window.location.search;

  const [data, setData] = useState(initData);
  const [callStats, setCallStats] = useState({});
  const [salesHistory, setSalesHistory] = useState([]);
  const [broadcastId, setBroadcastId] = useState(undefined);
  const [newAgentId, setNewAgentId] = useState(undefined);
  const [pause, setPause] = useState(!!queryString);
  const [disposition, setDisposition] = useState(null);
  const [note, setNote] = useState('');
  const [modal, setModal] = useState(initModalState);
  const [followUp, setFollowUp] = useState('');
  const [followUpDays, setFollowUpDays] = useState('');
  const [showAgentForm, toggleNewAgentForm] = useState(false);

  const placeCall = useRef(undefined);

  const {
    agentInfo,
    opsUser,
    click2call,
    salesStatus,
  } = data;

  const {
    agents_path,
    duplicate_check_path,
    click2call_path,
    new_dialer_prime_eligible_path,
    stats_dialer_prime_eligible_path,
    rex_product_sale_attempts_path,
    dialer_prime_eligible_path,
  } = props;

  window.setModal = setModal;

  useEffect(() => {
    ReferralExchange.updateTitle('Prime Eligible Dialer');
    readyToFetchProspect();
  }, []);

  useEffect(() => {
    document.body.addEventListener('add_rta.contact.success', handleNewContactAdded);

    return () => {
      document.body.removeEventListener('add_rta.contact.success', handleNewContactAdded);
    }
  }, [data]);

  useEffect(() => {
    if (!!(placeCall.current && click2call.phone)) {
      fetchSalesHistory();
      // if (!queryString) callAgentNumber();
      fetchUserStats();
    }
  }, [click2call.phone]);

  useEffect(() => {
    if (!queryString && click2call.phone === null) handleBlacklistedNumber('not_interested');
  }, [disposition, click2call.phone]);

  useEffect(() => {
    if (agentInfo.agent_id) setNewAgentId(agentInfo.agent_id);
  }, [agentInfo.agent_id]);

  const readyToFetchProspect = () => {
    const interval = setInterval(() => {
      if (document.readyState !== 'complete') return;

      const dialerContainer = document.querySelector('.prime-eligible-dialer');

      dialerContainer.addEventListener('prime-eligible-dialer.activate-prime', e => setNewAgentId(e.detail));
      clearInterval(interval);
      fetchNewProspect();
    }, 5);
  }

  const fetchNewProspect = () => {
    resetDialer();
    showLoader();

    fetch(
      `${new_dialer_prime_eligible_path}${queryString}`,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      setModal(initModalState);

      if (response.success) {
        const { agent, ops_user, sales_status, click2call } = response;

        const overrides = {
          agentInfo: agent,
          opsUser: ops_user,
          click2call: click2call,
          salesStatus: sales_status
        };

        placeCall.current = true;

        setData({ ...data, ...overrides });
        setBroadcastId(`prime-eligible-dialer:${agent.prospect_id}:${agent.prospect_type}`);
      } else {
        Util.createFlash(response.errors.join(', '), 'warning', '.dialer-flash-wrapper')
        setModal({ show: true, type: 'warning' });
      }
    })
    .catch(error => {
      Util.createFlash('Failed to fetch new prospect', 'danger', '.dialer-flash-wrapper');
      console.error(error);
    });
  }

  const fetchUserStats = () => {
    fetch(
      stats_dialer_prime_eligible_path,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        const { talk_time, calls, scheduled, missed, remaining } = response;

        setCallStats({ talk_time, calls, scheduled, missed, remaining });
      } else {
        Util.createFlash(response.errors.join(', '), 'warning', '.dialer-flash-wrapper')
      }
    })
    .catch(error => {
      Util.createFlash('Failed to fetch user stats', 'danger', '.dialer-flash-wrapper');
      console.error(error);
    });
  }

  const handleBlacklistedNumber = dispo => {
    const button = document.getElementById('prime-dialer-submit-disposition');
    const { prospect_type } = data.agentInfo;
    const disposition = /^Agent$/.test(prospect_type) ? 'not_interested' : 'bad_number';

    setModal({ show: true, type: 'warning' });
    setDisposition(dispo || disposition);
    button.click();
  }

  const callAgentNumber = () => {
    const { click2call } = data;

    setModal({ show: true, type: 'calling' });

    fetch(
      click2call_path,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(click2call),
      }
    )
    .then(response => {
      if (response.headers.get('blacklisted')) {
        handleBlacklistedNumber();
      } else {
        setModal(initModalState);
      }
    })
    .catch(error => {
      Util.createFlash('Failed to place call to agent', 'danger', '.dialer-flash-wrapper');
      console.error(error);
    });
  }

  const fetchSalesHistory = () => {
    const { prospect_id, prospect_type } = data.agentInfo;
    const params = Util.toQueryString({ sales: { product_type: 'prime', loggable_id: prospect_id, loggable_type: prospect_type } });

    fetch(
      `${rex_product_sale_attempts_path}?${params}`,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        const { logs } = response;

        setSalesHistory(logs);
      } else {
        Util.createFlash(response.errors.join(', '), 'warning', '.dialer-flash-wrapper')
      }
    })
    .catch(error => {
      Util.createFlash('Failed to fetch sales history', 'danger', '.dialer-flash-wrapper');
      console.error(error);
    });
  }

  const dispositionAgentStatus = async (params) => {
    return await fetch(
      dialer_prime_eligible_path,
      {
        method: 'PUT',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
      }
    );
  }

  const handlePreviousPhone = () => {
    const { prospect_id, prospect_type, number } = agentInfo;

    const params = {
      sales: {
        message: null,
        product_type: 'prime',
        status: 'bad_number',
        follow_up_date: null,
        loggable_id: prospect_id,
        loggable_type: prospect_type,
        phone_number: number,
        retain_prospect: true,
      },
    }

    dispositionAgentStatus(params)
      .then(response => response.json())
      .then(response => {
        if (!response.success) throw new Error(response.errors.join(', '));
      })
      .catch(error => {
        console.log(error);

        Bugsnag.notify(error, e => e.addMetadata('context', params));
      });
  }

  const handleNewContactAdded = (event) => {
    const {
      detail: {
        contactType,
        contact,
      },
    } = event;

    let overrides;

    if (contactType === 'phone') {
      const newPhone = parsePhoneNumber(contact, 'US').format('INTERNATIONAL');
      const dashPhone = newPhone.replace(/(\d{3}) (\d{3}) (\d{4})/, '$1-$2-$3');
      const nospacePhone = newPhone.replace(/\s/g, '');

      overrides = {
        agentInfo: { ...agentInfo, number: nospacePhone, display_number: dashPhone },
        click2call: { ...click2call, phone: nospacePhone },
      };

      placeCall.current = false;
      handlePreviousPhone();
    }

    if (contactType === 'email') {
      overrides = {
        agentInfo: { ...agentInfo, email: contact },
      };
    }

    if (overrides !== undefined) setData({ ...data, ...overrides });
  }

  const togglePause = () => {
    setPause(!pause);
  };

  const showLoader = () => {
    setModal({
      show: true,
      type: 'loader',
    })
  }

  const copyDialerLink = event => {
    const { prospect_id, prospect_type } = agentInfo;
    const input = document.createElement('input');
    const dialerLink = `${window.origin}${dialer_prime_eligible_path}?prospect_id=${prospect_id}&prospect_type=${prospect_type}`;

    input.value = dialerLink;
    document.body.prepend(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    setModal({
      show: true,
      type: 'copy-dialer-link',
    })
  }

  const activatePrime = event => {
    const { currentTarget } = event;
    const linkId = newAgentId || agentInfo.prospect_id;

    if (currentTarget.disabled) return;

    ReferralExchange.copyCrmLoginLinkToClipboard('agent', '/prime/overview', linkId, undefined, true);

    setModal({
      show: true,
      type: 'activate-prime',
    })
  }

  const scheduleFollowUp = () => {
    setModal({
      show: true,
      type: 'schedule-follow-up',
    })
  }

  const resetDialer = () => {
    Util.clearFlash('.dialer-flash-wrapper');
    setData(initData);
    setCallStats({});
    setSalesHistory([]);
    setBroadcastId(undefined);
    setNewAgentId(undefined);
    setPause(!!queryString);
    setDisposition(null);
    setNote('');
    setModal(initModalState);
    setFollowUp('');
    setFollowUpDays('');
    toggleNewAgentForm(false);
  }

  const followUpDate = () => {
    const result = followUp
      ? moment.utc(followUp)
      : (disposition === 'no_answer') ? followUp : moment().add(1, 'days');

    return result ? result.format('YYYY-MM-DD HH:mm:ss') : result;
  }

  const submit = (e) => {
    const button = e.currentTarget;

    button.disabeld = true;

    showLoader();

    if (/sold/.test(disposition)) {
      if (!pause) fetchNewProspect();
    } else {
      const { prospect_id, prospect_type, number } = agentInfo;

      const params = {
        sales: {
          message: note,
          product_type: 'prime',
          status: disposition,
          follow_up_date: followUpDate(),
          loggable_id: prospect_id,
          loggable_type: prospect_type,
          phone_number: number,
        },
      };

      dispositionAgentStatus(params)
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            if (!pause) fetchNewProspect();
          } else {
            Util.createFlash(response.errors.join(', '), 'danger', '.dialer-flash-wrapper')
            setModal(initModalState);
          }
        })
        .catch(error => {
          console.log(error);

          Bugsnag.notify(error, e => e.addMetadata('context', params));
        });
    }

    if (pause) window.location = '/dialer';

    button.disabeld = false;
  }

  return (
    <div className="prime-eligible-dialer" id={ broadcastId }>
      <AddContactModal />

      <CallStats
        number={agentInfo.display_number}
        callStats={callStats}
        togglePause={togglePause}
        pause={pause}
      />

      <div className='dialer-flash-wrapper'>
        <div id='flash-container'></div>
      </div>

      <div className="dialer-agent-details-wrapper">
        <div className="dialer-agent-details">
          <Intro
            agentInfo={agentInfo}
            opsName={opsUser.name}
          />
          <AgentInfo
            newAgentId={newAgentId}
            agentInfo={agentInfo}
            click2call={click2call}
            click2call_path={click2call_path}
            opsUser={opsUser}
            salesStatus={salesStatus}
            activatePrime={activatePrime}
            scheduleFollowUp={scheduleFollowUp}
            toggleNewAgentForm={toggleNewAgentForm}
            copyDialerLink={copyDialerLink}
          />
          <PrimeHistory salesHistory={salesHistory} />
        </div>
      </div>
      <CallDispositions
        agentInfo={agentInfo}
        disposition={disposition}
        setDisposition={setDisposition}
        note={note}
        setModal={setModal}
        setNote={setNote}
        submit={submit}
      />
      <Modals
        modal={modal}
        setModal={setModal}
        followUp={followUp}
        setFollowUp={setFollowUp}
        followUpDays={followUpDays}
        setFollowUpDays={setFollowUpDays}
        prospect={agentInfo}
        broadcastId={broadcastId}
      />
      {
        broadcastId &&
        <NewAgentForm
          agents_path={agents_path}
          broadcastId={broadcastId}
          showAgentForm={showAgentForm}
          toggleNewAgentForm={toggleNewAgentForm}
          duplicateCheckPath={duplicate_check_path}
        />
      }
    </div>
  )
}

export default EligibleDialer;
