export default class String {
  constructor() {}

  /* Example input: This is a close-up 1st text example!

  /**
   * Converts text to title case
   * @param  {string} text
   * @return {string}      Transformed text
   *
   * @example
   *   This Is A Close Up 1st Text Example
   */
  titleCase(text) {
    if (!text.length) return text;

    return text.replace(/\w\S*/g, (txt) => {
      const tokens = txt.split(/[^0-9A-Z]/ig);
      const words = [];

      tokens.forEach(token => {
        words.push(`${token.charAt(0).toUpperCase()}${token.substr(1).toLowerCase()}`);
      });

      return words.join(' ').trim();
    });
  }

  /**
   * Converts text to snake case
   * @param  {string} text
   * @return {string}      Transformed text
   *
   * @example
   *   this_is_a_close_up_1_st_text_example
   */
  snakeCase(text) {
    return text && text.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(txt => txt.toLowerCase()).join('_');
  }

  /**
   * Converts text to screaming snake case
   * @param  {string} text
   * @return {string}      Transformed text
   *
   * @example
   *   THIS_IS_A_CLOSE_UP_1_ST_TEXT_EXAMPLE
   */
  screamingSnakeCase(text) {
    return text && snakeCase(text).toUpperCase();
  }


  /**
   * Converts text to pascal case
   * @param  {string} text
   * @return {string}      Transformed text
   *
   * @example
   *   ThisIsACloseUp1stTextExample
   */
  pascalCase(text) {
    return text && titleCase(text).replace(/\s+/g, '');
  }

  /**
   * Converts text to camel case
   * @param  {string} text
   * @return {string}      Transformed text
   *
   * @example
   *   thisIsACloseUp1stTextExample
   */
  camelCase(text) {
    if (!text.length) return text;

    const transformed = pascalCase(text);

    return `${transformed.charAt(0).toLowerCase()}${transformed.substr(1)}`;
  }

  /**
   * Pluralizes words (blatant ripoff of Rails Inflector)
   * @param  {string} text
   * @return {string}
   */
  pluralize(text) {
    // Irregular
    if (/(person)/.test(text)) return text.replace('person', 'people');
    if (/(man)/.test(text)) return text.replace('man', 'men');
    if (/(child)/.test(text)) return text.replace('child', 'children');
    if (/(sex)/.test(text)) return text.replace('sex', 'sexes');
    if (/(move)/.test(text)) return text.replace('move', 'moves');
    if (/(zombie)/.test(text)) return text.replace('zombie', 'zombies');

    if (/(quiz)$/i.test(text)) return text.replace(/(quiz)$/i, '$1zes');
    if (/^(oxen)$/i.test(text)) return text.replace(/^(oxen)$/i, '$1');
    if (/^(ox)$/i.test(text)) return text.replace(/^(ox)$/i, '$1en');
    if (/^(m|l)ice$/i.test(text)) return text.replace(/^(m|l)ice$/i, '$1ice');
    if (/^(m|l)ouse$/i.test(text)) return text.replace(/^(m|l)ouse$/i, '$1ice');
    if (/(matr|vert|ind)(?:ix|ex)$/i.test(text)) return text.replace(/(matr|vert|ind)(?:ix|ex)$/i, '$1ices');
    if (/(x|ch|ss|sh)$/i.test(text)) return text.replace(/(x|ch|ss|sh)$/i, '$1es');
    if (/([^aeiouy]|qu)y$/i.test(text)) return text.replace(/([^aeiouy]|qu)y$/i, '$1ies');
    if (/(hive)$/i.test(text)) return text.replace(/(hive)$/i, '$1s');
    if (/(?:([^f])fe|([lr])f)$/i.test(text)) return text.replace(/(?:([^f])fe|([lr])f)$/i, '$1$2ves');
    if (/sis$/i.test(text)) return text.replace(/sis$/i, 'ses');
    if (/([ti])a$/i.test(text)) return text.replace(/([ti])a$/i, '$1a');
    if (/([ti])um$/i.test(text)) return text.replace(/([ti])um$/i, '$1a');
    if (/(buffal|tomat)o$/i.test(text)) return text.replace(/(buffal|tomat)o$/i, '$1oes');
    if (/(bu)s$/i.test(text)) return text.replace(/(bu)s$/i, '$1ses');
    if (/(alias|status)$/i.test(text)) return text.replace(/(alias|status)$/i, '$1es');
    if (/(octop|vir)i$/i.test(text)) return text.replace(/(octop|vir)i$/i, '$1i');
    if (/(octop|vir)us$/i.test(text)) return text.replace(/(octop|vir)us$/i, '$1i');
    if (/^(ax|test)is$/i.test(text)) return text.replace(/^(ax|test)is$/i, '$1es');
    if (/s$/i.test(text)) return text.replace(/s$/i, 's');
    if (/$/.test(text)) return text.replace(/$/, 's');
  }
}

