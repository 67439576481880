import { Component } from 'react';
import BlueArrowLeft from './images/blue-arrow-left.svg';

const CloseWrapper = ({ close }) => (
  <div className='dashoard-close-wrapper'>
    <div className='close close-text' onClick={ close }>
      <img src={ BlueArrowLeft } />
      <span>Back</span>
    </div>
  </div>
);

export default CloseWrapper;
