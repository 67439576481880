import { Component } from 'react';

export default class KillBlock extends Component {
  constructor( props ) {
    super( props );
    this.options = [
      "Bad - Don't Want to be Matched",
      'Bad - Agent',
      'Bad - Client has Already Transacted',
      'Bad - Currently Working with an Agent',
      'Bad - No Current Real Estate Needs',
      'Bad - Junk Info',
      'Bad - Max Requal Attempts',
      'Bad - Already Our Client',
      'Bad - Duplicate Submission'
    ];
    this.renderAgentInvitationCheckbox = this.renderAgentInvitationCheckbox.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.data.whitelabeled) {
      if (props.isCincLead) {
        return;
      }
      // This option should not exist for leads created by agents from whitelabeled partners.
      var idx = this.options.indexOf('Bad - Max Requal Attempts');
      if (idx > -1) this.options.splice(idx, 1);
    }
  }

  render() {
    return(
      <div className='dropdown_box red'>
        <div className='label'>
          Kill this Lead
        </div>
        <select value={ this.props.data.status_button.note_status } onChange={ this.handleChange.bind( this ) }>
          <option>Choose Status</option>
            <optgroup label='Kill'>
              { this.options.map( this._renderOption, this ) }
            </optgroup>
        </select>
        <div className={ this._noteStatus() ? 'dropdown_fields' : 'hidden' }>
          {
            this.props.isCompassLead
            ? null
            : (
                <div>
                  <input type='checkbox' name='conditional_requal_email' id='conditional_requal_email' onChange={this.onChange.bind(this)} />
                  <label htmlFor='conditional_requal_email'>Keep sending requal email</label>
                </div>
            )
          }

          { this.renderAgentInvitationCheckbox() }
        </div>
      </div>
    )
  }

  _noteStatus() {
    var show = true;
    var status = this.props.data.status_button.note_status === 'Bad - Junk Info';
    var hc = this.props.data.origin.indexOf( 'Home Captain' ) !== -1;
    var action = this.props.data.status_button.action === 'kill';

    if( action ){
      if( hc ) {
        if (this.props.data.qual_attempt_count >= 2) {
          show = true;
        } else if( !status ){
          show = false;
        }
      }
    } else {
      show = false;
    }

    return show;
  }

  _renderOption( option, i ) {
    return(
      <option key={ i } value={ option }>{ option }</option>
    )
  }

  onChange( e ) {
    this.props.data[ e.target.name ] = e.target.checked ? '1' : '0';
    this.props.data.lead.keep_sending_requal_email = e.target.checked ? '1' : '0';
    this.props.updateState( this.props.data );
  }

  handleChange( e ) {
    this.props.data.status_button.note_status = e.target.value;
    this.props.data.pmr_form.qual_status = e.target.value;
    this.props.data.status_button.action = 'kill';
    this.props.data.status_button.text = 'Kill This Lead';
    this.props.updateState( this.props.data );
  }

  renderAgentInvitationCheckbox() {
    if (this.props.data.status_button.note_status === 'Bad - Agent' && (this.props.data.render_agent_invitation_checkbox)) {
      return(
        <div>
          <input type='checkbox' name='send_agent_invitation' id='send_agent_invitation' onChange={ this.onChange.bind( this ) } />
          <label htmlFor='send_agent_invitation'>Send Agent Invitation email</label>
        </div>
      )
    }
  }
}
