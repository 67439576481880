import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import Fetch from '../../common/fetch';

import './styles.scss'

const RefundModal = (props) => {
  const {
    show,
    toggle,
    refundUrl,
    reloadTransactions,
    stripeChargeId,
  } = props;

  const [view, setView] = useState('initial');
  const [error, setError] = useState(null);
  const [comment, setComment] = useState('');

  const prompts = {
    initial: {
      header: 'Are You Sure?',
      message: "Clicking YES will refund the agent's card on file for this charge.",
    },
    reason: {
      header: 'Add Reason for Refund',
    },
    loading: {
      header: 'Sending request to stripe...'
    },
    success: {
      header: 'Success!',
      message: "A refund has been issued.  It may take a few days for the money to reach the agent's account"
    },
    error: {
      header: 'Error!',
      message: error,
    }
  };

  const {
    header,
    message,
  } = prompts[view];

  const resetPrompt = () => {
    setView('initial');
    setError(null);
    setComment('');
  }

  useEffect(resetPrompt, [show])

  const processRefund = async (e) => {
    const button = e.currentTarget;
    button.disabled = true;
    setView('loading');

    const resp = await Fetch.post({
      url: refundUrl,
      payload: {
        stripeChargeId,
        comment,
      }
    });

    setView(resp.success ? 'success' : 'error');

    if (resp.error) {
      setError(resp.error);
    } else {
      reloadTransactions();
    }

    button.disabled = false;
  }

  const handleChange = (e) => {
    setComment(e.currentTarget.value);
  }

  const footerContent = () => {
    switch(view) {
      case 'initial':
        return (
          <>
            <button
              type="button"
              className="default-update"
              onClick={toggle}
            >
              NO - CLOSE WINDOW
            </button>
            <button
              type="button"
              className="default-update inverse"
              onClick={setView.bind(null, 'reason')}
            >
              YES - REFUND CHARGE
            </button>
          </>
        );
      case 'reason':
        return (
          <>
            <button
              type="button"
              className="default-update"
              onClick={toggle}
            >
              CANCEL
            </button>
            <button
              type="button"
              className="default-update inverse"
              onClick={processRefund}
            >
              REFUND AGENT
            </button>
          </>
        );
      case 'success':
      case 'error':
      default:
        return (
          <button
            type="button"
            className="default-update"
            onClick={toggle}
          >
            Close
          </button>
        );
    }
  };

  return (
    <Modal
      id="refund-modal"
      status={show ? 'show' : 'hidden'}
      close={toggle}
      contentClass="refund-prompt"
    >
      <h2>{header}</h2>
      <p>{message}</p>
      {
        view === 'reason' && (
          <textarea
            placeholder="Add a note"
            maxLength="255"
            className="refund-comment"
            value={comment}
            onChange={handleChange}
          />
        )
      }
      <div className="bottom-footer-container">
        {footerContent()}
      </div>
    </Modal>
  )
};

export default RefundModal;
