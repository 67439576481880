import { Component } from 'react';
import Close from '../CloseWrapper';
import Confirm from '../modals/Confirm';
import { UnsavedChange } from '../ProductDashboard';

const dict = {
  company_brand: {
    name: 'Company Brand',
    placeholder: 'Company Brand',
    title: 'Add Company Brand',
  },
  designations: {
    name: 'Profile Designation',
    placeholder: 'Designation',
    title: 'Add Profile Designation',
  },
  specialties: {
    name: 'Profile Specialization',
    placeholder: 'Specialization',
    title: 'Add Profile Specialization',
  },
}

const initState = () => {
  const init = { error: { field: '', value: '' } };

  return Object.keys(dict)
               .reduce((acc, title) => {
                 acc[title] = '';
                 return acc;
               }, init);
};

function closeModal() {
  const closeModal = new CustomEvent('closeModal');
  const modalWrapper = document.querySelector('.product-dashboard-wrapper .dashboard-modal');

  modalWrapper.dispatchEvent(closeModal);
};

function onUnmount() {
  window.onbeforeunload = null;
  UnsavedChange.set(false);
}

const submit = async ({ name, value }) => {
  const url = 'product_dashboard/agents/create';
  const data = {
    field: name,
    value,
  };

  return fetch(
    url,
    {
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }
  )
    .then(response => response.json())
    .catch(() => { error: 'Please try again' });
};

function toggleOnbeforeunload(state) {
  const dirty = Object
                  .keys(dict)
                  .some(key => state[key].trim().length > 0);

  UnsavedChange.set(dirty);

  if (dirty) {
    window.onbeforeunload = function(e) {
      if (e) {
        e.preventDefault();
      }

      return 'show';
    };
  } else {
    window.onbeforeunload = null;
  }
}

function success({ name, state, value }) {
  const text = `Added ${ name }: ${ value }`;

  toggleOnbeforeunload(state);

  Confirm.modal('<img class="mb-15" src="images/product_dashboard/check-circle-solid.svg" /><h3>Input Added</h3>', {
    description: text,
    confirmLabel: 'OKAY',
    abortLabel: '',
    modalSize: 'modal-lg',
    textAlign: 'text-center',
    buttonSize: 'btn-lg'
  })
  .then(closeModal)
  .catch(closeModal);
}

export default class AddAgentCRMFields extends Component {
  constructor(props) {
    super(props);

    this.state = initState();

    this.confirm = this.confirm.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillUnmount() {
    onUnmount();
  }

  async confirm(e) {
    const { name } = e.target;
    const value = this.state[name];

    const text = `Click OKAY to add ${dict[name].name}: ${ value }`;

    function errorObj(err) {
      return { error: { field: name, value: err }};
    };

    Confirm.modal('<img class="mb-15" src="images/product_dashboard/yellow-alert.svg" /><h3>Are you sure?</h3>', {
      description: text,
      confirmLabel: 'OKAY',
      abortLabel: 'CANCEL',
      modalSize: 'modal-lg',
      textAlign: 'text-center',
      buttonSize: 'btn-lg'
    })
    .then(async () => {
      let result = initState();

      try {
        const response = await submit({ name, value });
        const { error } = response;

        if (error) {
          result = errorObj(error);
        } else {
          this.setState(result, () => {
            success({ name: dict[name].name, value, state: result });
          });
          return;
        }
      } catch ({ error }) {
        result = errorObj(error);
      }

      this.setState(result, closeModal);
    })
    .catch(closeModal);
  }

  onChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value }, () => toggleOnbeforeunload(this.state));
  }

  render() {
    const { close } = this.props;
    const { error } = this.state;

    return (
      <div className='add-agentcrm-fields'>
        {
          Object.keys(dict).map(key => {
            const placeholder = `Enter ${ dict[key].placeholder }`;
            const title = dict[key].title;
            const value = this.state[key];

            const buttonClasslist = [
              'btn',
              'btn-md',
              'btn-rex',
              'btn-orange',
              'search-button',
            ];

            if (!value) {
              buttonClasslist.push('disabled');
            }

            return (
              <div className='add-agentcrm-field dashboard-tool form-group' key={ key }>
                <div className='add-agentcrm-field-title'>
                  { title }
                </div>

                <div className='add-agentcrm-field-input input-group col-md-6 input-group-lg'>
                  <input
                    className='form-control'
                    onChange={ this.onChange }
                    placeholder={ placeholder }
                    name={ key }
                    value={ value }
                  />
                  <span className='input-group-btn'>
                    <button
                      className={ buttonClasslist.join(' ') }
                      name={ key }
                      onClick={ this.confirm }
                      disabled={ !value }
                    >
                      Save
                    </button>
                  </span>
                </div>

                { error.field === key &&
                  <div className='error-msg'>{ error.value }</div>
                }
              </div>
            );
          })
        }

      </div>
    );
  }
}
