import React from 'react';
import ReactDOM from 'react-dom';
import ProductSalesFormContainer from './Container';

/**
 * Initialize the ProductSalesForm tool.
 */

export function initialize(containerElement, props) {
  let component = React.createElement(ProductSalesFormContainer, props);
  ReactDOM.render(component, containerElement);
}
