import { Component } from 'react';

export default class FollowUpView extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.attachCalendar();
  }

  attachCalendar(){
    $(document).ready(() => {
      $('.datepicker').attachLinkedDateTimePicker();
      $('.datepicker').set6am();
      $('.ui-datepicker-trigger').hide();
      this.props.updateState({ followUpTime: $('#follow_up_datetime').val() });
      $(document).on('valueChange', '#follow_up_datetime', () => {
        this.props.updateState({ followUpTime: $('#follow_up_datetime').val() });
        $('#ui-datepicker-div').hide();
      });
    })
  }
  render(){
    let { state, updateState, loadPrevView, loadNextView } = this.props;
    return (
      <div className="content">
        <div className="brand-content">
          <div className="follow-up">
            <div className='follow-up-date-time'>
              <h3>Date/Time</h3>
              <input 
                id='follow_up_datetime'
                className='datepicker' 
                type='text'
              />
            </div>
            <div className='follow-up-message'>
              <h3>Message</h3>
              <textarea
                value={state.followUpMessage}
                onChange={(e) => updateState({ followUpMessage: e.currentTarget.value })}
              />
            </div>
          </div>
        </div>
        <div className="prompt-options">
          <div className="prompt-cancel" onClick={loadPrevView}>
            Back
          </div>
          <div className="prompt-confirm" onClick={loadNextView}>
            Next
          </div>
        </div>
      </div>
    )
  }
}