import React, { useState, useEffect } from 'react';

const Toggle = (props) => {
  const {
    handleEvent,
    salesAttempts,
    setHistory,
    showHistoryTable,
    toggleHandleEvent,
    toggleHistoryDisplay,
  } = props;

  const [historyCount, setHistoryCount] = useState(salesAttempts || 0);
  const [historyTabDisabled, setHistoryTabDisabled] = useState('disabled');
  const [fetched, setFetched] = useState(false);
  const [faIcon, setFaIcon] = useState('fa-angle-double-down');

  useEffect(() => {
    if (historyCount > 0) setHistoryTabDisabled('');
  });

  useEffect(() => {
    if (handleEvent) {
      showHistoryTable ? getHistory() : setFetched(false);
      showHideHistoryTabUpdated();
      toggleHandleEvent();
    }
  }, [handleEvent]);

  function showHideHistoryTabUpdated() {
    const showHideHistoryTab = document.querySelector('.product-sales-history ul.show-hide-history');

    setHistoryCount(historyCount + 1);

    if (showHideHistoryTab) {
      showHideHistoryTab.classList.add('success');
      setTimeout(() => { showHideHistoryTab.classList.remove('success'); }, 1500);
    }
  }

  function getHistory() {
    const {
      action,
      method,
      params,
    } = props;

    fetch(
      `${action}?${params}`,
      {
        method: method,
        credentials: 'same-origin',
        headers: {
          'Content-type': 'application/json',
        }
      }
    )
    .then(response => response.json())
    .then(response => {
      setFetched(response.success);

      if (response.success) {
        setHistoryCount(response.logs.length);
        setHistory(response.logs);
      } else {
        throw new Error(response.errors.join(', '));
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  function handleIconToggle() {
    const icon = showHistoryTable ? 'down' : 'up';

    setFaIcon(`fa-angle-double-${icon}`);
  }

  function toggleHistory() {
    if (!fetched) getHistory();

    if (historyCount > 0) {
      toggleHistoryDisplay();
      handleIconToggle();
    }
  }

  return (
    <ul className={ `show-hide-history ${historyTabDisabled}` }>
      <li onClick={toggleHistory} >
        Product Sales History
        <span className='history-count ml-5'>{ historyCount }</span>
        <i className={ `fa pull-right ${faIcon}` }></i>
      </li>
    </ul>
  );
};

export default Toggle;
