import React, { Component } from 'react';
import { connect } from 'react-redux';
import View from './view';

function mapStateToProps(state) {
  return {
    info: {
      id: state.info.get('id'),
      lead_type: state.info.get('lead_type'),
    },
    submitting: state.actions.get('submitting'),
  };
}

class Info extends Component {
  render() {
    const { info, submitting } = this.props;

    const data = { info, submitting };

    return (
      <View
        data={ data }
      />
    );
  }
}

export default connect(mapStateToProps)(Info);
