import React from 'react';
import ReactDOM from 'react-dom';
import DialerRecruitTopAgentsQueue from './Container';

/**
 * Initialize the DialerRecruitTopAgentsQueue modal.
 */

export function initialize(containerElement, props) {
  let component = React.createElement(DialerRecruitTopAgentsQueue, props);
  ReactDOM.render(component, containerElement);
}
