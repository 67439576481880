import { Component } from 'react';

export default class LeadStatus extends Component {
  constructor(props){
    super(props)
  }

  selectStatus(status) {
    this.props.updateLeadStatus(status);
  }

  capitalizeFirstLetter(string) {
    return string.split('_').map((str) => { return str.charAt(0).toUpperCase() + str.slice(1); }).join(' ');
  }

  statusElement(status) {
    return <div className="full-button-wrapper">
      <label className={"button-label " + (this.props.leadStatus === status ? 'label-highlight' : '')} onClick={() => this.props.handleLeadStatusChange(status)}>
        {this.capitalizeFirstLetter(status)}
      </label>
    </div>
  }

  renderStatusOptions() {
    const { matchedAgentIds = [] } = this.props;

    if (matchedAgentIds.length == 0) {
      return <form>
        { this.statusElement('fresh') }
        { this.statusElement('hightouch') }
        { this.statusElement('matching') }
        { this.statusElement('dead') }
      </form>
    } else if (matchedAgentIds.length == 1) {
      return <form>
        { this.statusElement('pending') }
        { this.statusElement('is_client') }
      </form>
    } else {
      return <form>
        { this.statusElement('pending') }
      </form>
    }
  }

  render() {
    let { leadStatus, handleLeadStatusChange} = this.props;
    return(
      <div className='row'>
        { this.renderStatusOptions() }
      </div>
    )
  }
}
