import React, { useState } from 'react';
import Modal from '../../Modal';
import Fetch from '../../../common/fetch';

import { altosTableHeaders, apAgentsTableHeadersSelectable } from '../shared/tables';
import searchListener from '../shared/searchListener';

const AddAltosStreamModal = (props) => {
  const {
    altosStreamModalStatus,
    altosStreams,
    altosTableRows,
    apAgentsSearchPath,
    apListingSearchPath,
    closeModal,
    rexAgents,
    updateState,
  } = props;

  const addEnterKeyListener = () => {
    document.addEventListener('keydown', searchListener);
  }

  const removeEnterKeyListener = () => {
    document.removeEventListener('keydown', searchListener);
  }

  const [newStreams, setNewStreams] = useState([]);
  const [apAgents, setApAgents] = useState([]);
  const [searchView, setSearchView] = useState('agents');

  const showAltosStreams = () => setSearchView('altos');
  const showAgents = () => setSearchView('agents');

  const close = () => {
    closeModal();
    setNewStreams([]);
    setApAgents([]);
    setSearchView('altos');
  }

  const search = async (e) => {
    const target = e.currentTarget;
    const search = document.getElementById('altos-search-field').value

    target.disabled = true;
    target.classList.add('submitting');

    const res = await Fetch.get({
      url: apListingSearchPath,
      params: {
        search,
        exclusions: altosStreams.map((a) => a.id),
      }
    })

    const listingsPresent = res.apListings && res.apListings.length;
    const apAgentsPresent = res.apAgents && res.apAgents.length;

    if (listingsPresent || apAgentsPresent) {
      if (listingsPresent) {
        setNewStreams(res.apListings);
      }

      if (apAgentsPresent) {
        setApAgents(res.apAgents);
      }

    } else if (res.error) {
      alert(res.error);
    } else {
      alert('Search could not be completed or returned nothing.');
    }

    target.disabled = false;
    target.classList.remove('submitting');
  }

  const handleCheck = (e) => {
    const updatedStreams = [...newStreams];
    const {
      checked,
      dataset: {
        index,
      },
    } = e.currentTarget;

    updatedStreams[parseInt(index, 10)].selected = checked;

    setNewStreams(updatedStreams);
  }

  const handleApAgentsCheck = (e) => {
    const updatedStreams = [...apAgents];
    const {
      checked,
      dataset: {
        index,
      },
    } = e.currentTarget;

    updatedStreams[parseInt(index, 10)].selected = checked;

    setApAgents(updatedStreams);
  }

  const addNewStreams = async (e) => {
    const target = e.currentTarget;

    target.disabled = true;
    target.classList.add('submitting');

    const selectedStreams = newStreams.filter(stream => stream.selected);

    const canonicalAids = apAgents
      .filter(agent => agent.selected)
      .map(({ canonicalAid }) => canonicalAid)

    const selectedRexAgents = [];

    const res = await Fetch.post({
      url: apAgentsSearchPath,
      payload: {
        canonicalAids,
      }
    });

    for (let i = 0; i < res.length; i += 1) {
      selectedStreams.push(...res[i].altosStreams);
      selectedRexAgents.push(...res[i].rexAgents);
    }

    updateState({
      altosStreams: [
        ...altosStreams,
        ...selectedStreams,
      ],
      rexAgents: [
        ...rexAgents,
        ...selectedRexAgents,
      ]
    })

    close();
  }

  const apAgentsTableRows = (rows, handleCheck) => {
    return (
      <>
        {
          rows.map(({
            name,
            brokerage,
            location,
            urlSlug,
            agentId,
            selected,
          }, idx) => (
            <div
              key={`${name}-${idx}`}
              className="form-table-row"
            >
              <div className="table-column col-width-5">
                <input
                  type="checkbox"
                  data-index={idx}
                  onChange={handleCheck}
                  checked={selected}
                />
              </div>
              <div className="table-column col-width-20">
                <span className="font-2">{name}</span>
              </div>
              <div className="table-column col-width-15">
                <span className="font-1">{agentId}</span>
              </div>
              <div className="table-column col-width-10">
                <span className="font-1">{urlSlug}</span>
              </div>
              <div className="table-column col-width-25">
                <span className="font-1">{brokerage}</span>
              </div>
              <div className="table-column col-width-25">
                <span className="font-1">{location}</span>
              </div>
            </div>
          ))
        }
      </>
    )
  }

  const altosDetailsClasslist = ['altos-search-details'];
  const rexDetailsClasslist = ['altos-search-details'];
  const altosTabClasslist = ['profile-edit-button'];
  const rexTabClasslist = ['profile-edit-button'];

  if (searchView === 'agents') {
    altosDetailsClasslist.push('hidden');
    rexTabClasslist.push('highlighted');
  } else {
    rexDetailsClasslist.push('hidden');
    altosTabClasslist.push('highlighted');
  }

  return (
    <Modal
      close={close}
      id="altos-search-modal"
      status={altosStreamModalStatus}
    >
      <h2>Search Agent Data</h2>
      <div className="altos-search">
        <input
          id="altos-search-field"
          type="text"
          placeholder="Enter name or property address"
          onFocus={addEnterKeyListener}
          onBlur={removeEnterKeyListener}
        />
        <button
          id="rea-search-button"
          className="profile-edit-button submit light"
          onClick={search}
        >
          <i className="fas fa-sync-alt fa-spin" />
          <span className="submit-text">Search</span>
        </button>
      </div>

      {
        (newStreams.length > 0 || apAgents.length > 0) && (
          <div className="altos-search-tabs">
            <button
              type="button"
              className={rexTabClasslist.join(' ')}
              onClick={showAgents}
            >
              {`Show Agents (${apAgents.length})`}
            </button>
            <button
              type="button"
              className={altosTabClasslist.join(' ')}
              onClick={showAltosStreams}
            >
              {`Show Altos Streams (${newStreams.length})`}
            </button>
          </div>
        )
      }
      <div className={altosDetailsClasslist.join(' ')}>
        {
          newStreams.length > 0 && (
            <div className="form-table">
              {altosTableHeaders()}
              <div className="form-table-data">
                {altosTableRows(newStreams, handleCheck)}
              </div>
              <div className="form-table-bottom-utilities">
                <button
                  type="button"
                  className="profile-edit-button"
                  onClick={addNewStreams}
                >
                  ADD TO CLUSTERS
                </button>
              </div>
            </div>
          )
        }
      </div>
      <div className={rexDetailsClasslist.join(' ')}>
        {
          apAgents.length > 0 && (
            <div className="form-table">
              {apAgentsTableHeadersSelectable()}
              <div className="form-table-data">
                {apAgentsTableRows(apAgents, handleApAgentsCheck)}
              </div>
              <div className="form-table-bottom-utilities">
                <button
                  type="button"
                  className="profile-edit-button"
                  onClick={addNewStreams}
                >
                  ADD TO CLUSTERS
                </button>
              </div>
            </div>
          )
        }
      </div>
    </Modal>
  )
}

export default AddAltosStreamModal;
