import React from 'react';

import './styles.scss';

const createLead = () => {
  const newLeadFromLeadModal = document.getElementById('new-lead-modal');
  if (newLeadFromLeadModal) {
    newLeadFromLeadModal.classList.remove('hidden');
  }
};

const renderCreateLead = ({ lead, live }) => {
  const {
    crm_link,
    rex_referral,
  } = lead;

  const { live_lead } = live;

  if (rex_referral && !live_lead) {
    return(
      <div className='create_lead'>
        <a href={ crm_link } target='_blank' style={{textDecoration: 'none'}}>
          Create Lead in CRM +
        </a>
      </div>

    );
  } else {
    return(
      <div className='create_lead' onClick={ createLead } >
        Create Lead +
      </div>
    );
  }
};

const Introduction = ({ introduction }) => {
  const { greeting, copy } = introduction;
  return (
    <div className='script-section script-introduction'>
      <div className='script-copy'>
        <div className={ greeting ? 'greeting' : 'hidden' } dangerouslySetInnerHTML={{ __html: greeting || '' }}></div>
        <div className='copy' dangerouslySetInnerHTML={{ __html: copy }}></div>
      </div>
    </div>
  );
};

const Questions = (data) => {
  const { lead, live } = data;
  const {
    bonus,
    copy,
    questions,
  } = data.questions;

  if (!questions) {
    return null;
  }

  return(
    <div className='script-section script-questions'>
      <div className='script-copy'>
        <div className='copy'>
          <div dangerouslySetInnerHTML={{ __html: copy }}></div>
          <div className='question-list'>
            <ul>
              {
                questions.map((q,i) => {
                  return (
                    <li key={`q-${ i }`}>
                      <span className='list-item-bullet'>-</span>
                      { q }
                    </li>)
                })
              }
            </ul>
          </div>
          { bonus &&
            <div className='bonus-section'>
              <span className='bonus-label'>BONUS:</span>
              <span dangerouslySetInnerHTML={{ __html: bonus }}></span>
              { renderCreateLead({ lead, live }) }
            </div>
          }
        </div>
      </div>
    </div>
  )
};

const Closing = ({ closing }) => {
  const { copy } = closing;

  if (!copy) {
    return null;
  }

  return (
    <div className='script-section script-closing'>
      <div className='script-copy'>
        <div className='copy' dangerouslySetInnerHTML={{ __html: copy }}></div>
      </div>
    </div>
  );
};

const Scripts = ({ scripts, lead, live }) => {
  const toggleScript = (e) => {
    const controls = document.getElementById('active-dialer-controls');
    const parent = e.target.parentNode;
    const operation = parent.classList.contains('expanded') ? 'remove' : 'add';
    parent.classList[operation]('expanded');
    controls.classList[operation]('script-open');
  };

  if (scripts) {
    const controls = document.getElementById('active-dialer-controls');
    controls.classList.add('script-enabled');
  }

  return (
    <div className='active-dialer-scripts'>
      <button disabled={ !scripts } onClick={ toggleScript }>Show Script</button>
      {
        scripts &&
        <div className='dialer-script'>
          <Introduction introduction={ scripts.introduction } />
          <Questions questions={ scripts.questions } lead={ lead } live={ live } />
          <Closing closing={ scripts.closing } />
        </div>
      }
    </div>

  );
};


export default Scripts;
