import  { Component } from  'react';
import mainIcon from '../images/call-failed.svg';

export default class AgentUnavailable extends Component {
  render(){
    return (
      <div className="button agent-unavailable">
        <div className='row'>
          <div className='col-sm-2'>
            <img src={ mainIcon }/>
          </div>
          <div className='col-sm-6'>
            <div className='row status-text top-row'>
              Agent Unavailable
            </div>
          </div>
        </div>
      </div>
    );
  }
}
