const Verification = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <h1 className="mt-0">Verification Results</h1>
        <p>&nbsp;</p>
      </div>
    </div>
  );
};

export default Verification;
