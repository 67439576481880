import React, { Component } from 'react';
import { connect } from 'react-redux';
import View from './view';
import { store } from '../../index';

import { getStateProps } from '../../utils';

import BaseGoogleSearch from './index';

export default class SellerGoogleSearch extends BaseGoogleSearch {
  constructor(props) {
    super(props);

    const { properties } = props;
    this.state = this.getCurrentStateFromStore(properties);
  }

  componentDidMount() {
    this.unsubscribeStore = store.subscribe(this.updateStateFromStore.bind(this));
  }

  componentWillUnmount() {
    this.unsubscribeStore();
  }

  getCurrentStateFromStore(properties) {
    const values = properties.reduce((acc, prop) => {
      const property = store.getState().info.get(prop);
      if (property) {
        acc.push(property);
      }
      return acc;
    }, []);
    return { values };
  }

  updateStateFromStore() {
    const { properties } = this.props;
    const currentState = this.getCurrentStateFromStore(properties);

    if (this.state !== currentState) {
      this.setState(currentState);
    }
  }
}
