import React from 'react';

const LeadType = ({ leadType, handleLeadTypeChange, isExclusive, handleIsExclusive }) => (
  <div>
    <div className='row'>
      <form>
        <div className="button-wrapper">
          <label
            className={"button-label " + (leadType === 'buyer' ? 'label-highlight' : '')}
            htmlFor='new_lead_type_buyer'
            onClick={() => handleLeadTypeChange('buyer')}
          >
            Buyer
          </label>
        </div>
        <div className="button-wrapper">
          <label
            className={"button-label right-button " + (leadType === 'seller' ? 'label-highlight' : '')}
            onClick={() => handleLeadTypeChange('seller')}
          >
            Seller
          </label>
        </div>
      </form>
    </div>
    <div className='row'>
      <form>
        <div>
        <label className="exclusive-lead-checkbox-label">
          <input
            type="checkbox"
            checked={isExclusive}
            onChange={(e) => handleIsExclusive(e)}
            className="exclusive-lead-checkbox"
          />
          Exclusive?
        </label>
        </div>
      </form>
    </div>
  </div>
);

export default LeadType;
