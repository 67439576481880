import String from '../../../../../../common/String.js'

const string = new String();

const InputField = props => {
  const {
    fieldValue,
    fieldName: fldName,
    fieldPrefix,
    fieldTitle,
    required,
    placeholder,
    updateFieldHandler,
  } = props;

  const fieldName = fldName || string.snakeCase(fieldTitle.replace(/[^\w\s]/g, ""));
  const name = !!fieldPrefix ? `${fieldPrefix}[${fieldName}]` : fieldName;
  const id = string.snakeCase(name);

  const labelText = () => {
    return required ? `${fieldTitle}*` : fieldTitle;
  };

  const updateField = event => {
    const { value } = event.target;
    const data = !!fieldPrefix ? { [fieldPrefix]: { [fieldName]: value } } : { [fieldName]: value };

    updateFieldHandler(data);
  };

  return (
    <div className="input-group">
      <label htmlFor={ id }>
        { fieldTitle && <span className="label-text">{ labelText() }</span> }
        <textarea id={ id } name={ name } placeholder={ placeholder } onChange={ updateField } value={ fieldValue } />
      </label>
    </div>
  );
};

export default InputField;
