import AlertIcon from '../../../../LiveAccountManagement/images/alert.png';

const Warning = (props) => {
  const {
    action,
    toggleButtons,
    moveTo,
    primeSubscriptionPath,
    newProductOfferingId,
    toggleModal,
    setAttribute,
    setAndGo,
  } = props;

  const disableSubmit = (e) => {
    toggleButtons(e, 'disable');
    const data = JSON.stringify({ action, product_offering_id: newProductOfferingId });

    fetch(
      primeSubscriptionPath,
      {
        method: 'PUT',
        credentials: 'same-origin',
        headers: {
          'Content-type': 'application/json',
        },
        body: data,
      }
    )
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setAttribute('currentProductOfferingId', newProductOfferingId)
          moveTo('success');
        } else {
          throw new Error(response.errors);
        }
      })
      .catch(error => {
        setAndGo('error', error.message, 'fail');
      });
  };

  return (
    <div id='disable-warning'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={AlertIcon} />
      </div>

      <div className='rex-modal-heading'>
        Are You Sure?
      </div>

      <div className='rex-modal-contents'>
        <div className='rex-modal-subheading'>
          <p className='center'>
            Confirming will IMMEDIATELY change the agent’s PRIME subscription package and
            charge the agent immediately as if they purchased this package originally.
          </p>
        </div>

        <div className='rex-modal-actions'>
          <button
            className='rex-modal-action rex-modal-action__loading'
            onClick={disableSubmit}
          >
            CONFIRM
          </button>
          <button
            className='rex-modal-action'
            onClick={() => toggleModal(false)}
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  )
};

export default Warning;
