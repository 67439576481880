import React from 'react';

import Caret from './images/caret.png';

const LiveLeadInfo = ({ state, setState, checkActiveCallStatus }) => {
  let {
    firstName,
    lastName,
    email,
    phoneNumber,
    reason,
    liveAgentId,
    liveAgentName,
    leadServiceType,
    liveAgentSearch,
    submittingToQual,
    leadType,
    rental,

    onActivePhoneCall,
    activePhoneCallDetails
  } = state;

  function handleChange(e) {
    const { field } = e.target.dataset;
    const { value } = e.target;

    setState({ [field]: value })
  }

  function handleSearchClick() {
    if (liveAgentSearch === '') return;
    setState({
      currentPage: 'liveAgentSearch',
      liveAgentSearchState: 'searching',
      liveAgentSearchResults: []
    });
    searchAgent();
  }

  function setReason(reason) {
    setState({
      reason,
    });
    showCompassDropdown(false);
  }

  const reasons = [
    'Specific agent',
    'Specific listing',
    'No specific agent or listing',
    'Compass Concierge',
    'Compass Sell',
    'Compass Coming Soon',
    'Compass Private Exclusives'
  ];

  function micrositeMapping(micrositeId, resultFor) {
    return {
      880: {
        partnerName: 'cinc',
        RexProductOfferingId: 8
      },
      899: {
        partnerName: 'compass',
        RexProductOfferingId: 10
      },
      705: {
        partnerName: 'rex',
        RexProductOfferingId: 7
      }
    }[micrositeId][resultFor]
  }

  function searchAgent(){
    let params;
    let baseOptions;

    let searchType = checkType(liveAgentSearch);

    if (searchType === 'phone') {
      liveAgentSearch = liveAgentSearch.replace(/[^0-9]/g, '')
    }

    if (searchType === 'name') {
      let [first_name, last_name] = liveAgentSearch.split(' ');
      baseOptions = { first_name, last_name };
    } else {
      baseOptions = { [searchType]: liveAgentSearch };
    }

    params = {
      search_params: {
        search_type: 'advanced_search',
        advanced_search_options: {
          ...baseOptions,
          with_rex_product_offering: micrositeMapping(leadServiceType, 'RexProductOfferingId'),
        }
      }
    }

    Util.searchAgents(params)
      .then((results) => {
        setState({
          liveAgentSearchResults: results,
          liveAgentSearchState: 'displaying',
        })
      });
  }

  function clearLiveAgent() {
    setState({
      liveAgentId: '',
      liveAgentName: '',
    })
  }

  const isCompass = leadServiceType === '899';

  const isBuyer = leadType === 'buyer';

  function showCompassDropdown(bool) {
    const compassDropdown = document.getElementById('compass-dropdown');
    const method = bool ? 'add' : 'remove';

    compassDropdown.classList[method]('active');
  }

  /* render call linking view */
  let phoneCallStatus;
  if(onActivePhoneCall) {
    phoneCallStatus = <div className='col-sm-5 right-column'>
      <label className='field-label'>Link Active Call</label>
      <p className="text-success">On the phone with { activePhoneCallDetails.number }.</p>
    </div>
  } else {
    phoneCallStatus = <div className='col-sm-5 right-column'>
      <label className='field-label'>Link Active Call</label>
      <p>You are not currently on the phone.</p>
      <button className="btn btn-primary" onClick={ checkActiveCallStatus }>Check Again</button>
    </div>
  }

  return (
    <div className='fieldset'>
      <div className='row'>
        <div className='col-sm-5 left-column'>
          <label className='field-label'>First Name<span className='required-field'>*</span></label>
          <input
            data-field="firstName"
            type='text'
            value={firstName || ''}
            onChange={handleChange}
          />
        </div>
        <div className='col-sm-5 right-column'>
          <label className='field-label'>Last Name<span className='required-field'>*</span></label>
          <input
            data-field="lastName"
            type='text'
            value={lastName || ''}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-5 left-column'>
          <label className='field-label'>Email<span className='required-field'>*</span></label>
          <input
            data-field="email"
            type='text'
            value={email || ''}
            onChange={handleChange}
          />
        </div>
        <div className='col-sm-5 right-column'>
          <label className='field-label'>Phone Number<span className='required-field'>*</span></label>
          <input
            data-field="phoneNumber"
            type='text'
            value={phoneNumber || ''}
            onChange={handleChange}
          />
        </div>
      </div>
      { isCompass &&
        <div className='row'>
          <div className='col-sm-5 left-column'>
            <label className='field-label'>What is the lead calling about?<span className='required-field'>*</span></label>
            <div
              id='compass-dropdown'
              className='select-menu-container'
              onMouseEnter={ () => showCompassDropdown(true) }
              onMouseLeave={ () => showCompassDropdown(false) }
            >
              <div className='select-menu-option select-menu-option__active'>{ reason || 'Select' }</div>
              <div className='select-menu-direction'>
                <img src={ Caret } />
              </div>
              <div className='select-menu-content'>
                {
                  reasons.map((reason,i) =>
                    <button
                      className='select-menu-option'
                      key={`reason-${i}`}
                      onClick={ () => setReason(reason) }>
                        { reason }
                    </button>
                  )
                }
              </div>
            </div>
          </div>
          {
            isBuyer &&
            <div className='col-sm-5 right-column'>
              <label className='field-label'>Is this a rental client?<span className='required-field'>*</span></label>
              <div id='compass-rental-client'>
                <div className='rental-client-selection'>
                  <input
                    type='radio'
                    value='true'
                    checked={rental === 'true'}
                    data-field='rental'
                    onChange={handleChange}
                  />
                  <label>Yes</label>
                </div>
                <div className='rental-client-selection'>
                  <input
                    type='radio'
                    value='false'
                    checked={rental === 'false'}
                    data-field='rental'
                    onChange={handleChange}
                  />
                  <label>No</label>
                </div>
              </div>
            </div>
          }
        </div>
      }
      <div className='row'>
        <div className='col-sm-5 left-column'>
          <h2 className='page-title'>{`Search for ${micrositeMapping(leadServiceType, 'partnerName')} Agent`}</h2>
          <br />
          <div className='agent-search-box'>
            <input
              data-field="liveAgentSearch"
              type='text'
              value={liveAgentSearch || ''}
              onChange={handleChange}
            />
            <div
              className='agent-search-button'
              onClick={handleSearchClick}
            >
              <i className="fa fa-search" />
            </div>
          </div>
          <br />
          {liveAgentId !== '' &&
            <div className="live-agent">
              <div className="live-agent-col">
                <label>Name</label>
                <span>{liveAgentName}</span>
              </div>
              <div className="live-agent-col">
                <label>Agent ID</label>
                <span>{liveAgentId}</span>
              </div>
              <div className="live-agent-col">
                <div className='live-agent-clear' onClick={clearLiveAgent}>Clear</div>
              </div>
            </div>
          }
        </div>

        { phoneCallStatus }
      </div>
      <div className={submittingToQual ? 'loading-screen' : 'hidden'}>
        Creating Lead
          <i className="fa fa-cog fa-spin fa-2x fa-fw" aria-hidden="true"></i>
        <div id='particle-wrapper'></div>
      </div>
    </div>
  );
}

export default LiveLeadInfo;
