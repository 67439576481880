const PHONE_REGEX = /^(\+?\d{1,3}[.-\s]?)?\(?([0-9]{3})\)?[.-\s]?([0-9]{3})[.-\s]?([0-9]{4})$/;
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const cleanInput = clone => {
  const input = clone.querySelector('input');
  const btnRemove = clone.querySelector('.remove-input');
  const btnAdd = clone.querySelector('.add-input');

  input.value = '';
  input.addEventListener('input', handleInput);
  btnAdd.addEventListener('click', toggleInput);
  btnRemove.addEventListener('click', toggleInput);

  clone.classList.remove('has-error');
  btnRemove.classList.remove('disabled');
};

const addInput = target => {
  const container = document.getElementById('dialer-recruit-top-agents-research-container');
  const formGroup = target.closest('.form-group');
  const parent = formGroup.closest('.agent-contacts');
  const clone = formGroup.cloneNode(true);

  container.dispatchEvent(new CustomEvent('agent_research.needs_verification'));

  cleanInput(clone);

  parent.append(clone);
};

const removeInput = target => {
  const formGroup = target.closest('.form-group');

  formGroup.remove();
};

const toggleInput = event => {
  const target = event.currentTarget;

  if (target.classList.contains('disabled')) return;
  if (target.classList.contains('add-input')) addInput(target);
  if (target.classList.contains('remove-input')) removeInput(target);
};

const validInput = target => {
  const parent = target.closest('.agent-contacts');
  const { contactType } = parent.dataset;

  if (!target.value) return true;
  if (/phones/.test(contactType)) return PHONE_REGEX.test(target.value);
  if (/emails/.test(contactType)) return EMAIL_REGEX.test(target.value);
};

const handleInput = event => {
  const { currentTarget } = event;
  const formGroup = currentTarget.closest('.form-group');
  const container = document.getElementById('dialer-recruit-top-agents-research-container');
  const action = validInput(currentTarget) ? 'remove' : 'add';

  container.dispatchEvent(new CustomEvent('agent_research.needs_verification'));
  formGroup.classList[action]('has-error');
};

const Input = () => {
  return (
    <div className="form-group">
      <div className="input-group">
        <input type="text" className="form-control" onChange={ handleInput } />
        <div className="input-group-addon remove-input disabled" onClick={ toggleInput }>
          <i className="fa fa-minus"></i>
        </div>
        <div className="input-group-addon add-input" onClick={ toggleInput }>
          <i className="fa fa-plus"></i>
        </div>
      </div>
    </div>
  );
};

export default Input;
