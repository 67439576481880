import { Component }  from 'react';

import PageTitle from './PageTitle';
import ToolSelector from './ToolSelector';
import ToolsList from './data/ToolsList';
import ToolWrapper from './ToolWrapper';

const initState = {
  pageTitle: 'Product Admin Dashboard',
  selected: null,
};

class UnsavedChanges {
  constructor() {
    this.hasUnsavedChanges = false;
  }

  set(val) {
    this.hasUnsavedChanges = val;
  }

  get() {
    return this.hasUnsavedChanges;
  }
}

const UnsavedChange = new UnsavedChanges();

export { UnsavedChange };

export default class ProductDashboard extends Component {
  constructor() {
    super();

    this.state = initState;

    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  open(item) {
    const { title } = item;

    this.setState({
      selected: item,
      pageTitle: title,
    });
  }

  close() {
    if (!UnsavedChange.get()) {
      this.setState(initState);
      return;
    }

    const shouldClose = window.confirm('Changes you made may not be saved');

    if (shouldClose) {
      this.setState(initState);
    }
  }

  render() {
    const { pageTitle, selected } = this.state;

    return (
      <div className='content'>
        <div className='dashboard-modal'></div>

        <PageTitle
          title={ pageTitle }
          selected={ selected }
          close={ this.close }
        />

        {
          !selected ?
            <div>
              {
                ToolsList.map((item, index) => (
                  <ToolSelector
                    key={`select-${index}`}
                    item={item}
                    openTool={this.open.bind(this, item)}
                  />
                ))
              }
            </div>
          :
            <ToolWrapper>
              <selected.tool close={this.close}/>
            </ToolWrapper>
        }
      </div>
    );
  }
}
