import { Component } from 'react';

export default class TagAgentView extends Component {
  constructor(props) {
    super(props);

    this.loadNextView = this.loadNextView.bind(this);
  }

  loadNextView(){
    let { state, updateState } = this.props;
    if (state.taggedAgentId === '') return;

    updateState({ workingListView: 'tagAgentFollowUp' });
  }

  render() {
    let { state, updateState } = this.props;
    return (
      <div className="content">
        <div className='list-buttons'>
          <input
            type='text'
            placeholder='Enter Agent ID...'
            value={state.taggedAgentId}
            onChange={(e) => updateState({ taggedAgentId: e.currentTarget.value })}
          />
          <input
            type='button'
            className='monitoring-button'
            value='Submit'
            onClick={this.loadNextView}
          />
        </div>
      </div>
    )
  }
}
