import React from 'react';
import GoogleIcon from '../../images/google-plus-square@2x.png';

import './styles.scss';

const GoogleSearch = ({ data }) => {
  const query = data.join('+');
  const href = `https://www.google.com/search?q=${ query }`;

  return (
    <div className='google-link-container'>
      <a
        className='google-link'
        href={ href }
        target='_blank'
      >
        <img className='google-icon' src={ GoogleIcon } />
      </a>
    </div>
  );
};

export default GoogleSearch;
