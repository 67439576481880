import { Component } from 'react';
import request from 'superagent';

export default class WishlistRow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      agentContext: this.props.wish.agent_context || '',
      edittingTextArea: false,
      showSaveIcon: false
    }

    this.changeAgentContext = this.changeAgentContext.bind(this)
    this.saveAgentContext = this.saveAgentContext.bind(this)
    this.enterTextArea = this.enterTextArea.bind(this)
    this.leaveTextArea = this.leaveTextArea.bind(this)
    this.saveButtonClass = this.saveButtonClass.bind(this)
  }

  changeAgentContext(e) {
    this.setState({ agentContext: e.currentTarget.value })
  }

  saveAgentContext(){
    if(this.props.wish.agent_context !== this.state.agentContext){
      let options = {
        wishlist_id: this.props.wish.id,
        agent_context: this.state.agentContext
      }
      request
        .post("/agent_insights/agent_wishlist/update_agent_context")
        .query(options)
        .then(() => this.setState({ showSaveIcon: false }))
        .catch(() =>
          alert("An error has occured while saving this suggestion.")
        );
    } else {
    }
  }

  enterTextArea() {
    this.setState({ showSaveIcon: true })
  }

  leaveTextArea(event) {
    let e = event.relatedTarget;
    if (e.classList && e.classList.contains('wishlist-context-button')) return;

    this.setState({ showSaveIcon: false });
  }

  saveButtonClass(){
    return this.state.showSaveIcon ? "wishlist-context-button" : "wishlist-context-button hidden";
  }

  render() {
    let { wish, removeWish, loadWorkingListModal } = this.props;
    let { showSaveIcon, agentContext } = this.state;
    let location = [wish.city, wish.state, wish.zip].filter(Boolean).join(', ');

    return(
      <div id={"wish-list-" + wish.id} className="list-item" style={{ height: 100 }}>
        <div className="list-item-centered-detail w250" style={{ marginTop: 5, marginLeft: 5 }}>
          <span><b>Name: {wish.first_name} {wish.last_name}</b></span><br />
          <span>Phone: {wish.phone}</span><br />
          <span>Email: {wish.email}</span><br />
          <span>Location: {location}</span><br />
        </div>
        <div className="list-item-centered-detail w165" style={{ marginTop: 5, marginLeft: 5 }}>
          <span><b>Brokerage: {wish.broker}</b></span><br />
          <span>Prior Relationshp: {wish.prior_relationship}</span><br />
          <span>Sales Executive: {wish.potential_sales_executive_name}</span><br />
        </div>
        <div className="list-item-centered-detail flex-centered w195">
          <button
            className="working-list-button"
            value="Working List"
            onClick={() => loadWorkingListModal(wish)}
          >
            Working List &nbsp;
            <i className="fa fa-plus" />
          </button>
          <button
            className="working-list-button"
            style={{ borderColor: 'red', color: 'red' }}
            value="Working List"
            onClick={() => removeWish(wish.id)}
          >
            Remove Sug. &nbsp;
            <i className="fa fa-times-circle fa" />
          </button>
        </div>
        <div className="list-item-centered-detail w560">
          <textarea
            className={showSaveIcon ? 'wishlist-context glow' : 'wishlist-context'}
            value={agentContext}
            onChange={this.changeAgentContext}
            onClick={this.enterTextArea}
          />
          <i className={'fa fa-floppy-o ' + this.saveButtonClass()} onClick={this.saveAgentContext} />
        </div>

      </div>
    )
  }
}
