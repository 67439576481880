import React from 'react';
import Context from '../../Context';
import InputField from '../../../FormBuilder/InputField';
import DisplayBlock from '../../shared/DisplayBlock';

const StageTwo = () => (
  <Context.Consumer>
    {(props) => {
      const {
        transaction: {
          agent_name,
          broker_name,
          address_house_number,
          address_street_name,
          address_unit,
          address_city,
          address_state,
          address_zip,
          lead_type,
          potential_agents
        },
        selections: {
          foundAgent,
          foundAgentMatches,
          foundAgentName,
          foundAgentNameFreeText,
          foundBrokerage,
          foundBrokerageName,
          foundOtherBrokerage,
        },
        updateSelections,
      } = props;

      const leadType = lead_type === 'seller'
        ? "seller's"
        : "buyer's";

      function answerAgentSelection(e) {
        const target = e.currentTarget;
        const foundAgent = target.classList.contains('confirm');

        updateSelections({ foundAgent });
      }

      function answerAgentMatchSelection(e) {
        const target = e.currentTarget;
        const newFoundAgentMatches = target.classList.contains('confirm');

        updateSelections({ foundAgentMatches: newFoundAgentMatches });
      }

      function answerBrokerageSelection(e) {
        const target = e.currentTarget;

        if (target.classList.contains('confirm')) {
          updateSelections({ foundBrokerage: 'yes' });
        } else if (target.classList.contains('unsure')) {
          updateSelections({ foundBrokerage: 'unsure' });
        } else {
          updateSelections({ foundBrokerage: 'no' });
        }
      }

      function answerOtherBrokerageSelection(e) {
        const target = e.currentTarget;
        const newFoundOtherBrokerage = target.classList.contains('confirm');

        updateSelections({ foundOtherBrokerage: newFoundOtherBrokerage });
      }

      function handleAgentNameChange(e) {
        const newFoundAgentName = e.currentTarget.value;

        if (newFoundAgentName.indexOf('Other:') < 0) {
          updateSelections({ foundAgentName: newFoundAgentName });
        } else {
          updateSelections({ foundAgentName: '' });
        }
      }

      function searchForAgent(name) {
        const query = [
          name,
          address_house_number,
          address_street_name,
          address_unit,
          address_city,
          address_state,
          address_zip,
        ].join(' ');

        return `https://www.google.com/search?q=${encodeURIComponent(query)}`;
      }

      function handleChange(e) {
        updateSelections({
          [e.currentTarget.name]: e.currentTarget.value || e.currentTarget.dataset.value || ''
        });
      }

      function agentSection() {
        return foundAgent && (
          <>
            <DisplayBlock
              title={`Was this the ${leadType} agent?`}
              value={agent_name || 'Unknown Agent'}
            />
            <div className="button-container">
              <button
                id="foundAgentMatchesConfirm"
                className={foundAgentMatches === true ? 'confirm inverted' : 'confirm'}
                onClick={answerAgentMatchSelection}
                disabled={ !agent_name }
              >
                Yes
              </button>
              <button
                id="foundAgentMatchesDeny"
                className={foundAgentMatches === false ? 'deny inverted' : 'deny'}
                onClick={answerAgentMatchSelection}
              >
                No
              </button>
            </div>
            {
              foundAgentMatches === false && (
                <>
                  <DisplayBlock title={`What is the ${leadType} agent's name?`} />
                  <div className="radio-container">
                    {potential_agents.map((agent, idx) => (
                      <div key={idx}>
                        <input
                          type="radio"
                          name="potentialAgent"
                          value={agent.name}
                          onChange={handleAgentNameChange}
                        />
                        {
                          agent.name === 'Other:'
                            ? <span>{agent.name}</span>
                            : (
                              <a href={searchForAgent(agent.name)} target="_blank">
                                <span>{agent.name}</span>
                              </a>
                            )
                        }
                      </div>
                    ))}
                    <InputField
                      id="foundAgentNameFreeText"
                      value={foundAgentNameFreeText}
                      handleChange={handleChange}
                    />
                  </div>
                </>
              )
            }
          </>
        )
      }

      function brokerageDisplayName() {
        if (foundAgentName && foundAgentName.length > 0) {
          const agentNames = potential_agents.map(a => a.name);
          const index = agentNames.indexOf(foundAgentName);

          return potential_agents[index].broker_name || 'Unknown Brokerage';
        } else {
          return broker_name || 'Unknown Brokerage';
        }
      };

      function brokerageSection() {
        if (foundAgentMatches || (foundAgentName && foundAgentName.length > 0)) {
          return (
            <>
              <DisplayBlock
                title={`Was this the ${leadType} brokerage?`}
                value={brokerageDisplayName()}
              />
              <div id="brokerage-button-container" className="button-container">
                <button
                  id="foundBrokerageConfirm"
                  className={foundBrokerage === 'yes' ? 'confirm inverted' : 'confirm'}
                  onClick={answerBrokerageSelection}
                >
                  Yes
                </button>
                <button
                  id="foundBrokerageUnsure"
                  className={foundBrokerage === 'unsure' ? 'unsure inverted' : 'unsure'}
                  onClick={answerBrokerageSelection}
                >
                  I don't know
                </button>
                <button
                  id="foundBrokerageDeny"
                  className={foundBrokerage === 'no' ? 'deny inverted' : 'deny'}
                  onClick={answerBrokerageSelection}
                >
                  No
                </button>
              </div>
              {anotherBrokerageSection()}
            </>
          );
        } else if ((foundAgent === false) || (foundAgentNameFreeText && foundAgentNameFreeText.length > 0)) {
          return (
            <>
              <DisplayBlock title={`Can you find the ${leadType} brokerage?`} />
              <div id="other-brokerage-button-container" className="button-container">
                <button
                  id="foundOtherBrokerageConfirm"
                  className={foundOtherBrokerage === true ? 'confirm inverted' : 'confirm'}
                  onClick={answerOtherBrokerageSelection}
                >
                  Yes
                </button>
                <button
                  id="foundOtherBrokerageDeny"
                  className={foundOtherBrokerage === false ? 'deny inverted' : 'deny'}
                  onClick={answerOtherBrokerageSelection}
                >
                  No
                </button>
              </div>
              {anotherBrokerageSection()}
            </>
          )
        }
      };

      function anotherBrokerageSection() {
        return (foundBrokerage === 'no' || foundOtherBrokerage) && (
          <>
            <DisplayBlock title={`What is the ${leadType} brokerage?`} />
            <InputField
              id="foundBrokerageName"
              value={foundBrokerageName}
              handleChange={handleChange}
            />
          </>
        );
      };

      return (
        <div className="form-builder stage2">
          <DisplayBlock title={`Can you identify the ${leadType} agent?`} />
          <div className="button-container">
            <button
              id="foundAgentConfirm"
              className={foundAgent === true ? 'confirm inverted' : 'confirm'}
              onClick={answerAgentSelection}
            >
              Yes
            </button>
            <button
              id="foundAgentDeny"
              className={foundAgent === false ? 'deny inverted' : 'deny'}
              onClick={answerAgentSelection}
            >
              No
            </button>
          </div>
          {agentSection()}
          {brokerageSection()}
        </div>
      )
    }}
  </Context.Consumer>
)

export default StageTwo;
