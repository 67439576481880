import React from 'react';

import Finished from './FinishedContainer';

import Alert from './images/alert.png';
import Success from './images/success.png';

const DisableLiveAccessView = (props) => {
  const {
    disableAccess,
    display,
    finished,
    loading,
    balanceOwed
  } = props;

  const icon = ['confirm', 'failed'].includes(display) ? Alert : Success;
  let title = 'LIVE Account Management: Disable All Access To Live';

  if (display === 'success') {
    title = 'PAYMENT SUCCESSFUL';
  } else if (display === 'failed') {
    title = 'PAYMENT FAILED';
  }

  const succeededMessage = `Agent's final payment of $${ balanceOwed } has succeeded and their account has been successfully disabled.`;
  const failedMessage    = `Agent's final payment of $${ balanceOwed } has failed and their account has been successfully disabled. Please followup on a payment separately.`;

  return (
    <div className='disable-live-access'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ icon } />
      </div>
      <div className='rex-modal-heading'>
        { title }
      </div>
      { display === 'confirm' &&
        <div className='rex-modal-contents'>
          <div className='rex-modal-subheading'>
            Are you sure you want to remove all access to LIVE in this agent's account (cannot be undone)?
          </div>
          <div className='rex-modal-actions rex-modal-actions__centered'>
            <button
              className={`rex-modal-action ${loading ? 'rex-modal-action__loading' : ''}`}
              onClick={ () => disableAccess() }
              disabled={ loading }
            >
              { loading ? 'Loading' : 'Disable Live' }
            </button>
          </div>
        </div>
      }
      { display === 'success' &&
        <Finished
          message={ succeededMessage }
          close={ finished }
          icon={ icon }
        />
      }
      {
        display === 'failed' &&
        <Finished
          message={ failedMessage }
          close={ finished }
          icon={ icon }
        />
      }
    </div>
  );
};

export default DisableLiveAccessView;
