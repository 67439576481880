import React from 'react';

const LiveAgentSearch = ({ state, setState }) => {
  const {
    liveAgentId,
    liveAgentSearchState,
    liveAgentSearchResults,
  } = state;

  function handleSelectAgent(agent) {
    const { id, name } = agent;
    setState({
      liveAgentId: id,
      liveAgentName: name,
    })
  }

  switch (liveAgentSearchState) {
    case 'searching':
      return (
        <div className='search-loading'>
          <i className="fa fa-cog fa-spin fa-3x fa-fw"></i>
          <span className="sr-only">Loading...</span>
        </div>
      );
    case 'displaying':
      return (
        <div className='search-results-table'>
          <div className='search-header'>
            <div className='search-column'>Name</div>
            <div className='search-column short'>Agent Id</div>
            <div className='search-column'>Email</div>
            <div className='search-column short'>Phone</div>
            <div className='search-column'>Agent Notify Preference</div>
            <div className='search-column'>Alternate Contact</div>
          </div>
          <div className='search-content'>
            {
              liveAgentSearchResults.map((agent, idx) => (
                <div
                  key={idx}
                  className={liveAgentId === agent.id ? 'search-rows selected' : 'search-rows'}
                  onClick={() => handleSelectAgent(agent)}
                >
                  <div className='search-row'>{agent.name}</div>
                  <div className='search-row short'>{agent.id}</div>
                  <div className='search-row'>{agent.email}</div>
                  <div className='search-row short'>{agent.phone_office || agent.phone_mobile}</div>
                  <div className='search-row'>{agent.notify_preference}</div>
                  <div className='search-row'>{agent.alternate_contact_name}</div>
                </div>
              ))
            }
          </div>
        </div>
      );
    default:
      return null;
  }
}

export default LiveAgentSearch;
