import String from '../../../../../common/String.js'

const Header = (props) => {
  const {
    historyKeys,
  } = props;

  function buildHeaders() {
    const data = [];
    const string = new String();

    historyKeys.forEach(key => {
      const header = key.includes('prime') ? 'PRIME Sales Attempt' : string.titleCase(key);
      data.push(<th key={key}>{header}</th>);
    });

    return data;
  }

  return (
    <thead>
      <tr>
        {buildHeaders()}
      </tr>
    </thead>
  );
};

export default Header;
