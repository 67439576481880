import { Component } from 'react';

export default class NewNote extends Component {
  constructor(props){
    super(props);
    this.state={
      note: ''
    }
    this.createNewNote = this.createNewNote.bind(this);
    this.changeText = this.changeText.bind(this);
  }

  changeText(e){
    this.setState({ note: e.currentTarget.value })
  }

  createNewNote(){
    let { note } = this.state;
    if(note === '') return
    let { currentUser, agentId, toggleNewNote, obtainAgentInfo } = this.props;
    let params = {
      note: {
        message: note,
        noteable_id: agentId,
        noteable_type: 'Agent',
        creator_id: currentUser.id
      }
    };
    Util.createNote(params)
      .then(()=> obtainAgentInfo())

    toggleNewNote();
  }

  preventBubbling(e){
    e.stopPropagation()
  }

  render(){
    let { user, toggleNewNote, agentId } = this.props
    return(
      <div className='react-notes-new-note' onClick={toggleNewNote}>
        <div className='react-notes-new-note-container'
          onClick={this.preventBubbling}
        >
          <div className='title'>
            {`New note for ${user.first_name} (#${agentId})`}
          </div>
          <div className='contents'>
            <textarea
              className='textarea'
              onChange={this.changeText}
              value={this.state.note}
            />
          </div>
          <div className='footer'>
            <div
              className='btn btn-default'
              type='button'
              onClick={this.props.toggleNewNote}
            >
              Close
            </div>
            &nbsp;&nbsp;
            <div
              className='btn btn-primary'
              type='button'
              onClick={this.createNewNote}
            >
              Create
            </div>
          </div>
        </div>
      </div>
    );
  }
}
