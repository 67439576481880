const Header = () => {
  return (
    <thead>
      <tr>
        <th className="max-width-15">Reviewer information</th>
        <th className="max-width-15">Reviewed on</th>
        <th className="max-width-15">Transaction information</th>
        <th className="max-width-25">Review</th>
        <th className="max-width-15">Moderation result</th>
        <th className="max-width-15">Reported?</th>
      </tr>
    </thead>
  )
};

export default Header;
