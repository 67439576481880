import { classList } from '../utils';

const NumberInput = (props) => {
  const {
    classNames,
    display,
    focused,
    id,
    index,
    max,
    min,
    name,
    type,
    valid,
    value,

    onChange,
    onFocus,
  } = props;

  const classes = classList({
    classNames,
    def: 'info-field',
    focused,
    valid
  });

  const inputId = `number-info-input-${ name }`;

  const error = typeof valid === 'string' ? valid : false;

  return (
    <div className={`info-field ${ classes }`}>
      <label htmlFor={ name }> { display }</label>
      <input
        type={ type || 'number' }
        name={ name }
        value={ value }
        onChange={ onChange }
        id={ inputId }
        min={ min || 0 }
        max={ max || 100000000 }
        onFocus={ onFocus }
      />
      {
        (focused && error) &&
        <span className='info-field-error'>{ error }</span>
      }

    </div>
  );
};

export default NumberInput;
