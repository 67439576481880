import { Component } from 'react';

const TableCol = props => {
  const {
    style,
    children,
  } = props

  const isPropPassed = (prop, newValue, defaultValue) => {
    return props[prop] ? newValue : defaultValue
  }

  const defaultStyle = {
    float: 'left',
    display: 'table-column',
    width: 150,
    padding: '10px',
    fontSize: '15px',
    background: isPropPassed('header', '#1b1e24', 'inherit'),
    color: isPropPassed('header', '#D5DDE5', '#666B85'),
    borderRight: '2px solid #dbdbdb',
  }

  return (
    <div style={Object.assign(defaultStyle, style)}>
      { children || <span>&nbsp;</span> }
    </div>
  )
}

export default TableCol;
