import React, { useState, useEffect } from 'react';

const dispositions = [
  {
    text: 'No answer',
    value: 'no_answer'
  },
  {
    text: 'Appointment set',
    value: 'appointment_set'
  },
  {
    text: 'No appointment',
    value: 'follow_up_fresh'
  },
  {
    text: 'Not interested',
    value: 'not_interested'
  },
  {
    text: 'Pitched agent',
    value: 'follow_up_prospect'
  },
  {
    text: 'Sold',
    value: 'sold'
  },
  {
    text: 'Bad Number',
    value: 'bad_number'
  },
  {
    text: 'Already Sold',
    value: 'already_sold'
  },
  {
    text: 'Missed appointment',
    value: 'appointment_missed'
  }
]

const CallDispositions = (props) => {
  const {
    agentInfo: { prospect_type, number },
    disposition,
    note,
    setModal,
    setNote,
    setDisposition,
    submit,
  } = props;

  const anAgent = /^Agent$/.test(prospect_type);
  const [showMore, setShowMore] = useState(false);
  const [linked, setLinked] = useState(false);

  useEffect(() => {
    if (number !== undefined) setShowMore(false);
  }, [number]);

  useEffect(() => {
    document.querySelector('body').addEventListener('prime_eligible_dialer.disposition_updater', event => {
      const { success } = event.detail;

      setLinked(success);
    });
  }, []);

  useEffect(() => {
    if (disposition === 'already_sold') {
      setModal({ show: true, type: 'merge-records' });
    }
  }, [disposition]);

  const selectDispo = (e) => {
    setDisposition(e.target.value);
  }

  const handleTextChange = (e) => {
    setNote(e.target.value);
  }

  const buttonClass = (disp) => {
    const bClass = ['dialer-button', 'orange'];

    if (disposition === disp) {
      bClass.push('filled');
    }

    return bClass.join(' ');
  }

  const buildDispositionButton = ({ value, text }) => {
    return (
      <button key={ value } type="button" value={ value } onClick={ selectDispo } className={ buttonClass(value) }>
        { text }
      </button>
    );
  }

  const buildDispositionButtons = (start, end) => {
    const buttons = [];

    dispositions.slice(start, end).map(d => {
      buttons.push(buildDispositionButton(d));
    });

    return buttons;
  }

  const updateShowMore = () => {
    setShowMore(!showMore);
  }

  return (
    <div className="dialer-call-dispositions">
      <textarea
        value={note}
        placeholder="Add Notes (optional)"
        onChange={handleTextChange}
      />
      <div className="disposition-buttons">
        <div className="button-row">
          { buildDispositionButtons(0, 3) }
        </div>
        {
          showMore && (
            <div className="button-row">
              { buildDispositionButtons(3, 6) }
            </div>
          )
        }
        {
          showMore && !anAgent && (
            <div className="button-row">
              { buildDispositionButtons(6) }
            </div>
          )
        }
        <div className="button-row">
          <button
            type="button"
            onClick={updateShowMore}
            className="dialer-button"
          >
            {`Show ${showMore ? 'less' : 'more'} options`}
          </button>
          <button
            type="button"
            id="prime-dialer-submit-disposition"
            disabled={!disposition || (disposition === 'already_sold' && !linked)}
            onClick={submit}
            className="dialer-button orange filled"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
};

export default CallDispositions;
