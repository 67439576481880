import React from 'react';

import Tooltip from './Tooltip';
import { notEmpty } from '../util/Util';
import { tooltip as tooltipProps } from './shared/PropTypes';

const FormLabel = ({
  label,
  klass,
  htmlFor,
  children,
  required,
  tooltip,
  mobileSubLabel,
}) => (
  <label htmlFor={htmlFor} className={klass}>
    { label || required || tooltip || notEmpty(mobileSubLabel) && (
      <span>
        {label}
        {required && <span className="required">*</span>}
        {tooltip && <Tooltip tip={tooltip} />}
        {notEmpty(mobileSubLabel) && <div className="sub-label">{mobileSubLabel}</div>}
      </span>
    )}
    {children}
  </label>
);

FormLabel.propTypes = tooltipProps.props;
FormLabel.defaultProps = tooltipProps.defaultProps;

export default FormLabel;
