import React from 'react';

import 'intl-tel-input/build/css/intlTelInput.css';

import { classList } from '../utils';

const PhoneNumber = (props) => {
  const {
    classNames,
    display,
    focused,
    id,
    index,
    name,
    parentClassNames,
    valid,
    value,

    onChange,
    onFocus,
  } = props;

  const classes = classList({
    classNames,
    def: 'info-field phone-number',
    focused,
    valid
  });

  const inputId = `number-info-input-${ name }`;

  const error = typeof valid === 'string' ? valid : false;

  return (
    <div className={`info-field ${ parentClassNames }`}>
      <div className={` ${ classes }`}>
        <label htmlFor={ name }> { display }</label>
        <input
          name={ name }
          value={ value || '' }
          onChange={ onChange }
          id={ id ||  `info-input-${ name }-${ index || 0 }`}
          data-index={ index || 0 }
          onFocus={ onFocus }
        />
        {
          (focused && error) &&
          <span className='info-field-error'>{ error }</span>
        }
      </div>
    </div>
  );
};

export default PhoneNumber;
