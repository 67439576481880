import { Component }  from 'react';
import Schedule       from './calendar/Schedule';
import CalendarWidget from '../CalendarWidget/CalendarWidget'

export default class Calendar extends Component {
  constructor(props){
    super(props)
  }

  render(){
    let { currentUser, currentUserRoles, activeDay, activeDate, activeMonth, activeYear, updateActiveDay, calendar, updateAppointmentAssignee, fetchAgent, appointmentSetterManager } = this.props
    let customerSuccessSchedule = () => {
      if(calendar){
        return(
          <Schedule
            currentUser={currentUser}
            currentUserRoles={currentUserRoles}
            activeDate={activeDate}
            fetchAgent={fetchAgent}
            appointmentSetterManager={appointmentSetterManager}
            updateAppointmentAssignee={updateAppointmentAssignee}
            salesManager={this.props.salesManager}
            calendar={calendar}
          />
        )
      }
    }
    return(
      <div className='cs-calendar-panel'>
        <CalendarWidget
          currentUserRoles={currentUserRoles}
          updateActiveDay={updateActiveDay}
          activeDay={activeDay}
          activeDate={activeDate}
          activeMonth={activeMonth}
          activeYear={activeYear}
          fetchAgent={fetchAgent}
        />
      { customerSuccessSchedule() }
      </div>
    )
  }
}
