import React, { useState, useEffect } from 'react';

const randomHex = size => {
  return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
};

const agentAttrs = {
  broker_name: '',
  original_real_estate_license_year: '',
  past_12_months_sales: '',
  license_type: '',
  license_number: '',
  license_state: '',
  ip_address: 0,
  country: 'US',
  lead_id: '',
  text_message_number: '',
  text_message: 1,
  zip_codes: []
};

const companyAttrs = {
  name: '',
  brand_id: '',
  address: '',
  city: '',
  state: '',
  zip: '',
};

const userAttrs = {
  first_name: '',
  last_name: '',
  email: '',
  phone_office: '',
  phone_mobile: '',
  direct_line: '',
  secret_question: 'Not used but required',
  password: randomHex(10),
};

const initData = {
  new_agent_type: 'prime_eligible',
  broadcast_id: '',
  created_by_id: window.current_user_id,
  existing_agent_id: '',
  user: userAttrs,
  agent: agentAttrs,
  company: companyAttrs
};

const NewAgentForm = (props) => {
  const {
    agents_path,
    broadcastId,
    showAgentForm,
    toggleNewAgentForm,
    duplicateCheckPath,
  } = props;

  const [data, setData] = useState(initData);
  const [checked, toggleChecked] = useState(false);
  const [localization, setLocalization] = useState('US');
  const [canSkipDuplicateCheck, toggleCanSkipDuplicateCheck] = useState(false);

  const { user, agent, company } = data;
  const checkedKlass = checked ? 'checked' : '';
  const selectLabelText = (/US/).test(localization) ? 'State' : 'Province';
  const labelText = (/US/).test(localization) ? 'Zip' : 'Postal';
  const visibleKlass = showAgentForm ? 'visible' : '';
  const form = document.getElementById('new-agent-form');

  useEffect(() => {
    setFormData(undefined, 'broadcast_id', broadcastId);
  }, [broadcastId]);

  function setFormData(key1, key2, value) {
    const overwrite = { [key2]: value };
    const finalwrite = (key1 === undefined) ? overwrite : { [key1]: { ...data[key1], ...overwrite } };

    setData({ ...data, ...finalwrite });
  }

  function buildOptions(country)  {
    const { states, provinces } = window.isoStateProvinceCodes;
    const codes = country == 'US' ? states : provinces;
    const options = [];

    options.push(<option key='' value=''></option>);

    codes.forEach(code => options.push(<option key={ code } value={ code }>{ code }</option>));

    return options;
  }

  function buildYearOptions() {
    const hi = new Date().getFullYear();
    const lo = hi - 100;
    const options = [];

    options.push(<option key='' value=''></option>);

    for (let i = hi; i >= lo; i--) {
      options.push(<option key={ i } value={ i }>{ i }</option>);
    }

    return options
  }

  function extractNestedNames(name) {
    return name.match(/\[(.*?)\]/g).map(e => e.replace(/\[|\]/g, ''));
  }

  function clearFormErrors() {
    const form = document.getElementById('new-agent-form');
    const errors = form.querySelectorAll('.has-error');

    errors.forEach(error => {
      error.classList.remove('has-error');
      error.closest('.form-group').getElementsByClassName('input-error').item(0).remove();
    });

    cleanDuplicatesDisplay();
  }

  function validateForm() {
    clearFormErrors();

    const formContainer = form.closest('.dialer-new-agent-form');
    const fields = form.querySelectorAll('.validate');
    let valid = true;

    fields.forEach(field => {
      const [object, attribute] = extractNestedNames(field.name);
      const formGroup = field.closest('.form-group');
      const list = field.classList;
      let messages = new Array();

      if (list.contains('validate-presence')) {
        if (!field.value.length) {
          formGroup.classList.add('has-error');
          valid = false;
          messages.push('Required field');
        }
      }

      if (list.contains('validate-number')) {
        if (!(/^\d+$/).test(field.value)) {
          formGroup.classList.add('has-error');
          valid = false;
          messages.push('Invalid value (number)');
        }
      }

      if (list.contains('validate-email')) {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!emailRegex.test(String(field.value).toLowerCase())) {
          formGroup.classList.add('has-error');
          valid = false;
          messages.push('Invalid email');
        }
      }

      if (list.contains('validate-zip')) {
        const countryIsUS = /US/.test(localization);
        const zipRegex = countryIsUS ? /^([0-9]{5})$/ : /^([A-Z][0-9][A-Z] [0-9][A-Z][0-9])$/;
        const message = countryIsUS ? 'Invalid zip' : 'Invalid postal code';

        if (!zipRegex.test(field.value)) {
          formGroup.classList.add('has-error');
          valid = false;
          messages.push(message);
        }
      }

      if (list.contains('validate-phone')) {
        const phoneRegex = /^(\+?\d{1,3}[.-\s]?)?\(?([0-9]{3})\)?[.-\s]?([0-9]{3})[.-\s]?([0-9]{4})$/

        if (!phoneRegex.test(field.value)) {
          formGroup.classList.add('has-error');
          valid = false;
          messages.push('Invalid phone');
        }
      }

      if (list.contains('validate-length')) {
        const { maxLength, minLength } = field.dataset;

        if (maxLength != undefined && field.value.length > maxLength) {
          formGroup.classList.add('has-error');
          valid = false;
          messages.push(`Max length (${maxLength})`);
        }

        if (minLength != undefined && field.value.length < minLength) {
          formGroup.classList.add('has-error');
          valid = false;
          messages.push(`Min length (${minLength})`);
        }
      }

      if (messages.length) {
        const errorDiv = document.createElement('div');

        errorDiv.classList.add('input-error');
        errorDiv.innerHTML = messages.join(', ');

        formGroup.appendChild(errorDiv);
      }
    });

    if (!valid) formContainer.scroll({ top: 0, behavior: 'smooth' });

    return valid;
  }

  function passDuplicateCheck() {
    const userEmailField = document.getElementById('candidate_user_email');
    const userEmailFormGroup = userEmailField.closest('.form-group');
    const { email } = data.user;
    const requestPath = `${duplicateCheckPath}?agent[email]=${email}`;

    return fetch(
      requestPath,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      if (!response.success) {
        const emailExactMatch = response.agent_matches.some(agent => agent.email.toLowerCase() == email.toLowerCase());
        const message = 'Possible duplicate agent';
        const errorDiv = document.createElement('div');

        errorDiv.classList.add('input-error');
        errorDiv.innerText = message;

        if (emailExactMatch) {
          userEmailFormGroup.classList.add('has-error');
          userEmailFormGroup.appendChild(errorDiv);
        }

        if (!canSkipDuplicateCheck) displayDuplicates(response.agent_matches);
        toggleCanSkipDuplicateCheck(!emailExactMatch);
      }

      return canSkipDuplicateCheck || response.success;
    });
  }

  function displayDuplicates(dupes) {
    const agentMatchesContainer = document.getElementById('duplicate-agent-matches-container');
    const agentMatchesContent = agentMatchesContainer.querySelector('.duplicate-agent-matches-content');

    cleanDuplicatesDisplay();

    dupes.forEach((dup, idx) => {
      const radioData = { ...dup, ...{ checked: '' } };
      const wrapper = document.createElement('div');

      wrapper.innerHTML = duplicateRadioOption(radioData);

      agentMatchesContent.appendChild(wrapper.firstChild);
    });

    const radios = document.querySelectorAll('input[name="candidate[existing_agent_id]"]');

    radios.forEach(radio => {
      radio.addEventListener('change', (event) => toggleCanSkipDuplicateCheck(event.currentTarget.value.length > 0));
    });

    agentMatchesContainer.classList.remove('hidden');
  }

  function duplicateRadioOption(data) {
    const {
      id,
      name,
      email,
      status,
      license_number,
      highlight,
      checked,
    } = data;

    const agentLink = id ? `<a href="/a/${id}" target="_blank">${id}</a>` : '';
    const emailAddress = highlight.email || email;
    const licenseNumber = highlight.license_number || license_number;

    const agentStatus = (() => {
      if (status == undefined) {
        return ' (agent status unknown)';
      } else if (status.length) {
        return ` (${status})`;
      } else {
        return status;
      }
    })();

    const inputText = [agentLink, `${name}${agentStatus}`, emailAddress.toLowerCase(), licenseNumber].filter(Boolean).join(', ');

    return(
      `<div class="duplicate-agent-match">
        <label for="candidate_existing_agent_id_${id}">
          <input ${checked} type="radio" value="${id}"" name="candidate[existing_agent_id]" id="candidate_existing_agent_id_${id}" /> ${inputText}
        </label>
      </div>`
    );
  }

  function cleanDuplicatesDisplay() {
    const agentMatchesContainer = document.getElementById('duplicate-agent-matches-container');
    const agentMatchesContent = agentMatchesContainer.querySelector('.duplicate-agent-matches-content');
    const radioData = { id: '', name: 'No Duplicates Found', status: '', email: '', checked: 'checked', highlight: {} };

    agentMatchesContent.innerHTML = duplicateRadioOption(radioData);
    toggleCanSkipDuplicateCheck(false);
    agentMatchesContainer.classList.add('hidden');
  }

  function toggleFormOverlay(operation) {
    const action = /show/.test(operation) ? 'add' : 'remove';

    form.classList[action]('overlay');
  }

  function handleChange(event) {
    const { value, name } = event.currentTarget;
    const [key1, key2] = extractNestedNames(name);

    if (key2 == 'country') {
      setLocalization(value);
      setFormData('company', 'state', '');
      setFormData('agent', 'license_state', '');
    }

    if (key2 == 'zip_codes') {
      setFormData(key1, key2, [value]);
    } else {
      setFormData(key1, key2, value);
    }
  }

  function handleOptIn() {
    const currentlyChecked = !checked;
    const value = currentlyChecked ? 1 : 0;

    setFormData('agent', 'text_message', value);
    toggleChecked(currentlyChecked);
  }

  function handleDuplicateRadioOption(event) {
    const { value } = event.currentTarget;

    setFormData(undefined, 'existing_agent_id', value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    toggleFormOverlay('show');

    if (validateForm()) {
      Util.clearFlash();

      passDuplicateCheck()
      .then(success => {
        if (success) {
          fetch(
            agents_path,
            {
              method: 'POST',
              credentials: 'same-origin',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ candidate: data }),
            }
          )
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              resetForm();
            } else {
              Util.createFlash(response.errors.join(', '), 'danger');
            }

            toggleFormOverlay('hide');
          })
          .catch(error => {
            Util.createFlash(error.message, 'danger');
          });
        } else {
          const duplicateAgentMatches = document.querySelectorAll('.duplicate-agent-match input');

          duplicateAgentMatches.forEach(duplicateAgentMatch => {
            duplicateAgentMatch.addEventListener('click', handleDuplicateRadioOption);
          });

          toggleFormOverlay('hide');
        }
      });
    } else {
      toggleFormOverlay('hide');
    }
  }

  function resetForm() {
    const formContainer = form.closest('.dialer-new-agent-form');

    toggleNewAgentForm(false);
    clearFormErrors();
    toggleChecked(false);
    setLocalization('US');
    setData(initData);

    setTimeout(() => {
      formContainer.scroll({ top: 0, behavior: 'smooth' });
    }, 250);
  }

  function handleCancel(event) {
    event.preventDefault();

    const currBroadcastID = broadcastId;

    resetForm();
    setFormData(undefined, 'broadcast_id', currBroadcastID);
  }

  return (
    <div className={ `dialer-new-agent-form-container ${visibleKlass}` }>
      <div className="dialer-new-agent-form">
        <form id="new-agent-form">
          <div className="row">
            <div className="col-md-12 text-right">
              <i className="fa fa-times fa-2x p-5 close-form" onClick={ handleCancel }></i>
              <h2 className="text-center p-0 mb-20">New Agent Form</h2>
            </div>

            <div className="col-md-12 section-header">
              <div className="form-group">
                <i className="fa fa-user"></i> Personal Info
                <hr />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="candidate_user_first_name">First Name</label>
                <input className="form-control validate validate-presence" type="text" name="candidate[user][first_name]" id="candidate_user_first_name" value={ user.first_name } onChange={ handleChange } />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="candidate_user_last_name">Last Name</label>
                <input className="form-control validate validate-presence" type="text" name="candidate[user][last_name]" id="candidate_user_last_name" value={ user.last_name } onChange={ handleChange } />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="candidate_user_email">Email</label>
                <input className="form-control validate validate-presence validate-email" type="text" name="candidate[user][email]" id="candidate_user_email" value={ user.email } onChange={ handleChange } />
              </div>
            </div>
            <div className="col-md-6 hidden">
              <div className="form-group">
                <label htmlFor="candidate_user_phone_office">Phone Office</label>
                <input className="form-control validate-presence validate-phone" type="text" name="candidate[user][phone_office]" id="candidate_user_phone_office" value={ user.phone_office } onChange={ handleChange } />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="candidate_user_phone_mobile">Phone Mobile</label>
                <input className="form-control validate validate-presence validate-phone" type="text" name="candidate[user][phone_mobile]" id="candidate_user_phone_mobile" value={ user.phone_mobile } onChange={ handleChange } />
              </div>
            </div>
            <div className="col-md-6 hidden">
              <div className="form-group">
                <label htmlFor="candidate_user_direct_line">Direct Line</label>
                <input className="form-control validate-presence validate-phone" type="text" name="candidate[user][direct_line]" id="candidate_user_direct_line" value={ user.direct_line } onChange={ handleChange } />
              </div>
            </div>
            <div className="col-md-6 hidden">
              <div className="form-group">
                <label htmlFor="candidate_agent_text_message_number">Text Message Number</label>
                <input className="form-control validate-presence validate-phone" type="text" name="candidate[agent][text_message_number]" id="candidate_agent_text_message_number" value={ agent.text_message_number } onChange={ handleChange } />
              </div>
            </div>
            <div className="col-md-12 hidden foobar">
              <div className="form-group">
                <label htmlFor="candidate_agent_text_message">
                  <input className="form-control mr-10" type="checkbox" name="candidate[agent][text_message]" id="candidate_agent_text_message" checked={ checkedKlass } value={ agent.text_message } onClick={ handleOptIn } />
                  Opt-In Text Message
                </label>
              </div>
            </div>

            <div className="col-md-12 section-header">
              <div className="form-group">
                <i className="fa fa-briefcase"></i> Company Info
                <hr />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="candidate_company_name">Company Name</label>
                <input className="form-control validate validate-presence" type="text" name="candidate[company][name]" id="candidate_company_name" value={ company.name } onChange={ handleChange } />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="candidate_company_address">Address</label>
                <input className="form-control validate validate-presence" type="text" name="candidate[company][address]" id="candidate_company_address" value={ company.address } onChange={ handleChange } />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="candidate_agent_country">Country</label>
                <select className="form-control validate validate-presence" name="candidate[agent][country]" id="candidate_agent_country" value={ agent.country } onChange={ handleChange } >
                  <option value="CA">Canada</option>
                  <option value="US">United States</option>
                </select>
              </div>
            </div>
            <div className="col-md-5">
              <div className="form-group">
                <label htmlFor="candidate_company_city">City</label>
                <input className="form-control validate validate-presence" type="text" name="candidate[company][city]" id="candidate_company_city" value={ company.city } onChange={ handleChange } />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="candidate_company_state">{ selectLabelText }</label>
                <select className="form-control validate validate-presence" name="candidate[company][state]" id="candidate_company_state" value={ company.state } onChange={ handleChange } >
                  { buildOptions(localization) }
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="candidate_company_zip">{ `${labelText} Code` }</label>
                <input className="form-control validate validate-presence validate-zip" type="text" name="candidate[company][zip]" id="candidate_company_zip" value={ company.zip } onChange={ handleChange } />
              </div>
            </div>

            <div className="col-md-12 section-header hidden">
              <div className="form-group mt-20">
                <i className="fa fa-id-card"></i> License Info
                <hr />
              </div>
            </div>
            <div className="col-md-12 hidden">
              <div className="form-group">
                <label htmlFor="candidate_agent_broker_name">Broker Name</label>
                <input className="form-control validate-presence validate-length" data-max-length="40" type="text" name="candidate[agent][broker_name]" id="candidate_agent_broker_name" value={ agent.broker_name } onChange={ handleChange } />
              </div>
            </div>
            <div className="col-md-3 hidden">
              <div className="form-group">
                <label htmlFor="candidate_agent_original_real_estate_license_year">Original License Year</label>
                <select className="form-control validate-presence" name="candidate[agent][original_real_estate_license_year]" id="candidate_agent_original_real_estate_license_year" value={ agent.original_real_estate_license_year } onChange={ handleChange } >
                  { buildYearOptions() }
                </select>
              </div>
            </div>
            <div className="col-md-5 hidden">
              <div className="form-group">
                <label htmlFor="candidate_agent_past_12_months_sales">Past 12 Months Sales</label>
                <input className="form-control validate-presence validate-length" data-max-length="50" type="text" name="candidate[agent][past_12_months_sales]" id="candidate_agent_past_12_months_sales" value={ agent.past_12_months_sales } onChange={ handleChange } />
              </div>
            </div>
            <div className="col-md-4 hidden">
              <div className="form-group">
                <label htmlFor="candidate_agent_license_type">License Type</label>
                <select className="form-control validate-presence" name="candidate[agent][license_type]" id="candidate_agent_license_type" value={ agent.license_type } onChange={ handleChange } >
                  <option value=""></option>
                  <option value="Broker">Broker</option>
                  <option value="Salesperson">Salesperson</option>
                </select>
              </div>
            </div>
            <div className="col-md-5 hidden">
              <div className="form-group">
                <label htmlFor="candidate_agent_license_number">License Number</label>
                <input className="form-control validate-presence validate-length" data-max-length="30" type="text" name="candidate[agent][license_number]" id="candidate_agent_license_number" value={ agent.license_number } onChange={ handleChange } />
              </div>
            </div>
            <div className="col-md-3 hidden">
              <div className="form-group">
                <label htmlFor="candidate_agent_license_state">{ `License ${selectLabelText}` }</label>
                <select className="form-control validate-presence" name="candidate[agent][license_state]" id="candidate_agent_license_state" value={ agent.license_state } onChange={ handleChange } >
                  { buildOptions(localization) }
                </select>
              </div>
            </div>
            <div className="col-md-4 hidden">
              <div className="form-group">
                <label htmlFor="candidate_agent_zip_codes">{ `${labelText} Code` }</label>
                <input className="form-control validate-presence validate-zip" type="text" name="candidate[agent][zip_codes][]" id="candidate_agent_zip_codes" value={ agent.zip_codes } onChange={ handleChange } />
              </div>
            </div>

            <div className="col-md-12 hidden" id="duplicate-agent-matches-container">
              <h3 className="text-danger p-0 mb-15">ATTENTION! Possible duplicate agents found.</h3>
              <p className="text-danger">Please select the agent you would like to update. If no duplicates are found, please correct the fields highlighted in red above in order to proceed.</p>
              <div className="duplicate-agent-matches-content"></div>
            </div>

            <div className="col-md-12">
              <div className="form-group text-right">
                <hr />
                <button className="dialer-button mr-30" onClick={ handleCancel }>Cancel</button>
                <button className="dialer-button orange" onClick={ handleSubmit }>Save</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewAgentForm;
