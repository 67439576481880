import { Component } from 'react';

import TimeSlots from './TimeSlots';

export default class NewEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      arrowControl: false,
      matches: this.initializeMatchEmails(),
      smsMatches: this.initializeMatchSMS(),
      contactTimeMatches: this.initializeContactTimeMatches(),
      to_email: this.props.matches[0].agent.email,
      emailable_type: 'Match',
      smsableType: 'Match',
      recipient_id: this.props.matches[0].agent.id,
      send_from: this.props.currentUser.id,
      subject: '',
      message: '',
      smsSubject: '',
      smsMessage: '',
      subjectPreview: '',
      preview_id: '',
      contactDate: '',
      contactTime: '',
      leadTextConsent: false,
      togglePreview: false
    };

    this.addAgent                      = this.addAgent.bind(this);
    this.addContactTimeAgent           = this.addContactTimeAgent.bind(this);
    this.addSMSAgent                   = this.addSMSAgent.bind(this);
    this.contactTimeShowAgentNames     = this.contactTimeShowAgentNames.bind(this);
    this.deleteEmail                   = this.deleteEmail.bind(this);
    this.refreshContactTimeTemplate    = this.refreshContactTimeTemplate.bind(this);
    this.refreshEmailTemplate          = this.refreshEmailTemplate.bind(this);
    this.refreshSMSTemplate            = this.refreshSMSTemplate.bind(this);
    this.saveContactTime               = this.saveContactTime.bind(this);
    this.saveEmails                    = this.saveEmails.bind(this);
    this.saveSMS                       = this.saveSMS.bind(this);
    this.showAgentEmails               = this.showAgentEmails.bind(this);
    this.showAgentNames                = this.showAgentNames.bind(this);
    this.showPreview                   = this.showPreview.bind(this);
    this.showSMSPreview                = this.showSMSPreview.bind(this);
    this.toggleArrowControl            = this.toggleArrowControl.bind(this);
    this.toggleContactTimeArrowControl = this.toggleContactTimeArrowControl.bind(this);
    this.togglePreview                 = this.togglePreview.bind(this);
    this.toggleSMSArrowControl         = this.toggleSMSArrowControl.bind(this);
    this.updateContactDate             = this.updateContactDate.bind(this);
    this.updateContactTime             = this.updateContactTime.bind(this);
    this.updateEmailType               = this.updateEmailType.bind(this);
    this.updateLeadTextConsent         = this.updateLeadTextConsent.bind(this);
    this.updateSMSType                 = this.updateSMSType.bind(this);
    this.updateSubject                 = this.updateSubject.bind(this);
  }

  initializeMatchEmails() {
    var matches = {};
    this.props.matches.forEach(function(data) {
      matches[data.match.id] = {};
      matches[data.match.id]['set'] = true;
      matches[data.match.id]['agent_id'] = data.agent.id;
      matches[data.match.id]['to_email'] = data.agent.email;
      matches[data.match.id]['full_name'] = `${data.agent.first_name} ${data.agent.last_name}`;
    });
    return matches;
  }

  initializeContactTimeMatches() {
    return this.props.matches.reduce((acc, data) => {
      const { agent, match } = data;
      acc[match.id] = {
        set: false,
        agent_id: agent.id,
        textable_id: match.id,
        lead_id: this.props.lead.id,
        full_name: `${agent.first_name} ${agent.last_name}`
      }
      return acc;
    }, {})
  }

  initializeMatchSMS() {
    let matches = {};
    this.props.matches.forEach((data, idx) => {
      const { agent } = data
      const {
        id,
        text_message_number,
        first_name,
        last_name,
      } = agent;
      matches[data.match.id] = {
        set: idx === 0,
        agent_id: id,
        text_message_number,
        full_name: `${first_name} ${last_name}`,
      };
    });
    return matches;
  }

  componentDidMount() {
    this.email_editor = new wysihtml5.Editor('email-body', {
      toolbar: 'wysihtml5-toolbar',
      stylesheets: ["" + this.props.user_mailer_css]
    });
    this.email_editor.on('load', function() {
      $('.wysihtml5-sandbox').contents().find('body').on('keyup', this.updateBody.bind(this))
    }.bind(this))

    this.smsEditor = new wysihtml5.Editor('sms-body', {
      toolbar: 'wysihtml5-toolbar',
      stylesheets: ["" + this.props.user_mailer_css]
    });
    this.smsEditor.on('load', () => {
      this.smsEditor.composer.disable();
    })
  }

  updateBody() {
    this.setState({message: this.email_editor.getValue()})
  }

  updateSMSBody() {
    this.setState({smsMessage: this.smsEditor.getValue()})
  }

  renderArrowControl() {
    return this.props.emailArrowControl
      ? <i className='fa fa-caret-right absolute'></i>
      : <i className='fa fa-caret-down absolute'></i>;
  }

  renderSMSArrowControl(){
    return this.props.smsArrowControl
      ? <i className='fa fa-caret-right absolute'></i>
      : <i className='fa fa-caret-down absolute'></i>;
  }

  renderContactTimeArrowControl(){
    const classNames = ['fa', 'absolute'];
    const direction = this.props.contactTimeArrowControl ? 'right' : 'down';
    const className = `fa-caret-${direction}`;

    classNames.push(className);
    return (<i className={classNames.join(' ')}></i>);
  }

  updateSubject(e) {
    this.setState({subject: e.target.value});
  }

  updateContactDate(e) {
    this.setState({contactDate: e.target.value});
  }

  updateContactTime(e) {
    this.setState({contactTime: e.target.value});
  }

  updateLeadTextConsent(e) {
    this.setState({leadTextConsent: !this.state.leadTextConsent});
  }

  updateEmailType(e) {
    if(this.state.togglePreview) {
      alert('Switch back to edit mode before switching email types'); return;
    }

    this.props.updateState('email_type', e.target.value);
    if(!e.target.value.length) {
      this.setState({subject: '', message: ''});
      this.email_editor.setValue('');
      return;
    }

    var options = {
      email: {
        email_type: e.target.value,
        to_email: this.state.to_email,
        emailable_type: 'Match',
        emailable_id: this.props.matches[0].match.id
      }
    }
    $.get('/dialer/profile_review/email_template', options, (result) => {
      this.setState({ subject: result.subject, message: result.message });
      this.email_editor.setValue(result.message);
    })
  }

  updateSMSType(e) {
    let type = e.target.value;

    this.props.updateState('smsType', type, this.showSMSPreview);

    if(!type.length) {
      this.setState({ smsSubject: '', smsMessage: '', preview_id: '' });
      this.smsEditor.setValue('');
      return;
    }
  }

  async showSMSPreview(){
    if(!this.props.smsType.length) {
      this.setState({ smsSubject: '', smsMessage: '', preview_id: '' });
      this.smsEditor.setValue('');
      return;
    }

    const { smsMatches } = this.state;
    let selectedMatchId = this.props.matches[0].match.id;
    let recipientId = this.state.recipient_id;
    Object.keys(smsMatches).forEach((matchId) => {
      const { set, agent_id, text_message_number } = smsMatches[matchId];
      if (set === true) {
        selectedMatchId = matchId
        recipientId = agent_id
      }
    });
    let options = {
      text_message_outbound: {
        name: this.props.smsType,
        textable_id: selectedMatchId,
        recipient_id: recipientId
      }
    }
    const textSidekiqJobId = await TextMessagesOutboundPreview.requestPreviewJobId(options)
    const textPreviewResponse = await TextMessagesOutboundPreview.waitForPreview(textSidekiqJobId);
    const text_message_outbound = await TextMessagesOutboundPreview.renderPreview(textPreviewResponse.results.text_messages_outbound_id, true);
    const { preview_id, message } = text_message_outbound
    this.setState({ smsMessage: message, preview_id: preview_id });
    this.smsEditor.setValue(message);
  }

  emailFormOptions() {
    return [
      <option key={0} value=''>-- SELECT --</option>,
      <option key={1} value='agent_call_client'>Agent Call Client</option>,
      <option key={2} value='agent_positive_feedback'>Agent Positive Feedback</option>,
      <option key={3} value='bad_number_inform_agent'>Bad Number Inform Agent</option>,
      <option key={4} value='profile_review_agent_timeline'>Profile Review Agent Timeline</option>,
      <option key={5} value='custom_match'>Custom</option>
    ]
  }

  SMSFormOptions() {
    return [
      <option key={0} value=''>-- SELECT --</option>,
      <option key={1} value='agent_call_client_text'>Agent Call Client</option>
    ]
  }

  timeSlotsOptions() {
    const timeSlots = TimeSlots.get();
    return timeSlots.map((timeSlot, idx) => {
      return <option key={idx} value={timeSlot}>{timeSlot}</option>
    });
  }

  addAgent(match_id, e) {
    let { matches } = this.state;
    matches[match_id]['set'] = e.target.checked;
    this.setState({ matches });
  }

  addContactTimeAgent(match_id) {
    let contactTimeMatches = this.state.contactTimeMatches;
    Object.keys(contactTimeMatches).forEach((match) => {
      contactTimeMatches[match].set = parseInt(match) == match_id;
    });
    this.setState({ contactTimeMatches });
    $('#dropdown-container-contact-time').slideUp();
  }

  addSMSAgent(match_id) {
    var smsMatches = this.state.smsMatches;
    Object.keys(smsMatches).forEach((match) => {
      parseInt(match) == match_id
        ? smsMatches[match].set = true
        : smsMatches[match].set = false;
    });
    this.setState({ smsMatches }, this.showSMSPreview);
    $('#dropdown-container-sms').slideUp();
  }

  async showPreview() {
    if(!this.props.email_type.length) return;

    const {
      email_type,
      matches,
    } = this.props;

    const {
      id,
      agent,
    } = matches[0].match;

    const {
      emailable_type,
      subject,
      message,
    } = this.state;

    var options = {
      email: {
        email_type: email_type,
        emailable_type: emailable_type,
        emailable_id: id,
        recipient_id: agent.id,
        profile_review_subject: subject,
        profile_review_message: message,
      }
    }

    EmailPreview.newEmailPreview(options, id, true);

    document.body.addEventListener(
      `email_preview.preview_complete_${id}`,
      event => {
      const {
        subject,
        message,
        preview_id,
        to_email
      } = event.detail;

      this.setState({
        subjectPreview: subject,
        preview_id: preview_id,
        to_email: to_email
      });

      this.email_editor.setValue(message);
      this.email_editor.disable();
    });
  }

  showAgentEmails() {
    return this.props.matches.map((data, idx) => {
      let fullName = `${data.agent.first_name} ${data.agent.last_name}`;
      return(
        <div className='dropdown-inner' key={idx}>
          <input
            type='checkbox'
            className='inline-block email-checkbox'
            onClick={this.addAgent.bind(this, data.match.id)}
            checked={this.state.matches[data.match.id]['set']}
          />
          <div className='email-container inline-block'>{fullName}</div>
        </div>
      )
    });
  }

  showAgentNames() {
    return this.props.matches.map((data, idx) => {
      let matchId = data.match.id;
      let fullName = `${data.agent.first_name} ${data.agent.last_name}`;
      let isInvalidNumber = !this.state.smsMatches[matchId]['text_message_number']
      return(
        <div className='dropdown-inner' key={idx}>
          <input
            type='radio'
            onChange={() => this.addSMSAgent(matchId)}
            checked={this.state.smsMatches[matchId]['set']}
            className='inline-block email-checkbox'
            disabled={isInvalidNumber}
          />
          <div className='email-container inline-block'>
            { isInvalidNumber ? fullName + ' (invalid)' : fullName }
          </div>
        </div>
      )
    })
  }

  contactTimeShowAgentNames() {
    return this.props.matches.map((data, index) => {
      const { id } = data.match;
      const { agent } = data;
      let fullName = `${agent.first_name} ${agent.last_name}`;
      return(
        <div className='dropdown-inner' key={index}>
          <input
            type='radio'
            onChange={() => this.addContactTimeAgent(id)}
            checked={this.state.contactTimeMatches[id]['set']}
            className='inline-block email-checkbox'
          />
          <div className='email-container inline-block'>
            { fullName }
          </div>
        </div>
      )
    })
  }

  renderPreviewButton() {
    return this.state.togglePreview
      ? <button onClick={this.togglePreview} className='border-box inline-block preview-button v-align-top'>Edit</button>
      : <button onClick={this.togglePreview} className='border-box inline-block preview-button v-align-top'>Preview</button>;
  }

  togglePreview() {
    if(!this.props.email_type.length) return;
    if(this.state.togglePreview) {
      this.email_editor.setValue(this.state.message);
      this.setState({subjectPreview: null});
      this.email_editor.enable();
    } else {
      this.showPreview();
    }
    this.setState({togglePreview: !this.state.togglePreview});
  }

  selectedUserCount() {
    let selected = [];
    this.props.matches.forEach((data) => {
      let matchId = data.match.id;
      if(this.state.matches[matchId]['set']) selected.push(matchId);
    });

    let length = selected.length;
    if(length > 1){
      return length + ' agents';
    } else if(length == 1){
      return '1 agent';
    } else {
      return '';
    }
  }

  selectedContactTimeAgent() {
    let agent;
    const { contactTimeMatches } = this.state;
    const found = this.props.matches.find(data => contactTimeMatches[data.match.id].set);

    if (found) agent = contactTimeMatches[found.match.id].full_name;

    return agent;
  }

  selectedSMS(){
    let agent;
    this.props.matches.forEach((data) => {
      let match = this.state.smsMatches[data.match.id];
      if(match.set) agent = match.full_name;
    });
    return agent;
  }

  emailAttributes(emailType, matchId) {
    return {
      email: {
        email_type: emailType,
        emailable_type: 'Match',
        emailable_id: matchId,
        recipient_id: this.state.matches[matchId].agent_id,
        profile_review_subject: this.state.subject,
        profile_review_message: this.state.message
      }
    }
  }

  saveEmails() {
    let emailType = this.props.email_type;
    if (!emailType.length || !this.selectedUserCount()) return;

    let { matches } = this.state;
    let matchIds = Object.keys(matches).filter(id => matches[id].set);
    let emailData = Object.assign({}, this.props.email_data);
    matchIds.forEach((matchId) => { emailData[matchId][emailType] = this.parseData(matchId); });

    matchIds.map(matchId => {
      new Promise(resolve => {
        EmailPreview.newEmailPreview(this.emailAttributes(emailType, matchId), matchId, true);

        document.body.addEventListener(`email_preview.preview_complete_${matchId}`, event => resolve(event.detail));
      }).then(email => {
        const {
          subject,
          message,
          preview_id,
          to_email,
        } = email;

        emailData[matchId][emailType].subject = subject;
        emailData[matchId][emailType].message = message;
        emailData[matchId][emailType].id = preview_id;
        emailData[matchId][emailType].to_email = to_email;

        this.props.updateState('email_data', emailData);
        this.refreshEmailTemplate();
      });
    });

    $('#dropdown-container-email').slideUp();
  }


  saveSMS() {
    let { smsType, email_data } = this.props;
    if(!smsType.length || !this.selectedSMS()) return;
    let smsMatches = this.state.smsMatches;
    let matchIds = Object.keys(smsMatches).filter(id => smsMatches[id].set);

    matchIds.forEach((matchId) => { email_data[matchId][smsType] = this.parseSMSData(matchId) });

    this.props.updateState('email_data', email_data);
    $('#dropdown-container-sms').slideUp();
    this.refreshSMSTemplate();
  }

  saveContactTime() {
    let smsType = 'contact_time_heads_up_to_agent';
    this.props.updateState('smsType', smsType);
    let contactTimeMatches = this.state.contactTimeMatches;
    let matchIds = Object.keys(contactTimeMatches).filter(id => contactTimeMatches[id].set);
    let { email_data } = this.props;

    matchIds.forEach((matchId) => { email_data[matchId][smsType] = this.parseContactTimeData(matchId) });

    this.props.updateState('email_data', email_data, this.refreshContactTimeTemplate);
    $('#dropdown-container-contact-time').slideUp();
  }

  refreshContactTimeTemplate() {
    this.props.updateState('smsType', '');
    this.setState({
      contactTimeMatches: this.initializeContactTimeMatches(),
      contactDate: '',
      contactTime: '',
      leadTextConsent: false,
    });
  }

  refreshEmailTemplate() {
    this.props.updateState('email_type', '');
    this.setState({
      matches: this.initializeMatchEmails(),
      subject: '',
      message: '',
      smsSubject: '',
      smsMessage: '',
      subjectPreview: '',
      preview_id: '',
      togglePreview: false
    });
    this.email_editor.enable();
    this.email_editor.setValue('');
  }

  refreshSMSTemplate() {
    this.props.updateState('smsType', '');
    this.setState({
      smsMatches: this.initializeMatchSMS(),
      preview_id: '',
      togglePreview: false
    });
    this.smsEditor.setValue('');
  }

  parseData(matchId) {
    return {
      id: this.state.preview_id,
      to_email: this.state.to_email,
      email_type: this.props.email_type,
      emailable_id: matchId,
      recipient_id: this.state.matches[matchId]['agent_id'],
      subject: this.state.subject,
      message: this.state.message,
    }
  }

  parseContactTimeData(matchId) {
    const {
      contactTimeMatches,
      contactDate,
      contactTime
    } = this.state;
    return {
      lead_id: this.props.lead.id,
      agent_id: contactTimeMatches[matchId]['agent_id'],
      textable_id: matchId,
      contact_date: contactDate,
      contact_time: contactTime
    }
  }

  parseSMSData(matchId) {
    // NOTE: We have to send the to_email, subject and message as part of this data
    // because the messaging train expects those in line with how our regular email
    // pop ups work.
    return {
      id: this.state.preview_id,
      name: this.props.smsType,
      textable_id: matchId,
      recipient_id: this.state.smsMatches[matchId].agent_id,
      body: this.state.smsMessage,
    }
  }

  deleteEmail(match_id, email_type, e) {
    var email_data = this.props.email_data;
    delete email_data[match_id][email_type];
    this.props.updateState('email_data', email_data);
  }

  toggleArrowControl(e) {
    this.stopPropagation(e);
    this.props.toggleArrowControl();
  }

  toggleSMSArrowControl(e) {
    this.stopPropagation(e);
    this.props.toggleSMSArrowControl();
  }

  toggleContactTimeArrowControl(e) {
    this.stopPropagation(e);
    this.props.toggleContactTimeArrowControl();
  }

  singleSMSSet() {
    let email_data = this.props.email_data,
        emailKeys = Object.keys(email_data),
        emailTypes = [].concat.apply([], emailKeys.map(key => { return Object.keys(email_data[key]) }));

    return emailTypes.length && emailTypes.includes('agent_call_client_text');
  }

  disableContactTimeSave() {
    const agent = this.selectedContactTimeAgent()
    return !(agent && this.state.contactDate && this.state.contactTime && this.state.leadTextConsent)
  }

  contactTimeSaveClassNames() {
    const classNames = ['border-box', 'inline-block', 'save-button', 'v-align-top', 'float-right']
    if (this.disableContactTimeSave()) {
      classNames.push('disabled-input')
    }
    return classNames.join(' ');
  }

  renderReadyEmails() {
    let match_ids = Object.keys(this.props.email_data);
    return match_ids.map((match_id, idx1) => {
      let email_types = Object.keys(this.props.email_data[match_id]);
      return email_types.map((email_type, idx2) => {
        let email_data = this.props.email_data[match_id][email_type];
        let isTypeSMS = email_data.email_type == 'agent_call_client_text';
        return (
          <div className='ready-emails'>
            <div className='ready-emails-container inline-block email-padding'>
              <div className='attribute display-inline-vertical-center inline-block'>
                { isTypeSMS ? 'SMS Type:' : 'Email Type:' }
              </div>
              <div className='value display-inline-vertical-center inline-block v-align-top'>{this.emailNameConversion()[email_type]}</div>
            </div>
            <div className='ready-emails-container inline-block'>
              <div className='attribute display-inline-vertical-center inline-block'>
                Send To:
              </div>
              <div className='value display-inline-vertical-center inline-block v-align-top'>{this.state.matches[match_id].full_name}</div>
            </div>
            <div className='button-container inline-block'>
              <div onClick={this.deleteEmail.bind(this, match_id, email_type)} className='delete-button'>DELETE</div>
            </div>
          </div>
        );
      });
    });
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  emailNameConversion() {
    return {
      'agent_positive_feedback': 'Agent Positive Feedback',
      'custom_match': 'Custom',
      'agent_call_client': 'Agent Call Client',
      'agent_call_client_text': 'Agent Call Client',
      'bad_number_inform_agent': 'Bad Number Inform Agent',
      'profile_review_agent_timeline': 'Profile Review Agent Timeline',
      'contact_time_heads_up_to_agent': 'Contact Time to Agent and Lead'
    }
  }

  render() {
    let singleSMSSet = this.singleSMSSet();
    return (
      <div className='inner-email-container'>
        <div className='outer-ready-container'>
          {this.renderReadyEmails()}
        </div>
        <div className='email-form border-box'>
          <div className='form-headers border-box'>
            <div className='form-header-container inline-block border-box'>
              <div className='attribute'>Email Type</div>
              <div className='value relative select-box'>
                <select value={this.props.email_type} onChange={this.updateEmailType}>
                  {this.emailFormOptions()}
                </select>
              </div>
            </div>
            <div className='form-header-container v-align-top inline-block border-box'>
              <div className='attribute'>Send To</div>
              <div className='value' style={{height: '40px'}}>
                <div className='dropdown-selection relative' onClick={this.toggleArrowControl}>
                  <div className='selected-user-count'>
                    {this.selectedUserCount()}
                  </div>
                  {this.renderArrowControl()}
                </div>
                <div id='dropdown-container-email' className='dropdown-container none absolute' onClick={this.stopPropagation}>
                  {this.showAgentEmails()}
                </div>
              </div>
            </div>
            <div className='form-header-container inline-block border-box'>
              <div className='attribute'>Send From</div>
              <div className='value'>
                <select>
                  <option value={this.props.email}>{this.props.name}</option>
                </select>
              </div>
            </div>
          </div>
          <div className='form-content'>
            <div className='preview'>
              <div className='attribute'>Subject</div>
              <div className='value'>
                <input className='border-box subject inline-block' value={this.state.subjectPreview || this.state.subject} onChange={this.updateSubject}/>
                {this.renderPreviewButton()}
                <button onClick={this.saveEmails} className='border-box inline-block save-button v-align-top'>Save</button>
            </div>
            </div>
            <div className='attribute'>Body</div>
            <div className='content'>
              <div id="wysihtml5-toolbar" className='none'>
                <div className="wysihtml5-toolbar"></div>
              </div>
              <textarea id='email-body' className='border-box email-body' value=''></textarea>
            </div>
          </div>
        </div>
        <div className='email-form border-box'>
          <div className='form-headers border-box'>
            <div>
              <div className='attribute'>Preferred Time to Contact Client</div>
            </div>
            <div className='form-header-container v-align-top inline-block border-box margin-right'>
              <div className='attribute'>Agent</div>
              <div className='value' style={{height: '40px'}}>
                <div className='dropdown-selection relative contact-time-agent' onClick={this.toggleContactTimeArrowControl}>
                  <div className='selected-user-count'>
                    {this.selectedContactTimeAgent()}
                  </div>
                  {this.renderContactTimeArrowControl()}
                </div>
                <div id='dropdown-container-contact-time' className='dropdown-container none absolute' onClick={this.stopPropagation}>
                  {this.contactTimeShowAgentNames()}
                </div>
              </div>
            </div>
            <div className='form-header-container v-align-top inline-block border-box'>
              <div className='attribute'>Sends</div>
              <div className='italic'>Contact Time Heads Up to Agent SMS</div>
              <div className='italic'>Contact Time Heads Up to Lead SMS</div>
            </div>
          </div>
          <div className='form-headers border-box'>
            <div className='form-header-container inline-block border-box margin-right'>
              <div className='attribute'>Date</div>
              <input
                className='value'
                type='date'
                name='contact-date'
                value={this.state.contactDate}
                id='contact-date'
                onChange={this.updateContactDate}
              />
            </div>
            <div className='form-header-container v-align-top inline-block border-box'>
              <div className='attribute'>Local Time</div>
              <div className='value' style={{height: '40px'}}>
                <div className='value relative select-box'>
                  <select
                    className='local-time'
                    value={this.state.contactTime}
                    onChange={this.updateContactTime}
                    style={{fontSize: '18px'}}
                  >
                    {this.timeSlotsOptions()}
                  </select>
                  <i className='fa fa-caret-down absolute'></i>
                </div>
              </div>
            </div>
          </div>
          <div className='form-headers border-box'>
            <div className='form-header-container inline-block border-box'>
              <div className='relative select-box'>
                <label className='consent-label'>
                  <input type="checkbox" checked={this.state.leadTextConsent} onChange={this.updateLeadTextConsent} className='consent-box'/>
                  Obtained consent to send text message(s)
                </label>
              </div>
            </div>
            <button
              onClick={this.saveContactTime}
              className={this.contactTimeSaveClassNames()}
              disabled={this.disableContactTimeSave()}
            >
              Save
            </button>
          </div>
        </div>
        <div className='email-form border-box'>
          <div className='form-headers border-box'>
            <div className='form-header-container inline-block border-box'>
              <div className='attribute'>SMS Type</div>
              <div className='value relative select-box'>
                <select
                  value={this.props.smsType}
                  onChange={this.updateSMSType}
                  disabled={singleSMSSet}
                  className={singleSMSSet ? 'disabled-select-box' : ''}
                >
                  {this.SMSFormOptions()}
                </select>
                <i className='fa fa-caret-down absolute' style={singleSMSSet ? { color: 'white'} : {}}></i>
              </div>
            </div>
            <div className='form-header-container v-align-top inline-block border-box'>
              <div className='attribute'>Send To</div>
              <div className='value' style={{height: '40px'}}>
                <div
                  className={singleSMSSet ? 'disabled-select-box relative' : 'dropdown-selection relative'}
                  onClick={singleSMSSet ? '' : this.toggleSMSArrowControl}
                >
                  <div className='selected-user-count'>
                    { this.selectedSMS() }
                  </div>
                  {this.renderSMSArrowControl()}
                </div>
                <div id='dropdown-container-sms' className='dropdown-container none absolute' onClick={this.stopPropagation} disabled={singleSMSSet}>
                  {this.showAgentNames()}
                </div>
              </div>
            </div>
            <div className='form-header-container v-align-top inline-block border-box'>
              <div className='attribute'>&nbsp;</div>
              <div className='value'>
                <button
                  onClick={this.saveSMS}
                  className={ singleSMSSet ? 'border-box inline-block save-button v-align-top disabled-input' : 'border-box inline-block save-button v-align-top'}
                  disabled={singleSMSSet}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className='form-content'>
            <div className='preview'>
              <div className='attribute'>Preview</div>
              <div className='relative'>
                <div className={singleSMSSet ? 'sms-body-overlay' : ''} />
                <textarea id='sms-body' className='border-box sms-body' value=''></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
