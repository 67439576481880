import { Component } from 'react';

import TableRow from './TableRow';
import TableCol from './TableCol';

export default class FeedCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersWatching: ''
    }
    this.success = this.success.bind(this);
    this.error = this.error.bind(this);
  }

  componentDidMount() {
    AjaxRequest.get(
      `jobs/whos_watching/${this.queueId(this.props.feed.channel)}`,
      this.success,
      this.error
    )
  }

  success(data) {
    this.setState({ usersWatching: data.viewers })
  }

  error(data) {
    alert('An error occurred.')
  }

  queueId(tab) {
    return `queue_menu_${tab.replace(/\W/g, '_').toLowerCase()}`;
  }

  convertNumber(number) {
    return number == null
      ? '-'
      : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  render() {
    let {
      feed,
      manager,
      segment,
    } = this.props;

    if (this.isJsonString(feed.data)) { feed.data = JSON.parse(feed.data) };

    return (
      <TableRow>
        <TableCol style={{ width: 300 }}>
          <span style={{ fontWeight: 500 }}>{feed.channel}</span>
          <div style={{ marginTop: 6, fontStyle: 'italic' }}>
            last updated: {feed.updated_at}
          </div>
        </TableCol>
        <TableCol style={{ width: 100 }}>{this.convertNumber(feed.data.feed_type)}</TableCol>
        <TableCol style={{ width: 100 }}>{this.convertNumber(feed.data.total)}</TableCol>
        <TableCol style={{ width: 100 }}>{this.convertNumber(feed.data[manager])}</TableCol>
        <TableCol style={{ width: 100 }}>{this.convertNumber(feed.data.intent)}</TableCol>
        <TableCol style={{ width: 100 }}>{this.convertNumber(feed.data.nonintent)}</TableCol>
        <TableCol style={{ width: 120 }}>{this.convertNumber(feed.data[segment])}</TableCol>
        <TableCol style={{ width: 300 }}>
          <div id={this.queueId(feed.channel)}>
            <span className="tab-names">{this.state.usersWatching}</span>
          </div>
        </TableCol>
      </TableRow>
    );
  }
}
