const Footer = (props) => {
  const {
    agentId,
    currentAreas,
    currentVisible,
    serviceAreas,
    serviceAreasPath,
    updateState,
  } = props;

  const dismissBtnText = currentVisible ? 'Done' : 'Discard';
  const addRmvBtnText = currentVisible ? 'Save' : 'Update Areas';
  const addRmvBtnClass = currentVisible ? 'btn-primary' : 'btn-warning';

  function handleDismiss(event) {
    if (!currentVisible) updateState('currentVisible', true);
  }

  function handleZipCodeUpdates(event) {
    if (currentVisible) {
      submitZipCodes(event);
    } else {
      mergeNewAreas(event);
      updateState('currentVisible', true);
    }
  }

  function mergeNewAreas(event) {
    const newAreas = selectedSearchResults(event);

    updateState('currentAreas', { ...currentAreas, ...newAreas });
  }

  function submitZipCodes(event) {
    toggleModalOverlay('add');

    fetch(
      serviceAreasPath,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ service_areas: { zip_codes: selectedZipCodes(event), agent_id: agentId } }),
      }
    )
    .then(response => response.json())
    .then(response => {
      toggleModalOverlay('remove');

      if (response.success) {
        updateState('serviceAreas', {});
        updateState('header', { klass: 'success', message: 'Service areas updated' });
      } else {
        updateState('header', { klass: 'danger', message: response.errors.join(', ') });
      }
    });
  }

  function toggleModalOverlay(action) {
    const modalContent = document.querySelector('.service-area-modal .modal-content');

    modalContent.classList[action]('submitting');
  }

  function selectedZipCodes(event) {
    const modalContent = event.target.closest('.modal-content');
    const selectedOptions = modalContent.querySelectorAll('.geo-wrapper.checked');
    const values = [];

    selectedOptions.forEach(option => values.push(option.dataset.geo));

    return values;
  }

  function selectedSearchResults(event) {
    const modalContent = event.target.closest('.modal-content');
    const serviceAreas = modalContent.querySelectorAll('.service-area.has-checked');
    const areas = {};

    serviceAreas.forEach(serviceArea => {
      const selectedOptions = serviceArea.querySelectorAll('.service-option.checked:not(.select-all)');
      const { city } = serviceArea.dataset;
      const values = [];

      selectedOptions.forEach(option => values.push(option.dataset.value));

      areas[city] = values;
    });

    return areas;
  }

  return (
    <div className='modal-footer'>
      <button type='button' className={ `btn ${addRmvBtnClass} add-remove-zip-codes` } onClick={ handleZipCodeUpdates }>{ addRmvBtnText }</button>
      <button type='button' className='btn btn-default ml-10' onClick={ handleDismiss } data-dismiss={ currentVisible && 'modal' }>{ dismissBtnText }</button>
    </div>
  );
};

export default Footer;
