import React, { useState, useEffect } from 'react';
import AltosDetails from './AltosDetails';
import CallStats from './CallStats';
import CallDispositions from './CallDispositions';
import AgentDetails from './AgentDetails';
import Fetch from '../../../common/fetch';

import './styles/index.scss';

const initData = {
  callStats: {},
  altosAgentInfo: {},
  rexAgentInfo: {},
}

const ValidationDialer = (props) => {
  const [data, setData] = useState(initData);
  const [pause, setPause] = useState(false);

  const {
    callStats,
    altosAgentInfo,
    rexAgentInfo,
  } = data;

  const {
    stats_dialer_claim_validation_path,
    new_dialer_claim_validation_path,
    dialer_claim_validation_path,
  } = props;

  const handleToggledDetails = () => {
    const toggledDetailsClaimed = document.getElementById('toggled-details-claimed');
    const angleUpClaimed = document.getElementById('angle-up-claimed');
    const angleDownClaimed = document.getElementById('angle-down-claimed');
    const toggledDetailsAltos = document.getElementById('toggled-details-altos');
    const angleUpAltos = document.getElementById('angle-up-altos');
    const angleDownAltos = document.getElementById('angle-down-altos');

    toggledDetailsClaimed.classList.toggle('hidden');
    angleUpClaimed.classList.toggle('hidden');
    angleDownClaimed.classList.toggle('hidden');
    toggledDetailsAltos.classList.toggle('hidden');
    angleUpAltos.classList.toggle('hidden');
    angleDownAltos.classList.toggle('hidden');
  }

  useEffect(() => {
    ReferralExchange.updateTitle('Claim Validation Dialer');
    fetchNewAgent();
  }, []);

  useEffect(() => {
    fetchUserStats();
  }, [rexAgentInfo.agent_id]);


  const fetchNewAgent = () => {
    fetch(
      new_dialer_claim_validation_path,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        const { altos_agent, rex_agent } = response;

        const newData = {
          ...data,
          altosAgentInfo: altos_agent,
          rexAgentInfo: rex_agent
        };

        setData(newData);
      } else {
        Util.createFlash(response.errors.join(', '), 'warning');
      }
    });
  }

  const fetchUserStats = () => {
    fetch(
      stats_dialer_claim_validation_path,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        const { agents_completed, agents_remaining } = response;

        const newData = {
          ...data,
          callStats: { agents_completed, agents_remaining }
        };
        setData(newData);
      } else {
        Util.createFlash(response.errors.join(', '), 'warning');
      }
    });
  }

  const togglePause = () => {
    setPause(!pause);
  };

  const submit = async (e) => {
    const button = e.currentTarget;

    button.disabeld = true;

    const resp = await Fetch.puts({
        url: dialer_claim_validation_path,
        payload: {
          validated: button.value,
          claim_validation_id: rexAgentInfo.claim_validation_id,
        }
      });

    if (resp.success) {
      if (callStats.agents_remaining === 1) {
        alert('There are no more agents remaining to validate.');
        fetchUserStats();
      }
      if (!pause) fetchNewAgent();
    } else {
      Util.createFlash(resp.errors.join(', '), 'danger');
    }

    if (pause) window.location = '/dialer';

    button.disabeld = false;
  }

  const handleServiceArea = (serviceArea) => {
    if (serviceArea) {
      return serviceArea.map((city, i)=>{ return <span key={i}>{city}</span>});
    }
  };

  return (
    <div className="claim-validation-dialer">
      <CallStats
        callStats={callStats}
        togglePause={togglePause}
        pause={pause}
      />
      <div className="dialer-agent-details-wrapper">
        <div className="dialer-agent-details">
          <AgentDetails
            handleToggledDetails={handleToggledDetails}
            rexAgentInfo={rexAgentInfo}
            altosAgentInfo={altosAgentInfo}
            handleServiceArea={handleServiceArea}
          />
          <AltosDetails
            handleToggledDetails={handleToggledDetails}
            altosAgentInfo={altosAgentInfo}
            handleServiceArea={handleServiceArea}
          />
        </div>
      </div>
      <CallDispositions
        submit={submit}
        agentsRemaining={callStats.agents_remaining}
      />
    </div>
  )
}

export default ValidationDialer;
