import { Component } from 'react';

export default class MatchingStatus extends Component {
  constructor( props ) {
    super(props);

    this.options = {
      personal: [
        'Good - Contacted',
        'Good - Answering Machine/Name',
        'Good - Phone/Address Records Match',
        'Good - Passes Qualification'
      ],
      good: [
        'Good - Contacted'
      ]
    }
  }

  _maxHCRequalAttempts() {
    return this.props.data.home_captain && this.props.data.qual_attempt_count >= 2
  }

  render() {
    let lead = this.props.data.lead;
    var hc = this.props.data.origin.indexOf( 'Home Captain' ) !== -1;
    var intent = this.props.data.intent;

    if (this._maxHCRequalAttempts()) {
      this.options = {
        personal: [
          'Good - Contacted',
          'Good - Answering Machine/Name',
          'Good - Phone/Address Records Match',
          'Good - Passes Qualification'
        ],
        good: [
          'Good - Contacted',
          'Good - Assume Intent'
        ]
      }
    }

    if (lead.lead_type === 'seller' && (lead.origination === 'Microsite' || lead.origination === 'Organic')) {
      this.options.personal.push('Good - Bad Number Only Seller (GGGB)');
      this.options.personal = [...new Set(this.options.personal)];
    }

    return(
      <div className='field full selectdd'>
        <div className='label'>
          Matching Status
        </div>
        <select name='note_status' value={ this.props.data.status_button.note_status } onChange={ this._handleChange.bind(this) }>
          <option value=''>Select Status</option>
          <optgroup label='Matching'>
            { !intent || hc ? this.options.good.map( this._renderOptions, this ) : this.options.personal.map( this._renderOptions, this ) }
          </optgroup>
        </select>
      </div>
    )
  }

  _renderOptions( option, i ) {
    return(
      <option key={ i } value={ option }>{ option }</option>
    )
  }

  _handleChange( e ) {
    this.props.data.status_button.note_status = e.target.value;
    this.props.data.pmr_form.qual_status = e.target.value;
    this.props.updateState( this.props.data );
  }
}
