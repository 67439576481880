import Table from './../table/Wrapper';

const Wrapper = ({ userName, userData }) => {
  const {
    allocated_totals,
    requested_totals,
    batches,
  } = userData;

  return (
    <div className="row user-batch-assign-container">
      <div className="col-md-12">
        <h1>{ userName } <span className="batch-assign-totals">{ `${allocated_totals || 0}/${requested_totals || 0}` }</span></h1>
        <Table batches={ batches } />
      </div>
    </div>
  );
};

export default Wrapper;
