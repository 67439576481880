import History from './views/table/Wrapper';
import moment from 'moment';

import { Component } from 'react';

const prospectEmails = (event) => {
  const agentRecruitContainer = event.target.closest('.agent-recruit');
  const { userEmails } = agentRecruitContainer.querySelector('.agent-recruit-card').dataset;

  return JSON.parse(userEmails);
};

export default class Container extends Component {
  constructor(props) {
    super(props);

    this.uniqId = `email-history-${Math.random().toString(36).slice(-10)}`;

    this.state = {
      fetched: false,
      history: [],
      requesting: false,
    };

    this.handleNewEmail = this.handleNewEmail.bind(this);
    this.handleRequest = this.handleRequest.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('new_email_popup.closed', this.handleNewEmail);
  }

  handleNewEmail(event) {
    const rtaPancake = document.getElementById('recruit_top_agents_pancake');
    const { targetId } = rtaPancake.dataset;
    const { fetched } = this.state;

    if (targetId === this.uniqId) {
      rtaPancake.removeAttribute('data-target-id');

      if (/create/.test(event.detail) && fetched) {
        const container = document.getElementById(targetId);

        setTimeout(() => {
          this.setState({ fetched: false });
          container.click();
        }, 1000);
      }
    }
  }

  fetchHistory(event) {
    const { target } = event;

    this.setState({ requesting: true });

    fetch(
      this.buildURI(event),
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-type': 'application/json',
        }
      }
    )
    .then(response => response.json())
    .then(response => {
      this.setState({ fetched: true, requesting: false, history: response.emails });

      if (response.count === 0) this.handleNoData(target);
    });
  }

  buildURI(event) {
    const emails = prospectEmails(event);
    const params = new URLSearchParams();

    const {
      action,
      emailable_id,
      emailable_type,
      recipient_type,
    } = this.props;

    params.append('recruit_top_agent[emailable_id]', emailable_id);
    params.append('recruit_top_agent[emailable_type]', emailable_type);
    params.append('recruit_top_agent[recipient_type]', recipient_type);
    emails.forEach(email => params.append('recruit_top_agent[to_email][]', email));

    return `${action}?${params}`;
  }

  handleNoData(target) {
    const wrapper = target.closest('.recruit-email-history-wrapper');
    const container = wrapper.querySelector('.recruit-email-history');

    container.classList.add('overlay', 'no-data');
  }

  handleRequest(event) {
    const emails = prospectEmails(event);
    const { fetched } = this.state;
    const { target } = event;

    if (emails.length) {
      if (!fetched) this.fetchHistory(event);
    } else {
      this.handleNoData(target);
    }
  }

  render() {
    const {
      fetched,
      history,
      requesting,
    } = this.state;

    const overlay = fetched ? '' : 'overlay';
    const fetching = requesting ? 'fetching' : '';

    return (
      <div id={ this.uniqId } className={ `recruit-email-history ${overlay} ${fetching}` } onClick={ this.handleRequest }>
        <History
          fetched={ fetched }
          history={ history }
        />
      </div>
    );
  }
}
