import { Component } from 'react';

export default class WorkingListReviewView extends Component {
  constructor(props){
    super(props);
  }

  render(){
    let { brandsSelected, followUpTime, followUpMessage, adminsSelected} = this.props.state;
    return (
      <div className="content">
        <div className="review">
          <div className="review-list">
            <h3>Brands</h3>
            {brandsSelected.map((brand, idx) => {
              return <div className="review-list-item" key={idx}>{brand.name}</div>
            })}
          </div>
          <div className="review-list">
            <h3>Sales Admins</h3>
            {adminsSelected.map((admin, idx) => {
              if (admin) {
                return <div className="review-list-item" key={idx}>{admin.name}</div>
              }
            })}
          </div>
          <div className="review-list">
            <h3>Follow Up</h3>
            <div className="review-list-item">Time:   {followUpTime || 'None'}</div>
            <div className="review-list-item">Message: {followUpMessage || 'None'}</div>
          </div>
        </div>
        <div className="prompt-options">
          <div className="prompt-cancel" onClick={this.props.loadPrevView}>
            Back
          </div>
          <div className="prompt-confirm" onClick={this.props.createWorkingEntry}>
            Submit
          </div>
        </div>
      </div>
    )
  }
}