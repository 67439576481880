import { Component } from 'react';

export default class AgentsModal extends Component {
  constructor(props){
    super(props)

    this.state = this.initialState();
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showLoading = this.showLoading.bind(this);
    this.resetState = this.resetState.bind(this);
    this.removeAgent = this.removeAgent.bind(this);
    this.addSlot = this.addSlot.bind(this);
    this.removeSlot = this.removeSlot.bind(this);
  }

  initialState(){
    return{
      selectedAgentToRemove: '',
      submitting: false,
      removalCompleted: false
    }
  }

  componentDidMount(){
    $('.container-fluid').css('background-color', '#f2f2f2')
    $('.cloud_indicator_borders').css('background-color', '#f2f2f2')
  }

  showModal(){
    return this.props.state.showAgentModal
      ? 'modal-overlay'
      : 'hidden';
  }

  showLoading() {
    return this.state.submitting
      ? 'modal-overlay-submitting'
      : 'hidden';
  }

  closeModal(){
    this.props.updateState({ showAgentModal: false });
    this.setState({ selectedAgentToRemove: '' });
  }

  resetState(){
    this.setState({ selectedAgentToRemove: '' })
  }

  removeAgent(agentId){
    this.setState({ selectedAgentToRemove: '', submitting: true })
    this.props.removeAgent(agentId)
      .then(() => this.setState({ submitting: false }))
  }

  addSlot(){
    if (confirm('Do you want to add a slot?')) {
      this.setState({ submitting: true })
      this.props.addSlot().then(() => this.setState({ submitting: false }))
    }
  }

  removeSlot(){
    if (confirm('Do you want to delete this slot?')) {
      this.setState({ submitting: true })
      this.props.removeSlot().then(() => this.setState({ submitting: false }))
    }
  }

  modalContent(){
    let { agents, metrics } = this.props.state;
    let { selectedAgentToRemove } = this.state;

    if(typeof selectedAgentToRemove !== 'number'){
      let agentSlots = agents.slice(0);
      let agentCount = agentSlots.length;
      let emptySlots = metrics.slots - agentCount;
      for (let i = 0; i < emptySlots; i++) { agentSlots.push('blank'); };
      agentSlots.push('add-slot');

      return(
        <div className="content">
          <div className="list">
            <div className="list-inner">
              {agentSlots.map((agent, idx) => {
                if(typeof agent === 'object'){
                  let execs = agent.sales_executives.join(', ');
                  return (
                    <div className="list-item-centered" key={idx}>
                      <div className="list-item-centered-detail w250 name">{agent.full_name}</div>
                      <div className="list-item-centered-detail w250 execs">Executives: {execs}</div>
                      <div className="list-item-centered-detail">
                        <div className="close-button-2" onClick={() => this.setState({ selectedAgentToRemove: idx })}>
                          <i className="fa fa-times-circle" />
                        </div>
                      </div>
                    </div>
                  );
                } else if (agent === 'blank') {
                  return (
                    <div className="list-item-centered just-space-between" key={idx}>
                      <div className="list-item-centered-detail name">
                        <div>Empty</div>
                      </div>
                      <div className="remove-button" onClick={this.removeSlot}>
                        Remove slot
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div className="list-item-centered just-center" key={idx}>
                      <div className="list-item-centered-detail name">
                        <div className="add-button" onClick={this.addSlot}>
                          <i className="fa fa-plus-circle" />
                          &nbsp;Add More Slots
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>
      )
    } else {
      let agent = agents[selectedAgentToRemove];
      let execs = agent.sales_executives.join(', ');
      return (
        <div className="content">
          <div className="list">
            <div className="list-inner">
              <div className="list-item-centered">
                <div className="list-item-centered-detail w250 name">{agent.full_name}</div>
                <div className="list-item-centered-detail w250 execs">Executives: {execs}</div>
              </div>
            </div>
          </div>
          <div className="prompt">
            Are you sure you want to remove this agent?
          </div>
          <div className="prompt-options">
            <div className="prompt-cancel" onClick={() => this.setState({ selectedAgentToRemove: '' })}>Cancel</div>
            <div className="prompt-confirm" onClick={() => this.removeAgent(selectedAgentToRemove)}>Remove</div>
          </div>
        </div>
      )
    }
  }

  render(){
    return(
      <div className={this.showModal()} onClick={this.closeModal}>
        <div className="title-modal" onClick={(e) => e.stopPropagation() }>
          <div className="header">
            <h3>Edit Agents</h3>
            <div className="close-button" onClick={this.closeModal}>
              <i className="fa fa-times-circle" />
            </div>
          </div>
          { this.modalContent() }
          <div className={this.showLoading()}>
            <i className='fa fa-refresh fa-spin fa-5x fa-fw'></i>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      </div>
    );
  }
}
