import React from 'react';

const Insights = ({ lifetime_earnings, lifetime_closings, marketing_spend, commission_opportunity }) => {

  const roundValue = (value) => (
    Math.round(value).toLocaleString('en')
  );

  return (
    <>
      <div className="columns-wrapper">
        <div className="column-1">
          <h4>Lifetime Earnings</h4>
          <p>${roundValue(lifetime_earnings)}</p>

          <h4>Lifetime Closings</h4>
          <p>{roundValue(lifetime_closings)}</p>

          <h4>30 Day Marketing Spend</h4>
          <p>${roundValue(marketing_spend)}</p>

          <h4>30 Day Commission Opportunity</h4>
          <p>${roundValue(commission_opportunity)}</p>
        </div>
      </div>
    </>
  )
}

export default Insights;
