import { Component }  from 'react';

import './styles/index.scss';

const initState = {
  rta_agent_id: '',
  canonical_aid: '',
  contact: '',
  source: '',
};

export default class Container extends Component {
  constructor() {
    super();

    this.state = initState;

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleNewContact = this.handleNewContact.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validatePhone = this.validatePhone.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.toggleErrors = this.toggleErrors.bind(this);
    this.toggleButtons = this.toggleButtons.bind(this);
    this.dispatchSuccessEvent = this.dispatchSuccessEvent.bind(this);
  }

  componentDidMount() {
    const modal = document.getElementById('add-rta-contact-container');

    $(modal).on('hidden.bs.modal', () => {
      this.setState(initState);
      this.toggleErrors('remove');
    });

    modal.addEventListener('add_rta.contact', e => this.showModal(e.detail));
  }

  showModal({ rta_agent_id, canonical_aid, source }) {
    const modal = document.getElementById('add-rta-contact-container');

    this.setState({ rta_agent_id, canonical_aid, source });
    this.toggleButtons('enable');
    $(modal).modal('show');
  }

  hideModal(event) {
    const { currentTarget } = event;
    const modal = document.getElementById('add-rta-contact-container');

    if (currentTarget && currentTarget.disabled) return;

    $(modal).modal('hide');
  }

  handleNewContact(event) {
    const { value } = event.currentTarget;

    this.setState({ contact: value });
  }

  toggleErrors(operation, error) {
    const input = document.getElementById('add-rta-contact');
    const formGroup = input.closest('.form-group');
    const inputError = formGroup.querySelector('.input-error');
    const action = /add/.test(operation) ? 'add' : 'remove';

    formGroup.classList[action]('has-error');
    inputError.innerText = error || '';
  }

  validatePhone() {
    const phoneRegex = /^(\+?\d{1,3}[.-\s]?)?\(?([0-9]{3})\)?[.-\s]?([0-9]{3})[.-\s]?([0-9]{4})$/;
    const { contact } = this.state;

    return phoneRegex.test(contact);
  }

  validateEmail() {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { contact } = this.state;

    return emailRegex.test(contact);
  }

  toggleButtons(operation) {
    const modal = document.getElementById('add-rta-contact-container');
    const buttons = modal.querySelectorAll('button');
    const disabled = /disable/.test(operation);
    const content = disabled ? '<i class="fa fa-spinner fa-spin-2x"></i>' : 'Add Contact';

    buttons.forEach(button => {
      if (button.id) button.innerHTML = content;
      button.disabled = disabled;
    });
  }

  dispatchSuccessEvent() {
    const { contact } = this.state;
    const contactType = this.validatePhone() ? 'phone' : 'email';
    const event = new CustomEvent('add_rta.contact.success', { detail: { contactType: contactType, contact: contact } });

    document.body.dispatchEvent(event);
  }

  handleSubmit(event) {
    event.preventDefault();

    const {
      rta_agent_id,
      canonical_aid,
      contact,
      source,
    } = this.state;

    this.toggleErrors('remove');

    if (this.validatePhone() || this.validateEmail()) {
      const contactType = this.validatePhone() ? 'phones' : 'emails';
      const params = JSON.stringify({ rta_agent_id, canonical_aid, ...{ [contactType]: [contact] } });

      this.toggleButtons('disable');

      fetch(
        '/recruit_top_agent/contacts',
        {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json'
          },
          body: params,
        }
      )
      .then(response => response.json())
      .then(response => {
        this.toggleButtons('enable');

        if (response.success) {
          const modal = document.getElementById('add-rta-contact-container');
          const closeButton = modal.querySelector('button[class="close"]');

          setTimeout(() => {
            closeButton.click();
            this.dispatchSuccessEvent();
            Util.createFlash('Added new contact', 'success', '.dialer-flash-wrapper');
          }, 500);
        } else {
          this.toggleErrors('add', response.errors.join(', '));
        }
      })
      .catch(error => console.log(error));
    } else {
      this.toggleErrors('add', 'Invalid contact');
    }
  }

  render() {
    const { contact } = this.state;

    return (
      <div id="add-rta-contact-container" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
              <div className="modal-header text-center">
                <button className="close" onClick={ this.hideModal }>
                  <span>&times;</span>
                </button>
                <h2>Add New Contact</h2>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="add-rta-contact">New Contact</label>
                  <input type="text" id="add-rta-contact" className="form-control" value={ contact } onChange={ this.handleNewContact } />
                  <div className="input-error"></div>
                </div>
              </div>
              <div className="modal-footer text-center">
                <button type="button" className="btn btn-md btn-wide btn-rex-default mr-15" onClick={ this.hideModal }>Cancel</button>
                <button type="button" id="submit-new-contact" className="btn btn-md btn-wide btn-rex-orange" onClick={ this.handleSubmit }>Add Contact</button>
              </div>
          </div>
        </div>
      </div>
    );
  }
}
