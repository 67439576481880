import React, { useState, useEffect, useRef } from 'react';
import { timer } from '../../../../util/Util';

const Controls = (props) => {
  const { agent, paused, setAttributeState } = props;
  const klass = paused ? 'fa-play' : 'fa-pause';
  const text = paused ? 'RESUME' : 'PAUSE';
  const [duration, setDuration] = useState('0:00');
  const [pauseIcon, setPauseIcon] = useState(klass);
  const [pauseText, setPauseText] = useState(text);
  const intervalId = useRef(undefined);

  function handlePause(event) {
    setAttributeState('paused', !paused);
    setPauseIcon(klass);
    setPauseText(text);
  }

  useEffect(() => {
    setPauseIcon(klass);
    setPauseText(text);
  }, [paused]);

  useEffect(() => {
    const timerDisplay = document.getElementById('rex-stat-timer');

    timerDisplay.addEventListener('agent_recruitment.start_timer', () => { timer(intervalId, setDuration); });
  });

  return (
    <div className="col-md-5">
      <button className="btn btn-xl btn-rex-blue btn-pause-dialer" onClick={ handlePause }>
        { pauseText } <i className={ `fa ${pauseIcon} ml-5` }></i>
      </button>
      <div className="rex-stat ml-30">
        <span className="rex-stat-pill">
          <strong id="rex-stat-timer">{ duration }</strong>
        </span>
      </div>
      <div className="rex-stat ml-30">
        <i className="fa fa-headphones text-blue mr-5"></i>
        <strong>{ agent.phone || 'No phone number' }</strong>
      </div>
    </div>
  )
};

export default Controls;
