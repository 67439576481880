import { Component } from 'react';

export default class MultipleLeads extends Component {
  renderUsers() {
    return this.props.lead.users.map(function(lead, idx) {
      return (
        <div className='user-profile' key={idx}>
          <a className='inline-block lead-link' target='_blank' href={this.userLink(lead.id)}>Lead: {lead.id}</a>
          <div className='status inline-block'>{lead.status}</div>
          {this.sameLead(lead.id)}
        </div>
      )
    }.bind(this))
  }

  userLink(id) {
    return `/l/${id}`;
  }

  sameLead(id) {
    if (id === this.props.lead.id) {
      return (
        <div className='inline-block current-lead'>Current Lead</div>
      );
    }
  }

  render() {
    return (
      <div className='cs-overshadow'>
        <div className='profile-review-multiple-container relative'>
          <div className='close absolute'>
            <i onClick={this.props.toggleMultipleContainer} className='fa fa-times'></i>
          </div>
          { this.renderUsers() }
        </div>
      </div>
    );
  }
}
