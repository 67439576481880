import { Component } from 'react';
import request from 'superagent';

import StuckLeadModal from './StuckLeadModal';
export default class StuckLeadTool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initLeads: [],
      leads: [],
      selectedLeadIdx: null,
      modalView: 'edit',
      submitTrainCar: null,
      changes: [],
      success: null,
      error: null,
    }

    this.objectsAreSame = this.objectsAreSame.bind(this);
    this.selectLeadRow = this.selectLeadRow.bind(this);
    this.revertLeadData = this.revertLeadData.bind(this);
    this.fetchStuckLead = this.fetchStuckLead.bind(this);
    this.quickSubmit = this.quickSubmit.bind(this);
    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    this.fetchStuckLeads();
  }

  fetchStuckLeads() {
    request
      .get('/stuck_leads/fetch_stuck_leads/')
      .then(({ body }) => {
        this.setState({
          initLeads: body.stuck_leads,
          leads: this.dupObject(body.stuck_leads),
        })
        window.leads = body.stuck_leads
      })
  }

  fetchStuckLead(stuckLeadId, stuckLeadIdx, e) {
    e.stopPropagation();
    const { initLeads, leads } = this.state;

    request
      .get('/stuck_leads/fetch_stuck_lead/')
      .query({ new_lead_id: stuckLeadId })
      .then(({ body }) => {
        const refreshedLead = body.stuck_lead;

        initLeads.splice(stuckLeadIdx, 1, refreshedLead);
        leads.splice(stuckLeadIdx, 1, this.dupObject(refreshedLead))

        this.setState({
          initLeads,
          leads,
        })
      })
  }

  dupObject(leads) {
    return JSON.parse(JSON.stringify(leads))
  }

  objectsAreSame(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  selectLeadRow(selectedLeadIdx) {
    this.setState({ selectedLeadIdx });
  }

  tableClass(i) {
    return this.state.selectedLeadIdx === i
      ? 'table-row selected'
      : 'table-row';
  }

  revertLeadData(e) {
    e.stopPropagation();
    const leadIdx = e.target.dataset.leadIndex;
    const lead = this.state.leads[leadIdx];

    request
      .post('/stuck_leads/revert_lead_data')
      .query({ new_lead_id: lead.id })
      .then(this.fetchStuckLeads())
  }

  revertButton(leadIdx) {
    const initLead = this.state.initLeads[leadIdx];
    let disabled = initLead.original_data && !this.objectsAreSame(initLead.data, initLead.original_data)
      ? false
      : true;

    if (initLead.current_train_car === "NewLead::TailWorker") {
      disabled = true;
    }


    return (
      <input
        type="submit"
        value="Revert"
        disabled={disabled}
        data-lead-index={leadIdx}
        className="revert-button"
        onClick={this.revertLeadData}
      />
    )
  }

  refreshButton(leadIdx) {
    const initLead = this.state.initLeads[leadIdx];

    return (
      <input
        type="submit"
        value="Refresh"
        data-lead-index={leadIdx}
        className="revert-button"
        onClick={(e) => this.fetchStuckLead(initLead.id, leadIdx, e)}
      />
    )
  }

  copyToClipboard(id, e) {
    e.stopPropagation();

    const input = document.createElement("input");
    document.body.appendChild(input);
    input.value = id;
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  }

  copyButton(leadIdx) {
    const initLead = this.state.initLeads[leadIdx];

    return (
      <div
        className="copy-button"
        onClick={(e) => this.copyToClipboard(initLead.id, e)}
        onMouseEnter={(e) => e.currentTarget.innerText = 'Copy'}
        onMouseLeave={(e) => e.currentTarget.innerText = initLead.id }
      >
        {initLead.id}
      </div>
    )
  }

  quickSubmitButton(leadIdx) {
    return (
      <input
        type="submit"
        value="Quick Submit"
        data-lead-index={leadIdx}
        onClick={(e) => this.quickSubmit(leadIdx, e)}
      />
    )
  }

  quickSubmit(leadIdx, e) {
    e.stopPropagation();

    const lead = this.state.leads[leadIdx];
    const options = {
      new_lead_id: lead.id,
      new_lead_data: JSON.stringify(lead.data),
      train_car: lead.current_train_car,
    }

    request
      .post('/stuck_leads/submit_stuck_lead/')
      .query(options)
      .then(setTimeout(() => this.fetchStuckLead(lead.id, leadIdx, e), 5000))
      .catch(err => console.warn(JSON.parse(err)))
  }

  stuckLeadsCount() {
    return this.state.leads
      .filter(({ current_train_car }) => current_train_car !== 'NewLead::TailWorker')
      .length
  }

  render() {
    const { leads, selectedLeadIdx, initLeads } = this.state;
    return (
      <div className="stuck-leads-container-inner">
        <div className="stuck-leads-metrics">
          <span><b>Stuck Lead Metrics</b></span>
          <span>Stuck Leads Count: <b>{this.stuckLeadsCount()}</b></span>

        </div>
        <div className="stuck-leads-table">
          <div className="table-header">
            <div className="column-name col-1">First Name</div>
            <div className="column-name col-1">Last Name</div>
            <div className="column-name col-1">New Lead Id</div>
            <div className="column-name col-1">Lead Link</div>
            <div className="column-name col-2">Current Train Cart</div>
            <div className="column-name col-2">Created At</div>
            <div className="column-name col-1">Revert Data</div>
            <div className="column-name col-1">Status</div>
            <div className="column-name col-1">Quick Submit</div>
          </div>
          <div className="table-content">
            {leads.map((lead, idx) => (
              <div
                key={idx}
                className={this.tableClass(idx)}
                onClick={() => this.selectLeadRow(idx)}
              >
                <span className="col-1">{lead.data.lead.first_name}</span>
                <span className="col-1">{lead.data.lead.last_name}</span>
                <span className="col-1">{this.copyButton(idx)}</span>
                <span className="col-1">
                  <a
                    target="_blank"
                    href={`/l/${lead.lead_id}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {lead.lead_id}
                  </a>
                </span>
                <span className="col-2">{lead.current_train_car}</span>
                <span className="col-2">{moment(lead.created_at).format('MM-DD-YYYY hh:mm A')}</span>
                <span className="col-1">{this.revertButton(idx)}</span>
                <span className="col-1">{this.refreshButton(idx)}</span>
                <span className="col-1">{this.quickSubmitButton(idx)}</span>
              </div>
            ))}
          </div>
          {leads[selectedLeadIdx] &&
            <StuckLeadModal
              state={this.state}
              setState={this.setState}
              lead={leads[selectedLeadIdx]}
              initLead={initLeads[selectedLeadIdx]}
              objectsAreSame={this.objectsAreSame}
              fetchStuckLead={this.fetchStuckLead}
            />
          }
        </div>
      </div>
    )
  }
}
