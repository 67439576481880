import { Map } from 'immutable';

const initialState = Map({
  crm_link: null,
  id: null,
  lead: null,
  mortgage_status: null,
  new_lead_options: null,
  partner_id: null,
  render_agent_invitation_checkbox: null,
  rex_referral: null,
  wp_subscriber_name: null,
});

const actionsMap = {
  ['SET_LEAD_INFO']: (state, action) => {
    const {
      crm_link,
      id,
      lead,
      mortgage_status,
      new_lead_options,
      partner_id,
      render_agent_invitation_checkbox,
      rex_referral,
      live: {
        wp_subscriber_name
       },
    } = action.data;

    return state.merge(Map({
      crm_link,
      id,
      lead,
      mortgage_status,
      new_lead_options,
      partner_id,
      render_agent_invitation_checkbox,
      rex_referral,
      wp_subscriber_name,
    }));
  },
  ['RESET_LEAD_INFO']: (state) => {
    return state.merge(initialState);
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
