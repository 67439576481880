import  React     from  'react';
import  ReactDOM  from  'react-dom';

import  NewLeadModal from './NewLeadModal';
import  NewLeadFromLeadModal from './NewLeadFromLeadModal';
export { NewLeadFromLeadModal };

/*
 * Define function to initalize the new lead form, so we can initialize it with seed data
 * on page load.
 */
export function initialize(modalType, modalContainer, options) {
  let modalClass;

  if(modalType === 'NewLead') {
    modalClass = NewLeadModal;
  } else if(modalType === 'NewLeadFromLead') {
    modalClass = NewLeadFromLeadModal;
  }

  // Render hidden component
  let modalElement = React.createElement(modalClass, options);
  ReactDOM.render(modalElement, modalContainer);

  // Open container when button is clicked.
  let createLeadButton = document.querySelector('li.nav-item.create-lead');
  let createExclusiveLeadButton = document.getElementById('create-exclusive-lead');
  [createLeadButton, createExclusiveLeadButton].forEach((element) => {
    if(element) {
      element.addEventListener('click', () => {
        console.log('Opening lead creation modal.');

        const currentAgent = JSON.parse(element.getAttribute('data'));

        window.displayNewLeadModal({
          isExclusive: element === createExclusiveLeadButton,
          currentAgent,
        })

        document.getElementById('new-lead-modal').classList.remove('hidden');
      });
    } else {
      console.error('Attempted to load new lead creation modal onto page that does not support it.');
    }
  })

  console.log('Initialized new lead form.');
}
