import React, { useState, useEffect, useRef } from 'react';

const Option = (props) => {
  const {
    selectAll,
    selected,
    value,
  } = props;

  const [checked, setChecked] = useState(selected || false);
  const [checkedAll, setCheckedAll] = useState(false);
  const optionRef = useRef(null);
  const initRender = useRef(true);

  const selectAllClass = (selectAll === true) ? 'select-all' : '';
  const selectedClass = checked ? 'checked' : '';
  const icon = checked ? 'fa-check' : 'fa-plus';

  useEffect(() => {
    if (initRender.current) {
      initRender.current = false;
      return;
    } else {
      toggleSelectedOptions();
    }
  }, [checkedAll]);

  useEffect(() => {
    handleServiceArea();
  }, [checked]);

  function handleClick(event) {
    setChecked(!checked);

    if (event.target.classList.contains('select-all')) setCheckedAll(!checkedAll);
  }

  function handleServiceArea() {
    const serviceAreaDiv = optionRef.current.closest('.service-area');
    const option = serviceAreaDiv.querySelector('.service-option.checked:not(.select-all)');
    const method = option ? 'add' : 'remove';

    serviceAreaDiv.classList[method]('has-checked');
  }

  function toggleSelectedOptions() {
    const serviceArea = optionRef.current.closest('.service-area');
    const targetClass = checkedAll ? '.service-option:not(.select-all):not(.checked)' : '.service-option.checked:not(.select-all)';
    const options = serviceArea.querySelectorAll(targetClass);

    options.forEach(option => option.click());
  }

  return (
    <div
      className={ `service-option mr-10 mb-10 ${selectAllClass} ${selectedClass}` }
      data-value={ value }
      onClick={ handleClick }
      ref={ optionRef }
    >
      { !selectAll && <i className={ `fa ${icon} mr-5` }></i> }
      { value }
    </div>
  );
};

export default Option;
