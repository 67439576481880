import { Component } from 'react';

import MatchingStatus from './MatchingStatus';
import CompaniesToBlock from './CompaniesToBlock';

export default class PreMatchResearchSellerForm extends Component {
  constructor( props ) {
    super( props );

    this.commentsOption = null;
    this.minChars = 150;

    this.onChange                       = this.onChange.bind(this);
    this.onCheckChange                  = this.onCheckChange.bind(this);
    this.onCommentsChange               = this.onCommentsChange.bind(this);
    this.handleSubmit                   = this.handleSubmit.bind(this);
    this.onCompanyChange                = this.onCompanyChange.bind(this);
    this.onChangeListedGroup            = this.onChangeListedGroup.bind(this);
    this.onAgentInvitationEmailChange   = this.onAgentInvitationEmailChange.bind(this);
    this.languagePreferenceChange       = this.languagePreferenceChange.bind(this);

    this.commentsChange = this.commentsChange.bind(this);
  }

  componentDidMount() {
    this.props.data.pmr_form.comments = this.props.data.comments
    this.props.updateState( this.props.data );
  }

  commentsChange(e) {
    const { value } = e.target;

    this.setState({ comments: value });

    this.onCommentsChange({
      target: {
        value: this.props.commentsMap[value],
      },
    });
  }

  checkComments(comment) {
    if (comment === null) {
      return 'none';
    }

    const { commentsMap } = this.props

    const providedCommentIndex = Object.values(commentsMap).indexOf(comment)

    if (providedCommentIndex > -1) {
      return Object.keys(commentsMap)[providedCommentIndex];
    }

    return 'freeText';
  }

  companiesSelectedForBlocking() {
    let { pmr_form, lead } = this.props.data;
    let bc = lead.blocked_company_brand_ids;
    let bvc = lead.blocked_verified_company_ids;

    if (pmr_form.companies_block ) { return true; };

    if((bc && bc.constructor === Object && Object.keys(bc).length === 0) &&
      (bvc && bvc.constructor === Object && Object.keys(bvc).length === 0)) {
      return false;
    }

    if (bc == '' || bvc == '' || bc == null || bvc == null) { return false; }

    return true;
  }

  handleSubmit( e ) {
    e.preventDefault();
    if( this.validateForm() ) {
      this.props.closeModal();
      this.props.pmrSubmit();
    }
  }

  onAgentInvitationEmailChange( e ) {
    this.props.data.send_agent_invitation = e.target.value;
    this.props.updateState( this.props.data );
  }

  validateForm() {
    let invalids = [];
    let fields = document.querySelectorAll('.seller_modal_form input, .seller_modal_form select, .seller_modal_form textarea')
    let { pmr_form } = this.props.data;
    fields.forEach(field => field.classList.remove('error'));

    if (!pmr_form.is_an_agent && this.commentsOption === 'none') {
      return false;
    }

    if(pmr_form.is_an_agent === true) {
      if(pmr_form.agent_link === ''){
        document.querySelector('[name="agent_link"]').classList.add('error')
        return false;
      } else {
        return true;
      }
    }

    fields.forEach(field => {
      let optional = field.getAttribute('data-optional');

      if (optional !== 'true') {
        if(field.value === '') {
          invalids.push(field);
        }

        if (field.type === 'radio' && pmr_form[field.name] === ''){
          invalids.push(field);
        }
      }
    });

    if( invalids.length > 0 ) {
      invalids.forEach(field => field.classList.add('error'));
      return false;
    }
    return true;
  }

  listedFsboOrForRentSelected() {
    let { pmr_form } = this.props.data
    return(pmr_form.listed === '1')
  }

  onChange( e ) {
    let { updateState, data } = this.props;
    let name = e.target.name;
    let val = e.target.value;

    switch(val) {
      case 'on':
        val = '1'; break;
      case 'off':
        val = '1'; break;
      case 'true':
        val = true; break;
      case 'false':
        val = false; break;
    }

    if (name === 'estimate' && val !== '') {
      data.pmr_form.no_estimate = '0';
    }

    data.pmr_form[name] = val;
    updateState( data );
  }

  onChangeListedGroup(e) {
    let values = e.target.value.split(',');

    this.props.data.pmr_form['listed'] = values[0];

    this.props.updateState( this.props.data );
  }

  onCompanyChange( e ) {
    var val = e.target.checked;

    if( !val ){
      this.props.data.lead.blocked_company_brand_ids = null;
      this.props.data.lead.blocked_verified_company_ids = null;
      this.props.data.pmr_form.blocked_company_brand_ids = {};
      this.props.data.pmr_form.blocked_verified_company_ids = {};
    }

    this.props.data.pmr_form[ e.target.name ] = val;
    this.props.updateState( this.props.data );
  }

  onCommentsChange( e ) {
    this.props.data.comments = e.target.value;
    this.props.data.pmr_form.comments = e.target.value;
    this.props.updateState( this.props.data );
  }

  onCheckChange( e ) {
    let { data, updateState } = this.props;
    let name = e.target.name;
    let val = e.target.checked ? '1' : '0';

    data.pmr_form[name] = val;

    if (name === 'no_estimate' && val === '1') {
      data.pmr_form.estimate = '';
    }

    updateState( data );
  }

  languagePreferenceChange( { target} ) {
    const { data, updateState } = this.props;
    const { value } = target;
    data.lead.language_preference = value;
    updateState( data );
  }

  render() {
    let { data, updateState } = this.props
    const { comments } = data;

    let {
      additional_listing_info,
      additional_notes,
      agent_link,
      estimate,
      is_an_agent,
      listed,
      listing_info,
      matcher_notes,
      no_estimate,
    } = this.props.data.pmr_form


    this.commentsOption = this.checkComments(comments);
    let companiesSelectedForBlocking = this.companiesSelectedForBlocking();

    let submitClasses = 'modal_button';

    if (is_an_agent) {
      submitClasses += ' kill';
    } else if (this.commentsOption === 'none') {
      submitClasses += ' disabled';
    } else if (this.commentsOption === 'freeText' && data.comments.length < this.minChars) {
      submitClasses += ' disabled';
    }

    return (
      <div className='content_form seller_modal_form'>
        <div className='field full'>
          <div className='label'>
            Any additional notes / comments?
          </div>
          <textarea
            name='matcher_notes'
            value={matcher_notes || ''}
            data-optional='true'
            onChange={this.onChange}
            placeholder='ex. Client requests Spanish-speaking agents, client will be in town this weekend. etc.'
          >
          </textarea>
        </div>
        <MatchingStatus
          data={data}
          updateState={updateState}
        />
        <div className='field full'>
          <div className='label'>
            Is this lead an agent?
          </div>
          <div>
            <input type='radio' name='is_an_agent' id='is_an_agent_false' value='false' onChange={this.onChange} />
            <label htmlFor='is_an_agent_false'>Not an agent</label>
          </div>
          <div>
            <input type='radio' name='is_an_agent' id='is_an_agent_true' value='true' onChange={this.onChange} />
            <label htmlFor='is_an_agent_true'>Is an Agent</label>
          </div>
        </div>
        <div className='field full selectdd'>
          <div className='label'>
            What is the client’s preferred language?
          </div>
          <select
            name='language_preference'
            value={ this.props.data.lead.language_preference }
            onChange={ this.languagePreferenceChange }
          >
            <option value='en'>English</option>
            <option value='es'>Spanish</option>
            <option value='fr'>French</option>
          </select>
        </div>
        {
          data.render_agent_invitation_checkbox
            ? <div className={is_an_agent === true ? 'field full' : 'hidden'}>
                <div>
                  <input type='checkbox' name='send_agent_invitation' id='send_agent_invitation' value='1' onChange={this.onAgentInvitationEmailChange} />
                  <label htmlFor='send_agent_invitation'>Send Agent Invitation email</label>
                </div>
              </div>
            : null
        }
        <div className={is_an_agent === true ? 'field full' : 'hidden'}>
          <div className='label'>
            If this lead is an agent please provide a link
          </div>
          <input type='text' name='agent_link' data-optional={is_an_agent !== true} value={agent_link} onChange={this.onChange} />
        </div>
        <div className={is_an_agent === true ? 'hidden' : ''}>
          <div className='field full'>
            <div className='label'>
              Is this property listed?
            </div>
            <div>
              <input type='radio' name='listed' id='listed_yes' value='1,0,0' onChange={this.onChangeListedGroup} />
              <label htmlFor='listed_yes'>Listed</label>
            </div>
            <div>
              <input type='radio' name='listed' id='listed_no' value='0,0,0' onChange={this.onChangeListedGroup} />
              <label htmlFor='listed_no'>Not Listed</label>
            </div>
            <div>
              <input type='radio' name='listed' id='no_info' value='0,0,0' onChange={this.onChangeListedGroup} />
              <label htmlFor='no_info'>No Info Found</label>
            </div>
          </div>
          <div className={this.listedFsboOrForRentSelected() ? 'field full' : 'hidden'}>
            <div className='label'>
              Listing/Rental Link
            </div>
            <textarea
              value={listing_info}
              data-optional={this.listedFsboOrForRentSelected() ? 'false' : 'true'}
              name='listing_info'
              style={{ height: '32px', paddingTop: '3px' }}
              onChange={this.onChange}
            />
          </div>
          <div className={this.listedFsboOrForRentSelected() ? 'field full' : 'hidden'}>
            <div className='label'>
              Additional Listing Notes
            </div>
            <textarea
              value={additional_listing_info}
              data-optional={this.listedFsboOrForRentSelected() ? 'false' : 'true'}
              name='additional_listing_info'
              onChange={this.onChange}
              placeholder='ex: Currently listed by (agent name and brokerage).'
            />
          </div>
          <div className='field full selectdd'>
            <div className='label'>
              How much is this property worth?
            </div>
            <select value={data.home_price} name='home_price' data-optional={no_estimate === '0' ? 'false' : 'true'} onChange={this.onChange}>
              <option value=''>Select One</option>
              {data.home.options.map((option, idx) => (
                <option key={idx} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className='field full'>
            <input type='text' placeholder={this.listedFsboOrForRentSelected() ? 'For Sale' : 'Estimate'} value={estimate} name='estimate' data-optional={no_estimate === '0' ? 'false' : 'true'} onChange={this.onChange} />
            <input type='checkbox' name='no_estimate' id='no_estimate' checked={no_estimate === '1' ? true : false} data-optional='true' value='1' onChange={this.onCheckChange} />
            <label htmlFor='no_estimate'>{this.listedFsboOrForRentSelected() ? 'No Sale Price Found' : 'No Estimate Found'}</label>
          </div>
          <div className='field full'>
            <div className='label'>
              Additional Notes
            </div>
            <textarea name='additional_notes' data-optional='true' value={additional_notes || ''} onChange={this.onChange} placeholder='Ex. Prev. listed by (list all brokerages), similar units, more estimates, multiple zip codes, etc...'></textarea>
          </div>
          <div className='field full'>
            <div className='label'>
              Are there companies to block?
            </div>
            <input type='checkbox' name='companies_block' id='companies_true' data-optional={companiesSelectedForBlocking ? 'true' : 'false'} defaultChecked={companiesSelectedForBlocking} onChange={this.onCompanyChange} />
            <label htmlFor='companies_true'>Companies to Block</label>
          </div>
          {
            companiesSelectedForBlocking
              ? <CompaniesToBlock
                  data={data}
                  updateState={updateState}
                />
              : null
          }
          <div className='field full label-only'>
            <div className='label'>
              Confirm client comments
              <span>
                Client comments will be viewed as a custom message to agents. Please confirm that all spelling and content is correct.
              </span>
            </div>
          </div>
          <div className='field full selectdd no-label'>
          <select value={ this.commentsOption } onChange={ this.commentsChange }>
            <option value='none'> </option>
            <option value='goodA'>Good - Answering Machine/Name</option>
            <option value='goodB'>Good - Phone/Address Record Match</option>
            <option value='goodC'>Good - 2-Factor Authentication Match</option>
            <option value='freeText'>Free Text</option>
            </select>
          </div>
          <div className='field full'>
            <textarea
              value={ comments === null ? ' ' : comments }
              data-optional='true'
              name='comments'
              onChange={this.onCommentsChange}
              disabled={ this.commentsOption !== 'freeText' }
            ></textarea>
            { (this.commentsOption == 'freeText' && (!comments || comments.length < this.minChars)) &&
              <div className='textarea-minimum'>Minimum { this.minChars } characters - { this.minChars - comments.length } remaining</div>
            }
          </div>
          <div className='clear'></div>
        </div>
        <div className='clear'></div>
        <div onClick={this.handleSubmit} className={ submitClasses }>
          {is_an_agent === true ? 'Update and Kill Lead' : 'Update and Send to Matching'}
        </div>
      </div>
    )
  }
}
