import React from 'react';

import ManagementOptions from './OptionsView';
import ProcessRefund from './ProcessRefundContainer';

import BackArrow from '../LiveAccountManagement/images/icon-arrow-left.png';
import Close from '../LiveAccountManagement/images/close.png';

const ManagementView = (props) => {
  const {
    agentId,
    display,
    displaying,
    loading,
    netInitialFee,
    toggleModal,
    updateManagement,
    unsuccessfulRequest,
  } = props;

  return (
    <div className='rex-modal-overlay'>
      <div className='rex-modal-content live-account-management-modal'>
        <div className='rex-modal-close'>
          <button
            onClick={ () => toggleModal(false) }
            disabled={ loading }
          >
            <img src={ Close } />
          </button>
        </div>
        <div className='rex-modal-back'>
          { displaying !== null &&
            <button onClick={ () => display(null) } disabled={ loading }>
              <span className='back-arrow'>
                <img src={ BackArrow } />
              </span>
              <span>Back</span>
            </button>
          }
        </div>
        { (() => {
            switch(displaying) {
              case 'processRefund':
                return <ProcessRefund
                  agentId={ agentId }
                  netInitialFee={ netInitialFee }
                  updateManagement={ updateManagement }
                  unsuccessfulRequest={ unsuccessfulRequest }
                />
              default:
                return <ManagementOptions
                  display={ display }
                />;
            }
          })() }
      </div>
    </div>
  );
};

export default ManagementView;
