import React, { useState, useEffect } from 'react';

import Outcome from './results/Outcome';

const displayOutcome = results => {
  if (!Object.keys(results).length) return undefined;

  const outcomes = [];

  for (const phone in results) {
    outcomes.push(<Outcome key={ phone } phone={ phone } result={ results[phone] } />);
  }

  return outcomes;
};

const Results = () => {
  const container = document.getElementById('dialer-recruit-top-agents-research-container');

  const [results, updateResults] = useState({});

  useEffect(() => {
    container.addEventListener('agent_research.verification_results', handleVerificationResults);
  });

  function handleVerificationResults(event) {
    const { detail } = event;
    const dataset = detail || {};

    updateResults(dataset);
  }

  return (
    <div className="row">
      { displayOutcome(results) }
    </div>
  );
};

export default Results;
