import { classList } from '../utils';

const RadioGroup = (props) => {
  const {
    classNames,
    focused,
    id,
    name,
    options,
    valid,
    value,

    onChange,
    onFocus,
  } = props;

  const classes = classList({
    classNames,
    def: 'info-radio-group',
    focused,
    valid
  });

  return (
    <div className={ classes }>
      { options.map((option, index) => {
          const { val } = option;
          const checked = value === val;
          const key = `${option.name}-${index}`;
          return (
            <label key={ key } className={ checked ? 'is-selected' : '' }>
              <input
                id={ `radio-info-input-${ name }-${ index || 0 }`}
                type='radio'
                name={ name }
                value={ val }
                checked={ checked }
                onChange={ onChange }
                onFocus={ onFocus }
              />
              { option.name }
            </label>
          );
      })}
    </div>
  );
};

export default RadioGroup;
