import { Component } from 'react';

const ReviewView = props => {
  const {
    createSyndications,
    returnToBrands,
    returnToExecutives,
  } = props;

  const {
    brandsSelected,
    adminsSelected,
    executivesSelected,
  } = props.state;

  const unmonitored = brandsSelected[0].name === 'Unmonitored';

  return (
    <div className="content">
      <div className="review">
        <div className="review-list">
          <h3>Brands</h3>
          {brandsSelected.map((brand, idx) => (
            <div className="review-list-item" key={idx}>{brand.name}</div>
          ))}
        </div>
        <div className="review-list">
          <h3>Sales Admins</h3>
          {adminsSelected.map((admin, idx) => {
            if (admin) {
              return <div className="review-list-item" key={idx}>{admin.name}</div>
            }
          })}
        </div>
        <div className="review-list">
          <h3>Sales Executives</h3>
          {executivesSelected.map((exec, idx) => {
            if (exec) {
              return <div className="review-list-item" key={idx}>{exec.name}</div>
            }
          })}
        </div>
      </div>
      <div className="prompt-options">
        <div className="prompt-cancel" onClick={unmonitored ? returnToBrands : returnToExecutives}>
          Back
        </div>
        <div className="prompt-confirm" onClick={createSyndications}>
          Submit
        </div>
      </div>
    </div>
  )
}

export default ReviewView;
