import am_logo from '../../../images/am_logo.png';
import application from '../../../images/application.png';
import arrow_refresh from '../../../images/arrow_refresh.png';
import calendar from '../../../images/calendar.png';
import google from '../../../images/google.png';
import googleIcon from '../../../images/google-icon.png';
import hourglass from '../../../images/hourglass.gif'
import indicator from '../../../images/indicator.gif';
import leadPhotoSellerSquare from '../../../images/lead-photo-seller-square.png';
import recall_icon from '../../../images/recall_icon.png';
import rex_full_activated_icon_tiny from '../../../images/rex_full_activated_icon_tiny.png';
import rex_logo from '../../../images/rex_logo.png';
import telephone_go from '../../../images/telephone_go.png';
import user from '../../../images/user.png';
import user_gray from '../../../images/user_gray.png';
import user_red from '../../../images/user_red.png';
import weather_sun from '../../../images/weather_sun.png';

const IMAGES = {
  am_logo,
  application,
  arrow_refresh,
  calendar,
  google,
  googleIcon,
  hourglass,
  indicator,
  leadPhotoSellerSquare,
  recall_icon,
  rex_full_activated_icon_tiny,
  rex_logo,
  telephone_go,
  user,
  user_gray,
  user_red,
  weather_sun,
};

window.image_path = (name) => { return IMAGES[name]; };

