import { Component }  from 'react';

// 10 seconds
const fetchInterval = 10 * 1000;
let interval;

export default class Container extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recruitable_leads: 0,
      callable_agents: 0,
      priority_researchable: 0,
      total_researchable: 0,
    };
  }

  componentDidMount() {
    this.fetchFeedCounts();
    interval = setInterval(() => this.fetchFeedCounts(), fetchInterval);
  }

  componentWillUnmount() {
    clearInterval(interval);
    this.toggleRefreshIcon('disable');
  }

  fetchFeedCounts() {
    const { feed_counts_dialer_recruit_top_agents_queue_path } = this.props;

    this.toggleRefreshIcon('enable');

    fetch(
      feed_counts_dialer_recruit_top_agents_queue_path,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )
    .then(response => response.json())
    .then(response => {
      this.setState(response);
      setTimeout(() => this.toggleRefreshIcon('disable'), 1000);
    });
  }

  toggleRefreshIcon(action) {
    const refreshIcon = document.getElementById('refresh-indicator');
    const operation = /enable/.test(action) ? 'add' : 'remove';

    refreshIcon.classList[operation]('fa-spin-2x', 'blue-indicator');
  }

  render() {
    const {
      dialer_recruit_top_agents_path,
      dialer_recruit_top_agents_research_path,
    } = this.props;

    const {
      recruitable_leads,
      callable_agents,
      priority_researchable,
      total_researchable,
    } = this.state;

    return (
      <div className="row">
        <div className="col-md-4 col-centered">
          <div className="dialer-stats-container mb-30">
            <i className="fa fa-refresh" id="refresh-indicator"></i>
            <table id="dialer-recruit-top-agents-counts" className="table table-borderless m-0">
              <tr>
                <td className="bold">Recruitable Leads</td>
                <td>{ recruitable_leads }</td>
              </tr>
              <tr>
                <td className="bold">Callable Agents</td>
                <td>{ callable_agents }</td>
              </tr>
              <tr>
                <td className="bold">Priority Researchable Agents</td>
                <td>{ priority_researchable }</td>
              </tr>
              <tr>
                <td className="bold">Total Researchable Agents</td>
                <td>{ total_researchable }</td>
              </tr>
            </table>
          </div>
          <div className="row dialer-links">
            <div className="col-md-6 col-sm-block">
              <a className="btn btn-lg btn-rex-default btn-block mb-30" href={ dialer_recruit_top_agents_path }>RECRUIT TOP AGENTS</a>
            </div>
            <div className="col-md-6 col-sm-block">
              <a className="btn btn-lg btn-rex-default btn-block mb-30" href={ dialer_recruit_top_agents_research_path }>AGENT RESEARCH</a>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
