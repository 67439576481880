import { Component } from 'react';
import AppointmentSlots from './book_appointment/AppointmentSlots';
import AppointmentDateSelector from './book_appointment/AppointmentDateSelector';
import AppointmentTimeSelector from './book_appointment/AppointmentTimeSelector';

export default class DatetimeSelector extends Component {
  constructor(props){
    super(props);
    let date = new Date;
    this.state = {
      calendar: null,
      maxDayRange: 14,
      activeDay: date.getDate(),
      activeDate: Util.MMDDYYYY(),
      activeDatetime: date,
      activeMonth: FormHelper.monthShort(date.getMonth() + 1),
      activeYear: date.getFullYear(),
      timeFilter: '',
      selectedSlot: '',
      welcomeTeamSchedule: {},
      weekdays: {},
      errorMessage: '',
    }
    this.updateDate        = this.updateDate.bind(this);
    this.updateTime        = this.updateTime.bind(this);
    this.selectSlot        = this.selectSlot.bind(this);
    this.resetErrorMessage = this.resetErrorMessage.bind(this);
  }

  componentDidMount(){
    this.updateDate();
    this.setWeekdays();
  }

  // function removes Saturdays and Sundays and saves the date value with
  // the parsed date string as an array.
  setWeekdays(){
    let range = this.state.maxDayRange || 7;
    let weekdays = {};
    let adjustedDate, displayDate, dateInt, datetime, date;

    for (let i = 0; i <= range; i++) {
      datetime = new Date;
      dateInt = (datetime.getDay() + i) % 7;
      adjustedDate = new Date(datetime.setDate(datetime.getDate() + i));
      date = Util.MMDDYYYY(adjustedDate);
      displayDate = `${FormHelper.weekday(dateInt)} ${date}`
      weekdays[date] = { displayDate: displayDate, adjustedDate: adjustedDate }
    }
    this.setState({ weekdays });
  }

  isWeekDay(weekdayInt){
    return weekdayInt > 0 && weekdayInt < 6
  }

  updateCalendar(params){
    Util.getCustomerSuccessSchedule(params)
      .then((result) => this.setState({ calendar: result.calendar, selectedSlot: '' }))
  }

  updateWelcomeTeamSchedule(params){
    Util.getWelcomeTeamSchedule(params)
      .then((result) => { this.setState({ welcomeTeamSchedule: result.welcome_team_schedule }) })
  }

  headerContent(){
    let { activeDay, activeMonth, activeYear, activeDate } = this.state;
    let currentDay = new Date();
    let nextWeekDate = new Date(currentDay.setDate(currentDay.getDate() + 6));
    let newMonth = FormHelper.monthShort(nextWeekDate.getMonth() + 1);
    let newDay = nextWeekDate.getDate();

    return `Appointments for ${activeMonth} ${activeDay} - ${newMonth} ${newDay} ${activeYear}`
  }

  selectSlot(selectedSlot, offsetTime){
    let {
      changeFollowUp,
      toggleDateSelector,
      timeZone,
    } = this.props;

    selectedSlot = selectedSlot.replace(/\s/g, '');
    let dateTime = this.state.activeDate + ' ' + offsetTime;
    let parsedTime = Util.parseDateTime(dateTime, -1 * (timeZone.offset + 8));

    $.post('/customer_success/reserve_slot', { timeslot: parsedTime }).then(res => {
      if (res.error) {
        this.setState({ errorMessage: res.error })
      } else {
        changeFollowUp(dateTime);
        toggleDateSelector();
      }
    });
  }

  resetErrorMessage() {
    this.setState({ errorMessage: '' });
  }

  updateDate(date = new Date){
    let newDate = Util.MMDDYYYY(date);
    let params = { weekday: newDate};
    this.updateCalendar(params);
    this.updateWelcomeTeamSchedule({ date: Util.parseForSQL(date) });
    this.setState({
      activeDate: newDate,
      activeDatetime: date
    });
  }

  updateTime(time){
    this.setState({ timeFilter: time })
  }

  preventBubbling(e){
    e.stopPropagation();
  }

  render(){
    let {
      activeDay, activeMonth, activeYear,
      activeDate, calendar, timeFilter,
      selectedSlot, progress, agentId,
      rsl, activeDatetime,
      weekdays, welcomeTeamSchedule, errorMessage
    } = this.state;

    let slots = () => {
      if(welcomeTeamSchedule){
        return(
          <AppointmentSlots
            calendar={calendar}
            timeFilter={timeFilter}
            selectSlot={this.selectSlot}
            selectedSlot={selectedSlot}
            timeZone={this.props.timeZone}
            currentUserId={this.props.currentUserId}
            welcomeTeamSchedule={welcomeTeamSchedule}
            errorMessage={errorMessage}
          />
        )
      }
    }
    return(
      <div className='react-mask-layer' onClick={this.props.toggleDateSelector}>
        <div className='cs-appt-content' onClick={this.preventBubbling}>
          <div>
            <div className='cs-appt-header'>
              { this.headerContent() }
            </div>
            <AppointmentDateSelector
              activeDate={activeDate}
              activeDatetime={activeDatetime}
              weekdays={weekdays}
              updateDate={this.updateDate}
              setWeekdays={this.setWeekdays}
              resetErrorMessage={this.resetErrorMessage}
            />
            <AppointmentTimeSelector
              updateTime={this.updateTime}
            />
            { slots() }
          </div>
        </div>
      </div>
    )
  }
}
