import { Component } from 'react';

export default class AppointmentTimeSelector extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeTime: 'all'
    }
  }
  updateTime(time){
    if(time === this.state.activeTime) return;

    this.props.updateTime(time);
    this.setState({ activeTime: time });
  }

  render(){
    let { activeTime } = this.state;
    return(
      <div className='cs-appt-time-selector'>
        <div className='select-container'>
          Time: &nbsp;&nbsp;
          <div
            className={activeTime === 'all' ? 'select-button selected' : 'select-button'}
            onClick={() => this.updateTime('all')}
          >
            All
          </div>
          <div
            className={activeTime === 'morning' ? 'select-button selected' : 'select-button'}
            onClick={() => this.updateTime('morning')}
          >
            Morning
          </div>
          <div
            className={activeTime === 'afternoon' ? 'select-button selected' : 'select-button'}
            onClick={() => this.updateTime('afternoon')}
          >
            Afternoon
          </div>
        </div>
      </div>
    )
  }
}
