import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

import String from '../../../../../../common/String.js'

const string = new String();

const getAddressComponents = address => {
  const street_number = (address.find(a => a.types.includes("street_number")) || {}).short_name;
  const street_name = (address.find(a => a.types.includes("route")) || {}).short_name;
  const city = (address.find(a => a.types.includes("locality")) || {}).short_name;
  const state = (address.find(a => a.types.includes("administrative_area_level_1")) || {}).short_name;
  const country = (address.find(a => a.types.includes("country")) || {}).short_name;
  const zip = (address.find(a => a.types.includes("postal_code")) || {}).short_name;

  return { street_number, street_name, city: `${city || ''}`.replace(/[^a-z]/ig, ' '), state, country, zip };
};

const GooglePlacesAutocomplete = props => {
  const {
    fieldName: fldName,
    fieldPrefix,
    fieldTitle,
    fieldType,
    required,
    placeholder,
    updateFieldHandler,
    filtered = false,
  } = props;

  const [address, setAddress] = useState("");

  const fieldName = fldName || string.snakeCase(fieldTitle.replace(/[^\w\s]/g, ""));
  const name = !!fieldPrefix ? `${fieldPrefix}[${fieldName}]` : fieldName;
  const id = string.snakeCase(name);

  const labelText = () => {
    return required ? `${fieldTitle}*` : fieldTitle;
  };

  const handleChange = address => {
    setAddress(address);
  };

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        const components = getAddressComponents(results[0].address_components);
        const data = !!fieldPrefix ? { [fieldPrefix]: { [fieldName]: components } } : { [fieldName]: components };

        setAddress(address);
        updateFieldHandler(data);
      })
      .catch(error => console.error('Error', error));
  };

  const filteredPlaces = places => {
    return places.filter(place => {
      if (place.types.includes('street_address')) return true;
      if (place.types.includes('premise')) return true;
    });
  };

  return (
    <div className="input-group">
      <label htmlFor={ id }>
        { fieldTitle && <span className="label-text">{ labelText() }</span> }

        <PlacesAutocomplete
          value={ address }
          onChange={ handleChange }
          onSelect={ handleSelect }
        >
          {
            ({ getInputProps, suggestions, getSuggestionItemProps, _loading }) => {
              const autocompleteContainerKlass = suggestions.length ? "autocomplete-dropdown-container active" : "autocomplete-dropdown-container";
              const places = filtered ? filteredPlaces(suggestions) : suggestions;

              return (
                <React.Fragment>
                  <input { ...getInputProps({ className: "location-search-input", type: "text", id, name, placeholder, }) } />

                  <div className={ autocompleteContainerKlass }>
                    {
                      places.map((place, i) => {
                        const className = place.active ? "suggestion-item active" : "suggestion-item";
                        const key = i;

                        return (
                          <div { ...getSuggestionItemProps(place, { key, className, }) }>
                            <span>{ place.description }</span>
                          </div>
                        );
                      })
                    }
                  </div>
                </React.Fragment>
              )
          }
        }
        </PlacesAutocomplete>
      </label>
    </div>
  );
};

export default GooglePlacesAutocomplete;


