import  { Component } from  'react';
import mainIcon from '../images/call-connected.svg';

export default class AgentReady extends Component {
  render(){
    return (
      <div className="button agent-ready">
        <div className='row'>
          <div className='col-sm-2'>
            <img src={ mainIcon }/>
          </div>
          <div className='col-sm-6'>
            <div className='row status-text top-row'>
              Agent Ready
            </div>
          </div>
        </div>
      </div>
    );
  }
}
