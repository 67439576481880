const Assign = (props) => {
  const {
    indexPath,
    productType,
    saleAssociates,
  } = props;

  function assignProspects(event) {
    event.preventDefault();

    const assignBtn = event.target;
    const form = assignBtn.closest('form');
    const [user, prospects] = form.querySelectorAll('select, input').values();
    const userName = user.options[user.selectedIndex].innerText;
    const message = `You are about to assign ${prospects.value} prospects to ${userName}. Are you sure?`;

    if (validForm(event) && confirm(message)) {
      form.classList.add('overlay');

      fetch(
        indexPath,
        {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            sales: {
              product_type: productType,
              user_id: user.value,
              prospects_to_assign: prospects.value,
            }
          })
        }
      )
      .then(response => response.json())
      .then(response => {
        form.classList.remove('overlay');

        if (response.success) {
          Util.createFlash(`Successfully enqueued job to assign ${prospects.value} prospects to ${userName}.`, 'success');
        } else {
          throw new Error(response.errors);
        }
      })
      .catch(error => {
        Util.createFlash(error.message, 'danger');
      });
    }
  }

  function validForm(event) {
    const inputs = event.target.closest('form').querySelectorAll('input, select');
    const valid = [];

    inputs.forEach(input => {
      const formGroup = input.closest('.form-group');
      const inputError = formGroup.querySelector('.input-error');
      const { value } = input;

      if (inputError) inputError.remove();

      if (value.length) {
        formGroup.classList.remove('has-error');
      } else {
        const errorDiv = document.createElement('div');

        errorDiv.classList.add('input-error');
        errorDiv.innerText = "Can't be blank";
        formGroup.classList.add('has-error');
        formGroup.appendChild(errorDiv);
        valid.push(false);
      }
    });

    return !valid.includes(false);
  }

  function buildSalesAssociates() {
    const options = [];

    saleAssociates.forEach(saleAssociate => {
      const [name, id] = saleAssociate;

      options.push(<option key={ id } value={ id }>{ name }</option>);
    });

    return options;
  }

  return(
    <form className='form assign-prospects'>
      <div className='form-group'>
        <select name='advocate' className='form-control advocate'>
          <option value=''>-- Advocate --</option>
          { buildSalesAssociates() }
        </select>
      </div>

      <div className='form-group'>
        <input type='number' name='prospects-to-assign' className='form-control prospects-to-assign' placeholder='Assign Prospects' min='1' />
      </div>

      <button className='btn btn-md btn-primary' onClick={ e => assignProspects(e) }>Assign</button>
    </form>
  );
}

export default Assign;
