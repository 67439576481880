import React, { useState, useEffect } from 'react';

import String from '../../../../../../../common/String.js'
import ButtonGroup from './../../_common/ButtonGroup.js';

const string = new String();

const LeadStatus = props => {
  const {
    leadFromLead = {},
    leadStatuses,
    lflLeadStatuses,
    updateFieldHandler,
  } = props;

  const { agent_ids = [] } = leadFromLead;

  const lflPresent = !!Object.keys(leadFromLead).length;
  const initStatuses = lflPresent ? [...leadStatuses, ...lflLeadStatuses] : leadStatuses;

  const [statuses, updateStatuses] = useState(initStatuses);
  const [disabled, updateDisabled] = useState(lflLeadStatuses);

  useEffect(() => updateDisabledStatuses(), [agent_ids]);
  useEffect(() => updateStatus(), [disabled]);

  const updateDisabledStatuses = () => {
    if (agent_ids.length === 0) updateDisabled(lflLeadStatuses);
    if (agent_ids.length === 1) updateDisabled(leadStatuses);
    if (agent_ids.length > 1) updateDisabled([...leadStatuses, lflLeadStatuses[1]]);
  };

  const updateStatus = () => {
    const container = document.getElementById('lead-statuses');
    const buttons = container.querySelectorAll('button');
    const button = [...buttons].filter(button => !button.disabled)[0];

    if (button) button.click();
  };

  return (
    <div id="lead-statuses" className="section">
      <div className="header">
        <div className="icon-wrapper"><i className="icon home"></i></div>
        <div className="title-wrapper">
          <h2>Lead Status</h2>
        </div>
      </div>

      <div className="input-row">
        <ButtonGroup attribute="status" buttons={ statuses } disabledButtons={ disabled } updateFieldHandler={ updateFieldHandler } />
      </div>
    </div>
  );
};

export default LeadStatus;
