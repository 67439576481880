import AlertIcon from '../../../../LiveAccountManagement/images/alert.png';

const _submitReactivation = (event, props) => {
  const {
    action,
    toggleButtons,
    moveTo,
    primeSubscriptionPath,
    setAndGo,
    setAttribute,
  } = props;

  toggleButtons(event, 'disable');

  fetch(
    primeSubscriptionPath,
    {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ action: action, reset: true }),
    }
  )
  .then(response => response.json())
  .then(response => {
    if (response.success) {
      setAttribute('featureEnabled', true);
      setAttribute('planCancelled', false);
      moveTo('success');
    } else {
      throw new Error(response.errors.join(', '));
    }
  })
  .catch((error) => {
    setAndGo('error', error.message, 'fail');
  });
};

const _toggleCheckbox = (input, disable) => {
  const label = input.closest('label');

  if (disable) {
    input.disabled = true;
    label.classList.add('disabled');
  } else {
    input.disabled = false;
    label.classList.remove('disabled');
  }
};

const _toggleEligibility = (props) => {
  const {
    eligibilityPath,
    setAttribute,
  } = props;

  const checkbox = document.getElementById('prime-eligibility');
  const method = checkbox.checked ? 'POST' : 'DELETE';

  _toggleCheckbox(checkbox, true);

  fetch(
    eligibilityPath,
    {
      method: method,
      credentials: 'same-origin'
    }
  )
  .then(response => response.json())
  .then(response => {
    _toggleCheckbox(checkbox, false);

    if (response.success) {
      setAttribute('primeEligible', !checkbox.checked);
    } else {
      throw new Error(response.errors);
    }
  })
  .catch(error => {
    checkbox.checked = !checkbox.checked;
  });
};

const Warning = (props) => {
  const {
    primeEligible,
    toggleModal,
  } = props;

  return (
    <div id='enable-warning'>
      <div className='rex-modal-icon-container'>
        <img className='rex-modal-icon' src={ AlertIcon } />
      </div>

      <div className='rex-modal-heading'>
        PRIME Currently Disabled
      </div>

      <div className='rex-modal-contents'>
        <div className='rex-modal-subheading'>
          <p className='center'>Check the box below to mark the agent as eligible for PRIME. To reactivate PRIME for<br /> the agent, click "REACTIVATE PRIME". To keep the account disabled, click "KEEP<br /> ACCOUNT DISABLED".</p>
        </div>

        <div className='center zoom-big'>
          <label htmlFor='prime-eligibility' className='center mb-15'>
            <input
              type='checkbox'
              name='prime-eligibility'
              id='prime-eligibility'
              className='prime-eligibility'
              checked={ primeEligible }
              onChange={ () => _toggleEligibility(props) }
            />
            <span className='checkbox-label'>Mark Agent As Eligible For PRIME</span>
          </label>
        </div>

        <div className='rex-modal-actions'>
          <button
            className='rex-modal-action'
            onClick={ () => toggleModal(false) }
          >
            KEEP ACCOUNT DISABLED
          </button>

          <button
            className='rex-modal-action rex-modal-action__loading'
            disabled={ !primeEligible }
            onClick={ (e) => _submitReactivation(e, props) }
          >
            REACTIVATE PRIME
          </button>
        </div>
      </div>
    </div>
  )
};

export default Warning;
