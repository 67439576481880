import React from 'react';
import ReactDOM from 'react-dom';
import LeadCreationForm from './index';

/**
 * Initialize LeadCreationForm
 */

export function initialize(containerElement, props) {
  let component = React.createElement(LeadCreationForm, props);
  ReactDOM.render(component, containerElement);
}
