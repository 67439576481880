import React, { Component } from 'react';
import { connect } from 'react-redux';
import View from './view';
import { store } from '../../index';

import { getStateProps } from '../../utils';

export default class GoogleSearch extends Component {  
  render() {
    const { values } = this.state;

    return (
      <View
        data={ values }
      />
    );
  }
}
