import { Component } from 'react';

import PreMatchResearchSellerForm from './PreMatchResearchSellerForm';
import PreMatchResearchBuyerForm from './PreMatchResearchBuyerForm';

export default class PreMatchResearchModal extends Component {
  constructor( props ) {
    super( props );
    this.convertIntWithCommas = this.convertIntWithCommas.bind(this);
  }

  commentsMap() {
    const { first_name } = this.props.data.lead;
    const { origin } = this.props.data;

    return {
      none: null,
      goodA: `${first_name} made a direct request on ${origin} and completed multiple prompts for information. We were able to confirm that the outgoing voicemail message matches the client’s first name. We are confident they are looking to find a top real estate agent in the area.`,
      goodB: `${first_name} made a direct request on ${origin} and completed multiple prompts for information. We were able to confirm that the public phone records match the client’s name. We are confident they are looking to find a top real estate agent in the area.`,
      goodC: `${first_name} made a direct request on ${origin} and completed multiple prompts for information. The client has confirmed their request via text message. We are confident they are looking to find a top real estate agent in the area.`,
      freeText: '',
    }
  }

  render() {
    return(
      <div className='research_modal'>
        <div className='heading'>
          <div className='title'>
            <i className='fa fa-refresh'></i>
            Pre-Connection Research
          </div>
          <div className='name active' onClick={ this.handleClick.bind( this ) }>
            { this.props.data.lead.first_name + ' ' + this.props.data.lead.last_name }
          </div>
          <div className='number' onClick={ this.handleClick.bind( this ) }>
            { this.props.data.lead.phone_number }
          </div>
          <div className='email' onClick={ this.handleClick.bind( this ) }>
            { this.props.data.email }
          </div>
          <div className={ this.props.data.lead.lead_type == 'seller' ? 'property' : 'property hidden' } onClick={ this.handleClick.bind( this ) }>
            Property Search
          </div>
          <div className='close_modal' onClick={ this.closeModal.bind( this ) }>
            X
          </div>
          <div className='lead_type_and_price'>
          { this.leadTypeAndPricePoint() }
          </div>
        </div>
        <div className='modal_content'>
          <div className='left_content'>
            { this._renderForm() }
          </div>
          <div className='right_content'>
            <iframe src={ 'https://www.bing.com/search?q=' + this.props.data.search }></iframe>
          </div>
        </div>
      </div>
    )
  }

  leadTypeAndPricePoint() {
    let lead = this.props.data.lead;
    if (lead.home_price_min && lead.home_price_max && lead.home_price_min != null && lead.home_price_min != '') {
      var formattedLeadPriceRange = numeral(lead.home_price_min).format('$0,0') + " - " + numeral(lead.home_price_max).format('$0,0')
    } else if (lead.home_price_max) {
      var formattedLeadPriceRange = numeral(lead.home_price_max).format('$0,0')
    } else {
      var formattedLeadPriceRange = ''
    }
    switch (lead.lead_type) {
      case 'buyer':
        if (this.props.data.home_price) {
          return(
            <div className="lead_type_and_price_point">
              { 'BUYER: ' + formattedLeadPriceRange }
            </div>
          )
        }
      case 'seller':
        if (lead.home_price_max) {
          return(
            <div className="lead_type_and_price_point">
              { 'SELLER: $' + formattedLeadPriceRange }
            </div>
          )
        }
    }
  }

  convertIntWithCommas(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  _renderForm() {
    if( this.props.data.lead.lead_type === 'seller' ) {
      $('.right_content').addClass('seller');

      return(
        <PreMatchResearchSellerForm
          data={ this.props.data }
          closeModal={ this.closeModal.bind( this ) }
          commentsMap={this.commentsMap()}
          updateState={ this.props.updateState.bind( this ) }
          pmrSubmit={ this.props.pmrSubmit.bind( this ) }
         />
      )
    } else if( this.props.data.lead.lead_type === 'buyer' ) {
      return(
        <PreMatchResearchBuyerForm
          data={ this.props.data }
          closeModal={ this.closeModal.bind( this ) }
          commentsMap={this.commentsMap()}
          updateState={ this.props.updateState.bind( this ) }
          pmrSubmit={ this.props.pmrSubmit.bind( this ) }
        />
      )
    }
  }

  closeModal() {
    $('.research_modal, .research_modal_overlay').hide();
  }

  handleClick( e ) {
    var location = '';
    var property_search = '';

    if( this.props.data.lead.lead_type == 'seller' ){
      location = this.props.data.lead.city + ' ' + this.props.data.lead.state;
      property_search = `${this.props.data.lead.street_number}%20${this.props.data.lead.street_name}%20${this.props.data.lead.city}%20${this.props.data.lead.state}%20${this.props.data.lead.zip}`;
    } else {
      var bc = this.props.data.lead.buyer_cities.split( '&' );
      location = bc[ 0 ].replace( ',', ' ' );
    }

    var realtor_search = `${this.props.data.lead.first_name}%20${this.props.data.lead.last_name}%20realtor%20${location}`;

    var offset = $( e.target ).offset();
    var x = e.clientX - offset.left;
    var y = e.clientY - offset.top;

    var state = {};
    var data = $( e.target ).html();
    var name = $( e.target ).hasClass( 'name' );
    var property = $( e.target ).hasClass( 'property' );

    $( '.research_modal .heading div' ).removeClass( 'active' );
    $( e.target ).addClass( 'active' );
    if( ( x >= 0 && x <= 25 ) && ( y >= 10 && y <= 45 ) ) {
      if( name ) {
        window.open(`https://www.google.com/search?q=${realtor_search}`);
      }else if( property ) {
        window.open('https://www.google.com/search?q=' + property_search);
      }else {
         window.open('https://www.google.com/search?q=' + data);
      }

      return false;
    }

    if( name ) {
      this.props.data.search = realtor_search;
    }else if( property ) {
      this.props.data.search = property_search;
    }else {
      this.props.data.search = data;
    }

    this.props.updateState( this.props.data );
  }
}
