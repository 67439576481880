import Agent from './body/Agent';
import Contacts from './body/Contacts';
import Verification from './body/Verification';
import Results from './body/Results';
import History from './body/History';

const Body = (props) => {
  const { agent, researchContactHistoryPath } = props;

  return (
    <div className="body">
      <div className="row">
        <div className="col-md-6">
          <div className="floating-pane">
            <Agent agent={ agent } />
            <Contacts />
          </div>

          <div className="floating-pane">
            <History
              agent={ agent }
              researchContactHistoryPath={ researchContactHistoryPath }
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="floating-pane verified-results">
            <Verification />
            <Results />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
