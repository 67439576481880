import React from 'react';
import ReactDOM from 'react-dom';
import BatchProcessingContainer from './Container';

/**
 * Initialize the BatchProcessing tool.
 */

export function initialize(containerElement, props) {
  let component = React.createElement(BatchProcessingContainer, props);
  ReactDOM.render(component, containerElement);
}
