import { Map } from 'immutable';

const formFields = [
  'buyer_cities',
  'city',
  'country',
  'contact_date',
  'email_address',
  'first_name',
  'home_price_min',
  'home_price_max',
  'last_name',
  'lead_type',
  'contact_time',
  'phone_number',
  'state',
  'street_name',
  'street_number',
  'text_consent',
  'type_home',
  'zip',
];

const buyerCities = ['buyer_cities'];

const nonStandardValidations = [].concat(buyerCities);

const initialState = Map({
  buyer_cities: false,
  city: false,
  country: false,
  contact_date: true,
  email_address: false,
  first_name: false,
  home_price_min: false,
  home_price_max: false,
  last_name: false,
  lead_type: false,
  contact_time: true,
  phone_number: false,
  state: false,
  street_name: false,
  street_number: false,
  text_consent: true,
  zip: false,
});

const actionsMap = {
  ['SET_INITIAL_BUYER_CITIES_FOCUSED']: (state, action) => {
    const { buyer_cities } = action.data.lead;
    let values = [];
    for (let j = 0; j < buyer_cities.length; j+= 1) {
      values.push(false);
    }
    return state.merge(Map({ buyer_cities: values }));
  },
  ['SET_BUYER_CITY_FOCUS']: (state, action) => {
    const { index } = action;
    const buyer_cities = state.get('buyer_cities');
    buyer_cities[index] = true;

    return state.merge(Map({
      ...state,
      buyer_cities,
    }));
  },
  ['ADD_BUYER_CITY_FOCUS']: (state, action) => {
    const buyer_cities = state.get('buyer_cities');
    buyer_cities.push(false);

    return state.merge(Map({
      ...state,
      buyer_cities,
    }));
  },
  ['REMOVE_BUYER_CITY_FOCUS']: (state, action) => {
    const { index } = action;
    const buyer_cities = state.get('buyer_cities');
    buyer_cities.splice(index, 1);

    return state.merge(Map({
      ...state,
      buyer_cities,
    }));
  },
  ['FIELD_FOCUSED']: (state, action) => {
    const { key } = action.data;
    return state.merge(Map({ [key]: true }));
  },
  ['RAW_LOCATIONS_FOCUS']: (state, action) => {
    const { info, rawAddressFields } = action.data;
    const { lead_type } = info;
    let accumulator = {};

    const bcCity = info.buyer_cities[0].city || '';
    const bcState = info.buyer_cities[0].state || '';
    const rawCity = rawAddressFields.city || '';
    const rawState = rawAddressFields.state || '';

    if (lead_type === 'buyer' && info.buyer_cities && info.buyer_cities[0]) {
      if (
        bcCity.toLowerCase() !== rawCity.toLowerCase()
        || bcState.toLowerCase() !== rawState.toLowerCase()
      ) {
        accumulator.buyer_cities = [false];
      } else {
        accumulator.buyer_cities = state.get('buyer_cities');
      }
    }

    const focused = Object.keys(rawAddressFields).reduce((acc, field) => {
      const val = rawAddressFields[field];

      if (['city', 'state'].includes(field) && lead_type === 'buyer') {
        return acc;
      } else if (
        info[field]
        && rawAddressFields[field]
        && info[field].toString().toLowerCase() !== rawAddressFields[field].toString().toLowerCase()
      ) {
        acc[field] = false;
      } else {
        acc[field] = state.get(field);
      }
      return acc;
    }, accumulator);
    return state.merge(Map({
      ...state,
      ...focused,
    }));
  },
  ['RESET_FOCUSED']: (state) => {
    return state.merge(initialState);
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
