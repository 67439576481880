import Modal from './views/modal/Wrapper';

import { Component } from 'react';

const defaultHeader = { klass: '', message: 'Add service area zip codes' };

const initState = {
  agentId: undefined,
  header: defaultHeader,
  serviceAreas: {},
  currentAreas: {},
  currentVisible: true,
};

export default class Container extends Component {
  constructor(props) {
    super(props);

    this.state = initState;
    this.initServiceAreaModal = this.initServiceAreaModal.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('service_area.modal_open', this.initServiceAreaModal);
  }

  initServiceAreaModal(event) {
    const modalDiv = document.getElementById('serviceAreaModal');
    const { agentId } = event.detail;

    this.setState({ ...initState, agentId: agentId });

    this.fetchCurrentAreas(agentId)
    .then(response => {
      this.setState({ currentAreas: response });
      $(modalDiv).modal('show');
    });
  }

  fetchCurrentAreas(agentId) {
    const {
      service_areas_path,
    } = this.props;

    return new Promise(resolve => {
      fetch(
        `${service_areas_path}${agentId}`,
        {
          method: 'GET',
          credentials: 'same-origin',
        }
      )
      .then(response => response.json())
      .then(response => {
        resolve(response.success ? response.marketing_areas : {});
      });
   });
  }

  updateState(attribute, value = undefined) {
    value = (value === undefined) ? initState[attribute] : value;

    this.setState({ [attribute]: value });
  }

  render() {
    const {
      lookup_path,
      service_areas_path,
    } = this.props;

    const {
      agentId,
      currentAreas,
      currentVisible,
      header,
      serviceAreas,
    } = this.state;

    return (
      <Modal
        agentId={ agentId }
        currentAreas={ currentAreas }
        currentVisible={ currentVisible }
        header={ header }
        lookupPath={ lookup_path }
        serviceAreas={ serviceAreas }
        serviceAreasPath={ service_areas_path }
        updateState={ this.updateState }
      />
    );
  }
}
