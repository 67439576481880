import React from 'react';

const DisplayBlock = ({ title, value }) => (
  <div className="info-block">
    <div className="info-block-title">
      {title}
    </div>
    { value && (
      <div className="info-block-value">
        {value}
      </div>
    )}
  </div>
)

export default DisplayBlock;
